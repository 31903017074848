<template>
    <div> 

        <v-slider
            v-model="slider_unit"
            class="align-center"                                
            hide-details
            thumb-label="always"
            :color="'red'"
            :thumb-color="thumb_color"            
            :min="min_units"
            :max="max_units"  
        >

            <!-- 
                Remove unit
            -->
            <template v-slot:prepend>
               <i @click="remove" class="btn-icon fas fa-minus"></i>
               <div class="indicator left">|<span class="min_units">{{min_units}}</span></div>
            </template>

            <!-- 
                Add unit + input
            -->
            <template v-slot:append>    

                <i @click="add" class="btn-icon fas fa-plus"></i>
                <div class="indicator right">|<span class="max_units">{{max_units_label}}</span></div>
                <div class="ml-4 d-flex flex-column align-items-center justify-content-center">
                    <b-form-input id="manual_unit_input" @input="setSliderUnit" style="width: 80px;" type="number" :min="min_units" :max="max_units" v-model="manual_unit_input" :value="manual_unit_input" trim></b-form-input>
                    <small class="selling-mode">{{formatSellingMode(slider_unit, selectedPlan.sellingMode)}}</small>
                </div>

            </template>

        </v-slider>

        <!-- 
            Groups buttons
        -->
        <!-- <div v-if="!selectedPlan.hide_blocks && unit_groups.length > 1" class="groups d-flex flex-row justify-content-around align-items-center"> -->
        <div :class="{'hide': selectedPlan.hide_blocks || unit_groups.length <= 1}" class="groups d-flex flex-row justify-content-around align-items-center">
            <div class="item" v-for="(item, index) in unit_groups" :key="index">
                <v-btn rounded @click.stop="selectGroup(index)" :color="isSelectedGroup(index) ? 'error' : ''">
                    {{item.label}}<!-- - Unit:{{item.group_price.unit}} - Total:{{item.group_price.total}}-->
                </v-btn>
            </div>
        </div>
        
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import $ from "jquery"

export default {
    props: [                             
        'thumb_color',
        'unit_groups',
        'step',
        'min_units',
        'max_units',
        'max_units_label',
        'selectedPlan',
        'renderedPlan'
    ],
    computed:{               

        ...mapState('MainStoreModule', {    
            user_monetary: state => state.user_monetary,                                
            shopping_cart: state => state.shopping_cart
        }),

    },
    data(){
        return {   
            manual_unit_input: 0,
            slider_unit: 0,
            selected_group: {},
            plan_price: 0
        }
    },
    watch:{

        slider_unit(slider_unit){
            this.manual_unit_input = slider_unit 
            setTimeout(() => {
                this.updateShoppingCart({'key': 'selected_plan', 'value': {'plan_price': this.plan_price, 'unit_amount': slider_unit, 'group': this.selected_group, 'renderedPlan': this.renderedPlan, 'plan': this.selectedPlan}})
            }, 500);
        }
        
    },

    mounted(){
        this.selected_group = this.unit_groups[0]

        /*
            Plan price starts with
            SLIDER_UNIT * FIRST GROUP UNIT PRICE 
        */
        this.plan_price = parseFloat(this.slider_unit * this.selected_group.group_price.unit)
    },
    methods: {
        ...mapActions('MainStoreModule', [           
            'updateShoppingCart'
        ]),

       
        formatSellingMode(unit_amount, selling_mode){
            if(unit_amount > 1){
                selling_mode += "S"
            }

            return this.$t(selling_mode)
        },
        
        setSliderUnit(){

            this.slider_unit = parseInt(this.slider_unit)
            this.manual_unit_input = $.trim(this.manual_unit_input) == "" || parseInt(this.manual_unit_input) < this.min_units ? this.min_units : parseInt(this.manual_unit_input)

            if(this.manual_unit_input > this.max_units){
                
                this.slider_unit = this.max_units
                this.manual_unit_input = this.max_units                                        
                return true
                
            }

            if(this.slider_unit <= this.manual_unit_input){

                while(this.slider_unit < this.manual_unit_input){
                    this.slider_unit++
                }   

            }else if(this.slider_unit > this.manual_unit_input){
                
                while(this.slider_unit > this.manual_unit_input){
                    this.slider_unit--
                }
            }
            
        },
        remove () {
            this.slider_unit = parseInt((this.slider_unit - 1) || this.min_units)
        },
        add () {
            this.slider_unit = parseInt((this.slider_unit + 1) || this.max_units)
        },

        isSelectedGroup(index){

            const item = {
                min_units: parseInt(this.unit_groups[index].min_units),
                max_units: this.unit_groups[index].max_units ? parseInt(this.unit_groups[index].max_units) : null
            }

            this.slider_unit = parseInt(this.slider_unit)

            const isSelected = (this.slider_unit >= item.min_units && this.slider_unit <= item.max_units) ||
                               (item.max_units == null && this.slider_unit == item.min_units)

            if(isSelected){
                this.selected_group = this.unit_groups[index]  
                this.plan_price = this.getPlanPrice(index)        
            }

            return isSelected
        },

        getPlanPrice(index){

            var plan_price = 0,
                other_groups_units = 0

            for(var i = 0; i < index; i++){  
                other_groups_units = parseInt(this.unit_groups[i].max_units)
                plan_price += parseFloat(this.unit_groups[i].group_price.total)
            }

            const selected_group_unit = this.slider_unit - other_groups_units,
                  selected_group_price = parseFloat(selected_group_unit * this.selected_group.group_price.unit)

            plan_price += selected_group_price

            return plan_price

        },

        selectGroup(index){                        
            const group_min_unit = parseInt(this.unit_groups[index].min_units)            
            this.slider_unit = parseInt(this.slider_unit)
            
            if(this.slider_unit <= group_min_unit){

                while(this.slider_unit <= group_min_unit){
                    this.slider_unit++
                }   

            }else if(this.slider_unit > group_min_unit){
                
                while(this.slider_unit > group_min_unit){
                    this.slider_unit--
                }
            }
        }
    },   
}
</script>

<style lang="scss" scoped>

.btn-icon{
        
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
        color: #3150E6;
    }
}

.indicator{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;

    font-weight: 800;
    font-size: 8px;
    line-height: 8px;
    
    span{        
        position: absolute;
        margin-top: 25px;
        font-weight: bold !important;
        font-size: 12px !important;
        line-height: 12px !important;
    }

    &.left{        
        margin-top: 13px;
        margin-left: 29px;
    }

    &.right{        
        margin-top: 30px;
        margin-left: -18px;
    }
}

.groups{
    margin-top: 50px;
}

.selling-mode{    
    position: absolute;
    margin-top: 55px;
}

.hide{
    display: none !important;
}


</style>