<template>
  <div style="min-height: 100vh">
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
      <div
        class="section section-main-process contractManagement d-flex flex-row justify-content-center align-items-center"
      >
        <div
          class="col-lg-6 col-md-6 col-sm-12 mockup"
          v-if="window.width > 991"
        >
          <img
            :src="`/assets/images/domains/${domain.name}/processes/contractManagement/mockup_01_${lang}.png`"
            :alt="$t('PROCESS_CONTRACT_MANAG')"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 div-text">
          <div
            class="header-main-process d-flex flex-row justify-content-start align-items-center"
          >
            <div class="icon icon-shape contractManagement">
              <img
                src="/assets/images/processes/icons/03.png"
                :alt="$t('PROCESS_CONTRACT_MANAG')"
              />
              <img
                src="/assets/images/processes/icons/03_hover.png"
                :alt="$t('PROCESS_CONTRACT_MANAG')"
              />
            </div>
            <h1 class="mb-4 title" v-html="$t('PROCESS_CONTRACT_MANAG')"></h1>
          </div>
          <h2 class="subtitle" v-html="$t('THE PROCESS MO...')"></h2>

          <div class="div-buttons d-flex">
            <router-link
              class="btn contractManagement btn-medium-width"
              :to="`/start-free${partner_url}`"
              >{{ $t("START_FREE") }}</router-link
            >
            <router-link
              v-if="
                isDomainPermitted &&
                (domain.type == 'interfy' || domain.type == 'distribuitor')
              "
              class="mx-4 px-3 btn contractManagement-alternative"
              :to="`/plans${partner_url}`"
              >{{ $t("BECOME_A_PARTNER") }}</router-link
            >
          </div>
        </div>
      </div>
    </transition>

    <img
      class="top-image"
      src="/assets/images/processes/top-01.png"
      :alt="`${domain.title}`"
    />

    <transition name="slide-fade">
      <div class="section base-padding section-blocks-description">
        <div class="row">
          <div class="column col-lg-4 col-md-4 col-sm-12">
            <div
              class="item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="icon icon-shape icon-01">
                <img
                  src="/assets/images/processes/contractManagement/icons/01.png"
                  :alt="$t('ALLOW YOURSELF...')"
                />
              </div>
              <span></span>
              <p v-html="$t('ALLOW YOURSELF...')"></p>
            </div>
          </div>
          <div class="column col-lg-4 col-md-4 col-sm-12">
            <div
              class="item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="icon icon-shape icon-01">
                <img
                  src="/assets/images/processes/contractManagement/icons/02.png"
                  :alt="$t('THE CONTRACT G...')"
                />
              </div>
              <span></span>
              <p v-html="$t('THE CONTRACT G...')"></p>
            </div>
          </div>
          <div class="column col-lg-4 col-md-4 col-sm-12">
            <div
              class="item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="icon icon-shape icon-01">
                <img
                  src="/assets/images/processes/contractManagement/icons/03.png"
                  :alt="$t('IN THE FLOW, T...')"
                />
              </div>
              <span></span>
              <p v-html="$t('IN THE FLOW, T...')"></p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="column col-lg-4 col-md-4 col-sm-12">
            <div
              class="item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="icon icon-shape icon-01">
                <img
                  src="/assets/images/processes/contractManagement/icons/04.png"
                  :alt="$t('IF THE FIRST A...')"
                />
              </div>
              <span></span>
              <p v-html="$t('IF THE FIRST A...')"></p>
            </div>
          </div>
          <div class="column col-lg-4 col-md-4 col-sm-12">
            <div
              class="item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="icon icon-shape icon-01">
                <img
                  src="/assets/images/processes/contractManagement/icons/05.png"
                  :alt="$t('WHEN THE FINAL...')"
                />
              </div>
              <span></span>
              <p v-html="$t('WHEN THE FINAL...')"></p>
            </div>
          </div>
          <div class="column col-lg-4 col-md-4 col-sm-12">
            <div
              class="item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="icon icon-shape icon-01">
                <img
                  src="/assets/images/processes/contractManagement/icons/06.png"
                  :alt="$t('THROUGH THIS P...')"
                />
              </div>
              <span></span>
              <p v-html="$t('THROUGH THIS P...')"></p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div class="section section-list-process-description">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12 mockup">
            <img
              :src="`/assets/images/domains/${domain.name}/processes/contractManagement/mockup_02.png`"
              :alt="$t('PROCESS_CONTRACT_MANAG')"
            />
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12">
            <div v-for="(item, index) in processList" :key="index" class="ct">
              <div
                class="item d-flex flex-row justify-content-between align-items-start"
              >
                <div class="icon">
                  <img :src="item.icon" :alt="item.title" />
                  <img :src="item.icon_hover" :alt="item.title" />
                </div>

                <div
                  class="text d-flex flex-column justify-content-center align-items-start"
                >
                  <h2 v-html="item.title"></h2>
                  <p v-html="item.description"></p>
                </div>

                <div class="arrows" @click="openItem($event)">
                  <img
                    src="/assets/images/processes/arrow-down.png"
                    :alt="`${domain.title}`"
                  />
                  <img
                    src="/assets/images/processes/arrow-up.png"
                    :alt="`${domain.title}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div class="section section-process-description">
        <p v-html="$t('WORKING WITH A...')"></p>
      </div>
    </transition>

    <!-- 

        Join......

    -->
    <transition name="slide-fade">
      <Join
        :isDomainPermitted="isDomainPermitted"
        :partner_url="partner_url"
        v-if="show"
      ></Join>
    </transition>

    <!-- 

        See also......

    -->
    <transition name="slide-fade">
      <OtherProcesses
        :partner_url="partner_url"
        v-if="show"
        :page="page"
        :path="path"
      ></OtherProcesses>
    </transition>

    <!-- 

        Automate......

    -->
    <transition name="slide-fade">
      <AutomateProcesses
        :isDomainPermitted="isDomainPermitted"
        :partner_url="partner_url"
        v-if="show"
      ></AutomateProcesses>
    </transition>

    <!-- 

        The platform...

    -->
    <transition name="slide-fade">
      <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    <!-- 

        Clients

    -->
    <transition name="slide-fade">
      <Clients class="clients-bpm"></Clients>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Clients from "../common/Clients";
import Join from "../common/Join";
import ThePlatform from "../common/ThePlatform";
import OtherProcesses from "../common/OtherProcesses";
import AutomateProcesses from "../common/AutomateProcesses";
import $ from "jquery";

export default {
  components: {
    Clients,
    Join,
    ThePlatform,
    OtherProcesses,
    AutomateProcesses,
  },
  props: {
    isDomainPermitted: {
      type: Boolean,
      default: false,
    },
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
    page: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    partner_url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState("MainStoreModule", {
      domain: (state) => state.domain,
    }),

    lang() {
      return this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale;
    },
    processList() {
      return [
        {
          icon: "/assets/images/processes/contractManagement/icons/icon-01.png",
          icon_hover:
            "/assets/images/processes/contractManagement/icons/icon-02.png",
          title: this.$t("NO LOGIN OR PA..."),
          description: this.$t("THE PEOPLE WHO..."),
        },
        {
          icon: "/assets/images/processes/contractManagement/icons/icon-03.png",
          icon_hover:
            "/assets/images/processes/contractManagement/icons/icon-04.png",
          title: this.$t("E-MAIL NOTIFIC..."),
          description: this.$t("FOR THE FIRST ..."),
        },
        {
          icon: "/assets/images/processes/contractManagement/icons/icon-05.png",
          icon_hover:
            "/assets/images/processes/contractManagement/icons/icon-06.png",
          title: this.$t("DOCUMENTS LIS..."),
          description: this.$t("WHEN ACCESSING..."),
        },
        {
          icon: "/assets/images/processes/contractManagement/icons/icon-07.png",
          icon_hover:
            "/assets/images/processes/contractManagement/icons/icon-08.png",
          title: this.$t("THE DIGITAL SI..."),
          description: this.$t("THE DOCUMENT W..."),
        },
        {
          icon: "/assets/images/processes/contractManagement/icons/icon-09.png",
          icon_hover:
            "/assets/images/processes/contractManagement/icons/icon-10.png",
          title: this.$t("USE THE DIGITA..."),
          description: this.$t("WHEN SELECTING..."),
        },
      ];
    },
  },
  methods: {
    ...mapActions("MainStoreModule", []),
  },

  beforeCreate() {
    this.$emit("isPageLoaded", false);
  },

  mounted() {
    setTimeout(() => {
      this.$emit("isPageLoaded", true);
      this.show = true;
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 991px) {
  .section-list-process-description,
  .section-blocks-description {
    .row {
      margin: 0 !important;
    }
  }

  .section-list-process-description {
    .mockup {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: relative;
        align-self: center;
        width: 80% !important;
        margin-bottom: 30px;
      }
    }

    .item {
      padding: 10px !important;

      .icon,
      .arrows {
        margin-top: 2px !important;
      }

      .text {
        padding: 0 20px !important;
        h2 {
          font-size: 16px !important;
          line-height: 18px !important;
          margin-bottom: 0 !important;
        }
        p {
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }
  }
}
</style>