<template>
  <div class="ct-form-checkout">

    <form id="form-account" ref="form-account">

        <!-- 
            Workspace name
        -->
        <div class="row">
            <div class="col">
                <b-form-group class="m-0" :description="''" :label="$t('WORKSPACE_NAME')" label-for="workspace_name" :invalid-feedback="error_workspace_name" :state="state.workspace_name">
                    <div class="row">
                        <div class="col-10 d-flex align-items-center p-0">
                            <b-form-input id="workspace_name" :state="state.workspace_name" v-model="form.workspace_name" trim required></b-form-input>                    
                        </div>
                        <div class="col-2 d-flex align-items-center p-0 pl-2">.{{domain.location}}</div>
                    </div>
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <!--
                email
            -->
            <div class="col">          
                <b-form-group class="m-0" :description="''" :label="$t('E-mail')" label-for="email">
                    <b-form-input :disabled="pre_customer !== null" type="email" id="email" :state="state.email" v-model="form.email" trim required></b-form-input>                    
                </b-form-group>
            </div>          
        </div>

        <div class="row">
            <!--
                name
            -->
            <div class="col">         
                <b-form-group class="m-0" :description="''" :label="$t('FULL_NAME')" label-for="name">                    
                    <b-form-input id="name" :state="state.name" v-model="form.name" trim required></b-form-input>                    
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <!--
                password
            -->
            <div class="col-sm-12 col-md-6 col-lg-6">                
                <b-form-group class="m-0" :description="''" :label="$t('password')" label-for="password" :invalid-feedback="error_password" :state="state.password">
                    <b-form-input id="password" type="password" :state="state.password" v-model="form.password" trim required></b-form-input>
                </b-form-group>
            </div>
            <!--
                password
            -->
            <div class="col-sm-12 col-md-6 col-lg-6">
                <b-form-group class="m-0" :description="''" :label="$t('rep_password')" label-for="rep_password">
                    <b-form-input id="rep_password" type="password" :state="state.password" v-model="form.rep_password" trim required></b-form-input>                    
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <!--
                customer_phone
            -->
            <div class="col">         
                <b-form-group class="m-0" :description="''" :label="$t('PHONE')" label-for="customer_phone">
                    <b-form-input id="customer_phone" v-mask="'############'" :state="state.customer_phone" v-model="form.customer_phone" trim required></b-form-input>                    
                </b-form-group>
            </div>
        </div>
        
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
export default {       
    props:['selectedPlan'],  
    computed: {
        ...mapState('MainStoreModule', {                                                
            pre_customer: state => state.pre_customer,      
            domain: state => state.domain,            
            shopping_cart: state => state.shopping_cart
        }),      
        
          
    },
    data(){
        return {
            isLoading: false,
            error_workspace_name: null,
            error_password: null,
            form:{ 
                workspace_name: '',
                password: '',
                rep_password: '',
                email: '',
                name: '',
                customer_phone: ''
            },   
            state:{ 
                workspace_name: null,
                password: this.checkPassword,
                rep_password: this.checkPassword,
                email: null,
                name: null,
                customer_phone: null
            } 
        }
    },

    watch:{
        form:{
            deep: true,
            handler(form){

                if(!this.isLoading){
                    this.state.workspace_name = this.isValidWorkspaceName(this.form.workspace_name)
                    this.state.password = this.isValidPassword(this.form.password, this.form.rep_password)
                    this.updateShoppingCart({'key': 'account', 'value': form})                
                }
            }
        }
    },

    methods:{
        ...mapActions('MainStoreModule', [          
            'updateShoppingCart'            
        ]),

        hasSpecialChars(str){
            return !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(str)
        },

        isAlphaNumeric(str){                        

            const hasLetters = !/^[A-Za-z]+$/g.test(str),
                  hasNumbers = !/^[0-9]+$/g.test(str)

            return hasLetters && hasNumbers
        },

        isValidPassword(password, rep_password){
            
            if(
                password.length < 8 ||
                !this.hasSpecialChars(password) ||
                !this.isAlphaNumeric(password)
            ){

                if(password.length == 0){
                    this.error_password = this.$t('FIELD_IS_REQUIRED').replace('{var1}',this.$t('password'))
                }else{
                    this.error_password = this.$t('INVALID_PASSWORD')
                }

                return false
                
            }

            if(password != rep_password){
                this.error_password = this.$t('NOT_EQUAL_PASSWORDS')
                return false
            }

            return true
        },

        isValidWorkspaceName(str){
    
            if (
                str.length < 3 ||
                str.includes(" ") ||
                str.includes("__")||
                str.includes("--")||
                str.includes("_-")||
                str.includes("-_")||
                !this.hasSpecialChars(str) ||
                ( str[0] == "-" || str[0] == "_" ) ||
                ( str.substr(str.length - 1) == "-" || str.substr(str.length - 1) == "_" )
            ) {

                if(str.length == 0){
                    this.error_workspace_name = this.$t('FIELD_IS_REQUIRED').replace('{var1}',this.$t('WORKSPACE_NAME'))
                }else{
                    this.error_workspace_name = this.$t('INVALID_WORKSPACE_NAME')
                }

                return false
            }
        
            return true
    
        },        
    },
    mounted(){        
        this.isLoading = true    
        this.form.email = this.pre_customer.email
        this.form.name = this.pre_customer.name
        this.form.customer_phone = this.pre_customer.phone
        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>

.ct-form-checkout{    
    width: 100%;

    .row{
        margin: 0;
    }
}

</style>