<template>
  <div class="documentation" v-if="Object.keys(business_partner).length > 0">
    <ol class="numbered-list mb-5 no-print">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 1)">Definições</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 2)">Compromisso</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 3)">Duração</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 4)">Política comercial e divulgação</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 5)">Entrega de materiais para o Parceiro de Negócio</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 6)">Categoria de parceiro e remuneração</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 7)">Impostos sobre as vendas do produto</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 8)">Obrigações gerais da Interfy</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 9)">Obrigações gerais do Parceiro de Negócio</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 10)">Marketing</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 11)">Demonstração</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 12)">Treinamento</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 13)">Suporte e atualização da plataforma Interfy</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 14)">Direito sobre a plataforma Interfy</span>
          </li>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6">
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 15)">Correções e modificações</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 16)">Confidencialidade</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 17)">Não competição</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 18)">Direitos de propriedade</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 19)">Marcas</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 20)">Representações e garantias da Interfy</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 21)">Indenização do Parceiro de Negócios</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 22)">Terminação</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 23)">Efeito de rescisão</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 24)">Desempenho das obrigações decorrentes do acordo após
              rescisão</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 25)">Relacionamento jurídico</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 26)">Tarefa</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 27)">Interpretação</span>
          </li>
          <li class="link">
            <span @click.stop="scrollto('.agreement-list', 28)">Geral</span>
          </li>
        </div>
      </div>
    </ol>

    <h1 class="mb-40" style="width: 100%; text-align: center">
      CONTRATO DE PARCERIA DE NEGÓCIOS
    </h1>
    <p class="mb-30">
      Este CONTRATO DE PARCERIA de revenda de software, permite a
      comercialização da Plataforma de software INTERFY (o "Contrato") entra em
      vigor na data de sua associação.
    </p>

    <table class="mb-40">
      <tr>
        <td style="vertical-align: top; padding-right: 20px; font-size: 20px">
          <b>ENTRE:</b>
        </td>
        <td>
          <p class="mb-20">
            <b>INTERFY INTERNATIONAL LLC </b> (a "INTERFY"), uma empresa
            constituída e existente sob as leis do Estado da Flórida, EIN
            32-0508424, com sede localizada em:
          </p>
          <p class="mb-30">
            6000 Metrowest Blvd, Suite 203<br />
            Orlando, FL - United States<br />
            CEP: 32835<br /><br />
            E-mail: partners@interfy.io
          </p>
        </td>
      </tr>

      <tr>
        <td style="vertical-align: top; padding-right: 20px; font-size: 20px">
          <b>E:</b>
        </td>
        <td>
          <p class="mb-20">
            <b>{{ business_partner.name.toUpperCase() }}</b> ("PARCEIRO DE
            NEGÓCIO"), uma empresa constituída e existente de acordo com as leis
            de ({{ business_partner.country }}), com sede em:
          </p>
          <p class="mb-30" v-if="Object.keys(business_partner).length > 0">
            <span v-html="business_partner.address"></span>
            <br /><br />
            E-mail: {{ business_partner.email }}
          </p>
        </td>
      </tr>
    </table>

    <p class="mb-30">
      CONSIDERANDO QUE a INTERFY é a ÚNICA proprietária da plataforma de
      software e marca conhecida como INTERFY e a única proprietária de todos os
      direitos de propriedade intelectual da Plataforma INTERFY;
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE a INTERFY desenvolveu e planeja continuar o
      desenvolvimento de soluções de tecnologias (plataforma e software) que
      integram novos conceitos para gestão de negócios em nuvem e outras
      plataformas e inovações tecnológicas relacionadas onde em formato
      exclusivo utilizando sua própria marca junto aos seus PARCEIROS DE
      NEGÓCIO, tendo como base principal Plataforma INTERFY;
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE a INTERFY não é de propriedade nem tem qualquer
      associação legal ou fiscal nem obrigações com o PARCEIRO DE NEGÓCIO
      (doravante "PARCEIRO DE NEGÓCIO") fora das cláusulas contratuais aqui
      declaradas por meio deste acordo mútuo, a fim de obter permissão legal da
      INTERFY para o PARCEIRO DE NEGÓCIO para comercializar os produtos,
      software e serviços da INTERFY;
    </p>
    <p class="mb-30">
      Nenhuma interferência ou modificação de codificação deve ser transferida
      para o PARCEIRO DE NEGÓCIO e/ou suas partes relacionadas ou associados a
      qualquer momento, e este acordo não constitui em qualquer fonte de
      transferência de direito de propriedade do software, nem concede ao
      PARCEIRO DE NEGÓCIO quaisquer direitos de propriedade intelectual, nem
      confere ao PARCEIRO DE NEGÓCIO qualquer outro direito, senão apenas
      comercializar produtos e serviços existentes da INTERFY e que utilizem a
      mesma marca (INTERFY).
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE a INTERFY possui outras tecnologias e planos para
      entregar outros produtos que podem ser adaptados, incluindo Software
      conhecido como PROCESSDOC, DOCSYSTEM, CLOUDDOC, INTELLIFY e NEOFY, bem
      como software para ser usado para fins de Gestão de Negócios, que estão
      atualmente em desenvolvimento e não são tratados ou interferem com este
      contrato;
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE a INTERFY está desenvolvendo produtos semelhantes para
      serem adaptados ao seu software, que em um futuro próximo também pode ser
      atribuído como parte deste acordo ao PARCEIRO DE NEGÓCIO e tudo é
      garantido que não está relacionado em nenhum sentido ou forma à
      transferência de propriedades intelectuais relativas ao Software, ou
      codificação da plataforma nem a transferência de processos de criação ou
      conceito de INTERFY;
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE este instrumento não concede, nem transfere ao PARCEIRO
      DE NEGÓCIOS, quaisquer direitos além dos especificados neste contrato e
      nenhum direito de propriedade intelectual relacionado ao Software, ao
      Código-fonte da plataforma ou a qualquer Criação e Processos desenvolvidos
      pela INTERFY. A engenharia reversa ou qualquer outro tipo de tecnologia é
      proibida para promover qualquer alteração nos produtos fornecidos, exceto
      aquelas previstas nas ferramentas incluídas na Plataforma de Software
      INTERFY;
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE O PARCEIRO DE NEGÓCIO aceita a garantia, dentro do escopo
      limitado por ele previsto e concorda em envidar seus melhores esforços
      para transmitir as características, benefícios e preços dos produtos da
      Plataforma INTERFY aos seus clientes.
    </p>
    <p class="mb-30">
      CONSIDERANDO que o PARCEIRO DE NEGÓCIO concorda que, para comercializar os
      produtos da INTERFY, estará obrigatoriamente sujeito às regras
      estabelecidas pela INTERFY, não podendo utilizar práticas ilícitas ou
      desleais de concorrência para atuar na comercialização do produto ou
      praticar atos que caracterizem má-fé, falta profissional, crimes contra o
      sistema financeiro ou qualquer tipo de crime passível de punição.
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE o PARCEIRO DE NEGÓCIO reconhece e concorda que a INTERFY,
      ao celebrar este Acordo, ao celebrar este Acordo, está contando com os
      esforços que serão feitos pelo PARCEIRO DE NEGÓCIO em relação à venda e
      força de marketing para alcançar os melhores resultados possíveis;
    </p>
    <p class="mb-30">
      CONSIDERANDO QUE a INTERFY está concedendo ao PARCEIRO DE NEGÓCIO, a
      partir da data deste documento, direitos de comercialização de forma não
      exclusivos os produtos INTERFY;
    </p>
    <p class="mb-30">
      CONSIDERANDO a conclusão dos acordos de PARCEIROS COMERCIAIS mencionados
      acima entre a INTERFY. e o PARCEIRO DE NEGÓCIO, é condição essencial para
      a celebração deste contrato de revenda pela INTERFY.
    </p>

    <p>AGORA FICA ACORDADO da seguinte forma:</p>

    <ol class="agreement-list numbered-list">
      <li class="item">
        <p><b>DEFINIÇÕES</b></p>

        <p>
          Neste Contrato, a menos que o contexto exija de outra forma, as
          seguintes expressões têm os seguintes significados:
        </p>
        <p>
          "Dia Útil" significa um dia diferente de sábado, domingo ou feriado;
        </p>
        <p>
          "Produto concorrente" significa qualquer software de computador que
          oferece a mesma funcionalidade ou recursos substancialmente
          semelhantes a qualquer produto do portfólio da INTERFY, a um preço de
          aproximadamente o preço de lista publicado do Produto no Território,
          ou parte dele, naquele momento;
        </p>
        <p>
          A PLATAFORMA INTERFY e quaisquer outros produtos dela derivados
          constituem um sistema de software desenvolvido pela INTERFY e
          desenhado para auxiliar na gestão do processamento de documentos,
          gestão de processos, colaboração, entre outras funcionalidades;
        </p>
        <p>"Cliente" significa o beneficiário de uma Workspace;</p>
        <p>
          A plataforma INTERFY e quaisquer produtos derivados dela fornecem
          recursos de digitalização e importação de documentos; pesquisa de
          conteúdo, controle de versão de conteúdo, com gerenciamento de
          registros; gerenciamento de funções; gestão de processos, utilizando a
          notação BPMN, e execução de tarefas e atividades 100% online, sem
          qualquer instalação; entre outros recursos.
        </p>
        <p>
          "Região" significa o local onde o PARCEIRO DE NEGÓCIO está autorizado
          a comercializar a Plataforma INTERFY.
        </p>
        <p>
          "Direitos de propriedade intelectual" inclui patentes, marcas
          comerciais, marcas de serviço, projetos registrados, topografia de
          circuito integrado, incluindo aplicativos para qualquer um dos
          anteriores, bem como direitos autorais, direitos de projeto, know-how,
          informações confidenciais, nomes comerciais e comerciais e qualquer
          outros direitos semelhantes em qualquer país;
        </p>
        <p>
          "Workspace" significa o espaço de trabalho concedido pela Interfy a um
          Cliente para operar na Plataforma, nos termos e condições aqui
          estabelecidos;
        </p>
        <p>
          "Produtos não exclusivos" significa aqueles produtos para os quais
          direitos não exclusivos são concedidos ao PARCEIRO DE NEGÓCIO;
        </p>
        <p>"Região não exclusiva" significa o local onde o parceiro opera.</p>
        <p>
          "Computador pessoal portátil" significa um computador móvel robusto
          desenvolvido e projetado pelo PARCEIRO DE NEGÓCIO para funcionar em um
          ambiente hostil;
        </p>
        <p>
          "Preferencial" significa sob os termos e condições não menos
          favoráveis para o beneficiário de tais condições como aquelas dadas,
          no momento relevante, a qualquer terceiro nas mesmas circunstâncias ou
          semelhantes;
        </p>
        <p>
          "Produto" significa a versão em nuvem de um determinado produto, com
          base na Plataforma INTERFY, propriedade da INTERFY, e todas as
          modificações, aprimoramentos e substituições dos mesmos e acréscimos
          que podem ser fornecidos pela INTERFY e disponibilizados ao PARCEIRO
          DE NEGÓCIOS de tempos em tempo de acordo com este Contrato e incluirá
          qualquer Produto Planejado assim que a INTERFY considerar, a seu
          exclusivo critério, que qualquer Produto Planejado atingiu um nível
          suficiente de desenvolvimento para estar pronto para revenda;
        </p>
        <p>
          "Materiais do Produto" significa o Produto relevante, juntamente com a
          Descrição do Produto e a Documentação do Produto relacionada a este
          Produto;
        </p>
        <p>
          “Acordos Relacionados” significa os acordos de PARCEIRO DE NEGÓCIO
          firmados entre INTERFY e PARCEIRO DE NEGÓCIO na data deste documento;
        </p>
        <p>
          "Código-fonte" significa todas as lógicas existentes e disponíveis,
          diagramas lógicos, fluxogramas, representações ortográficas,
          algoritmos, rotinas, sub-rotinas, utilitários, módulos, estruturas de
          arquivo, folhas de codificação, codificação, listagens de
          códigos-fonte, especificações funcionais, especificações de programa e
          todos outros materiais e documentos existentes e disponíveis,
          necessários para permitir que um programador razoavelmente qualificado
          mantenha, altere e aprimore o Produto relevante sem referência a
          qualquer outra pessoa ou documentação, tanto em formato legível por
          humanos ou por máquina;
        </p>
        <p>
          "Serviços" significa quaisquer serviços de suporte ao Produto
          fornecidos ou a serem fornecidos pelo PARCEIRO DE NEGÓCIO de acordo
          com qualquer contrato firmado com um Cliente, incluindo para a
          instalação, o suporte, a programação personalizada e o aprimoramento
          dos Produtos;
        </p>
        <p>
          "Padrão" significa termos e condições padrão publicados ou, na
          ausência de tal publicação, termos e condições normalmente concedidos
          a terceiros não relacionados no curso normal dos negócios para o
          fornecimento de um determinado produto ou serviço;
        </p>
        <p>
          "Impostos" significa direitos, vendas, bens e serviços, impostos
          especiais de consumo e impostos de valor agregado;
        </p>
        <p>
          "Território" deve ser entendido como referindo-se apenas ao Estado, ou
          parte dele, para o qual, conforme o caso, direitos de comercialização
          não exclusivos com relação ao Produto, Materiais do Produto,
          Documentação do Produto ou Descrição do Produto relevantes são
          concedidos ao PARCEIRO DE NEGÓCIO de acordo com os parágrafos 2.1
          deste Acordo;
        </p>
        <p>
          "Marcas comerciais" significa os seguintes nomes e marcas comerciais:
          “INTERFY”;
        </p>
      </li>

      <li class="item">
        <p><b>ÁREA DE ATUAÇÃO DO PARCEIRO DE NEGÓCIO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              A INTERFY concede ao PARCEIRO DE NEGÓCIO, que o aceita, o direito
              não exclusivo de comercializar a Plataforma INTERFY aos seus
              clientes em
              <b>todo o país onde a empresa do parceiro está sediada;</b>
            </p>
          </li>

          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve cumprir todas as obrigações expressas e
              assumidas neste contrato;
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>DURAÇÃO DO CONTRATO DE PARCERIA</b></p>

        <p>
          Este Acordo terá início na data em que é aceito e permanecerá em
          vigor por um período de 3 (três) anos, com fidelidade de ano, e sua renovação por igual
          período, através de aditivo assinado digitalmente, desde que ocorra
          acordo entre as partes. Caso não ocorra acordo entre as partes, esse
          contrato será automaticamente rescindido, sem necessidade de aviso
          prévio ou multa por ambas as partes.
        </p>
      </li>

      <li class="item">
        <p><b>POLÍTICA COMERCIAL E DIVULGAÇÃO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Os produtos INTERFY poderão ser comercializados através da venda
              online utilizando o Marketing Digital, por meios de anúncios no
              Google, Linkedin e de campanhas feitas através das redes sociais
              como o Facebook, Instagram e de e-mails marketing.
            </p>
            <p>
              Quando uma empresa se torna um Parceiro de Negócio, a INTERFY a
              seu critério poderá liberar uma URL para que o parceiro utilize
              para realizar suas vendas. Ex.:
              www.interfy.io/nomedaempresadoparceiro. Ou seja, uma URL onde
              consta o nome do parceiro para que ele possa divulgar e fazer as
              suas campanhas de marketing, e todas as vendas realizadas a partir
              dessa URL são contabilizadas no software de Administração e Gestão
              que a INTERFY fornece para o Parceiro de Negócio.
            </p>
          </li>

          <li>
            <p>
              O produto deve ser comercializado considerando os custos por
              usuário, espaço de armazenamento e módulos opcionais ou
              funcionalidade;
            </p>
          </li>

          <li>
            <p>
              Alguns opcionais serão liberados assim que a configuração e adesão
              forem feitas pelo administrador do Workspace do cliente final, que
              serão contabilizados automaticamente pelo sistema de acordo com o
              consumo apurado e cobrado na nota fiscal do PARCEIRO DE NEGÓCIO.
              Essas informações estarão disponíveis no sistema de administração
              da Workspace disponibilizado pela INTERFY ao PARCEIRO DE NEGÓCIO,
              onde ele poderá acompanhar o consumo de cada cliente e o
              detalhamento de cada faturamento recebido da INTERFY.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>ENTREGA DE MATERIAIS PARA O PARCEIRO DE NEGÓCIO</b></p>
        <p>
          Após o aceite deste contrato, a nossa equipe de canais irá entrar em contato com a revenda para explicar
          como funciona o programa de parceria. Uma vez tudo acertado, o Parceiro de Negócio receberá todos os itens
          abaixo:
        </p>

        <ol class="numbered-list">
          <li>
            <p>
              O PARCEIRO COM A MARCA INTERFY, se compromete a pagar a taxa de setup (taxa única), conforme acertada com
              o departamento de canais da Interfy, para a liberação de todos os itens abaixo. A Interfy irá
              disponibilizar sua estrutura de Cloud da Plataforma com marca Interfy com usuários ilimitados do ECM e BPM
              e demais módulos. O parceiro deverá pagar a Interfy 50% do custo do site sobre suas vendas.
            </p>
          </li>
          <li>
            <p>
              Acesso a Universidade Interfy para até duas pessoas, onde o parceiro deverá completar todos os
              treinamentos disponíveis, para então começar a vender os produtos da plataforma Interfy.
            </p>
          </li>
          <li>
            <p>
              Link do website com a sua URL Ex:www.interfy.io/nomedoparceiro,
              pela qual realizará as suas vendas.
            </p>
          </li>
          <li>
            <p>
              Sistema DEMO com uma Workspace com ECM e BPM com 5 usuários para
              uso interno em sua empresa e em demonstração e treinamento para os
              seus clientes.
            </p>
          </li>
          <li>
            <p>
              Software de Administração e Gestão de Vendas da Plataforma INTERFY, onde o Parceiro de Negócio irá
              administrar seus clientes, consultar faturas emitidas pela INTERFY, verificar o consumo de cada cliente
              para emissão da sua cobrança mensal, bloquear o acesso do cliente em caso de inadimplência, solicitar a
              eliminação definitiva das bases de clientes quando cancelado o contrato, entre outras ações, que estão
              especificadas nos vídeos de treinamento disponíveis na universidade INTERFY.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>CATEGORIA DE PARCEIRO E REMUNERAÇÃO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Este contrato contempla a categoria de parceria:
              <b>Interfy Partner</b>,
              onde o Parceiro de Negócio realiza todas as vendas e fatura
              diretamente para os seus clientes. Nessa modalidade de parceria o
              Parceiro de Negócio recebe um desconto de 50% sobre os preços de
              vendas publicados no website da Interfy.
            </p>

            <!-- <ol class="numbered-list">
              <li>
                <p>
                  A categoria Silver, é considerado um Parceiro de Negócio
                  Afiliado Web/Indicador. Nessa modalidade o parceiro receberá
                  uma bonificação de 10% referente as todas as vendas realizadas
                  por ele e faturadas diretamente pela INTERFY. O pagamento da
                  comissão será feito 10 dias após o fechamento do mês mediante
                  a emissão de nota de fiscal para o valor mínimo acumulado de $
                  300 (trezentos dólares). Se esse valor não for atingido, o
                  pagamento será processado no mês seguinte, se o montante
                  mínimo tiver sido atingido e assim sucessivamente.
                </p>
              </li>

              <li>
                <p>
                  A categoria Gold, é considerado um Parceiro de Negócio padrão
                  que realiza todas as vendas e fatura diretamente para os seus
                  clientes, para essa categoria o parceiro receberá um desconto
                  de 50% sobre o preço de venda publicado no website da INTERFY.
                </p>
              </li>
            </ol> -->
          </li>

          <li>
            <p>
              Não haverá taxa de renovação da parceria, porém é esperado que o Parceiro de Negócio mantenha seus
              melhores esforços para promover a comercialização da plataforma Interfy.
            </p>
          </li>

          <li>
            <p>
              Para a Certificação Online na Universidade Interfy, o Parceiro de
              Negócios poderá inscrever até 2 pessoas para fazer treinamento
              gratuitamente por ano.
            </p>
          </li>

          <!-- <li>
            <p>
              <b>A Interfy irá disponibilizar sua estrutura de Cloud da Plataforma Interfy com usuários ilimitados do
                ECM e BPM. Para cobrir os custos da plataforma, o parceiro deverá pagar a Interfy 50% do custo do site
                sobre suas vendas. Esta cobrança entra em vigor a partir do momento em que o consumo de usuários exceder
                o número contratado na adesão deste instrumento (25 usuários).</b>
            </p>
          </li> -->

          <li>
            <p>
              Se, durante o período de teste drive ou ao final do mesmo, a contratação for efetivada, uma fatura será
              emitida e cobrada
              no prazo de até 3 dias do Parceiro, caso a forma de pagamento do parceiro seja cartão de crédito, se for
              com boleto bancário, a cobrança será unificada e enviada junto ao montante de consumo mensal. A partir da
              contratação da workspace, começa a contar o consumo mensal que será incluído no fechamento da fatura
              seguinte. Sendo que todo o faturamento feito pela empresa INTERFY USA será com emissão de invoice.
            </p>
          </li>

          <li>
            <p>
              Todas as faturas de consumo mensal serão cobradas no primeiro dia
              do mês seguinte à data de fechamento.
            </p>
          </li>

          <li>
            <p>
              Se um parceiro ficar inadimplente por 30 dias, este contrato será automaticamente rescindido e o parceiro
              será desqualificado. Todos os acessos à Plataforma e ao ambiente de Administração serão bloqueados e todas
              as bases de clientes do parceiro serão transferidas para a INTERFY ou eliminadas definitivamente sem
              possibilidade de recuperação, caso o cliente não deseje mais utilizar os serviços. Para tal, a INTERFY
              notificará o Parceiro e os clientes, informando-os da desqualificação do parceiro e da possibilidade de os
              clientes continuarem a utilizar os serviços para que não sofram quaisquer danos em resultado da rescisão
              do contrato de parceria.
            </p>
          </li>

          <li>
            <p>
              Se o Parceiro for desqualificado, por inadimplência, ou por qualquer fator resultante do não cumprimento
              das cláusulas contratuais pelo Parceiro, ou rescisão deste contrato até o seu vencimento, quando o
              Parceiro não manifestar interesse em renovar este contrato de parceria, a INTERFY é dispensada do
              pagamento de qualquer valor a título de multa ou indenização ao Parceiro, ou ainda como remuneração pela
              transferência de clientes.
            </p>
          </li>

          <li>
            <p>
              O PARCEIRO DE NEGÓCIO compromete-se a pagar o valor do seu consumo
              mensal apurado atempadamente. Pelo não pagamento dos valores
              devidos à execução deste Contrato, na data de seu vencimento,
              estará sujeito às seguintes sanções:
            </p>

            <ol class="numbered-list">
              <li>
                <p>
                  Multa de 5% (cinco por cento), incidente sobre o valor total
                  da dívida não quitada, no dia seguinte ao do vencimento; e
                </p>
              </li>

              <li>
                <p>
                  Juros de mora ao mês (ou fração de mês) de 1% (um por cento),
                  a contar do 1º dia seguinte ao do vencimento e incidentes
                  sobre o valor da dívida não quitada; e
                </p>
              </li>

              <li>
                <p>
                  Após 10 (dez) dias de atraso no pagamento ou 3 tentativas de
                  cobrança da fatura pelo sistema, os serviços serão suspensos.
                  O acesso ao serviço somente será restabelecido com o pagamento
                  do valor devido, incluindo os encargos moratórios previstos
                  neste Contrato, sem prejuízo da possibilidade de rescisão
                  contratual e cobrança extrajudicial ou judicial pela
                  Companhia;
                </p>
              </li>

              <li>
                <p>
                  As disposições desta Seção sobreviverão à rescisão, por
                  qualquer motivo, deste Acordo.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>IMPOSTOS SOBRES AS VENDAS DO PRODUTO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              O Parceiro de Negócio será responsável pelo pagamento de todos os
              impostos aplicáveis a qualquer Produto/Serviço comercializado por
              ele, de acordo com os termos deste instrumento.
            </p>
          </li>

          <li>
            <p>
              Caso a INTERFY tenha pago tais impostos em nome do PARCEIRO DE
              NEGÓCIOS, então ela terá o direito de ser reembolsada pelo
              PARCEIRO DE NEGÓCIO mediante a apresentação de qualquer prova de
              tal pagamento.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>OBRIGAÇÕES GERAIS DA INTERFY:</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Fornecer ao PARCEIRO DE NEGÓCIO a infraestrutura em nuvem para
              suportar os contratos que serão firmados com os clientes finais de
              acordo com a política comercial definida pela INTERFY;
            </p>
          </li>

          <li>
            <p>
              Fornecer ao PARCEIRO DE NEGÓCIO as ferramentas de controle para
              habilitar e desabilitar clientes em seu ambiente de nuvem por meio
              de login e senha intransferíveis;
            </p>
          </li>

          <li>
            <p>
              Ser responsável pelo bom funcionamento do ambiente de nuvem e da
              Plataforma de Software para atendimento satisfatório ao cliente,
              com disponibilidade de acesso com índice igual ou superior a
              99,00% (noventa e nove por cento) do mês;
            </p>
          </li>

          <li>
            <p>
              Prestar ao PARCEIRO DE NEGÓCIO o suporte técnico e comercial
              necessário para a comercialização da Plataforma;
            </p>
          </li>

          <li>
            <p>
              Fornecer ao PARCEIRO DE NEGÓCIO meios para treinamento no uso e
              parametrização das ferramentas da plataforma sempre que
              necessário.
            </p>
          </li>

          <li>
            <p>
              Esforçar-se para responder o mais rápido possível a todas as
              dúvidas razoáveis levantadas pelo PARCEIRO DE NEGÓCIO ou Clientes
              em relação ao uso técnico ou aplicação do Produto;
            </p>
          </li>

          <li>
            <p>
              Fornecer ao PARCEIRO DE NEGÓCIO prontamente todas as informações e
              assistência necessárias para permitir que o PARCEIRO DE NEGÓCIO
              execute adequadamente suas obrigações aqui estabelecidas em
              relação a qualquer versão modificada, aprimorada ou de
              substituição ou adição ao Produto.
            </p>

            <ol class="numbered-list">
              <li>
                <p>
                  A INTERFY deverá disponibilizar ao PARCEIRO DE NEGÓCIO o login
                  e a senha que deve ficar sob seu poder para administrar os
                  espaços de trabalho e todas as ações que são permitidas neste
                  ambiente, estando ciente de que o login recebido tem plenos
                  poderes para realizar todas as ações que estão disponíveis no
                  ambiente, incluindo a solicitação de exclusão de bancos de
                  dados de produção.
                </p>
              </li>

              <li>
                <p>
                  A INTERFY poderá, a seu critério, divulgar o nome, telefone,
                  e-mail, contato e logotipo do PARCEIRO DE NEGÓCIO no site e
                  material publicitário durante a vigência do contrato.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>OBRIGAÇÕES GERAIS DO PARCEIRO DE NEGÓCIO:</b></p>
        <p>
          O Parceiro da categoria INTERFY PARTNER deverá estar sujeito às
          obrigações específicas e previstas neste contrato:
        </p>

        <ol class="numbered-list">
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve gerenciar seu ambiente de nuvem com
              excelência, não criando nem permitindo a criação de Workspaces sem
              que haja uma real demanda de negócios por serviço;
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO compromete-se a pagar as mensalidades de
              consumo dos seus clientes na plataforma, acrescidas de eventuais
              taxas adicionais que os seus clientes possam ter;
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO concorda que não fará quaisquer
              representações ou criará quaisquer garantias, expressas ou
              implícitas, em relação à INTERFY e que tomará todas as medidas
              para garantir que seus funcionários, agentes e outros sob sua
              direção cumpram os termos e condições desta disposição e o
              presente contrato.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO não deve usar a marca em desacordo com os
              padrões definidos pela INTERFY; portanto, fornecerá os arquivos
              com as artes do material de marketing que podem ser produzidos
              para uso pelo PARCEIRO DE NEGÓCIO. Qualquer material que venha a
              ser produzido pelo PARCEIRO DE NEGÓCIO diferente dos modelos
              fornecidos deve passar pela aprovação prévia da INTERFY.
            </p>
          </li>
          <li>
            <p>
              Fornecer aos clientes suporte profissional e oportuno para o
              produto, incluindo, sem limitação, aconselhamento e assistência em
              relação à configuração e uso do produto;
            </p>
          </li>
          <li>
            <p>
              Informar prontamente a INTERFY de quaisquer fatos ou opiniões de
              que o PARCEIRO DE NEGÓCIO tenha conhecimento que possam ser
              relevantes em relação à exploração comercial do Produto e que
              sejam vantajosos ou desvantajosos para os interesses de tal
              exploração;
            </p>
          </li>
          <li>
            <p>
              Em todos os momentos, conduza seus negócios de maneira que reflita
              favoravelmente no Produto e no bom nome e reputação da INTERFY;
            </p>
          </li>
          <li>
            <p>
              Não participar, sozinho ou com outros, de quaisquer práticas
              ilegais, enganosas, ou antiéticas, incluindo, mas não se limitando
              a, depreciação do Produto da INTERFY ou outras práticas que podem
              ser prejudiciais para o Produto, INTERFY ou interesse público;
            </p>
          </li>
          <li>
            <p>
              Observe e cumpra todas as Leis dos Estados Unidos e Leis do Estado
              da Flórida, ordens, decretos, avisos, regras e regulamentos
              relativos ou de qualquer forma aplicáveis à venda e uso dos
              Produtos no Território;
            </p>
          </li>
          <li>
            <p>
              Não fazer quaisquer promessas ou representações ou dar quaisquer
              garantias ou indenizações em relação aos Produtos, exceto as que
              são fornecidas abaixo ou conforme expressamente autorizado pela
              INTERFY por escrito;
            </p>
          </li>
          <li>
            <p>
              Abster-se de fornecer o produto a qualquer pessoa sabendo que o
              produto não pode atender aos requisitos especificados dessa
              pessoa;
            </p>
          </li>
          <li>
            <p>
              Não fornecer ou recomendar qualquer equipamento de computador a um
              Cliente para uso em conjunto com o Produto, exceto para o
              equipamento que pode ser recomendado pela INTERFY ao PARCEIRO DE
              NEGÓCIOS de vez em quando;
            </p>
          </li>
          <li>
            <p>
              Fornecer à INTERFY tais relatórios, devoluções e outras
              informações relativas aos pedidos e pedidos projetados para o
              Produto e em relação aos Clientes, pois a INTERFY pode, de tempos
              em tempos, razoavelmente exigir;
            </p>
          </li>
          <li>
            <p>
              Periodicamente, conforme prático, informe a INTERFY por escrito
              sobre os desejos e observações dos clientes e potenciais clientes
              sobre os materiais do produto;
            </p>
          </li>
          <li>
            <p>
              Permitindo que a INTERFY e seus Agentes Autorizados, em todos os
              momentos razoáveis, entrem em contato com os clientes que usam a
              plataforma, a fim de verificar se o PARCEIRO DE NEGÓCIO está
              cumprindo com suas obrigações nos termos deste Acordo, que o
              PARCEIRO DE NEGÓCIO irrevogavelmente autoriza à INTERFY, seus
              funcionários e Agentes a entrar em tais instalações para tal fim
              durante a vigência deste.
            </p>
          </li>
          <li>
            <p>
              Nessa categoria o parceiro não tem meta mínima definida, e seu
              contrato terá validade de 1 ano, podendo ser renovado
              automaticamente por igual período através de aditivos acordado
              entre as partes e assinado digitalmente.
            </p>
          </li>
          <!-- <li>
            <p>
              Além dos benefícios especificados acima, o parceiro receberá mais
              10 usuários para comercialização, através do qual poderá gerar
              recursos para cobrir seus custos mensais de R$ 600,00. Portanto,
              só receberá faturas extras quando consumir além dos benefícios
              concedidos, incluindo esses 10 usuários extras. Com esse bônus, o
              parceiro pode conseguir um faturamento total de R$ 1.200,00
              mensais, o que é o dobro do valor comprometido com a mensalidade.
            </p>
          </li> -->
        </ol>
      </li>

      <li class="item">
        <p><b>MARKETING</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              O Parceiro concorda em desenvolver campanhas de marketing digital
              e divulgar sua URL de vendas para atrair clientes ao seu site,
              cumprindo as especificações e boas práticas de uso do marketing
              digital e preservando a reputação da marca INTERFY.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>DEMONSTRAÇÃO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              A INTERFY concede ao PARCEIRO DE NEGÓCIO acesso a um Sistema DEMO
              em uma Workspace, gratuitamente para uso em sua revenda e em
              demonstração para os clientes durante a vigência deste Acordo.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>TREINAMENTO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO DA CATEGORIA GOLD receberá acesso a
              Universidade INTERFY para que seus funcionários possam fazer
              treinamentos online e serem certificados na Plataforma, o que deve
              acontecer sempre que houver novas versões que são lançadas
              anualmente.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve manter sempre sua equipe treinada para
              oferecer treinamento aos clientes de acordo com as melhores
              práticas seguidas na indústria de software e de acordo com as
              políticas da INTERFY;
            </p>

            <ol class="numbered-list">
              <li>
                <p>
                  Fica acordado entre as partes, que os empregados, sócios,
                  colaboradores ou prepostos de cada uma das partes não tenham
                  vínculo empregatício com a outra, cabendo a cada signatário
                  deste contrato a responsabilidade única e exclusiva pelo
                  pagamento dos encargos trabalhistas e contribuições sociais de
                  segurança de seus respectivos funcionários e / ou terceiros.
                </p>
              </li>
              <li>
                <p>
                  Cada parte assume neste ato, total e irrestrita
                  responsabilidade pelas reclamações trabalhistas relacionadas
                  ao objeto deste contrato, eventualmente propostas por seus
                  empregados em face da outra parte.
                </p>
              </li>
              <li>
                <p>
                  Cada parte concorda e se compromete a indenizar a outra parte
                  por todos e quaisquer custos incorridos devido a reclamações
                  trabalhistas eventualmente movidas por seus funcionários
                  contra a outra parte, incluindo, mas não se limitando a, o
                  valor da sentença, custas, custas judiciais e honorários
                  advocatícios.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>SUPORTE E ATUALIZAÇÃO DA PLATAFORMA INTERFY</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              A Plataforma INTERFY é baseada na Nuvem, e comercializada no
              formato SaaS (Software as a Service), sendo a INTERFY a única
              responsável pela atualização e evolução da plataforma e fornecerá
              ao PARCEIRO DE NEGÓCIO todo o suporte técnico necessário para o
              bom funcionamento da plataforma.
            </p>
          </li>
          <li>
            A INTERFY manterá o sistema em constante atualização, evoluindo-o de
            acordo com seu cronograma de melhorias e disponibilizará ao
            PARCEIRO, as novas funcionalidades, realizando, sempre que
            disponível, as atualizações da Plataforma no ambiente utilizado pelo
            PARCEIRO;
          </li>
          <li>
            A INTERFY deve fornecer suporte técnico e comercial ao PARCEIRO DE
            NEGÓCIOS gratuitamente, diretamente ou por meio de terceiros por ela
            indicados, para esclarecer dúvidas e fornecer orientações sobre a
            comercialização e implementação da Plataforma.
          </li>
          <li>
            O suporte gratuito da INTERFY ao PARCEIRO DE NEGÓCIO refere-se ao
            suporte remoto/online durante o horário comercial, das 9h00 às 17h00
            (horário do leste dos Estados Unidos), nos dias úteis, para
            solucionar problemas e orientar a utilização do aplicativo para o
            seu bom funcionamento, portanto não incluindo suporte para processos
            e customizações e integrações feitas pelo PARCEIRO DE NEGÓCIO, esses
            serviços devem estar sujeitos a um orçamento separado.
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>DIREITO SOBRE A PLATAFORMA INTERFY</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Nenhum direito de imprimir, copiar, exibir ou alterar o Software
              (a plataforma ou seus produtos), no todo ou em parte, é concedido,
              exceto conforme expressamente previsto neste Acordo.
            </p>
          </li>
          <li>
            <p>
              Nenhuma propriedade é concedida a quaisquer direitos de
              propriedade intelectual relacionados ao Software.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO não pode desmontar, extrair qualquer
              código-fonte ou fazer engenharia reversa do Software.
            </p>
          </li>
          <li>
            <p>
              A INTERFY é a única desenvolvedora da Plataforma e vende ao seu
              cliente final através do PARCEIRO DE NEGÓCIO ou diretamente,
              portanto, uma vez aderido a este instrumento contratual, o
              PARCEIRO DE NEGÓCIO fica por si e seus representantes obrigados a
              respeitar a política comercial e de serviços aplicada pela
              INTERFY.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO concorda em incluir quaisquer avisos,
              incluindo avisos de propriedade, avisos de direitos autorais e
              legendas de direitos restritos exibidos no Software, ou fornecidos
              ao longo do tempo pela INTERFY em quaisquer cópias que possam ser
              disponibilizadas a critério do fornecedor.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>CORREÇÕES E MODIFICAÇÕES</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve notificar imediatamente a INTERFY de
              qualquer erro ou defeito no Produto de que tome conhecimento e
              fornecer à INTERFY exemplos documentados de tal erro ou defeito.
            </p>
          </li>
          <li>
            <p>
              A INTERFY deve, no prazo de 5 dias úteis após o recebimento de tal
              notificação, avaliar o erro ou defeito notificado e fornecer ao
              PARCEIRO DE NEGÓCIO uma estimativa do tempo que levará para emitir
              uma atualização do Produto, que é afetado por tal erro ou defeito.
              A INTERFY deve envidar todos os esforços razoáveis para fornecer
              qualquer atualização dentro do prazo estimado.
            </p>
          </li>
          <li>
            <p>
              Dentro do período de 15 dias úteis referido no parágrafo 16.2, a
              INTERFY deve dedicar seus melhores esforços a fim de fornecer ao
              PARCEIRO DE NEGÓCIO uma solução de substituição ou de contorno
              para o erro ou defeito.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>CONFIDENCIALIDADE</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Ambas as Partes concordam em consideram como confidenciais
              quaisquer informações ou dados divulgados à outra Parte nos termos
              do presente Contrato, por escrito ou oralmente, que tenham sido
              designados à outra Parte por escrito como confidenciais ou que
              sejam tratados como confidenciais pela outra Parte de acordo com
              as práticas normais na indústria de informática (doravante
              denominada "Informação Confidencial").
            </p>
          </li>
          <li>
            <p>As Partes concordam que as Informações Confidenciais devem:</p>
          </li>
          <li>
            <p>
              Ser protegidos e mantidos em estrita confidencialidade pelas
              Partes, que devem usar o mesmo grau de precaução e salvaguarda que
              são usados para proteger suas próprias informações proprietárias
              de importância semelhante, mas em nenhum caso menos do que o
              cuidado razoável;
            </p>
          </li>
          <li>
            <p>
              Não ser usado, no todo ou em parte, para qualquer finalidade
              diferente da finalidade deste Contrato sem o consentimento prévio
              por escrito da outra Parte;
            </p>
          </li>
          <li>
            <p>
              Nem ser copiado, nem de outra forma reproduzido, nem duplicado no
              todo ou em parte, onde tal cópia, reprodução ou duplicação não
              tenha sido especificamente autorizada por escrito pela Parte a que
              esta Informação Confidencial pertence.
            </p>
          </li>
          <li>
            <p>
              Quaisquer documentos ou meios de informação que contenham
              Informações Confidenciais e suas cópias divulgadas pela INTERFY ao
              PARCEIRO DE NEGÓCIO permanecerão propriedade da INTERFY e serão
              devolvidos à INTERFY imediatamente após a rescisão deste Acordo.
            </p>
          </li>
          <li>
            <p>
              Nenhuma das Partes terá obrigações ou restrições com relação a
              qualquer Informação Confidencial que possa provar:
            </p>
          </li>
          <li>
            <p>
              Tornou-se domínio público antes ou após a divulgação e, nesse
              caso, sem nenhum ato ilícito da Parte;
            </p>
          </li>
          <li>
            <p>
              Já é do conhecimento da Parte, conforme evidenciado por
              documentação escrita em arquivos dessa Parte;
            </p>
          </li>
          <li>
            <p>
              Foi legalmente recebido de um terceiro sem restrições ou violação
              deste Acordo ou de um acordo de confidencialidade com a Parte à
              qual pertencem as Informações Confidenciais;
            </p>
          </li>
          <li>
            <p>Foi ou é publicado sem violação deste Acordo, ou</p>
          </li>
          <li>
            <p>
              É aprovado para liberação ou uso por autorização por escrito da
              Parte a que pertencem as Informações Confidenciais.
            </p>
          </li>
          <li>
            <p>
              Se o PARCEIRO DE NEGÓCIO tomar conhecimento de qualquer violação
              de confidencialidade com relação às Informações Confidenciais por
              qualquer um de seus funcionários, ele deverá notificar
              imediatamente a INTERFY e fornecer à INTERFY toda a assistência
              razoável em conexão com qualquer processo que a INTERFY possa
              instituir contra tais pessoas.
            </p>
          </li>
          <li>
            <p>
              O próprio PARCEIRO DE NEGÓCIO, seus colaboradores e qualquer
              pessoa que tenha acesso aos seus escritórios e demais dependências
              ou quaisquer informações de sua propriedade estão obrigados a
              manter em sigilo as informações técnicas, comerciais e de
              plataforma, especialmente aquelas relacionadas à documentação
              técnica e informações recebidas da INTERFY e acesso ao Ambiente de
              Administração de Parceiros, que em nenhuma hipótese poderá ser
              compartilhado com terceiros externos ao negócio, além de
              informações reveladas em reuniões, demonstrações, webinars,
              correspondências ou quaisquer outras informações. Outros materiais
              aos quais você tenha acesso, bem como as informações que o
              PARCEIRO DE NEGÓCIO venha a ter conhecimento em virtude e/ou na
              execução deste contrato.
            </p>
          </li>
          <li>
            <p>
              As disposições desta Seção sobreviverão à rescisão, por qualquer
              motivo, deste Acordo.
            </p>
          </li>
          <li>
            <p>
              Havendo quebra de sigilo, o PARCEIRO DE NEGÓCIO obriga-se a
              ressarcir a INTERFY por quaisquer perdas ou danos que venha a
              sofrer com o descumprimento desta cláusula, sem prejuízo da
              rescisão contratual.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>NÃO COMPETIÇÃO</b></p>

        <p>
          O PARCEIRO DE NEGÓCIO concorda que, durante a vigência deste Acordo
          (seja sozinho ou em conjunto e seja direta ou indiretamente), deverá:
        </p>

        <ol class="numbered-list">
          <li>
            <p>
              Não estar preocupado ou interessado no desenvolvimento,
              comercialização, distribuição, licenciamento ou venda de qualquer
              Produto Competitivo, que possa ser adquirido pelo PARCEIRO DE
              NEGÓCIOS por meio de aquisição de boa-fé de um terceiro, apenas
              nas situações em que o PARCEIRO DE NEGÓCIO tenha que cumprir
              ordens de compra para clientes que devem solicitar especificamente
              (e sem incentivo do PARCEIRO DE NEGÓCIO) tais outros sistemas,
              caso em que o PARCEIRO DE NEGÓCIO concorda que deve sempre dedicar
              seus melhores esforços para induzir esses clientes a migrar para
              as tecnologias da Interfy;
            </p>
          </li>
          <li>
            <p>
              Não exibir, demonstrar e de outra forma representar os produtos de
              forma justa em comparação com quaisquer produtos da concorrência;
            </p>
          </li>
          <li>
            <p>
              Encaminhar prontamente à INTERFY quaisquer consultas que ela possa
              receber sobre os Produtos de pessoas situadas fora do Território;
            </p>
          </li>
          <li>
            <p>
              Não entrar em qualquer contrato de licenciamento e suporte para o
              Software com qualquer cliente potencial fora do território
              autorizado (seu país de origem);
            </p>
          </li>
          <li>
            <p>
              Não contratar ou de outra forma solicitar qualquer funcionário ou
              representante da INTERFY ou qualquer de suas subsidiárias, por um
              período de 1 ano a partir da data em que tal funcionário ou
              representante deixar a INTERFY; nenhuma das partes deve induzir ou
              recomendar a qualquer funcionário ou representante para deixar a
              outra parte.
            </p>
          </li>
          <li>
            <p>
              A INTERFY concorda que o PARCEIRO DE NEGÓCIO deve, ao longo do
              prazo, ser tratado de forma equitativa tendo em conta as relações
              jurídicas e comerciais existentes ou futuras entre a INTERFY e
              outros PARCEIROS COMERCIAIS com respeito a Produtos Não
              Exclusivos.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO concorda em informar ativamente a INTERFY de
              quaisquer oportunidades comerciais em perspectiva com terceiros
              com o objetivo de favorecer a conclusão de joint ventures ou tipo
              semelhante de acordos comerciais com tais terceiros para a
              comercialização e venda dos produtos de ambas as empresas,
              INTERFY, e o Parceiro de Negócios em todo o mundo.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>DIREITOS DE PROPRIEDADE</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Os Materiais do Produto e o Código-fonte e os Direitos de
              Propriedade Intelectual neles ou relacionados a eles são e devem
              permanecer propriedade exclusiva da INTERFY em todo o Território e
              nenhum título sobre eles deve passar para o PARCEIRO DE NEGÓCIO ou
              para seus Clientes. Todas as cópias de documentos, métodos,
              processos ou qualquer outro tipo de material na posse, custódia ou
              controle do PARCEIRO DE NEGÓCIO devem ser devolvidos à INTERFY ou
              de outra forma dispostos pelo PARCEIRO DE NEGÓCIO como a INTERFY
              pode, de tempos em tempos, direcionar, no final deste acordo.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO reconhece e concorda que os Materiais do
              Produto e o Código Fonte, incluindo, sem limitação, o Produto,
              interface do usuário, formatos de exibição do monitor, módulos e
              técnicas de programação desenvolvidos e usados nos Materiais do
              Produto e no Código Fonte, e todas as melhorias, modificações e
              atualizações feitas pela INTERFY constituem propriedade exclusiva
              da INTERFY, quer qualquer parte dela seja ou não protegida por
              direitos autorais ou patenteada e que o título e a propriedade
              total permanecerão em todos os momentos exclusivamente na INTERFY.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve notificar a INTERFY imediatamente se o
              PARCEIRO DE NEGÓCIO tomar conhecimento de qualquer uso não
              autorizado de qualquer um dos Materiais do Produto ou do
              Código-fonte relacionado ao Produto ou qualquer um dos Direitos de
              Propriedade Intelectual nele ou relacionados aos mesmos e deve
              ajudar a INTERFY, às custas da INTERFY, em tomar todas as medidas
              para proteger ou defender os direitos da INTERFY nisso.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO não deve, em nenhum momento, expressa ou
              implicitamente reivindicar qualquer direito, título ou interesse
              nos Materiais do Produto ou no Código-fonte, nem fingir adquirir
              ou ter adquirido qualquer Direito de Propriedade Intelectual nos
              Materiais do Produto ou no Código-fonte de terceiros.
            </p>
          </li>
          <li>
            <p>
              As disposições desta Seção sobreviverão à rescisão por qualquer
              motivo deste Acordo.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>MARCAS</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Sujeito às restrições estabelecidas abaixo, o PARCEIRO DE NEGÓCIO
              terá o direito de usar as Marcas registradas durante a vigência
              deste Acordo apenas em conexão com o uso e comercialização dos
              Produtos e Serviços.
            </p>
          </li>
          <li>
            <p>
              O uso das marcas registradas deve estar sujeito às restrições e
              padrões razoáveis que a INTERFY pode adotar de tempos em tempos.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO não deverá usar as Marcas de forma alguma
              após o término deste Acordo.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>REPRESENTAÇÕES E GARANTIAS DA INTERFY</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              A INTERFY representa e garante que é a única e exclusiva
              proprietária legal e benéfica de todos os Direitos de Propriedade
              Intelectual em e para os Materiais do Produto e do Código Fonte,
              exceto para qualquer parte dos Materiais do Produto para os quais
              a INTERFY foi licenciada por terceiros e, nesse caso, essa INTERFY
              tem o direito de conceder ao PARCEIRO DE NEGÓCIO nos termos deste
              documento com relação a essa parte licenciada do Produto. A
              INTERFY ainda declara e garante que não está ciente de qualquer
              reivindicação existente, potencial ou conflitante de propriedade
              dos Direitos de Propriedade Intelectual ou de qualquer parte
              deles.
            </p>
          </li>
          <li>
            <p>
              A INTERFY deve defender e isentar o PARCEIRO DE NEGÓCIO contra
              qualquer reclamação de que o Produto infringe qualquer Direito de
              Propriedade Intelectual de terceiros e a INTERFY deve pagar
              quaisquer custos resultantes, danos e taxas legais razoáveis
              incorridas, desde que:
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO notifica prontamente a INTERFY por escrito
              de qualquer reclamação; e
            </p>
          </li>
          <li>
            <p>
              A INTERFY tem controle exclusivo da defesa e de todas as
              negociações de acordo relacionadas, desde que o PARCEIRO DE
              NEGÓCIOS possa envolver seus próprios advogados para auxiliar em
              tal esforço, às suas próprias custas.
            </p>
          </li>
          <li>
            <p>
              A INTERFY não terá nenhuma obrigação de defender o PARCEIRO DE
              NEGÓCIOS ou de fazer qualquer pagamento de custos, danos, taxas
              legais ou de outra forma para qualquer reclamação baseada:
            </p>
          </li>
          <li>
            <p>
              A combinação, operação ou uso do Produto com qualquer hardware,
              Produto, equipamento ou processo não especificado pela INTERFY ou
              aprovado pela INTERFY se tal violação tivesse sido evitada, mas
              para tal combinação, operação ou uso.
            </p>
          </li>
          <li>
            <p>
              Estão excluídos das garantias da INTERFY qualquer defeito do
              Produto resultante de falhas na conexão do cliente à Internet,
              incluindo a falta de capacidade em relação ao uso do software.
            </p>
          </li>
          <li>
            <p>
              Para mais certeza, a INTERFY não terá qualquer responsabilidade ou
              responsabilidade de qualquer natureza sob este Acordo para
              quaisquer Modificações de PARCEIRO DE NEGÓCIO.
            </p>
          </li>
          <li>
            <p>
              EM NENHUMA HIPÓTESE A INTERFY TERÁ QUALQUER RESPONSABILIDADE PARA
              COM O PARCEIRO DE NEGÓCIO OU SEUS CLIENTES POR PERDA (DIRETA OU
              INDIRETA) DE LUCROS, PERDA DE RECEITA DE NEGÓCIOS OU FALHA EM
              REALIZAR AS ECONOMIAS ESPERADAS OU POR QUALQUER PERDA OU DANOS
              INDIRETOS, ESPECIAIS OU CONSEQUENTES, OU DANOS INDIRETOS,
              ESPECIAIS OU CONSEQUENTES NÃO SE LIMITANDO A, PERDA DE USO OU
              PERDA DE DADOS OU INFORMAÇÕES DE QUALQUER TIPO, NO ENTANTO
              CAUSADA, OU FALHA DE QUALQUER PRODUTO PARA TRABALHAR OU EXECUTAR
              DE QUALQUER FORMA, OU QUALQUER RESPONSABILIDADE PARA CLIENTES OU
              TERCEIROS, MESMO SE AVISADO DA POSSIBILIDADE OU RESULTANTE DE
              NEGLIGÊNCIA, VIOLAÇÃO DE CONTRATO OU DE OUTRA FORMA.
            </p>
          </li>
          <li>
            <p>
              EXCETO PARA AS GARANTIAS EXPRESSAS ACIMA, A INTERFY NÃO FAZ OUTRAS
              GARANTIAS OU CONDIÇÕES, EXPRESSAS OU IMPLÍCITAS. OS TERMOS
              EXPRESSOS NESTE CONTRATO SUBSTITUEM TODAS AS GARANTIAS, CONDIÇÕES,
              TERMOS, COMPROMISSOS E OBRIGAÇÕES IMPLÍCITAS POR ESTATUTO, Leis
              Comuns dos Estados Unidos e Leis Estaduais da Flórida, USO
              CUSTOMIZADO, USO COMERCIAL, CURSO DE NEGOCIAÇÃO OU OUTRO, ATÉ A
              PERMISSÃO PERMANENTE DE ORIGEM DO PARCEIRO DE NEGÓCIO. INTERFY
              INTERNATIONAL LLC. AQUI EXCLUI TODAS E QUAISQUER GARANTIAS OU
              CONDIÇÕES IMPLÍCITAS DE COMERCIABILIDADE, QUALIDADE COMERCIAL OU
              ADEQUAÇÃO PARA QUALQUER PROPÓSITO PARTICULAR, ESPECÍFICO OU
              OUTROS, ATÉ O LIMITE MÁXIMO PERMITIDO PELO PAÍS PARCEIRO.
            </p>
          </li>
          <li>
            <p>
              As disposições anteriores estabelecem os recursos exclusivos do
              PARCEIRO DE NEGÓCIO e, em nenhum caso, a responsabilidade da
              INTERFY por qualquer reclamação excederá os valores recebidos pela
              INTERFY nos termos deste documento com relação à Licença ou outro
              contrato sob o qual o Produto ou Serviço deu origem a tal
              reclamação, mesmo se a INTERFY for avisada da possibilidade de
              tais danos.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>INDENIZAÇÃO DO PARCEIRO DE NEGÓCIO</b></p>
        <ol class="numbered-list">
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve isentar de responsabilidade e indenizar
              a INTERFY, e manter a INTERFY total e efetivamente indenizada sob
              demanda, de e contra todas e quaisquer perdas, reclamações, danos,
              custos, encargos, despesas, responsabilidades (incluindo
              honorários advocatícios razoáveis) resultantes de ou devido a
              reivindicações, demandas, processos ou ações que a INTERFY pode
              sustentar ou incorrer, ou que podem ser instaurados ou instaurados
              contra ela por qualquer pessoa e que, em qualquer caso, surjam de
              ou em relação a ou em razão de:
            </p>
          </li>
          <li>
            <p>
              Qualquer violação por parte do PARCEIRO DE NEGÓCIO de suas
              obrigações nos termos deste Contrato; ou
            </p>
          </li>
          <li>
            <p>
              Qualquer ação não autorizada ou omissão do PARCEIRO DE NEGÓCIO,
              Agentes ou funcionários dos mesmos; ou
            </p>
          </li>
          <li>
            <p>
              A maneira pela qual o PARCEIRO DE NEGÓCIO comercializa o Produto;
            </p>
          </li>
          <li>
            <p>
              O fornecimento independente pelo PARCEIRO DE NEGÓCIO de quaisquer
              Produtos ou Serviços para uso em conjunto com ou em relação ao
              Produto, ou
            </p>
          </li>
          <li>
            <p>Quaisquer modificações de PARCEIRO DE NEGÓCIO.</p>
          </li>
          <li>
            <p>
              Se qualquer reclamação for feita contra a INTERFY para a qual é
              pedida indenização nos termos do parágrafo 22.1, a INTERFY deve
              consultar o PARCEIRO DE NEGÓCIO e, sujeito a ser garantida a sua
              satisfação razoável, deve cooperar com o PARCEIRO DE NEGÓCIO em
              relação a qualquer pedido razoável feito pelo PARCEIRO DE NEGÓCIO
              em relação a tal reclamação.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>TERMINAÇÃO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Este Contrato será automaticamente rescindido imediatamente após a
              ocorrência de qualquer um dos seguintes eventos de inadimplemento,
              a critério e critério da Parte não inadimplente:
            </p>
          </li>
          <li>
            <p>
              Se qualquer Parte se tornar geralmente incapaz de pagar suas
              dívidas no vencimento, admite por escrito. <br />Sua incapacidade
              de pagar suas dívidas em geral, faz uma cessão em benefício de
              seus credores;
            </p>
          </li>
          <li>
            <p>
              Se qualquer processo for instaurado contra qualquer Parte que
              busque julgá-la como falida ou insolvente ou que busque a
              liquidação, liquidação, reorganização, arranjo, ajuste, proteção,
              alívio ou composição dela ou de suas dívidas de acordo com as leis
              dos Estados Unidos relativas à falência, insolvência ou
              reorganização ou alívio de devedores ou que buscam a entrada de
              uma ordem de alívio ou a nomeação de um administrador judicial,
              fiduciário, custodiante ou outro oficial semelhante para ele ou
              para qualquer parte substancial de sua propriedade, a menos que em
              tal caso, tais procedimentos sejam contestados de boa-fé por tal
              Parte e qualquer ordem, nomeação ou outra reparação é suspensa
              enquanto se aguarda o resultado do processo contestado e tal
              ordem, nomeação ou outra reparação ou rescindida dentro de 10
              dias;
            </p>
          </li>
          <li>
            <p>
              Se qualquer Parte tirar vantagem de qualquer lei dos Estados
              Unidos relativa à falência, insolvência ou, em geral, alívio de
              devedores.
            </p>
          </li>
          <li>
            <p>
              Este Acordo pode ser rescindido pela INTERFY, a seu critério, se o
              PARCEIRO DE NEGÓCIO violar as disposições relativas ao pagamento
              do Preço de Compra e não remediar sua inadimplência no prazo de 10
              dias após ter recebido da INTERFY um aviso especificando o padrão
              e exigindo que seja remediado.
            </p>
          </li>
          <li>
            <p>
              Este Acordo pode ser rescindido por qualquer Parte, a seu
              critério, dando à Parte inadimplente um aviso de rescisão se a
              outra Parte estiver inadimplente em qualquer outra disposição
              deste Contrato e se a Parte inadimplente não remediar tal
              inadimplência (se tal inadimplência é capaz de ser reparada)
              dentro de 10 dias após ter recebido da Parte não inadimplente
              notificação especificando a inadimplência e exigindo que seja
              corrigida.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>EFEITO DE RESCISÃO</b></p>

        <p>Após a rescisão ou expiração deste Acordo:</p>

        <ol class="numbered-list">
          <li>
            <p>
              Todos os direitos e obrigações das Partes nos termos deste
              Contrato serão automaticamente rescindidos, exceto:
            </p>
          </li>
          <li>
            <p>
              Para os direitos de ação que devem ter acumulado antes dessa
              rescisão e quaisquer obrigações que expressamente ou por
              implicação se destinam a entrar ou continuar em vigor em ou após
              essa rescisão;
            </p>
          </li>
          <li>
            <p>
              Que os termos deste Contrato permanecerão em pleno vigor e efeito
              apenas na medida e pelo período necessário para permitir que o
              PARCEIRO DE NEGÓCIO execute adequadamente suas obrigações
              contínuas de acordo com a Licença ou os Contratos de Serviço
              existentes na data de rescisão e que não tenham sido transferidos
              para a INTERFY de acordo com a Seção 25 (e as obrigações do
              PARCEIRO DE NEGÓCIO nos termos dos parágrafos 25.1 e 25.2 abaixo
              serão suspensas durante o período em que essas obrigações
              continuadas subsistirem);
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO eliminará imediatamente de toda a sua
              literatura, papelaria comercial, publicações, avisos e anúncios
              todas as referências ao título "PARCEIRO DE NEGÓCIO AUTORIZADO DA
              INTERFY" e todas as outras representações da nomeação do PARCEIRO
              DE NEGÓCIOS nos termos deste.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deverá, às suas próprias custas, devolver
              imediatamente para a INTERFY ou dispor de outra forma como a
              INTERFY pode instruir todos os materiais promocionais e outros
              documentos e papéis enviados para o PARCEIRO DE NEGÓCIO e
              relacionados aos negócios da INTERFY (exceto correspondência entre
              as Partes), todas as propriedades da INTERFY e todas as cópias dos
              Materiais do Produto e do Código-fonte relacionados ao Produto,
              estando em posse do PARCEIRO DE NEGÓCIO ou sob seu controle.
            </p>
          </li>
          <li>
            <p>
              Se o evento de inadimplência que dá origem à rescisão for pelo
              PARCEIRO DE NEGÓCIO, todas as quantias devidas à INTERFY pelo
              PARCEIRO DE NEGÓCIO no momento da rescisão tornar-se-ão
              imediatamente devidos e pagáveis à INTERFY.
            </p>
          </li>
          <li>
            <p>
              Cada Licença ou Contrato de Serviço então existente deve continuar
              em vigor e sobreviver à rescisão deste Contrato, sujeito às
              disposições da Seção 25.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p>
          <b>DESEMPENHO DAS OBRIGAÇÕES DECORRENTES DO ACORDO APÓS RESCISÃO</b>
        </p>

        <p>
          As partes concordam que após a rescisão deste Contrato por qualquer
          motivo:
        </p>

        <ol class="numbered-list">
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO concorda irrevogavelmente em transferir, sem
              nenhum custo, todos os direitos e obrigações sob qualquer Licença
              ou Contrato de Serviço selecionado pela INTERFY, por meio de
              cessão ou por qualquer outro meio ou operação legal que possa
              estar disponível de acordo com as Leis dos Estados Unidos e as
              Leis do Estado da Flórida aplicáveis a tais Licenças ou Acordos de
              Serviço conforme a INTERFY possa determinar, desde que após tal
              transferência o PARCEIRO DE NEGÓCIO não tenha mais obrigações para
              com os Clientes com relação ao desempenho das obrigações do
              PARCEIRO DE NEGÓCIO após tal transferência ou que a INTERFY ou
              qualquer terceiro para o benefício de quem tal transferência será
              efetuada garante ao PARCEIRO DE NEGÓCIO contra qualquer reclamação
              decorrente de não cumprimento ou cumprimento defeituoso de tais
              obrigações (a "Transferência").
            </p>
          </li>
          <li>
            <p>
              Após a data da transferência, a INTERFY, ou qualquer terceiro
              designado pela INTERFY, deve cumprir todas as obrigações do
              PARCEIRO DE NEGÓCIO com relação a qualquer Licença ou Contrato de
              Serviço assim transferido e será responsável por quaisquer
              reclamações do Cliente baseadas em não cumprimento ou cumprimento
              defeituoso de tais obrigações pela INTERFY ou terceiros após a
              data da Transferência relevante.
            </p>
          </li>
          <li>
            <p>
              O PARCEIRO DE NEGÓCIO deve dar à INTERFY ou a tal terceiro que a
              INTERFY possa indicar, toda a cooperação razoável na transferência
              dos direitos e obrigações do PARCEIRO DE NEGÓCIO sob tal Licença e
              Contrato de Serviço para a INTERFY ou tal terceiro.
            </p>
          </li>
          <li>
            <p>
              Para mais certeza, a INTERFY não terá nenhuma obrigação de aceitar
              a transferência de qualquer licença ou contrato de serviço
              relacionado a qualquer produto ou serviço de terceiros (incluindo
              outros produtos pertencentes ou distribuídos pelo PARCEIRO DE
              NEGÓCIOS ou produtos que foram combinados com quaisquer produtos
              da INTERFY).
            </p>
          </li>
          <li>
            <p>
              A menos que este Acordo tenha sido rescindido pela INTERFY por
              motivo de inadimplência do PARCEIRO DE NEGÓCIO que não foi curado
              na satisfação da INTERFY dentro dos atrasos fornecidos, a INTERFY
              deve continuar a fornecer ao PARCEIRO DE NEGÓCIO suporte e
              serviços de manutenção que incumbem à INTERFY de acordo com as
              Condições Padrões da INTERFY.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>RELACIONAMENTO JURÍDICO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Ao dar cumprimento a este Contrato, nenhuma das Partes será ou
              será considerada um Agente ou funcionário da outra para qualquer
              finalidade, e que sua relação entre si será a de contratantes
              independentes. Nada neste Contrato constituirá uma parceria ou
              joint venture entre as Partes. Nenhuma das partes terá o direito
              de celebrar contratos ou penhorar o crédito ou incorrer em
              despesas de responsabilidades em nome da outra.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>TAREFA</b></p>

        <p>
          O PARCEIRO DE NEGÓCIO não pode ceder quaisquer direitos ou benefícios
          deste Acordo a qualquer pessoa. O PARCEIRO DE NEGÓCIO concorda em
          cumprir suas obrigações nos termos do presente Acordo e não
          providenciar de forma alguma para que qualquer outra Pessoa (exceto
          seus funcionários) cumpra essas obrigações, exceto conforme
          expressamente permitido aqui. Nenhuma atribuição de benefícios ou
          arranjo para desempenho substituído pelo PARCEIRO DE NEGÓCIO terá
          qualquer efeito contra a INTERFY, exceto na medida em que tenha
          expressamente autorizado nos termos deste instrumento ou consentido
          por escrito.
        </p>
      </li>

      <li class="item">
        <p><b>INTERPRETAÇÃO</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Qualquer obrigação específica que incumba a uma Parte ou às Partes
              de acordo com as disposições deste acordo deve ser lida e
              interpretada em conjunto com os termos deste instrumento. Em caso
              de ambiguidade, inconsistência ou incompatibilidade entre qualquer
              disposição deste instrumento e qualquer disposição contida neste
              contrato, a disposição que for mais específica prevalecerá sobre a
              disposição que é mais geral na medida de qualquer ambiguidade,
              inconsistência ou incompatibilidade, conforme for o caso.
            </p>
          </li>
        </ol>
      </li>

      <li class="item">
        <p><b>GERAL</b></p>

        <ol class="numbered-list">
          <li>
            <p>
              Qualquer notificação, demanda ou outra comunicação (nesta seção,
              uma "notificação") exigida ou permitida a ser fornecida ou feita
              nos termos deste instrumento deve ser feita por e-mail durante o
              horário comercial normal de um Dia Útil:<br />Cada notificação
              enviada de acordo com esta Seção será considerada recebida se
              entregue durante o horário comercial normal do destinatário, na
              hora do dia em que foi entregue, ou no início do expediente no
              primeiro Dia Útil seguinte, se o dia em que foi entregue não era
              um Dia Útil; ou uma hora depois de ter sido enviada na mesma data,
              foi enviada por Transmissão Eletrônica, ou no início dos negócios
              no primeiro Dia Útil seguinte se o dia em que foi enviada por
              Transmissão Eletrônica não fosse um Dia Útil.<br />Qualquer Parte
              pode alterar seu endereço para notificação, mediante notificação à
              outra Parte, conforme disposto nesta Seção.
            </p>
          </li>
          <li>
            <p>O tempo é a essência de cada disposição deste Acordo.</p>
          </li>
          <li>
            <p>
              Se qualquer ação for necessária de acordo com este Contrato em ou
              até uma data especificada, que não seja um Dia Útil, tal ação será
              válida se tomada no próximo Dia Útil subsequente.
            </p>
          </li>
          <li>
            <p>
              Este Contrato será regido, interpretado e executado de acordo com
              as Leis dos Estados Unidos em vigor no Estado da Flórida
              (excluindo qualquer conflito das Leis dos Estados Unidos e regra
              ou princípio das Leis do Estado da Flórida que possam referir tal
              construção às Leis dos Estados Unidose Leis do Estado da Flórida
              de outra jurisdição) e devem ser tratados em todos os aspectos
              como um contrato do Estado da Flórida.
            </p>
          </li>
          <li>
            <p>
              Este Acordo, juntamente com quaisquer acordos e outros documentos
              a serem entregues nos termos ou concomitantemente a este,
              constitui o acordo integral entre as Partes no que diz respeito ao
              assunto aqui tratado e substitui todos os acordos, negociações,
              discussões e entendimentos anteriores, escritos ou orais, entre as
              Partes. Não há representações, garantias, condições, outros
              acordos ou reconhecimentos, sejam diretos ou colaterais, expressos
              ou implícitos, e fazem parte ou afetam este Acordo. A execução
              deste Acordo não foi induzida por, nem qualquer uma das Partes
              conta ou considera como material, quaisquer representações,
              garantias, condições, outros acordos ou reconhecimentos não
              expressamente feitos neste Acordo ou nos acordos e outros
              documentos a serem entregue nos termos deste.
            </p>
          </li>
          <li>
            <p>
              Cada Parte fará tais atos e executará tais documentos adicionais e
              fará com que tais atos sejam praticados e causará a execução de
              todos os outros documentos que estiverem em seu poder, como a
              outra Parte poderá, por escrito, a qualquer momento e de tempos em
              tempos, fazer ou executar solicitações razoáveis, a fim de dar
              pleno efeito às disposições deste Acordo.
            </p>
          </li>
          <li>
            <p>
              Este Acordo pode ser alterado ou complementado apenas por um
              acordo escrito assinado por cada Parte.
            </p>
          </li>
          <li>
            <p>
              Qualquer renúncia ou consentimento para afastar-se dos requisitos
              de qualquer disposição do presente Acordo entrará em vigor apenas
              se for por escrito e assinado pela Parte que o concedeu, e apenas
              no caso específico e para o propósito específico para o qual foi
              dado. Nenhuma falha por parte de qualquer das Partes ou nenhum
              atraso no exercício de qualquer direito sob este Contrato deve
              operar como uma renúncia de tal direito. Nenhum exercício único ou
              parcial de qualquer desses direitos deve impedir qualquer outro
              exercício de tal direito ou o exercício de qualquer outro direito.
            </p>
          </li>
          <li>
            <p>
              Este Contrato pode ser executado em qualquer número de vias; cada
              contraparte executada será considerada um original; todas as
              contrapartes executadas em conjunto devem constituir um contrato.
            </p>
          </li>
          <li>
            <p>
              Exceto quando expressamente disposto de outra forma neste
              Contrato, as datas e horas em que qualquer Parte é obrigada a
              cumprir qualquer obrigação sob este Contrato, exceto o pagamento
              em dinheiro, serão adiadas automaticamente na medida e pelo
              período de tempo em que a Parte for impedido de fazê-lo por
              circunstâncias além de seu controle razoável. Tais circunstâncias
              devem incluir atos da natureza, greves, bloqueios, motins, atos de
              guerra, epidemias, regulamentações governamentais impostas após o
              fato, incêndio, falhas nas linhas de comunicação, falhas de
              energia, terremotos ou outros desastres. A Parte impedida de
              realizar o desempenho deve notificar a outra Parte imediatamente e
              em detalhes sobre o início e a natureza de tal circunstância e as
              prováveis consequência dela e outras obrigações de ambas as Partes
              afetadas por este atraso serão ajustadas em conformidade. Cada
              Parte cujo desempenho está atrasado deve envidar esforços
              razoáveis para cumprir suas obrigações em tempo hábil, deve
              empregar todos os recursos razoavelmente necessários nas
              circunstâncias e deve obter suprimentos ou serviços de outras
              fontes, se razoavelmente disponíveis.
            </p>
          </li>
          <li>
            <p>
              Este Acordo entrará em vigor e será vinculativo para as Partes
              deste e seus cessionários, representantes, herdeiros e sucessores
              a partir da data deste. Além disso, este Acordo será vinculativo e
              eficaz contra qualquer comprador ou cessionário de todos ou
              qualquer parte dos ativos, empreendimentos, propriedade, Produtos
              (exceto para Licenças para usar os Produtos concedidos no curso
              normal e normal dos negócios) ou ações (exceto aos funcionários,
              no âmbito de um plano de compra de ações) da INTERFY.
            </p>
          </li>
          <li>
            <p>
              As partes excluem expressamente a aplicação da Convenção dos
              Estados Unidos para a Venda Internacional de Mercadorias.
            </p>
          </li>
          <li>
            <p>
              Não obstante a data de assinatura, as Partes concordam que este
              Acordo será considerado como tendo sido celebrado na data formal
              de sua adesão pelo Parceiro através da página de credenciamento da
              INTERFY em:
              <a href="https://www.interfy.io/become-a-partner"
                target="_blank">https://www.interfy.io/become-a-partner</a>
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: ["business_partner"],
  inject: ["scrollto"],
};
</script>

<style scoped lang="scss"></style>