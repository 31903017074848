<template>
  <div style="min-height: 100vh;">        
    
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-process customerService d-flex flex-row justify-content-center align-items-center">

          <div class="col-lg-6 col-md-6 col-sm-12 mockup" v-if="window.width > 991">
            <img :src="`/assets/images/domains/${domain.name}/processes/customerService/mockup_01_${lang}.png`" :alt="$t('PROCESS_CUSTOMER_SERVI')">
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 div-text">
            <div class="header-main-process d-flex flex-row justify-content-start align-items-center">
              <div class='icon icon-shape customerService'>
                <img src="/assets/images/processes/icons/01.png" :alt="$t('PROCESS_CUSTOMER_SERVI')" />
                <img src="/assets/images/processes/icons/01_hover.png" :alt="$t('IF YOU WORK WI.....')" />
              </div>
              <h1 class="mb-4 title" v-html="$t('PROCESS_CUSTOMER_SERVI')"></h1>
            </div>
            <h2 class="subtitle" v-html="$t('IF YOU WORK WI.....')"></h2>
            
            <div class="div-buttons d-flex">
              <router-link class="btn customerService btn-medium-width" :to="`/start-free${partner_url}`">{{$t("START_FREE")}}</router-link>
              <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-4 px-3 btn customerService-alternative" :to="`/plans${partner_url}`">{{$t("BECOME_A_PARTNER")}}</router-link>
            </div>
          </div>

        </div>
    </transition>

    <img class="top-image" src="/assets/images/processes/top-01.png" :alt="`${domain.title}`">

    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description customerService">
          <div class="row">
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/customerService/icons/01.png" :alt="$t('SERVICE OF <BR.....')">
                  </div>                                    
                  <h2 v-html="$t('SERVICE OF <BR.....')"></h2>
                  <p v-html="$t('HOW TO CONTROL.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/customerService/icons/02.png" :alt="$t('STRUCTURING AN.....')">
                  </div>                                    
                  <h2 v-html="$t('STRUCTURING AN.....')"></h2>
                  <p v-html="$t('STRUCTURING TH.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/customerService/icons/03.png" :alt="$t('FOCUSED ON YOU.....')">
                  </div>                                    
                  <h2 v-html="$t('FOCUSED ON YOU.....')"></h2>
                  <p v-html="$t('WHILE EACH SEG.....')"></p>
              </div>
            </div>
          </div>

        </div>
    </transition>


    <transition name="slide-fade">
      <div class="section section-process-gains">  

        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 mockup">
            <img :src="`/assets/images/domains/${domain.name}/processes/customerService/mockup_02.png`" :alt="$t('WHAT DOES YOUR.....')">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h2 v-html="$t('WHAT DOES YOUR.....')"></h2>
            <p v-html="$t('GREATER ORGANI.....')"></p>
          </div>   
        </div>

        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="mt-5 row">
            <div class="mt-5 col-lg-4 col-md-4 col-sm-12">
              <ul>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('CENTRALIZATION.....')">
                  <p v-html="$t('CENTRALIZATION.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('AGILITY IN RES.....')">
                  <p v-html="$t('AGILITY IN RES.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('INCREASE IN CU.....')">
                  <p v-html="$t('INCREASE IN CU.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('ALLOWS CONTINU.....')">
                  <p v-html="$t('ALLOWS CONTINU.....')"></p>
                </li>            
              </ul>
            </div>
            <div class="mt-5 col-lg-4 col-md-4 col-sm-12">
              <ul>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('ASSIGNMENT OF .....')">
                  <p v-html="$t('ASSIGNMENT OF .....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('REDUCING THE P.....')">
                  <p v-html="$t('REDUCING THE P.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('EASY TO MANAGE.....')">
                  <p v-html="$t('EASY TO MANAGE.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('AVOID LOSS OF .....')">
                  <p v-html="$t('AVOID LOSS OF .....')"></p>
                </li>            
              </ul>
            </div>
            <div class="mt-5 col-lg-4 col-md-4 col-sm-12">
              <ul>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('OPTIMIZATION O.....')">
                  <p v-html="$t('OPTIMIZATION O.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('STANDARDIZATIO.....')">
                  <p v-html="$t('STANDARDIZATIO.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('INCREASED PROD.....')">
                  <p v-html="$t('INCREASED PROD.....')"></p>
                </li>
                <li>
                  <img src="/assets/images/processes/check.png" :alt="$t('COST REDUCTION.....')">
                  <p v-html="$t('COST REDUCTION.....')"></p>
                </li>            
              </ul>
            </div>
          </div> 
        </div>
      </div>
    </transition>

    <!-- 

        Join......

    -->  
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></Join>
    </transition>

    <transition name="slide-fade">
      <div class="section section-process-how-work">  
        <h2 v-html="$t('HOW TO WORK WI.....')"></h2>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="item">
              <img src="/assets/images/processes/customerService/icons/04.png" :alt="`${domain.title}`">
              <p v-html="$t('ITS SIMPLE! I.....')"></p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="item">
              <img src="/assets/images/processes/customerService/icons/05.png" :alt="`${domain.title}`">
              <p v-html="$t('THE AREA WILL .....')"></p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="item">
              <img src="/assets/images/processes/customerService/icons/06.png" :alt="`${domain.title}`">
              <p v-html="$t('THE REQUEST MA.....')"></p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    

    

    <!-- 

        Automate......

    -->  
    <transition name="slide-fade">             
        <AutomateProcesses :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></AutomateProcesses>
    </transition>

    <!-- 

        See also......

    -->  
    <transition name="slide-fade">             
        <OtherProcesses :partner_url="partner_url" v-if="show" :page="page" :path="path"></OtherProcesses>
    </transition>

    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="clients-bpm"></Clients>
    </transition> 

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import OtherProcesses from '../common/OtherProcesses'
import AutomateProcesses from '../common/AutomateProcesses'
import $ from 'jquery'

export default {
 
    components:{
      Clients,
      Join,
      ThePlatform,
      OtherProcesses,
      AutomateProcesses,      
    },
    props: {     
      isDomainPermitted:{
          type: Boolean,
          default: false
      }, 
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      },
      page: {
        type: String,
        default: ''
      },
      path: {
        type: String,
        default: ''
      },
      partner_url: {
          type: String,
          default: ''
      },
    },
    data(){
        return{            
            show: false,            
        }
    },
    computed: {

        ...mapState('MainStoreModule', {                                  
            domain: state => state.domain
        }),
        
        lang(){
          return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">

.section-process-how-work {
    padding: 0 10vw 6vw 10vw;
    h2 {
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }
    .item {
        background-color: #FDFDFD;
        border-radius: 20px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        text-align: center;
        &:hover {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
        }
        padding: 30px;
        width: 100%;
        height: 270px;
        img {
            height: 50px;
            margin-bottom: 20px;
        }
        p {
            width: 100%;
            text-align: center;
            font-size: 18px;
            line-height: 25px;
            font-weight: 500;
            max-height: 200px;
            margin: 0;
        }
    }
}


.section-process-gains {
    padding: 0 10vw 6vw 10vw;
    background-color: #F5F6FB;
    .mockup {
        img{
          width: 100%;
        }
    }
    h2 {
        margin-bottom: 40px;
        width: 100%;
        text-align: center;
    }
    p {
        width: 100%;
        text-align: center;
    }
    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 25px;
            img {
                height: 22px;
                margin-right: 12px;
            }
            p {
                font-weight: 500;
                font-size: 17px !important;
                line-height: 20px !important;
                margin: 0;
                text-align: left;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
  
  
  .section-process-how-work,
  .section-process-gains,
  .section-blocks-description{
      .row{
          margin: 0 !important;
      }
  }  

  .section-process-gains{

    .mockup {
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        position: relative;
        align-self: center;
        width: 80% !important;
        margin-bottom: 30px;
      }
    }
    
    .row{
      div{
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .col-sm-12{
      &:first-child{        
        margin-top: 15px !important; 
      }
    }

    ul{
      margin: 0 !important;

      li{        
        margin: 0 0 10px 0 !important;
        img{
          height: 15px !important;          
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }

  }

  
}

    
</style>