<template>
  <div style="min-height: 100vh;">        

    <div v-if="show" class="base-padding pt-5">

      <SearchBarPosts         
        :partner_url="partner_url"        
        @search="searchPosts"
      ></SearchBarPosts>

      <FeaturedPosts 
        class="mt-5"
        :partner_url="partner_url"           
      ></FeaturedPosts>

      <ListPosts 
        class="mt-5"
        :partner_url="partner_url"          
      ></ListPosts>

    </div>


    <ThePlatform :window="window" class='mt-5' style="background-color: #F3F6FF;" v-if="show"></ThePlatform>
    <Clients></Clients>

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ThePlatform from '../common/ThePlatform'
import Clients from '../common/Clients'
import FeaturedPosts from '../blog/FeaturedPosts'
import SearchBarPosts from '../blog/SearchBarPosts'
import ListPosts from '../blog/ListPosts'
import BlogMixin from '../../mixins/BlogMixin'

export default {  

  mixins: [BlogMixin],

  components:{
    ThePlatform,
    Clients,
    FeaturedPosts,
    SearchBarPosts,
    ListPosts
  },

  props: {    
    partner_url: {
        type: String,
        default: ''
    },  
    window: {
      type: Object,
      default () {
        return {
          width: 0,
          height: 0
        }
      }
    }
  },

  computed: {
      ...mapState("MainStoreModule", { 
          post: state => state.post,
          posts_list: state => state.posts,            
          posts_authors: state => state.posts_authors,
          posts_categories: state => state.posts_categories,
      }),
      
      lang(){
          return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
  },

  data(){
    return {
      show: false
    }
  },
  
  beforeCreate(){         
    this.$emit('isPageLoaded', false)  
  },
  
  async mounted(){  

    await this.getPosts()

    setTimeout(() => {
        this.$emit('isPageLoaded', true)
        this.show = true
    }, 1000);

  },

  methods: {
    ...mapActions('MainStoreModule', [   
        'getPosts',
        'getPost',
        'getPostsAuthors',
        'getPostsCategories'
    ]),
  },
}
</script>

<style lang="scss" scoped>
    
</style>