<template>
    <div>
        <div class="list d-flex flex-wrap">

            <div class="item" v-for="(item, index) in posts_list" :key="index">

                <div class="image-wrapper">
                    <img :src="`${item.image}`" :alt="`${item.title[lang]}`">
                </div>

                <div class="text-wrapper d-flex flex-column">

                    <div class="d-flex flex-row align-items-center">
                        <span class="date mr-1"> {{ dateRelative(item.created_at.date) }} </span>
                        |
                        <span class="category ml-1"> {{ item.category.title[lang] }} </span>
                    </div>
                    <span class="title mt-2"> {{ limitChars(item.title[lang], 60) }} </span>           
                    <router-link class="mt-2 btn-read-article" :to="`/blog/${item.id}/${item.slug}${partner_url}`">{{$t('READ_ARTICLE')}} <i class="ml-2 fas fa-arrow-circle-right"></i></router-link>

                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BlogMixin from '../../mixins/BlogMixin'

export default {
    mixins: [BlogMixin],
    props:[
        'partner_url',
        'posts'
    ],
    computed:{  
        ...mapState("MainStoreModule", { 
            posts_list: state => state.posts_list,
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },
}
</script>

<style lang="scss" scoped>

$item_height: 250px;

.list{
    width: 100%;
    
    .item{

        width: 25%;                         
        margin-bottom: 30px;
        
        .image-wrapper{  
            padding: 0 10px;

            width: 100%; 
            height: $item_height;                           
            
            img{
                position: relative;                    
                width: 100%;
                height: 100%;
                object-fit: cover;  
                
                border-radius: 10px;
                border: 1px solid #2C2FFA;
                -moz-box-shadow: 0 20px 11px -12px #C4C9D9;
                -webkit-box-shadow: 0 20px 11px -12px #C4C9D9;
                box-shadow: 0 20px 11px -12px #C4C9D9;
                transition: all 0.35s ease !important;
            }

        }

        .text-wrapper{

            margin: 0 10px;
            margin-top: -10px;
            padding: 15px 20px;
            padding-top: 25px;

            border-radius: 0 0 10px 10px;
            transition: all 0.35s ease !important;

            .date{                
                text-align: left;
                font-size: 12px;
                color: rgba(0,0,0, .7);
            }
            .category{                
                text-align: left;
                font-size: 12px;
                color: #5D6ADB;
                font-weight: 500;
            }
            .title{
                width: 100%;
                text-align: left;
                font-size: 18px;
                line-height: 28px;
                color: rgba(0,0,0, .8);
                font-weight: 500;
            }

        }


        &:hover{
            .text-wrapper{
                background-color: #DDDFF8; 
                transition: all 0.35s ease !important;
            }
        }
    }

}


.btn-read-article{    
    color: #5D6ADB;
    display: flex;
    flex-direction: row;    
    align-items: center;
    text-decoration: none;    
    font-size: 16px;
    font-weight: 600;
    transition: all 0.35s ease !important;

    i{  
        transition: all 0.35s ease !important;
        font-size: 18px;
        color: #5D6ADB;
        font-weight: bold;
    }

    &:hover{
        color: #5FBA21;
        i{
            color: #5FBA21;
        }
    }
}

</style>