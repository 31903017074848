<template>
  <div class="cookies row">

    <div class="div-text column col-lg-8 col-md-8 col-sm-12" v-html="$t('COOKIES_DESCRIPTION').replace(/{lang}/g, lang)"></div>

    <div class="div-buttons column col-lg-4 col-md-4 col-sm-12">
        <a @click="acceptCookies">{{$t('ACCEPT_COOKIES')}}</a>
        <a v-b-modal.cookies-modal>{{$t('MANAGE_COOKIES')}}</a>
    </div>

   <b-modal @ok="setSelectedCookies" ok-only size="lg" id="cookies-modal" :title="$t('COOKIES_MODAL_TITLE')">
        <p class="cookies-modal-description" v-html="$t('COOKIES_MODAL_DESCRIPTION').replace(/{lang}/g, lang)"></p>

        <div class="cookies-list">

            <div class='item' v-for="(item, index) in cookies" :key="index">
                <span>
                    <b-form-checkbox :disabled="item.disabled" v-model="cookies[index].value" name="cookies" switch>{{item.label}}</b-form-checkbox> 
                    <i @click="item.showDescription = !item.showDescription" class="ml-2 far fa-question-circle"></i>
                </span>
                <b-alert :show="item.showDescription">{{item.description}}</b-alert>
            </div>
            
        </div>
    </b-modal> 
    
  </div>
</template>

<script>
export default {
    props: ['lang'],
    data(){
        return {
            cookies: {
                essencials: {
                    "label": this.$t('ESSENCIAL_COOKIES_LABEL'),
                    "description": this.$t('ESSENCIAL_COOKIES_DESCRIPTION'),
                    "showDescription": false,
                    "disabled": true,
                    "value": true
                },
                performance_and_analytics: {
                    "label": this.$t('PERFORMANCE_COOKIES_LABEL'),
                    "description": this.$t('PERFORMANCE_COOKIES_DESCRIPTION'),
                    "showDescription": false,
                    "disabled": false,
                    "value": true
                },
                functional: {
                    "label": this.$t('FUNCTIONAL_COOKIES_LABEL'),
                    "description": this.$t('FUNCTIONAL_COOKIES_DESCRIPTION'),
                    "showDescription": false,
                    "disabled": false,
                    "value": true
                },
                // targeting: {
                //     "label": this.$t('TARGETING_COOKIES_LABEL'),
                //     "description": this.$t('TARGETING_COOKIES_DESCRIPTION'),
                //     "showDescription": false,
                //     "disabled": false,
                //     "value": true
                // },
            }
        }
    },
    methods:{
        acceptCookies(){

            localStorage.setItem('cookiesAccepted', true)

            for(var i in this.cookies){
                this.cookies[i].value = true
            }

            localStorage.setItem('cookiesSelected', JSON.stringify(this.cookies))
            this.$emit('cookiesAccepted', true)      

        },

        setSelectedCookies(){
            localStorage.setItem('cookiesAccepted', true)            
            localStorage.setItem('cookiesSelected', JSON.stringify(this.cookies))
            this.$emit('cookiesAccepted', true)
        }
    }
}
</script>

<style lang="scss">
.cookies{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: #2382d1;
    color: #fff;
    padding: 20px 6vw !important;
    z-index: 999999;
    line-height: 1.1rem;
    font-size: 1rem;

    &.row{
        margin: 0 !important;
    }

    .column{
        
        &.div-text{
            text-align: left;
            
            a{
                color: #fff;
                transition: all .4s ease;
                text-decoration: none;
                font-weight: bold;
            }
        }

        &.div-buttons{

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            a{
                cursor: pointer;
                color: #fff;
                transition: all .4s ease;
                text-decoration: none;
                padding: 3px 10px;
                outline: 3px solid #fff;       
                font-weight: bold;
                margin: 0 10px;

                &:hover{
                    background-color: #fff;            
                    color: #2382d1;
                }
            }
        }
    }

    
}

.cookies-modal-description{
    font-size: 15px !important;
    line-height: 21px !important;
}

.cookies-list{
    width: 100%;
    cursor: pointer;

    .item{    
        
        span{
            display: flex;
            flex-direction: row;            
            align-items: center;
        }

        .alert{
            font-size: 12px !important;
            line-height: 16px !important;
            margin: 10px 0;
        }

        i{
            cursor: pointer;
            font-size: 12px !important;
            line-height: 12px !important;
            transition: all .4s ease;
            &:hover{
                color: #2382d1;    
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    .cookies{
        padding: 20px 10px !important;

        .column{        
            &.div-text{
                text-align: center !important;
            }
        }
        
    }
}
</style>