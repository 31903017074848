<template>
    <div class="section section-join base-padding d-flex flex-column justify-content-center align-items-center ">
        <h1 v-html="$t('Join_TITLE').replace(/{domain}/g, domain.title)"></h1>

        <div class="d-flex flex-row justify-content-center align-items-center div-buttons-hire">            
            <router-link class="btn btn-white btn-medium-width" :to="`/start-free${partner_url}`">{{ $t('START_FREE') }} </router-link>                        
            <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="ml-5 btn btn-success px-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    computed:{
        ...mapState("MainStoreModule", {            
            domain: state => state.domain
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },
    props:{
        isDomainPermitted:{
            type: Boolean,
            default: false
        },
        partner_url: {
            type: String,
            default: ''
        },
    },

}
</script>

<style scoped lang="scss">

.section-join {
    padding: 12vh 26vw !important;
    background-color: #2D5EFC;
    color: #fff;
    h1 {
        color: #fff;
        width: 45vw;
        text-align: center;
        margin-bottom: 20px;
    }
    i {
        color: #fff !important;
    }
    a {
        color: #fff;
        transition: all .4s ease;
        &:hover {
            color: #3E3936;
        }
    }
}

@media only screen and (max-width: 991px) {

    .section-join {
        padding: 30px 10px !important;           
        h1 {
            width: 60% !important;             
            margin-bottom: 15px !important;
        }     
        
        .div-buttons-hire{
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            
            .btn {
                padding: 0 10px !important;             
                margin: 20px 0 0 0 !important;
                width: 190px !important;
                height: 30px !important;
                font-size: 16px !important;
                line-height: 18px !important;
                border-radius: 5px !important;          
            }
        }
    }

}

</style>