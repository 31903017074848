<template>
    <div class="section section-FAQ base-padding d-flex flex-column justify-content-center align-items-start ">
        <h1 class="text-center font-bpm">{{$t('DSS_FUNCTIONALIES')}}</h1>

        <div class="d-flex flex-row justify-content-center align-items-center div-questions-list">
            <ul>
                <li v-for="(item, index) in faq" :key="index" class="d-flex flex-column justify-content-center align-items-center">
                    <a class="d-flex flex-row justify-content-between align-items-center" @click.stop="toggleOpen($event, index)">{{ item.question[lang].replace(/{domain}/g, domain.title) }}<i :class="{'fa fa-plus': !item.isOpen, 'fa fa-minus': item.isOpen}"></i></a>
                    <span :class="{'is-open': item.isOpen}" v-html="item.answer[lang].replace(/{domain}/g, domain.title).replace('{email1}', ( isInterfyFamily() ) ? domain.email2 : domain.email1)"></span>
                </li>
            </ul>
        </div>
    </div>
</template>



<script>
import { mapState, mapActions } from 'vuex'
export default {

    computed:{

        ...mapState('MainStoreModule', {                                
            domain: state => state.domain
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },    
    },

    data(){
        return{            
            faq: [
                {
                    isOpen: false,
                    question: {
                        en: "Digital signature",
                        pt: "Assinatura digital",
                        es: "Firma digital",
                    },
                    answer: {
                        en: "Signature in PDF (PAdES), XAdES and CAdES standards <br> Compliance with ICPBrasil's Signature Policies <br> Verification of digitally signed documents <br> Positioning of the visual representation of the PAdES signature configured by the user at the time of signature <br>Support for non-ICP-Brazil certificates - Support for Firefox and Google Chrome (Windows, Mac OS and Linux) and Microsoft Edge and Microsoft Internet Explorer - LCRs cache <br> LCR centralizer in single URL",
                        pt: "Assinatura nos padrões PDF (PAdES), XAdES e CAdES<br>Conformidade com as Políticas de Assinatura da ICPBrasil<br>Verificação de Documentos assinados digitalmente<br>Posicionamento da representação visual da assinatura PAdES configurada pelo usuário no momento da assinatura<br>Suporte a certificados não ICP-Brasil<br>Suporte para Firefox e Google Chrome (Windows, Mac OS e Linux) e Microsoft Edge e Microsoft Internet Explorer<br>Cache de LCRs<br>Centralizador de LCRs em URL única",
                        es: "Firma en PDF (PAdES), estándares XAdES y CAdES <br> Cumplimiento de las Políticas de Firma de ICPBrasil <br> Verificación de documentos firmados digitalmente <br> Posicionamiento de la representación visual de la firma PAdES establecida por el usuario en el momento de la firma <br > Soporte para certificados no ICP-Brasil - Soporte para Firefox y Google Chrome (Windows, Mac OS y Linux) y Microsoft Edge y Microsoft Internet Explorer - Caché LCR - Centralizador de URL de URL única",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Electronic signature",
                        pt: "Assinatura Eletrônica",
                        es: "Firma electronica",
                    },
                    answer: {
                        en: "Verification of identity by SMS, e-mail and OTP; <br> Audit trail storage with: IP;Geolocation;Unique identifier for the device.",
                        pt: "Verificação de identidade por SMS, e-mail e OTP;<br>Armazenamento de trilha de auditoria com: IP; Geolocalização; Identificador único do dispositivo.",
                        es: "Verificación de identidad por SMS, correo electrónico y OTP; <br> Almacenamiento de pistas de auditoría con: IP;Geolocalización;Identificador único del dispositivo.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Attribute Certificate",
                        pt: "Certificado de Atributo",
                        es: "Certificado de Atributo",
                    },
                    answer: {
                        en: "Inclusion of customer attribute certificates in the digital signature; <br> Issuance of attribute certificates.",
                        pt: "Inclusão de certificados de atributo do cliente na assinatura digital;<br>Emissão de certificados de atributo.",
                        es: "Inclusión de certificados de atributos de clientes en la firma digital; Emisión de certificados de atributos.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "User identification",
                        pt: "Identificação de usuários",
                        es: "Identificación de usuario",
                    },
                    answer: {
                        en: "Login with digital certificate; <br> E-mail and cell phone validation at the time of registration; <br> Double factor of authentication with SMS and OTP; <br> Logs of all user operations.",
                        pt: "Login com certificado digital;<br>Validação de e-mail e celular de usuários no momento do cadastro;<br>Duplo fator de autenticação com SMS e OTP;<br>Logs de todas as operações do usuário.",
                        es: "Inicio de sesión con certificado digital; <br> Validación de correo electrónico y teléfono celular en el momento del registro; <br> Autenticación de doble factor con SMS y OTP; <br> Registros de todas las operaciones del usuario.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Subscription flow",
                        pt: "Fluxo de assinaturas",
                        es: "Flujo de suscripción",
                    },
                    answer: {
                        en: "Customizable flow, sequential and / or in parallel; <br> Multiple signature profiles (Signatory, Observer, Approver); <br> Sending e-mails for all steps of the flow; <br> Inserting a Visa mark in allthe pages of the document by the Observer profile; <br> Sign documents in batch; <br> Signature of documents using QRCode",
                        pt: "Fluxo customizável, sequencial e/ou em paralelo;<br>Múltiplos perfis de assinatura (Signatário, Observador, Aprovador);<br>Envio de e-mails para todos os passos do fluxo;<br>Inserção de marca de Visto em todas as páginas do documento pelo perfil de Observador;<br>Assinar documentos em lote;<br>Assinatura de documentos utilizando QRCode",
                        es: "Flujo personalizable, secuencial y / o en paralelo; <br> Perfiles de firma múltiple (Firmante, Observador, Aprobador); <br> Envío de correos electrónicos para todos los pasos del flujo; <br> Inserción de una marca Visa en todas las páginas de el documento por el perfil de Observador; <br> Firmar documentos en lote; <br> Firma de documentos usando QRCode",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "HSM integration (optional)",
                        pt: "Integração com HSM (opcional)",
                        es: "Integración HSM (opcional)",
                    },
                    answer: {
                        en: "Issue certificates in HSMs; <br> Sign document using certificates stored in HSMs; <br> Digital signature without the need to install a plugin in the browser; <br> Native support for HSMs Dynamo.",
                        pt: "Emitir certificados em HSMs;<br>Assinar documento utilizando certificados armazenados em HSMs;<br>Assinatura digital sem a necessidade de instalação de plugin no browser;<br>Suporte nativo a HSMs Dínamo.",
                        es: "Emitir certificados en HSM; <br> Firmar documentos usando certificados almacenados en HSM; <br> Firma digital sin necesidad de instalar un complemento en el navegador; <br> Soporte nativo para HSM Dynamo.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Integration and compatibility",
                        pt: "Integração e compatibilidade",
                        es: "Integración y compatibilidad",
                    },
                    answer: {
                        en: "Integrated authentication with other applications using OAuth2 and OpenID Connect; <br> REST APIs for submitting document flows; <br> Webhooks and APIs for integration with billing systems.",
                        pt: "Autenticação integrada com outas aplicações utilizando OAuth2 e OpenID Connect;<br>APIs REST para submissão de fluxos de documentos;<br>Webhooks e APIs para integração com sistemas de cobrança.",
                        es: "Autenticación integrada con otras aplicaciones usando OAuth2 y OpenID Connect; API REST para enviar flujos de documentos; Webhooks y API para integración con sistemas de facturación.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Permissions",
                        pt: "Permissões",
                        es: "Permisos",
                    },
                    answer: {
                        en: "Individualized permissions by organization; <br> Users can have the following profiles in each organization: Administrator;Manager;Basic.",
                        pt: "Permissões individualizadas por organização;<br>Os usuários podem ter os seguintes perfis em casa organização: Administrador; Gerente; Básico.",
                        es: "Permisos individualizados por organización; <br> Los usuarios pueden tener los siguientes perfiles en cada organización: Administrador;Gerente;Básico.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Administration",
                        pt: "Administração",
                        es: "Administración",
                    },
                    answer: {
                        en: "Registration and maintenance of organizations / companies; <br> Registration and maintenance of users; <br> Access to all user actions; <br> Access to all user logs.",
                        pt: "Cadastro e manutenção de organizações/empresas;<br>Cadastro e manutenção de usuários;<br>Acesso a todas as ações de um usuário;<br>Acesso a todos os logs de um usuário.",
                        es: "Registro y mantenimiento de organizaciones / empresas; <br> Registro y mantenimiento de usuarios; <br> Acceso a todas las acciones de los usuarios; <br> Acceso a todos los registros de usuarios.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Mobile",
                        pt: "Mobile",
                        es: "Mobile",
                    },
                    answer: {
                        en: "Import of certificates to the device; <br> Digital signature with certificate installed on the device; <br> Submit documents for signature directly on the device; <br> Collection of geographical position.",
                        pt: "Importação de certificados para o dispositivo;<br>Assinatura digital com certificado instalado no dispositivo;<br>Submeter documentos para assinatura diretamente no dispositivo;<br>Coleta de posição geográfica.",
                        es: "Importación de certificados al dispositivo; <br> Firma digital con certificado instalado en el dispositivo; <br> Enviar documentos para su firma directamente en el dispositivo; <br> Recogida de posición geográfica.",
                    },
                },
            ],            
        }
    },
    methods:{
        toggleOpen(e, index){
            e.preventDefault
            this.faq[index].isOpen = !this.faq[index].isOpen
        },

        isInterfyFamily(){
            const family = ['Interfy','Neofy','ProcessDoc','CloudDoc','Intellify','Autofy','Lawfy','Healthify','Educafy','Accountify','Realtify','DocSystem']
            return family.includes(this.domain.title)
        }
    }

}
</script>

<style scoped lang="scss">
a{
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .section-FAQ{        
        margin-top: 0 !important;
        padding: 30px 10px !important;    
        
        h2{
            width: 100%;
            text-align: center;
        }
    }
}
</style>