<template>
  <div style="min-height: 100vh;">        
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-about-us base-padding d-flex flex-row justify-content-center align-items-center">

          <div class="row">
            <div class="col-12">
              <h1 class="mb-4 title" v-html="$t('WELCOME...').replace(/{domain}/g, ucwords(domain.company_name.toLowerCase()))"></h1>
            </div>            
          </div>  

          <div class="mockup" v-if="window.width > 991">
              <img src="/assets/images/about-us/mockup.png" :alt="$t('WELCOME...').replace(/{domain}/g, ucwords(domain.company_name.toLowerCase()))">              
          </div> 
        </div>
    </transition>

    <!-- 

      Brands & Segments

    -->

    <transition name="slide-fade">
        <div class="section section-about-us base-padding row">

            <div :class="{'col-lg-6 col-md-12 col-sm-12': domain.type == 'interfy', 
                          'col-12': domain.type != 'interfy'}">
              <h2 v-html="$t('TITLE_ABOUT_US').replace(/{domain}/g, ucwords(domain.company_name.toLowerCase()))"></h2>
              <p v-html="$t('SUBTITLE_ABOUT_US_' + (domain.type == 'interfy' ? 'interfy' : domain.name)).replace(/{domain}/g, domain.title)"></p>
            </div>

            <div v-if="domain.type == 'interfy'" class="col-lg-6 col-md-12 col-sm-12">
              <div class="d-flex flex-wrap justify-content-center align-items-center">

                <a 
                  v-for="(item, index) in brandsSegments" :key="index"
                  :href="item.url" 
                  target="_blank"> 
                  <img :src="`/assets/images/about-us/logos/${item.icon_gray}`" :alt="item.title"> 
                  <img :src="`/assets/images/about-us/logos/${item.icon_colored}`" :alt="item.title"> 
                </a>

              </div>
            </div>

        </div>
    </transition>


    <!-- 

        Tech...

    -->  
    <transition name="slide-fade">             
        <div v-if="show && domain.type == 'interfy'" class="section-tech base-padding d-flex flex-column justify-content-center align-items-center">

          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12" v-if="window.width > 991">
              <img src="/assets/images/about-us/icon12.png" :alt="$t('WELCOME...')">
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12">
              <div class="div-list d-flex flex-column justify-content-center align-items-center">
                <div>
                  <h2 v-html="$t('TECH_TITLE_01')"></h2>
                  <p v-html="$t('TECH_SUBTITLE_01').replace(/{domain}/g, domain.title)"></p>
                </div>
                <div>
                  <h2 v-html="$t('TECH_TITLE_02')"></h2>
                  <p v-html="$t('TECH_SUBTITLE_02')"></p>
                </div>
                <div>
                  <h2 v-html="$t('TECH_TITLE_03')"></h2>
                  <p v-html="$t('TECH_SUBTITLE_03')"></p>
                </div>
              </div>
            </div>
          </div>                        

        </div>
    </transition>


    <!-- 

        Vision, mission, principles...

    -->  
    <transition name="slide-fade">             
        <div v-if="show && domain.type == 'interfy'" class="section-vision d-flex flex-column justify-content-center align-items-center">

          <div class="row">            
            <div class="col-lg-4 col-md-4 col-sm-12">
              
              <div class="d-flex flex-row justify-content-center align-items-start">
                <span><img src="/assets/images/about-us/icon13.png" :alt="$t('VIEW')"></span>
                <div>
                  <h2 v-html="$t('VIEW')"></h2>
                  <p v-html="$t('VIEW_SUBTITLE')"></p>
                </div>
              </div>

            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              
              <div class="d-flex flex-row justify-content-center align-items-start">
                <span><img src="/assets/images/about-us/icon14.png" :alt="$t('MISSION')"></span>
                <div>
                  <h2 v-html="$t('MISSION')"></h2>
                  <p v-html="$t('MISSION_SUBTITLE')"></p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              
              <div class="d-flex flex-row justify-content-center align-items-start">
                <span><img src="/assets/images/about-us/icon15.png" :alt="$t('PRINCIPLES')"></span>
                <div>
                  <h2 v-html="$t('PRINCIPLES')"></h2>
                  <p v-html="$t('PRINCIPLES_SUBTITLE')"></p>
                </div>
              </div>

            </div>
          </div>                        

        </div>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="mb-5"></Clients>
    </transition>   
    
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'


export default {
    components:{                  
        Clients, 
    },
    props: {           
        window: {
            type: Object,
            default () {
                return {
                    width: 0,
                    height: 0
                }
            }
        },
        page: {
            type: String,
            default: ''
        },
        path: {
            type: String,
            default: ''
        }
    },
    data(){
        return{            
            
            show: false,
            brandsSegments: [
              // {
              //   'icon_colored': '01.svg',
              //   'icon_gray': '01-2.svg',
              //   'title': 'Bememo',
              //   'url': 'https://bememo.com'
              // },
              {
                'icon_colored': '02.svg',
                'icon_gray': '02-2.svg',
                'title': 'ProcessDoc',
                'url': 'https://processdoc.io'
              },
              {
                'icon_colored': '03.svg',
                'icon_gray': '03-2.svg',
                'title': 'Neofy',
                'url': 'https://neofy.io'
              },
              {
                'icon_colored': '04.svg',
                'icon_gray': '04-2.svg',
                'title': 'Intellify',
                'url': 'https://intellify.app'
              },
              {
                'icon_colored': '05.svg',
                'icon_gray': '05-2.svg',
                'title': 'DocSystem',
                'url': 'https://docsystemcorp.com'
              },
              // {
              //   'icon_colored': '06.svg',
              //   'icon_gray': '06-2.svg',
              //   'title': 'Autofy',
              //   'url': 'https://autofy.one'
              // },
              // {
              //   'icon_colored': '07.svg',
              //   'icon_gray': '07-2.svg',
              //   'title': 'Educafy',
              //   'url': 'https://educafy.one'
              // },
              // {
              //   'icon_colored': '08.svg',
              //   'icon_gray': '08-2.svg',
              //   'title': 'Healthify',
              //   'url': 'https://healthify.one'
              // },
              // {
              //   'icon_colored': '09.svg',
              //   'icon_gray': '09-2.svg',
              //   'title': 'Lawfy',
              //   'url': 'https://lawfy.one'
              // },
              // {
              //   'icon_colored': '10.svg',
              //   'icon_gray': '10-2.svg',
              //   'title': 'Accountify',
              //   'url': 'https://accountify.one'
              // }
            ]
        }
    },
    computed: {  

      ...mapState('MainStoreModule', {                                             
        domain: state => state.domain
      }),
  
      lang() {
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
    
       
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),
        
        ucwords(str){

          let words = str.split(" ")

          for(var i in words){
            let word = words[i]
            words[i] = word.charAt(0).toUpperCase() + word.slice(1)
          }

          words = words.join(" ")
          return words
        },
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){               
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">


.section-tech {    
    background: url(/assets/images/about-us/banner-02.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    img {
        width: 30vw;
        height: auto !important;
    }
    .div-list {
        height: 100%;
        div {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
            h2 {
                font-weight: 600 !important;
                color: #4E4E4C;
            }
            p {
                color: #4E4E4C;
            }
        }
    }
}


.section-vision {
    padding: 10vw 10vw;
    div {
        div {
            div {
                span {
                    margin-right: 30px;
                    border-radius: 10px;
                    padding: 20px;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
                    &:hover {
                        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
                    }
                    img {
                        height: 50px;
                    }
                }
                div {
                    h2 {}
                    p {}
                }
            }
        }
    }
}


.section-main-about-us {

    height: calc(100vh - 80px);
    color: #fff;
    background: url(/assets/images/about-us/banner-01.png) no-repeat;
    background-size: cover;
    h1 {
        color: #fff;
        width: 30%;
        text-align: left;
    }
    .mockup {
        position: absolute;
        bottom: 0;
        right: 10vw;
        width: 40vw;
        img {
            width: 100%;
        }
    }
}


.section-about-us {
    h2 {
        color: #4D4D4D;
    }
    p {
        color: #4D4D4D;
        b {
            font-weight: bold;
        }
    }
    div {
        a {
            text-decoration: none;
            &:hover {
                img:nth-child(1) {
                    display: none;
                }
                img:nth-child(2) {
                    display: block;
                }
            }
            img {
                width: 100px;
                height: 100px;
                margin: 15px 20px;
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}
    
</style>