<template>
  <div class="checkout-page">   

      <div v-if="1 != 2" class="d-flex flex-row justify-content-center aling-items-center mt-5">
        Checkout disabled by the administrator!
      </div>

      <div v-else>
      <!-- <div> -->
      
    
        <!-- 

          Not welcome conditions:
          1 - No pre customer data
          2 - URL data has parent ID but there's no parent data (possible wrong partner ID or SLUG)

        -->
        <NotWelcome v-if="!isLoading && !isValidCheckoutData()"></NotWelcome>

        <div v-if="!isLoading && isValidCheckoutData()">

          <div class="div-top base-padding">
              <img class="logo-top" :src="`/assets/images/domains/${domain.name}/logo_03.png`" :alt="`${domain.title}`">                 
              
              <span class="partner" v-if="partner">
                <b>{{partner.name}}</b>
                <small>
                  <i class="mr-1 fas fa-envelope"></i>{{partner.billing_email}} 
                  <i class="ml-2 mr-1 fas fa-phone"></i>{{partner.phone1}}
                </small>              
              </span>
          </div>

          <div class="div-content base-padding">

            <div class="row" v-if="renderedPlans.length > 0" >
              <div class="col-8">

                <div class="box">
                  <span class="box-title" v-html="$t('SELECTED_PLAN')"></span>              
                  <FormSelectedPlan              
                    :renderedPlan="renderedPlans[0]" 
                    :renderedAddons="renderedAddons" 
                    :selectedPlan="selectedPlan"
                    :lang="lang"
                    :user_monetary="user_monetary"
                    :method="pre_customer.method"
                    :partner_url="partner_url"
                  ></FormSelectedPlan>
                </div>

                <div class="box" >
                  <span class="box-title" v-html="$t('ADDONS')"></span>
                  <FormAddons
                    :lang="lang"
                    :user_monetary="user_monetary"
                    :renderedPlan="renderedPlans[0]"  
                    :selectedPlan="selectedPlan" 
                    :renderedAddons="renderedAddons"
                  ></FormAddons>
                </div>

                <div class="box" v-if="pre_customer">
                  <span class="box-title" v-html="$t('ACCOUNT_INFO')"></span>
                  <FormAccount :selectedPlan="selectedPlan"></FormAccount>
                </div>
            
                <div class="box">
                  <span class="box-title" v-html="$t('PAYMENT_INFO')"></span>
                  <FormPayment :lang="lang" :selectedPlan="selectedPlan"></FormPayment>
                </div>

              </div>

              <div class="col-4 col-summary">
                <div class="box-summary ">
                  <div class="box ">
                    <span class="box-title" v-html="$t('SUMMARY')"></span>                                
                    <FormSummary></FormSummary>                
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import NotWelcome from '../../NotWelcome'
import FormSelectedPlan from '../../plans/checkout/FormSelectedPlan'
import FormAddons from '../../plans/checkout/FormAddons'
import FormAccount from '../../plans/checkout/FormAccount'
import FormPayment from '../../plans/checkout/FormPayment'
import FormSummary from '../../plans/checkout/FormSummary'
import CheckoutMixin from '../../../mixins/CheckoutMixin'
import PlansMixin from '../../../mixins/PlansMixin'

export default {

    provide: function () {
      return {      
        getAddonUnit: this.getAddonUnit
      }
    }, 

    computed: {
      ...mapState('MainStoreModule', {                                  
          domain: state => state.domain
      }),
    },

    mixins: [CheckoutMixin, PlansMixin],    
    components:{        
      NotWelcome, 
      FormSelectedPlan,
      FormAddons,
      FormAccount,
      FormPayment,
      FormSummary      
    },  
   
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },

    async created(){     
      
      await this.extractUrlData()

      if(!this.api.token){     
        this.isLoading = true         
        await this.setApiToken()
      }

      if(this.api.token){
        await this.getCheckoutData()
      }
            
    },
    
    mounted(){
      this.$emit('isPageLoaded', true)
    }
    
}
</script>

<style lang="scss" scoped>

.checkout-page{  
  margin: 0 !important;   
  min-height: calc(100vh - 130px);

  .overlay-size{    
    min-height: calc(100vh - 130px);
  }
}

.div-top{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 85px;  
  background-color: #3150E6;  
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-top{
      height: 45px;
  }

  .partner{
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.div-content{
  margin-top: 85px;
  padding-top: 50px;

  .col-summary{
    width: 28vw;
    right: 9vw;    
    position: fixed;

    .box-summary{
      width: 100%;      
      .box{        
        width: 100%;
      }
    }

  }
  .box{
    border: 2px solid #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 50px;

    

    .box-title{
      background-color: #fff;
      color: #3150E6;
      font-size: 20px;
      padding: 3px 10px;
      font-weight: bold;
      margin-top: -40px;
    }
  }
}
    
</style>

