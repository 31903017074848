<template>
    <div>

        <Carousel 
            :per-page="1"
            :autoplay="true"
            :autoplayTimeout="5000"
            :easing="'ease'"
            :loop="true"
            :mouse-drag="true"
            @page-change="navigationHandler"
        >
            <slide v-for="(item, index) in posts_list" :key="index" class="featured-post">
                
                <div class="row" v-if="item.isFeatured">

                    <div class="col-featured-wrapper col col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-center">
                        
                        <span class="image-background left"></span>
                        <span class="image-background right"></span>                            

                        <div class="image-wrapper">
                            <img :src="`${item.image}`" :alt="`${item.title[lang]}`">
                        </div>

                    </div>

                    <div class="col col-featured-text col-lg-5 col-md-5 col-sm-12 d-flex flex-column justify-content-center align-items-center">

                        <span class="date"> {{ dateRelative(item.created_at.date) }} </span>
                        <span class="category"> {{ item.category.title[lang] }} </span>
                        <span class="title mt-3"> {{ limitChars(item.title[lang], 100) }} </span>           
                        <router-link class="mt-4 btn-read-article colored" :to="`/blog/${item.id}/${item.slug}${partner_url}`">{{$t('READ_ARTICLE')}} <i class="ml-2 fas fa-arrow-circle-right"></i></router-link>

                    </div>

                </div>

            </slide>
            
        </Carousel>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import BlogMixin from '../../mixins/BlogMixin'


export default {    
    mixins: [BlogMixin],
    props:[
        'partner_url'
    ],
    components:{
        Carousel,
        Slide,  
    },
    data(){
        return {            
            carousel: {
                modules: {
                    index: 0
                }
            },
        }
    },
    computed:{  
        ...mapState("MainStoreModule", { 
            posts_list: state => state.posts_list,
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },

        loop(){
            var arr = []
            while(arr.length <= 6000){ //10000
                arr.push(arr.length)
            }

            return arr
        }
    },
    mounted(){        
    },

    methods:{
        navigationHandler(index){
            this.carousel.modules.index = index
        }
    }

}
</script>

<style lang="scss" scoped>

$featured_post_height: 400px;

.featured-post{
    
    height: $featured_post_height;

    .row{
        
        .col-featured-wrapper{

            padding-top: 0 !important;
            
            .image-background{
                
                position: absolute;                    
                width: 55%;
                height: calc(100% - 50px);
                background-color: #B5C2E5;

                &.left{
                    bottom: 10px;
                    left: 13px;
                    border-radius: 0 0 0 10px;
                }
                &.right{
                    top: 10px;
                    right: 10px;
                    border-radius: 0 10px 0 0;
                }
            }

            .image-wrapper{                
                height: $featured_post_height;
                width: 100%;                
                padding: 30px;

                img{
                    position: relative;                    
                    width: 100%;
                    height: 100%;
                    object-fit: cover;  
                    
                    border-radius: 10px;
                    border: 1px solid #2C2FFA;
                    -moz-box-shadow: 20px 20px 11px -12px #C4C9D9;
                    -webkit-box-shadow: 20px 20px 11px -12px #C4C9D9;
                    box-shadow: 20px 20px 11px -12px #C4C9D9;
                }

            }

        }

        .col-featured-text{
            padding: 50px !important;

            .date{
                width: 100%;
                text-align: left;
                font-size: 16px;
                color: #ABACAC;
            }
            .category{
                width: 100%;
                text-align: left;
                font-size: 16px;
                color: #5FBA21;
                font-weight: 500;
            }
            .title{
                width: 100%;
                text-align: left;
                font-size: 25px;
                line-height: 28px;
                color: rgba(0,0,0, .8);
                font-weight: 500;
            }

        }
    }

}


.btn-read-article{
    border-radius: 20px;
    color: #5D6ADB;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 3px 15px;
    font-size: 20px;

    &.colored{
        color: #fff;
        background: rgb(67,101,245);
        background: linear-gradient(90deg, rgba(67,101,245,1) 0%, rgba(65,98,231,1) 49%, rgba(50,80,185,1) 100%);
        transition: all 0.35s ease !important;

        &:hover{
            transition: all 0.35s ease !important;
            -moz-box-shadow: 10px 10px 7px -5px #C4C9D9;
            -webkit-box-shadow: 10px 10px 7px -5px #C4C9D9;
            box-shadow: 10px 10px 7px -5px #C4C9D9;
        }
    }

    i{  
        color: #fff;
        font-weight: bold;
    }
}

</style>