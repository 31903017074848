<template>
  <div style="min-height: 100vh;">        
    
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-process paymentAuthorization d-flex flex-row justify-content-center align-items-center">

          <div class="col-lg-6 col-md-6 col-sm-12 mockup" v-if="window.width > 991">
            <img :src="`/assets/images/domains/${domain.name}/processes/paymentAuthorization/mockup_01_${lang}.png`" :alt="$t('PROCESS_PAYMENT_AUTHOR')">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 div-text">
            <div class="header-main-process d-flex flex-row justify-content-start align-items-center">
              <div class='icon icon-shape paymentAuthorization'>
                <img src="/assets/images/processes/icons/04.png" :alt="$t('PROCESS_PAYMENT_AUTHOR')" />
                <img src="/assets/images/processes/icons/04_hover.png" :alt="$t('PROCESS_PAYMENT_AUTHOR')" />
              </div>
              <h1 class="mb-4 title" v-html="$t('PROCESS_PAYMENT_AUTHOR')"></h1>
            </div>
            <h2 class="subtitle" v-html="$t('WHEN ....')"></h2>
            
            <div class="div-buttons d-flex">
              <router-link class="btn paymentAuthorization btn-medium-width" :to="`/start-free${partner_url}`">{{$t("START_FREE")}}</router-link>
              <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-4 px-3 btn paymentAuthorization-alternative" :to="`/plans${partner_url}`">{{$t("BECOME_A_PARTNER")}}</router-link>
            </div>
          </div>

        </div>
    </transition>

    <img class="top-image" src="/assets/images/processes/top-01.png" :alt="`${domain.title}`">

    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description paymentAuthorization">
          <div class="row">
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/paymentAuthorization/icons/01.png" :alt="$t('WITH ....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('WITH ....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/paymentAuthorization/icons/02.png" :alt="$t('BPM M....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('BPM M....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/paymentAuthorization/icons/03.png" :alt="$t('TO FA....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('TO FA....')"></p>
              </div>
            </div>
          </div>

        </div>
    </transition>


    <img src="/assets/images/processes/top-02.png" :alt="`${domain.title}`">

    <transition name="slide-fade">
      <div class="section section-process-benefits">
        <h1 class="mb-5" v-html="$t('HOW C....')"></h1>
        <div class="row mt-4">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <ul>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('STAND....')">
                <p v-html="$t('STAND....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('REDUC....')">
                <p v-html="$t('REDUC....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('COST ....')">
                <p v-html="$t('COST ....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('BETTE....')">
                <p v-html="$t('BETTE....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('VALID....')">
                <p v-html="$t('VALID....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('ACCES....')">
                <p v-html="$t('ACCES....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('MANAG....')">
                <p v-html="$t('MANAG....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('INTEG....')">
                <p v-html="$t('INTEG....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('TIME ....')">
                <p v-html="$t('TIME ....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('COST ....')">
                <p v-html="$t('COST ....')"></p>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mockup">
            <img :src="`/assets/images/domains/${domain.name}/processes/paymentAuthorization/mockup_02.png`" :alt="$t('PROCESS_PAYMENT_AUTHOR')">
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-fade">
        <div class="section section-process-description">
          <p v-html="$t('TO FA....')"></p>
        </div>
    </transition>

    <transition name="slide-fade">
        <div class="section section-list-process-description">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div v-for="(item, index) in processList['left']" :key="index" class="ct">
                <div class="item d-flex flex-row justify-content-between align-items-start">

                  <div class="icon">
                    <img :src="item.icon" :alt="item.title">
                    <img :src="item.icon_hover" :alt="item.title">
                  </div>
                  
                  <div class="text d-flex flex-column justify-content-center align-items-start">
                    <h2 v-html="item.title"></h2>
                    <p v-html="item.description"></p>
                  </div>

                  <div class="arrows" @click="openItem($event)">
                    <img src="/assets/images/processes/arrow-down.png" :alt="`${domain.title}`">
                    <img src="/assets/images/processes/arrow-up.png" :alt="`${domain.title}`">
                  </div>

                </div> 
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div v-for="(item, index) in processList['right']" :key="index" class="ct">
                <div class="item d-flex flex-row justify-content-between align-items-start">

                  <div class="icon">
                    <img :src="item.icon" :alt="item.title">
                    <img :src="item.icon_hover" :alt="item.title">
                  </div>
                  
                  <div class="text d-flex flex-column justify-content-center align-items-start">
                    <h2 v-html="item.title"></h2>
                    <p v-html="item.description"></p>
                  </div>

                  <div class="arrows" @click="openItem($event)">
                    <img src="/assets/images/processes/arrow-down.png" :alt="`${domain.title}`">
                    <img src="/assets/images/processes/arrow-up.png" :alt="`${domain.title}`">
                  </div>

                </div> 
              </div>

            </div>
          </div>
        </div>
    </transition>

    


    <!-- 

        Join......

    -->  
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></Join>
    </transition>

    <!-- 

        See also......

    -->  
    <transition name="slide-fade">             
        <OtherProcesses :partner_url="partner_url" v-if="show" :page="page" :path="path"></OtherProcesses>
    </transition>

    <!-- 

        Automate......

    -->  
    <transition name="slide-fade">             
        <AutomateProcesses :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></AutomateProcesses>
    </transition>

    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="clients-bpm"></Clients>
    </transition> 

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import OtherProcesses from '../common/OtherProcesses'
import AutomateProcesses from '../common/AutomateProcesses'
import $ from 'jquery'

export default {
    
    components:{
      Clients,
      Join,
      ThePlatform,
      OtherProcesses,
      AutomateProcesses,      
    },
    props: {      
      isDomainPermitted:{
          type: Boolean,
          default: false
      },
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      },
      page: {
        type: String,
        default: ''
      },
      path: {
        type: String,
        default: ''
      },
      partner_url: {
          type: String,
          default: ''
      },
    },
    
    data(){
        return{            
            show: false,
            
        }
    },
    computed: {

        ...mapState('MainStoreModule', {                                  
            domain: state => state.domain
        }),
        
        lang(){
          return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
        processList() {
          
          return {'left': [
            {
              'icon': '/assets/images/processes/paymentAuthorization/icons/icon-01.png',
              'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-02.png',
              'title': this.$t('CREAT....'),
              'description': this.$t('BUSIN....'),
            },
            {
              'icon': '/assets/images/processes/paymentAuthorization/icons/icon-03.png',
              'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-04.png',
              'title': this.$t('ATTAC....'),
              'description': this.$t('THE N....'),
            },
            {
              'icon': '/assets/images/processes/paymentAuthorization/icons/icon-05.png',
              'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-06.png',
              'title': this.$t('DIVER....'),
              'description': this.$t('DEPEN....'),
            },
            {
              'icon': '/assets/images/processes/paymentAuthorization/icons/icon-07.png',
              'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-08.png',
              'title': this.$t('THE P....'),
              'description': this.$t('AFTER....'),
            },
            {
              'icon': '/assets/images/processes/paymentAuthorization/icons/icon-09.png',
              'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-10.png',
              'title': this.$t('PREVI....'),
              'description': this.$t('IN TH....'),
            },
          ],
          'right': [
              {
                'icon': '/assets/images/processes/paymentAuthorization/icons/icon-11.png',
                'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-12.png',
                'title': this.$t('CORRE....'),
                'description': this.$t('IF TH....'),
              },
              {
                'icon': '/assets/images/processes/paymentAuthorization/icons/icon-13.png',
                'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-14.png',
                'title': this.$t('PAYME....'),
                'description': this.$t('INTH.....'),
              },
              {
                'icon': '/assets/images/processes/paymentAuthorization/icons/icon-15.png',
                'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-16.png',
                'title': this.$t('DELIV....'),
                'description': this.$t('THEP.....'),
              },
              {
                'icon': '/assets/images/processes/paymentAuthorization/icons/icon-17.png',
                'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-18.png',
                'title': this.$t('CONSU....'),
                'description': this.$t('IT IS....'),
              },
              {
                'icon': '/assets/images/processes/paymentAuthorization/icons/icon-19.png',
                'icon_hover': '/assets/images/processes/paymentAuthorization/icons/icon-20.png',
                'title': this.$t('ATTA.....'),
                'description': this.$t('ITIS.....'),
              },
          ]}
        }
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">

@media only screen and (max-width: 991px) {
  .section-process-benefits,
  .section-blocks-description{
      .row{
          margin: 0 !important;
      }
  } 
  
  .section-process-description{
    p{
      margin: 0 !important;
    }
  }

  .section-process-benefits{

    .mockup {
      display: none !important;
    }

    .row{
      div{
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    ul{
      margin: 0 !important;

      li{        
        margin: 0 0 10px 0 !important;
        img{
          height: 15px !important;          
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }

  }

  .section-list-process-description{

    .col-sm-12{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .item{
      padding: 10px !important;     
      
      .icon, 
      .arrows{
        margin-top: 2px !important;
      } 

      .text{
        padding: 0 20px !important;
        h2{
          font-size: 16px !important;
          line-height: 18px !important;
          margin-bottom: 0 !important;
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;         
        }
      }
    }
  }
}
    
</style>