<template>
    <div style="min-height: 100vh;">

        <transition name="slide-fade">

            <div>

                <div class="section section-main-module">
                    <div class="main-background"></div>

                    <h1 class="mb-4 mb-4 title" v-html="$t('DSS').replace(/<br>/g, '')"></h1>
                    <h2 class="subtitle"
                        v-html="$t('DSS_SUBTITLE').replace(/{domain}/g, domain.title).replace(/<br>/g, '')"></h2>

                    <div class="div-buttons base-padding d-flex flex-row justify-content-center align-items-center">
                        <a class="btn btn-1 mx-3" @click.stop="scrollto('#plans')">{{ $t('LEARN_MORE') }}</a>
                        <router-link
                            v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')"
                            class="btn btn-2 mx-3"
                            :to="`/become-a-partner${partner_url}`">{{ $t('BECOME_A_PARTNER') }}</router-link>
                    </div>

                    <div class="mockup">
                        <img class="base" :src="`/assets/images/dss/mockup-${lang}-01.png`"
                            :alt="$t('DSS') + ' - ' + $t('DSS_SUBTITLE').replace(/{domain}/g, domain.title)">
                    </div>

                </div>

                <Clients></Clients>
                <!-- 01 -->
                <div
                    class="section base-padding section-dss-01 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="mb-4 text-center" v-html="$t('DSS_TEXT_01')"></h1>
                    <h2 class="text-center" v-html="$t('DSS_TEXT_02')"></h2>
                </div>

                <!-- 02 -->
                <div
                    class="section base-padding section-dss-02 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="mb-4 text-center" v-html="$t('DSS_TEXT_03')"></h1>

                    <div class="mt-2 d-flex justify-content-center align-items-center row">
                        <div class="col-lg-2 col-md-2 col-sm-12">
                            <img src="/assets/images/dss/dss-01.svg" :alt="$t('DSS_NO_TAGS')">
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <p v-html="$t('DSS_TEXT_04')"></p>
                        </div>
                    </div>
                </div>

                <!-- 03 -->
                <div class="section section-dss-03 base-padding">
                    <h1 class="mb-4 text-center" v-html="$t('DSS_TEXT_06')"></h1>

                    <div :class="{ 'mt-5': window.width > 991 }"
                        class="d-flex flex-row justify-content-center align-items-center">

                        <Carousel style="width: 100%;" :margin="0" :autoplay="true" :dots="false" :nav="false"
                            :responsive="{
                                0: {
                                    items: 1
                                },
                                991: {
                                    items: 3
                                },
                                1400: {
                                    items: 4
                                },
                            }">
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-dss-02"></i> <span></span></div>
                                        <p v-html="$t('DSS_TEXT_07')"></p>
                                        <p class="description" v-html="$t('DSS_TEXT_08')"></p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-dss-03"></i> <span></span></div>
                                        <p v-html="$t('DSS_TEXT_09')"></p>
                                        <p class="description" v-html="$t('DSS_TEXT_10')"></p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-dss-04"></i> <span></span></div>
                                        <p v-html="$t('DSS_TEXT_11')"></p>
                                        <p class="description" v-html="$t('DSS_TEXT_12')"></p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-dss-05"></i> <span></span></div>
                                        <p v-html="$t('DSS_TEXT_13')"></p>
                                        <p class="description" v-html="$t('DSS_TEXT_14')"></p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>

                <!-- 04 -->
                <!-- <div id="plans"
                    class="section base-padding section-addon d-flex flex-column justify-content-center align-items-center">
                    <h1 class="mb-4 text-center" v-html="$t('DSS_2')"></h1>

                    <div style="width: 100%;"
                        class="addons-wrapper mt-5 d-flex flex-wrap justify-content-center align-items-stretch"
                        v-if="addon">
                        <div v-for="(tier_item, index) in addon.tiers" :key="index"
                            class='addon-tier d-flex flex-row justify-content-center'>

                            <div class='d-flex flex-column justify-content-center align-items-center'>

                                <span v-if="tier_item.max && !tier_item.last_tier_with_price" class="text-center"
                                    v-html="`${$t('UP_TO')} <b>${tier_item.max}</b> ${$t(getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular')))} / ${$t('MONTH')}`
                                        "></span>

                                <span v-else class="text-center" v-html="`<b>${$t(getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular')))} ${$t('UNLIMITED_plural')}</b>`
                                    "></span>

                                <p class="text-center description mb-3" v-html="tier_item.description"></p>

                                <p class="text-center price" v-html="`${user_monetary.symbol} <b>${tier_item.total}</b> +${$t('PLAN')} / ${$t('MONTH')}`
                                    "></p>
                            </div>

                        </div>
                    </div>
                </div> -->

                <!-- 05 -->
                <div class="section section-main-plans section-dss-05">
                    <div class="plans-background"></div>
                    <!-- <Plans v-if="show" :parent="'dss'" :must_have="`ECM`" :title="$t('DSS_CHOOSE_YOUR_PLAN')"></Plans> -->

                    <div v-if="domain.type == 'interfy'"
                        class="mt-5 d-flex flex-row justify-content-center align-items-center">
                        <a href="https://assinador.interfy.io/" target="_blank"
                            class="mt-5 btn-external-dss">{{ $t('EXTERNAL_DSS') }}</a>
                    </div>
                </div>
                <!-- 05 -->
                <!-- <div class="section section-main-plans section-dss-05">
                <div class="plans-background"></div>

                <div class="top-content d-flex flex-column justify-content-center align-items-center">
                    <h1 v-html="$t('CHOOSE_YOUR_DSS_PLAN').replace(/{domain}/g, domain.title)"></h1>              
                </div>

                <div class="bottom-content base-padding d-flex flex-row justify-content-center align-items-start">
                    <div class="plans-columns d-flex flex-row justify-content-center align-items-stretch">
                        <div class="mr-2 plan-column d-flex flex-column justify-content-start align-items-center" v-for="(plan, index) in plans" :key="index">

                            <div class="block-01">
                                <h1>DSS {{plan.title}}</h1>
                            </div>

                            <div class="block-02">
                                <div class="line-02" v-if="user_monetary">
                                    <div>
                                        <label>{{user_monetary.symbol}}</label>
                                        <h2>{{plan.price[user_monetary.currency_code][0]}}</h2>
                                        <label>{{user_monetary.separators.cents + plan.price[user_monetary.currency_code][1]}}</label>
                                    </div>
                                </div>

                                <div class="line-03">
                                    <label>{{plan.unit}} / {{plan.recurrence}}</label>
                                </div>

                                <div class="line-04">
                                    <a href="/start-free" class="btn btn-white btn-medium-width">{{$t('START_FREE')}}</a>
                                </div>
                            </div>

                            <div class="block-03">
                                <p v-html="$t(`DSS_${plan.title.toUpperCase()}_LINE_01`)"></p>
                                <p v-html="$t(`DSS_${plan.title.toUpperCase()}_LINE_02`)"></p>
                                <p v-html="$t(`DSS_${plan.title.toUpperCase()}_LINE_03`)"></p>
                            </div>
                        </div>  
                    </div>
                </div>
            </div> -->

                <!-- 07 -->
                <div
                    class="section base-padding section-dss-07 d-flex flex-column justify-content-center align-items-center">
                    <div class="background"></div>

                    <div class="content">
                        <h1 class="mb-4 text-center" v-html="$t('DSS_TEXT_15')"></h1>

                        <div class="blocks-wrapper mt-3">
                            <div class="block">
                                <div class="inner-block">
                                    <div class="image">
                                        <span>1</span>
                                        <img class='mb-3' src="/assets/images/dss/01.png" :alt="domain.title">
                                    </div>
                                    <p v-html="$t('DSS_TEXT_16')"></p>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-block">
                                    <div class="image">
                                        <span>2</span>
                                        <img class='mb-3' src="/assets/images/dss/02.png" :alt="domain.title">
                                    </div>
                                    <p v-html="$t('DSS_TEXT_17')"></p>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-block">
                                    <div class="image">
                                        <span>3</span>
                                        <img class='mb-3' src="/assets/images/dss/03.png" :alt="domain.title">
                                    </div>
                                    <p v-html="$t('DSS_TEXT_18')"></p>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-block">
                                    <div class="image">
                                        <span>4</span>
                                        <img class='mb-3' src="/assets/images/dss/04.png" :alt="domain.title">
                                    </div>
                                    <p v-html="$t('DSS_TEXT_19')"></p>
                                </div>
                            </div>

                            <div class="block">
                                <div class="inner-block">
                                    <div class="image">
                                        <span>5</span>
                                        <img class='mb-3' src="/assets/images/dss/05.png" :alt="domain.title">
                                    </div>
                                    <p v-html="$t('DSS_TEXT_20')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FAQDSS :key="$i18n.locale" v-if="show"></FAQDSS>
                <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show" class="ecm"></Join>
                <ThePlatform :window="window" v-if="show"></ThePlatform>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import FAQDSS from '../common/FAQDSS'
import Plans from '../plans/plans'
import PlansMixin from '../../mixins/PlansMixin'

import Carousel from 'vue-owl-carousel'

export default {
    name: 'DSSPage',
    inject: ['scrollto'],
    mixins: [PlansMixin],
    components: {
        Clients,
        ThePlatform,
        Join,
        Carousel,
        FAQDSS,
        // Plans
    },
    props: {
        isDomainPermitted: {
            type: Boolean,
            default: false
        },
        partner_url: {
            type: String,
            default: ''
        },
        window: {
            type: Object,
            default() {
                return {
                    width: 0,
                    height: 0
                }
            }
        }
    },
    data() {
        return {
            show: false,
            addon: null,
            // plans: [
            //     {
            //         title: "Starter",
            //         price: {
            //             "brl": ['36', '00'],
            //             "usd": ['36', '00']
            //         },
            //         unit: this.$t('USER'),
            //         recurrence: this.$t('MONTH')
            //     },
            //     {
            //         title: "Business",
            //         price: {
            //             "brl": ['80', '00'],
            //             "usd": ['80', '00']
            //         },
            //         unit: this.$t('USER'),
            //         recurrence: this.$t('MONTH')
            //     },
            //     {
            //         title: "Professional",
            //         price: {
            //             "brl": ['120', '00'],
            //             "usd": ['120', '00']
            //         },
            //         unit: this.$t('USER'),
            //         recurrence: this.$t('MONTH')
            //     },
            //     {
            //         title: "Corporate",
            //         price: {
            //             "brl": ['160', '00'],
            //             "usd": ['160', '00']
            //         },
            //         unit: this.$t('USER'),
            //         recurrence: this.$t('MONTH')
            //     }
            // ]
        }
    },

    computed: {
        ...mapState("MainStoreModule", {
            user_monetary: state => state.user_monetary,
            domain: state => state.domain
        }),

        lang() {
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },

    watch: {
        lang(lang) {
            this.addonTranslation()
        },
    },

    methods: {
        ...mapActions('MainStoreModule', [
            'getAddonByKey'
        ]),

        addonTranslation() {
            if (this.addon.tiers[0]) this.addon.tiers[0].description = this.$t('DSS_STARTER_LINE_01')
            if (this.addon.tiers[1]) this.addon.tiers[1].description = this.$t('DSS_BUSINESS_LINE_01')
            if (this.addon.tiers[2]) this.addon.tiers[2].description = this.$t('DSS_PROFESSIONAL_LINE_01')
            if (this.addon.tiers[3]) this.addon.tiers[3].description = this.$t('DSS_CORPORATE_LINE_01')
        },
    },

    beforeCreate() {
        this.$emit('isPageLoaded', false)
    },

    async created() {
        if (!this.api.token) {
            await this.setApiToken()
        }

        if (this.api.token) {
            await this.getAddonByKey({ key: 'DSS-DigitalSignature' })
            await this.getSellablePlans({ 'domain': this.domain.name, 'group': this.group })

            this.addon = Object.assign({}, this.renderedAddons['GENERAL'][0])

            this.addonTranslation()

            if (this.addon.tiers.length > 4) {
                this.addon.tiers.length = 4
            }
        }
    },

    async mounted() {

        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }
}
</script>

<style scoped lang="scss">
$ecm: #0DC5C7;
$bpm: #2C2FFA;
$dss: #E82F8A;
$dss: #962DFB;
$dss: #80BA27;
$das: #DEC902;

.section-dss-01 {
    p {
        color: rgba(0, 0, 0, .9) !important;
        font-weight: 600;
    }

    background-color: #EFF3FE;
}

.section-dss-02 {
    background-color: #FFF;
    padding-left: 26vw !important;
    padding-right: 26vw !important;

    .row {
        background-color: #EFF3FE;
        border: 2px solid #D8D4D4;
        padding: 30px;
        border-radius: 10px;

        div {
            padding: 0;

            img {
                width: auto;
            }

            p {
                margin: 0 !important;
                padding: 0 0 0 30px !important;
                text-align: center;
                display: block;

                &:first-child {
                    margin-bottom: 20px !important;
                }

                b {
                    font-weight: 700;
                }
            }
        }
    }

}


.section-dss-03 {
    background-color: #EFF3FE;

    h2 {
        width: 100%;
        text-align: center;
    }

    a {
        font-size: 16px !important;
        transition: all .4s ease;
    }

    p {
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }

    .imgOpenModal {
        margin-top: 20px;
        width: 15vw;
    }

    .item {
        padding: 30px 25px;

        .div-card {
            width: 100%;
            border-radius: 20px;
            padding: 40px 20px;
            height: 400px;
            transition: all .4s ease;
            box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
            cursor: pointer;
            text-align: center;
            background-color: #fff;

            &:hover {

                p,
                a {
                    opacity: 0;
                }

                p.description {
                    opacity: 1;
                }

                div {
                    span {
                        width: 70px;
                    }
                }
            }

            div {
                margin-bottom: -10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    display: block;
                    margin-top: 10px;
                    width: 0;
                    border-radius: 20px;
                    transition: all .2s ease;
                }

                i {
                    font-size: 50px;
                }
            }

            p {
                margin: 0 !important;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;

                b {
                    font-weight: 800;
                }

                &.description {
                    font-size: 16px !important;
                    line-height: 20px !important;
                    font-weight: 400 !important;
                    opacity: 0;
                    position: absolute;
                    padding: 20px 40px;
                    top: 130px;
                }
            }

            a {
                text-decoration: none;
                border: 1px solid transparent;
                padding: 0;
                transition: all .2s ease;
            }
        }
    }
}

.section-dss-03 .owl-item {
    &:nth-child(1) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(238, 136, 5, 0.5);
            }

            color: #EE8805;

            a:hover {
                color: #EE8805;
                border: 1px solid #EE8805;
            }

            div span {
                border-bottom: 3.5px solid #EE8805;
            }
        }
    }

    &:nth-child(2) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(148, 197, 74, 0.5);
            }

            i {
                color: #94C54A;
            }

            a:hover {
                color: #94C54A;
                border: 1px solid #94C54A;
            }

            div span {
                border-bottom: 3.5px solid #94C54A;
            }
        }
    }

    &:nth-child(3) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(128, 81, 152, 0.5);
            }

            i {
                color: #805198;
            }

            a:hover {
                color: #805198;
                border: 1px solid #805198;
            }

            div span {
                border-bottom: 3.5px solid #805198;
            }
        }
    }

    &:nth-child(4) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(232, 48, 138, 0.5);
            }

            i {
                color: #E8308A;
            }

            a:hover {
                color: #E8308A;
                border: 1px solid #E8308A;
            }

            div span {
                border-bottom: 3.5px solid #E8308A;
            }
        }
    }

}


.section-dss-05 {

    .btn-external-dss {
        background-color: $bpm !important;
        border: 2px solid $bpm !important;
        padding: 10px 15px !important;
        color: #fff !important;
        border-radius: 5px;
        text-decoration: none;
        transition: all .4s ease;

        &:hover {
            background-color: #fff !important;
            color: $bpm !important;
            transition: all .4s ease;
        }
    }

    .plans-background {
        position: absolute;
        z-index: -1;
        background-color: #4669F8;
        width: 100%;
        height: 30vw !important;
    }

    &.section-main-plans {
        .bottom-content {
            margin-bottom: 30px;
            min-height: auto !important;

            .plans-columns .plan-column .block-02 {
                margin-bottom: 20px !important;
            }
        }
    }
}

/*
.section-dss-05 {
    
    .plans-background {
        position: absolute;
        z-index: -1;
        background-color: #4669F8;
        width: 100%;
        height: 30vw !important;
    }
    
    &.section-main-plans {
        .bottom-content {
            margin-bottom: 30px;
            min-height: auto !important;

            .plans-columns {
                
                .plan-column {
                    
                    .block-01 {
                        height: auto !important;   
                        h1{
                            font-size: 21px !important;
                        }                  
                    }
                    .block-02 {
                        height: auto !important;           
                    }
                    .block-03 {
                        height: 100%;
                        border-radius: 10px;
                        background-color: #EEF4FB;
                        padding: 20px; 
                        p{
                            padding: 0 !important;
                            font-weight: 400 !important;
                            font-size: 16px;
                            line-height: 20px;

                            &:nth-child(2){
                                color: $bpm !important;
                            }
                        }
                    }
                }
            }
        }
    }
}*/


.section-dss-07 {
    background-color: #FFF;
    padding-top: 0 !important;
    position: relative;

    .background {
        position: absolute;
        z-index: 0;
        background-color: #EEF4FB;
        width: 100%;
        height: 350px !important;
        top: 0;
    }

    .content {
        width: 100%;
        padding-top: 6vw;
        z-index: 1;

        .blocks-wrapper {
            height: auto;
            display: flex;
            flex-wrap: wrap;

            .block {
                width: 20%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: stretch;
                justify-content: center;

                .inner-block {
                    border: 2px solid $bpm;
                    border-radius: 10px;
                    padding: 25px;
                    width: 100%;
                    background-color: #FFF;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    .image {
                        position: relative;

                        span {
                            color: #D85060;
                            font-weight: bold;
                            position: absolute;
                            margin-left: -15px;
                            font-size: 16px !important;
                            line-height: 16px !important;
                        }

                        img {
                            position: relative;
                            width: 100% !important;
                            height: auto;
                        }
                    }

                    p {
                        width: 100%;
                        text-align: center;
                        color: rgba(0, 0, 0, .9) !important;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1460px) {}

@media only screen and (max-width: 991px) {

    .section-dss-01,
    .section-dss-02,
    .section-dss-03,
    .section-dss-04,
    .section-dss-05,
    .section-dss-06,
    .section-dss-07,
    .section-addon,
    .section-main-module {
        h1 {
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2 {
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
        }

        h3 {
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
        }
    }


    .section-dss-02 {

        padding-left: 10px !important;
        padding-right: 10px !important;

        .row {
            padding: 20px !important;

            div {
                text-align: center;

                img {
                    width: 100px !important;
                }

                p {
                    margin: 20px 0 !important;
                    padding: 0 !important;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

    }

    .section-dss-07 {

        .background {
            height: 350px !important;
        }

        .content {
            padding: 30px 0 !important;

            .blocks-wrapper {
                flex-direction: column !important;

                .block {
                    width: 100% !important;

                    .inner-block {
                        width: 100% !important;
                    }
                }
            }
        }
    }


}

@media only screen and (min-width: 451px) and (max-width: 991px) {

    // .section-dss-05 {

    //     .top-content{
    //         h1{
    //             margin: 0 !important;
    //         }
    //     }

    //     &.section-main-plans {
    //         .bottom-content {                
    //             .plans-columns {
    //                 width: 100% !important;
    //                 padding: 10px !important;
    //                 .plan-column {         
    //                     width: 50% !important;               
    //                     min-width: 50% !important;
    //                     max-width: 50% !important;                        
    //                 }
    //             }
    //         }
    //     }
    // }
}

@media only screen and (max-width: 450px) {

    // .section-dss-05 {

    //     .top-content{
    //         h1{
    //             margin: 0 !important;
    //         }
    //     }

    //     &.section-main-plans {
    //         .bottom-content {                
    //             .plans-columns {
    //                 width: 100% !important;
    //                 padding: 10px !important;
    //                 .plan-column {         
    //                     width: 100% !important;               
    //                     min-width: 100% !important;
    //                     max-width: 100% !important;                        
    //                 }
    //             }
    //         }
    //     }
    // }
}
</style>