<template>
  <div class="bg-dark text-white py-5 d-flex align-items-center justify-content-center">
      <div class="container py-5">
          <div class="row">
               <div class="col-md-2 text-center">
                    <p><i class="fa fa-exclamation-triangle fa-5x"></i><br/>Status Code: 403</p>
               </div>
               <div class="col-md-10">
                    <h3 v-html="$t('NOT_WELCOME_TITLE')"></h3>
                    <p v-html="$t('NOT_WELCOME_MESSAGE')"></p>
                    <a class="btn btn-danger" href="javascript:history.back()">{{$t('GO_BACK')}}</a>
               </div>
          </div>
     </div>

     <div id="footer" class="text-center">
          {{domain.title}} © {{ new Date().getFullYear() }}. {{$t('ALL_RIGHTS_RESERVED')}}.
     </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
     computed: {
        ...mapState('MainStoreModule', {                                    
            domain: state => state.domain
        }),
    }, 
}
</script>

<style scoped>



.bg-dark{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
}
#footer{    
  position: fixed;  
  bottom: 0px;
}

</style>
