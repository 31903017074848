<template>
    <div>
        <v-app id="range-units">

            <v-card flat color="transparent">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <RangeTypeNormal v-if="!isLoading && selectedPlan.tiers.length > 0 && type == 'normal'"
                                :thumb_color="thumb_color" :unit_groups="unit_groups" :step="step" :min_units="min_units"
                                :max_units="max_units" :max_units_label="max_units_label" :selectedPlan="selectedPlan"
                                :renderedPlan="renderedPlan">
                            </RangeTypeNormal>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-app>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RangeTypeNormal from '../../../plans/checkout/range/RangeTypeNormal'

export default {
    components: {
        RangeTypeNormal,
    },
    props: {
        renderedPlan: {
            type: Object,
            default() {
                return {}
            }
        },
        selectedPlan: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: String,
            default: 'normal'
        },
        thumb_color: {
            type: String,
            default: "#3150E6"
        }
    },

    computed: {

        ...mapState('MainStoreModule', {
            user_monetary: state => state.user_monetary,
        })
    },

    data() {
        return {
            isLoading: true,
            unit_groups: [],
            step: 1,
            min_units: null,
            max_units: null,
            max_units_label: ''
        }
    },

    mounted() {
        this.unit_groups = this.getUnitGroups()

        const groups_length = this.unit_groups.length,
            last_group = this.unit_groups[groups_length - 1]

        this.step = 1
        this.min_units = parseInt(this.unit_groups[0].min_units)
        this.slider_unit = parseInt(this.min_units)
        this.max_units = parseInt(last_group.max_units || last_group.min_units)
        this.max_units_label = last_group.max_units ? last_group.max_units : this.$t('UNLIMITED')

        this.isLoading = false
    },

    methods: {
        getGroupPrice(index, tier) {

            let group = tier,
                min_units = group.min_units,
                max_units = group.max_units ? group.max_units : min_units,
                units_group = index == 0 ? max_units : (max_units - min_units) + 1,
                max_price_group = 0,
                unit_price = 0,
                price_id = null


            for (var i in group.prices) {
                /*
                    Based on price with discount
                */

                if (group.prices[i].currency == this.user_monetary.currency_code && group.prices[i].has_discount) {
                    price_id = group.prices[i].id
                    unit_price = parseFloat(group.prices[i].value)
                    max_price_group = unit_price * units_group
                    break
                }
            }

            return {
                id: price_id,
                unit: unit_price,
                total: max_price_group
            }
        },
        getUnitGroups() {

            var tiers = [...this.selectedPlan.tiers]

            if (tiers.length == 1 && !tiers[0].max_units) {
                tiers[0].max_units = 1000
            }

            for (var i in tiers) {

                const max_units = tiers[i].max_units || this.$t('UNLIMITED'),
                    label = `${tiers[i].min_units} - ${max_units}`

                tiers[i].label = label


                tiers[i].group_price = this.getGroupPrice(i, tiers[i])
            }

            return tiers
        }
    },
}
</script>

<style>
.v-application--wrap {
    min-height: auto;
}
</style>