<template>
  <div>
    <!-- 
            Modules
        -->
    <div
      class="section section-modules base-padding d-flex flex-column justify-content-center align-items-center"
    >
      <div class="dots-panel">
        <span
          v-for="dot in loop"
          :key="dot"
          class="dot"
          :class="{
            'background-ecm': carousel.modules.index == 0,
            'background-bpm': carousel.modules.index == 1,
            'background-swc': carousel.modules.index == 2,
            'background-dss': carousel.modules.index == 3,
          }"
        ></span>
      </div>
      <div class="div-carousel">
        <Carousel
          :per-page="1"
          :autoplay="true"
          :autoplayTimeout="5000"
          :easing="'ease'"
          :loop="true"
          :mouse-drag="true"
          @page-change="navigationHandler"
        >
          <!-- 
                        ECM
                    -->
          <slide v-if="modules.includes('ecm')">
            <div
              class="product-slide d-flex flex-row align-items-center justify-items-center"
            >
              <div class="icons d-flex flex-wrap">
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-01"
                    style="color: #2346aa"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.1')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-02"
                    style="color: #d74e82"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.2')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-03"
                    style="color: #ef9e66"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.3')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-04"
                    style="color: #f9a4a9"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.4')"></h3>
                </div>

                <div class="break"></div>

                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-05"
                    style="color: #95c94d"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.5')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-06"
                    style="color: #26c6e0"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.6')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-07"
                    style="color: #8282e6"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.7')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 ecm icon-ecm-08"
                    style="color: #6095e3"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.ECM.8')"></h3>
                </div>
              </div>
              <div class="description d-flex flex-column">
                <h2 v-html="$t('ALSO KNOW')"></h2>
                <h1 class="font-ecm">{{ $t("ECM_NO_TAGS") }}</h1>
                <h2 v-html="$t('ECM_SUBTITLE')"></h2>
                <router-link
                  class="btn btn-ecm btn-medium-width"
                  :to="`/ecm${partner_url}`"
                  >{{ $t("LEARN_MORE") }}</router-link
                >
              </div>
            </div>
          </slide>

          <!-- 
                        BPM
                    -->
          <slide v-if="modules.includes('bpm')">
            <div
              class="product-slide d-flex flex-row align-items-center justify-items-center"
            >
              <div class="icons d-flex flex-wrap">
                <div class="item">
                  <span
                    class="card-1 icon-bpm-01"
                    style="color: #2346aa"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.1')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-02"
                    style="color: #d74e82"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.2')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-03"
                    style="color: #ef9e66"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.3')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-04"
                    style="color: #f9a4a9"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.4')"></h3>
                </div>

                <div class="break"></div>

                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-05"
                    style="color: #95c94d"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.5')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-06"
                    style="color: #26c6e0"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.6')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-07"
                    style="color: #8282e6"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.7')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 bpm icon-bpm-08"
                    style="color: #6095e3"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.BPM.8')"></h3>
                </div>
              </div>
              <div class="description d-flex flex-column">
                <h2 v-html="$t('ALSO KNOW')"></h2>
                <h1 class="font-bpm">{{ $t("BPM_NO_TAGS") }}</h1>
                <h2 v-html="$t('BPM_SUBTITLE')"></h2>
                <router-link
                  class="btn btn-bpm btn-medium-width"
                  :to="`/bpm${partner_url}`"
                  >{{ $t("LEARN_MORE") }}</router-link
                >
              </div>
            </div>
          </slide>

          <!-- 
                        SWC
                    -->
          <slide v-if="modules.includes('swc')">
            <div
              class="product-slide d-flex flex-row align-items-center justify-items-center"
            >
              <div class="icons d-flex flex-wrap">
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-01"
                    style="color: #2346aa"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.1')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-02"
                    style="color: #d74e82"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.2')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-03"
                    style="color: #ef9e66"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.3')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-04"
                    style="color: #f9a4a9"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.4')"></h3>
                </div>

                <div class="break"></div>

                <div class="item">
                  <span
                    class="card-1 swc icon-swc-05"
                    style="color: #95c94d"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.5')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-06"
                    style="color: #26c6e0"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.6')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-07"
                    style="color: #8282e6"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.7')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 swc icon-swc-08"
                    style="color: #6095e3"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.SWC.8')"></h3>
                </div>
              </div>
              <div class="description d-flex flex-column">
                <h2 v-html="$t('ALSO KNOW')"></h2>
                <h1 class="font-swc">{{ $t("SWC_NO_TAGS") }}</h1>
                <h2
                  v-html="$t('SWC_SUBTITLE').replace(/{domain}/g, domain.title)"
                ></h2>
                <router-link
                  :disabled="true"
                  :event="1 == 2 ? 'click' : ''"
                  class="btn btn-swc btn-medium-width"
                  :to="`/swc${partner_url}`"
                  >{{ $t("LEARN_MORE") }}</router-link
                >
              </div>
            </div>
          </slide>

          <!-- 
                        DSS
                    -->
          <slide v-if="modules.includes('dss')">
            <div
              class="product-slide d-flex flex-row align-items-center justify-items-center"
            >
              <div class="icons d-flex flex-wrap">
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-01"
                    style="color: #2346aa"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.1')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-02"
                    style="color: #d74e82"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.2')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-03"
                    style="color: #ef9e66"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.3')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-04"
                    style="color: #f9a4a9"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.4')"></h3>
                </div>

                <div class="break"></div>

                <div class="item">
                  <span
                    class="card-1 dss icon-dss-05"
                    style="color: #95c94d"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.5')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-06"
                    style="color: #26c6e0"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.6')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-07"
                    style="color: #8282e6"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.7')"></h3>
                </div>
                <div class="item">
                  <span
                    class="card-1 dss icon-dss-08"
                    style="color: #6095e3"
                  ></span>
                  <h3 v-html="$t('MODULES_CAROUSEL.DSS.8')"></h3>
                </div>
              </div>
              <div class="description d-flex flex-column">
                <h2 v-html="$t('ALSO KNOW')"></h2>
                <h1 class="font-dss">{{ $t("DSS_NO_TAGS") }}</h1>
                <h2 v-html="$t('DSS_SUBTITLE')"></h2>
                <router-link
                  :disabled="true"
                  :event="1 == 2 ? 'click' : ''"
                  class="btn btn-dss btn-medium-width"
                  :to="`/dss${partner_url}`"
                  >{{ $t("LEARN_MORE") }}</router-link
                >
              </div>
            </div>
          </slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    modules: {
      type: Array,
      default() {
        return [];
      },
    },
    partner_url: {
      type: String,
      default: "",
    },
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      carousel: {
        modules: {
          index: 0,
        },
      },
    };
  },
  computed: {
    ...mapState("MainStoreModule", {
      user_monetary: (state) => state.user_monetary,
      domain: (state) => state.domain,
    }),

    loop() {
      var arr = [];
      while (arr.length <= 6000) {
        //10000
        arr.push(arr.length);
      }

      return arr;
    },
  },
  methods: {
    navigationHandler(index) {
      this.carousel.modules.index = index;
    },
  },
};
</script>

<style lang="scss">
$ecm: #0dc5c7;
$bpm: #2c2ffa;
$swc: #e82f8a;
$dss: #962dfb;
$slv: #80ba27;
$das: #dec902;

.section-modules {
  .dots-panel {
    width: 40%;
    min-height: 400px;
    max-height: 60%;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: -1;
    line-height: 5px !important;
    .dot {
      line-height: 5px !important;
      opacity: 0.3;
      height: 5px;
      width: 5px;
      margin: 3px 3px;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .div-carousel {
    width: 100%;
    .product-slide {
      z-index: 3 !important;
      width: 100%;
      color: #4d4d4d;
      padding: 30px 10px;
      div.icons {
        z-index: 4 !important;
        width: 70%;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.25),
          0 2px 2px rgba(0, 0, 0, 0.22);
        padding: 40px 0;
        .item {
          width: 25%;
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span.card-1 {
            margin: 0;
            width: 90px !important;
            height: 70px !important;
            font-size: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &:hover {
              color: #fff !important;
            }
            &.ecm {
              &:hover {
                background-color: $ecm;
              }
            }
            &.bpm {
              &:hover {
                background-color: $bpm;
              }
            }
            &.scs {
              &:hover {
                background-color: $swc;
              }
            }
            &.dss {
              &:hover {
                background-color: $dss;
              }
            }
          }
          h3 {
            text-align: center;
            margin: 10px 0;
            font-weight: 500 !important;
            font-size: 13px !important;
            line-height: 17px !important;
          }
        }
        span {
          background-repeat: no-repeat;
          background-size: 2vw 2vw;
          background-position: center;
          width: 6vw;
          height: 4.5vw;
          transition: all 0.4s ease;
          background-color: #fbfbfb;
          border-radius: 10px;
          margin-right: 1.5vw;
          margin-left: 0.5vw;
          &:hover {
            background-color: #2d5efc;
          }
        }
      }
      div.description {
        width: 40%;
        padding-left: 50px;
        h1 {
          font-weight: 600;
          margin: 10px 0 !important;
        }
        h2 {
          color: #717171;
          font-weight: 400;
          margin: 10px 0 !important;
        }
        a {
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .div-carousel {
    .product-slide {
      .description {
        padding: 0 !important;
        margin-bottom: 50px !important;
      }
    }
  }
}
</style>