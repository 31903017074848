<template>
  <div style="min-height: 100vh;">   
    
    <transition name="slide-fade">

        <div>

            <div class="section section-main-module">
                <div class="main-background"></div>
                
                <h1 class="mb-4 title" v-html="$t('SLV').replace(/<br>/g, '')"></h1>
                <h2 class="subtitle" v-html="$t('SLV_SUBTITLE').replace(/<br>/g, '')"></h2>            

                <div class="div-buttons base-padding d-flex flex-row justify-content-center align-items-center">
                    <a class="btn btn-1 mx-3" @click.stop="scrollto('#plans')">{{$t('LEARN_MORE')}}</a>
                    <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="btn btn-2 mx-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
                </div>

                <div class="mockup">
                    <img class="base" :src="`/assets/images/slv/mockup-01.png`" :alt="$t('SLV') + ' - ' + $t('SLV_SUBTITLE')">
                </div> 

            </div>
            
            <Clients></Clients>
            <!-- 01 -->
            <div class="section base-padding section-slv-01 d-flex flex-column justify-content-center align-items-center">
                <h1 class="mb-4 text-center">Documentos digitalizados direto da sua aplicação web em conformidade com o Decreto 10.278, de 18 de março de 2020</h1>
                <h2 class="text-center">A {{domain.title}} permite a digitalização direto no browser com assinatura digital e inclusão de metadados,nos padrões definidos pelo decreto 10.278, que define as regras para a digitalização de documentos públicos e privados, com objetivo produzir os mesmos efeitos legais dos documentos originais.</h2>
            </div>

            <!-- 02 -->
            <div class="section base-padding section-slv-02 d-flex flex-column justify-content-center align-items-center">
                <h1 class="mb-4 text-center">Abrangência de regras</h1>

                <div class="mt-2 d-flex justify-content-center align-items-center row">
                    <div class="col-lg-2 col-md-2 col-sm-12">
                        <img src="/assets/images/slv/slv-01.svg" :alt="$t('SLV_NO_TAGS')">                    
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-12">
                        <p>Com o módulo de digitalização com validade legal, o {{domain.title}} trabalha de acordo com os requisitos estipulados pela lei para assegurar que os documentos digitalizados sejam arquivados de maneira íntegra e confiável. A Plataforma {{domain.title}} é totalmente aderente às boas práticas da digitalização de documentos, contribui para que as empresas implemente seus processos totalmente digitais, além de tratar seu legado de documentos de acordo com o Decreto 10.278 que regulamenta a digitalização com validade legal, agregando muitas vantagens competitivas e uma notável redução de custos às empresas.</p>
                    </div>
                </div>
            </div>

            <!-- 03 -->            
            <div class="section section-slv-03 base-padding">            
                <h1 class="mb-4 text-center">Uma experiência agradável do início ao fim</h1>

                <div :class="{'mt-5': window.width > 991}" class="d-flex flex-row justify-content-center align-items-center">

                    <Carousel   
                        style="width: 100%;"                     
                        :margin="0" 
                        :autoplay="true"                     
                        :dots="false"
                        :nav="false"
                        :responsive="{
                            0:{
                                items: 1
                            },
                            991:{
                                items: 3
                            },
                            1400:{
                                items: 4
                            },
                        }"
                    >
                        <div class="item">
                            <div class="card-col">
                                <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                    <div><i class="icon-slv-02"></i> <span></span></div>
                                    <p>Assinaturas com <br>Validade Legal</p>
                                    <p class="description">Na plataforma {{domain.title}} você assina documentos digitalizados, ou novos documentos, de acordo com as exigências legais, e cria um acervo totalmente digital para melhor suporte às tarefas dos seus colaboradores.</p>
                                    <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="card-col">
                                <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                    <div><i class="icon-slv-03"></i> <span></span></div>
                                    <p>Gestão de <br>Documentos</p>
                                    <p class="description">A gestão de documentos e processos fica muito mais eficiente e simplificada com o {{domain.title}}, já que não é necessário lidar com várias interfaces, pois todos os recursos necessários fazem parte da Plataforma.</p>
                                    <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="card-col">
                                <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                    <div><i class="icon-slv-04"></i> <span></span></div>
                                    <p>Fluxo de <br>Assinaturas</p>
                                    <p class="description">Crie fluxos de assinatura a partir da interface do ECM ou acesse o assinador para ter mais funcionalidades e gerenciar todos os documentos com processo de assinatura em andamento ou concluídos.</p>
                                    <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="card-col">
                                <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                    <div><i class="icon-slv-05"></i> <span></span></div>
                                    <p>Descarte de <br>Documentos Físicos</p>
                                    <p class="description">Digitalize os documentos usando a captura com validade legal e descarte os documentos físicos que não possuem valor histórico. Reduza seus custos com papel, descarte as cópias desnecessárias, e deixe de imprimir documentos.</p>
                                    <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </div>
            </div>    
           
            <!-- 04 -->
            <!-- <div id="plans" class="section base-padding section-addon d-flex flex-column justify-content-center align-items-center">
                <h1 class="mb-4 text-center">SLV - Digitalização com Validade Legal</h1>
                <h2 class="text-center mb-5">Esse item pode ser utilizado para a Digitalização com Validade Legal e para a Assinatura de Documentos com uso de Certificado Digital padrão ICP Brasil.</h2>

                <div style="width: 100%;" class="addons-wrapper mt-5 d-flex flex-wrap justify-content-center align-items-stretch" v-if="addon">
                    <div v-for="(tier_item, index) in addon.tiers"  :key="index" class='addon-tier d-flex flex-row justify-content-center'>
                        
                        <div class='d-flex flex-column justify-content-center align-items-center'>
                            
                            <span v-if="tier_item.max && !tier_item.last_tier_with_price" class="text-center" v-html="
                                `${$t('UP_TO')} <b>${tier_item.max}</b> ${$t(getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular')))} / ${$t('MONTH')}`
                            "></span>

                            <span v-else class="text-center" v-html="
                                `<b>${$t(getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular')))} ${$t('UNLIMITED_plural')}</b>`
                            "></span>

                            <p class="text-center description mb-3" v-html="tier_item.description"></p>
                            
                            <p class="text-center price" v-html="
                                `${user_monetary.symbol} <b>${tier_item.total}</b> +${$t('PLAN')} / ${$t('MONTH')}`
                            "></p>
                        </div>

                    </div>
                </div>
            </div> -->

            <!-- 05 -->
            <!-- <div class="section section-main-plans section-slv-05">
                <div class="plans-background"></div>
                <Plans 
                    v-if="show" 
                    :parent="'slv'"
                    :must_have="`ECM`"
                    :title="'Escolha seu plano e adicione a opção SLV (digitalização com validade legal) na área de checkout'"
                ></Plans>
            </div> -->

            <!-- 06 -->
            <div class="section base-padding section-slv-06 d-flex flex-column justify-content-center align-items-center">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-start align-items-center">
                        <span class="mb-4 icon-purple"><i class="icon-slv-06"></i></span>
                        <h2 class="mb-4 text-center">Documento Digitalizado com Validade Legal</h2>
                        <p class="text-center">Os recursos do scanner são desenvolvidos considerando os requisitos estipulados pela lei da liberdade econômica e pelo decreto 10.278, de 18 de março de 2020, para garantir que o processo de digitalização seja íntegro e confiável.</p>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-start align-items-center">
                        <span class="mb-4 icon-yellow"><i class="icon-slv-07"></i></span>
                        <h2 class="mb-4 text-center">Formatos de Assinaturas Distintas</h2>
                        <p class="text-center">A Plataforma permite o uso de assinatura digital com certificado ICP Brasil, não ICP ou assinatura eletrônica.<br>Para atendimento ao Decreto 10.278 é obrigatório o uso de certificado digital ICP-Brasil.</p>
                    </div>
                </div>
            </div>

            <!-- 07 -->
            <div class="section base-padding section-slv-07 d-flex flex-column justify-content-center align-items-center">
                <h1 class="mb-4 text-center">Usabilidade do início ao fim</h1> 
                <h2 class="text-center mb-5 px-5">Na interface de digitalização, o scanner é automaticamente detectado e pode ser configurado para aplicar o tratamento necessário aos documentos.<br>Na mesma interface você pode digitalizar documentos comuns, ou selecionar a opção de digitalizar com validade legal. O sistema já detecta o certificado digital ativo para o usuário logado, e valida suas permissões.</h2>

                <div class="blocks-wrapper mt-3">
                    <div class="block">
                        <div class="inner-block">
                            <div class="image">
                                <span>1</span>
                                <img class='mb-3' src="/assets/images/slv/01.png" :alt="domain.title">
                            </div>
                            <p>Na janela de captura, o usuário seleciona o scanner e define as configurações prévias</p>
                        </div>
                    </div>
                    <div class="block">
                        <div class="inner-block">
                            <div class="image">
                                <span>2</span>
                                <img class='mb-3' src="/assets/images/slv/02.png" :alt="domain.title">
                            </div>
                            <p>Faz a organização dos arquivos ou trabalha com múltiplos documentos.</p>
                        </div>
                    </div>
                    <div class="block">
                        <div class="inner-block">
                            <div class="image">
                                <span>3</span>
                                <img class='mb-3' src="/assets/images/slv/03.png" :alt="domain.title">
                            </div>
                            <p>Armazena o conteúdo capturado e o sistema aplica a assinatura automaticamente.</p>
                        </div>
                    </div>
                    <div class="block">
                        <div class="inner-block">
                            <div class="image">
                                <span>4</span>
                                <img class='mb-3' src="/assets/images/slv/04.png" :alt="domain.title">
                            </div>
                            <p>O sistema arquiva a versão válida legalmente, contendo a identificação do certificado digital utilizado e os metadados aplicados em cada arquivo.</p>
                        </div>
                    </div>
                </div>               
            </div>

            <!-- 08 -->
            <div class="section base-padding section-slv-08 d-flex flex-column justify-content-center align-items-center">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-start align-items-center">
                        <span class="mb-4 icon-green1"><i class="icon-slv-08"></i></span>
                        <h2 class="mb-4 text-center">Compatibilidade</h2>
                        <p class="text-center">Compatível com os principais equipamentos disponíveis no mercado, seja scanners ou multifuncionais, pois se conecta e identifica facilmente o dispositivo que utiliza o driver TWAIN.</p>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-start align-items-center">
                        <span class="mb-4 icon-green2"><i class="icon-slv-09"></i></span>
                        <h2 class="mb-4 text-center">Custódia</h2>
                        <p class="text-center">Garante a manutenção da cadeia de custódia, salvando o documento digitalizado e assinado diretamente no sistema de destino sem passar pelo computador do digitalizador, e mantém a segurança do conteúdo e rastreabilidade.</p>
                    </div>
                </div>
            </div>
        
            <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show" class="ecm"></Join>    
            <ThePlatform :window="window" v-if="show"></ThePlatform>
        </div>
    </transition>    
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import Plans from '../plans/plans'
import PlansMixin from '../../mixins/PlansMixin'

import Carousel from 'vue-owl-carousel'

export default {
    inject: ['scrollto'],
    mixins: [PlansMixin],  
    components:{                  
        Clients,        
        ThePlatform,
        Join,
        // Plans,
        Carousel
    },
    props: {      
        isDomainPermitted:{
            type: Boolean,
            default: false
        },
        partner_url: {
            type: String,
            default: ''
        },     
        window: {
            type: Object,
            default () {
                return {
                    width: 0,
                    height: 0
                }
            }
        }
    },
    data(){
        return{                        
            show: false,
            addon: null
        }
    },

    watch:{
        lang(lang){
            this.addonTranslation()
        },        
    },

    methods: {
        ...mapActions('MainStoreModule', [  
            'getAddonByKey'
        ]),  
        
        addonTranslation(){
            if(this.addon.tiers[0]) this.addon.tiers[0].description = "Solução para<br> Micro Empresas"
            if(this.addon.tiers[1]) this.addon.tiers[1].description = "Solução acessível para<br> Pequenas Empresas"
            if(this.addon.tiers[2]) this.addon.tiers[2].description = "Solução para Médias empresas<br> de uso intensivo de papel"
            if(this.addon.tiers[3]) this.addon.tiers[3].description = "Solução customizadas para<br> clientes Corporativos"
        },
    }, 
    
    beforeCreate(){          
        this.$emit('isPageLoaded', false)  
    },

    async created(){
        
        if(!this.api.token){            
            await this.setApiToken()
        }

        if(this.api.token){    
            await this.getAddonByKey({key: 'SLV-LegalValidity'})              
            await this.getSellablePlans({ 'domain': this.domain.name, 'group': this.group } ) 

            this.addon = Object.assign({}, this.renderedAddons['GENERAL'][0])
            
            if(this.addon.tiers.length > 4){
                this.addon.tiers.length = 4
            }
            
            this.addonTranslation()
        }
    },

    async mounted(){     

        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style lang="scss">
$ecm: #0DC5C7;
$bpm: #2C2FFA;
$swc: #E82F8A;
$dss: #962DFB;
$slv: #80BA27;
$das: #DEC902;

.section-slv-01 {    
    p {
        color: rgba(0, 0, 0, .9) !important;
        font-weight: 600;
    }
    background-color: #EFF3FE;
}

.section-slv-02 {
    background-color: #FFF;
    padding-left: 20vw !important;
    padding-right: 20vw !important;    
    .row {
        background-color: #EFF3FE;
        border: 2px solid #D8D4D4;
        padding: 30px;
        border-radius: 10px;
        div{
            padding: 0;
            img {
                width: auto;
            }
            p {
                margin: 0 !important;
                padding: 0 0 0 30px !important;
                text-align: center;
            }
        }
    }
}

.section-slv-03 {    
    h2 {
        width: 100%;
        text-align: center;
    }
    a {
        font-size: 16px !important;
        transition: all .4s ease;
    }
    p {
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }
    .imgOpenModal {
        margin-top: 20px;
        width: 15vw;
    }
    .item{
        padding: 30px 25px;
            
        .div-card {
            width: 100%;
            border-radius: 20px;
            padding: 40px 20px;
            height: 400px;
            transition: all .4s ease;
            box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
            cursor: pointer;
            text-align: center;
            background-color: #fff;

            &:hover {
                p,
                a {
                    opacity: 0;
                }
                p.description {                    
                    opacity: 1;
                }
                div {
                    span {
                        width: 70px;
                    }
                }
            }
            div {                
                margin-bottom: -10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span {
                    display: block;
                    margin-top: 10px;
                    width: 0;
                    border-radius: 20px;
                    transition: all .2s ease;
                }
                i {
                    font-size: 50px;
                }
            }
            p {
                margin: 0 !important;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                b {
                    font-weight: 800;
                }
                &.description {
                    font-size: 16px !important;
                    line-height: 20px !important;
                    font-weight: 400 !important;
                    opacity: 0;
                    position: absolute;
                    padding: 20px 40px;
                    top: 130px;
                }
            }
            a {
                text-decoration: none;
                border: 1px solid transparent;
                padding: 0;
                transition: all .2s ease;
            }
        }
    }
}

.section-slv-03 .owl-item {    
    &:nth-child(1) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(238, 136, 5, 0.5);
            }
            color: #EE8805;
            a:hover {
                color: #EE8805;
                border: 1px solid #EE8805;
            }
            div span {
                border-bottom: 3.5px solid #EE8805;
            }
        }
    }
    &:nth-child(2) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(148, 197, 74, 0.5);
            }
            i {
                color: #94C54A;
            }
            a:hover {
                color: #94C54A;
                border: 1px solid #94C54A;
            }
            div span {
                border-bottom: 3.5px solid #94C54A;
            }
        }
    }
    &:nth-child(3) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(128, 81, 152, 0.5);
            }
            i {
                color: #805198;
            }
            a:hover {
                color: #805198;
                border: 1px solid #805198;
            }
            div span {
                border-bottom: 3.5px solid #805198;
            }
        }
    }
    &:nth-child(4) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(232, 48, 138, 0.5);
            }
            i {
                color: #E8308A;
            }
            a:hover {
                color: #E8308A;
                border: 1px solid #E8308A;
            }
            div span {
                border-bottom: 3.5px solid #E8308A;
            }
        }
    }

}

.section-slv-05 {
    
    .plans-background {
        position: absolute;
        z-index: -1;
        background-color: #4669F8;
        width: 100%;
        height: 30vw !important;
    }
    
    &.section-main-plans {
        .bottom-content {
            margin-bottom: 30px;
            min-height: auto !important;
            .plans-columns .plan-column .block-02 {
                margin-bottom: 20px !important;
            }
        }
    }
}

.section-slv-07 {
    background-color: #FFF;    
   
    .blocks-wrapper {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        
        .block {                    
            width: 25%;
            padding: 10px;
            display: flex;            
            justify-content: center;
            align-items: stretch;
            justify-content: center;

            .inner-block {                
                border: 2px solid $bpm;
                border-radius: 10px;
                padding: 25px;
                width: 100%;                

                display: flex; 
                flex-direction: column;           
                justify-content: flex-start;
                align-items: center;
                
                .image {
                    position: relative;
                    span {
                        color: #D85060;
                        font-weight: bold;
                        position: absolute;
                        margin-left: -15px;
                        font-size: 16px !important;
                        line-height: 16px !important;
                    }
                    img {
                        position: relative;
                        width: 100% !important;
                        height: auto;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    color: rgba(0, 0, 0, .9) !important;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                }
            }
        }
    }
}

.section-slv-06,
.section-slv-08 {
    background-color: #EFF3FE;
    .row {
        div {
            padding: 0 80px;
            h2 {
                color: $bpm !important;
            }
            p {
                color: rgba(0, 0, 0, .9) !important;
            }
            span {
                padding: 15px;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                    font-size: 35px;
                    color: #fff;
                }
                &.icon-purple {
                    background-color: #972DFA;
                }
                &.icon-yellow {
                    background-color: #FDC800;
                }
                &.icon-green1 {
                    background-color: #81C41C;
                }
                &.icon-green2 {
                    background-color: #00C7AF;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px){

    .section-slv-01,
    .section-slv-02,
    .section-slv-03,
    .section-addon,    
    .section-slv-06,
    .section-slv-07,
    .section-slv-08,
    .section-main-module {
        h1{
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2{
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
        }

        h3{
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
        }
    }
    
    .section-slv-02 {
        
        padding-left: 10px !important;
        padding-right: 10px !important;  
        .row {            
            padding: 20px !important;
            
            div{
                text-align: center;
                img {
                    width: 100px !important;
                }
                p {
                    margin: 20px 0 !important;
                    padding: 0 !important;
                    
                    &:last-child{
                        margin-bottom: 0 !important;
                    }
                }
            }
        }  
        
    }

    .section-slv-07 {
        
        .blocks-wrapper {
            flex-direction: column !important;
            
            .block {                    
                width: 100% !important;

                .inner-block {                
                    width: 100% !important;
                }
            }
        }
    }

    .section-slv-06,
    .section-slv-08 {
        .row{
            div{
                padding: 30px 10px !important;
            }
        }
    }

}
</style>