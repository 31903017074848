<template>
  <div style="min-height: 100vh;">        
    
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-process purchaseOrder d-flex flex-row justify-content-center align-items-center">

          <div class="col-lg-6 col-md-6 col-sm-12 mockup" v-if="window.width > 991">
            <img :src="`/assets/images/domains/${domain.name}/processes/purchaseOrder/mockup_01_${lang}.png`" :alt="$t('PROCESS_PURCHASE_ORDER')">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 div-text">
            <div class="header-main-process d-flex flex-row justify-content-start align-items-center">
              <div class='icon icon-shape purchaseOrder'>
                <img src="/assets/images/processes/icons/02.png" :alt="$t('PROCESS_PURCHASE_ORDER')" />
                <img src="/assets/images/processes/icons/02_hover.png" :alt="$t('PROCESS_PURCHASE_ORDER')" />
              </div>
              <h1 class="mb-4 title" v-html="$t('PROCESS_PURCHASE_ORDER')"></h1>
            </div>
            <h2 class="subtitle" v-html="$t('THIS PR.....')"></h2>
            
            <div class="div-buttons d-flex">
              <router-link class="btn purchaseOrder btn-medium-width" :to="`/start-free${partner_url}`">{{$t("START_FREE")}}</router-link>
              <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-4 px-3 btn purchaseOrder-alternative" :to="`/plans${partner_url}`">{{$t("BECOME_A_PARTNER")}}</router-link>
            </div>
          </div>

        </div>
    </transition>

    <img class="top-image" src="/assets/images/processes/top-01.png" :alt="`${domain.title}`">

    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description purchaseOrder">
          <div class="row">
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/purchaseOrder/icons/01.png" :alt="$t('USERS A.....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('USERS A.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/purchaseOrder/icons/02.png" :alt="$t('IF THE .....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('IF THE .....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/purchaseOrder/icons/03.png" :alt="$t('WHEN RE.....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('WHEN RE.....')"></p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/purchaseOrder/icons/04.png" :alt="$t('THE PUR.....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('THE PUR.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/purchaseOrder/icons/05.png" :alt="$t('WHEN TH.....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('WHEN TH.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/purchaseOrder/icons/06.png" :alt="$t('IN THE .....')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('IN THE .....')"></p>
              </div>
            </div>
          </div>
        </div>
    </transition>


    <transition name="slide-fade">
        <div class="section section-flow-process-purchaseOrder">
          <h1 v-html="$t('THE FUL.....')"></h1>

          <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-12 mockup">
              <img :src="`/assets/images/domains/${domain.name}/processes/purchaseOrder/mockup_02.png`" :alt="$t('THE FUL.....')">
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-end">
              <ul class='mb-5'>
                <li class="mt-5 mb-5">
                  <img src="/assets/images/processes/check.png" :alt="$t('SOLICIT.....')">
                  <p v-html="$t('SOLICIT.....')"></p>
                </li>
                <li class="mt-5 mb-5">
                  <img src="/assets/images/processes/check.png" :alt="$t('APPROVA.....')">
                  <p v-html="$t('APPROVA.....')"></p>
                </li>
                <li class="mt-5 mb-5">
                  <img src="/assets/images/processes/check.png" :alt="$t('PRICE.....')">
                  <p v-html="$t('PRICE.....')"></p>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-end">
              <ul class='mb-5'>
                <li class="mt-5 mb-5">
                  <img src="/assets/images/processes/check.png" :alt="$t('PURCHAS.....')">
                  <p v-html="$t('PURCHAS.....')"></p>
                </li>
                <li class="mt-5 mb-5">
                  <img src="/assets/images/processes/check.png" :alt="$t('MATERIA.....')">
                  <p v-html="$t('MATERIA.....')"></p>
                </li>
                <li class="mt-5 mb-5">
                  <img src="/assets/images/processes/check.png" :alt="$t('PENDING.....')">
                  <p v-html="$t('PENDING.....')"></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </transition>
    


    <!-- 

        Join......

    -->  
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></Join>
    </transition>

    <transition name="slide-fade">
        <div style="padding-bottom: 0 !important; padding-left: 15vw; padding-right: 15vw;" class="section section-process-description ml-5 mr-5">
          <p style="font-size: 30px; line-height: 40px;" v-html="$t('DOCUMEN.....')"></p>
        </div>
    </transition>

    <!-- 

        See also......

    -->  
    <transition name="slide-fade">             
        <OtherProcesses :partner_url="partner_url" v-if="show" :page="page" :path="path"></OtherProcesses>
    </transition>

    <!-- 

        Automate......

    -->  
    <transition name="slide-fade">             
        <AutomateProcesses :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></AutomateProcesses>
    </transition>

    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="clients-bpm"></Clients>
    </transition> 

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import OtherProcesses from '../common/OtherProcesses'
import AutomateProcesses from '../common/AutomateProcesses'
import $ from 'jquery'

export default {
    
    components:{
      Clients,
      Join,
      ThePlatform,
      OtherProcesses,
      AutomateProcesses,      
    },
    props: {      
      isDomainPermitted:{
          type: Boolean,
          default: false
      },
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      },
      page: {
        type: String,
        default: ''
      },
      path: {
        type: String,
        default: ''
      },
      partner_url: {
          type: String,
          default: ''
      },
    },
    data(){
        return{            
          show: false,            
        }
    },
    computed: {

      
      ...mapState('MainStoreModule', {                                  
          domain: state => state.domain
      }),
      
      
      lang(){
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">

.section-flow-process-purchaseOrder {
    padding: 6vw 10vw;
    background-color: #F3F6FF;
    h2 {
        margin-bottom: 50px;
        width: 100%;
        text-align: center;
    }
    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 25px;
            img {
                height: 25px;
                margin-right: 20px;
            }
            p {
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
  .section-flow-process-purchaseOrder,  
  .section-blocks-description{
      .row{
          margin: 0 !important;
      }
  }  

  .section-flow-process-purchaseOrder{
    .mockup {
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        position: relative;
        align-self: center;
        width: 80% !important;
        margin-bottom: 30px;
      }
    }

    .row{
      div{
        padding: 0 !important;
      }
    }

    ul{
      margin: 0 !important;

      li{        
        margin: 0 0 10px 0 !important;
        img{
          height: 15px !important;          
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }
  }
}
    
</style>