<template>
    <div class="documentation" v-if="Object.keys(business_partner).length > 0">

        <ol class="numbered-list mb-5 no-print">

            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">

                    <li class="link"><span @click.stop="scrollto('.agreement-list', 1)">Definitions</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 2)">Commitments</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 3)">Duration</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 4)">Commercial policy and
                            disclosure</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 5)">Delivery of materials to the
                            Private Label Partner</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 6)">Partner category and
                            compensation</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 7)">Product sales tax</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 8)">Interfy's General
                            Obligations</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 9)">General Private Label Partner
                            Obligations</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 10)">Marketing</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 11)">Demonstration</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 12)">Training</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 13)">Interfy platform support and
                            update</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 14)">Right on the Interfy
                            platform</span></li>

                </div>

                <div class="col-sm-12 col-md-6 col-lg-6">

                    <li class="link"><span @click.stop="scrollto('.agreement-list', 15)">Corrections and
                            Modifications</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 16)">Confidentiality</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 17)">No competition</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 18)">Property Rights</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 19)">Brands</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 20)">Interfy's representations and
                            warranties</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 21)">Private Label Partner
                            Indemnification</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 22)">Termination</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 23)">Termination effect</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 24)">Performance of obligations
                            arising from the agreement after termination</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 25)">Legal Relationship</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 26)">Task</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 27)">Interpretation</span></li>
                    <li class="link"><span @click.stop="scrollto('.agreement-list', 28)">General</span></li>

                </div>
            </div>
        </ol>

        <h1 class="mb-40" style="width: 100%; text-align: center;">PARTNERSHIP AGREEMENT - PRIVATE LABEL</h1>
        <p class="mb-30">This PARTNERSHIP AGREEMENT - PRIVATE LABEL for software resale, allows INTERFY to customize its
            platform with the partner's unique brand and URL, for the commercialization of the INTERFY software Platform
            (the "Agreement"), enters into force on the date of its Association.</p>

        <table class="mb-40">
            <tr>
                <td style="vertical-align: top; padding-right: 20px; font-size: 20px;"><b>BETWEEN:</b></td>
                <td>
                    <p class="mb-20">
                        <b>INTERFY INTERNATIONAL LLC </b> (the "INTERFY"), a company incorporated and existing under the
                        laws of the State of Florida, EIN 32-0508424, with registered office located at:
                    </p>
                    <p class="mb-30">
                        6000 Metrowest Blvd, Suite 203<br>
                        Orlando, FL - United States<br>
                        Zip Code: 32835<br><br>
                        E-mail: partners@interfy.io
                    </p>
                </td>
            </tr>

            <tr>
                <td style="vertical-align: top; padding-right: 20px; font-size: 20px;"><b>AND:</b></td>
                <td>
                    <p class="mb-20">
                        <b>{{ business_partner.name.toUpperCase() }}</b> ("PARTNER PRIVATE LABEL"), a company
                        incorporated
                        and existing under the laws of ({{ business_partner.country }}), headquartered in:
                    </p>
                    <p class="mb-30" v-if="Object.keys(business_partner).length > 0">
                        <span v-html="business_partner.address"></span>
                        <br><br>
                        E-mail: {{ business_partner.email }}
                    </p>
                </td>
            </tr>
        </table>

        <p class="mb-30">WHEREAS INTERFY is the SOLE owner of the software platform and brand known as INTERFY and the
            sole owner of all intellectual property rights in the INTERFY Platform;</p>
        <p class="mb-30">WHEREAS INTERFY has developed and plans to continue the development of technology solutions
            (platform and software) that integrate new concepts for cloud business management and other platforms and
            related technological innovations where in an exclusive format using its own brand together with its PRIVATE
            LABEL PARTNERS , based on the INTERFY Platform;</p>
        <p class="mb-30">WHEREAS INTERFY is not owned by, nor has any legal or tax association nor obligations with the
            PRIVATE LABEL PARTNER (hereinafter "PRIVATE LABEL PARTNER") outside the contractual clauses stated herein
            through this mutual agreement, in order to obtain legal permission from INTERFY to the PRIVATE LABEL PARTNER
            to market INTERFY's products, software and services;</p>
        <p class="mb-30">No interference or coding modification shall be transferred to PRIVATE LABEL PARTNER and/or its
            related or associated parties at any time, and this agreement does not constitute any source of transfer of
            title to the software, nor does it grant BUSINESS PARTNER any intellectual property rights, nor does it
            grant the BUSINESS PARTNER any other right, other than just marketing existing Interfy products and services
            that use the same brand (Interfy).</p>
        <p class="mb-30">WHEREAS INTERFY has other technologies and plans to deliver other products that can be adapted,
            including Software known as PROCESSDOC, DOCSYSTEM, CLOUDDOC, INTELLIFY and NEOFY, as well as software to be
            used for Business Management purposes, which are currently under development and are not treated or
            interfered with this agreement;</p>
        <p class="mb-30">WHEREAS INTERFY is developing similar products to be adapted to its software, which in the near
            future may also be assigned as part of this agreement to the PRIVATE LABEL PARTNER and everything is
            guaranteed that it is not related in any sense or form to the transfer of intellectual properties relating
            to the Software, or coding of the platform nor the transfer of creation processes or concept of INTERFY;</p>
        <p class="mb-30">WHEREAS this instrument does not grant or transfer to the PRIVATE LABEL PARTNER any rights
            other than those specified in this agreement and any intellectual property rights related to the Software,
            the Platform Source Code or any Creation and Processes developed by INTERFY. Reverse engineering or any
            other type of technology is prohibited to promote any changes to the products provided, except those
            provided for in the tools included in the INTERFY Software Platform;</p>
        <p class="mb-30">WHEREAS PRIVATE LABEL PARTNER accepts the guarantee, within the limited scope provided by it
            and agrees to use its best efforts to transmit the features, benefits and prices of the INTERFY Platform
            products to its customers.</p>
        <p class="mb-30">WHEREAS the PRIVATE LABEL PARTNER agrees that, in order to market INTERFY's products, it will
            be subject to the rules established by INTERFY, not being able to use illegal or unfair competition
            practices to act in the commercialization of the product or to practice acts that characterize bad faith,
            lack of professional, crimes against the financial system or any type of punishable crime.</p>
        <p class="mb-30">WHEREAS PRIVATE LABEL PARTNER acknowledges and agrees that INTERFY, by entering into this
            Agreement, by entering into this Agreement, is counting on the efforts that will be made by the BUSINESS
            PARTNER in relation to the sales and marketing force to achieve the best possible results;</p>
        <p class="mb-30">WHEREAS INTERFY is granting the PRIVATE LABEL PARTNER, as of the date of this document,
            non-exclusive marketing rights for INTERFY products;</p>
        <p class="mb-30">CONSIDERING the conclusion of the above-mentioned BUSINESS PARTNER agreements between INTERFY
            and the PRIVATE LABEL PARTNER, it is an essential condition for the execution of this PRIVATE LABEL PARTNER
            agreement by INTERFY.</p>

        <p>
            NOW IT IS AGREED as follows:
        </p>

        <ol class="agreement-list numbered-list">
            <li class="item">
                <p><b>DEFINITIONS</b></p>

                <p>In this Agreement, unless the context requires otherwise, the following expressions have the
                    following meanings:</p>
                <p>"Business Day" means a day other than a Saturday, Sunday or public holiday;</p>
                <p>"Competitive Product" means any computer software that offers the same functionality or substantially
                    similar features as any product in INTERFY's portfolio, at a price approximately the published list
                    price of the Product in the Territory, or part thereof, at that time;</p>
                <p>The INTERFY PLATFORM and any other products derived from it constitute a software system developed by
                    INTERFY and designed to assist in the management of document processing, process management,
                    collaboration, among other features;</p>
                <p>"Customer" means the beneficiary of a Workspace;</p>
                <p>The INTERFY platform and any products derived from it provide document scanning and import
                    capabilities; content search, content version control, with records management; role management;
                    process management, using BPMN notation, and execution of tasks and activities 100% online, without
                    any installation; among other resources.</p>
                <p>"Region" means the place where the PRIVATE LABEL PARTNER is authorized to market the INTERFY
                    Platform.</p>
                <p>"Intellectual Property Rights" includes patents, trademarks, service marks, registered designs,
                    integrated circuit topography, including applications to any of the foregoing, as well as
                    copyrights, design rights, know-how, confidential information, trade names and trade and any other
                    similar rights in any country;</p>
                <p>"Workspace" means the workspace granted by Interfy to a Customer to operate on the Platform, under
                    the terms and conditions set forth herein;</p>
                <p>"Non-exclusive Products" means those products for which non-exclusive rights are granted to the
                    PRIVATE LABEL PARTNER;</p>
                <p>"Non-exclusive Region" means the location where the partner operates.</p>
                <p>"Portable Personal Computer" means a rugged mobile computer developed and designed by PRIVATE LABEL
                    PARTNER to function in a hostile environment;</p>
                <p>"Preferred" means under terms and conditions no less favorable to the beneficiary of such conditions
                    as those given, at the relevant time, to any third party in the same or similar circumstances;</p>
                <p>"Product" means the cloud version of a particular product, based on the INTERFY Platform, owned by
                    INTERFY, and all modifications, enhancements and replacements thereof and additions that may be
                    provided by INTERFY and made available to the PRIVATE LABEL PARTNER from time to time time in
                    accordance with this Agreement and will include any Planned Product once INTERFY deems, in its sole
                    discretion, that any Planned Product has reached a sufficient level of development to be ready for
                    resale;</p>
                <p>"Product Materials" means the relevant Product, together with the Product Description and Product
                    Documentation relating to this Product;</p>
                <p>“Related Agreements” means the PRIVATE LABEL PARTNER agreements entered into between INTERFY and the
                    PRIVATE LABEL PARTNER on the date hereof;</p>
                <p>"Source code" means all existing and available logics, logic diagrams, flowcharts, orthographic
                    representations, algorithms, routines, subroutines, utilities, modules, file structures, coding
                    sheets, coding, source code listings, specifications functionalities, program specifications and all
                    other existing and available materials and documents necessary to enable a reasonably qualified
                    programmer to maintain, change and improve the relevant Product without reference to any other
                    person or documentation, whether in human or machine readable form;</p>
                <p>"Services" means any Product support services provided or to be provided by PRIVATE LABEL PARTNER
                    pursuant to any agreement entered into with a Customer, including for installation, support, custom
                    programming and enhancement of the Products;</p>
                <p>"Standard" means published standard terms and conditions or, in the absence of such publication,
                    terms and conditions normally granted to unrelated third parties in the ordinary course of business
                    for the provision of a particular product or service;</p>
                <p>"Taxes" means duties, sales, goods and services, excise and value added taxes;</p>
                <p>"Territory" shall be understood as referring only to the State, or part thereof, to which, as the
                    case may be, non-exclusive marketing rights with respect to the Product, Product Materials, Product
                    Documentation or relevant Product Description are granted to the PRIVATE LABEL PARTNER in accordance
                    with paragraphs 2.1 of this Agreement;</p>
                <p>"Trademarks" means the following names and trademarks: “INTERFY”;</p>

            </li>

            <li class="item">
                <p><b>COMMITMENT</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>A INTERFY grants the PRIVATE LABEL PARTNER, who accepts it, the right to market the INTERFY
                            Platform to its customers, only in the country where the partner's company is based with its
                            BRAND, URL and exclusive SITE, where the PRIVATE LABEL PARTNER will provide the product
                            name, website domain and product URL so that INTERFY can customize it within 10 business
                            days;</p>
                    </li>

                    <li>
                        <p>PRIVATE LABEL PARTNER must comply with all obligations expressed and assumed in this
                            contract;</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>DURATION</b></p>

                <p>
                    This Agreement will begin on the date on which it is accepted and will remain in force for a period
                    of 3 (three) years, with its automatic renewal for an equal period, successively, as agreed between
                    the parties. If there is no agreement between the parties, this contract will be automatically
                    terminated, without the need for prior notice on the part of INTERFY.
                </p>
            </li>

            <li class="item">
                <p><b>COMMERCIAL POLICY AND DISCLOSURE</b></p>

                <ol class="numbered-list">
                    <li>The platform with the partner's brand can be marketed through online sales using Digital
                        Marketing, through ads on Google, Linkedin and campaigns made through social networks such as
                        Facebook, Instagram and also marketing emails.</li>
                    <li>When a company becomes a Private Label Partner, Interfy will make the product available with its
                        brand and a website so that it can use it to make its sales.<br>Ex.: www.marcadoparceiro.com so
                        that it can publicize and campaigns, and thereby strengthen your own brand. </li>
                    <li>That is, a URL containing the partner's brand name so that he can promote and carry out his
                        marketing campaigns, and all sales made from that URL are accounted for in the Administration
                        and Management software that INTERFY provides to the PARTNER PRIVATE LABEL.</li>
                    <li>The product must be marketed considering the costs per user, storage space and optional modules
                        or functionality;</li>
                    <li>Some options will be released as soon as the configuration and adhesion are done by the end
                        customer's workspace administrator, which will be automatically counted by the system according
                        to the consumption calculated and charged to the PRIVATE LABEL PARTNER. This information will be
                        available in the workspace administration system provided by INTERFY to the PRIVATE LABEL
                        PARTNER, where he will be able to monitor the consumption of each customer and the details of
                        each billing received from INTERFY.</li>
                </ol>
            </li>

            <li class="item">
                <p><b>DELIVERY OF MATERIALS TO THE PRIVATE LABEL PARTNER</b></p>

                <ol class="numbered-list">
                    <!-- <li>
                        <p>This process begins after acceptance of this contract and payment of the setup fee (one-off
                            fee), as agreed with Interfy's channel department. This fee refers to the installation and
                            configuration of the partner's branded platform on Amazon AWS, which will have unlimited ECM
                            and BPM users. To cover the costs of the platform, the partner will have to pay Interfy 50%
                            of the cost of the site on their sales. This charge will come into effect once the number of
                            users exceeds the number contracted when signing up to this instrument (25 users).</p>
                    </li> -->
                    <li>
                        <p>
                            Once this contract has been accepted, our channel team will contact the reseller
                            to explain how the Private Label partnership program works. Once everything has been agreed, the
                            Business Partner will receive all the items below:
                        </p>
                    </li>
                    <li>
                        <p>
                            The PRIVATE LABEL PARTNER undertakes to pay the setup fee (one-off fee), as
                            agreed with Interfy's channel department, relating to the installation and configuration of the
                            platform with its brand on Amazon AWS, and the customization of the partner's brand on the platform
                            and website. Interfy will provide a platform cloud structure with the partner's branding.
                            with unlimited ECM and BPM users and other modules. The partner will pay
                            Interfy 50% of the cost of the site on its sales.
                        </p>
                    </li>
                    <li>
                        <p>The product will be provided with the brand of the PRIVATE LABEL PARTNER, composed of the ECM
                            (Enterprise Content Management) and BPM (Business Process Management) that must be marketed
                            together, or according to packages pre-defined by INTERFY following the price published on
                            the INTERFY website. and other private label partners following a national standard for each
                            country.</p>
                    </li>
                    <li>
                        <p>In addition to the product with your brand and unique URL, a website customized with your
                            brand and all your company's contact data, login and password to access the management
                            environment. </p>
                    </li>
                    <li>
                        <p>Access to a DEMO System with a workspace with ECM and BPM with 5 users for internal use in
                            your company and in demonstration and training for your customers.</p>
                    </li>
                    <li>
                        <p>Access to the Administration and Sales Management Software of the INTERFY Platform, where the
                            Business Partner will manage their customers, consult invoices issued by INTERFY, check the
                            consumption of each customer for the issuance of their monthly charge, block the customer's
                            access in case of default, request the definitive elimination of customer bases when the
                            contract is canceled, among other actions, which are specified in the training videos
                            available at INTERFY university.</p>
                    </li>
                    <li>
                        <p>The delivery period is up to 10 (ten) business days after receipt of the product brand,
                            product domain and website.</p>
                    </li>
                    <li>
                        <p>Upon receipt of the items specified above, the PRIVATE LABEL PARTNER must immediately start
                            the process of marketing their new product.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>PARTNER CATEGORY AND COMPENSATION</b></p>
                <p>This contract includes only one partnership level: PRIVATE LABEL, as follows:</p>

                <ol class="numbered-list">

                    <li>
                        <p>PRIVATE LABEL PARTNER, is a partner that works with its own brand making sales to its
                            customer base and billing them directly, for this category the partner has a <b>50%
                                discount</b> on the sale price published on the INTERFY website.</p>
                    </li>
                    <!-- <li><p>For this partnership model, the PRIVATE LABEL PARTNER needs to make an investment (one-time fee) of US$10,000.00 referring to the installation and configuration of the INTERFY Platform on Amazon AWS with unlimited users of ECM and BPM, and the customization of the platform with personalization of the partner's brand and website.</p></li> -->
                    <li>
                        <p>For Online Certification at Interfy University, the Partner of this category will be able to
                            write up to 10 people to do training at INTERFY University for free per year. </p>
                    </li>
                    <li>
                        <p>When the partner starts making sales to its customers, consumption is accounted for, and a
                            monthly invoice is closed on the last day of the current month, and after applying the
                            partner's discount, the system will charge the part belonging to INTERFY, by international
                            means of payment.</p>
                    </li>
                    <li>
                        <p>If, in the TEST DRIVE period or at the end, the contracting is carried out, an invoice will
                            be issued and charged within up to 3 days from the Partner, if the partner's payment method
                            is a credit card, if it is with a bank slip, the charge will be unified and sent together
                            with the monthly consumption amount. Once the workspace is contracted, the monthly
                            consumption starts to count, which will be included in the closing of the next invoice.
                            Since all the billing made by the company INTERFY USA will be with the issuance of an
                            invoice. </p>
                    </li>
                    <li>
                        <p>All monthly consumption invoices will be billed on the first day of the month following the
                            closing date.</p>
                    </li>
                    <li>
                        <p>If a partner is in default for 30 days, this agreement will automatically terminate and the
                            partner will be disqualified. All access to the Platform and the Administration environment
                            will be blocked and all the partner's customer bases will be transferred to INTERFY or
                            permanently eliminated without the possibility of recovery, in case the customer no longer
                            wishes to use the services. To this end, INTERFY will notify the Partner and the customers,
                            informing them of the disqualification of the partner and the possibility of the customers
                            continuing to use the services so that they do not suffer any damage as a result of the
                            termination of the partnership agreement.</p>
                    </li>
                    <li>
                        <p>If the Partner is disqualified, due to default, or for any factor resulting from the
                            non-compliance with the contractual clauses by the Partner, or termination of this contract
                            until its expiration, when the Partner does not express an interest in renewing this
                            partnership agreement, INTERFY is exempt from the payment of any amount as a fine or
                            indemnity to the Partner, or as remuneration for the transfer of customers.</p>
                    </li>
                    <li>
                        <p>THE PRIVATE LABEL PARTNER undertakes to pay the amount of its monthly consumption calculated
                            in a timely manner. Failure to pay the amounts due for the performance of this Agreement, on
                            the due date, will be subject to the following sanctions:</p>
                    </li>
                    <li>
                        <p> Fine of 5% (five percent), levied on the total amount of unpaid debt, on the day after
                            maturity; e</p>
                    </li>
                    <li>
                        <p> Interest on arrears per month (or fraction of a month) of 1% (one percent), from the 1st day
                            following the due date and levied on the value of the unpaid debt; e</p>
                    </li>
                    <li>
                        <p> After 10 (ten) days of late payment or 3 attempts to collect the invoice by the system, the
                            services will be suspended. Access to the service will only be restored with the payment of
                            the amount due, including the late payment charges provided for in this contract, without
                            prejudice to the possibility of contractual termination and extrajudicial or judicial
                            collection by INTERFY.</p>
                    </li>
                    <li>
                        <p> The provisions of this section will survive termination, for any reason, provided that you
                            agree.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>TAXES ON PRODUCT SALES</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>The Partner registered in the Platinum category will be responsible for the payment of all
                            taxes applicable to any Product/Service marketed by him, in accordance with the terms
                            hereof. </p>
                    </li>

                    <li>
                        <p>If INTERFY has paid such taxes on behalf of the PRIVATE LABEL PARTNER, then it will have the
                            right to be reimbursed by the PRIVATE LABEL PARTNER upon presentation of any proof of such
                            payment.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>INTERFY'S GENERAL OBLIGATIONS:</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>Provide the PRIVATE LABEL PARTNER with the cloud infrastructure to support the contracts that
                            will be signed with end customers in accordance with the commercial policy defined by
                            INTERFY;</p>
                    </li>
                    <li>
                        <p>Provide the PRIVATE LABEL PARTNER with control tools to enable and disable clients in its
                            cloud environment through a non-transferable login and password;</p>
                    </li>
                    <li>
                        <p>Be responsible for the proper functioning of the cloud environment and the Software Platform
                            for satisfactory customer service, with access availability with an index equal to or
                            greater than 99.00% (ninety-nine percent) of the month;</p>
                    </li>
                    <li>
                        <p>Provide the PRIVATE LABEL PARTNER with the technical and commercial support necessary for the
                            commercialization of the Platform;</p>
                    </li>
                    <li>
                        <p>Provide the PRIVATE LABEL PARTNER with means for training in the use and parameterization of
                            the platform tools whenever necessary.</p>
                    </li>
                    <li>
                        <p>Strive to respond as quickly as possible to all reasonable doubts raised by the PRIVATE LABEL
                            PARTNER or Customers regarding the technical use or application of the Product;</p>
                    </li>
                    <li>
                        <p>Provide PRIVATE LABEL PARTNER promptly with all information and assistance necessary to
                            enable BUSINESS PARTNER to properly perform its obligations set forth herein with respect to
                            any modified, enhanced or replacement version or addition to the Product.</p>
                    </li>
                    <li>
                        <p>INTERFY must make available to the PRIVATE LABEL PARTNER the login and password that must be
                            under its power to manage the cloud environment and all actions that are available in this
                            environment, including the request to delete the production database.</p>
                    </li>
                    <li>
                        <p>INTERFY may, at its discretion, disclose the name, telephone number, e-mail, contact and logo
                            of the partner on the website and advertising material during the term of the contract.</p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>GENERAL OBLIGATIONS OF THE PRIVATE LABEL PARTNER:</b></p>
                <p>
                    The PRIVATE LABEL PARTNER is subject to all the specific obligations set out in this contract:
                </p>

                <ol class="numbered-list">
                    <li>
                        <p>The PRIVATE LABEL PARTNER undertakes to pay the setup fee (one-off fee), as agreed with
                            Interfy's channel department, for the installation and configuration of the platform with
                            its brand on Amazon AWS, and the customization of the partner's brand on the platform and
                            website.</p>
                    </li>
                    <!-- <li>
                        <p>In addition to the setup fee, the PRIVATE LABEL PARTNER must hire 25 users of the Enterprise
                            plan with a 50% discount so that the partner can start selling the platform's products under
                            its brand.</p>
                    </li> -->
                    <li>
                        <p>In this category, the partner has no defined minimum target and their contract will be valid
                            for 3 years and can be automatically renewed for an equal period by agreement between the
                            parties.</p>
                        <!-- <p>Comercializando um desses dois pacotes o parceiro poderá gerar recursos para cobrir o custo mensal de <b>R$ 1.600,00</b>. O Parceiro só receberá faturas extras quando consumir os planos concedidos. Com esses planos gratuitos, o parceiro pode conseguir um faturamento total de <b>R$ 3.600,00 mensal</b>, o que é o dobro do valor comprometido com a mensalidade e irá ajudar a recuperar o investimento inicial.</p> -->
                    </li>
                    <li>
                        <p>The PRIVATE LABEL PARTNER must manage its cloud environment with excellence, neither creating
                            nor allowing the creation of workspaces without there being a real business demand for the
                            service;</p>
                    </li>
                    <li>
                        <p>The PRIVATE LABEL PARTNER undertakes to pay the monthly consumption fees of its customers on
                            the platform, plus any additional fees that its customers may have;</p>
                    </li>
                    <li>
                        <p>The PRIVATE LABEL PARTNER agrees that it will not make any representations or create any
                            warranties, express or implied, in relation to INTERFY and that it will take all steps to
                            ensure that its employees, agents and others under its direction comply with the terms and
                            conditions of this provision and this agreement.</p>
                    </li>
                    <li>
                        <p>Provide customers with professional and timely product support, including, without
                            limitation, advice and assistance in connection with product setup and use;</p>
                    </li>
                    <li>
                        <p>Promptly inform INTERFY of any facts or opinions that the PRIVATE LABEL PARTNER is aware of
                            that may be relevant in relation to the commercial exploitation of the Product and that are
                            advantageous or disadvantageous for the interests of such exploitation;</p>
                    </li>
                    <li>
                        <p> Observe and comply with all United States Laws and State of Florida Laws, orders,
                            ordinances, notices, rules and regulations relating to or in any way applicable to the sale
                            and use of the Products in the Territory;</p>
                    </li>
                    <li>
                        <p> Not to make any promises or representations or give any warranties or indemnities in
                            relation to the Products, except as provided below or as expressly authorized by INTERFY in
                            writing;</p>
                    </li>
                    <li>
                        <p> Refrain from providing the product to any person knowing that the product cannot meet that
                            person's specified requirements;</p>
                    </li>
                    <li>
                        <p> Not to provide or recommend any computer equipment to a customer for use in conjunction with
                            the Product, except for equipment that may be recommended by INTERFY to the PRIVATE LABEL
                            PARTNER from time to time;</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>MARKETING</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>O Partner agrees to develop digital marketing campaigns and publicize its product to attract
                            customers to its website, complying with the specifications and good practices of using
                            digital marketing.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>DEMONSTRATION</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>INTERFY grants the PRIVATE LABEL PARTNER access to a DEMO System in a Workspace, free of
                            charge for use in its resale and in demonstration to customers during the term of this
                            Agreement.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>TRAINING</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>The PRIVATE LABEL PARTNER will receive access to INTERFY University so that its employees can
                            take online training and be certified on the Platform, which should happen whenever there
                            are new versions that are released annually.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER must always keep its staff trained to provide training to customers in
                            accordance with the best practices followed in the software industry and in accordance with
                            INTERFY policies;</p>

                        <ol class="numbered-list">

                            <li>
                                <p>It is agreed between the parties that the employees, partners, collaborators or
                                    agents of each of the parties do not have an employment relationship with the other,
                                    with each signatory of this agreement being solely and exclusively responsible for
                                    the payment of labor charges and social security contributions of their respective
                                    employees and/or third parties.</p>
                            </li>
                            <li>
                                <p>Each party hereby assumes full and unrestricted responsibility for labor claims
                                    related to the object of this contract, eventually proposed by its employees against
                                    the other party.</p>
                            </li>
                            <li>
                                <p>Each party agrees and undertakes to indemnify the other party for any and all costs
                                    incurred due to labor claims eventually brought by its employees against the other
                                    party, including, but not limited to, the amount of the award, costs, court costs
                                    and fees. attorneys.</p>
                            </li>

                        </ol>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>INTERFY PLATFORM SUPPORT AND UPDATE</b></p>

                <ol class="numbered-list">

                    <li>
                        <p>The INTERFY Platform is based on the Cloud, and marketed in SaaS (Software as a Service)
                            format, with INTERFY being solely responsible for updating and evolving the platform and
                            will provide the PRIVATE LABEL PARTNER with all the technical support necessary for the
                            proper functioning of the platform.</p>
                    </li>
                    <li>
                        <p>INTERFY will keep the system constantly updated, evolving it according to its schedule of
                            improvements and will make available to the PARTNER the new functionalities, carrying out,
                            whenever available, updates to the Platform in the environment used by the PARTNER;</p>
                    </li>
                    <li>
                        <p>INTERFY must provide technical and commercial support to the PRIVATE LABEL PARTNER free of
                            charge, directly or through third parties indicated by it, to clarify doubts and provide
                            guidance on the commercialization and implementation of the Platform.</p>
                    </li>
                    <li>
                        <p>INTERFY's free support to the PRIVATE LABEL PARTNER refers to remote/online support during
                            business hours, from 9:00 am to 5:00 pm (Eastern United States time), on business days, to
                            solve problems and guide the use of the application for the its proper functioning,
                            therefore not including support for processes and customizations and integrations made by
                            PARTNER PRIVATE LABEL, these services must be subject to a separate budget.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>RIGHTS ON THE INTERFY PLATFORM</b></p>

                <ol class="numbered-list">

                    <li>
                        <p>No right to print, copy, display or change the Software (the platform or its products), in
                            whole or in part, is granted, except as expressly provided for in this Agreement.</p>
                    </li>
                    <li>
                        <p>No ownership is granted to any intellectual property rights relating to the Software.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER may not disassemble, extract any source code or reverse engineer the
                            Software.</p>
                    </li>
                    <li>
                        <p>INTERFY is the sole developer of the Platform and sells to its end customer through the
                            PRIVATE LABEL PARTNER or directly, therefore, once it has adhered to this contractual
                            instrument, the PRIVATE LABEL PARTNER is for itself and its representatives obliged to
                            respect the commercial and services applied by INTERFY.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER agrees to include any notices, including proprietary notices, copyright
                            notices and restricted rights legends displayed on the Software, or provided from time to
                            time by INTERFY in any copies that may be made available at the supplier's discretion.</p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>CORRECTIONS AND MODIFICATIONS</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>PRIVATE LABEL PARTNER must immediately notify INTERFY of any error or defect in the Product
                            of which it becomes aware and provide INTERFY with documented examples of such error or
                            defect.</p>
                    </li>
                    <li>
                        <p>INTERFY shall, within 5 working days of receipt of such notification, assess the error or
                            defect notified and provide PRIVATE LABEL PARTNER with an estimate of the time it will take
                            to issue an update to the Product, which is affected by such error or defect. INTERFY shall
                            use all reasonable efforts to provide any updates within the estimated time frame.</p>
                    </li>
                    <li>
                        <p>Within the period of 15 working days referred to in paragraph 16.2, INTERFY shall devote its
                            best efforts in order to provide the PRIVATE LABEL PARTNER with a replacement or workaround
                            solution for the error or defect.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>CONFIDENTIALITY</b></p>

                <ol class="numbered-list">

                    <li>
                        <p>Both Parties agree to consider as confidential any information or data disclosed to the other
                            Party pursuant to this Agreement, in writing or orally, which has been designated to the
                            other Party in writing as confidential or which is treated as confidential by the other
                            Party in accordance with normal practices in the computer industry (hereinafter referred to
                            as "Confidential Information").</p>
                    </li>
                    <li>
                        <p>The Parties agree that Confidential Information must:</p>
                    </li>
                    <li>
                        <p>Be protected and kept in strict confidentiality by the Parties, who shall use the same degree
                            of precaution and safeguards as are used to protect their own proprietary information of
                            similar importance, but in no case less than reasonable care;</p>
                    </li>
                    <li>
                        <p>Not be used, in whole or in part, for any purpose other than the purpose of this Agreement
                            without the prior written consent of the other Party;</p>
                    </li>
                    <li>
                        <p>Neither be copied, nor otherwise reproduced, nor duplicated in whole or in part, where such
                            copying, reproduction or duplication has not been specifically authorized in writing by the
                            Party to which this Confidential Information belongs.</p>
                    </li>
                    <li>
                        <p>Any documents or information media that contain Confidential Information and copies thereof
                            disclosed by INTERFY to the PRIVATE LABEL PARTNER will remain the property of INTERFY and
                            will be returned to INTERFY immediately upon termination of this Agreement.</p>
                    </li>
                    <li>
                        <p>Neither Party will have any obligations or restrictions with respect to any Confidential
                            Information that may prove:</p>
                    </li>
                    <li>
                        <p>It became public domain before or after disclosure and, in that case, without any wrongful
                            act by the Party;</p>
                    </li>
                    <li>
                        <p>It is already known to the Party, as evidenced by written documentation in that Party's
                            files;</p>
                    </li>
                    <li>
                        <p> It was lawfully received from a third party without restriction or violation of this
                            Agreement or a confidentiality agreement with the Party to which the Confidential
                            Information belongs;</p>
                    </li>
                    <li>
                        <p> Was or is published without breach of this Agreement, or</p>
                    </li>
                    <li>
                        <p> It is approved for release or use by written authorization of the Party to which the
                            Confidential Information belongs.</p>
                    </li>
                    <li>
                        <p> If PRIVATE LABEL PARTNER becomes aware of any breach of confidentiality with respect to
                            Confidential Information by any of its employees, it shall promptly notify INTERFY and
                            provide INTERFY with all reasonable assistance in connection with any proceedings that
                            INTERFY may institute against such people.</p>
                    </li>
                    <li>
                        <p> The PRIVATE LABEL PARTNER, its employees and anyone who has access to its offices and other
                            facilities or any information owned by them are obliged to keep technical, commercial and
                            platform information confidential, especially those related to technical documentation and
                            information received. of INTERFY and access to the Partner Management Environment, which
                            under no circumstances may be shared with third parties outside the business, in addition to
                            information revealed in meetings, demonstrations, webinars, correspondence or any other
                            information. Other materials to which you have access, as well as information that the
                            PRIVATE LABEL PARTNER may come to know by virtue of and/or in the execution of this
                            contract.</p>
                    </li>
                    <li>
                        <p> The provisions of this Section will survive termination, for any reason, of this Agreement.
                        </p>
                    </li>
                    <li>
                        <p> In the event of a breach of confidentiality, the PRIVATE LABEL PARTNER undertakes to
                            reimburse INTERFY for any losses or damages it may suffer from the breach of this clause,
                            without prejudice to the contractual termination.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>NO COMPETITION</b></p>

                <p>
                    PRIVATE LABEL PARTNER agrees that, during the term of this Agreement (whether alone or jointly and
                    either directly or indirectly), it shall:
                </p>

                <ol class="numbered-list">
                    <li>
                        <p>Not be concerned or interested in the development, commercialization, distribution, licensing
                            or sale of any Competitive Product, which may be acquired by the PRIVATE LABEL PARTNER
                            through a good faith acquisition of a third party, only in situations where the PRIVATE
                            LABEL PARTNER has to fulfill purchase orders for customers who must specifically request
                            (and without encouragement from the PRIVATE LABEL PARTNER) such other systems, in which case
                            the PRIVATE LABEL PARTNER agrees that it must always devote its best efforts to inducing
                            such customers to migrate to Interfy's technologies ;</p>
                    </li>
                    <li>
                        <p>Fail to display, demonstrate and otherwise represent the products fairly in comparison with
                            any competitive products;</p>
                    </li>
                    <li>
                        <p>Promptly forward to INTERFY any queries it may receive about the Products from persons
                            located outside the Territory;</p>
                    </li>
                    <li>
                        <p>Not enter into any licensing and support agreement for the Software with any potential
                            customer outside the authorized territory (your home country);</p>
                    </li>
                    <li>
                        <p>Not to hire or otherwise solicit any employee or representative of INTERFY or any of its
                            subsidiaries, for a period of 1 year from the date such employee or representative leaves
                            INTERFY; neither party shall induce or recommend any employee or representative to leave the
                            other party.</p>
                    </li>
                    <li>
                        <p>INTERFY agrees that the PRIVATE LABEL PARTNER shall, throughout the term, be treated
                            equitably, taking into account existing or future legal and commercial relationships between
                            INTERFY and other BUSINESS PARTNERS with respect to Non-Exclusive Products.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER agrees to actively inform INTERFY of any prospective commercial
                            opportunities with third parties with the aim of favoring the conclusion of joint ventures
                            or similar types of commercial agreements with such third parties for the commercialization
                            and sale of the products of both companies, INTERFY, and the Business Partner worldwide.</p>
                    </li>

                </ol>

            </li>

            <li class="item">
                <p><b>PROPERTY RIGHTS</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>The Product Materials and the Source Code and the Intellectual Property Rights in or related
                            to them are and shall remain the exclusive property of INTERFY throughout the Territory and
                            no title thereto shall pass to the PRIVATE LABEL PARTNER or its Clients. All copies of
                            documents, methods, proceedings or any other type of material in the possession, custody or
                            control of the PRIVATE LABEL PARTNER must be returned to INTERFY or otherwise disposed of by
                            the PRIVATE LABEL PARTNER as INTERFY may, from time to time, direct, at the end of this
                            agreement.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER acknowledges and agrees that the Product Materials and Source Code,
                            including, without limitation, the Product, user interface, monitor display formats, modules
                            and programming techniques developed and used in the Product Materials and Code Source, and
                            all improvements, modifications and updates made by INTERFY are the exclusive property of
                            INTERFY, whether or not any part thereof is copyrighted or patented and that title and full
                            ownership will at all times remain solely with INTERFY.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER shall notify INTERFY immediately if PRIVATE LABEL PARTNER becomes aware
                            of any unauthorized use of any of the Product Materials or Source Code related to the
                            Product or any of the Intellectual Property Rights therein or relating thereto and shall
                            assist INTERFY, at INTERFY's expense, in taking all steps to protect or defend INTERFY's
                            rights therein.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER shall not, at any time, expressly or implicitly claim any right, title
                            or interest in the Product Materials or the Source Code, nor pretend to acquire or have
                            acquired any Intellectual Property Right in the Product Materials or the Code- third-party
                            source.</p>
                    </li>
                    <li>
                        <p>The provisions of this Section will survive termination for any reason of this Agreement.</p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>BRANDS</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>Subject to the restrictions set forth below, PRIVATE LABEL PARTNER shall have the right to
                            use the Trademarks during the term of this Agreement only in connection with the use and
                            commercialization of the Products and Services.</p>
                    </li>
                    <li>
                        <p>Use of the trademarks shall be subject to reasonable restrictions and standards that INTERFY
                            may adopt from time to time.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER shall not use the Marks in any way after termination of this Agreement.
                        </p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>INTERFY REPRESENTATIONS AND WARRANTIES</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>INTERFY represents and warrants that it is the sole and exclusive legal and beneficial owner
                            of all Intellectual Property Rights in and to the Product Materials and Source Code, except
                            for any portion of the Product Materials for which INTERFY has been licensed by third
                            parties, in which case INTERFY has the right to grant the PRIVATE LABEL PARTNER hereunder
                            with respect to such licensed part of the Product. INTERFY further represents and warrants
                            that it is not aware of any existing, potential or conflicting claims of ownership of the
                            Intellectual Property Rights or any part thereof.</p>
                    </li>
                    <li>
                        <p>INTERFY shall defend and hold harmless the PRIVATE LABEL PARTNER against any claim that the
                            Product infringes any third party Intellectual Property Right and INTERFY shall pay any
                            resulting costs, damages and reasonable legal fees incurred, provided that:</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER promptly notifies INTERFY in writing of any claim; e</p>
                    </li>
                    <li>
                        <p>INTERFY has exclusive control of the defense and all related settlement negotiations,
                            provided that PRIVATE LABEL PARTNER may engage its own attorneys to assist in such effort,
                            at its own expense.</p>
                    </li>
                    <li>
                        <p>INTERFY shall have no obligation to defend the PRIVATE LABEL PARTNER or to make any payment
                            of costs, damages, legal fees or otherwise for any claim based on:</p>
                    </li>
                    <li>
                        <p>The combination, operation or use of the Product with any hardware, Product, equipment or
                            process not specified by INTERFY or approved by INTERFY if such violation had been avoided,
                            but for such combination, operation or use.</p>
                    </li>
                    <li>
                        <p>Any defect in the Product resulting from failures in the customer's Internet connection,
                            including lack of ability to use the software, is excluded from INTERFY's warranties.</p>
                    </li>
                    <li>
                        <p>For further certainty, INTERFY shall have no liability or liability whatsoever under this
                            Agreement for any PRIVATE LABEL PARTNER Modifications.</p>
                    </li>
                    <li>
                        <p>IN NO EVENT SHALL INTERFY HAVE ANY LIABILITY TO PRIVATE LABEL PARTNER OR ITS CUSTOMERS FOR
                            LOSS (DIRECT OR INDIRECT) OF PROFITS, LOSS OF BUSINESS REVENUE OR FAILURE TO REALIZE
                            EXPECTED SAVINGS OR FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL LOSS OR DAMAGES , OR
                            INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES NOT LIMITED TO, LOSS OF USE OR LOSS OF DATA OR
                            INFORMATION OF ANY KIND, HOWEVER CAUSED, OR FAILURE OF ANY PRODUCT TO WORK OR PERFORM IN ANY
                            WAY, OR ANY LIABILITY TO CUSTOMERS OR THIRD PARTIES , EVEN IF ADVISED OF THE POSSIBILITY OR
                            RESULTING OF NEGLIGENCE, BREACH OF CONTRACT OR OTHERWISE.</p>
                    </li>
                    <li>
                        <p> EXCEPT FOR THE EXPRESS WARRANTIES ABOVE, INTERFY MAKES NO OTHER WARRANTIES OR CONDITIONS,
                            EXPRESS OR IMPLIED. THE TERMS EXPRESSED IN THIS AGREEMENT REPLACE ALL WARRANTIES,
                            CONDITIONS, TERMS, COMMITMENTS AND OBLIGATIONS IMPLIED BY STATUTE, Common Laws of the United
                            States and Florida State Laws, CUSTOMIZED USE, COMMERCIAL USE, COURSE OF TRADING OR
                            OTHERWISE, UNTIL PERMANENT PERMISSION OF ORIGIN FROM THE PARTNER PRIVATE LABEL. INTERFY
                            INTERNATIONAL LLC. HEREBY DISCLAIMS ANY AND ALL IMPLIED WARRANTIES OR CONDITIONS OF
                            MERCHANTABILITY, MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, SPECIFIC OR
                            OTHERWISE, TO THE MAXIMUM EXTENT PERMITTED BY THE PARTNER COUNTRY.</p>
                    </li>
                    <li>
                        <p> The foregoing provisions set forth the exclusive remedies of the PRIVATE LABEL PARTNER, and
                            in no event shall INTERFY's liability for any claim exceed the amounts received by INTERFY
                            under the terms of this document with respect to the License or other agreement under which
                            the Product or Service originated. to such claim, even if INTERFY is advised of the
                            possibility of such damages.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>INDEMNIFICATION OF THE PRIVATE LABEL PARTNER</b></p>
                <ol class="numbered-list">
                    <li>
                        <p>PRIVATE LABEL PARTNER shall hold harmless and indemnify INTERFY, and hold INTERFY fully and
                            effectively indemnified upon demand, from and against any and all losses, claims, damages,
                            costs, charges, expenses, liabilities (including reasonable attorneys' fees) resulting from
                            from or due to claims, demands, proceedings or actions which INTERFY may sustain or incur,
                            or which may be brought or brought against it by any person and which, in any case, arise
                            out of or in relation to or on account of:</p>
                    </li>
                    <li>
                        <p>Any breach by PRIVATE LABEL PARTNER of its obligations under this Agreement; or</p>
                    </li>
                    <li>
                        <p>Any unauthorized action or omission of the PRIVATE LABEL PARTNER, Agents or employees
                            thereof; or</p>
                    </li>
                    <li>
                        <p>The way in which the PRIVATE LABEL PARTNER sells the Product;</p>
                    </li>
                    <li>
                        <p>The independent provision by the PRIVATE LABEL PARTNER of any Products or Services for use in
                            conjunction with or in connection with the Product, or</p>
                    </li>
                    <li>
                        <p>Any modifications of PARTNER PRIVATE LABEL.</p>
                    </li>
                    <li>
                        <p>If any claim is made against INTERFY for which indemnification is sought pursuant to
                            paragraph 22.1, INTERFY shall consult the PRIVATE LABEL PARTNER and, subject to its
                            reasonable satisfaction being guaranteed, shall cooperate with the BUSINESS PARTNER in
                            relation to any reasonable request made by BUSINESS PARTNER in connection with such claim.
                        </p>
                    </li>

                </ol>

            </li>

            <li class="item">
                <p><b>TERMINATION</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>This Agreement will automatically terminate immediately upon the occurrence of any of the
                            following events of default, at the discretion and discretion of the non-defaulting Party:
                        </p>
                    </li>
                    <li>
                        <p>If any Party becomes generally unable to pay its debts when due, it admits in writing<br>Its
                            inability to pay its debts in general, makes an assignment for the benefit of its creditors;
                        </p>
                    </li>
                    <li>
                        <p>If any proceeding is brought against any Party seeking to find it bankrupt or insolvent or
                            seeking to liquidate, liquidate, reorganize, arrange, adjust, protect, relieve or settle it
                            or its debts in accordance with the laws of the United States relating to bankruptcy,
                            insolvency or debtor reorganization or relief or seeking the entry of a relief order or the
                            appointment of a trustee, trustee, custodian or other similar official for him or any
                            substantial part of his property, unless in such event, such proceedings are contested in
                            good faith by such Party and any order, appointment or other remedy is suspended pending the
                            outcome of the contested proceeding and such order, appointment or other remedy or
                            terminated within 10 days;</p>
                    </li>
                    <li>
                        <p>If any Party takes advantage of any United States law relating to bankruptcy, insolvency or,
                            in general, debt relief.</p>
                    </li>
                    <li>
                        <p>This Agreement may be terminated by INTERFY, at its discretion, if the PRIVATE LABEL PARTNER
                            breaches the provisions relating to the payment of the Purchase Price and fails to remedy
                            its default within 10 days after having received from INTERFY a notice specifying the
                            default and requiring that be remedied.</p>
                    </li>
                    <li>
                        <p>This Agreement may be terminated by either Party, at its discretion, by giving the defaulting
                            Party notice of termination if the other Party is in default of any other provision of this
                            Agreement and if the defaulting Party fails to remedy such default (if such default is
                            capable of being repaired) within 10 days after receiving from the non-defaulting Party a
                            notice specifying the default and requiring it to be corrected.</p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>TERMINATION EFFECT</b></p>

                <p>
                    Upon termination or expiration of this Agreement:
                </p>

                <ol class="numbered-list">
                    <li>
                        <p>All rights and obligations of the Parties under this Agreement shall automatically terminate,
                            except:</p>
                    </li>
                    <li>
                        <p>For the rights of action which must have accrued prior to such termination and any
                            obligations which expressly or by implication are intended to enter or continue in effect on
                            or after such termination;</p>
                    </li>
                    <li>
                        <p>That the terms of this Agreement will remain in full force and effect only to the extent and
                            for the period necessary to enable PRIVATE LABEL PARTNER to properly perform its continuing
                            obligations under the License or Service Agreements existing on the date of termination and
                            which have not been transferred to INTERFY in accordance with Section 25 (and the PRIVATE
                            LABEL PARTNER's obligations under paragraphs 25.1 and 25.2 below will be suspended for the
                            period such continuing obligations survive);</p>
                    </li>
                    <li>
                        <p>The PRIVATE LABEL PARTNER shall immediately delete from all its literature, commercial
                            stationery, publications, notices and advertisements all references to the title "PRIVATE
                            LABEL PARTNER" and all other representations of PRIVATE LABEL PARTNER's appointment
                            hereunder.</p>
                    </li>
                    <li>
                        <p>The PRIVATE LABEL PARTNER shall, at its own expense, immediately return to INTERFY or
                            otherwise arrange how INTERFY may instruct all promotional materials and other documents and
                            papers sent to PRIVATE LABEL PARTNER and related to INTERFY's business (except
                            correspondence between the Parties), all INTERFY properties and all copies of the Product
                            Materials and Source Code related to the Product, whether in the possession of the PRIVATE
                            LABEL PARTNER or under its control.</p>
                    </li>
                    <li>
                        <p>If the event of default giving rise to the termination is by the PRIVATE LABEL PARTNER, all
                            amounts owed to INTERFY by the PRIVATE LABEL PARTNER at the time of termination shall become
                            immediately due and payable to INTERFY.</p>
                    </li>
                    <li>
                        <p>Each then-existing License or Service Agreement shall continue in effect and survive
                            termination of this Agreement, subject to the provisions of Section 25.</p>
                    </li>
                </ol>


            </li>

            <li class="item">
                <p><b>PERFORMANCE OF OBLIGATIONS ARISING FROM THE AGREEMENT AFTER TERMINATION</b></p>

                <p>
                    The parties agree that upon termination of this Agreement for any reason:
                </p>

                <ol class="numbered-list">
                    <li>
                        <p>PRIVATE LABEL PARTNER irrevocably agrees to transfer, at no cost, all rights and obligations
                            under any License or Service Agreement selected by INTERFY, by way of assignment or by any
                            other means or legal operation that may be available in accordance with the Laws of the
                            United States and the Laws of the State of Florida applicable to such Licenses or Service
                            Agreements as INTERFY may determine, provided that after such transfer PRIVATE LABEL PARTNER
                            has no further obligations to Customers with respect to the performance of PRIVATE PARTNER's
                            obligations LABEL after such transfer or that INTERFY or any third party for whose benefit
                            such transfer will be effected guarantees PRIVATE LABEL PARTNER against any claim arising
                            from non-performance or defective performance of such obligations (the "Transfer").</p>
                    </li>
                    <li>
                        <p>After the date of transfer, INTERFY, or any third party designated by INTERFY, shall comply
                            with all obligations of the PRIVATE LABEL PARTNER with respect to any License or Service
                            Agreement so transferred and shall be liable for any claims by the Customer based on
                            non-compliance or compliance defect of such obligations by INTERFY or third parties after
                            the date of the relevant Transfer.</p>
                    </li>
                    <li>
                        <p>PRIVATE LABEL PARTNER shall give INTERFY or such third party that INTERFY may appoint, all
                            reasonable cooperation in transferring the PRIVATE LABEL PARTNER's rights and obligations
                            under such License and Service Agreement to INTERFY or such third party.</p>
                    </li>
                    <li>
                        <p>For greater certainty, INTERFY will have no obligation to accept the transfer of any license
                            or service agreement related to any third party product or service (including other products
                            owned or distributed by PRIVATE LABEL PARTNER or products that have been combined with any
                            INTERFY products.).</p>
                    </li>
                    <li>
                        <p>Unless this Agreement has been terminated by INTERFY due to default by the PRIVATE LABEL
                            PARTNER that has not been cured to INTERFY's satisfaction within the delays provided,
                            INTERFY shall continue to provide the PRIVATE LABEL PARTNER with support and maintenance
                            services incumbent upon INTERFY in accordance with INTERFY's Standard Conditions.</p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>LEGAL RELATIONSHIP</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>In carrying out this Agreement, neither Party will be or will be considered an Agent or
                            employee of the other for any purpose, and that their relationship with each other will be
                            that of independent contractors. Nothing in this Agreement shall constitute a partnership or
                            joint venture between the Parties. Neither party shall have the right to enter into
                            contracts or pledge credit or incur liability expenses on behalf of the other.</p>
                    </li>
                </ol>

            </li>

            <li class="item">
                <p><b>ASSIGNMENT</b></p>

                <p>
                    PRIVATE LABEL PARTNER may not assign any rights or benefits under this Agreement to any person.
                    PRIVATE LABEL PARTNER agrees to perform its obligations under this Agreement and not to arrange in
                    any way for any other Person (other than its employees) to perform such obligations, except as
                    expressly permitted herein. No benefit assignment or performance arrangement substituted by the
                    PRIVATE LABEL PARTNER shall have any effect against INTERFY, except to the extent that it has
                    expressly authorized it under the terms of this instrument or consented in writing.
                </p>
            </li>

            <li class="item">
                <p><b>INTERPRETATION</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>Any specific obligation incumbent on a Party or Parties under the provisions of this
                            agreement must be read and interpreted in conjunction with the terms of this instrument. In
                            the event of ambiguity, inconsistency or incompatibility between any provision of this
                            instrument and any provision contained in this agreement, the provision that is more
                            specific will prevail over the provision that is more general to the extent of any
                            ambiguity, inconsistency or incompatibility, as the case may be.</p>
                    </li>
                </ol>
            </li>

            <li class="item">
                <p><b>GENERAL</b></p>

                <ol class="numbered-list">
                    <li>
                        <p>Any notice, demand or other communication (in this section, a "notice") required or permitted
                            to be given or made hereunder must be made by email during normal business hours on a
                            Business Day:<br>Each notice sent in accordance with this Section will be deemed received if
                            delivered during the recipient's normal business hours, at the time of the day it was
                            delivered, or at the start of business on the first following Business Day if the day on
                            which it was delivered was not a Useful; or one hour after it was sent on the same date it
                            was sent by Electronic Transmission, or at the start of business on the first Business Day
                            following if the day it was sent by Electronic Transmission was not a Business Day.<br>Any
                            Party may change its notification address upon notification to the other Party as provided
                            in this Section.</p>
                    </li>
                    <li>
                        <p>Timing is of the essence of every provision of this Agreement.</p>
                    </li>
                    <li>
                        <p>If any action is required under this Agreement on or by a specified date other than a
                            Business Day, such action will be effective if taken on the next succeeding Business Day.
                        </p>
                    </li>
                    <li>
                        <p>This Agreement shall be governed by, construed and performed in accordance with the United
                            States Laws in effect in the State of Florida (excluding any conflict of United States Laws
                            and rule or principle of the Laws of the State of Florida that may refer such construction
                            to the Laws of the States States and Florida State Laws of another jurisdiction) and shall
                            be treated in all respects as a contract of the State of Florida.</p>
                    </li>
                    <li>
                        <p>This Agreement, together with any agreements and other documents to be delivered under or
                            concomitantly herewith, constitutes the entire agreement between the Parties with respect to
                            the subject matter hereof and supersedes all previous agreements, negotiations, discussions
                            and understandings, written or orally, between the Parties. There are no representations,
                            warranties, conditions, other agreements or acknowledgments, whether direct or collateral,
                            express or implied, and form part of or affect this Agreement. The performance of this
                            Agreement has not been induced by, nor does either Party regard or consider as material, any
                            representations, warranties, conditions, other agreements or acknowledgments not expressly
                            made in this Agreement or in the agreements and other documents to be delivered hereunder.
                        </p>
                    </li>
                    <li>
                        <p>Each Party will perform such acts and execute such additional documents and will cause such
                            acts to be performed and will cause the execution of all other documents in its possession,
                            as the other Party may, in writing, at any time and from time to time , make or execute
                            reasonable requests in order to give full effect to the provisions of this Agreement.</p>
                    </li>
                    <li>
                        <p>This Agreement may be amended or supplemented only by a written agreement signed by each
                            Party.</p>
                    </li>
                    <li>
                        <p>Any waiver or consent to depart from the requirements of any provision of this Agreement
                            shall be effective only if it is in writing and signed by the Granting Party, and only in
                            the specific case and for the specific purpose for which it was given. No failure on the
                            part of either Party or any delay in exercising any right under this Agreement shall act as
                            a waiver of such right. No single or partial exercise of any of these rights shall preclude
                            any further exercise of such right or the exercise of any other right.</p>
                    </li>
                    <li>
                        <p>This Agreement may be executed in any number of ways; each executed counterparty will be
                            considered an original; all jointly executed counterparties must form a contract.</p>
                    </li>
                    <li>
                        <p> Except as otherwise expressly provided in this Agreement, the dates and times at which any
                            Party is required to perform any obligation under this Agreement, other than cash payment,
                            will be automatically deferred to the extent and for the period of time that the Party is
                            prevented to do so due to circumstances beyond its reasonable control. Such circumstances
                            may include acts of nature, strikes, lockdowns, riots, acts of war, epidemics,
                            after-the-fact government regulations, fire, communication lines failures, power failures,
                            earthquakes or other disasters. The Party prevented from performing shall notify the other
                            Party promptly and in detail of the onset and nature of such circumstance and the likely
                            consequence thereof and other obligations of both Parties affected by this delay will be
                            adjusted accordingly. Each Party whose performance is delayed must use reasonable efforts to
                            perform its obligations in a timely manner, must employ all resources reasonably necessary
                            in the circumstances, and must obtain supplies or services from other sources if reasonably
                            available.</p>
                    </li>
                    <li>
                        <p> This Agreement shall enter into force and be binding on the Parties hereto and their
                            assigns, representatives, heirs and successors as of the date hereof. Furthermore, this
                            Agreement shall be binding and effective against any purchaser or assignee of all or any
                            part of the assets, ventures, property, Products (except for Licenses to use the Products
                            granted in the ordinary and ordinary course of business) or actions (except to employees ,
                            within the scope of a share purchase plan) of INTERFY.</p>
                    </li>
                    <li>
                        <p> The parties expressly exclude the application of the United States Convention for the
                            International Sale of Goods.</p>
                    </li>
                    <li>
                        <p> Notwithstanding the date of signature, the Parties agree that this Agreement will be deemed
                            to have been entered into on the formal date of its adhesion by the Partner through the
                            INTERFY accreditation page at: <a href="https://www.interfy.io/become-a-partner"
                                target="_blank">https://www.interfy.io/become-a-partner</a></p>
                    </li>

                </ol>
            </li>

        </ol>


    </div>

</template>

<script>
export default {
    props: ['business_partner'],
    inject: ['scrollto']
}
</script>

<style scoped lang="scss"></style>