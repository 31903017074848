<template>
  <div style="min-height: 100vh;">        
    
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-process recruitmentAndSelection d-flex flex-row justify-content-center align-items-center">

          <div class="col-lg-6 col-md-6 col-sm-12 mockup" v-if="window.width > 991">
            <img :src="`/assets/images/domains/${domain.name}/processes/recruitmentAndSelection/mockup_01_${lang}.png`" :alt="$t('PROCESS_RECRUITMENT_AN')">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 div-text">
            <div class="header-main-process d-flex flex-row justify-content-start align-items-center">
              <div class='icon icon-shape recruitmentAndSelection'>
                <img src="/assets/images/processes/icons/06.png" :alt="$t('PROCESS_RECRUITMENT_AN')" />
                <img src="/assets/images/processes/icons/06_hover.png" :alt="$t('PROCESS_RECRUITMENT_AN')" />
              </div>
              <h1 class="mb-4 title" v-html="$t('PROCESS_RECRUITMENT_AN')"></h1>
            </div>
            <h2 class="subtitle" v-html="$t('HIRING PER.....')"></h2>
            
            <div class="div-buttons d-flex">
              <router-link class="btn recruitmentAndSelection btn-medium-width" :to="`/start-free${partner_url}`">{{$t("START_FREE")}}</router-link>
              <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-4 px-3 btn recruitmentAndSelection-alternative" :to="`/plans${partner_url}`">{{$t("BECOME_A_PARTNER")}}</router-link>
            </div>
          </div>

        </div>
    </transition>

    <img class="top-image" src="/assets/images/processes/top-01.png" :alt="`${domain.title}`">

    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description recruitmentAndSelection">
          <div class="row">
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/recruitmentAndSelection/icons/01.png" :alt="$t('CONTROLLIN.....')">
                  </div>
                  <span></span>                  
                  <p v-html="$t('CONTROLLIN.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/recruitmentAndSelection/icons/02.png" :alt="$t('AUTOMATING.....')">
                  </div>
                  <span></span>                  
                  <p v-html="$t('AUTOMATING.....')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-4 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/recruitmentAndSelection/icons/03.png" :alt="$t('HIRING IS .....')">
                  </div>
                  <span></span>                  
                  <p v-html="$t('HIRING IS .....')"></p>
              </div>
            </div>
          </div>

        </div>
    </transition>


    <transition name="slide-fade">
      <div class="section section-process-benefits recruitmentAndSelection">
        
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h1 class='mb-5' v-html="$t('BENEFITS O.....')"></h1>
            <ul class='mt-3'>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('REDUCTION .....')">
                <p v-html="$t('REDUCTION .....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('CONTROL AN.....')">
                <p v-html="$t('CONTROL AN.....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('CENTRALIZA.....')">
                <p v-html="$t('CENTRALIZA.....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('REDUCED RE.....')">
                <p v-html="$t('REDUCED RE.....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('VISIBILITY.....')">
                <p v-html="$t('VISIBILITY.....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('REDUCTION2 .....')">
                <p v-html="$t('REDUCTION2 .....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('BETTER COM.....')">
                <p v-html="$t('BETTER COM.....')"></p>
              </li>
              <li>
                <img src="/assets/images/processes/check.png" :alt="$t('GREATER OR.....')">
                <p v-html="$t('GREATER OR.....')"></p>
              </li>
              
            </ul>
          </div>
          <div class="col-mockup col-lg-6 col-md-6 col-sm-12 mockup">

            <img :src="`/assets/images/domains/${domain.name}/processes/recruitmentAndSelection/mockup_02.png`" :alt="$t('PROCESS_PAYMENT_AUTHOR')">

          </div>
        </div>
        
      </div>
    </transition>

    <transition name="slide-fade">
        <div class="section section-list-process-description recruitmentAndSelection">
          <h1 class="mb-5 text-center" v-html="$t('HIRING FLO.....')"></h1>

          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div v-for="(item, index) in processList['left']" :key="index" class="ct">
                <div class="item d-flex flex-row justify-content-between align-items-start">

                  <div class="icon">
                    <img :src="item.icon" :alt="item.title">
                    <img :src="item.icon_hover" :alt="item.title">
                  </div>
                  
                  <div class="text d-flex flex-column justify-content-center align-items-start">
                    <h2 v-html="item.title"></h2>
                    <p v-html="item.description"></p>
                  </div>

                  <div class="arrows" @click="openItem($event)">
                    <img src="/assets/images/processes/arrow-down.png" :alt="`${domain.title}`">
                    <img src="/assets/images/processes/arrow-up.png" :alt="`${domain.title}`">
                  </div>

                </div> 
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div v-for="(item, index) in processList['right']" :key="index" class="ct">
                <div class="item d-flex flex-row justify-content-between align-items-start">

                  <div class="icon">
                    <img :src="item.icon" :alt="item.title">
                    <img :src="item.icon_hover" :alt="item.title">
                  </div>
                  
                  <div class="text d-flex flex-column justify-content-center align-items-start">
                    <h2 v-html="item.title"></h2>
                    <p v-html="item.description"></p>
                  </div>

                  <div class="arrows" @click="openItem($event)">
                    <img src="/assets/images/processes/arrow-down.png" :alt="`${domain.title}`">
                    <img src="/assets/images/processes/arrow-up.png" :alt="`${domain.title}`">
                  </div>

                </div> 
              </div>

            </div>
          </div>
        </div>
    </transition>

    


    <!-- 

        Join......

    -->  
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></Join>
    </transition>

    <!-- 

        See also......

    -->  
    <transition name="slide-fade">             
        <OtherProcesses :partner_url="partner_url" v-if="show" :page="page" :path="path"></OtherProcesses>
    </transition>

    <!-- 

        Automate......

    -->  
    <transition name="slide-fade">             
        <AutomateProcesses :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></AutomateProcesses>
    </transition>

    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="clients-bpm"></Clients>
    </transition> 

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import OtherProcesses from '../common/OtherProcesses'
import AutomateProcesses from '../common/AutomateProcesses'
import $ from 'jquery'

export default {
  
    components:{
      Clients,
      Join,
      ThePlatform,
      OtherProcesses,
      AutomateProcesses,      
    },
    props: {      
      isDomainPermitted:{
          type: Boolean,
          default: false
      },
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      },
      page: {
        type: String,
        default: ''
      },
      path: {
        type: String,
        default: ''
      },
      partner_url: {
          type: String,
          default: ''
      },
    },
    data(){
        return{            
            show: false,            
        }
    },
    computed: {

      ...mapState('MainStoreModule', {                                  
          domain: state => state.domain
      }),
      
      lang(){
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
      processList(){
        return {
          'left': [
            {
              'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-01.png',
              'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-02.png',
              
              'title': this.$t('REQUEST FO.....'),
              'description': this.$t('USUALLY DO.....'),
            },
            {
              'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-03.png',
              'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-04.png',
              
              'title': this.$t('HUMAN RESO.....'),
              'description': this.$t('TO AUTHORI.....'),
            },
            {
              'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-05.png',
              'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-06.png',
              
              'title': this.$t('OPENING OF.....'),
              'description': this.$t('PUBLICATIO.....'),
            },
            {
              'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-07.png',
              'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-08.png',
              
              'title': this.$t('SELECTION .....'),
              'description': this.$t('SELECTION2 .....'),
            },
            {
              'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-09.png',
              'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-10.png',
              
              'title': this.$t('CONDUCTING.....'),
              'description': this.$t('EVALUATION.....'),
            },
            {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-11.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-12.png',
                
                'title': this.$t('TECHNICAL .....'),
                'description': this.$t('EVALUATION2.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-13.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-14.png',
                
                'title': this.$t('CONTRACT C.....'),
                'description': this.$t('HUMAN RESO2.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-15.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-16.png',
                
                'title': this.$t('NOTIFY SUC.....'),
                'description': this.$t('(AUTOMATIC.....'),
              },
          ],
          'right': [
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-17.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-18.png',
                
                'title': this.$t('RECEIVE DO.....'),
                'description': this.$t('CHECK LIST.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-19.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-20.png',
                
                'title': this.$t('PERFORM ME.....'),
                'description': this.$t('HEALTH FIT.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-21.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-22.png',
                
                'title': this.$t('CONTACT AN.....'),
                'description': this.$t('CHECK LIST2.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-23.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-24.png',
                
                'title': this.$t('ARCHIVE DO.....'),
                'description': this.$t('SCAN IN TH.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-25.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-26.png',
                
                'title': this.$t('WORK FORMA.....'),
                'description': this.$t('(EMAIL, SY.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-27.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-28.png',
                
                'title': this.$t('REQUEST BE.....'),
                'description': this.$t('IDENTIFY B.....'),
              },
              {
                'icon': '/assets/images/processes/recruitmentAndSelection/icons/icon-29.png',
                'icon_hover': '/assets/images/processes/recruitmentAndSelection/icons/icon-30.png',
                
                'title': this.$t('EMPLOYEE I.....'),
                'description': this.$t('RESPONSIBL.....'),
              }
          ]
        } 
      }   
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">

@media only screen and (max-width: 991px) {
  .section-list-process-description,
  .section-process-benefits,
  .section-blocks-description{
      .row{
          margin: 0 !important;
      }
  }  


  .section-process-benefits{

    .mockup {
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        position: relative;
        align-self: center;
        width: 80% !important;
        margin-bottom: 30px;
      }
    }
    
    .row{
      div{
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .col-sm-12{
      &:first-child{        
        margin-top: 15px !important; 
      }
    }

    ul{
      margin: 0 !important;

      li{        
        margin: 0 0 10px 0 !important;
        img{
          height: 15px !important;          
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }

  }

  .section-list-process-description{

    .col-sm-12{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .item{
      padding: 10px !important;     
      
      .icon, 
      .arrows{
        margin-top: 2px !important;
      } 

      .text{
        padding: 0 20px !important;
        h2{
          font-size: 16px !important;
          line-height: 18px !important;
          margin-bottom: 0 !important;
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;         
        }
      }
    }
  }
}
    
</style>