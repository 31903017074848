<template>
    <div class="section section-FAQ base-padding d-flex flex-column justify-content-center align-items-start ">
        <h2>{{$t('FAQ')}}</h2>

        <div class="d-flex flex-row justify-content-center align-items-center div-questions-list">
            <ul>
                <li v-for="(item, index) in faq" :key="index" class="d-flex flex-column justify-content-center align-items-center">
                    <a class="d-flex flex-row justify-content-between align-items-center" @click.stop="toggleOpen($event, index)">{{ item.question[lang].replace(/{domain}/g, domain.title) }}<i :class="{'fa fa-plus': !item.isOpen, 'fa fa-minus': item.isOpen}"></i></a>
                    <span :class="{'is-open': item.isOpen}" v-html="item.answer[lang].replace(/{domain}/g, domain.title).replace('{email1}', ( isInterfyFamily() ) ? domain.email2 : domain.email1)"></span>
                </li>
            </ul>
        </div>
    </div>
</template>



<script>
import { mapState, mapActions } from 'vuex'
export default {

    computed:{

        ...mapState('MainStoreModule', {                                
            domain: state => state.domain
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },    
    },

    data(){
        return{            
            faq: [
                {
                    isOpen: false,
                    question: {
                        en: "What is the main difference of a scanner?",
                        pt: "Qual é a principal diferença de um scanner?",
                        es: "¿Cuál es la principal diferencia de un escáner?",
                    },
                    answer: {
                        en: "Regular scanners expect users to select from a long list of settings - resolution, color, double or single-sided and more.The user is expected to specify where the scanned document should be saved and, in addition, he is expected to provide indexing information to allow for later retrieval.The SWC simplifies this by selecting jobs with a button - the user simply selects the document being scanned and the rest is defined completely automatically.",
                        pt: "Os scanners regulares esperam que os usuários selecionem em uma longa lista de configurações - resolução, cor, dupla ou face única e muito mais. Espera-se que o usuário especifique onde o documento digitalizado deve ser salvo e, além disso, espera-se que ele forneça informações de indexação para permitir a recuperação posterior. O SWC simplifica isso por meio da seleção de trabalhos com um botão - o usuário simplesmente seleciona o documento que está sendo digitalizado e o restante é definido completamente automaticamente.",
                        es: "Los escáneres habituales esperan que los usuarios seleccionen de una larga lista de configuraciones: resolución, color, doble o simple cara y más.Se espera que el usuario especifique dónde se debe guardar el documento escaneado y, además, se espera que proporcione información de indexación para permitir su posterior recuperación.El SWC simplifica esto seleccionando trabajos con un botón: el usuario simplemente selecciona el documento que se está escaneando y el resto se configura de forma completamente automática.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "What is the main difference from other SWC or advanced scanners?",
                        pt: "Qual é a principal diferença em relação a outros scanners SWC ou avançados?",
                        es: "¿Cuál es la principal diferencia con otros SWC o escáneres avanzados?",
                    },
                    answer: {
                        en: "Many advanced scanners feature high PDF compression, some job definition functions, OCR features and even network operation.But no other SWC solution includes all of our features in one powerful package.",
                        pt: "Muitos scanners avançados apresentam alta compactação de PDF, algumas funções de definição de trabalho, recursos de OCR e até mesmo operação em rede. Mas nenhuma outra solução do tipo SWC inclui todos os nossos recursos em um único pacote poderoso.",
                        es: "Muchos escáneres avanzados cuentan con alta compresión de PDF, algunas funciones de definición de trabajos, funciones de OCR e incluso funcionamiento en red.Pero ninguna otra solución de tipo SWC incluye todas nuestras funciones en un paquete potente.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "What documents can be manipulated?",
                        pt: "Quais documentos podem ser manipulados?",
                        es: "¿Qué documentos se pueden manipular?",
                    },
                    answer: {
                        en: "Any paper document can be automated and scanned using the SWC.Structured documents where the main information is always in the same location (for example, invoices, where the invoice number is always printed in the same location) can be completely automated - the key metadata can be read automatically from each document and indexed and storedin the ECM of the Interfy platform, which can be used to automate processes in BPM.",
                        pt: "Qualquer documento em papel pode ser automatizado e digitalizado usando o SWC. Documentos estruturados em que as principais informações estão sempre no mesmo local (por exemplo, faturas, onde o número da fatura é sempre impresso no mesmo local) podem ser completamente automatizadas - os metadados da chave podem ser lidos automaticamente de cada documento e indexados e armazenados no ECM da plataforma Interfy que poderá seu utilizado para a automatização de processos no BPM.",
                        es: "Cualquier documento en papel se puede automatizar y escanear utilizando el SWC.Los documentos estructurados en los que la información principal está siempre en la misma ubicación (por ejemplo, las facturas, donde el número de factura siempre se imprime en la misma ubicación) se pueden automatizar completamente: los metadatos clave se pueden leer automáticamente de cada documento, indexarlos y almacenarlos. en el ECM de la plataforma Interfy, que se puede utilizar para automatizar procesos en BPM.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Read the content of the scanned documents?",
                        pt: "Lê o conteúdo dos documentos digitalizados?",
                        es: "¿Leer el contenido de los documentos escaneados?",
                    },
                    answer: {
                        en: "Each document scanned by the SWC is automatically passed through the main optical character recognition mechanisms - OCR.This allows all printed text to be searchable.",
                        pt: "Cada documento digitalizado pelo SWC é automaticamente passado pelos principais mecanismos de reconhecimento óptico de caracteres - OCR. Isso permite que todo o texto impresso seja pesquisável.",
                        es: "Cada documento escaneado por SWC pasa automáticamente a través de los principales mecanismos ópticos de reconocimiento de caracteres: OCR.Esto permite realizar búsquedas en todo el texto impreso.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Read QR and bar codes?",
                        pt: "Lê QR e códigos de barras?",
                        es: "¿Leer códigos QR y de barras?",
                    },
                    answer: {
                        en: "Yes, SWC is able to detect and read many different types or bar codes and QR Codes.",
                        pt: "Sim, o SWC é capaz de detectar e ler muitos tipos diferentes ou códigos de barras e QR Codes.",
                        es: "Sí, SWC puede detectar y leer muchos tipos diferentes de códigos de barras y códigos QR.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "How does he handle identity documents?",
                        pt: "Como ele lida com documentos de identidade?",
                        es: "¿Cómo maneja los documentos de identidad?",
                    },
                    answer: {
                        en: "Identity Cards and National Passports that adhere to ICAO international standards for Machine Reading Zones (MRZ) are automatically recognized and read by Scan2x.When reading the MRZ, the SWC automatically collects all related information as metadata.In addition, it checks the security checksum and the document's expiration date and notifies the user if any of the tests fail.",
                        pt: "Cartões de Identidade e Passaportes Nacionais que aderem aos padrões internacionais da ICAO para Zonas de Leitura de Máquina (MRZ) são automaticamente reconhecidos e lidos pelo Scan2x. Ao ler o MRZ, o SWC coleta automaticamente todas as informações relativas como metadados. Além disso, verifica a soma de verificação de segurança e a data de expiração do documento e avisa o usuário se algum dos testes falhar.",
                        es: "Las tarjetas de identidad y los pasaportes nacionales que cumplen con los estándares internacionales de la OACI para las zonas de lectura de máquinas (MRZ) son reconocidos y leídos automáticamente por Scan2x.Al leer la MRZ, el SWC recopila automáticamente toda la información relacionada como metadatos.Además, verifica la suma de verificación de seguridad y la fecha de vencimiento del documento y notifica al usuario si falla alguna de las pruebas.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Can you process business and contact cards?",
                        pt: "Pode processar negócios e cartões de contato?",
                        es: "¿Puede procesar tarjetas comerciales y de contacto?",
                    },
                    answer: {
                        en: "It is possible to configure a SWC job that will accept a batch of business cards and produce a PDF for each one, in which all the text on the card is searchable.This allows a company to capture and share contact information from the business card collections of all team members.",
                        pt: "É possível configurar um trabalho do SWC que aceitará um lote de cartões de visita e produzirá um PDF para cada um, no qual todo o texto do cartão seja pesquisável. Isso permite que uma empresa capture e compartilhe informações de contato das coleções de cartões de visita de todos os membros da equipe",
                        es: "Es posible configurar un trabajo SWC que aceptará un lote de tarjetas de visita y producirá un PDF para cada una, en el que se puede buscar todo el texto de la tarjeta.Esto permite que una empresa capture y comparta información de contacto de las colecciones de tarjetas de presentación de todos los miembros del equipo.",
                    },
                },
                {
                    isOpen: false,
                    question: {
                        en: "Which type of organization / sector is most beneficial?",
                        pt: "Qual tipo de organização / setor é mais benéfico?",
                        es: "¿Qué tipo de organización / sector es más beneficioso?",
                    },
                    answer: {
                        en: "Any organization in any business sector will benefit from the SWC if they have paper documents that require digitization.",
                        pt: "Qualquer organização em qualquer setor de negócios se beneficiará do SWC se tiver documentos em papel que exijam digitalização.",
                        es: "Cualquier organización en cualquier sector empresarial se beneficiará del SWC si tiene documentos en papel que requieren digitalización.",
                    },
                },
            ],            
        }
    },
    methods:{
        toggleOpen(e, index){
            e.preventDefault
            this.faq[index].isOpen = !this.faq[index].isOpen
        },

        isInterfyFamily(){
            const family = ['Interfy','Neofy','ProcessDoc','CloudDoc','Intellify','Autofy','Lawfy','Healthify','Educafy','Accountify','Realtify','DocSystem']
            return family.includes(this.domain.title)
        }
    }

}
</script>

<style scoped lang="scss">
a{
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .section-FAQ{        
        margin-top: 0 !important;
        padding: 30px 10px !important;    
        
        h2{
            width: 100%;
            text-align: center;
        }
    }
}
</style>