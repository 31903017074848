import moment from 'moment'
import $ from 'jquery'

export default {
   
    methods:{

        dateRelative(value){            
            return moment(String(value)).fromNow()
        },

        limitChars(str, limit = 20) {

            if (!str) {
                return
            }

            var newStr = str

            if (str.length > limit) {
                newStr = $.trim(str.substring(0, limit)) + "..."
            }

            return newStr

        },

        searchPosts(payload){
            console.log("Payload", payload)
        },

    }

}