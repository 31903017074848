<template>
    <div>

        <div class="bar d-flex flex-row justify-content-between align-items-center">

            <div class="categories-wrapper d-flex flex-wrap">

                <div @click.stop="handleSearch('category_id', item.id)" v-for="(item, index) in posts_categories" :key="index" v-html="item.title[lang].replace(` `, `<br>`)"></div>

            </div>

            <div class="input-wrapper">

                <b-form-group class="m-0" 
                    :description="''"                     
                    label-for="search_posts_string" 
                    :invalid-feedback="error_search_posts_string" 
                    :state="state.search_posts_string"
                >                
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <b-form-input 
                            id="search_posts_string" 
                            :placeholder="$t('SEARCH')"
                            :state="state.search_posts_string" 
                            v-model="form.search_posts_string" 
                            trim 
                            required
                        ></b-form-input>
                        <span class="btn-search" @click.stop="handleSearch('string')"> <i class="fas fa-search"></i> </span>
                    </div>
                </b-form-group>

            </div>

        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {    
    props:[
        'partner_url'
    ],    
    computed: {
        ...mapState("MainStoreModule", { 
            posts_categories: state => state.posts_categories,
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },
    data(){
        return { 
            show: false,         
            error_search_posts_string: null,            
            form:{ 
                search_posts_string: ''
            },   
            state:{ 
                search_posts_string: ''
            }   
        }
    },

    async mounted(){
        await this.getPostsCategories()
    },
    
    methods:{
        ...mapActions('MainStoreModule', [               
            'getPostsCategories'
        ]),

        handleSearch(mode, item = null){
            

            if(mode == "string"){

                const str = this.form.search_posts_string
                this.state.search_posts_string = this.isValidString(str)

                if(!this.state.search_posts_string){                  
                    return false
                }else{
                    item = str
                }

            // }else if(mode == "category_id"){

            }

            this.$emit("search", {mode: mode, item: item})
            
        },

        isValidString(str){
    
            if (
                str.length < 3
            ) {

                if(str.length == 0){
                    this.error_search_posts_string = this.$t('FIELD_IS_REQUIRED').replace('{var1}',this.$t('SEARCH'))
                }

                return false
            }
        
            return true
    
        },
       
    }

}
</script>

<style lang="scss" scoped>

.bar{
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 3px solid #F1EFF6;

    .categories-wrapper{
        div{
            cursor: pointer;
            margin: 0 5px;
            padding: 6px 15px;
            border-radius: 25px;
            background-color: #DCDDDA;
            font-size: 14px;
            line-height: 15px;
            color: rgba(0,0,0, .8);        
            font-weight: 500;       
            text-align: center; 
            transition: all 0.35s ease !important;

            &:hover{
                color: #fff;
                transition: all 0.35s ease !important;
                background-color: #60BB1F;
            }
        }
    }

    .input-wrapper{
        #search_posts_string{
            border-radius: 20px;
            padding-right: 50px;
        }

        .btn-search{
            margin-left: -40px;

            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            padding: 15px;

            color: #fff;
            background: rgb(67,101,245);
            background: linear-gradient(90deg, rgba(67,101,245,1) 0%, rgba(65,98,231,1) 49%, rgba(50,80,185,1) 100%);
            transition: all .4s ease !important;

            &:hover{
                background: rgb(96,187,31);
                background: linear-gradient(90deg, rgba(96,187,31,1) 0%, rgba(85,166,27,1) 49%, rgba(67,131,21,1) 100%);
                transition: all .4s ease !important;
            }

            i{
                color: #fff;
            }
        }
    }
}

</style>