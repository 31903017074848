import Vuex from 'vuex'
import Vue from 'vue'

import MainStoreModule from './modules/MainStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        MainStoreModule
    }
})