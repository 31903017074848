<template>
    <div style="min-height: 100vh;" class="page-cic">
        <!-- 
  
          Main banner
  
      -->
        <transition name="slide-fade">
            <div
                class="section section-main-cic base-padding d-flex flex-row justify-content-center align-items-center">

                <div class="row">
                    <div class="col-12">
                        <h1 class="mb-4 title">Colaboration and Interaction Chat</h1>
                        <h2 class="subtitle">Ideal for connecting your team and keeping your entire company in complete
                            harmony.</h2>

                        <a href="#" class="btn">Make an assessment free of charge!</a>
                    </div>
                </div>

                <div class="mockup" v-if="window.width > 991">
                    <a target="_blank"
                        href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA"><i
                            class="icon-play"></i></a>
                    <img src="/assets/images/cic/banner-topo.png" alt="">
                </div>
            </div>
        </transition>

        <!-- 
  
          Clients
  
      -->
        <transition name="slide-fade">
            <Clients class="mt-5 mb-5"></Clients>
        </transition>

        <!-- 
  
          chat whith your team
  
      -->

        <transition name="slide-fade">
            <div v-if="show" class="section section-your-team base-padding justify-content-center">
                <div class="bg-topo"></div>
                <h2>
                    Chat with your team and exchange documents through the same platform
                </h2>

                <p class="base-padding">Interfy CHAT is a fundamental collaboration resource for the secure exchange of
                    messages and
                    documents between the company, its users and external collaborators, including customers, suppliers
                    and other stakeholders.</p>

                <img src="/assets/images/cic/chat.png" alt="">

                <div class="bottom">
                    <h3>With Interfy Chat, Platform users can send invitations to non-users</h3>

                    <img src="/assets/images/cic/chat2.png" alt="">
                </div>
            </div>
        </transition>

        <!-- 
  
          register
  
      -->

        <transition name="slide-fade">
            <div v-if="show" class="section section-register base-padding justify-content-center">

                <h2>
                    They can register and be part of a specific service, or they can use this registration for ongoing
                    interactions with the company in different services, such as:
                </h2>

                <div class="flex">
                    <div class="item">
                        <img src="/assets/images/cic/solicitar-esclarecimento.png" alt="">
                        <p>Request Clarification</p>
                    </div>
                    <div class="item">
                        <img src="/assets/images/cic/solicitar-docs.png" alt="">
                        <p>Request Documents</p>
                    </div>
                    <div class="item">
                        <img src="/assets/images/cic/solicitar-suporte.png" alt="">
                        <p>Request Support</p>
                    </div>
                    <div class="item">
                        <img src="/assets/images/cic/exercise.png" alt="">
                        <p>Exercise Data Subject Rights</p>
                    </div>
                </div>

                <h3>or any other demand that requires interaction.</h3>
            </div>
        </transition>

        <!-- 
  
          keep your information
  
      -->

        <transition name="slide-fade">
            <div v-if="show" class="section section-information base-padding justify-content-center">

                <div class="center">
                    <h2>
                        Keep your information safe
                    </h2>

                    <p>The best thing about all this is that each open service channel keeps records and documents safe,
                        and they can even be encrypted with permission for specific users to view the content.
                        Everything is recorded and can be audited when necessary.</p>

                    <a href="#" class="btn start">Start Free</a>

                    <a href="#" class="btn partner">Become a Partner</a>
                </div>
            </div>
        </transition>

        <!-- 
  
          share documents
  
      -->

        <transition name="slide-fade">
            <div v-if="show" class="section section-share base-padding justify-content-center">
                <div class="bg-bottom"></div>

                <div class="center">
                    <h2>
                        Documents exchanged via chat can be stored directly in the ECM, or documents from the ECM can be
                        shared via CHAT, depending on the user's permission.
                    </h2>

                    <div class="flex">
                        <div class="item">
                            <img src="/assets/images/cic/cic-ecm.png" style="max-height: 200px">
                        </div>
                        <div class="item bg">
                            <img src="/assets/images/cic/camada.png" style="max-height: 200px">
                            <p>Share Documents</p>
                        </div>
                        <div class="item">
                            <img src="/assets/images/cic/ecm-cic.png" style="max-height: 200px">
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <!-- 
  
          manage services
  
      -->

        <transition name="slide-fade">
            <div v-if="show" class="section section-manage base-padding justify-content-center">
                <h2>Manage all your services, including:</h2>
                <div class="flex">
                    <div class="item">
                        <img src="/assets/images/cic/closed.png" alt="">
                    </div>
                    <div class="item">
                        <img src="/assets/images/cic/reopened.png" alt="">
                    </div>
                    <div class="item">
                        <img src="/assets/images/cic/def_closed.png" alt="">
                    </div>
                </div>
            </div>
        </transition>

        <!-- 
  
          messaging system
  
      -->

        <transition name="slide-fade">
            <div v-if="show" class="section section-msg-system base-padding justify-content-center">
                <h2>Have a powerful messaging system</h2>
                <p>Interfy CHAT replaces the exchange of messages and documents through means that the company cannot
                    control or even track, with a safe and easy-to-use resource.</p>

                <div class="tabs-container">
                    <div class="tabs-header">
                        <button :class="{ active: activeTab === 'tab1' }" @click="activeTab = 'tab1'">
                            Problems with other systems
                        </button>
                        <button :class="{ active: activeTab === 'tab2' }" @click="activeTab = 'tab2'">
                            Scenario with Interfy Chat
                        </button>
                    </div>

                    <div class="tabs-content">
                        <div v-if="activeTab === 'tab1'" class="tab-content">
                            <div class="flex">
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/latencia.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Latency</h3>
                                        <p>Delays in message delivery can be critical in systems that require real-time
                                            communication.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/gerenciamento.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Connection Management</h3>
                                        <p> Maintaining and managing a large number of simultaneous connections can be
                                            challenging.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/seguranca.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Security</h3>
                                        <p>Protecting messages from interception, alteration or unauthorized access is
                                            vital, especially in systems that handle sensitive information.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/rastreabilidade.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Service Traceabilit</h3>
                                        <p> Implement effective monitoring and activity logging systems to quickly
                                            detect and resolve problems.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/ESCALABILIDADE.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Scalability</h3>
                                        <p> Ensuring that all messages are delivered and are not lost in transmission is
                                            essential, especially in critical applications.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/MANTENABILIDADE.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Maintainability</h3>
                                        <p> Ensure that the system can be updated and maintained without significant
                                            interruptions to service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeTab === 'tab2'" class="tab-content">
                            <div class="flex">
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/latencia.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Latency</h3>
                                        <p>Interfy's CIC uses low-latency network technologies and protocol optimization
                                            to ensure messages are delivered quickly.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/gerenciamento.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Connection Management</h3>
                                        <p>Adopts connection pooling and multiplexing techniques to efficiently manage a
                                            large number of simultaneous connections, ensuring stability and
                                            performance.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/seguranca.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Security</h3>
                                        <p>Implements end-to-end encryption to ensure messages are secure during
                                            transmission and storage.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/rastreabilidade.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Service Traceabilit</h3>
                                        <p>Provides advanced monitoring and logging tools, with real-time dashboards and
                                            configurable alerts to quickly detect and resolve problems.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/ESCALABILIDADE.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Scalability</h3>
                                        <p>The system is designed with a scalable microservices-based architecture and
                                            dynamic load balancing, allowing new servers to be added as needed.</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="icon">
                                        <img src="/assets/images/cic/MANTENABILIDADE.svg" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>Maintainability</h3>
                                        <p>The system is designed with a modular architecture that allows for updates
                                            and maintenance without the need for interruptions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="slide-fade">
            <div v-if="show" class="section base-padding justify-content-center">
                <div class="colaboration">
                    <h2>Have a powerful messaging system</h2>
                    <p>Interfy CHAT replaces the exchange of messages and documents through means that the company
                        cannot control or even track, with a safe and easy-to-use resource.</p>

                    <img src="/assets/images/cic/colaboration.png" alt="">
                </div>
            </div>
        </transition>

        <transition name="slide-fade">
            <div class="section-faq">
                <div class="center">
                    <h2>Frequently Asked Questions</h2>
                    <FaqCic :faqs="faqList" />
                </div>
            </div>
        </transition>

        <BusinessTab />

        <!-- 
            The platform...
        -->
        <ThePlatform :window="window" v-if="show"></ThePlatform>

        <div class="section section-help">
            <div class="flex">
                <div class="item">
                    <h2>How can we help?</h2>
                </div>
                <div class="item">
                    <a href="" class="btn">Start Free</a>
                    <a href="" class="btn">Become a Partner</a>
                    <a href="" class="btn">Check out our Blog!</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FaqCic from '../common/FaqCic'
import Clients from '../common/Clients'
import ThePlatform from "../common/ThePlatform";
import RecaptchaMixin from '../../mixins/RecaptchaMixin'
import BusinessTab from '../common/BusinessTab.vue';

export default {
    mixins: [RecaptchaMixin],
    components: {
        Clients,
        ThePlatform,
        FaqCic,
        BusinessTab
    },
    props: {
        partner_url: {
            type: String,
            default: ''
        },
        window: {
            type: Object,
            default() {
                return {
                    width: 0,
                    height: 0
                }
            }
        }
    },
    data() {
        return {
            show: false,

            //msg system
            activeTab: 'tab1', // Define qual aba está ativa inicialmente

            faqList: [
                {
                    question: 'What is an CIC System?',
                    answer: 'A CIC - Collaboration and Interaction Chat system is a platform that allows the sending, receiving and management of messages and documents between different parties, such as users with access, both those who have direct access to the system, and external guests.',
                },
                {
                    question: 'What are the benefits of CIC for a company?',
                    answer: 'A CIC - Collaboration and Interaction Chat system is a platform that allows the sending, receiving and management of messages and documents between different parties, such as users with access, both those who have direct access to the system, and external guests.',
                },
                {
                    question: 'What types of documents can be shared through a CIC?',
                    answer: 'A CIC - Collaboration and Interaction Chat system is a platform that allows the sending, receiving and management of messages and documents between different parties, such as users with access, both those who have direct access to the system, and external guests.',
                },

            ],
        }
    },
    computed: {
        ...mapState('MainStoreModule', {
            api: state => state.api,
            google: state => state.google,
            domain: state => state.domain,
            env: state => state.env
        }),
        lang() {
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },
    watch: {

    },
    methods: {
        ...mapActions('MainStoreModule', [
            'setApiToken',
            'sendJobRequest',
            'upload'
        ]),
    },

    mounted() {
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }
}
</script>

<style lang="scss">
.modal-careers {

    header {
        background-color: transparent !important;

        h5 {
            padding-left: 15px;
        }
    }

}


.section-who {
    background-color: #F3F6FF;
    padding: 0 15vw;

    .item {
        margin-top: -9vw !important;

        div {
            margin: 0 30px;
            background-color: #fff;
            border-radius: 30px;
            padding: 60px;
            height: 520px;

            img {
                height: 100px;
            }

            h2,
            p {
                width: 100%;
                text-align: left;
                margin-top: 20px;
            }
        }

        &:nth-child(1) {
            h2 {
                color: #04C9FE !important;
            }
        }

        &:nth-child(2) {
            h2 {
                color: #8282F4 !important;
            }
        }
    }
}

.section-description-career {
    padding: 6vw 10vw;
    background-color: #F7F7F7;

    div {
        div {
            div {
                img {
                    height: 150px;
                    margin-bottom: 30px;
                }

                div {
                    h2 {
                        color: #1443C5;
                        text-align: center;
                        padding: 0 20px;
                        margin-bottom: 30px;
                        font-size: 24px;
                        font-weight: 600;
                    }

                    p {
                        padding: 0 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.section-description-career.listjobs {
    padding: 6vw 10vw;
    background-color: #F3F6FF;

    ul {
        width: 100%;
        list-style: none;
        padding: 0 40px 0 0;

        li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ccc;
            width: 100%;
            color: #737479;
            padding: 10px 40px;
            font-size: 23px;

            a {
                height: 50px;
            }
        }
    }
}

.section-description-career.blocks {
    background-color: #F7F7F7;
}


.section-main-cic {
    height: calc(100vh - 160px);
    background: rgb(25, 102, 250);
    background: linear-gradient(90deg, rgba(25, 102, 250, 1) 0%, rgba(8, 33, 175, 1) 35%);
    background-size: cover;
    color: #fff;

    h1 {
        color: #fff;
        width: 40%;
        text-align: left;
        font-weight: 600;
    }

    h2 {
        color: #fff;
        width: 40%;
        text-align: left;
        font-weight: 400;
    }

    .btn {
        background: #fff;
        max-width: 474px;
        width: 100%;
        padding: 7px 10px;
        border-radius: 11px;
        color: #1863F7 !important;
        font-weight: bold !important;
        text-align: center;
        display: block;
        margin: 15px 0;
    }

    .mockup {
        position: absolute;
        bottom: 12vw;
        right: 10vw;
        width: 40vw;

        img {
            width: 100%;
        }

        .icon-play {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 75px;
            height: 75px;
            margin: -37.5px 0 0 -37.5px;
            background: url('../../../public/assets/images/home/play.png') no-repeat center;
            opacity: .5;
            transition: all ease .2s;

            &:hover {
                opacity: 1;
                transform: scale(1.2);
            }
        }

    }
}

.section-your-team {
    background: #fff;
    padding: 45px 0;
    text-align: center;
    position: relative;

    .bg-topo {
        background: #F5F5FF;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 375px;
    }

    h2 {
        font-size: 32px !important;
        color: #333333;
        font-weight: bold;
        position: relative;
        line-height: normal !important;
    }

    p {
        position: relative;
        font-weight: 200;
        font-size: 16px;
        color: #333333;
    }

    img {
        position: relative;
        max-width: 950px;
        width: 100%;
        margin: 0 auto;
    }

    .bottom {
        position: relative;
        background: transparent linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #E1E1F2 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(180deg, #FFFFFF 0%, #E1E1F2 100%) 0% 0% no-repeat padding-box;
        padding-bottom: 45px;

        h3 {
            font-size: 32px !important;
            font-weight: bold !important;
            text-align: center;
            margin: 45px 0;
            line-height: normal !important;
        }
    }
}

.section-register {
    background: #fff;
    padding-top: 0 !important;
    padding-bottom: 40px !important;
    text-align: center;
    position: relative;

    h2,
    h3 {
        font-size: 32px !important;
        color: #333333;
        font-weight: bold;
        position: relative;
        line-height: normal !important;
        max-width: 1200px;
        margin: 0 auto 20px;
    }

    .flex {
        display: flex;
        margin: 0 auto;
        max-width: 1025px;
        position: relative;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        .item {
            width: 475px;
            height: 250px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 20px #6F4DD142;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            margin: 20px 0;

            p {
                font-size: 20px;
                color: #333333;
                font-weight: bold;
            }
        }
    }
}

.section-information {
    background: #F5F5FF;
    height: 425px;
    text-align: center;

    .center {
        max-width: 1250px;
        width: 98%;
        margin: 0 auto;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #1863F757;
        border-radius: 25px;
        padding: 40px 0 30px;

        h2 {
            color: #333333;
            font-size: 32px !important;
            line-height: normal !important;
        }

        p {
            font-size: 20px !important;
            color: #333333;
            font-weight: 200;
        }

        .btn {
            display: inline-block !important;
            padding: 10px !important;
            margin: 0 6px !important;

            &.start {
                width: 175px;
                background: #0B8BCE;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                border-radius: 10px;
                margin: 0 5px;

                @media (max-width: 767px) {
                    display: block !important;
                    margin: 10px auto !important;
                }

                &:hover {
                    background: #fff;
                    color: #0B8BCE;
                }
            }

            &.partner {
                width: 200px;
                background: #0040a6;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                border-radius: 10px;
                margin: 0 5px;

                @media (max-width: 767px) {
                    display: block !important;
                    margin: 10px auto !important;
                }

                &:hover {
                    background: #fff;
                    color: #0040a6;
                }
            }
        }
    }
}

.section-share {
    background: #fff;
    text-align: center;
    position: relative;

    .bg-bottom {
        background: #F5F5FF;
        height: 275px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    h2 {
        position: relative;
        color: #333333;
        font-size: 32px !important;
        line-height: normal !important;
        max-width: 1200px;
        margin: 0 auto;
    }

    .flex {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 40px 10px 30px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

        .item {
            &.bg {
                background: url('../../../public/assets/images/cic/setas.png');
                width: 300px;
                height: 275px;

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }

        p {
            font-size: 20px !important;
            color: #333333;
            font-weight: bold;
        }
    }
}

.section-manage {
    background: #fff;
    text-align: center;
    padding: 45px 0;

    h2 {
        position: relative;
        color: #333333;
        font-size: 32px !important;
        line-height: normal !important;
        margin: 0 auto;
    }

    .flex {
        display: flex;
        margin: 65px auto 0;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1320px;
    }
}

.section-msg-system {
    background: transparent linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #E1E1F2 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E1E1F2 100%) 0% 0% no-repeat padding-box;
    padding: 45px 0;
    text-align: center;

    h2 {
        position: relative;
        color: #333333;
        font-size: 32px !important;
        line-height: normal !important;
        margin: 0 auto;
    }

    p {
        position: relative;
        color: #333333;
        font-size: 16px !important;
        line-height: normal !important;
        margin: 20px auto;
        font-weight: 200;
        max-width: 1200px;
        padding: 0 10px;
    }

    .tabs-container {
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        border-radius: 5px;
    }

    .tabs-header {
        display: flex;
        background-color: #fff;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 15px #1863F740;
        border-radius: 25px;
        margin-bottom: 45px;
    }

    .tabs-header button {
        flex: 1;
        padding: 10px;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s ease;
        font-weight: bold;
        border-radius: 25px;
        color: #000;
        width: 420px;
    }

    .tabs-header button.active {
        background-color: #ffa100;
        color: white;
    }

    .tabs-header button:not(.active):hover {
        background-color: #ffa100;
    }

    .tabs-content {
        padding: 20px;
    }

    .tab-content {
        animation: fadeIn 0.3s ease-in-out;

        .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .item {
                width: 600px;
                background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 15px #1863F740;
                border-radius: 25px;
                display: flex;
                padding: 20px 10px;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 25px;

                .icon {
                    margin-right: 15px;
                }

                h3 {
                    font-size: 15px !important;
                    font-weight: bold;
                    color: #333333;
                    text-align: left;
                }

                p {
                    font-size: 14px;
                    font-weight: 200;
                    color: #333333;
                    text-align: left;
                }
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}

.colaboration {
    background: #fff;
    text-align: center;

    h2 {
        position: relative;
        color: #333333;
        font-size: 32px !important;
        line-height: normal !important;
        margin: 0 auto;
    }

    p {
        position: relative;
        color: #333333;
        font-size: 16px !important;
        line-height: normal !important;
        margin: 20px auto;
        font-weight: 200;
        max-width: 1200px;
        padding: 0 10px;
    }

    img {
        max-width: 1272px;
        width: 100%;
        margin: 0 auto;
    }
}

//section help
.section-help {
    background: #F5F5FF;
    padding: 65px 0;

    h2 {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-bottom: 45px;
    }
}

.section-faq {
    background: #F2F2FC;
    padding: 60px 0;

    h2 {
        font-size: 32px !important;
        font-weight: bold !important;
        color: #333333;
        text-align: center;
        margin-bottom: 45px;
        padding-top: 45px;
    }

    .center {
        max-width: 1150px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #1863F740;
        border-radius: 25px;
    }
}

.section-help h2 {
    font-size: 28px !important;
    font-weight: bold;
    color: #333333;
}

.section-help .flex {
    display: flex;
    max-width: 1320px;
    padding: 0 20px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.section-help .flex .item:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
}

.section-help .flex .item .btn {
    height: 100px !important;
    background: #fff;
    color: #1863F7 !important;
    text-align: center;
    position: relative;
    border-radius: 15px;
    padding-left: 75px !important;
    padding-right: 30px !important;
    margin-right: 60px;
    min-width: 210px;
    box-shadow: 0px 0px 10px #00000029;
}

.section-help .flex .item .btn:before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -20px;
}

.section-help .flex .item .btn:hover {
    background: #1863F7;
    color: #fff !important;
}

.section-help .flex .item .btn:nth-child(1):before {
    background: url('../../../public/assets/images/home/start-free.svg');
}

.section-help .flex .item .btn:nth-child(1):hover:before {
    background: url('../../../public/assets/images/home/start-white.svg');
}

.section-help .flex .item .btn:nth-child(2):before {
    background: url('../../../public/assets/images/home/become-partner.svg') no-repeat;
}

.section-help .flex .item .btn:nth-child(2):hover:before {
    background: url('../../../public/assets/images/home/become-partner-white.svg') no-repeat;
}

.section-help .flex .item .btn:nth-child(3):before {
    background: url('../../../public/assets/images/home/check-blog.svg');
}

.section-help .flex .item .btn:nth-child(3):hover:before {
    background: url('../../../public/assets/images/home/check-blog-white.svg');
}

@media only screen and (max-width: 991px) {


    .section-description-career,
    .section-simplify,
    .section-who,
    .section-main-cic {
        h1 {
            font-size: 25px !important;
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2 {
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
            font-size: 20px !important;
            line-height: 23px !important;
        }

        .btn {
            margin: 15px auto;
        }

        h3 {
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
            font-size: 18px !important;
        }
    }

    .section-your-team {

        h2,
        h2 {
            font-size: 24px !important;
        }
    }

    .section-register {

        h2,
        h3 {
            font-size: 24px !important;
        }

        .flex {
            justify-content: center;
        }
    }

    .section-information {
        .center {
            h2 {
                font-size: 25px !important;
            }

            .btn {
                display: block !important;
                margin: 5px auto;
            }
        }
    }

    .section-share {
        h2 {
            font-size: 25px !important;
        }
    }

    .section-faq {
        h2 {
            font-size: 25px !important;
        }
    }

    .section-main-cic {

        h1.title {
            font-size: 25px !important;
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2.subtitle {
            margin: 20px 0 0 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
            font-size: 20px !important;
            line-height: 23px !important;
        }
    }

    .section-who {
        padding: 30px 10px !important;
        margin: 0 !important;

        .item {
            margin: 10px 20px !important;
            padding: 0 !important;

            div {
                margin: 0 !important;
                padding: 20px !important;
                height: auto !important;

                img {
                    margin-top: 10px !important;
                    height: 60px !important;
                }

                h2 {
                    width: 100% !important;
                    margin-top: 15px !important;
                    text-align: center !important;
                }

                p {
                    width: 100% !important;
                    text-align: center !important;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    .section-simplify {
        padding: 30px 10px !important;
        margin: 0 !important;

        p {
            margin-bottom: 0 !important;
            text-align: center !important;
        }
    }

    .section-description-career {
        padding: 30px 10px !important;
        margin: 0 !important;

        img {
            margin: 10px 0 0 0 !important;
            height: 60px !important;
        }

        h2 {
            width: 100% !important;
            margin-top: 15px !important;
            text-align: center !important;
        }

        p {
            width: 100% !important;
            text-align: center !important;
            margin-bottom: 0 !important;
        }

        &.listjobs {
            padding: 0 10px !important;
            margin: 0 !important;

            ul {
                padding: 0 !important;

                li {
                    padding: 10px !important;
                    font-size: 12px !important;
                    line-height: 14px !important;

                    .btn {
                        width: 100px !important;
                        font-size: 12px !important;
                        line-height: 14px !important;
                        padding: 5px !important;
                    }
                }
            }
        }
    }

    #formCareers {

        .div-recaptcha {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px 0 !important;
        }

        .row {

            .col-sm-12,
            .col {
                padding: 0 !important;
            }
        }
    }
}

@media (max-width: 767px) {
    .section-help .flex {
        flex-direction: column;
    }

    .section-help .flex .item:nth-child(2) {
        flex-direction: column;
    }

    .section-help .flex .item .btn:nth-child(1),
    .section-help .flex .item .btn:nth-child(2),
    .section-help .flex .item .btn:nth-child(3) {
        width: 266px !important;
    }
}
</style>