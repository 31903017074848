<template>
  <div style="min-height: 100vh">
    <transition name="slide-fade">
      <div>
        <div class="section section-main-module">
          <div class="main-background"></div>

          <h1 class="mb-4 title" v-html="$t('SWC').replace(/<br>/g, '')"></h1>
          <h2 class="subtitle" v-html="$t('SWC_SUBTITLE')
            .replace(/{domain}/g, domain.title)
            .replace(/<br>/g, '')
            "></h2>

          <div class="div-buttons base-padding d-flex flex-row justify-content-center align-items-center">
            <a class="btn btn-1 mx-3" @click.stop="scrollto('#plans')">{{
              $t("LEARN_MORE")
            }}</a>
            <router-link v-if="isDomainPermitted &&
                (domain.type == 'interfy' || domain.type == 'distribuitor')
                " class="btn btn-2 mx-3" :to="`/become-a-partner${partner_url}`">{{ $t("BECOME_A_PARTNER")
    }}</router-link>
          </div>

          <div class="mockup">
            <img class="base" :src="`/assets/images/swc/mockup-${lang}-01.png`" :alt="$t('SWC') +
              ' - ' +
              $t('SWC_SUBTITLE').replace(/{domain}/g, domain.title)
              " />
          </div>
        </div>

        <Clients></Clients>
        <!-- 01 -->
        <div class="section base-padding section-swc-01 d-flex flex-column justify-content-center align-items-center">
          <h1 class="mb-4 text-center" v-html="$t('SWC_TEXT_01')"></h1>
          <h2 class="text-center" v-html="$t('SWC_TEXT_02')"></h2>
        </div>

        <!-- 02 -->
        <div class="section base-padding section-swc-02 d-flex flex-column justify-content-center align-items-center">
          <h1 class="mb-4 text-center" v-html="$t('SWC_TEXT_03')"></h1>

          <div class="mt-2 d-flex justify-content-center align-items-center row">
            <div class="col-lg-2 col-md-2 col-sm-12">
              <img src="/assets/images/swc/swc-01.svg" :alt="$t('SWC_NO_TAGS')" />
            </div>
            <div class="col-lg-10 col-md-10 col-sm-12">
              <p v-html="$t('SWC_TEXT_04')"></p>
              <p v-html="$t('SWC_TEXT_05')"></p>
            </div>
          </div>
        </div>

        <!-- 03 -->
        <div class="section section-swc-03 base-padding">
          <h1 class="mb-4 text-center" v-html="$t('SWC_TEXT_06')"></h1>

          <div :class="{ 'mt-5': window.width > 991 }" class="d-flex flex-row justify-content-center align-items-center">
            <Carousel style="width: 100%" :margin="0" :autoplay="true" :dots="false" :nav="false" :responsive="{
              0: {
                items: 1,
              },
              991: {
                items: 3,
              },
              1400: {
                items: 4,
              },
            }">
              <div class="item">
                <div class="card-col">
                  <div class="div-card d-flex flex-column justify-content-between align-items-center">
                    <div><i class="icon-swc-02"></i> <span></span></div>
                    <p v-html="$t('SWC_TEXT_07')"></p>
                    <p class="description" v-html="$t('SWC_TEXT_08')"></p>
                    <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="card-col">
                  <div class="div-card d-flex flex-column justify-content-between align-items-center">
                    <div><i class="icon-swc-03"></i> <span></span></div>
                    <p v-html="$t('SWC_TEXT_09')"></p>
                    <p class="description" v-html="$t('SWC_TEXT_10')"></p>
                    <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="card-col">
                  <div class="div-card d-flex flex-column justify-content-between align-items-center">
                    <div><i class="icon-swc-04"></i> <span></span></div>
                    <p v-html="$t('SWC_TEXT_11')"></p>
                    <p class="description" v-html="$t('SWC_TEXT_12')"></p>
                    <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="card-col">
                  <div class="div-card d-flex flex-column justify-content-between align-items-center">
                    <div><i class="icon-swc-05"></i> <span></span></div>
                    <p v-html="$t('SWC_TEXT_13')"></p>
                    <p class="description" v-html="$t('SWC_TEXT_14')"></p>
                    <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        <!-- 04 -->
        <!-- <div id="plans"
          class="section base-padding section-addon d-flex flex-column justify-content-center align-items-center">
          <h1 class="mb-4 text-center" v-html="$t('SWC_2')"></h1>

          <div style="width: 100%" class="addons-wrapper mt-5 d-flex flex-wrap justify-content-center align-items-stretch"
            v-if="addon">
            <div v-for="(tier_item, index) in addon.tiers" :key="index"
              class="addon-tier d-flex flex-row justify-content-center">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <span v-if="tier_item.max && !tier_item.last_tier_with_price" class="text-center" v-html="`${$t('UP_TO')} <b>${tier_item.max}</b> ${$t(
                  getAddonUnit(
                    addon,
                    tier_item.max == null || tier_item.max > 1
                      ? 'plural'
                      : 'singular'
                  )
                )} / ${$t('MONTH')}`
                  "></span>

                <span v-else class="text-center" v-html="`<b>${$t(
                  getAddonUnit(
                    addon,
                    tier_item.max == null || tier_item.max > 1
                      ? 'plural'
                      : 'singular'
                  )
                )} ${$t('UNLIMITED_plural')}</b>`
                  "></span>

                <p class="text-center description mb-3" v-html="tier_item.description"></p>

                <p class="text-center price" v-html="`${user_monetary.symbol} <b>${tier_item.total}</b> +${$t(
                  'PLAN'
                )} / ${$t('MONTH')}`
                  "></p>
              </div>
            </div>
          </div>
        </div> -->

        <!-- 05 -->
        <!-- <div class="section section-main-plans section-swc-05">
          <div class="plans-background"></div>
          <Plans v-if="show" :parent="'dss'" :must_have="`ECM`" :title="$t('SWC_CHOOSE_YOUR_PLAN')"></Plans>
        </div> -->
        <!-- 05 -->
        <!-- <div class="section section-main-plans section-swc-05">
                <div class="plans-background"></div>

                <div class="top-content d-flex flex-column justify-content-center align-items-center">
                    <h1 v-html="$t('CHOOSE_YOUR_SWC_PLAN').replace(/{domain}/g, domain.title)"></h1>              
                </div>

                <div class="bottom-content base-padding d-flex flex-row justify-content-center align-items-start">
                    <div class="plans-columns d-flex flex-row justify-content-center align-items-stretch">
                        <div class="mr-2 plan-column d-flex flex-column justify-content-start align-items-center" v-for="(plan, index) in plans" :key="index">

                            <div class="block-01">
                                <h1>SWC {{plan.title}}</h1>
                            </div>

                            <div class="block-02">
                                <div class="line-02" v-if="user_monetary">
                                    <div>
                                        <label>{{user_monetary.symbol}}</label>
                                        <h2>{{plan.price[user_monetary.currency_code][0]}}</h2>
                                        <label>{{user_monetary.separators.cents + plan.price[user_monetary.currency_code][1]}}</label>
                                    </div>
                                </div>

                                <div class="line-03">
                                    <label>{{plan.unit}} / {{plan.recurrence}}</label>
                                </div>

                                <div class="line-04">
                                    <a href="/start-free" class="btn btn-white btn-medium-width">{{$t('START_FREE')}}</a>
                                </div>
                            </div>

                            <div class="block-03">
                                <p v-html="$t(`SWC_${plan.title.toUpperCase()}_LINE_01`)"></p>
                                <p v-html="$t(`SWC_${plan.title.toUpperCase()}_LINE_02`)"></p>
                                <p v-html="$t(`SWC_${plan.title.toUpperCase()}_LINE_03`)"></p>
                            </div>
                        </div>  
                    </div>
                </div>
            </div> -->

        <!-- 07 -->
        <div class="section base-padding section-swc-07 d-flex flex-column justify-content-center align-items-center">
          <div class="background"></div>
          <div class="content">
            <h1 class="mb-4 text-center" v-html="$t('SWC_TEXT_15')"></h1>

            <div class="blocks-wrapper mt-3">
              <div class="block">
                <div class="inner-block">
                  <p class="mb-3" v-html="$t('SWC_TEXT_16').toUpperCase()"></p>
                  <div class="image">
                    <img class="mb-3" src="/assets/images/swc/01.png" :alt="domain.title" />
                  </div>
                  <p class="bold" v-html="$t('SWC_TEXT_17')"></p>
                  <ul>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_18')" />
                      <p v-html="$t('SWC_TEXT_18')"></p>
                    </li>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_19')" />
                      <p v-html="$t('SWC_TEXT_19')"></p>
                    </li>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_20')" />
                      <p v-html="$t('SWC_TEXT_20')"></p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="block">
                <div class="inner-block">
                  <p class="mb-3" v-html="$t('SWC_TEXT_21').toUpperCase()"></p>
                  <div class="image">
                    <img class="mb-3" src="/assets/images/swc/02.png" :alt="domain.title" />
                  </div>
                  <p class="bold" v-html="$t('SWC_TEXT_22')"></p>
                  <ul>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_23')" />
                      <p v-html="$t('SWC_TEXT_23')"></p>
                    </li>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_24')" />
                      <p v-html="$t('SWC_TEXT_24')"></p>
                    </li>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_25')" />
                      <p v-html="$t('SWC_TEXT_25')"></p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="block">
                <div class="inner-block">
                  <p class="mb-3" v-html="$t('SWC_TEXT_26').toUpperCase()"></p>
                  <div class="image">
                    <img class="mb-3" src="/assets/images/swc/03.png" :alt="domain.title" />
                  </div>
                  <p class="bold" v-html="$t('SWC_TEXT_27')"></p>
                  <ul>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_28')" />
                      <p v-html="$t('SWC_TEXT_28')"></p>
                    </li>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_29')" />
                      <p v-html="$t('SWC_TEXT_29')"></p>
                    </li>
                    <li>
                      <img src="/assets/images/processes/check.png" :alt="$t('SWC_TEXT_30')" />
                      <p v-html="$t('SWC_TEXT_30')"></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FAQSWC :key="$i18n.locale" v-if="show"></FAQSWC>

        <div class="scan2x-form-page">

          <div class="scan2x-wrapper">

            <div class="form-scan2x">

              <h1 class='text-center font-bpm mb-5' v-html="$t('LICENSE_CAPTURE_MODULE')"></h1>

              <formScan2X @onSave="isLoading = false" @error="isLoading = false">
              </formScan2X>

              <b-button @click.stop="subscribeSwc" v-if="!isLoading"
                class='btn btn-success btn-medium-width mt-3'>{{ $t('SUBSCRIBE') }}</b-button>

            </div>
          </div>
        </div>

        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show" class="ecm"></Join>
        <ThePlatform :window="window" v-if="show"></ThePlatform>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Clients from "../common/Clients";
import Join from "../common/Join";
import ThePlatform from "../common/ThePlatform";
import FAQSWC from "../common/FAQSWC";
import Plans from "../plans/plans";
import PlansMixin from "../../mixins/PlansMixin";
import formScan2X from "../common/formScan2X";

import Carousel from "vue-owl-carousel";

export default {
  inject: ["scrollto"],
  mixins: [PlansMixin],
  components: {
    Clients,
    ThePlatform,
    Join,
    Carousel,
    FAQSWC,
    // Plans,
    formScan2X,
  },
  props: {
    isDomainPermitted: {
      type: Boolean,
      default: false,
    },
    partner_url: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },
  data() {
    return {
      show: false,
      addon: null,
      isLoading: false,
      // plans: [
      //     {
      //         title: "Starter",
      //         price: {
      //             "brl": ['160', '00'],
      //             "usd": ['54', '00']
      //         },
      //         unit: this.$t('USER'),
      //         recurrence: this.$t('MONTH')
      //     },
      //     {
      //         title: "Business",
      //         price: {
      //             "brl": ['720', '00'],
      //             "usd": ['250', '00']
      //         },
      //         unit: this.$t('USER'),
      //         recurrence: this.$t('MONTH')
      //     },
      //     {
      //         title: "Professional",
      //         price: {
      //             "brl": ['1280', '00'],
      //             "usd": ['427', '00']
      //         },
      //         unit: this.$t('USER'),
      //         recurrence: this.$t('MONTH')
      //     },
      //     {
      //         title: "Corporate",
      //         price: {
      //             "brl": ['2880', '00'],
      //             "usd": ['960', '00']
      //         },
      //         unit: this.$t('USER'),
      //         recurrence: this.$t('MONTH')
      //     }
      // ]
    };
  },

  computed: {
    ...mapState("MainStoreModule", {
      user_monetary: (state) => state.user_monetary,
      domain: (state) => state.domain,
    }),

    lang() {
      return this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale;
    },
  },

  watch: {
    lang(lang) {
      this.addonTranslation();
    },
  },

  methods: {

    subscribeSwc() {

      if (this.preVerifySwcForm(true)) {

        this.isLoading = true
        this.$root.$emit('on-hit-ok-start-scan2x')

      }

    },
    ...mapActions("MainStoreModule", ["getAddonByKey"]),
    addonTranslation() {
      if (this.addon.tiers[0])
        this.addon.tiers[0].description = this.$t("SWC_STARTER_LINE_01");
      if (this.addon.tiers[1])
        this.addon.tiers[1].description = this.$t("SWC_BUSINESS_LINE_01");
      if (this.addon.tiers[2])
        this.addon.tiers[2].description = this.$t("SWC_PROFESSIONAL_LINE_01");
      if (this.addon.tiers[3])
        this.addon.tiers[3].description = this.$t("SWC_CORPORATE_LINE_01");
    },
  },

  beforeCreate() {
    this.$emit("isPageLoaded", false);
  },

  async created() {
    if (!this.api.token) {
      await this.setApiToken();
    }

    if (this.api.token) {
      await this.getAddonByKey({ key: "6" });
      await this.getSellablePlans({
        domain: this.domain.name,
        group: this.group,
      });

      this.addon = this.renderedAddons["GENERAL"][0];

      if (this.addon.tiers.length > 4) {
        this.addon.tiers.length = 4;
      }

      this.addonTranslation();
    }
  },

  async mounted() {
    setTimeout(() => {
      this.$emit("isPageLoaded", true);
      this.show = true;
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
$ecm: #0dc5c7;
$bpm: #2c2ffa;
$swc: #e82f8a;
$dss: #962dfb;
$swc: #80ba27;
$das: #dec902;

.scan2x-form-page {
  background-color: #EFF3FE;
  padding: 80px 0;

  .scan2x-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-scan2x {

      width: 850px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      border-radius: 10px;
      padding: 30px;

      -moz-box-shadow: 10px 10px 10px -12px #ccc;
      -webkit-box-shadow: 10px 10px 10px -12px #ccc;
      box-shadow: 10px 10px 10px -12px #ccc;

      h2 {
        color: #2C2FFA;
        text-align: center;
      }

      p {
        text-align: center;
        padding: 0 80px;

        a {
          color: #000 !important;
          font-weight: 600 !important;

          &:hover {
            color: #2C2FFA !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {

  .scan2x-form-page {
    padding: 10px 10px !important;

    .scan2x-wrapper {

      .form-scan2x {
        width: 100% !important;

        h2 {
          margin: 20px 0 20px 0 !important;
          padding: 0 !important;
          width: 100% !important;
          text-align: center;
          font-size: 20px !important;
        }

        .sign-agreement {
          padding: 0 !important;
        }
      }
    }
  }

}

.section-swc-01 {
  p {
    color: rgba(0, 0, 0, 0.9) !important;
    font-weight: 600;
  }

  background-color: #eff3fe;
}

.section-swc-02 {
  background-color: #fff;
  padding-left: 26vw !important;
  padding-right: 26vw !important;

  .row {
    background-color: #eff3fe;
    border: 2px solid #d8d4d4;
    padding: 30px;
    border-radius: 10px;

    div {
      padding: 0;

      img {
        width: auto;
      }

      p {
        margin: 0 !important;
        padding: 0 0 0 30px !important;
        text-align: center;
        display: block;

        &:first-child {
          margin-bottom: 20px !important;
        }

        b {
          font-weight: 700;
        }
      }
    }
  }
}

.section-swc-03 {
  background-color: #eff3fe;

  h2 {
    width: 100%;
    text-align: center;
  }

  a {
    font-size: 16px !important;
    transition: all 0.4s ease;
  }

  p {
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 2vw;
  }

  .imgOpenModal {
    margin-top: 20px;
    width: 15vw;
  }

  .item {
    padding: 30px 25px;

    .div-card {
      width: 100%;
      border-radius: 20px;
      padding: 40px 20px;
      height: 400px;
      transition: all 0.4s ease;
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
      cursor: pointer;
      text-align: center;
      background-color: #fff;

      &:hover {

        p,
        a {
          opacity: 0;
        }

        p.description {
          opacity: 1;
        }

        div {
          span {
            width: 70px;
          }
        }
      }

      div {
        margin-bottom: -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          display: block;
          margin-top: 10px;
          width: 0;
          border-radius: 20px;
          transition: all 0.2s ease;
        }

        i {
          font-size: 50px;
        }
      }

      p {
        margin: 0 !important;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;

        b {
          font-weight: 800;
        }

        &.description {
          font-size: 16px !important;
          line-height: 20px !important;
          font-weight: 400 !important;
          opacity: 0;
          position: absolute;
          padding: 20px 40px;
          top: 130px;
        }
      }

      a {
        text-decoration: none;
        border: 1px solid transparent;
        padding: 0;
        transition: all 0.2s ease;
      }
    }
  }
}

.section-swc-03 .owl-item {
  &:nth-child(1) {
    .div-card {
      &:hover {
        box-shadow: 0 0 2.5rem rgba(238, 136, 5, 0.5);
      }

      color: #ee8805;

      a:hover {
        color: #ee8805;
        border: 1px solid #ee8805;
      }

      div span {
        border-bottom: 3.5px solid #ee8805;
      }
    }
  }

  &:nth-child(2) {
    .div-card {
      &:hover {
        box-shadow: 0 0 2.5rem rgba(148, 197, 74, 0.5);
      }

      i {
        color: #94c54a;
      }

      a:hover {
        color: #94c54a;
        border: 1px solid #94c54a;
      }

      div span {
        border-bottom: 3.5px solid #94c54a;
      }
    }
  }

  &:nth-child(3) {
    .div-card {
      &:hover {
        box-shadow: 0 0 2.5rem rgba(128, 81, 152, 0.5);
      }

      i {
        color: #805198;
      }

      a:hover {
        color: #805198;
        border: 1px solid #805198;
      }

      div span {
        border-bottom: 3.5px solid #805198;
      }
    }
  }

  &:nth-child(4) {
    .div-card {
      &:hover {
        box-shadow: 0 0 2.5rem rgba(232, 48, 138, 0.5);
      }

      i {
        color: #e8308a;
      }

      a:hover {
        color: #e8308a;
        border: 1px solid #e8308a;
      }

      div span {
        border-bottom: 3.5px solid #e8308a;
      }
    }
  }
}

.section-swc-05 {
  .plans-background {
    position: absolute;
    z-index: -1;
    background-color: #4669f8;
    width: 100%;
    height: 30vw !important;
  }

  &.section-main-plans {
    .bottom-content {
      margin-bottom: 30px;
      min-height: auto !important;

      .plans-columns .plan-column .block-02 {
        margin-bottom: 20px !important;
      }
    }
  }
}

/*
.section-swc-05 {
    
    .plans-background {
        position: absolute;
        z-index: -1;
        background-color: #4669F8;
        width: 100%;
        height: 30vw !important;
    }
    
    &.section-main-plans {
        .bottom-content {
            margin-bottom: 30px;
            min-height: auto !important;

            .plans-columns {
                
                .plan-column {
                    
                    .block-01 {
                        height: auto !important; 
                        h1{
                            font-size: 21px !important;
                        }          
                    }
                    .block-02 {
                        height: auto !important;           
                    }
                    .block-03 {
                        height: 100%;
                        border-radius: 10px;
                        background-color: #EEF4FB;
                        padding: 20px; 
                        p{
                            padding: 0 !important;
                            font-weight: 400 !important;
                            font-size: 16px;
                            line-height: 20px;

                            &:nth-child(2){
                                color: $bpm !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
*/

.section-swc-07 {
  background-color: #fff;
  padding-top: 0 !important;
  position: relative;

  .background {
    position: absolute;
    z-index: 0;
    background-color: #eef4fb;
    width: 100%;
    height: 350px !important;
    top: 0;
  }

  .content {
    width: 100%;
    padding-top: 6vw;
    z-index: 1;

    .blocks-wrapper {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        width: calc(100% / 3);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: stretch;

        .inner-block {
          border: 2px solid $bpm;
          border-radius: 10px;
          padding: 25px;
          width: 100%;
          background-color: #fff;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .image {
            img {
              position: relative;
              height: 100px;
              margin: 30px 0 !important;
            }
          }

          p {
            width: 70%;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            margin: 0 !important;

            &:first-child {
              padding-bottom: 10px;
              border-bottom: 2px solid $ecm;
            }

            &.bold {
              font-weight: 600 !important;
            }
          }

          ul {
            list-style-type: none;
            margin: 30px 0 0 0;
            padding: 0;
            width: 100%;

            li {
              margin: 0 0 10px 0 !important;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              img {
                height: 25px !important;
                margin-right: 10px;
              }

              p {
                width: 100%;
                font-size: 16px !important;
                line-height: 20px !important;
                text-align: left !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1460px) {}

@media only screen and (max-width: 991px) {

  .section-swc-01,
  .section-swc-02,
  .section-swc-03,
  .section-swc-04,
  .section-swc-05,
  .section-swc-06,
  .section-swc-07,
  .section-addon,
  .section-main-module {
    h1 {
      width: 100% !important;
      text-align: center;
      padding: 20px 0 !important;
      margin: 0 !important;
    }

    h2 {
      margin: 0 0 20px 0 !important;
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
    }

    h3 {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
    }
  }

  .section-swc-02 {
    padding-left: 10px !important;
    padding-right: 10px !important;

    .row {
      padding: 20px !important;

      div {
        text-align: center;

        img {
          width: 100px !important;
        }

        p {
          margin: 20px 0 !important;
          padding: 0 !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .section-swc-07 {
    .background {
      height: 350px !important;
    }

    .content {
      padding: 30px 0 !important;

      .blocks-wrapper {
        flex-direction: column !important;
        align-items: center;
        justify-content: center;

        .block {
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 451px) and (max-width: 991px) {
  // .section-swc-05 {

  //     .top-content{
  //         h1{
  //             margin: 0 !important;
  //         }
  //     }

  //     &.section-main-plans {
  //         .bottom-content {
  //             .plans-columns {
  //                 width: 100% !important;
  //                 padding: 10px !important;
  //                 .plan-column {
  //                     width: 50% !important;
  //                     min-width: 50% !important;
  //                     max-width: 50% !important;
  //                 }
  //             }
  //         }
  //     }
  // }
}

@media only screen and (max-width: 450px) {
  // .section-swc-05 {

  //     .top-content{
  //         h1{
  //             margin: 0 !important;
  //         }
  //     }

  //     &.section-main-plans {
  //         .bottom-content {
  //             .plans-columns {
  //                 width: 100% !important;
  //                 padding: 10px !important;
  //                 .plan-column {
  //                     width: 100% !important;
  //                     min-width: 100% !important;
  //                     max-width: 100% !important;
  //                 }
  //             }
  //         }
  //     }
  // }
}
</style>