<template>
  <div>
    <form  :key="formID" class="form" :id="formID" :ref="formID" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
      <div class="inner">
        <!-- 
          FORM
        -->
        <div>
          
          <!-- 

            PERSONAL

          -->
          <h3 v-html="$t('PERSONAL_INFORMATION')"></h3>
          
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">                
              <b-form-group :label="$t('FULL_NAME')" :state="state.personal.full_name" label-for="full_name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('FULL_NAME'))">
                  <b-form-input :state="state.personal.full_name" id="full_name" v-model="form.personal.full_name" required></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <b-form-group :label="'E-mail'" :state="state.personal.email" label-for="email" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}','E-mail')">
                  <b-form-input id="email" :state="state.personal.email" v-model="form.personal.email" type="email" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              
              <b-form-group :label="$t('PHONE')" :state="state.personal.personal_phone" label-for="phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('PHONE'))">                   

                  <div class="d-flex flex-row">
                    <vue-tel-input                             
                        :key="lang"
                        id="phone"
                        required
                        :state="state.personal.personal_phone"
                        v-model="form.personal.personal_phone"
                        :allCountries="getCountries()"
                        :preferredCountries="['BR','US']"
                        :autoformat="true"
                        :validCharactersOnly="true"
                        :autoDefaultCountry="true"                            
                        :inputOptions="{
                            placeholder: '',
                            required: true
                        }"
                    ></vue-tel-input>
                  </div>
              </b-form-group>
            </div>
          </div>


          <!-- 

            COMPANY

          -->

          <h3 v-html="$t('COMPANY_INFORMATION')"></h3>

          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">                   
              <b-form-group :label="$t('COMPANYS NAME')" :state="state.company.company_name" label-for="company_name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('COMPANYS NAME'))">
                  <b-form-input :state="state.company.company_name" id="company_name" v-model="form.company.company_name" required></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">             
              <b-form-group :label="$t('COUNTRY')" :state="state.company.country" label-for="country" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('COUNTRY'))">                        
                    <v-select v-if="countriesList" id="country" v-model="form.company.country" :options="countriesList">
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        :required="!form.company.country"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <b-form-group :label="company_identification.title" :state="state.company.ein" label-for="ein" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', company_identification.title)">
                  <b-form-input :disabled="!form.company.country" v-mask="company_identification.id_mask" id="ein" :state="state.company.ein" v-model="form.company.ein" required></b-form-input>
              </b-form-group>

            </div>

          </div>

          <div class="row">
            
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('PHONE')" :state="state.company.company_phone" label-for="phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('PHONE'))">                   

                  <div class="d-flex flex-row">
                    <vue-tel-input                             
                        :key="lang"
                        id="phone"
                        required
                        :state="state.company.company_phone"
                        v-model="form.company.company_phone"
                        :allCountries="getCountries()"
                        :preferredCountries="['BR','US']"
                        :autoformat="true"
                        :validCharactersOnly="true"
                        :autoDefaultCountry="true"                            
                        :inputOptions="{
                            placeholder: '',
                            required: true
                        }"
                    ></vue-tel-input>
                  </div>
              </b-form-group>

            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">        
              <b-form-group :label="$t('EMPLOYEES')" :state="state.company.employees" label-for="employees" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('EMPLOYEES'))">                        
                  <b-form-select :state="state.company.employees" id="employees" v-model="form.company.employees" :options="employeesList" required></b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('ZIPCODE')" :state="state.company.zipcode" label-for="zipcode" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('ZIPCODE'))">
                  <b-form-input id="zipcode" v-mask="company_identification.zipcode_mask" :state="state.company.zipcode" v-model="form.company.zipcode" required></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">                  
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('ADDRESS')" :state="state.company.address" label-for="address" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('ADDRESS'))">
                  <b-form-input id="address" :state="state.company.address" v-model="form.company.address" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('NUMBER')" :state="state.company.number" label-for="number" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('NUMBER'))">
                  <b-form-input id="number" :state="state.company.number" v-model="form.company.number" required></b-form-input>
              </b-form-group>
            </div>                                  
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('COMPLEMENT')" :state="state.company.complement" label-for="complement">
                  <b-form-input id="complement" :state="state.company.complement" v-model="form.company.complement"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">                  
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('COUNTY_REGION')" :state="state.company.county_region" label-for="county_region" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('COUNTY_REGION'))">
                  <b-form-input id="county_region" :state="state.company.county_region" v-model="form.company.county_region" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('CITY')" :state="state.company.city" label-for="city" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('CITY'))">
                  <b-form-input id="city" :state="state.company.city" v-model="form.company.city" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group :label="$t('STATE')" :state="state.company.state" label-for="state" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('STATE'))">
                  <b-form-input id="state" :state="state.company.state" v-model="form.company.state" required></b-form-input>
              </b-form-group>
            </div>                  
          </div>

          <div class="row">
            <div class="col-12">
              <b-form-group :label="$t('WEBSITE')" :state="state.company.website" label-for="website" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('WEBSITE'))">
                  <b-form-input id="website" :state="state.company.website" v-model="form.company.website" required></b-form-input>
              </b-form-group>
            </div>                 
          </div>


          <!-- 

            CEO

          -->

          <!-- <h3 v-html="$t('CEO_INFORMATION')"></h3>
          
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">                
              <b-form-group :label="$t('FULL_NAME')" :state="state.ceo.ceo_full_name" label-for="ceo_full_name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('FULL_NAME'))">
                  <b-form-input :state="state.ceo.ceo_full_name" id="ceo_full_name" v-model="form.ceo.ceo_full_name" required></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <b-form-group :label="'E-mail'" :state="state.ceo.ceo_email" label-for="ceo_email" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}','E-mail')">
                  <b-form-input id="ceo_email" :state="state.ceo.ceo_email" v-model="form.ceo.ceo_email" type="email" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              
              <b-form-group :label="$t('PHONE')" :state="state.ceo.ceo_phone" label-for="ceo_phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('PHONE'))">                   

                  <div class="d-flex flex-row">
                    <vue-tel-input                             
                        :key="lang"
                        id="ceo_phone"
                        required
                        :state="state.ceo.ceo_phone"
                        v-model="form.ceo.ceo_phone"
                        :allCountries="getCountries()"
                        :preferredCountries="['BR','US']"
                        :autoformat="true"
                        :validCharactersOnly="true"
                        :autoDefaultCountry="true"                            
                        :inputOptions="{
                            placeholder: '',
                            required: true
                        }"
                    ></vue-tel-input>
                  </div>
              </b-form-group>
            </div>
          </div> -->


          <!-- 

            TECH

          -->

          <!-- <h3 v-html="$t('TECH_INFORMATION')"></h3>
          
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">                
              <b-form-group :label="$t('FULL_NAME')" :state="state.tech.tech_full_name" label-for="tech_full_name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('FULL_NAME'))">
                  <b-form-input :state="state.tech.tech_full_name" id="tech_full_name" v-model="form.tech.tech_full_name" required></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <b-form-group :label="'E-mail'" :state="state.tech.tech_email" label-for="tech_email" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}','E-mail')">
                  <b-form-input id="tech_email" :state="state.tech.tech_email" v-model="form.tech.tech_email" type="email" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              
              <b-form-group :label="$t('PHONE')" :state="state.tech.tech_phone" label-for="tech_phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('PHONE'))">                   

                  <div class="d-flex flex-row">
                    <vue-tel-input                             
                        :key="lang"
                        id="tech_phone"
                        required
                        :state="state.tech.tech_phone"
                        v-model="form.tech.tech_phone"
                        :allCountries="getCountries()"
                        :preferredCountries="['BR','US']"
                        :autoformat="true"
                        :validCharactersOnly="true"
                        :autoDefaultCountry="true"                            
                        :inputOptions="{
                            placeholder: '',
                            required: true
                        }"
                    ></vue-tel-input>
                  </div>
              </b-form-group>
            </div>
          </div> -->

          

          <!-- 

            COMPANY DETAILS

          -->
          <h3 v-html="$t('COMPANY_DETAILS')"></h3>
          

          <h6 class="title" v-html="$t('WHICH PRODUCTS DOES YOUR COMPANY ALREADY RESELLS?')"></h6>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="d-flex flex-wrap justify-content-center align-items-center">
                <b-form-checkbox class="mx-3" v-model="form.company.resells_ecm" name="check-button" switch>ECM</b-form-checkbox>
                <b-form-checkbox class="mx-3" v-model="form.company.resells_bpm" name="check-button" switch>BPM</b-form-checkbox>
                <b-form-checkbox class="mx-3" v-model="form.company.resells_crm" name="check-button" switch>CRM</b-form-checkbox>
                <b-form-checkbox class="mx-3" v-model="form.company.resells_erp" name="check-button" switch>ERP</b-form-checkbox>
                <b-form-checkbox class="mx-3" v-model="form.company.resells_bi" name="check-button" switch>BI</b-form-checkbox>                      

                <b-form-input style="width: 130px;" class="mx-3" id="resells_others" :state="state.company.resells_others" v-model="form.company.resells_others" :placeholder="$t('OTHERS')"></b-form-input>
              </div>
            </div>
          </div>


          <h6 class="title" v-html="$t('IN WHAT SEGMENT DOES YOUR COMPANY OR RESELLER OPERATES?')"></h6>

          <div class="row">
            <div 
              :class="{
                  'col-lg-4 col-md-4 col-sm-12': ['Outro','Otro','Other'].includes(form.company.segment),
                  'col-lg-6 col-md-12 col-sm-12': !(['Outro','Otro','Other'].includes(form.company.segment)),
              }">             
              <b-form-group :label="$t('SEGMENT')" :state="state.company.segment" label-for="segment" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('SEGMENT'))">                        
                  <b-form-select :state="state.company.segment" id="segment" v-model="form.company.segment" :options="segmentsList" required></b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12" v-if="['Outro','Otro','Other'].includes(form.company.segment)">                    
              <b-form-group :label="$t('OTHER')" :state="state.company.other_segment" label-for="other_segment" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('OTHER'))">                        
                <b-form-input id="other_segment" :state="state.company.other_segment" v-model="form.company.other_segment" required></b-form-input>
              </b-form-group>                    
            </div>

            <div 
              :class="{
                  'col-lg-4 col-md-4 col-sm-12': ['Outro','Otro','Other'].includes(form.company.segment),
                  'col-lg-6 col-md-12 col-sm-12': !(['Outro','Otro','Other'].includes(form.company.segment)),
              }"
            >
              <b-form-group :label="$t('OTHER BRAND REPRESENTATIVE')" :state="state.company.other_brand" label-for="other_brand">                        
                <b-form-input id="other_brand" :state="state.company.other_brand" v-model="form.company.other_brand"></b-form-input>
              </b-form-group>
            </div>
          </div>

          <h6 class="title" v-html="$t('HOW DID YOU FIND US?')"></h6>

          <div class="row">
            <div 
              :class="{
                  'col-lg-6 col-md-12 col-sm-12': ['Outro','Otro','Other'].includes(form.company.media),
                  'col-12': !(['Outro','Otro','Other'].includes(form.company.media)),
              }"
            >             
              <b-form-group label="" :state="state.company.media" label-for="media" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('HOW DID YOU FIND US?'))">                        
                  <b-form-select :state="state.company.media" id="media" v-model="form.company.media" :options="mediasList" required></b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12" v-if="['Outro','Otro','Other'].includes(form.company.media)">
              <b-form-input id="other_media" :state="state.company.other_media" v-model="form.company.other_media" :placeholder="$t('OTHER')" required></b-form-input>
            </div>
          </div>

        </div>

        <!-- 
          AGREEMENTS
        -->
        <div class="row mt-3">
          <div class="col row d-flex flex-column">

            <div v-if="isInterfyGroup() && form.partnership == 'Authorized Partner'" class="form-check d-flex flex-column justify-content-center align-items-start">
              <input type="checkbox" class="form-check-input" @click="form.agreement_accepted = !form.agreement_accepted" :id="`accept_agreement_${formID}`">
              <label class="form-check-label" :for="`accept_agreement_${formID}`" v-html="$t('ACCEPT_AGREEMENT').replace(/{domain}/g, domain_title).replace(/{lang}/g, lang)"></label>
            </div>
            <div v-if="isInterfyGroup() && form.partnership != 'Authorized Partner'" class="form-check d-flex flex-column justify-content-center align-items-start">
              <input type="checkbox" class="form-check-input" @click="form.agreement_accepted = !form.agreement_accepted" :id="`accept_agreement_${formID}`">
              <label class="form-check-label" :for="`accept_agreement_${formID}`" v-html="$t('ACCEPT_AGREEMENT_PRIVATE_LABEL').replace(/{domain}/g, domain_title).replace(/{lang}/g, lang)"></label>
            </div>
            
            <div class="form-check d-flex flex-column justify-content-center align-items-start">
              <input type="checkbox" class="form-check-input" @click="form.policy_accepted = !form.policy_accepted" :id="`accept_policy_${formID}`">
              <label class="form-check-label" :for="`accept_policy_${formID}`" v-html="$t('ACCEPT_PRIVACY_POLICY').replace(/{domain}/g, domain_title).replace(/{lang}/g, lang)"></label>
            </div>
            <div class="form-check d-flex flex-column justify-content-center align-items-start">
              <input type="checkbox" class="form-check-input" @click="form.terms_accepted = !form.terms_accepted" :id="`accept_terms_${formID}`">
              <label class="form-check-label" :for="`accept_terms_${formID}`" v-html="$t('ACCEPT_TERMS').replace(/{domain}/g, domain_title).replace(/{lang}/g, lang)"></label>
            </div>

          </div>
        </div>

        <!-- 
          RECAPTCHA
        -->
        <div class="row">
          <div class="col div-recaptcha mt-3">
            <vue-recaptcha
              ref="recaptcha"
              @verify="recaptchaOnVerify"
              @expired="recaptchaOnExpired"
              :sitekey="domain.googlerecaptcha_key"
              :loadRecaptchaScript="true"
            >
            </vue-recaptcha>
          </div>
        </div>

        <!-- 
          SUBMIT 
        -->
        <div class="row row-btn">
          <div class="col">
            <div class="btn-wrapper d-flex flex-column justify-content-center align-items-end">                    
                
              <button class="btn btn-success btn-medium-width" @click.stop="handleOk">{{ !isLoading ? $t('SEND') : $t('AWAIT')}}</button>
              <span v-if="isLoading" class="btn-overlay"></span>
                
            </div>
          </div>
        </div>
      </div>
      
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
import countriesJson from '../../../public/assets/json/countries.json'
import RecaptchaMixin from '../../mixins/RecaptchaMixin'

export default {
  mixins: [RecaptchaMixin],
   
    props: {       
        formID: {
          type: String,
          default: null
        },
        partnership: {
          type: String,
          default: null
        },                                    
        mode: {
          type: String,
          default: null
        },                                    
        window: {
            type: Object,
            default () {
                return {
                    width: 0,
                    height: 0
                }
            }
        },
        page: {
            type: String,
            default: ''
        },
        path: {
            type: String,
            default: ''
        }
    },
    inject: ['scrollto','showAlert'],
    data(){
      return{

        company_identification: {
          title: this.$t('company_identification'),
          id_mask: "##########################",
          zipcode_mask: "############"
        },
        
        domain_title: "Interfy",
        isLoading: false,     
        countriesJson: countriesJson,                           
        countriesList: null,
        state: {
          partnership: null,
          agreement_accepted: null,
          policy_accepted: null,
          terms_accepted: null,
          personal: {                
            full_name: "",
            email: "",
            phone: "",
          },
          company: {
            company_name: "", 
            employees: "",               
            ein: "",                
            country: "",
            phone: "",
            zipcode: "",
            address: "",
            county_region: "",
            number: "",
            city: "",
            state: "",
            complement: "",            
            website: "",
            resells_ecm: "",
            resells_bpm: "",
            resells_crm: "",
            resells_erp: "",
            resells_bi: "",
            resells_others: "",
            segment: "",
            other_segment: "",
            other_brand: "",                
            media: "",
            other_media: ""
          }, 
          ceo:{
            ceo_phone: "",
            ceo_email: "",
            ceo_full_name: "",            
          },
          tech:{
            tech_phone: "",
            tech_email: "",
            tech_full_name: "",
          }
        },
        form: {
          partnership: '',
          agreement_accepted: false,
          policy_accepted: false,
          terms_accepted: false,
          personal: {
            full_name: '',
            email: '',
            personal_phone: '',
          },
          company: {
            company_name: '',      
            employees: '',          
            ein: '',                
            country: '',
            company_phone: '',
            zipcode: '',
            address: '',
            county_region: '',
            number: '',
            city: '',
            state: '',
            complement: '',            
            website: '',
            resells_ecm: false,
            resells_bpm: false,
            resells_crm: false,
            resells_erp: false,
            resells_bi: false,
            resells_others: '',
            segment: '',
            other_segment: '',
            other_brand: '',                
            media: '',
            other_media: ''
          }, 
          ceo:{
            ceo_phone: '',
            ceo_email: '',
            ceo_full_name: '',            
          },
          tech:{
            tech_phone: '',
            tech_email: '',
            tech_full_name: '',
          } 
        },                
        partnershipList: [
          { value: "Authorized Partner", text: "Authorized Partner" },
          { value: "White Label Partner", text: "White Label Partner" },
        ],
        employeesList: [
          { value: "1-10", text: "1-10" },
          { value: "11-50", text: "11-50" },
          { value: "51-200", text: "51-200" },
          { value: "201-500", text: "201-500" },
          { value: "501-1000", text: "501-1000" },
          { value: "1001-5000", text: "1001-5000" },
          { value: "5001-10000", text: "5001-10000" },
          { value: "10000+", text: "10000+" }
        ],
        mediasList: [],
        medias: [
          {
            "pt": "Revistas",
            "en": "Magazines",
            "es": "Revistas"
          },
          {
            "pt": "Jornais",
            "en": "Newspapers",
            "es": "Periódicos"
          },
          {
            "pt": "Pesquisadores",
            "en": "Searchers",
            "es": "Buscadores"
          },
          {
            "pt": "Facebook",
            "en": "FaceBook ",
            "es": "Facebook"
          },
          {
            "pt": "Instagram",
            "en": "Instagram",
            "es": "Instagram"
          },
          {
            "pt": "Google",
            "en": "Google",
            "es": "Google"
          },
          {
            "pt": "Marketing de email",
            "en": "E-mail Marketing",
            "es": "Correo de propaganda"
          },
          {
            "pt": "Outro",
            "en": "Other",
            "es": "Otro"
          },
        ],
        segmentsList: [],
        segments: [
            {
              "pt": "Renvenda de Software",
              "en": "Software Resellers",
              "es": "Distribuidores de software",
            },
            {
              "pt": "Revenda de scanners",
              "en": "Scanners Reseller",
              "es": "Distribuidor de escáneres",
            },
            {
              "pt": "Revenda de Outsourcing de impressão",
              "en": "Printing Outsourcing Reseller",
              "es": "Revendedor de outsourcing de impresión",
            },
            {
              "pt": "Consultoria em TI",
              "en": "IT Consulting",
              "es": "Consultoría de TI",
            },
            {
              "pt": "Birô de Digitalização de Documentos",
              "en": "Document Scanning Bureaus",
              "es": "Oficinas de escaneo de documentos",
            },
            {
              "pt": "Revenda de Certificados Digitais",
              "en": "Resale of Digital Certificates",
              "es": "Reventa de Certificados Digitales",
            },
            {
              "pt": "Empresas de armazenamento de documentos",
              "en": "Document Storage Companies",
              "es": "Empresas de almacenamiento de documentos",
            },
            {
              "pt": "Revenda de produtos de informática ",  
              "en": "Resale of Computer Products",     
              "es": "Reventa de productos informáticos ",  
            },
            {
              "pt": "Outro",  
              "en": "Other",     
              "es": "Otro",  
            },
        ]
      }
    },
    computed: {
        ...mapState('MainStoreModule', {                                     
            api: state => state.api,
            google: state => state.google,
            domain: state => state.domain,
            user_ip: state => state.user_ip,
            env: state => state.env
        }),
        lang(){
          return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        } 
    },
    watch:{    
      partnership(partnership){
        console.log(">> Form Partnership", partnership)
        this.form.partnership = partnership
      },  
      form: {
        deep: true,
        handler(form){

          this.setCompanyIdentification()

          if(!(['Outro','Otro','Other'].includes(form.company.segment))){
            this.form.company.other_segment = ''            
          }

          if(!(['Outro','Otro','Other'].includes(form.media))){
            this.form.company.other_media = ''
          }

          localStorage.setItem("pre_partner_temp_data", JSON.stringify(form))
        }
        
      },
      isLoading(bool){
        $(".modal-dialog button").prop('disabled', bool)
      },
    },
    methods: {
        ...mapActions('MainStoreModule', [             
          'setApiToken',
          'sendPartnershipRequest',
          "savePrePartner",
          'editPrePartner',
          "getPrePartnerByEmail"
        ]), 


        getCountries(){

            var countries = []

            for(var i in this.countriesJson){
                var country = this.countriesJson[i]
                var name = null

                if(this.lang == 'en'){
                    name = country['name']['common']
                }else{          
                    name = country['translations'][this.lang]['common']
                }

                countries.push({
                    name: name,
                    iso2: country.cca2,
                    dialCode: country.callingCode[0],
                    priority: 0,
                    areaCodes: null
                })
            }

            return countries
        },

        setCountriesList(){
      
          var countriesList = []

          for(var i in this.countriesJson){
            var country = this.countriesJson[i]

            var name = null,                
                code = country['cca3']
                

            if(this.lang == 'en'){
              name = country['name']['common']
            }else{          
              name = country['translations'][this.lang]['common']
            }

            countriesList.push({
              code: code,
              label: this.$t(`${name}`)
            })

          }

          this.countriesList = countriesList
        },

        setCompanyIdentification() {

          var identification = {},
              country_code = this.form.company.country ? this.form.company.country.code : null
          
          if(country_code == 'MOZ'){

            identification = {
              title: "NUIT",
              id_mask: "#########",
              zipcode_mask: "####"
            }

          }else if(country_code == 'BRA'){

            identification = {
              title: "CNPJ",
              id_mask: "##.###.###/####-##",
              zipcode_mask: "#####-###"
            }

          }else if(country_code == 'USA'){

            identification = {
              title: "EIN",
              id_mask: "##-#######",
              zipcode_mask: "#####"
            }

          }else{
            
            identification = {
              title: this.$t('company_identification'),
              id_mask: "##########################",
              zipcode_mask: "##########"
            }
          }

          return this.company_identification = identification
        },

        isInterfyGroup(){          
          return ['localhost','clouddod','docsystem','intellify','interfy','neofy','processdoc'].includes(this.domain.name)
        },

        formatSegments(){
          for(var i in this.segments){
            var name = this.segments[i][this.lang]

            this.segmentsList.push({
              value: name,
              text: this.$t(`${name}`)
            })
          }
        },

        formatMedias(){
          for(var i in this.medias){
            var name = this.medias[i][this.lang]

            this.mediasList.push({
              value: name,
              text: this.$t(`${name}`)
            })
          }
        },
        
        checkFormValidity(form) {
          
          var t = this
          var v = true
          var scrolled = false

          $(form).find('input, select').each(function(){
            
            const valid = this.checkValidity()
            const id = $(this).attr('id')

            for(var group in t.state){

              for(var field in t.state[group]){    
                if(field == id){                  
                  t.state[group][field] = valid
                }
              }
            }
              
            if(!valid){   
              if(!scrolled)
                t.scrollto($(this))

              scrolled = true                      
              v = valid
            }

          })

          return v
          
        },
        resetForm() {

          this.form = {
            partnership: '',
            agreement_accepted: false,
            policy_accepted: false,
            terms_accepted: false,
            personal: {
              full_name: '',
              email: '',
              personal_phone: '',
            },
            company: {
              company_name: '',      
              employees: '',          
              ein: '',                
              country: '',
              company_phone: '',
              zipcode: '',
              address: '',
              county_region: '',
              number: '',
              city: '',
              state: '',
              complement: '',              
              website: '',
              resells_ecm: false,
              resells_bpm: false,
              resells_crm: false,
              resells_erp: false,
              resells_bi: false,
              resells_others: '',
              segment: '',
              other_segment: '',
              other_brand: '',                
              media: '',
              other_media: ''    
            },
            ceo:{
              ceo_phone: '',
              ceo_email: '',
              ceo_full_name: '',            
            },
            tech:{
              tech_phone: '',
              tech_email: '',
              tech_full_name: '',
            }    
          },

          this.state = {            
            accept: {
              agreement: null,
              policy: null,
            },
            personal: {
              full_name: null,              
              email: null,
              phone: null,
            },
            company: {
              company_name: null, 
              employees: null,               
              ein: null,                
              country: null,
              phone: null,
              zipcode: null,
              address: null,
              county_region: null,
              number: null,
              city: null,
              state: null,
              complement: null,              
              website: null,
              resells_ecm: null,
              resells_bpm: null,
              resells_crm: null,
              resells_erp: null,
              resells_bi: null,
              resells_others: null,
              segment: null,
              other_segment: null,
              other_brand: null,              
              media: null,
              other_media: null 
            },
            ceo:{
              ceo_phone: null,
              ceo_email: null,
              ceo_full_name: null,            
            },
            tech:{
              tech_phone: null,
              tech_email: null,
              tech_full_name: null,
            }
          }
        },

        handleOk(e) {          
          e.preventDefault()          
          this.handleSubmit()

          let el = document.querySelector( ':focus' )
          if( el ) el.blur()
        },
      
        async handleSubmit() {

          if (!this.checkFormValidity("#"+this.formID)) {            
            return
          }

          if(this.isInterfyGroup()){

            // if(this.form.partnership == 'Authorized Partner' && !this.form.agreement_accepted){
            if(!this.form.agreement_accepted){
              this.showAlert({
                bgVariant: "warning",
                textVariant: "dark",
                buttons: "ok-only",
                title: this.$t('WARNING'),
                message: this.$t((this.form.partnership == 'Authorized Partner') ? 'SOLVE_AGREEMENT' : 'SOLVE_AGREEMENT_PRIVATE_LABEL').replace('{domain}', this.domain_title)
              })
              return
            }

          }else{

            this.form.partnership = "Distribuitor's Partner"

          }

          if(!this.form.policy_accepted){
            this.showAlert({
              bgVariant: "warning",
              textVariant: "dark",
              buttons: "ok-only",
              title: this.$t('WARNING'),
              message: this.$t('SOLVE_PRIVACY_POLICY').replace('{domain}', this.domain_title)
            })
            return
          }

          if(!this.form.terms_accepted){
            this.showAlert({
              bgVariant: "warning",
              textVariant: "dark",
              buttons: "ok-only",
              title: this.$t('WARNING'),
              message: this.$t('SOLVE_TERMS').replace('{domain}', this.domain_title)
            })
            return
          }

          if(!this.recaptcha){

            this.showAlert({
              bgVariant: "warning",
              textVariant: "dark",
              buttons: "ok-only",
              title: this.$t('WARNING'),
              message: this.$t('SOLVE_RECAPTCHA')
            })            
            return

          }

          this.recaptchaReset()
          this.$refs.recaptcha.reset() // Direct call reset method        

          this.form.company.country = this.form.company.country.code

          this.form.mode = this.mode
          this.form.view = "partnership"
          this.form.subject = (this.mode == 'partner') ? "Novo contato sobre parceria!" : "Novo contato sobre distribuição!"                              
          this.isLoading = true

          /*
                
              Check if pre_partner already exists

          */
          await this.getPrePartnerByEmail({ email: this.form.personal.email }).then(async (response) => {

            var data = {
              locale: this.$i18n.locale,
              user_ip: this.user_ip,
              partnership: this.form.partnership,
              ...this.form,
              ...this.form.personal,
              ...this.form.company,
              ...this.form.ceo,
              ...this.form.tech,
            }

            if (response.length == 0) {
                
                await this.savePrePartner(data).then((res) => {
                    console.log(">> New Pre Partner", res)
                    this.sendEmail(data)
                }).finally(() => {                  
                  this.resetForm()
                })

            } else {
                console.log(">> Already a Pre Partner", response)

                await this.editPrePartner({ id: response.id, data: data }).then((response) => {
                    this.sendEmail(data)
                }).finally(() => {                  
                  this.resetForm()
                })
            }
          })

        },

        async sendEmail(data){

          /*
            If there's a partner linked to the domain, notify him too
          */
          if(this.domain.partner){
            data.partner = this.domain.partner
          }

          /*
              
            Send notification

          */
          await this.sendPartnershipRequest(data).then((response) => {

            if(response.sent){

              this.showAlert({
                bgVariant: "success",
                textVariant: "light",
                buttons: "ok-only",
                title: this.$t('SENT_SUCESS'),
                message: this.$t('THANKS_PARTNERSHIP').replace('{domain}', this.domain_title)
              }) 
            }

            this.$root.$emit("closePartnershipForm")
          })

          this.isLoading = false

        },

        
    }, 

    
    async created(){      
      if(!this.api.token){                   
        await this.setApiToken()
      }
    },

    mounted(){              
      this.setCountriesList()
      this.formatSegments()
      this.formatMedias()

      this.form.partnership = this.partnership

      this.domain_title = this.domain.type == 'interfy' ? "Interfy" : this.domain.title
    } 
    
}
</script>

<style scoped lang="scss">

  @media only screen and (max-width: 991px) {

    .form{
      width: 100% !important;
      padding: 0 10px !important;

      .row{
        margin: 0 !important;
      }

      .form-check{
        display: flex;        
        justify-content: flex-start;        
        margin-bottom: 15px;

        &:last-child{
          margin-bottom: 0 !important;
        }
      }

      .div-recaptcha{        
        display: flex;        
        justify-content: center;        
        align-items: center;
        margin: 15px 0 !important;
      }

      .row-btn{
        
        .btn-wrapper{          
          display: flex;
          justify-content: center !important;
          align-items: center !important;

          .btn{            
            margin: 0 !important;
          }
        }
      }
      
    }
  }

</style>