<template>
  <div>

    <div class="top-content d-flex flex-column justify-content-center align-items-center">
      <h1 v-html="title || $t('PLANS_TITLE').replace(/{domain}/g, domain.title)"></h1>
      <div class="d-flex">
        <label class="mr-2" style="font-size: 1.25rem; line-height: 1.5; font-weight: 600"
          :style="{ color: (recurrence === 'year') ? '#0c182e' : 'white' }">{{ $t('MONTHLY_PLAN') }}</label>
        <b-form-checkbox switch size="lg" button-variant="danger" :checked="recurrence === 'year'"
          @change="toggleRecurrence">
          <span :style="{ color: (recurrence === 'month') ? '#0c182e' : 'white' }">{{ $t('SAVE_WITH_YEARLY_PLAN') }}</span>
        </b-form-checkbox>
      </div>
    </div>

    <div class="bottom-content base-padding d-flex flex-row justify-content-center align-items-start">
      <!-- 
        Addons titles column
      -->
      <div v-if="renderedPlans.length > 0"
        class="mr-2 fixed-column d-flex flex-column justify-content-center align-items-center">
        <div class="block-01">{{ $t('CHOOSE_THE_PLAN') }}</div>
        <div class="block-02">{{ $t('PRODUCTS_INCLUDED') }}</div>
        <div class="block-03" v-html="$t('PRICE_BY_PLAN')"></div>
        <div class="block-04" v-if="parent == 'prices'">
          <ul class="list-addons">
            <li>{{ $t('UNLIMITED_USERS') }}</li>
            <li>{{ $t('AMOUNT_USERS_FULL') }}</li>
            <li>{{ $t('AMOUNT_USERS_VIEW') }}</li>
            <li>{{ $t('AMOUNT_OF_STORAGE') }}</li>
            <li v-if="lang == 'pt'">{{ $t('DSS_ADDON') }}</li>
            <li v-if="lang == 'pt'">{{ $t('SLV_ADDON') }}</li>

            <li>{{ $t('UNLIMITED_PROCESSES') }}</li>
            <li>{{ $t('INTEGRATED_SUPPORT') }}</li>
            <li>{{ $t('INTERFY_UNIVERSITY') }}</li>
            <li>{{ $t('AD_1') }}</li>
            <li>{{ $t('AD_2') }}</li>
            <li>{{ $t('AD_3') }}</li>
            <li>{{ $t('API_ACCESS') }}</li>
            <li>{{ $t('AD_4') }}</li>
            <li>{{ $t('AD_5') }}</li>
            <li>{{ $t('AD_6') }}</li>
            <li>{{ $t('AD_7') }}</li>
            <li>{{ $t('AD_8') }}</li>
            <li>{{ $t('AD_9') }}</li>
            <li>{{ $t('AD_10') }}</li>
            <li>{{ $t('AD_11') }}</li>
            <li>{{ $t('AD_12') }}</li>
            <li>{{ $t('AD_13') }}</li>
            <li>{{ $t('AD_14') }}</li>
            <li>{{ $t('AD_15') }}</li>

            <!-- <li class="li-divider">
              <h1>{{ $t('ADDITIONAL_INVESTMENTS') }}</h1>
            </li> -->

            <li v-for="(addon, index) in renderedAddons['GENERAL']" :key="index" :id="`tooltip-general-${index}`">
              {{ limitChars(addon.name[lang], 70) }}
              <b-tooltip v-if="addon.name[lang].length >= 70" :target="`tooltip-general-${index}`" triggers="hover">{{
                addon.name[lang] }}</b-tooltip>
            </li>
          </ul>
        </div>
      </div>

      <div class="plans-columns d-flex flex-row justify-content-center align-items-start">
        <div :class="{ 'is-popular': plan.isPopular }"
          class="mr-2 plan-column d-flex flex-column justify-content-center align-items-center"
          v-for="(plan, index) in renderedPlans" :key="index">

          <!-- 
            Plan + modules
          -->
          <div class="block-01">
            <small v-if="plan.isPopular">{{ $t('MOST_POPULAR') }}</small>
            <h1>{{ plan.tier }}</h1>
            <img :src="'/assets/images/plans/' + plan.tier + '.png'" alt="">
            <h1 style="font-size: 18px !important;">{{ plan.modules }}{{ getNonModules(plan.type) }}</h1>
          </div>

          <div class="block-02">

            <!-- 
              From price
            -->
            <!-- <div class="line-01" v-if="plansPaymentMethod == 'credit-card'">
              <label>{{ $t('FROM') }}: {{ (acceptCurrencies.includes(currency_by_url)) ? ((currency_by_url.toLowerCase() ==
                'usd') ? "$" : "R$") : user_monetary.symbol }} {{ plan.initialPrice[0] + user_monetary.separators.cents +
    plan.initialPrice[1] }}</label>
              <label v-if="plansPaymentMethod == 'credit-card'">{{ $t('TO') }}: </label>

              <span>-{{ plan.discount }}%</span>
            </div> -->

            <!-- 
              To price
            -->
            <div class="line-02">
              <div>
                <label> {{ (acceptCurrencies.includes(currency_by_url)) ? ((currency_by_url.toLowerCase() == 'usd') ?
                  "$"
                  : "R$") : user_monetary.symbol }}</label>

                <div v-if="plan.prices[0].recurrence === 'month'">
                  <h2>{{ String(parseFloat(plan.prices[0].value).toFixed(2)).split('.')[0] }}</h2>
                  <label>{{ user_monetary.separators.cents +
                    String(parseFloat(plan.prices[0].value).toFixed(2)).split('.')[1] }}</label>
                </div>

                <div v-else>
                  <h2>{{ String(parseFloat(plan.prices[0].value / 12).toFixed(2)).split('.')[0] }}</h2>
                  <label>{{ user_monetary.separators.cents +
                    String(parseFloat(plan.prices[0].value / 12).toFixed(2)).split('.')[1] }}</label>
                </div>
                <!-- <h2 v-if="plansPaymentMethod == 'credit-card'">{{ plan.finalPrice[0] }}</h2>
                <h2 v-if="plansPaymentMethod == 'manual'">{{ plan.initialPrice[0] }}</h2> -->

                <!-- <label v-if="plansPaymentMethod == 'credit-card'">{{ user_monetary.separators.cents +
                  plan.finalPrice[1] }}</label>
                <label v-if="plansPaymentMethod == 'manual'">{{ user_monetary.separators.cents +
                  plan.initialPrice[1] }}</label> -->
              </div>

            </div>

            <div class="line-03">
              <label>{{ $t('PER_USER') }} / {{ $t("MONTH") }}</label><br />
              <label>{{ $t('USER_MINIMUM', { user_count: plan.min_units }) }}</label><br v-if="plan.prices[0].recurrence === 'year'" />
              <label v-if="plan.prices[0].recurrence === 'year'">{{ $t('BILLED_ANNUALLY') }}</label>
            </div>

            <!-- 
              Add to cart Buttons
               - In case it's a new pre_customer, he will need to fulfill a form
               - In case it's a plan change (from checkout page), it's not necessary to request an e-mail confirmation
            -->
            <div class="line-04" v-if="!hasPreCustomer()">
              <router-link class="btn btn-white btn-medium-width" :to="`/start-free${partner_url}`">{{ $t('START_FREE')
                }}
              </router-link>
            </div>
            <div class="line-04" v-else>
              <router-link class="btn btn-white btn-medium-width" :to="`/start-free${partner_url}`">{{ $t('START_FREE')
                }}
              </router-link>
            </div>

          </div>

          <!-- 
            Addons by each plan
          -->
          <div class="block-03" v-if="parent == 'prices'">
            <ul class="list-addons">
              <li class="on"> {{ $t('CHARGE_PER_USER') }}</li>

              <!-- REG. USERS -->
              <li class="on"> {{ $t('STARTS_WITH_X_USERS', { user_count: plan.min_units }) }}</li>

              <!-- VIEW USERS -->
              <!-- <li class="on" v-if="plan.min_view_users > 0"> {{ plan.min_view_users }} {{ $t('USERS') }}</li>
              <li class="off" v-if="plan.min_view_users == 0"> <img :src="`/assets/images/plans/false.png`"></li> -->
              <li class="on" v-if="'view_users_per_full_user' in plan && plan.view_users_per_full_user"> {{
                plan.view_users_per_full_user }} {{ $t('BY_USER') }}</li>
              <li class="off" v-else> <img :src="`/assets/images/plans/false.png`"></li>

              <!-- STORAGE -->
              <li class="on"><span>{{ plan.storage }} GB <span v-if="plan.isStoragePerUser">{{ $t('BY_USER') }}</span>
                  <span v-else
                    v-html="$t('STORAGE_AMOUNT').replace('{var1}', plan.storage_upto.toLocaleString())"></span>
                </span></li>

              <!-- DSS -->
              <li class="on" v-if="lang == 'pt' && plan.documents_upto > 0"
                v-html="$t('DOCUMENTS_UPTO').replace('{var1}', plan.documents_upto)"></li>
              <li class="off" v-if="lang == 'pt' && plan.documents_upto == 0"> <img
                  :src="`/assets/images/plans/false.png`"></li>

              <!-- SLV -->
              <li class="on" v-if="lang == 'pt' && plan.documents_upto > 0"
                v-html="$t('DOCUMENTS_UPTO').replace('{var1}', plan.documents_upto)"></li>
              <li class="off" v-if="lang == 'pt' && plan.documents_upto == 0"> <img
                  :src="`/assets/images/plans/false.png`"></li>

              <li class="on" v-if="['Corporate', 'Enterprise'].includes(plan.tier)"><img
                  :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-else><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="on"><img :src="`/assets/images/plans/true.png`"></li>

              <li class="on" v-if="plan.ad_1"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-else><img :src="`/assets/images/plans/false.png`"></li>

              <li class="on" v-if="plan.ad_2"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-else><img :src="`/assets/images/plans/false.png`"></li>

              <li class="on" v-if="plan.ad_3"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-else><img :src="`/assets/images/plans/false.png`"></li>

              <li class="on"><img :src="`/assets/images/plans/true.png`"></li>

              <li class="on" v-if="plan.ad_4"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_4"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_5"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_5"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_6"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_6"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_7"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_7"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_8"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_8"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_9"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_9"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_10"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_10"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_11"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_11"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_12"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_12"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_13"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_13"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_14"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_14"><img :src="`/assets/images/plans/false.png`"></li>
              <li class="on" v-if="plan.ad_15"><img :src="`/assets/images/plans/true.png`"></li>
              <li class="off" v-if="!plan.ad_15"><img :src="`/assets/images/plans/false.png`"></li>

              <!-- <li class="li-divider"></li>

              <Addons :mod="'GENERAL'" :plan_addons="plan.addons['GENERAL']"></Addons> -->

            </ul>
          </div>
        </div>
      </div>

    </div>

    <ModalCustomer ref="modalCustomer" v-if="!hasPreCustomer() && !isModalClosed" @closeModal="isModalClosed = true"
      :selectedPlan="selectedPlan" :key="lang"></ModalCustomer>

  </div>
</template>

<script>
import PlansMixin from '../../mixins/PlansMixin'
import ModalCustomer from '../../components/plans/modalCustomer'
import Addons from '../../components/plans/Addons'

export default {
  mixins: [PlansMixin],
  components: {
    ModalCustomer,
    // Addons
  },

  props: {
    partner_url: {
      type: String,
      default: ''
    },
    parent: {
      type: String,
      default: 'prices'
    },
    title: {
      type: String,
      default: null
    },
    must_have: {
      type: String,
      default: null
    },

    recurrence: {
      type: String,
      default: 'month'
    }
  },

  async mounted() {
    if (this.hasPreCustomer()) {
      console.log(">> Has pre-customer", this.storage_pre_customer)
    }
  },

  methods: {
    toggleRecurrence() {
      this.$emit('toggleRecurrence')
    }
  }
}
</script>

<style scoped lang="scss">
.list-addons {
  li:last-child {
    margin-bottom: 0 !important;
  }
}

.li-divider {
  padding: 0 !important;
  margin: 10px 0 10px 0 !important;
  background-color: #00c7af !important;
  display: flex;
  align-items: center;

  h1 {
    position: absolute;
    background-color: #00c7af !important;
    color: #fff;
    margin: 0;
    margin-left: 10px;
    font-size: 22px !important;
    width: 78%;
    height: 45px;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {

  .li-divider {
    h1 {
      width: 100%;
    }
  }
}
</style>