<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <form id="formScan2XData" ref="formScan2XData" @submit.stop.prevent="handleSubmit">
        <div>
          <div class="row">
            <div class="col-12">
              <b-form-group :label="$t('FULL_NAME')" :state="state.name" label-for="name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('FULL_NAME'))
                ">
                <b-form-input :state="state.name" id="name" v-model="form.name" required></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="'E-mail'" :state="state.email" label-for="email" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', 'E-mail')
                ">
                <b-form-input id="email" :state="state.email" v-model="form.email" type="email" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">

              <b-form-group :label="$t('PHONE')" :state="state.phone" label-for="phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('PHONE'))
                ">
                <div class="d-flex flex-row">

                  <vue-tel-input :key="lang" id="phone" required :state="state.phone" v-model="form.phone"
                    :allCountries="getCountries()" :preferredCountries="['BR', 'US']" :autoformat="true"
                    :validCharactersOnly="true" :autoDefaultCountry="true" :inputOptions="{
                      placeholder: '',
                      required: true
                    }" @country-changed="phoneCountryChanged"></vue-tel-input>

                </div>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="$t('COMPANYS NAME')" :state="state.company_name" label-for="company_name"
                :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('COMPANYS NAME'))">
                <b-form-input :state="state.company_name" id="company_name" v-model="form.company_name"
                  required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="$t('POSITION')" :state="state.role" label-for="role" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('POSITION'))
                ">
                <b-form-select :state="state.role" id="role" v-model="form.role" :options="rolesList"
                  required></b-form-select>
              </b-form-group>
            </div>
          </div>

          <!-- 

              LOCATION

            -->

          <div class="row">
            <!-- country -->
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group id="input-group-9" :label="$t('country')" label-for="country">
                <v-select :disabled="isLoadingZipcode" v-if="countriesList" id="country" v-model="form.country"
                  :options="countriesList">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!form.country" v-bind="attributes" v-on="events" />
                  </template>
                </v-select>
              </b-form-group>
            </div>

            <!-- zip_code -->
            <div class="col-lg-3 col-md-3 col-sm-12">
              <b-form-group id="input-group-5" :key="`zipcode_${form.country.code}`" :label="$t('zip_code')"
                label-for="zip_code">
                <b-form-input :disabled="isLoadingZipcode" v-if="form.country && form.country.code == 'BRA'"
                  v-mask="'#####-###'" id="zip_code" v-model="form.zip_code" @change="handleZipCode" trim></b-form-input>
                <b-form-input :disabled="isLoadingZipcode" v-else-if="form.country && form.country.code == 'USA'"
                  v-mask="'#####-####'" id="zip_code" v-model="form.zip_code" trim></b-form-input>
                <b-form-input :disabled="isLoadingZipcode" v-else id="zip_code" v-model="form.zip_code"
                  trim></b-form-input>
              </b-form-group>
            </div>

            <!-- address_line1 -->
            <div class="col">
              <b-form-group id="input-group-3" :label="$t('address_line1')" label-for="address_line1">
                <b-form-input id="address_line1" v-model="form.address_line1" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

          </div>

          <div class="row">

            <!-- province -->
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group id="input-group-6" :label="$t('province')" label-for="province">
                <b-form-input id="province" v-model="form.province" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

            <!-- city -->
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group id="input-group-8" :label="$t('city')" label-for="city">
                <b-form-input id="city" v-model="form.city" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

            <!-- county -->
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group id="input-group-7" :label="$t('county')" label-for="county">
                <b-form-input id="county" v-model="form.county" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>
          </div>

        </div>
      </form>
    </b-overlay>

    <div v-if="showCustomModal" class="custom-modal">

      <div class="content">
        <div class="header" v-html="$t('SWC_SUBMIT_CONFIRMATION')"></div>
        <div class="body"
          v-html="$t('WE_WILL_BE_IN_TOUCH_SWC')">
        </div>
        <div class="footer">
          <button class='btn btn-success' @click.stop="showCustomModal = false">{{ $t('CLOSE') }}</button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import countriesJson from '../../../public/assets/json/countries.json'
import $ from "jquery"

export default {
  components: {
  },
  computed: {
    ...mapState("MainStoreModule", {
      user_monetary: (state) => state.user_monetary,
      domain: state => state.domain,
      env: state => state.env
    }),
    lang() {
      return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
    }
  },

  watch: {
    lang(lang) {
      this.formatroles()
    },

    watch: {
      form: {
        deep: true,
        handler(form) {
          this.setCompanyIdentification()
        }
      }
    },
    user_monetary: {
      deep: true,
      handler() {

        setTimeout(() => {
          if (this.brLocaleValidation) {
            this.form.country = { code: "BRA", label: "Brasil" }

          } else if (this.enLocaleValidation) {
            this.form.country = { code: "USA", label: "United States" }
          }
        }, 200)
      }
    }
  },
  data() {
    return {
      isLoadingZipcode: false,
      brLocaleValidation: null,
      enLocaleValidation: null,
      showCustomModal: false,
      countries: countriesJson,
      countriesList: null,
      countriesJson: countriesJson,

      isLoading: false,
      state: {
        name: null,
        email: null,
        phone: null,
        role: null,
        company_name: null,
        zip_code: null,
        address_line1: null,
        county: null,
        city: null,
        province: null,
        country: null,
      },
      form: {
        name: "",
        email: "",
        phone: "",
        role: "",
        company_name: "",
        zip_code: "",
        address_line1: "",
        county: "",
        city: "",
        province: "",
        country: "",
      },
      rolesList: [],
      roles: [
        {
          pt: "Administrativo / Financeiro",
          en: "Administrative / Financial",
          es: "Administrativo / Financiero",
        },
        {
          pt: "Recursos humanos",
          en: "Human resources",
          es: "Recursos humanos",
        },
        {
          pt: "TI / Desenvolvimento de sistemas",
          en: "IT / Software development",
          es: "TI / Desarrollo de sistemas",
        },
        {
          pt: "Marketing",
          en: "Marketing",
          es: "Marketing",
        },
        {
          pt: "Operações",
          en: "Operations",
          es: "Operaciones",
        },
        {
          pt: "Proprietário / CEO",
          en: "Owner / CEO",
          es: "Dueño / CEO",
        },
        {
          pt: "Controle de vendas",
          en: "Sales control",
          es: "Control de ventas",
        },
        {
          pt: "Controle de qualidade",
          en: "Quality control",
          es: "Control de calidad",
        },
        {
          pt: "Vendas",
          en: "Sales",
          es: "Ventas",
        },
        {
          pt: "Outro",
          en: "Other",
          es: "Otro",
        },
      ],

    }
  },

  methods: {
    ...mapActions("MainStoreModule", [
      "sendEmailConfirmation",
      "savePreCustomer",
      "getPreCustomerByEmail",
      "editPreCustomer",
      "getCepData"
    ]),

    phoneCountryChanged(e) {
      //
    },

    async handleZipCode() {

      const zipcode = this.form.zip_code.replace(/_/g, '').replace(/ /g, '')

      if (zipcode.length < 9) {
        return
      }

      this.isLoadingZipcode = true

      await this.getCepData(zipcode).then(response => {

        const data = response

        if (data.erro) {

          this.form.county = null
          this.form.city = null
          this.form.address_line1 = null
          this.form.province = null

        } else {

          this.form.county = data.bairro
          this.form.city = data.localidade
          this.form.address_line1 = data.logradouro
          this.form.province = data.estado
          this.form.country = { code: "BRA", label: "Brasil" }

        }

      }).finally(() => {
        this.isLoadingZipcode = false
      })

    },

    isValidZipCode(str) {

      str = str.replace(/_/g, '').replace(/ /g, '')

      if (
        str == '' ||
        str == null ||
        (str.length < 9 && this.country.code == 'Brazil') ||
        (str.length < 10 && this.country.code == 'United States')
      ) {
        return false
      }

      return true

    },

    setCompanyIdentification() {

      var identification = {},
        country_code = this.form.country ? this.form.country.code : null

      if (country_code == 'MOZ') {

        identification = {
          title: "NUIT",
          id_mask: "#########",
          zipcode_mask: "####"
        }

      } else if (country_code == 'BRA') {

        identification = {
          title: "CNPJ",
          id_mask: "##.###.###/####-##",
          zipcode_mask: "#####-###"
        }

      } else if (country_code == 'USA') {

        identification = {
          title: "EIN",
          id_mask: "##-#######",
          zipcode_mask: "#####"
        }

      } else {

        identification = {
          title: this.$t('company_identification'),
          id_mask: "##########################",
          zipcode_mask: "##########"
        }
      }

      return this.company_identification = identification
    },

    setCountriesList() {

      var countriesList = []

      for (var i in this.countriesJson) {
        var country = this.countriesJson[i]

        var name = null,
          code = country['cca3']

        if (this.lang == 'en') {
          name = country['name']['common']
        } else {
          name = country['translations'][this.lang]['common']
        }

        countriesList.push({
          code: code,
          label: this.$t(`${name}`)
        })

      }

      this.countriesList = countriesList
    },

    reset() {
      this.form = {
        name: "",
        email: "",
        phone: "",
        role: "",
        company_name: "",
        zip_code: "",
        address_line1: "",
        county: "",
        city: "",
        province: "",
        country: "",
      },
        this.state = {
          name: null,
          email: null,
          phone: null,
          role: null,
          company_name: null,
          zip_code: null,
          address_line1: null,
          county: null,
          city: null,
          province: null,
          country: null,
        }
    },

    getCountries() {

      var countries = []

      for (var i in this.countries) {
        var country = this.countries[i]
        var name = null

        if (this.lang == 'en') {
          name = country['name']['common']
        } else {
          name = country['translations'][this.lang]['common']
        }

        countries.push({
          name: name,
          iso2: country.cca2,
          dialCode: country.callingCode[0],
          priority: 0,
          areaCodes: null
        })
      }

      return countries
    },

    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
    },
    formatroles() {
      this.rolesList = []
      for (var i in this.roles) {
        var name = this.roles[i][this.lang]

        this.rolesList.push({
          value: name,
          text: this.$t(`${name}`),
        })
      }
    },

    checkFormValidity() {
      var t = this
      var v = true

      $("#formScan2XData").find("input, select, .vti__input").each(function () {
        const valid = this.checkValidity()
        const id = $(this).attr("id")
        const className = $(this).attr("class")

        for (var field in t.state) {
          if (field == id || (field == 'phone' && className == 'vti__input')) {
            t.state[field] = valid
          }
        }

        if (!valid) {
          v = valid
        }
      })

      return v
    },

    handleOk(e) {
      e.preventDefault()
      this.handleSubmit()
      let el = document.querySelector(':focus')
      if (el) el.blur()
    },

    async handleSubmit() {

      if (!this.checkFormValidity()) {
        this.$emit('error', false)
        return
      }

      setTimeout(async () => {

        this.isLoading = true

        this.form.country = this.form.country.code

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=d8e39554c6492ada92d834c157102018; XSRF-TOKEN=0nTP-QdaeGji9OTRZJFdWBzGin86m8IajQctRYbmdKE; cmp478339627=1477155c915d5748d11788af7b4026dd");

        var formdata = new FormData();
        formdata.append("jsonp", "true");
        formdata.append("u", "12");
        formdata.append("f", "12");
        formdata.append("s", "");
        formdata.append("c", "0");
        formdata.append("m", "0");
        formdata.append("act", "sub");
        formdata.append("v", "2");
        formdata.append("or", "d0d08ca6b08632602340cefe85c12fff");
        formdata.append("fullname", this.form.name);
        formdata.append("email", this.form.email);
        formdata.append("phone", this.form.phone);
        formdata.append("field[5]", this.form.company_name);
        formdata.append("field[9]", this.form.role);
        formdata.append("field[10]", this.form.country);
        formdata.append("field[11]", this.form.zip_code);
        formdata.append("field[12]", this.form.address_line1);
        formdata.append("field[6]", this.form.province);
        formdata.append("field[7]", this.form.city);
        formdata.append("field[13]", this.form.county);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://interfy.activehosted.com/proc.php", requestOptions)
          .then(response => response.text( 
            this.$emit('closeModal'),
            this.showCustomModal = true,
            this.reset(),
            this.isLoading = false
          ))
          .then(result => console.log(result))
          .catch((error) => {
                console.log(">> Error: ", error)

                this.$root.$emit("showAlert", {
                    bgVariant: "danger",
                    textVariant: "light",
                    buttons: "ok-only",
                    title: this.$t("ERROR"),
                    message: "<br><br><small><b>Error message: </b>" +
                    error.responseJSON.message +
                    "</small>",
                })
            });

          this.$emit('onSave')

      }, 500)
    },
  },

  mounted() {
    this.formatroles()
    this.setCountriesList()


    this.$root.$on('on-hit-ok-modal-scan2x', async () => {
      this.handleSubmit()
    })

    this.$root.$on('on-hit-ok-start-scan2x', async () => {
      this.handleSubmit()
    })
  },



  destroyed() {
    this.$root.$off(['on-hit-ok-modal-scan2x', 'on-hit-ok-start-scan2x'])
  },
}
</script>

<style scoped lang="scss">
.custom-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 500px;
    min-height: 300px;
    border-radius: 10px;
    padding: 30px;
    background-color: #fff;

    .header {
      font-size: 20px;
      color: #1d70b7;
      width: 100%;
      text-align: center;
      border-bottom: 3px solid #eef3fc;
      padding-bottom: 30px;
    }

    .body {
      padding: 30px 0;
      width: 100%;
      text-align: center;
    }

    .footer {
      border-top: 3px solid #eef3fc;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 30px;

      .btn {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
    }
  }

}

@media only screen and (max-width: 991px) {
  #formScan2XData {
    .row {
      margin: 0 !important;
    }

    .div-recaptcha {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0 !important;
    }
  }
}
</style>