<template>
  <div class="ct-summary" v-if="shopping_cart.selected_plan && shopping_cart.selected_plan.plan">

    <b-overlay :show="isSubmitting">
    
      <div class="d-flex flex-column order-summary mt-4">

        <!-- 

          Shopping cart - Plan

        -->

        <div class="d-flex flex-row justify-content-between align-items-start selected-plan">

          <div class="d-flex flex-column align-items center justify-content-center">

            <span class="plan-type">
              <b>{{$t('PLAN')}}: </b>
              {{shopping_cart.selected_plan.plan.type}}
            </span> 

            <span>
              <b>{{shopping_cart.selected_plan.unit_amount}}</b>
              {{formatSellingMode(shopping_cart.selected_plan.unit_amount, shopping_cart.selected_plan.plan.sellingMode)}}
            </span>

          </div>
      
          <span class="plan-price">
            <b>{{formatPrice(shopping_cart.selected_plan.plan_price)}}</b>
          </span>

        </div>


        <!-- 

          Shopping cart - Addons

        -->
        <div class="optional-addons mt-3">

          <ul class='list p-0 m-0' v-if="shopping_cart.selected_addons">

            <template v-for="(addon, index) in shopping_cart.selected_addons">

              <li :key="index"> 

                <div class="row">
                  
                  <!--                       
                    Addon name
                  -->
                  <div class="col-8 d-flex flex-column justify-content-center addon-name"> {{addon.name[lang]}} </div>


                  <!--                       
                    Addon Price
                  -->
                  <div class="col-4 d-flex flex-column justify-content-center addon-price">

                      <!-- 
                        No tiers
                      -->                    
                      <template v-if="!addon.tiered">
                        <b class="mr-3">{{ `${user_monetary.symbol} ${addon.price[0]}${user_monetary.separators.cents}${addon.price[1]}`}}</b>
                        <small>
                          {{$t('MONTHLY_VALUE')}} 
                          <!-- {{$t('BY')}} {{$t( (addon.unit == 'users') ? 'USER' : ( (addon.unit == 'pages') ? 'PAGE' : ( (addon.unit) ? 'CNPJ' : addon.unit ) ) )}} -->
                        </small> 
                      </template>

                      <!-- 
                        With tiers
                      -->
                      <template v-else>                        
                        <!-- tier price -->
                        <b class="mr-3">{{ `${user_monetary.symbol}  ${addon.selectedTier.total}` }}</b>
                        <small>
                          <!-- limited max units -->
                          <template v-if="addon.selectedTier.max && !addon.selectedTier.last_tier_with_price">
                            {{$t('UP_TO')}} {{ addon.selectedTier.max }} {{$t( (addon.unit == 'users') ? 'USERS' : ( (addon.unit == 'pages') ? 'PAGES' : ( (addon.unit) ? 'CNPJ' : addon.unit ) ) )}}
                          </template>

                          <!-- unlimited max units (when the real last price is 0, show second to last as unlimited) -->                                
                          <template v-else-if="addon.selectedTier.max && addon.selectedTier.last_tier_with_price">                                    
                              {{$t('UNLIMITED')}}
                          </template>

                          <!-- unlimited max units (when the real last price is not 0) -->
                          <template v-else>
                            {{ addon.selectedTier.min }} {{$t( (addon.unit == 'users') ? 'USERS' : ( (addon.unit == 'pages') ? 'PAGES' : ( (addon.unit) ? 'CNPJ' : addon.unit ) ) )}}
                            {{$t('OR_MORE')}}
                          </template>

                        </small>
                      </template>
                                            
                  </div>
                  
                </div>
              </li> 
            </template>
          </ul>

          <div class="row my-2">
            <div class="p-0 col sub-total d-flex align-items-center justify-content-end">            
              <b class="mr-2">{{$t('ADDONS')}}:</b>{{formatPrice(addons_total)}}
            </div>
          </div>
            
        </div>

        <!-- 
          Total
        -->
        <div class="row">
          <div class="p-0 col order-total d-flex align-items-center justify-content-end">          
            <b class="mr-2">{{$t('ORDER_TOTAL')}}:</b>{{formatPrice(order_total)}}
          </div>
        </div>

      </div>

      <!-- 
          Google Recaptcha + Submit
      -->    
      <div class="row">
        <div class="p-0 col div-recaptcha mt-3 text-right">

            <vue-recaptcha
              ref="recaptcha"
              @verify="recaptchaOnVerify"
              @expired="recaptchaOnExpired"
              :sitekey="domain.googlerecaptcha_key"
              :loadRecaptchaScript="true"
            >
            </vue-recaptcha>

            <button :disabled="isSubmitting" class="mt-3 btn btn-blue btn-medium-width" @click.stop="beforeSubmit">{{$t('SUBMIT_ORDER')}}</button>
        </div>
      </div>

    </b-overlay>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RecaptchaMixin from '../../../mixins/RecaptchaMixin'
import CheckoutMixin from '../../../mixins/CheckoutMixin'
import $ from 'jquery'
export default {
  mixins: [RecaptchaMixin, CheckoutMixin],      
  components:{    
  },

  watch:{
    summary:{
      deep: true,
      handler(summary){
        console.log(">> Summary", summary)
      }
    },    
  },

  computed:{
    
    order_total(){
      return this.addons_total + this.shopping_cart.selected_plan.plan_price
    },

    addons_total(){
      var total = 0

      if(this.shopping_cart.selected_addons){

        this.shopping_cart.selected_addons.map((addon) => {

          let addon_price = 0

          if(!addon.tiered){
            addon_price = parseFloat(`${addon.price[0]}.${addon.price[1]}`)
          }else{
            // addon_price = parseFloat(`${addon.selectedTier.price[0]}.${addon.selectedTier.price[1]}`)
            addon_price = parseFloat(addon.selectedTier.total)
          }

          
          total += addon_price

        })
      }

      return total
    }
  },

  methods:{
    beforeSubmit(){
      this.handleSubmit()
    }
  }

}
</script>

<style lang="scss" scoped>

.ct-summary{    
    width: 100%;
    .row{
        margin: 0;
    }
}

.selected-plan{
  color: #4D4D4D;
  font-size: 25px;
  font-weight: 600;

  b{
    font-weight: 800;
  }

  .plan-type{
    margin-bottom: -5px;
  }

  .plan-price{
    font-size: 40px;
  }
}

.order-total{
  
  color: #4D4D4D;
  font-size: 25px;
  font-weight: 600;
  b{
    font-weight: 800;
  }
  
}

.optional-addons{

  .list{
    max-height: 25vh;
    overflow: auto;
  }

  small{
    
    color: #4D4D4D;
    font-size: 20px;
    font-weight: 600;

    b{
      font-weight: 800;
    }
    
  }

  display: flex;
  flex-direction: column;  
  align-items: center;  
  justify-content: space-between;

  h3{
    font-size: 20px;
  }

  ul{
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    li{             
      background-color: #EEEEEE;
      border-radius: 5px;
      width: 100%;      
      margin: 4px 0;
      color: #4D4D4D;
      font-size: 14px;    
      padding: 0;

      .addon-price{
        small{
          font-size: 10px;          
          margin-top: -5px;
        }
      }
      
      
    }

    &:first-child{
      li{
        &:last-child{
          margin: 0;          
        }
      }
    }
  }
  
}

</style>