import { mapState, mapActions } from 'vuex'
import $ from 'jquery'

export default {
    props: {},

    data() {
        return {
            isModalClosed: true,
            acceptCurrencies: ['usd', 'brl'],
            maxDiscount: 0,
            plansPaymentMethod: 'credit-card',
            group: 1,
            renderedPlans: [],
            renderedAddons: [],
            modules: ['ECM', 'BPM'],
            show: false,
            selectedPlan: null,
            storage_pre_customer: null,
        }
    },

    watch: {
        lang() {
            this.adjustListItemsHeight()
        },

        plans: {
            deep: true,
            handler(data) {
                // console.log(">> Start rendering plans and addons")
                // console.log(                    
                //     this.user_monetary.currency_code, 
                //     this.plansPaymentMethod
                // )

                this.renderAddons({
                    'currency': (this.acceptCurrencies.includes(this.currency_by_url)) ? this.currency_by_url : this.user_monetary.currency_code,
                    'method': this.plansPaymentMethod
                })

                this.renderPlans({
                    'currency': (this.acceptCurrencies.includes(this.currency_by_url)) ? this.currency_by_url : this.user_monetary.currency_code,
                    'method': this.plansPaymentMethod
                })
            },
        },

        plansPaymentMethod(payload) {
            this.renderPlans({
                'currency': (this.acceptCurrencies.includes(this.currency_by_url)) ? this.currency_by_url : this.user_monetary.currency_code,
                'method': payload
            })
        }
    },

    computed: {
        ...mapState('MainStoreModule', {
            user_monetary: state => state.user_monetary,
            env: state => state.env,
            api: state => state.api,
            frontend: state => state.frontend,
            google: state => state.google,
            plans: state => state.plans,
            addons: state => state.addons,
            partner: state => state.partner,
            domain: state => state.domain
        }),
        lang() {
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },

        currency_by_url() {
            return Object.keys(this.$route.params).length && this.$route.params.currency_by_url ? this.$route.params.currency_by_url.toLowerCase() : null
        }

    },

    methods: {
        ...mapActions('MainStoreModule', [
            'setApiToken',
            'getSellablePlans',
            'getAddons',
            'setUserMonetary',
            'editPreCustomer',
            'setUserMonetaryByLocation'
        ]),

        getNonModules(type) {
            return ""
            // switch (type) {
            //     case "Starter":
            //         return this.lang == 'pt' ? " + DSS + SLV" : ""

            //     case "Business":
            //         return this.lang == 'pt' ? " + DSS + SLV" : ""

            //     case "Corporate":
            //         return ""

            //     case "Enterprise":
            //         return this.lang == 'pt' ? " + DSS + SLV" : ""

            // }

        },

        limitChars(str, limit = 20) {

            if (!str) {
                return
            }

            var newStr = str

            if (str.length > limit) {
                newStr = $.trim(str.substring(0, limit)) + "..."
            }

            return newStr

        },

        openmodalCustomerData() {
            this.isModalClosed = false
            setTimeout(() => {
                this.$refs.modalCustomer.$refs["modalCustomerData"].show()
            }, 500)

            setTimeout(() => {
                $(".modal-dialog").addClass("modal-plans")
            }, 200)
        },

        preVerifySelectedPlan(plan, isTrial) {
            if (this.currency_by_url) {
                this.$root.$emit('showAlert', {
                    bgVariant: "danger",
                    textVariant: "light",
                    buttons: "ok-only",
                    title: this.$t('FORBIDDEN_ACCESS'),
                    message: this.$t('FORBIDDEN_ACCESS_MESSAGE')
                })
                return false
            }

            this.selectedPlan = {
                "plan_id": plan.id,
                "method": this.plansPaymentMethod,
                "isTrial": this.checkIsTrial(isTrial)
            }

            return true

        },

        preVerifySwcForm(isTrial) {

            if (this.currency_by_url) {
                this.$root.$emit('showAlert', {
                    bgVariant: "danger",
                    textVariant: "light",
                    buttons: "ok-only",
                    title: this.$t('FORBIDDEN_ACCESS'),
                    message: this.$t('FORBIDDEN_ACCESS_MESSAGE')
                })
                return false
            }

            this.selectedPlan = {
                "isTrial": this.checkIsTrial(isTrial)
            }

            return true

        },

        checkIsTrial(isTrial) {
            if (isTrial === true || isTrial === 'true' || isTrial === 1) {
                return true
            }

            if (isTrial === false || isTrial === 'false' || isTrial === 0) {
                return false
            }
        },


        /*
    
          overwrite_customer_data can be FALSE when it's been redirected
          from the cart icon

          It's TRUE when a new plan is selected on Plans page

        */
        async redirectToCheckout(overwrite_customer_data = true) {

            if (overwrite_customer_data) {

                var data = {
                    ...this.storage_pre_customer,
                    ...this.selectedPlan
                }

                console.log(">> Updating Pre-customer data with new selected plan...")
                await this.editPreCustomer({ 'id': this.storage_pre_customer.id, 'data': data }).then((response) => {
                    localStorage.setItem(this.env, JSON.stringify({ 'pre_customer': response }))
                })

            }


            /*
      
              Redirect to checkout data

            */
            let linkParams = {
                customer: {
                    id: this.storage_pre_customer.id,
                    email: this.storage_pre_customer.email,
                    lang: (this.$i18n.locale == "pt_br") ? 'pt' : this.$i18n.locale
                }
            }

            if (this.storage_pre_customer.partner_id) {
                linkParams.partner = {
                    id: this.storage_pre_customer.partner.id,
                    slug: this.storage_pre_customer.partner.slug,
                }
            }

            const encryptedData = btoa(JSON.stringify(linkParams))
            this.$router.push({ name: "checkout", params: { data: encryptedData } })

        },

        switchCheckoutPlan(e, plan, isTrial) {

            if (this.preVerifySelectedPlan(plan, isTrial)) {
                this.redirectToCheckout(this.selectedPlan)
            }

        },

        addPlanToCart(e, plan, isTrial) {
            e.preventDefault()

            if (this.preVerifySelectedPlan(plan, isTrial)) {
                this.openmodalCustomerData()
            }

        },

        hasPreCustomer() {

            const env_storage = localStorage.getItem(this.env)

            if (env_storage) {
                this.storage_pre_customer = JSON.parse(env_storage).pre_customer
                return true
            } else {
                this.storage_pre_customer = null
                return false
            }

        },

        adjustListItemsHeight() {

            $(".fixed-column .list-addons").ready(function () {

                $(".plans-columns").ready(function () {

                    $(".fixed-column").each(function () {

                        var heights = []

                        $(this).find(".list-addons li").each(function (i) {
                            heights.push($(this).height())
                        })

                        $(this).parent().find('.plan-column').each(function (x) {

                            $(this).find(".list-addons li").each(function (i) {

                                $(this).height(heights[i])

                            })

                        })
                    })

                })

            })

        },

        getTierTotalPrice(tier_index, tiers) {

            let totalPrice = 0

            for (var i in tiers) {

                if (i <= tier_index) {

                    var units = this.getTierUnits(tiers, i)
                    totalPrice += this.getUnitsPrice(tiers[i].price, units)

                } else {
                    break
                }

            }

            totalPrice = totalPrice.toFixed(2)
            totalPrice = String(totalPrice).split('.')

            return totalPrice[0] + this.user_monetary.separators.cents + totalPrice[1]

        },

        getTierUnits(tiers, i) {

            var units = 1

            if (tiers[i].max) {
                units = (i == 0) ? parseInt(tiers[i].max) : parseInt(tiers[i].max) - parseInt(tiers[i - 1].max)
            }

            return units

        },

        getUnitsPrice(price, units) {
            return parseFloat(parseFloat(price.join(".")) * units)
        },

        setPrices(isTiered, data, params, plan = null) {
            // console.log(">> setPrices data", data, "params", params, "isTiered", isTiered)

            if (data === undefined) {
                return
            }

            var initialPrice = ['0', '00']
            var finalPrice = ['0', '00']
            var price = null

            if (isTiered) {
                for (var index in data.prices) {
                    price = data.prices[index]

                    if (price.currency.toLowerCase() == params.currency.toLowerCase() &&
                        (
                            (price.method && (price.method.toLowerCase() == params.method.toLowerCase())) ||
                            !price.method
                        )
                    ) {
                        var min_units = 1,
                            value = price.value

                        // if (plan != null) {
                        //     min_units = parseInt(plan.tiers[0].min_units)
                        //     value *= min_units
                        // }

                        value = String(parseFloat(value).toFixed(2))

                        /* ORIGINAL PRICE */
                        if (!price.has_discount) {
                            initialPrice = value.split('.')
                            finalPrice = initialPrice

                            /* FINAL PRICE (with discount) */
                        } else if (price.has_discount) {
                            finalPrice = value.split('.')
                        }
                    }
                }
            } else {
                price = data[params.currency.toLowerCase()]

                price.value = String(parseFloat(price.value).toFixed(2))

                /* ORIGINAL PRICE */
                if (!price.has_discount) {
                    initialPrice = price.value.split('.')
                    finalPrice = initialPrice

                    /* FINAL PRICE (with discount) */
                } else if (price.has_discount) {
                    finalPrice = price.value.split('.')
                }
            }

            return {
                "initialPrice": initialPrice,
                "finalPrice": finalPrice,
            }
        },

        /*

          Plans is not null when it comes from the checkout page
          There we need it to be rendered

          If plans is null, it means the plans are been rendered on the plans page

        */
        renderPlans(params, plans = null) {
            this.$emit('isPageLoaded', false)

            this.renderedPlans = [];

            if (!plans) {
                plans = this.plans
            }

            for (var i in plans) {
                const plan = plans[i]
                var hasModule = false

                const modules = plan.modules

                /*
                    Used by the addons page, such as SLV and DAS
                */
                if (!hasModule && this.must_have != null) {
                    continue
                }

                if ('discount' in plan && this.maxDiscount < plan.discount[params.currency.toLowerCase()]) {
                    this.maxDiscount = plan.discount[params.currency.toLowerCase()]
                }

                var renderedPlan = {
                    'isPopular': (plan.tier === 'enterprise') ? true : false,
                    'sellingMode': (plan.sellingMode) ? plan.sellingMode : 'BY-USER', //by-user, by-store...
                    'id': plan.id,
                    'order': plan.order,
                    'tier': plan.tier.charAt(0).toUpperCase() + plan.tier.slice(1),
                    'modules': modules.join(" + "),
                    // 'isStoragePerUser': plan.storage_per_user ? true : false,
                    'storage': plan.base_storage_gb,
                    'addons': { "GENERAL": [], "ECM": [], "BPM": [], "ERP": [], "CRM": [] },
                    'min_units': plan.users_minimum,
                    prices: plan.prices
                }

                renderedPlan.storage_upto = renderedPlan.storage * 15000

                switch (renderedPlan.tier) {
                    case "Starter":
                        renderedPlan.min_view_users = 3
                        // renderedPlan.min_units = 3
                        renderedPlan.view_users_per_full_user = 1
                        // renderedPlan.storage = 2
                        // renderedPlan.isStoragePerUser = true
                        // renderedPlan.storage_upto = "60.0000"
                        renderedPlan.documents_upto = 10
                        renderedPlan.ad_1 = true
                        renderedPlan.ad_2 = true
                        renderedPlan.ad_3 = false
                        renderedPlan.ad_4 = true
                        renderedPlan.ad_5 = true
                        renderedPlan.ad_6 = true
                        renderedPlan.ad_7 = true
                        renderedPlan.ad_8 = true
                        renderedPlan.ad_9 = true
                        renderedPlan.ad_10 = true
                        renderedPlan.ad_11 = true
                        renderedPlan.ad_12 = true
                        renderedPlan.ad_13 = true
                        renderedPlan.ad_14 = true
                        renderedPlan.ad_15 = true
                        break

                    case "Business":
                        renderedPlan.min_view_users = 3
                        // renderedPlan.min_units = 5
                        renderedPlan.view_users_per_full_user = 1
                        // renderedPlan.isStoragePerUser = true
                        // renderedPlan.storage = 2
                        // renderedPlan.storage_upto = "60.0000"
                        renderedPlan.documents_upto = 10
                        renderedPlan.ad_1 = true
                        renderedPlan.ad_2 = true
                        renderedPlan.ad_3 = true
                        renderedPlan.ad_4 = true
                        renderedPlan.ad_5 = true
                        renderedPlan.ad_6 = true
                        renderedPlan.ad_7 = true
                        renderedPlan.ad_8 = true
                        renderedPlan.ad_9 = true
                        renderedPlan.ad_10 = true
                        renderedPlan.ad_11 = true
                        renderedPlan.ad_12 = true
                        renderedPlan.ad_13 = true
                        renderedPlan.ad_14 = true
                        renderedPlan.ad_15 = true
                        break

                    case "Corporate":
                        renderedPlan.min_view_users = 0
                        // renderedPlan.min_units = 5
                        // renderedPlan.isStoragePerUser = true
                        // renderedPlan.storage = 1
                        // renderedPlan.storage_upto = "60.0000"
                        renderedPlan.documents_upto = 0
                        renderedPlan.ad_1 = true
                        renderedPlan.ad_2 = true
                        renderedPlan.ad_3 = false
                        renderedPlan.ad_4 = true
                        renderedPlan.ad_5 = true
                        renderedPlan.ad_6 = true
                        renderedPlan.ad_7 = true
                        renderedPlan.ad_8 = true
                        renderedPlan.ad_9 = true
                        renderedPlan.ad_10 = true
                        renderedPlan.ad_11 = true
                        renderedPlan.ad_12 = true
                        renderedPlan.ad_13 = true
                        renderedPlan.ad_14 = true
                        renderedPlan.ad_15 = true
                        break

                    case "Enterprise":
                        renderedPlan.min_view_users = 5
                        // renderedPlan.min_units = 5
                        renderedPlan.view_users_per_full_user = 2
                        // renderedPlan.isStoragePerUser = true
                        // renderedPlan.storage = 3
                        // renderedPlan.storage_upto = "135.0000"
                        renderedPlan.documents_upto = 10
                        renderedPlan.ad_1 = true
                        renderedPlan.ad_2 = true
                        renderedPlan.ad_3 = true
                        renderedPlan.ad_4 = true
                        renderedPlan.ad_5 = true
                        renderedPlan.ad_6 = true
                        renderedPlan.ad_7 = true
                        renderedPlan.ad_8 = true
                        renderedPlan.ad_9 = true
                        renderedPlan.ad_10 = true
                        renderedPlan.ad_11 = true
                        renderedPlan.ad_12 = true
                        renderedPlan.ad_13 = true
                        renderedPlan.ad_14 = true
                        renderedPlan.ad_15 = true
                        break

                }

                for (var mod in this.renderedAddons) {

                    var addons_by_module = this.renderedAddons[mod]

                    addons_by_module.map((item, index) => {
                        var addon = {
                            "id": item._id,
                            "hasAddon": false,
                            "is_included": false,
                            "isOptional": item.isOptional,
                            "tiered": item.tiered,
                            "unit": item.unit,
                            "min_units": item.min_units,
                            "max_units": item.max_units,
                            "modules": item.modules,
                            "price": item.price,
                            "tiers": item.tiers,
                        }

                        for (var i in plan.addons) {
                            if (plan.addons[i].addon_id == addon.id) {

                                const is_included = ['true', true, '1', 1].includes(plan.addons[i].is_included)
                                addon.hasAddon = true
                                addon.is_included = is_included
                                break;
                            }
                        }

                        renderedPlan.addons[mod].push(addon)
                    })
                }

                this.renderedPlans.push(renderedPlan)
            }

            this.$emit('isPageLoaded', true)

            this.adjustListItemsHeight()
        },

        renderAddons(params) {
            this.$emit('isPageLoaded', false)

            this.renderedAddons = [];

            for (var i in this.addons) {
                const addon = this.addons[i].addon ? this.addons[i].addon : this.addons[i],
                    addon_id = addon.id ? addon.id : addon._id

                if (!addon.visible) {
                    continue
                }

                var renderedAddon = {
                    '_id': addon.id,
                    'version': addon.version,
                    'isOptional': addon.isOptional,
                    'modules': (addon.modules) ? addon.modules.map((module, index) => { return module.title ? module.title : module }) : [],
                    'name': addon.name,
                    'description': addon.description,
                    'visible': addon.visible,
                    'tiered': addon.tiered,
                    'key': addon.key,
                    'unit': addon.unit,
                    'min_units': addon.tiered ? addon.tiers[0].min : 1,
                    'max_units': addon.tiered ? (addon.tiers[0].max ? addon.tiers[0].max : addon.tiers[0].min) : 1
                }

                var formattedPrices = null

                if (addon.tiers) {
                    var firstTier = addon.tiers[0]
                    formattedPrices = this.setPrices(true, firstTier, params)

                    renderedAddon.tiers = []

                    for (var j in addon.tiers) {

                        var tier = addon.tiers[j],
                            tier_price = this.setPrices(true, tier, params),
                            initial_price = parseFloat(tier_price.initialPrice.join('.'))

                        /*
                            If the tier is unlimited and it's price is 0
                            we ignore this tier and show the previous one as UNLIMITED
                            because it has the last price to be showed
                        */

                        if (!tier.max && initial_price == 0) {

                            const last_index = renderedAddon.tiers.length - 1
                            renderedAddon.tiers[last_index].last_tier_with_price = true

                            continue
                        }

                        renderedAddon.tiers.push({
                            '_id': tier._id,
                            'min': tier.min,
                            'max': tier.max,
                            'price': tier_price.initialPrice
                        })

                    }

                    /* 
                        Set the tier price base on previous tiers
                    */
                    for (var tier_index in renderedAddon.tiers) {
                        const total = this.getTierTotalPrice(tier_index, renderedAddon.tiers)
                        renderedAddon.tiers[tier_index].total = total
                    }

                } else {
                    formattedPrices = this.setPrices(false, addon.prices, params)
                }

                if ([5, 1, 2].includes(parseInt(renderedAddon.key))) {

                    console.log(">> Ignoring tier for", renderedAddon.name.en, renderedAddon.tiers[3])
                    renderedAddon.tiers.splice(3, 1)

                }

                if (formattedPrices !== undefined) {
                    renderedAddon.price = formattedPrices.initialPrice
                    this.renderedAddons.push(renderedAddon);
                }

            }

            var addons_by_modules = { "GENERAL": [], "ECM": [], "BPM": [], "ERP": [], "CRM": [] }

            for (var x in this.renderedAddons) {

                var rAddon = this.renderedAddons[x]

                if (rAddon.modules.length == 0) {

                    addons_by_modules['GENERAL'].push(rAddon)

                } else {

                    for (var y in rAddon.modules) {

                        var mod = rAddon.modules[y]
                        addons_by_modules[mod].push(rAddon)

                    }

                }

            }

            this.renderedAddons = addons_by_modules

            // console.log(">> Done rendering the addons!", "rendered:", this.renderedAddons)
            this.$emit('isPageLoaded', true)

        },

        togglePaymentMethod() {
            const el = document.getElementsByClassName('toggle')[0];
            const el_i = el.getElementsByTagName('i')[0];

            if (el_i.classList.contains("goRight")) {
                el_i.classList.remove("goRight")
                this.plansPaymentMethod = "credit-card"
            } else {
                el_i.classList.add("goRight")
                this.plansPaymentMethod = "manual"
            }

        },

        getAddonUnit(addon, mode) {

            if (addon.unit == 'users') {
                return mode == 'singular' ? 'USER' : 'USERS'
            } else if (addon.unit == 'pages') {
                return mode == 'singular' ? 'PAGE' : 'PAGES'
            } else if (addon.unit == 'cnpj') {
                return mode == 'singular' ? 'CNPJ' : 'CNPJs'
            } else if (addon.unit == 'documents') {
                return mode == 'singular' ? 'DOCUMENT' : 'DOCUMENTS'
            } else if (addon.unit == 'plan') {
                return 'PLAN'
            } else {
                return addon.unit
            }
        }
    }

}