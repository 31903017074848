<template>
  <div style="min-height: 100vh;">        
    
    <div v-if="Object.keys(post).length > 0 && show" class="base-padding pt-5 d-flex flex-column">

      <SearchBarPosts 
          :partner_url="partner_url"          
          @search="searchPosts"
      ></SearchBarPosts>

      <h1 class="mt-5 post-title" v-html="post.title[lang]"></h1>

      <div class="text-wrapper mt-3 d-flex flex-row justify-content-center align-items-center">
          <span class="author mr-2">{{$t("BY")}} <span class='name'>{{ post.author.name }}</span> </span>
          |
          <span class="date mx-2"> {{ dateRelative(post.created_at.date) }} </span>
          |
          <span class="category ml-2"> {{ post.category.title[lang] }} </span>
      </div>

      <div class="image-wrapper mt-4">
          <img :src="`${post.image}`" :alt="`${post.title[lang]}`">
      </div>

      <div class="my-5" v-html="post[`content_${lang}`]"></div>

      <!-- <RelatedPosts 
        v-if="posts_related.length > 0"
        class="mt-5"
        :partner_url="partner_url"   
        :posts="posts_related"     
      ></RelatedPosts> -->

    </div>

    <div v-if="post && post.tags" class="tags-wrapper mt-3 d-flex flex-wrap justify-content-center align-items-center">
      <span @click.stop="searchPosts({mode: 'tag', item: item})" class="item" v-for="(item, index) in post.tags" :key="index"> {{item}} </span>
    </div>

    <ThePlatform :window="window" style="background-color: #F3F6FF;" v-if="show"></ThePlatform>
    <Clients></Clients>

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ThePlatform from '../common/ThePlatform'
import Clients from '../common/Clients'
import SearchBarPosts from '../blog/SearchBarPosts'
import RelatedPosts from '../blog/RelatedPosts'
import BlogMixin from '../../mixins/BlogMixin'

export default {
    mixins: [BlogMixin],    
    components:{
      ThePlatform,
      Clients,      
      SearchBarPosts,
      // RelatedPosts 
    },
    props: {    
      partner_url: {
          type: String,
          default: ''
      },  
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      }
    },

    computed: {
      ...mapState("MainStoreModule", { 
          post: state => state.post,
          posts_list: state => state.posts
      }),
      
      lang(){
          return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
    },

    data(){
      return {
        show: false
      }
    },
    
    beforeCreate(){         
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){    
      
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    },

    methods: {
    ...mapActions('MainStoreModule', [             
      ]),
    },
}
</script>

<style lang="scss" scoped>

.post-title{
    width: 100%;
    text-align: center;
    color: rgba(0,0,0, .8);
    font-weight: 700 !important;
}

.image-wrapper{ 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    img{
      position: relative;                    
      width: 700px;
      height: auto;
      
      border-radius: 10px;        
      -moz-box-shadow: 0 20px 11px -12px #C4C9D9;
      -webkit-box-shadow: 0 20px 11px -12px #C4C9D9;
      box-shadow: 0 20px 11px -12px #C4C9D9;
    }

}

.tags-wrapper{
  width: 100%;
  padding: 3vw 10vw;
  background-color: #E0E7FF;

  .item{
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    color: rgba(0,0,0, 0.8);
    font-weight: 600;
    padding: 10px 20px;
    margin: 10px;
    transition: all 0.35s ease !important;

    &:hover{
      color: #fff;
      background-color: #5FBA21;
      transition: all 0.35s ease !important;
    }
  }
}

.text-wrapper{

    .author{                
        text-align: left;
        font-size: 14px;
        color: rgba(0,0,0, .7);
        font-weight: 500;

        .name{
          color: #5FBA21;          
        }
    }
    .date{                
        text-align: left;
        font-size: 14px;
        color: rgba(0,0,0, .7);
        font-weight: 500;
    }
    .category{                
        text-align: left;
        font-size: 14px;
        color: #5FBA21;
        font-weight: 500;
    }
    

}

    
</style>