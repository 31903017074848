<template>
     <div style="min-height: 100vh;">
          <div class="bg-dark text-white py-5 d-flex align-items-center justify-content-center">
               <div class="container py-5">
                    <div class="row">
                         <div class="col-md-2 text-center">
                              <p><i class="fa fa-exclamation-triangle fa-5x"></i><br />Status Code: 404</p>
                         </div>
                         <div class="col-md-10">
                              <h3 v-html="$t('NOT_FOUND_TITLE')"></h3>
                              <p v-html="$t('NOT_FOUND_MESSAGE')"></p>
                              <a class="btn btn-danger" href="javascript:history.back()">{{ $t('GO_BACK') }}</a>
                         </div>
                    </div>
               </div>

          </div>
     </div>
</template>

<script>
export default {
     name: 'NotFound'
}
</script>

<style scoped lang="scss">
.bg-dark {
     height: 100vh;
     width: 100vw;
     position: fixed;
     z-index: 99999;
     top: 0;

     a {
          max-width: 200px;
     }
}
</style>
