<template>
    <div class="section section-clients row base-padding">
        <div class="title col-lg-3 col-md-3 col-sm-12 d-flex flex-row justify-content-center align-items-center">
            {{$t('TRUSTED')}}
        </div>
        
        <div class="carousel-clients-wrapper col-lg-9 col-md-9 col-sm-12">

            <Carousel    
                class="carousel-clients"                    
                :margin="0" 
                :autoplay="true"                     
                :dots="false"
                :nav="false"
                :responsive="{
                    0:{
                        items: 2
                    },
                    991:{
                        items: 4
                    },
                    1400:{
                        items: 6
                    }
                }"
            >

                <div class="item">
                    <img src="/assets/images/clients/01.png" alt="Valspar">
                </div>
                <div class="item">
                    <img src="/assets/images/clients/02.png" alt="Continental">
                </div>
                <div class="item">
                    <img src="/assets/images/clients/03.png" alt="Coca-cola">
                </div>
                <div class="item">
                    <img src="/assets/images/clients/04.png" alt="Okidata">
                </div>
                <div class="item">
                    <img src="/assets/images/clients/05.png" alt="Konica Minolta">
                </div>
                <div class="item">
                    <img src="/assets/images/clients/06.png" alt="Toyota">
                </div>
            </Carousel>
        </div>
    </div>
</template>

<script>
import Carousel from 'vue-owl-carousel'
export default {
    components:{
        Carousel
    }
}
</script>

<style scoped lang="scss">
.section-clients {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    color: #4D4D4D;

    .title {
        font-size: 16px !important;
        text-align: left !important;
        margin: 0;
    }

    .carousel-clients-wrapper{
        
        .carousel-clients{            
            .item{
                height: 100px;                
                display: flex;
                justify-content: center;
                align-items: center;

                img {           
                    position: relative;     
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    
}


@media only screen and (max-width: 991px) {
    .section-clients{
        padding-bottom: 0 !important;

        .title {            
            text-align: center !important;            
        }
    }
}

</style>