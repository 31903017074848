<template>
  <div style="min-height: 100vh;">        
    
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-process expensesRefund d-flex flex-row justify-content-center align-items-center">

          <div class="col-lg-6 col-md-6 col-sm-12 mockup" v-if="window.width > 991">
            <img :src="`/assets/images/domains/${domain.name}/processes/expensesRefund/mockup_01_${lang}.png`" :alt="$t('PROCESS_EXPENSES_REFUN')">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 div-text">
            <div class="header-main-process d-flex flex-row justify-content-start align-items-center">
              <div class='icon icon-shape expensesRefund'>
                <img src="/assets/images/processes/icons/05.png" :alt="$t('PROCESS_EXPENSES_REFUN')" />
                <img src="/assets/images/processes/icons/05_hover.png" :alt="$t('PROCESS_EXPENSES_REFUN')" />
              </div>
              <h1 class="mb-4 title" v-html="$t('PROCESS_EXPENSES_REFUN')"></h1>
            </div>
            <h2 class="subtitle" v-html="$t('WITH......')"></h2>
            
            <div class="div-buttons d-flex">
              <router-link class="btn expensesRefund btn-medium-width" :to="`/start-free${partner_url}`">{{$t("START_FREE")}}</router-link>
              <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-4 px-3 btn expensesRefund-alternative" :to="`/plans${partner_url}`">{{$t("BECOME_A_PARTNER")}}</router-link>
            </div>
          </div>

        </div>
    </transition>

    <img class="top-image" src="/assets/images/processes/top-01.png" :alt="`${domain.title}`">

    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description expensesRefund">
          <div class="row">
            <div class="column col-lg-4 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/expensesRefund/icons/01.png" :alt="$t('THIS......')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('THIS......')"></p>
              </div>
            </div>
            <div class="column col-lg-8 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/expensesRefund/icons/02.png" :alt="$t('THE ......')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('THE ......')"></p>
              </div>
            </div>            
          </div>

          <div class="row">
            <div class="column col-lg-4 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/expensesRefund/icons/04.png" :alt="$t('ONCE......')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('ONCE......')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/expensesRefund/icons/05.png" :alt="$t('IF T......')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('IF T......')"></p>
              </div>
            </div>
            <div class="column col-lg-4 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/processes/expensesRefund/icons/06.png" :alt="$t('AT A......')">                  
                  </div>
                  <span></span>                  
                  <p v-html="$t('AT A......')"></p>
              </div>
            </div>
          </div>
        </div>
    </transition>


    <transition name="slide-fade">
        <div class="section section-flow-process-expensesRefund">
          
            <div class="row">
              
              <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  
                  <h1 class="mb-5 text-center" v-html="$t('EXPO......')"></h1>

                  <ul class='mt-3 d-flex flex-row justify-content-center align-items-center'>
                    <li class="mr-4">
                      <img src="/assets/images/processes/check.png" :alt="$t('SIMP......')">
                      <p v-html="$t('SIMP......')"></p>
                    </li>
                    <li class="ml-5 mr-4">
                      <img src="/assets/images/processes/check.png" :alt="$t('QUIC......')">
                      <p v-html="$t('QUIC......')"></p>
                    </li>
                    <li class="ml-5">
                      <img src="/assets/images/processes/check.png" :alt="$t('EAS......')">
                      <p v-html="$t('EAS......')"></p>
                    </li>
                  </ul>
                </div>

              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 mockup">
                <img :src="`/assets/images/domains/${domain.name}/processes/expensesRefund/mockup_02.png`" :alt="$t('THE FUL.....')">            
              </div>
            </div>
            
        </div>
    </transition>
    


    <!-- 

        Join......

    -->  
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></Join>
    </transition>

    <!-- 

        See also......

    -->  
    <transition name="slide-fade">             
        <OtherProcesses :partner_url="partner_url" v-if="show" :page="page" :path="path"></OtherProcesses>
    </transition>

    <!-- 

        Automate......

    -->  
    <transition name="slide-fade">             
        <AutomateProcesses :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show"></AutomateProcesses>
    </transition>

    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="clients-bpm"></Clients>
    </transition> 

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import OtherProcesses from '../common/OtherProcesses'
import AutomateProcesses from '../common/AutomateProcesses'
import $ from 'jquery'

export default {
    
    components:{
      Clients,
      Join,
      ThePlatform,
      OtherProcesses,
      AutomateProcesses,      
    },
    props: {      
      isDomainPermitted:{
          type: Boolean,
          default: false
      },
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      },
      page: {
        type: String,
        default: ''
      },
      path: {
        type: String,
        default: ''
      },      
      partner_url: {
          type: String,
          default: ''
      },
    },
    data(){
        return{            
            show: false,
            
        }
    },
    computed: {

      ...mapState('MainStoreModule', {                                  
          domain: state => state.domain
      }),
      
      lang(){
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">


.section-flow-process-expensesRefund {
    padding: 6vw 10vw;
    background-color: #FDFDFD;
    h2 {
        margin-bottom: 50px;
        width: 100%;
        text-align: left;
        font-size: 35px;
        line-height: 45px;
    }
    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 25px;
            img {
                height: 25px;
                margin-right: 20px;
            }
            p {
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;
                margin: 0;
            }
        }
    }
}


@media only screen and (max-width: 991px) {
  .section-flow-process-expensesRefund,
  .section-blocks-description{
      .row{
          margin: 0 !important;
      }
  }  

  .section-flow-process-expensesRefund{

    h2{
      text-align: center !important;
    }

    .mockup {
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        position: relative;
        align-self: center;
        width: 80% !important;
        margin-top: 30px;
      }
    }
    
    .row{
      div{
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .col-sm-12{
      &:first-child{        
        margin-top: 15px !important; 
      }
    }

    ul{
      margin: 0 !important;
      
      li{        
        margin: 15px 20px !important;
        flex-direction: column !important;
        img{
          height: 15px !important;          
          margin: 0 0 10px 0 !important
        }
        p{
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }

  }
}
    
</style>