var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-clients row base-padding"},[_c('div',{staticClass:"title col-lg-3 col-md-3 col-sm-12 d-flex flex-row justify-content-center align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('TRUSTED'))+" ")]),_c('div',{staticClass:"carousel-clients-wrapper col-lg-9 col-md-9 col-sm-12"},[_c('Carousel',{staticClass:"carousel-clients",attrs:{"margin":0,"autoplay":true,"dots":false,"nav":false,"responsive":{
                0:{
                    items: 2
                },
                991:{
                    items: 4
                },
                1400:{
                    items: 6
                }
            }}},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/clients/01.png","alt":"Valspar"}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/clients/02.png","alt":"Continental"}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/clients/03.png","alt":"Coca-cola"}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/clients/04.png","alt":"Okidata"}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/clients/05.png","alt":"Konica Minolta"}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/clients/06.png","alt":"Toyota"}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }