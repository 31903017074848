<template>
  <div class="
      section section-FAQ
      base-padding
      d-flex
      flex-column
      justify-content-center
      align-items-start
    " style="margin: 0 !important">
    <h2>{{ $t("FAQ") }}</h2>

    <div class="
        d-flex
        flex-row
        justify-content-center
        align-items-center
        div-questions-list
      " :key="`faq_${$i18n.locale}`">
      <ul>
        <li v-for="(item, index) in faq[partnership]" :key="index"
          class="d-flex flex-column justify-content-center align-items-center">
          <a href="#" class="d-flex flex-row justify-content-between align-items-center"
            @click.prevent="toggleOpen($event, index)">{{ item.question[lang]
            }}<i :class="{
              'fa fa-plus': !item.isOpen,
              'fa fa-minus': item.isOpen,
            }"></i></a>
          <span :class="{ 'is-open': item.isOpen }" v-html="item.answer[lang]">
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["partnership"],

  data() {
    return {
      lang: this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale,
      faq: {
        "private-label": [
          {
            isOpen: false,
            question: {
              en: "How much does it cost to join the Interfy Partner Program?",
              pt: "Quanto custa para me associar ao Programa de Parceria Interfy?",
              es: "¿Cuánto cuesta unirse al programa Interfy Partner?",
            },
            answer: {
              en: "There is no accreditation fee or annual renewal fee for the partnership, just a one-off setup fee to prepare the product and website with the partner's branding.",
              pt: "Não há taxa de credenciamento ou taxa de renovação anual da parceria, apenas uma taxa setup única para preparar o produto e o site com a marca do parceiro.",
              es: "No hay cuota de acreditación ni cuota anual de renovación de la asociación, sólo una cuota única de configuración para preparar el producto y el sitio web con la marca del socio.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "How does the accreditation process work?",
              pt: "Como funciona o processo de credenciamento?",
              es: "¿Cómo funciona el proceso de acreditación?",
            },
            answer: {
              en: "Our channel team will contact you to send you the partnership program and explain how it works.",
              pt: "Nossa equipe de canais entrará em contato para enviar o programa de parceria e explicar como funciona.",
              es: "Nuestro equipo de canales se pondrá en contacto con usted para enviarle el programa de asociación y explicarle cómo funciona.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "What are the benefits of Interfy partnership?",
              pt: "Quais os benefícios da parceria Interfy?",
              es: "¿Cuáles son los beneficios de la asociación de interfencionar?",
            },
            answer: {
              en: `
                            50% discounts<br />
                            Free online training at Interfy University<br />
                            The products are invoiced to the partners and the partners invoice their customers directly<br />
                            Access to a platform workspace for internal use and free demonstration for the duration of the partnership<br />
                            Product with the partner's exclusive brand so that they can promote it via social networks and other means of dissemination<br />
                            Administration system where the partner manages all their sales and has full control over the use of the platform.
                            `,
              pt: `
                            Descontos de 50%<br />
                            Treinamento online grátis na Universidade Interfy<br />
                            Os produtos são faturados para os parceiros e o mesmo fatura diretamente aos seus clientes<br />
                            Acesso a uma workspace da plataforma para uso interno e em demonstração gratuita por todo o período da parceria<br />
                            Produto com marca exclusiva do parceiro para que ele possa divulgar através das redes sociais e outros meios de divulgação<br />
                            Sistema de Administração onde o parceiro administra todas as suas vendas e tem total gestão do consumo da Plataforma
                            `,
              es: `
                            Descuentos del 50%<br />
                            Formación online gratuita en la Universidad Interfy<br />
                            Los productos se facturan a los partners y los partners facturan directamente a sus clientes<br />
                            Acceso a un espacio de trabajo de la plataforma para uso interno y demostración gratuita mientras dure la colaboración<br />
                            Producto con marca exclusiva para que el partner lo publicite a través de redes sociales y otros medios de difusión<br />
                            Sistema de administración donde el socio gestiona todas sus ventas y tiene control total sobre el uso de la plataforma.
                            `,
            },
          },
          {
            isOpen: false,
            question: {
              en: "How does platform marketing work?",
              pt: "Como funciona a comercialização da plataforma?",
              es: "¿Cómo funciona la plataforma de marketing?",
            },
            answer: {
              en: "You'll be able to sell to your portfolio of clients and also through Digital Marketing, ads on Google, LinkedIn and social media campaigns such as Facebook, Instagram and email marketing. You'll be investing in promoting your own brand.",
              pt: "Você poderá vender para sua carteira de clientes e também através de Marketing Digital, anúncios no Google, LinkedIn e de campanhas feitas através das redes sociais como Facebook, Instagram e também através de e-mails marketing. Você estará investindo para divulgar a sua própria marca.",
              es: "Podrás vender a tu cartera de clientes y también a través del Marketing Digital, anuncios en Google, LinkedIn y campañas a través de redes sociales como Facebook, Instagram y email marketing. Invertirás en dar a conocer tu propia marca.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "What will Interfy make available after signing the partnership contract?",
              pt: "O que a Interfy irá disponibilizar depois da assinatura do contrato de parceria?",
              es: "¿Qué pondrá a disposición la interfaz después de firmar el contrato de asociación?",
            },
            answer: {
              en: "Interfy will make the product available with your brand and a website (www.partnerbrand.com), so that through it your company can make sales and thereby strengthen its own brand.",
              pt: "A Interfy irá disponibilizar o produto com sua marca e um site (www.marcadoparceiro.com), para que através dele a sua empresa possa realizar suas vendas e com isso fortalecer a sua própria marca.",
              es: "Interfy pondrá a su disposición el producto con su marca y un sitio web (www.sitiodelparceiro.com), para que su empresa pueda vender a través de él y reforzar así su propia marca.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "What products will my company market?",
              pt: "Quais produtos minha empresa irá comercializar?",
              es: "¿Qué productos harán mi mercado de la empresa?",
            },
            answer: {
              en: "All those listed in the products tab on the platform's website.",
              pt: "Todos os que constam na aba de produtos do site da plataforma.",
              es: "Todos los que figuran en la pestaña de productos del sitio web de la plataforma.",
            },
          },
        ],

        "authorized-partner": [
          {
            isOpen: false,
            question: {
              en: "How much does it cost to join the Interfy Partner Program?",
              pt: "Quanto custa para me associar ao Programa de Parceria Interfy?",
              es: "¿Cuánto cuesta unirse al programa Interfy Partner?",
            },
            answer: {
              en: "There is no accreditation fee or annual partnership renewal fee.",
              pt: "Não há taxa de credenciamento ou taxa de renovação anual da parceria.",
              es: "No hay cuota de acreditación ni cuota anual de renovación de la asociación.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "How does the accreditation process work?",
              pt: "Como funciona o processo de credenciamento?",
              es: "¿Cómo funciona el proceso de acreditación?",
            },
            answer: {
              en: "In the Interfy branded model: after registering, the partner will receive an e-mail with the contract that was accepted after filling in the form and our team will contact them to explain how the partnership works",
              pt: "No modelo com a marca Interfy: após o cadastro, o parceiro receberá um e-mail com o contrato que foi aceito após o preenchimento do formulário e nossa equipe irá entrar em contato para explicar como funciona a parceria",
              es: "En el modelo de marca Interfy: tras registrarse, el socio recibirá un correo electrónico con el contrato aceptado tras rellenar el formulario y nuestro equipo se pondrá en contacto con él para explicarle cómo funciona la asociación.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "How many people can I train as an Interfy partner for free?",
              pt: "Quantas pessoas posso treinar gratuitamente como parceiro Interfy?",
              es: "¿A cuántas personas puedo entrenar como socio de Interfy de forma gratuita?",
            },
            answer: {
              en: "Interfy Partner: 2<br>Interfy Private Label: 10",
              pt: "Interfy Partner: 2<br>Interfy Private Label: 10",
              es: "Interfy Partner: 2<br>Interfy Private Label: 10",
            },
          },
          {
            isOpen: false,
            question: {
              en: "What are the benefits of the Interfy partnership?",
              pt: "Quais os benefícios da parceria Interfy?",
              es: "¿Cuáles son los beneficios de la asociación Interfy?",
            },
            answer: {
              en: "50% discounts on all Interfy products<br />Free online training at (Interfy University)<br />Unique URL www.interfy.io/partnername<br />Access to the Cloud environment with unlimited users<br />The products are invoiced to the partner and the same invoiced to their customers<br />Access to a demo workspace with 5 free users<br />Administration system that manages all your sales and has total management of the consumption of the free Platform<br />Partner's exclusive URL to promote the products",
              pt: "Descontos de 50% em todos os produtos Interfy<br />Treinamento online gratuito na (Universidade Interfy)<br />Link com a URL www.interfy.io/nomedoparceiro<br />Acesso ao ambiente em Cloud com usuários ilimitados<br />Os produtos são faturados para o parceiro e mesmo fatura para os seus clientes<br />Acesso a uma workspace de demonstração com 5 usuários grátis<br />Sistema de Administração que gerencia todas as suas vendas e tem total gestão do consumo da Plataforma gratuita<br />URL exclusiva do parceiro para divulgação dos produtos",
              es: "Descuentos del 50% en todos los productos de Interfy<br />Formación online gratuita en (Interfy University)<br />Enlace único www.interfy.io/nombreedelparcero<br />Acceso al entorno Cloud con usuarios ilimitados<br />Los productos se facturan al partner y lo mismo se factura a sus clientes<br />Acceso a un espacio de trabajo demo con 5 usuarios gratuitos<br />Sistema de administración que gestiona todas tus ventas y tiene gestión completa del consumo de la Plataforma gratuita<br />Ul URL exclusiva del partner para promocionar los productos.",
            },
          },
          {
            isOpen: false,
            question: {
              // en: "What is Interfy's sales concept?",
              // pt: "Qual o conceito de vendas da Interfy?",
              // es: "¿Cuál es el concepto de ventas de Interfy?",
              en: "How Platform Marketing Works?",
              pt: "Como funciona a comercialização da plataforma?",
              es: "Cómo funciona la plataforma de marketing?",
            },
            answer: {
              // en: "Marketing should be done especially through campaigns on Google AdWords and social networks such as Facebook ADS and Linkedin, so each partner receives a unique URL for disclosure.",
              // pt: "A comercialização deve ser feita especialmente através de campanhas no Google AdWords e das redes sociais como Facebook ADS e Linkedin, por isso cada parceiro recebe uma URL exclusiva para divulgação.",
              // es: "El marketing debe hacerse especialmente a través de campañas en Google AdWords y redes sociales como Facebook ADS y Linkedin, para que cada socio reciba una URL única para su divulgación.",

              en: "You'll be able to sell to your portfolio of clients and through Digital Marketing, ads on Google, LinkedIn and campaigns made through social networks such as Facebook, Instagram and also through email marketing, using your own URL to make the sales",
              pt: "Você poderá vender para sua carteira de clientes e através do Marketing Digital, anúncios no Google, LinkedIn e de campanhas feitas através das redes sociais como Facebook, Instagram e também através de e-mails marketing, usando a sua própria URL para fazer as vendas",
              es: "Podrás vender a tu cartera de clientes y a través de Marketing Digital, anuncios en Google, LinkedIn y campañas a través de redes sociales como Facebook, Instagram y también a través de email marketing, utilizando tu propia URL para realizar las ventas.",
            },
          },
          {
            isOpen: false,
            question: {
              en: "What products will my company sell?",
              pt: "Quais produtos minha empresa irá comercializar?",
              es: "¿Qué productos comercializará mi empresa?",
            },
            answer: {
              en: "All those listed in the products tab on the platform's website.",
              pt: "Todos os que constam na aba de produtos do site da plataforma.",
              es: "Todos los que figuran en la pestaña de productos del sitio web de la plataforma.",
            },
          },
        ],
      },
    };
  },
  methods: {
    toggleOpen(e, index) {
      e.preventDefault;
      this.faq[this.partnership][index].isOpen =
        !this.faq[this.partnership][index].isOpen;
    },
  },
};
</script>

<style></style>