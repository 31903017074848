<template>
  <div class="ct-payment">
    
    <form id="form-payment" ref="form-payment">

      <!-- 
        Billing method
      -->
      
      <!-- <div class="row" v-if="form.country && form.country.code == 'Brazil'"> -->
      <div class="row" v-if="user_monetary.currency_code == 'brl'">
        <div class="col">
          <b-form-group class="m-0" :description="''" :label="`${$t('BILLING_METHOD')}`" label-for="billing_method">
            <b-form-radio-group required :state="state.billing_method" v-model="form.billing_method" :options="methodsList" name="radio-inline"></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
     
      <div class="row">
        <!--
          tax_id
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">                 
          <b-form-group class="m-0" :description="''" :label="`${$t('tax_id')}`" label-for="tax_id" :invalid-feedback="error_taxid" :state="state.tax_id">            
            <b-form-input v-if="form.country && form.country.code == 'Brazil'" v-mask="'##.###.###/####-##'" id="tax_id" :state="state.tax_id" v-model="form.tax_id" trim required></b-form-input>                    
            <b-form-input v-else-if="form.country && form.country.code == 'United States'" v-mask="'##-#######'" id="tax_id" :state="state.tax_id" v-model="form.tax_id" trim required></b-form-input>                    
            <b-form-input v-else id="tax_id" v-model="form.tax_id" trim required></b-form-input>                    
          </b-form-group>
        </div>
        <!--
          trading_name
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">
          <b-form-group class="m-0" :description="''" :label="$t('trading_name')" label-for="trading_name" :state="state.trading_name">
            <b-form-input id="trading_name" v-model="form.trading_name" trim required></b-form-input>                    
          </b-form-group>
        </div>
      </div>

      <div class="row">

        <!--
          country
        -->        
        <div class="col-sm-12 col-md-6 col-lg-6">          
          <b-form-group class="m-0" :description="''" :label="$t('country')" label-for="country" :state="state.country">
            <v-select v-if="countriesList" :disabled="isLoadingZipcode" required id="country" v-model="form.country" :options="countriesList" ></v-select>
          </b-form-group>
        </div>

        <!--
          zip_code
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">          
          <b-form-group class="m-0" :description="''" :label="`${$t('zip_code')} ${(env == 'localhost') ? '(Localhost)' : ''}`" label-for="zip_code" :invalid-feedback="error_zipcode" :state="state.zip_code">            
            <b-form-input v-if="form.country && form.country.code == 'Brazil'" v-mask="'#####-###'" id="zip_code" :state="state.zip_code" v-model="form.zip_code" @change="handleZipCode" trim required></b-form-input>                    
            <b-form-input v-else-if="form.country && form.country.code == 'United States'" v-mask="'#####-####'" id="zip_code" :state="state.zip_code" v-model="form.zip_code" trim required></b-form-input>                    
            <b-form-input v-else id="zip_code" v-model="form.zip_code" trim required></b-form-input>                    
          </b-form-group>
        </div>
      </div>

      <div class="row">

        <!--
          address_line1
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">
          <b-form-group class="m-0" :description="''" :label="$t('address_line1')" label-for="address_line1" :state="state.address_line1">
            <b-form-input :disabled="isLoadingZipcode" id="address_line1" v-model="form.address_line1" trim required></b-form-input>                    
          </b-form-group>
        </div>

        <!--
          county
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">          
          <b-form-group class="m-0" :description="''" :label="$t('county')" label-for="county" :state="state.county">
            <b-form-input :disabled="isLoadingZipcode" id="county" v-model="form.county" trim required></b-form-input>                    
          </b-form-group>
        </div>
            
      </div>

      <div class="row">
        <!--
          address_line2
        -->
        <div class="col">
          <b-form-group class="m-0" :description="''" :label="$t('address_line2')" label-for="address_line2" :state="state.address_line2">
            <b-form-input :disabled="isLoadingZipcode" id="address_line2" v-model="form.address_line2" trim></b-form-input>                    
          </b-form-group>
        </div> 
        
      </div>

      <div class="row">
        <!--
          province
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">          
          <b-form-group class="m-0" :description="''" :label="$t('province')" label-for="province" :state="state.province">
            <v-select v-if="form.country && form.country.code == 'Brazil'" required id="province" v-model="form.province" :options="brStates" ></v-select>
            <v-select v-else-if="form.country && form.country.code == 'United States'" required id="province" v-model="form.province" :options="enStates" ></v-select>
            <b-form-input v-else :disabled="isLoadingZipcode" id="province" v-model="form.province" trim required></b-form-input>                    
          </b-form-group>
        </div>

        <!--
          city
        -->
        <div class="col-sm-12 col-md-6 col-lg-6">
          <b-form-group class="m-0" :description="''" :label="$t('city')" label-for="city" :state="state.city">
            <b-form-input :disabled="isLoadingZipcode" id="city" v-model="form.city" trim required></b-form-input>                    
          </b-form-group>
        </div>
      </div>

    </form>

    <!-- 
      Credit card data (Stripe)
    -->
    <div class="row" v-if="publishableKey && form.billing_method == 1">
      <div class="col">
        <b-form-group class="m-0" :description="''" :label="$t('CREDIT_CARD')">

          <StripeElements             
            ref="elementsRef"
            :pk="publishableKey"          
            :locale="lang"
            @token="tokenCreated"
            @loading="loading = $event"          
          >
          </StripeElements>
        </b-form-group>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { StripeElements } from 'vue-stripe-checkout'
import countriesJson from '../../../../public/assets/json/countries.json'

import $ from 'jquery'
export default {  
  props:['selectedPlan', 'lang'],  
  components:{    
    StripeElements
  },
  computed:{
    ...mapState('MainStoreModule', {                                          
      pre_customer: state => state.pre_customer,      
      env: state => state.env,
      stripe: state => state.stripe,
      shopping_cart: state => state.shopping_cart,
      user_monetary: state => state.user_monetary,
    }),

    brLocaleValidation(){
      return this.user_monetary.ioc == 'br'
    },

    enLocaleValidation(){
      return this.user_monetary.ioc == 'us'
    },

    
  },
  data(){    
      return {
        countriesList: null,
        countriesJson: countriesJson,
        loading: false,        
        publishableKey: null,
        
        methodsList: [
          { text: this.$t('CREDIT_CARD'), value: 1 },
          { text: this.$t('BANK_SLIP'), value: 2 }
        ],

        isLoadingZipcode: false,
        error_zipcode: null,
        error_taxid: null,
        

        form: {
          billing_method: null,
          token: null,

          tax_id: null,
          trading_name: null,
          zip_code: null,
          address_line1: null,
          address_line2: null,
          county: null,
          city: null,
          province: null,
          country: null,
        },
        
        state: {
          billing_method: null,
          tax_id: null,
          trading_name: null,
          zip_code: null,
          address_line1: null,
          address_line2: null,
          county: null,
          city: null,
          province: null,
          country: null,
        },

        enStates: [
          "Alabama",
          "Alaska",
          "Arizona",
          "Arkansas",
          "California",
          "Colorado",
          "Connecticut",
          "Delaware",
          "Florida",
          "Georgia",
          "Hawaii",
          "Idaho",
          "Illinois",
          "Indiana",
          "Iowa",
          "Kansas",
          "Kentucky",
          "Louisiana",
          "Maine",
          "Maryland",
          "Massachusetts",
          "Michigan",
          "Minnesota",
          "Mississippi",
          "Missouri",
          "Montana",
          "Nebraska",
          "Nevada",
          "New Hampshire",
          "New Jersey",
          "New Mexico",
          "New York",
          "North Carolina",
          "North Dakota",
          "Ohio",
          "Oklahoma",
          "Oregon",
          "Pennsylvania",
          "Rhode Island",
          "South Carolina",
          "South Dakota",
          "Tennessee",
          "Texas",
          "Utah",
          "Vermont",
          "Virginia",
          "Washington",
          "West Virginia",
          "Wisconsin",
          "Wyoming"
        ],

        brStates: [
          "Acre",
          "Alagoas",
          "Amapá",
          "Amazonas",
          "Bahia",
          "Brasília",
          "Ceará",
          "Espírito Santo",
          "Goiás",
          "Mato Grosso",
          "Mato Grosso do Sul",
          "Maranhão",
          "Minas Gerais",
          "Pará",
          "Paraná",
          "Paraíba",
          "Pernambuco",
          "Piauí",
          "Rio de Janeiro",
          "Rio Grande do Norte",
          "Rio Grande do Sul",
          "Rondônia",
          "Roraima",
          "Santa Catarina",
          "São Paulo",
          "Sergipe",
          "Tocantins"
        ]
      }
  },

  watch:{
    form:{
      deep: true,
      handler(form){
        
        this.state.zip_code = this.isValidZipCode(form.zip_code)
        this.state.tax_id = this.isValidTaxID(form.tax_id)

        this.updateShoppingCart({'key': 'payment', 'value': form})        
      }
    },

     
  },
  
  methods:{

    ...mapActions('MainStoreModule', [          
      'updateShoppingCart',
      'getCepData'
    ]),

    setCountriesList(){
      
      var countriesList = []

      for(var i in this.countriesJson){
        var country = this.countriesJson[i]

        var name = null,
            code = country['name']['common']

        if(this.lang == 'en'){
          name = code
        }else{          
          name = country['translations'][this.lang]['common']
        }

        countriesList.push({
          code: country['name']['common'],
          label: this.$t(`${name}`)
        })

      }

      this.countriesList = countriesList
    },

    hasSpecialChars(str){
        return !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(str)
    },

    isValidZipCode(str){

      if (
          str == null ||
          (str.length < 9 && this.brLocaleValidation) ||
          (str.length < 10 && this.enLocaleValidation)
      ) {

          if(str == null || str.length == 0){
              this.error_zipcode = this.$t('FIELD_IS_REQUIRED').replace('{var1}', this.$t('zip_code'))
          }else{
              this.error_zipcode = this.$t('INVALID_ZIP_CODE')
          }

          return false
      }
  
      return true

    },

    isValidTaxID(str){

      if (
          (!this.isCnpj(str) && this.brLocaleValidation) ||
          (!this.isAmericanEIN(str) && this.enLocaleValidation)
      ) {

          if(str == null || str.length == 0){
              this.error_taxid = this.$t('FIELD_IS_REQUIRED').replace('{var1}',this.$t('tax_id'))
          }else{
              this.error_taxid = this.$t('INVALID_TAX_ID')
          }

          return false
      }

      return true
      
    },

    isAmericanEIN(str){
      var patternEIN = /^\d{2}-?\d{7}$/
      return patternEIN.test(str)
    },

    isCnpj(cnpj) {

      if(cnpj == null)
        return false
 
      cnpj = cnpj.replace(/[^\d]+/g,'')
  
      if(cnpj == '') return false
      
      if (cnpj.length != 14)
          return false
  
      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" || 
          cnpj == "11111111111111" || 
          cnpj == "22222222222222" || 
          cnpj == "33333333333333" || 
          cnpj == "44444444444444" || 
          cnpj == "55555555555555" || 
          cnpj == "66666666666666" || 
          cnpj == "77777777777777" || 
          cnpj == "88888888888888" || 
          cnpj == "99999999999999")
          return false
          
      // Valida DVs
      var tamanho = cnpj.length - 2,
          numeros = cnpj.substring(0,tamanho),
          digitos = cnpj.substring(tamanho),
          soma = 0,
          pos = tamanho - 7

      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2)
              pos = 9
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado != digitos.charAt(0))
          return false
          
      tamanho = tamanho + 1
      numeros = cnpj.substring(0,tamanho)
      soma = 0
      pos = tamanho - 7
      for (var x = tamanho; x >= 1; x--) {
        soma += numeros.charAt(tamanho - x) * pos--
        if (pos < 2)
              pos = 9
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado != digitos.charAt(1))
            return false
            
      return true
        
    },

    async handleZipCode(){

      if(this.form.zip_code.length < 9){
        return 
      }

      this.isLoadingZipcode = true
      await this.getCepData(this.form.zip_code).then((response) => {
          
          if(response.erro){

            this.form.county = null
            this.form.address_line2 = null
            this.form.city = null
            this.form.address_line1 = null
            this.form.province = null
            this.form.country = null

          }else{

            this.form.county = response.bairro
            this.form.address_line2 = response.complemento
            this.form.city = response.localidade
            this.form.address_line1 = response.logradouro
            this.form.province = response.estado
            this.form.country = {code: "Brazil", label: "Brasil"}

          }
          
      })

      this.isLoadingZipcode = false
    },

    submit () {     
      this.form.token = null 
      this.$refs.elementsRef.submit()
    },
    
    tokenCreated (token) {            
      this.form.token = token 
      console.log(">> New stripe token created!")  
    }

  },


  mounted(){

    this.setCountriesList()

    setTimeout(() => {
      if(this.brLocaleValidation){
        this.form.country = {code: "Brazil", label: "Brasil"}

      }else if(this.enLocaleValidation){
        this.form.country = {code: "United States", label: "United States"}
      }
    }, 200)

    this.form.trading_name = this.pre_customer.company_name
    this.publishableKey = this.stripe.publishableKey    
    this.form.billing_method = 1

    this.$root.$on('validate-card', async () => {      
      console.log(">> Validating card...")
      this.submit()
    })

  },

  destroyed() {          
    this.$root.$off(['validate-card'])
  }
}
</script>

<style lang="scss" scoped>

.ct-payment{    
  width: 100%;

  .row{
      margin: 0;
  }
}

</style>