<template>
  <div style="min-height: 100vh;">        
    
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-solutions base-padding d-flex flex-row justify-content-center align-items-center">

          <div class="row">
            <div class="col-12">
              <h1 class="mb-4 title" v-html="$t('A COM......')"></h1>              
              <div class="div-buttons d-flex flex-row">
                <router-link class="mr-5 btn btn-blue btn-medium-width" :to="`/start-free${partner_url}`">{{ $t('START_FREE') }} </router-link>
                <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="btn btn-success" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
              </div>              
            </div>            
          </div>  
        </div>
    </transition>

    <transition name="slide-fade">
        <div class="section section-platform-solutions d-flex flex-wrap justify-content-center align-items-start">
        
          <div class="item">
            <img src="/assets/images/solutions/img-01.png" :alt="$t('ACCOU......')">
            <h3 v-html="$t('ACCOU......')"></h3>
            <p v-html="$t('ENSUR......')"></p>
          </div>

          <div class="item">
            <img src="/assets/images/solutions/img-02.png" :alt="$t('EDUCA......')">
            <h3 v-html="$t('EDUCA......')"></h3>
            <p v-html="$t('DIGIT......')"></p>
          </div>

          <div class="item">
            <img src="/assets/images/solutions/img-03.png" :alt="$t('LEGAL......')">
            <h3 v-html="$t('LEGAL......')"></h3>
            <p v-html="$t('HAVIN......')"></p>
          </div>

          <div class="item">
            <img src="/assets/images/solutions/img-04.png" :alt="$t('CHEER......')">
            <h3 v-html="$t('CHEER......')"></h3>
            <p v-html="$t('THE A......')"></p>
          </div>

          <div class="item">
            <img src="/assets/images/solutions/img-05.png" :alt="$t('VEHIC......')">
            <h3 v-html="$t('VEHIC......')"></h3>
            <p v-html="$t('MAKIN......')"></p>
          </div>

          <div class="item">
            <img src="/assets/images/solutions/img-06.png" :alt="$t('PROPE......')">
            <h3 v-html="$t('PROPE......')"></h3>
            <p v-html="$t('DEALI......')"></p>
          </div>
          

        </div>
    </transition>

    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description solutions">
          <div class="row">
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/solutions/icons/01.png" :alt="$t('RETAI......')">                  
                  </div>                  
                  <h3 v-html="$t('RETAI......')"></h3>
                  <p v-html="$t('BECAU......')"></p>
              </div>
            </div>
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/solutions/icons/02.png" :alt="$t('GOVER......')">                  
                  </div>                   
                  <h3 v-html="$t('GOVER......')"></h3>
                  <p v-html="$t('INNOV......')"></p>
              </div>
            </div>
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/solutions/icons/03.png" :alt="$t('FINAN......')">                  
                  </div>                    
                  <h3 v-html="$t('FINAN......')"></h3>
                  <p v-html="$t('SECUR......')"></p>
              </div>
            </div>

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">
                  <div class='icon icon-shape icon-01'>
                    <img src="/assets/images/solutions/icons/04.png" :alt="$t('TELEC......')">                  
                  </div>                  
                  <h3 v-html="$t('TELEC......')"></h3>
                  <p v-html="$t('THE M......')"></p>
              </div>
            </div>
          </div>

        </div>
    </transition>



    <transition name="slide-fade">
        <div class="section base-padding section-blocks-description solutions-2">
          <div class="row">
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/05.png" :alt="$t('WITH ....')">  
                  <h3 v-html="$t('INDUS......')"></h3>
                  <p v-html="$t('DIGIT2.....')"></p>
              </div>
            </div>
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/06.png" :alt="$t('BPM M....')">
                  <h3 v-html="$t('AGROI......')"></h3>
                  <p v-html="$t('AGRIB......')"></p>
              </div>
            </div>
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/07.png" :alt="$t('TO FA....')">
                  <h3 v-html="$t('MANUF......')"></h3>
                  <p v-html="$t('THE C......')"></p>
              </div>
            </div>

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/08.png" :alt="$t('TO FA....')">
                  <h3 v-html="$t('INSUR......')"></h3>
                  <p v-html="$t('CUSTO......')"></p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/09.png" :alt="$t('WITH ....')">
                  <h3 v-html="$t('DISTR......')"></h3>
                  <p v-html="$t('WHEN ......')"></p>
              </div>
            </div>
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/10.png" :alt="$t('BPM M....')">
                  <h3 v-html="$t('STEEL......')"></h3>
                  <p v-html="$t('EFFIC......')"></p>
              </div>
            </div>
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/11.png" :alt="$t('TO FA....')">
                  <h3 v-html="$t('CONST......')"></h3>
                  <p v-html="$t('CONTR......')"></p>
              </div>
            </div>

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="item d-flex flex-column justify-content-start align-items-center">                  
                  <img src="/assets/images/solutions/icons/12.png" :alt="$t('TO FA....')">
                  <h3 v-html="$t('TRADE......')"></h3>
                  <p v-html="$t('THEM.......')"></p>
              </div>
            </div>
          </div>

        </div>
    </transition>


    <!-- 
        Inovate...
    -->          
    <div v-if="show" class="section section-inovate base-padding d-flex flex-column justify-content-center align-items-center ">
        <h1 class="mb-4 text-center font-bpm">{{$t('INOVATE THE WAY YOU WORK')}}</h1>
        <h2>{{$t('NOW YOU ARE ONE CLICK FROM THE INTERFY PLATFORM').replace(/{domain}/g, domain.title)}}</h2>

        <div class="d-flex flex-row justify-content-center align-items-center div-buttons-hire">
            <router-link class="btn btn-blue btn-medium-width" :to="`/start-free${partner_url}`">{{ $t('START_FREE') }} </router-link>            
            <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-5 btn btn-success px-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
            
        </div>
    </div>


    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="clients-bpm"></Clients>
    </transition> 


    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

    

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import ThePlatform from '../common/ThePlatform'
import $ from 'jquery'

export default {
    components:{
      Clients,      
      ThePlatform      
    },
    props: {   
      isDomainPermitted:{
          type: Boolean,
          default: false
      },
      partner_url: {
        type: String,
        default: ''
      },   
      window: {
        type: Object,
        default () {
          return {
            width: 0,
            height: 0
          }
        }
      }      
    },
    data(){
        return{            
          show: false,            
        }
    },
    computed: {

      ...mapState("MainStoreModule", {            
          domain: state => state.domain
      }),
      
      lang(){
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },      
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   
    }, 
    
    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">


.section-main-solutions {    
    height: calc(100vh - 80px);
    color: #fff;
    background: url(/assets/images/solutions/banner_01.jpg) no-repeat;
    background-size: cover;
    .title {
        font-size: 40px !important;
        line-height: 50px !important;
        width: 50% !important;
        margin-bottom: 60px !important;
    }
    .hire-now-ct {
        width: 400px;
        margin-top: 30px;
        a {
            text-align: left;
            color: #fff;
            font-weight: 400;
            text-shadow: 1px 1px 6px #000000 !important;
            text-decoration: none;
            transition: all .4s ease;
            &:hover {
                text-shadow: 1px 1px 2px rgba(33, 76, 219, 1) !important;
                // color: rgba(33,76,219,1);
            }
        }
    }
    h1 {
        color: #fff;
        width: 60%;
        text-align: left;
        font-size: 60px !important;
        font-weight: 600;
        margin-bottom: 30px;
        text-shadow: 1px 1px 6px #000000 !important;
    }
    h2 {
        color: #fff;
        width: 40%;
        text-align: left;
        font-weight: 400;
        margin-bottom: 30px;
        text-shadow: 1px 1px 6px #000000 !important;
    }
}

.section-platform-solutions {
    padding: 6vw 10vw;
    h2 {
        color: #4F4F4F;
        width: 50%;
        text-align: center;
        margin-bottom: 70px;
    }

    .item {
        padding: 0 30px;
        margin-bottom: 30px;
        
        width: 30%;
        
        img {
            position: relative;
            width: 100%;
            margin-bottom: 30px;
        }
        h3 {
            color: #4F4F4F;
            width: 100%;
            text-align: center;
            font-size: 30px;
        }
        p {
            color: #4F4F4F;
            width: 100%;
            text-align: center;
            font-size: 18px;
        }
    }
    
}

@media only screen and (min-width: 992px) and (max-width: 1460px) {
  
  .section-blocks-description{
    .row{
      margin: 0 !important;

      .column{
        .item{
          min-height: auto !important;
        }
      }
    }
  }

  .section-platform-solutions {    
    
    .item {
      h3 {              
        font-size: 18px !important;
      }
      p {            
        font-size: 14px !important;
      }
    }
      
  }
}

@media only screen and (max-width: 991px) {
  
  .section-main-solutions {

      .div-buttons{
          display: flex !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
          
          .btn {
              padding: 0 10px !important;             
              margin: 20px 0 0 0 !important;
              width: 190px !important;
              height: 30px !important;
              font-size: 16px !important;
              line-height: 18px !important;
              border-radius: 5px !important;          
          }
      }
      
      h1.title{
          font-size: 25px !important;
          line-height: 28px !important;
          width: 100% !important;
          text-align: center;
          padding: 20px 0 !important;
          margin: 0 !important;
      }

      h2{
          margin: 0 0 20px 0 !important;
          padding: 0 !important;
          width: 100% !important; 
          text-align: center;
          font-size: 20px !important;
          line-height: 23px !important;
      }

      h3{
          margin: 0 !important;
          padding: 0 !important;
          width: 100% !important; 
          text-align: center;
          font-size: 18px !important;
      }
  }

  .section-platform-solutions {    
    
    .item {
      padding: 0 10px !important;
      margin-bottom: 20px !important;          
      width: 100% !important;
      
      img {
        margin-bottom: 20px !important;
      }
      h3 {              
        font-size: 18px !important;
      }
      p {            
        font-size: 14px !important;
      }
    }
      
  }

  .section-blocks-description{
    .row{
      margin: 0 !important;
    }
  }
}
    
</style>