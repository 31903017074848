<template>
    <div>
        <li 
            v-for="(addon, index) in plan_addons" :key="index"
            :class="{'on': addon.hasAddon, 'off': !addon.hasAddon}" 
        >
            <img v-if="!addon.isOptional || !addon.hasAddon || (addon.isOptional && addon.is_included)" :src="`/assets/images/plans/${addon.hasAddon}.png`">

            <div style="width: 100%;" class="optional-wrapper d-flex flex-row justify-content-between align-items-center" v-if="addon.isOptional && addon.hasAddon && !addon.is_included">
                <div class="optional">

                    <!--
                        No tiers
                    -->                     
                    <div v-if="!addon.tiered">
                        <span>
                            <span :class="{
                                    'font-ecm': (mod == 'ECM' || mod == 'GENERAL'),
                                    'font-bpm': mod == 'BPM',
                                    'font-swc': mod == 'SCS',
                                    'font-dss': mod == 'DSS'
                                }">{{$t('MONTHLY_VALUE')}}</span> 
                            <br>
                            <!--  {{$t('BY')}} {{ addon.min_units }} {{$t( getAddonUnit(addon, ((addon.min_units > 1) ? 'plural' : 'singular'))) }} -->
                        </span> 

                        <span>{{ `${(acceptCurrencies.includes(currency_by_url)) ? ( (currency_by_url.toLowerCase() == 'usd') ? "$" : "R$" ) : user_monetary.symbol} ${addon.price[0]}${user_monetary.separators.cents}${addon.price[1]}`}}</span>
                    </div>

                    <!--
                        with tiers
                    -->
                    <div v-if="addon.tiered" class="addon-tiers">

                        <div v-for="(tier_item, tier_index) in addon.tiers" :key="tier_index"                         
                            :class="{
                                'hide' : tier_index > 0                       
                            }"
                        >
                            
                            <span>
                                <span :class="{
                                    'font-ecm': (mod == 'ECM' || mod == 'GENERAL'),
                                    'font-bpm': mod == 'BPM',
                                    'font-swc': mod == 'SCS',
                                    'font-dss': mod == 'DSS'
                                }">{{$t('MONTHLY_VALUE')}}</span> <br>
                                
                                <!-- limited max units -->
                                <template v-if="tier_item.max && !tier_item.last_tier_with_price">
                                    {{$t('UP_TO')}} {{ tier_item.max }} {{$t( getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular'))) }}
                                </template>

                                <!-- unlimited max units (when the real last price is 0, show second to last as unlimited) -->                                
                                <template v-else-if="tier_item.max && tier_item.last_tier_with_price">                                    
                                    {{$t('UNLIMITED')}}
                                </template>

                                <!-- unlimited max units (when the real last price is not 0) -->
                                <template v-else>
                                    {{ tier_item.min }} {{$t( getAddonUnit(addon, ((tier_item.min > 1) ? 'plural' : 'singular')) )}}
                                   {{$t('OR_MORE')}}
                                </template>

                            </span>    

                            <!-- tier price  -->                                            
                            <span>{{ `${(acceptCurrencies.includes(currency_by_url)) ? ( (currency_by_url.toLowerCase() == 'usd') ? "$" : "R$" ) : user_monetary.symbol} ${tier_item.total}` }}</span>

                        </div>
                    </div>
                </div>

                <small :class="{
                    'font-ecm': mod == 'ECM' || mod == 'GENERAL',
                    'font-bpm': mod == 'BPM',
                    'font-swc': mod == 'SCS',
                    'font-dss': mod == 'DSS'
                }">
                    <i v-if="addon.tiered" class="mr-1 fas fa-chevron-circle-down"></i>                            
                    <i :id="`tooltip-target-${index}`" class="fas fa-info-circle"></i>                    
                </small>

                <b-tooltip :target="`tooltip-target-${index}`" triggers="hover">
                    {{$t('OPTIONAL_ON_CHECKOUT')}}
                </b-tooltip>
                
            </div>
    
        </li>
    </div>

</template>

<script>
import PlansMixin from '../../mixins/PlansMixin'
export default {
    mixins: [PlansMixin],
    props:['mod', 'plan_addons'],
}
</script>

<style lang="scss">


.optional-wrapper {
    &:hover {
        .addon-tiers {
            z-index: 99999999999 !important;
            position: absolute;
            padding: 30px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #4669F8;
            div {
                display: block !important;
                margin: 10px 0;
            }
            i {
                cursor: pointer;
            }
        }
    }
}

</style>