import VueRecaptcha from 'vue-recaptcha'

export default {
    components:{
        VueRecaptcha
    },    
    data(){
        return {
            recaptcha: null,
        }
    },
    methods:{
        recaptchaOnVerify(response){
            console.log('>> Recaptcha verified!')
            this.recaptcha = response            
        },
        recaptchaOnExpired(){
            console.log('>> Recaptcha has expired!')
            this.recaptcha = null
        },
        recaptchaReset(){
            console.log('>> Recaptcha has been reset!')
            this.recaptcha = null  
        }
    }
}