<template>
    <div class="section section-FAQ base-padding d-flex flex-column justify-content-center align-items-start ">
        <h2>{{$t('FAQ')}}</h2>

        <div class="d-flex flex-row justify-content-center align-items-center div-questions-list">
            <ul>
                <li v-for="(item, index) in faq" :key="index" class="d-flex flex-column justify-content-center align-items-center">
                    <a class="d-flex flex-row justify-content-between align-items-center" @click.stop="toggleOpen($event, index)">{{ item.question[lang].replace(/{domain}/g, domain.title) }}<i :class="{'fa fa-plus': !item.isOpen, 'fa fa-minus': item.isOpen}"></i></a>
                    <span :class="{'is-open': item.isOpen}" v-html="item.answer[lang].replace(/{domain}/g, domain.title).replace('{email1}', ( isInterfyFamily() ) ? domain.email2 : domain.email1)"></span>
                </li>
            </ul>
        </div>
    </div>
</template>



<script>
import { mapState, mapActions } from 'vuex'
export default {

    computed:{

        ...mapState('MainStoreModule', {                                
            domain: state => state.domain
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },    
    },

    data(){
        return{            
            faq: [
                {
                    isOpen: false,
                    question: {
                        en: "Do you need to do any installation to use the System?",
                        pt: "Precisa fazer alguma instalação para usar o Sistema?",
                        es: "¿Necesita hacer alguna instalación para usar el Sistema?",
                    },
                    answer: {
                        en: "No, access can be done directly in one of the main browsers available, but we recommend that you use Google Chrome for better performance. The only item that must be installed is the scanning plugin that will be triggered when you first click on the \"Scan\" option.",
                        pt: "Não, o acesso pode ser feito direto em um dos principais browsers disponíveis, mas recomendamos que utilize o Google Chrome para uma melhor performance. O único item que deve ser instalado é o plugin de digitalização que será acionado quando você clicar pela primeira vez na opção \"Digitalizar\".",
                        es: "No, el acceso se puede hacer directamente en uno de los principales navegadores disponibles, pero le recomendamos que utilice Google Chrome para un mejor rendimiento. El único elemento que debe instalarse es el complemento de escaneo que se activará cuando haga clic por primera vez en la opción \"Escanear\".",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "Can I change my plan?",
                        pt: "Posso mudar de plano?",
                        es: "¿Puedo cambiar mi plan?",
                    },
                    answer: {
                        en: "If you need to register more users, there is no need to migrate to a larger plan, just register users and the system will count and charge your monthly consumption, both for the user and for storage space, always respecting the minimum package of your plan. Now if you want to include more tools in your plan, example: you have only hired ECM and you also want to include BPM, send an email to our support center ({email1}) and we will help you.",
                        pt: "Se você precisa cadastrar mais usuários, não é necessário migrar para um plano maior, basta cadastrar os usuários e o sistema vai contabilizar e realizar a cobrança do seu consumo mensal, tanto para usuário como para espaço de armazenamento, respeitando sempre o pacote mínimo do seu plano. Agora se você quer incluir mais ferramentas no seu plano, exemplo: você contratou só ECM e quer incluir também o BPM, envie um email para nossa central de suporte ({email1}) e vamos ajudá-lo.",
                        es: "Si necesita registrar más usuarios, no es necesario migrar a un plan más amplio, solo registre usuarios y el sistema contará y cobrará su consumo mensual, tanto para el usuario como para el espacio de almacenamiento, siempre respetando el paquete mínimo de su plan. Ahora, si desea incluir más herramientas en su plan, por ejemplo: solo ha contratado ECM y también desea incluir BPM, envíe un correo electrónico a nuestro centro de soporte ({email1}) y lo ayudaremos.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "Do I need to sign a contract to use the System?",
                        pt: "Preciso assinar um contrato para utilizar o Sistema?",
                        es: "¿Debo firmar un contrato para usar el Sistema?",
                    },
                    answer: {
                        en: "There is no contract for signing, however, when you register your account, you are subscribing to the service and agreeing to its terms of use and privacy policy.",
                        pt: "Não existe um contrato para assinatura, contudo, quando você registra sua conta, você está aderindo ao serviço e concordando com os seus termos de uso e com a política de privacidade.",
                        es: "No hay contrato para firmar, sin embargo, cuando registra su cuenta, se suscribe al servicio y acepta sus términos de uso y política de privacidad.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "Is there a loyalty period?",
                        pt: "Existe um prazo de fidelidade?",
                        es: "¿Hay un período de fidelización?",
                    },
                    answer: {
                        en: "No, you can stop using at any time, just inform that you want to end the service and comply with the 30 day notice period.",
                        pt: "Não, você pode parar de usar a qualquer tempo, basta informar que deseja encerrar o serviço e cumprir o prazo de aviso prévio de 30 dias.",
                        es: "No, puede dejar de usarlo en cualquier momento, solo informe que desea finalizar el servicio y cumplir con el período de notificación de 30 días.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "Can I block my employees' access to the Platform after their working hours?",
                        pt: "Posso bloquear acesso dos meus funcionários à Plataforma após o horário de trabalho deles?",
                        es: "¿Puedo bloquear el acceso de mis empleados a la Plataforma después de sus horas de trabajo?",
                    },
                    answer: {
                        en: "Yes, as long as you create a work calendar specifying the work schedule and then link it to the user's registration.",
                        pt: "Sim, desde que você crie um calendário de trabalho especificando o horário de trabalho e depois vincule isso no cadastro do usuário.",
                        es: "Sí, siempre que cree un calendario de trabajo que especifique el horario de trabajo y luego lo vincule al registro del usuario.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "Do you have an application that I can download and use on my phone?",
                        pt: "Tem um aplicativo que eu possa baixar e usar no celular?",
                        es: "¿Tiene una aplicación que puedo descargar y usar en mi teléfono?",
                    },
                    answer: {
                        en: "No, the Platform is responsive and allows use through mobile devices and automatically adjusts.",
                        pt: "Não, a Plataforma é responsiva e permite que utilização através de dispositivos móveis se ajustando automaticamente.",
                        es: "No, la plataforma es receptiva y permite su uso a través de dispositivos móviles, ajustándose automáticamente.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "How does {domain} control the access of its internal staff to customer bases to prevent misuse of information?",
                        pt: "Como a {domain} controla o acesso do seu pessoal interno às bases de clientes para prevenir uso indevido de informações?",
                        es: "¿Cómo controla {domain} el acceso de su personal interno a las bases de clientes para evitar el mal uso de la información?",
                    },
                    answer: {
                        en: "The {domain} Platform was built using data isolation, which means that, although the data is in the cloud, they are not unified in the same location, and have restricted access to users who have access credentials, and not even the team of {domain} support is able to access the information, so whenever a new base is created, the data of the master user is sent to the creator's email so that he can configure the System, and if the creator / administrator needs help with parameterization or for future support, he must release a temporary login so that the {domain} team can interact and assist him, properly identified.",
                        pt: "A Plataforma {domain} foi construída utilizando o isolamento de dados, isso faz com que, embora os dados estejam em nuvem, eles não estão unificados em um mesmo local, e possuem acesso restrito aos usuários que com credencial de acesso, e nem mesmo a equipe de suporte da {domain} consegue acessar as informações, por isso, sempre que uma nova base é criada, os dados do usuário master é enviado ao email do criador para que ele possa configurar o Sistema, e se o criador/administrador precisar de ajuda para parametrização ou para um suporte futuro, ele deve liberar login temporário para que a equipe da {domain} possa interagir e auxiluá-lo, devidamente identificados.",
                        es: "La plataforma {domain} se creó utilizando el aislamiento de datos, lo que significa que, aunque los datos están en la nube, no están unificados en la misma ubicación y tienen acceso restringido a los usuarios que tienen credenciales de acceso, y ni siquiera al equipo de El soporte de {domain} puede acceder a la información, por lo que cada vez que se crea una nueva base, los datos del usuario maestro se envían al correo electrónico del creador para que pueda configurar el sistema, y ​​si el creador / administrador necesita ayuda con la parametrización o para soporte futuro, debe liberar un inicio de sesión temporal para que el equipo de {domain} pueda interactuar y ayudarlo, debidamente identificado.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "If I stop using the System, can I export my documents?",
                        pt: "Se eu deixar de usar o Sistema, posso exportar meus documentos?",
                        es: "Si dejo de usar el Sistema, ¿puedo exportar mis documentos?",
                    },
                    answer: {
                        en: "Yes, just ask support for your bucket link",
                        pt: "Sim,  basta solicitar ao suporte o link do seu bucket ",
                        es: "Sí, solo pide ayuda para tu enlace de cubo",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "I hired the system, but I don't know how to configure or design the processes in BPM, do you offer Consulting services for implementation?",
                        pt: "Contratei o sistema, mas não sei configurar nem desenhar os processos no BPM, vocês oferecem serviços de Consultoria para implementação?",
                        es: "Contraté el sistema, pero no sé cómo configurar o diseñar los procesos en BPM, ¿ofrecen servicios de consultoría para la implementación?",
                    },
                    answer: {
                        en: "Yes, just ask the support informing that you want to hire hours for parameterization of the System and our team will guide you on step-by-step.",
                        pt: "Sim, basta solicitar ao suporte informando que deseja contratar horas para parametrização do Sistema e nossa equipe vai orientá-lo sobre o passo-a-passo.",
                        es: "Sí, solo solicite al soporte informándole que desea contratar horas para la parametrización del Sistema y nuestro equipo lo guiará paso a paso.",
                    },

                },
                {
                    isOpen: false,
                    question: {
                        en: "Can I become a partner by referring the product to my friends?",
                        pt: "Posso me tornar parceiro indicando o produto para meus amigos?",
                        es: "¿Puedo convertirme en socio remitiendo el producto a mis amigos?",
                    },
                    answer: {
                        en: "Yes, we have a type of partnership called affiliates, where you publish an exclusive URL and all sales made through the URL are counted to the affiliate you indicated and he receives a bonus.",
                        pt: "Sim, temos uma modalidade de parceria chamada afiliados, onde você divulga uma URL excusiva e todas as vendas realizadas através da URL são contabilizadas para o afiliado que indicou e ele recebe uma bonificação.",
                        es: "Sí, tenemos un tipo de asociación llamada afiliados, donde publica una URL exclusiva y todas las ventas realizadas a través de la URL se cuentan para el afiliado que indicó y él recibe un bono.",
                    },

                }
            ],            
        }
    },
    methods:{
        toggleOpen(e, index){
            e.preventDefault
            this.faq[index].isOpen = !this.faq[index].isOpen
        },

        isInterfyFamily(){
            const family = ['Interfy','Neofy','ProcessDoc','CloudDoc','Intellify','Autofy','Lawfy','Healthify','Educafy','Accountify','Realtify','DocSystem']
            return family.includes(this.domain.title)
        }
    }

}
</script>

<style scoped lang="scss">
a{
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .section-FAQ{        
        padding: 30px 10px !important;    
        
        h2{
            width: 100%;
            text-align: center;
        }
    }
}
</style>