<template>
  <div class="ct-form-checkout">

    <form id="form-addons" ref="form-account-info">

        <!-- 

          Optional Addons

          

        -->

        <div class="optional-addons" v-if="done">
          
          <template v-for="(addonsGroup, groupIndex) in renderedAddons">

            <ul class="mb-4" v-if="renderedAddons[groupIndex].length > 0" :key="groupIndex">

              <h3 class="mt-4" :class="`font-${groupIndex.toLowerCase()}`" v-if="hasAddons[groupIndex]" v-html="$t(`ADDONS_${groupIndex}`)"></h3>

              <template v-for="(addon, addon_index) in addonsGroup">

                <li v-if="showAddon(addon, groupIndex)" :key="addon_index"> 

                  <div class="row border-top-addon">
                    
                    <!--                       
                      Addon name
                    -->
                    <div class="col-7 d-flex flex-column addon-name"
                      :class="{'justify-content-start' : addon.tiered, 
                               'justify-content-center' : !addon.tiered
                              }"
                    > {{addon.name[lang]}} </div>


                    <!--                       
                      Addon Price
                    -->
                    <div class="col-5 d-flex flex-column justify-content-center addon-price">

                        <!-- 
                          No tiers
                        -->

                        <div v-if="!addon.tiered" class="d-flex flex-row justify-content-between aling-items-center">

                          <div class="d-flex flex-column justify-content-center">
                            <b class="mr-2">{{ `${user_monetary.symbol} ${addon.price[0]}${user_monetary.separators.cents}${addon.price[1]}`}}</b>
                                        
                            <small>
                              {{$t('MONTHLY_VALUE')}} 
                              <!-- {{$t('BY')}} {{ addon.min_units }} {{$t( getAddonUnit(addon, ((addon.min_units > 1) ? 'plural' : 'singular'))) }} -->
                            </small>                             
                          </div>

                          <b-form-checkbox    
                            :class="`chk_${addon_index}`"                   
                            switch 
                            size="lg" 
                            button-variant="danger"
                            @change="toggleAddon($event, addon)"
                          ></b-form-checkbox>

                        </div>

                        <!-- 
                          With tiers
                        -->
                        
                        <div v-else v-for="(tier_item, tier_index) in addon.tiers" :key="tier_index" class="d-flex flex-row justify-content-between aling-items-center">
                          
                          <div class="d-flex flex-column justify-content-center">
                            <!-- tier price -->
                            <b class="mr-2">{{ `${user_monetary.symbol} ${tier_item.total}` }}</b>
                            <small>
                              {{$t('MONTHLY_VALUE')}} 
                              

                              <!-- limited max units -->
                              <template v-if="tier_item.max && !tier_item.last_tier_with_price">
                                  {{$t('UP_TO')}} {{ tier_item.max }} {{$t( getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular'))) }}
                              </template>

                              <!-- unlimited max units (when the real last price is 0, show second to last as unlimited) -->                                
                              <template v-else-if="tier_item.max && tier_item.last_tier_with_price">                                    
                                  {{$t('UNLIMITED')}}
                              </template>

                              <!-- unlimited max units (when the real last price is not 0) -->
                              <template v-else>
                                  {{ tier_item.min }} {{$t( getAddonUnit(addon, ((tier_item.min > 1) ? 'plural' : 'singular')) )}}
                                  {{$t('OR_MORE')}}
                              </template>

                            </small>      

                          </div>

                          <b-form-checkbox                                                                                     
                            :checked="checkboxes[groupIndex][addon_index]['tiers'][tier_index].isSelected"
                            switch 
                            size="lg" 
                            button-variant="danger"
                            @change="toggleAddon($event, addon, tier_item, groupIndex, addon_index, tier_index)"
                          ></b-form-checkbox>  

                        </div>
                                              
                    </div>                   
                  </div>
                </li>              
              </template>

            </ul>
          </template>
        </div>
        
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import $ from 'jquery'
export default {  

  inject: ['getAddonUnit'],

  props:[
    'lang',
    'user_monetary',
    'selectedPlan',
    'renderedPlan',
    'renderedAddons'
  ],  

  data(){
    return {          
      checkboxes: null, 
      done: false,       
      planAddons: [],
      selectedAddons: [],
      hasAddons: {
        'GENERAL': false,
        'ECM': false,
        'BPM': false
      }
    }
  },

  computed: {
    ...mapState('MainStoreModule', {                                          
      shopping_cart: state => state.shopping_cart
    }),
  },

  watch:{
    
    selectedAddons:{
      deep: true,
      handler(selectedAddons){
        this.updateShoppingCart({'key': 'selected_addons', 'value': selectedAddons})        
      }
    }
  },

  mounted(){

    this.done = false

    for(var i in this.selectedPlan.addons){
      this.planAddons.push(this.selectedPlan.addons[i].addon_id)
    }

    this.checkboxes = {...this.renderedAddons}

    // for(var groupIndex in this.renderedAddons){
    //   var addonsGroup = this.renderedAddons[groupIndex]
    //   for(var addon_index in addonsGroup){
    //     var addon = addonsGroup[addon_index]
    //     if(addon.tiered){
    //       for(var tier_index in addon.tiers){
    //         var tier = addon.tiers[tier_index]
    //         tier.isSelected = false           
    //       }
    //     }
    //   }
    // }

    this.done = true

  },
  
  methods:{

    ...mapActions('MainStoreModule', [          
        'updateShoppingCart'
    ]),

   
    async toggleAddon(
      bool, 
      addon, 
      tier_item = null, 
      groupIndex = null, 
      addon_index = null, 
      tier_index = null
    ){

      let checkboxes = Object.assign({}, this.checkboxes)

      if(tier_item){

        addon.selectedTier = tier_item  
        
        checkboxes[groupIndex][addon_index]['tiers'][tier_index].isSelected = bool

        for(var j in checkboxes[groupIndex][addon_index]['tiers']){
          if(parseInt(j) != tier_index){            
            checkboxes[groupIndex][addon_index]['tiers'][j].isSelected = false
          }
        }

        this.checkboxes = checkboxes
      }

      for(var i in this.selectedAddons){
        if(this.selectedAddons[i]._id == addon._id){
          this.selectedAddons.splice(i, 1)        
          
          if(!tier_item || (tier_item && !bool)){
            return
          }
          
        }
      }

      this.selectedAddons.push(addon)
    },

    showAddon(addon, groupIndex){

      const show = this.planAddons.includes(addon._id) && addon.isOptional

      if(show){
        this.hasAddons[groupIndex] = true
      }

      return show
    }
  }
}
</script>

<style lang="scss" scoped>

.ct-form-checkout{    
    width: 100%;

    .row{
        margin: 0;
    }
}


.optional-addons{
  
  display: flex;
  flex-direction: column;  
  align-items: center;  
  justify-content: space-between;

  h3{
    font-size: 20px;
  }

  .border-top-addon{
    border-top: 1px solid #ccc;
  }

  ul{
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    li{             
      // background-color: rgba(49,80,230, 0.03);     
      border-radius: 10px;
      width: 100%;      
      margin: 0;
      color: #4D4D4D;
      font-size: 14px;    
      padding: 0;

      .addon-name{

      }
      .addon-price{
        small{
          font-size: 10px;          
          margin-top: -5px;
        }
      }
     
    }
  }
  
}

</style>