<template>
  <div style="width: 100%;" :key="lang">

    <div class="section section-main-partner align-items-center">                  
      <h1 class="mb-4 title" v-html="$t('PRIVACY_POLICY')"></h1>    
    </div>
  
    <div v-if="window.width > 991" style="width: 100%;" class="d-flex justify-content-end print-download">
      <a class="btn btn-blue mt-5" @click.stop="printMe()">{{$t('PDF_DOWNLOAD')}}</a>
    </div>
  
    <div class="documentation-padding mb-5">
      <div class="documentation-wrapper" v-if="show" ref="printable">
          <PrivacyPolicyPt :lang="lang" :company="company" v-if="lang == 'pt'"></PrivacyPolicyPt>
          <PrivacyPolicyEn :lang="lang" :company="company" v-else></PrivacyPolicyEn>      
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PrivacyPolicyEn from '../privacy-policy/PrivacyPolicyEn'
import PrivacyPolicyPt from '../privacy-policy/PrivacyPolicyPt'
import $ from 'jquery'


export default {    
  provide: function () {

    return {      
      scrollto: this.scrollto
    }
  },  
  props: {          
    partner_url: {
        type: String,
        default: ''
    },
    window: {
        type: Object,
        default () {
            return {
                width: 0,
                height: 0
            }
        }
    }
  }, 
  components:{
    PrivacyPolicyEn,
    PrivacyPolicyPt
  },   
  computed:{
    ...mapState('MainStoreModule', {                                    
        domain: state => state.domain
    }),

    company(){
        return {
            company_name: this.domain.company_name,
            company_fullname: this.domain.company_fullname,
            location: this.domain.location,
            address1: this.domain.address1,
            address2: this.domain.address2,
        }
    },

    locale(){
      return this.$i18n.locale
    }
  },

  
  data(){
      return{ 
        lang: 'en',
        show: false, 
        urlData: null
      }
  },
  watch:{    
    locale(locale){      
      this.lang = locale == "pt_br" ? 'pt' : locale
    }
  },
  beforeCreate(){    
    this.$emit('isPageLoaded', false)  
  },    
  mounted(){  

    this.setUrlData()

    setTimeout(() => {
        this.$emit('isPageLoaded', true)
        this.show = true
    }, 1000)
  },  
  methods:{    

    ...mapActions('MainStoreModule', [  
        'setLocale'                           
    ]),

    printMe(){
      this.$print(this.$refs.printable)
    },
    
    scrollto(elem, i) {

        elem = `${elem} .item:nth-child(${i})`
        var elemPos = $(elem).offset().top - 100

        $('html, body').animate({
            scrollTop: elemPos
        }, 500)
    },

    changeLocale(locale){                
        this.$i18n.locale = locale
        this.setLocale(locale)
        this.$moment.locale(locale)
    },
    
    setUrlData(){            
      const params = this.$route.params

      if(Object.keys(params).length){
        if(params.lang){
          this.lang = params.lang
          this.changeLocale(this.lang)
        }         
      }
      
    },

    
  }
}
</script>

<style scoped lang="scss">

 @media only screen and (max-width: 991px) {

      .section-main-partner{

        min-height: 200px;

        h1.title{
          font-size: 25px !important;
          width: 100% !important;
          text-align: center;
          padding: 20px 0 !important;
          margin: 0 !important;
        }
      }
 }
</style>