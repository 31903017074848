<template>
  <div class="start-free-checkout-page">

    <div class="start-free-checkout-wrapper">

      <h1 class='text-center font-bpm' v-html="$t('TEST_DRIVE').replace(/{var1}/g, 7)"></h1>
      <h2 class='mb-5' v-html="$t('CONGRATULATIONS_YOU_ARE').replace(/{domain}/g, domain.title)"></h2>

      <div class="form-checkout" v-if="renderedPlans.length > 0">
        <div class='wrapper-flex'>

          <!-- 
            Plan
          -->
          <div class="column">
            <div class="plan-header">
              <h2 class='m-0'>{{ renderedPlans[0].tier }}</h2>
              <img class='my-3' :src="'/assets/images/plans/' + renderedPlans[0].tier + '.png'" :alt="domain.title">
              <h2 class='m-0'>{{ renderedPlans[0].modules }}</h2>
            </div>

            <div class="plan-body mt-5">
              <ul>
                <li v-for="(item, index) in plan_features" :key="index"><i class="fas fa-check mr-2"></i>{{ item[lang]
                  }}
                </li>
              </ul>
            </div>
          </div>


          <!-- 
            Form
          -->
          <div class="column">

            <b-overlay :show="isLoading || isSubmitting" rounded="sm">
              <form id="formStartFreeCheckout" ref="formStartFreeCheckout" @submit.stop.prevent="beforeSubmit">

                <!-- 
                  Workspace name
                -->
                <div class="row">
                  <div class="col">
                    <b-form-group class="m-0" :description="''" :label="$t('WORKSPACE_NAME')" label-for="workspace_name"
                      :invalid-feedback="error_workspace_name" :state="state.workspace_name">
                      <div class="workspace-detail row m-0">
                        <div class="col-lg-10 col-md-10 col-sm-12 d-flex align-items-center px-0">
                          <b-form-input id="workspace_name" :state="state.workspace_name"
                            v-model="form.account.workspace_name" trim required></b-form-input>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center pl-0 pl-2">.{{ domain.location
                          }}
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <!--
                      email
                    -->
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <b-form-group class="m-0" :description="''" :label="$t('E-mail')" label-for="email">
                      <b-form-input :disabled="pre_customer !== null" type="email" id="email" :state="state.email"
                        v-model="form.account.email" trim required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <!--
                    tax_id
                  -->
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <b-form-group class="m-0" :description="''" :label="company_identification.title" label-for="tax_id"
                      :state="state.tax_id"
                      :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', company_identification.title)">
                      <b-form-input :disabled="!form.payment.country" v-mask="company_identification.id_mask"
                        id="tax_id" v-model="form.payment.tax_id" trim required></b-form-input>
                    </b-form-group>
                  </div>
                  <!--
                    trading_name
                  -->
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <b-form-group class="m-0" :description="''" :label="$t('trading_name')" label-for="trading_name"
                      :state="state.trading_name"
                      :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('trading_name'))">
                      <b-form-input id="trading_name" v-model="form.trading_name" trim required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <!--
                        password
                    -->
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <b-form-group class="m-0" :description="''" :label="$t('password')" label-for="password"
                      :invalid-feedback="error_password" :state="state.password">
                      <b-form-input id="password" type="password" :state="state.password"
                        v-model="form.account.password" trim required></b-form-input>
                    </b-form-group>
                  </div>
                  <!--
                        password
                    -->
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <b-form-group class="m-0" :description="''" :label="$t('rep_password')" label-for="rep_password">
                      <b-form-input id="rep_password" type="password" :state="state.password"
                        v-model="form.account.rep_password" trim required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <!-- 
                    Google Recaptcha + Submit
                -->
                <div class="row">
                  <div class="p-0 col div-recaptcha mt-3 text-right">

                    <vue-recaptcha ref="recaptcha" @verify="recaptchaOnVerify" @expired="recaptchaOnExpired"
                      :sitekey="domain.googlerecaptcha_key" :loadRecaptchaScript="true">
                    </vue-recaptcha>

                  </div>
                </div>

              </form>

            </b-overlay>

          </div>

        </div>

        <div class='btn-wrapper'>
          <button v-if="!isSubmitting" class='btn btn-success btn-medium-width align-self-end'
            @click.stop="beforeSubmit">{{ $t('START_NOW') }}</button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CheckoutMixin from '../../mixins/CheckoutMixin'
import PlansMixin from '../../mixins/PlansMixin'
import RecaptchaMixin from '../../mixins/RecaptchaMixin'

export default {
  mixins: [RecaptchaMixin, CheckoutMixin, PlansMixin],

  computed: {
    ...mapState('MainStoreModule', {
      user_monetary: state => state.user_monetary
    }),

    lang() {
      return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
    },

    plan_features() {
      return [
        { "pt": "5 usuários", "en": "5 users", "es": "5 usuarios" },
        { "pt": "Upload de Arquivos", "en": "Files Upload", "es": "Carga de archivos" },
        { "pt": "Usuário de Captura/Scanner", "en": "Capture/Scanner user", "es": "Usuario de captura / escáner" },
        { "pt": "Busca por OCR Full Text", "en": "OCR Full Text Search", "es": "Búsqueda de texto completo de OCR" },
        { "pt": "Integração com Banco de Dados", "en": "Database Integration", "es": "Integración de bases de datos" },
        { "pt": "Suporte com SLA de 4 horas", "en": "4-hour SLA Support", "es": "Soporte SLA de 4 horas" }
      ]
    }
  },

  data() {
    return {
      brLocaleValidation: null,
      enLocaleValidation: null,

      company_identification: {
        title: this.$t('company_identification'),
        id_mask: "##########################",
        zipcode_mask: "############"
      },

      isLoading: false,
      error_workspace_name: null,
      error_password: null,

      form: {

        payment: {
          billing_method: "",
          token: "",
          tax_id: "",
          trading_name: "",
          zip_code: "",
          address_line1: "",
          address_line2: "",
          county: "",
          city: "",
          province: "",
          country: "",
        },

        account: {
          workspace_name: "",
          password: "",
          rep_password: "",
          email: "",
          name: "",
          customer_phone: ""
        }

      },
      state: {
        workspace_name: null,
        password: this.checkPassword,
        rep_password: this.checkPassword,
        email: null,
        country: null,
        trading_name: null,
        tax_id: null
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler(form) {
        this.updateShoppingCart({ 'key': 'account', 'value': form.account })
        this.updateShoppingCart({ 'key': 'payment', 'value': form.payment })


        /*
        
          Selecting the first tier price

        */

        var units = parseInt(this.selectedPlan.users_minimum)

        var currentPrice

        this.selectedPlan.prices.forEach(price => {
          if (price.currency == this.user_monetary.currency_code &&
            price.recurrence == 'month' && price.trial_days > 0
          ) {
            currentPrice = price
            return false
          }
        });

        this.updateShoppingCart({ 'key': 'selected_plan', 'value': { 'plan_price': currentPrice, 'unit_amount': units, 'renderedPlan': this.renderedPlan, 'plan': this.selectedPlan } })
      }
    }
  },

  methods: {
    ...mapActions('MainStoreModule', [
      'updateShoppingCart'
    ]),

    setCompanyIdentification() {
      var identification = {},
        country_code = this.form.payment.country ? this.form.payment.country : null

      if (country_code == 'MOZ') {

        identification = {
          title: "NUIT",
          id_mask: "#########",
          zipcode_mask: "####"
        }

      } else if (country_code == 'BRA') {

        identification = {
          title: "CNPJ",
          id_mask: "##.###.###/####-##",
          zipcode_mask: "#####-###"
        }

      } else if (country_code == 'USA') {

        identification = {
          title: "EIN",
          id_mask: "##-#######",
          zipcode_mask: "#####"
        }

      } else {

        identification = {
          title: this.$t('company_identification'),
          id_mask: "##########################",
          zipcode_mask: "##########"
        }
      }

      return this.company_identification = identification
    },


    beforeSubmit() {
      this.state.workspace_name = this.isValidWorkspaceName(this.form.account.workspace_name)
      this.state.password = this.isValidPassword(this.form.account.password, this.form.account.rep_password)

      if (this.state.workspace_name && this.state.password)
        this.handleSubmit()
    },



    hasSpecialChars(str) {
      return !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(str)
    },

    isAlphaNumeric(str) {

      const hasLetters = !/^[A-Za-z]+$/g.test(str),
        hasNumbers = !/^[0-9]+$/g.test(str)

      return hasLetters && hasNumbers
    },

    isValidPassword(password, rep_password) {

      if (
        password.length < 8 ||
        !this.hasSpecialChars(password) ||
        !this.isAlphaNumeric(password)
      ) {

        if (password.length == 0) {
          this.error_password = this.$t('FIELD_IS_REQUIRED').replace('{var1}', this.$t('password'))
        } else {
          this.error_password = this.$t('INVALID_PASSWORD')
        }

        return false

      }

      if (password != rep_password) {
        this.error_password = this.$t('NOT_EQUAL_PASSWORDS')
        return false
      }

      return true
    },

    isValidWorkspaceName(str) {

      if (
        str.length < 3 ||
        str.includes(" ") ||
        str.includes("__") ||
        str.includes("--") ||
        str.includes("_-") ||
        str.includes("-_") ||
        !this.hasSpecialChars(str) ||
        (str[0] == "-" || str[0] == "_") ||
        (str.substr(str.length - 1) == "-" || str.substr(str.length - 1) == "_")
      ) {

        if (str.length == 0) {
          this.error_workspace_name = this.$t('FIELD_IS_REQUIRED').replace('{var1}', this.$t('WORKSPACE_NAME'))
        } else {
          this.error_workspace_name = this.$t('INVALID_WORKSPACE_NAME')
        }

        return false
      }

      return true

    },

  },

  beforeCreate() {
    this.$emit('isPageLoaded', false)
  },

  async created() {
    if (!this.user_monetary)
      await this.setUserMonetaryByLocation()

    await this.extractUrlData()

    if (!this.api.token) {
      this.isLoading = true
      await this.setApiToken()
    }

    if (this.api.token) {
      await this.getCheckoutData('start-free-checkout')
    }

    setTimeout(() => {
      this.form.payment.trading_name = this.pre_customer.company_name
      this.form.payment.billing_method = 2
      this.form.payment.address_line1 = this.pre_customer.address_line1
      this.form.payment.address_line2 = this.pre_customer.address_line2
      this.form.payment.city = this.pre_customer.city
      this.form.payment.province = this.pre_customer.province
      this.form.payment.country = this.pre_customer.country
      this.form.payment.zip_code = this.pre_customer.zip_code

      this.form.account.name = this.pre_customer.name
      this.form.account.email = this.pre_customer.email
      this.form.account.customer_phone = this.pre_customer.customer_phone

      this.setCompanyIdentification()
    }, 1000);

  },

  mounted() {
    setTimeout(() => {
      this.$emit('isPageLoaded', true)
      this.show = true
    }, 1000);
  }
}
</script>


<style lang="scss" scoped>
.start-free-checkout-page {
  background-color: #EFF3FE;
  padding-left: 80px 0;
  min-height: 500px;

  .start-free-checkout-wrapper {
    padding-top: 30px;

    h1 {
      color: #2C2FFA;
      text-align: center;
    }

    h2 {
      color: #000;
      text-align: center;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-checkout {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .btn-wrapper {
        width: 100%;
        padding: 30px 20px !important;
        display: flex;
        justify-content: flex-end;
      }

      .wrapper-flex {

        display: flex;
        flex-direction: flex-wrap;
        align-items: stretch;
        justify-content: center;
        width: 100%;

        .column {

          margin: 0 20px;
          padding: 30px;

          background-color: #fff;
          border-radius: 10px;
          -moz-box-shadow: 10px 10px 10px -12px #ccc;
          -webkit-box-shadow: 10px 10px 10px -12px #ccc;
          box-shadow: 10px 10px 10px -12px #ccc;

          &:nth-child(1) {
            width: 50%;
            min-width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          &:nth-child(2) {
            width: 100%;
            min-width: 600px;
          }

          .plan-header {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
              color: rgba(0, 0, 0, 0.8) !important;
              font-weight: 700;
            }

            img {
              width: 200px;
            }

          }

          .plan-body {

            ul {
              list-style-type: none;

              li {
                margin-bottom: 5px;
                font-weight: 700;
                font-size: 18px;

                i {
                  color: #2C2FFA;
                }
              }
            }

          }
        }


      }


    }
  }
}


@media only screen and (max-width: 991px) {

  .start-free-checkout-page {

    .row {
      margin: 0 !important;
    }

    padding: 80px 0 30px 10px !important;
    margin: 0 !important;

    .start-free-checkout-wrapper {
      padding: 0 !important;
      margin: 0 !important;

      h1 {
        margin: 20px 0 20px 0 !important;
        padding: 0 !important;
        width: 100% !important;
        text-align: center;
      }

      h2 {
        margin: 0 0 30px 0 !important;
        padding: 0 !important;
        width: 100% !important;
        text-align: center;
      }

      .form-checkout {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;

        .wrapper-flex {
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
          flex-direction: column !important;

          .column {
            width: 100% !important;
            min-width: auto !important;
            padding: 20px !important;
            margin: 0 0 20px 0 !important;

            .plan-header {
              img {
                display: none;
              }

              h2 {
                font-size: 25px !important;
                margin: 0 0 10px 0 !important;
              }
            }

            .plan-body {
              margin-top: 30px !important;

              li {
                font-size: 16px !important;
              }
            }

            .workspace-detail {
              div {
                &:first-child {
                  margin-bottom: -20px !important;
                }

                &:nth-child(2) {
                  justify-content: flex-end !important;
                }
              }
            }

            .div-recaptcha {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 15px 0 !important;
            }


          }

        }

      }

    }

    .btn {
      margin: 0 !important;
      align-self: center !important;
    }

  }


}
</style>