<template>
  <div style="width: 100%" :key="lang">
    <div
      class="section section-main-partner align-items-center"
    >
      <h1 class="mb-4 title" v-html="$t('PARTNERSHIP_AGREEMENT')"></h1>
    </div>

    <div
      v-if="window.width > 991"
      style="width: 100%"
      class="d-flex justify-content-end print-download"
    >
      <a class="btn btn-blue mt-5" @click.stop="printMe()">{{
        $t("PDF_DOWNLOAD")
      }}</a>
    </div>

    <div class="documentation-padding mb-5">
      <div
        id="documentation-wrapper"
        class="documentation-wrapper"
        v-if="show"
        ref="printable"
      >
        <AgreementContentPt
          class="mb-5"
          v-if="lang == 'pt'"
          :business_partner="business_partner"
        />
        <AgreementContentEn
          class="mb-5"
          v-else
          :business_partner="business_partner"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AgreementContentPt from "../become-a-partner/AgreementContentPt";
import AgreementContentEn from "../become-a-partner/AgreementContentEn";
import $ from "jquery";
export default {
  provide: function () {
    return {
      scrollto: this.scrollto,
    };
  },
  props: {
    partner_url: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },
  components: {
    AgreementContentPt,
    AgreementContentEn,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  data() {
    return {
      lang: "en",
      show: false,
      urlData: null,
      pre_partner_temp_data: {},
      business_partner: {},
    };
  },
  watch: {
    locale(locale) {
      this.lang = locale == "pt_br" ? "pt" : locale;
      this.setBusinessPartner();
    },
  },
  beforeCreate() {
    this.$emit("isPageLoaded", false);
  },
  mounted() {
    this.setUrlData();

    setTimeout(() => {
      this.setBusinessPartner();
    }, 500);

    setTimeout(() => {
      this.$emit("isPageLoaded", true);
      this.show = true;
    }, 1000);
  },
  methods: {
    ...mapActions("MainStoreModule", ["setLocale"]),

    scrollto(elem, i) {
      elem = `${elem} .item:nth-child(${i})`;
      var elemPos = $(elem).offset().top - 100;

      $("html, body").animate(
        {
          scrollTop: elemPos,
        },
        500
      );
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.setLocale(locale);
      this.$moment.locale(locale);
    },

    async printMe() {
      console.log('printing1')
      await this.$htmlToPaper('documentation-wrapper');
      // this.$print(this.$refs.printable);
    },

    setUrlData() {
      const params = this.$route.params;

      if (Object.keys(params).length) {
        if (params.lang) {
          this.lang = params.lang;
          this.changeLocale(this.lang);
        }

        if (params.data) {
          this.urlData = JSON.parse(atob(params.data));
        }
      }
    },

    setBusinessPartner() {
      var data = {};

      if (this.urlData) {
        data = this.urlData;
      } else {
        this.pre_partner_temp_data = JSON.parse(
          localStorage.getItem("pre_partner_temp_data")
        );
        data = this.pre_partner_temp_data;
      }

      const name =
          data && data.company && $.trim(data.company.company_name)
            ? data.company.company_name
            : this.$t("BUSINESS_PARTNER"),
        email =
          data && data.personal && $.trim(data.personal.email)
            ? data.personal.email
            : "business@partner.com";

      this.business_partner = {
        name: name,
        email: email,
      };

      if (data && data.company) {
        this.business_partner.address = this.getAddress(data);
        this.business_partner.country = this.getCountry(data);
      }
    },

    getAddress(data) {
      var address = [
        `${data.company.address || this.$t("ADDRESS")}, ${
          data.company.number || this.$t("NUMBER")
        } ${data.company.complement || this.$t("COMPLEMENT")}`,
        `${data.company.county_region || this.$t("COUNTY_REGION")}, ${
          data.company.city || this.$t("CITY")
        }, ${data.company.state || this.$t("STATE")}`,
        `${data.company.zipcode || this.$t("ZIPCODE")} - ${
          data.company.country || this.$t("COUNTRY")
        }`,
      ];

      return address.join("<br>");
    },

    getCountry(data) {
      return `${data.company.country || this.$t("COUNTRY")}`;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 991px) {
  .section-main-partner {
    min-height: 200px;

    h1.title {
      font-size: 25px !important;
      width: 100% !important;
      text-align: center;
      padding: 20px 0 !important;
      margin: 0 !important;
    }
  }
}
</style>