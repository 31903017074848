<template>
  <transition name="slide-fade">
    <!-- 
            All what...
        -->
    <div class="section section-your-business">
      <h2>Everything your business needs in one place!</h2>
      <p>Interfy is a Corporate Digital Platform focused on Content Management and Process Automation</p>

      <div class="tabs-component">
        <div class="tabs">
          <button :class="{ 'active-tab': activeTab === 'tab1' }" @click="setActiveTab('tab1')">
            ECM
          </button>
          <button :class="{ 'active-tab': activeTab === 'tab2' }" @click="setActiveTab('tab2')">
            BPM
          </button>
          <button :class="{ 'active-tab': activeTab === 'tab3' }" @click="setActiveTab('tab3')">
            SWC
          </button>
          <button :class="{ 'active-tab': activeTab === 'tab4' }" @click="setActiveTab('tab4')">
            DSS
          </button>
          <button :class="{ 'active-tab': activeTab === 'tab5' }" @click="setActiveTab('tab5')">
            LVS
          </button>
          <button :class="{ 'active-tab': activeTab === 'tab6' }" @click="setActiveTab('tab6')">
            CIC
          </button>
        </div>

        <div v-bind:class="['tab-content', activeTabClass]" :style="{ backgroundColor: getBackgroundColor() }">
          <div class="ecm" v-if="activeTab === 'tab1'">
            <div class="flex">
              <div class="item">
                <h2>ENTERPRISE CONTENT MANAGEMENT</h2>
                <p>Transform your company into a digital workplace. Every company needs to deal with a series of
                  documents of different natures and for different purposes.</p>
                <a href="#" class="btn" v-html="$t('SEE_MORE')"></a>
                <a href="https://interfy.io/start-free" class="btn" v-html="$t('START_FREE')"></a>
              </div>
              <div class="item">
                <img src="/assets/images/home/business/ecm.png" alt="ecm">
                <a target="_blank"
                  href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">
                  <i class="ico-play"></i>
                </a>
              </div>

              <div class="bottom"><i class="down"></i></div>
            </div>

            <div class="content-carousel-business">
              <h3>What does ECM offer you?</h3>
              <!-- Slider business -->
              <carousel :per-page="1" :scroll-per-page="true" :navigation-enabled="true" :pagination-enabled="true"
                :loop="true" @page-change="handlePageChange">
                <slide v-for="(slide, index) in tab2Slides" :key="index"
                  :class="{ 'second-active': isCentralSlide(index) }">
                  <div class="slide-content">
                    <div class="box-image-carousel">
                      <img :src="slide.image" alt="Image" class="carousel-image" />
                    </div>
                    <p class="slide-text">{{ slide.text }}</p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <div class="bpm" v-if="activeTab === 'tab2'">
            <div class="flex">
              <div class="item">
                <h2>ENTERPRISE CONTENT MANAGEMENT</h2>
                <p>Transform your company into a digital workplace. Every company needs to deal with a series of
                  documents of different natures and for different purposes.</p>
                <a href="#" class="btn" v-html="$t('SEE_MORE')"></a>
                <a href="https://interfy.io/start-free" class="btn" v-html="$t('START_FREE')"></a>
              </div>
              <div class="item">
                <img src="/assets/images/home/business/ecm.png" alt="ecm">
                <a target="_blank"
                  href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">
                  <i class="ico-play"></i>
                </a>
              </div>

              <div class="bottom"><i class="down"></i></div>
            </div>

            <div class="content-carousel-business">
              <h3>What does BPM offer you?</h3>
              <!-- Slider business -->
              <carousel :per-page="1" :scroll-per-page="true" :navigation-enabled="true" :pagination-enabled="true"
                :loop="true" @page-change="handlePageChange">
                <slide v-for="(slide, index) in tab2Slides" :key="index"
                  :class="{ 'second-active': isCentralSlide(index) }">
                  <div class="slide-content">
                    <div class="box-image-carousel">
                      <img :src="slide.image" alt="Image" class="carousel-image" />
                    </div>
                    <p class="slide-text">{{ slide.text }}</p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <div class="swc" v-if="activeTab === 'tab3'">
            <div class="flex">
              <div class="item">
                <h2>ENTERPRISE CONTENT MANAGEMENT</h2>
                <p>Transform your company into a digital workplace. Every company needs to deal with a series of
                  documents of different natures and for different purposes.</p>
                <a href="#" class="btn" v-html="$t('SEE_MORE')"></a>
                <a href="https://interfy.io/start-free" class="btn" v-html="$t('START_FREE')"></a>
              </div>
              <div class="item">
                <img src="/assets/images/home/business/ecm.png" alt="ecm">
                <a target="_blank"
                  href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">
                  <i class="ico-play"></i>
                </a>
              </div>

              <div class="bottom"><i class="down"></i></div>
            </div>

            <div class="content-carousel-business">
              <h3>What does SWC offer you?</h3>
              <!-- Slider business -->
              <carousel :per-page="1" :scroll-per-page="true" :navigation-enabled="true" :pagination-enabled="true"
                :loop="true" @page-change="handlePageChange">
                <slide v-for="(slide, index) in tab2Slides" :key="index"
                  :class="{ 'second-active': isCentralSlide(index) }">
                  <div class="slide-content">
                    <div class="box-image-carousel">
                      <img :src="slide.image" alt="Image" class="carousel-image" />
                    </div>
                    <p class="slide-text">{{ slide.text }}</p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <div class="dss" v-if="activeTab === 'tab4'">
            <div class="flex">
              <div class="item">
                <h2>ENTERPRISE CONTENT MANAGEMENT</h2>
                <p>Transform your company into a digital workplace. Every company needs to deal with a series of
                  documents of different natures and for different purposes.</p>
                <a href="#" class="btn" v-html="$t('SEE_MORE')"></a>
                <a href="https://interfy.io/start-free" class="btn" v-html="$t('START_FREE')"></a>
              </div>
              <div class="item">
                <img src="/assets/images/home/business/ecm.png" alt="ecm">
                <a target="_blank"
                  href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">
                  <i class="ico-play"></i>
                </a>
              </div>

              <div class="bottom"><i class="down"></i></div>
            </div>

            <div class="content-carousel-business">
              <h3>What does DSS offer you?</h3>
              <!-- Slider business -->
              <carousel :per-page="1" :scroll-per-page="true" :navigation-enabled="true" :pagination-enabled="true"
                :loop="true" @page-change="handlePageChange">
                <slide v-for="(slide, index) in tab2Slides" :key="index"
                  :class="{ 'second-active': isCentralSlide(index) }">
                  <div class="slide-content">
                    <div class="box-image-carousel">
                      <img :src="slide.image" alt="Image" class="carousel-image" />
                    </div>
                    <p class="slide-text">{{ slide.text }}</p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <div class="lvs" v-if="activeTab === 'tab5'">
            <div class="flex">
              <div class="item">
                <h2>ENTERPRISE CONTENT MANAGEMENT</h2>
                <p>Transform your company into a digital workplace. Every company needs to deal with a series of
                  documents of different natures and for different purposes.</p>
                <a href="#" class="btn" v-html="$t('SEE_MORE')"></a>
                <a href="https://interfy.io/start-free" class="btn" v-html="$t('START_FREE')"></a>
              </div>
              <div class="item">
                <img src="/assets/images/home/business/ecm.png" alt="ecm">
                <a target="_blank"
                  href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">
                  <i class="ico-play"></i>
                </a>
              </div>

              <div class="bottom"><i class="down"></i></div>
            </div>

            <div class="content-carousel-business">
              <h3>What does LVS offer you?</h3>
              <!-- Slider business -->
              <carousel :per-page="1" :scroll-per-page="true" :navigation-enabled="true" :pagination-enabled="true"
                :loop="true" @page-change="handlePageChange">
                <slide v-for="(slide, index) in tab2Slides" :key="index"
                  :class="{ 'second-active': isCentralSlide(index) }">
                  <div class="slide-content">
                    <div class="box-image-carousel">
                      <img :src="slide.image" alt="Image" class="carousel-image" />
                    </div>
                    <p class="slide-text">{{ slide.text }}</p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <div class="cic" v-if="activeTab === 'tab6'">
            <div class="flex">
              <div class="item">
                <h2>ENTERPRISE CONTENT MANAGEMENT</h2>
                <p>Transform your company into a digital workplace. Every company needs to deal with a series of
                  documents of different natures and for different purposes.</p>
                <a href="#" class="btn" v-html="$t('SEE_MORE')"></a>
                <a href="https://interfy.io/start-free" class="btn" v-html="$t('START_FREE')"></a>
              </div>
              <div class="item">
                <img src="/assets/images/home/business/ecm.png" alt="ecm">
                <a target="_blank"
                  href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">
                  <i class="ico-play"></i>
                </a>
              </div>

              <div class="bottom"><i class="down"></i></div>
            </div>

            <div class="content-carousel-business">
              <h3>What does CIC offer you?</h3>
              <!-- Slider business -->
              <carousel :per-page="1" :scroll-per-page="true" :navigation-enabled="true" :pagination-enabled="true"
                :loop="true" @page-change="handlePageChange">
                <slide v-for="(slide, index) in tab2Slides" :key="index"
                  :class="{ 'second-active': isCentralSlide(index) }">
                  <div class="slide-content">
                    <div class="box-image-carousel">
                      <img :src="slide.image" alt="Image" class="carousel-image" />
                    </div>
                    <p class="slide-text">{{ slide.text }}</p>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    isDomainPermitted: {
      type: Boolean,
      default: false,
    },
    partner_url: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },
  data() {
    return {
      show: false,
      slide: [],
      responsiveOpts: {},
      modules: [],

      activeTab: 'tab1',
      activeTabInvt: 'tab1',
      activePage: 0,
      tab1Slides: [
        { image: '/assets/images/home/business/1.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/2.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/3.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/4.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/5.svg', text: 'Simplified document scanning and uploading' }
      ],
      tab2Slides: [
        { image: '/assets/images/home/business/1.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/2.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/3.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/4.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/5.svg', text: 'Simplified document scanning and uploading' }
      ],
      tab3Slides: [
        { image: '/assets/images/home/business/1.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/2.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/3.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/4.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/5.svg', text: 'Simplified document scanning and uploading' }
      ],
      tab4Slides: [
        { image: '/assets/images/home/business/1.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/2.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/3.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/4.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/5.svg', text: 'Simplified document scanning and uploading' }
      ],
      tab5Slides: [
        { image: '/assets/images/home/business/1.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/2.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/3.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/4.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/5.svg', text: 'Simplified document scanning and uploading' }
      ],
      tab6Slides: [
        { image: '/assets/images/home/business/1.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/2.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/3.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/4.svg', text: 'Simplified document scanning and uploading' },
        { image: '/assets/images/home/business/5.svg', text: 'Simplified document scanning and uploading' }
      ],
    };
  },
  computed: {
    activeTabClass() {
      return `${this.activeTab}-active`;
    },
  },

  watch: {

  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.activePage = 0;  // Reset activePage when tab changes
    },

    handlePageChange(page) {
      this.activePage = page;
    },

    isCentralSlide(index) {
      // Adjusting the central index based on the number of slides and active page
      const totalSlides = this[`tab${this.activeTab.slice(-1)}Slides`].length;
      return index === (this.activePage + Math.floor(totalSlides / 2)) % totalSlides;
    },

    getBackgroundColor() {
      switch (this.activeTab) {
        case 'tab1':
          return '#00A800';
        case 'tab2':
          return '#2C2FFA';
        case 'tab3':
          return '#E82F8A';
        case 'tab4':
          return '#962DFB';
        case 'tab5':
          return '#80BA27';
        case 'tab6':
          return '#DEC902';
        default:
          return '#ffffff';
      }
    },
  },
};
</script>

<style lang="scss">
//BUSINESS
.section-your-business {
  padding-top: 45px;

  &>h2 {
    color: #333333;
    text-align: center;
    font-size: 30px !important;
  }

  p {
    font-size: 16px !important;
    font-weight: 200;
    color: #333333;
    text-align: center;
  }
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  max-width: 905px;
  margin: 0 auto;
}

.tabs button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  border-radius: 3px 3px 0px 0px;
  width: 150px;
  border-bottom: 3px solid transparent;
  font-weight: bold;
  color: #777777;
}

.tabs button.active-tab:nth-child(1) {
  border-bottom: 3px solid #00A800;
  color: #00A800;
}

.tabs button.active-tab:nth-child(2) {
  border-bottom: 3px solid #2C2FFA;
  color: #2C2FFA;
}

.tabs button.active-tab:nth-child(3) {
  border-bottom: 3px solid #E82F8A;
  color: #E82F8A;
}

.tabs button.active-tab:nth-child(4) {
  border-bottom: 3px solid #962DFB;
  color: #962DFB;
}

.tabs button.active-tab:nth-child(5) {
  border-bottom: 3px solid #80BA27;
}

.tabs button.active-tab:nth-child(6) {
  border-bottom: 3px solid #DEC902;
}

.tabs-component .tab-content {
  padding: 20px 0 0;
  color: white;
  text-align: center;
  border-radius: 5px;
}

.tabs-component .tab-content .flex {
  display: flex;
  max-width: 1280px;
  padding: 0;
  margin: 0 auto 25px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.tabs-component .tab-content .flex .item {
  position: relative;
}

.tabs-component .tab-content .flex .item .ico-play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  margin: -37.5px 0 0 -37.5px;
  background: url('../../../public/assets/images/home/play.png') no-repeat center;
  opacity: .5;
  transition: all ease .2s;
}

.tabs-component .tab-content .flex .item .ico-play:hover {
  opacity: 1;
  transform: scale(1.2);
}

.tabs-component .tab-content.tab2-active .flex .bottom {
  background: rgb(44, 47, 250);
}

.tabs-component .tab-content.tab3-active .flex .bottom {
  background: rgb(232, 47, 138);
}

.tabs-component .tab-content.tab4-active .flex .bottom {
  background: rgb(150, 45, 251);
}

.tabs-component .tab-content.tab5-active .flex .bottom {
  background-color: rgb(128, 186, 39);
}

.tabs-component .tab-content.tab6-active .flex .bottom {
  background-color: rgb(222, 201, 2);
}

.tabs-component .tab-content .flex .bottom {
  position: absolute;
  width: 160px;
  bottom: -43px;
  background: #00A800;
  border-radius: 0px 0px 75px 75px;
  left: 50%;
  margin-left: -97.5px;
  z-index: 0;
  padding: 40px 15px;
  z-index: -1;
}

.tabs-component .tab-content .flex .bottom:after,
.tabs-component .tab-content .flex .bottom:before {
  top: 60%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tabs-component .tab-content .flex .bottom:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.tabs-component .tab-content .flex .bottom:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #fff;
  border-width: 16px;
  margin-left: -16px;
}

.tabs-component .tab-content .flex .item:first-child {
  text-align: left;
}

.tabs-component .tab-content .flex .item h2 {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-align: left;
}

.tabs-component .tab-content .flex .item p {
  font-size: 16px;
  color: #fff;
  text-align: left;
}

.tabs-component .tab-content .flex .item .btn {
  width: 175px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: inline-block !important;
  vertical-align: middle;
  padding: 15px !important;
  margin: 0 10px 0 0;
}

.tabs-component .tab-content .ecm .flex .item .btn {
  color: #00A800 !important;
}

.tabs-component .tab-content .bpm .flex .item .btn {
  color: #2C2FFA !important;
}

.tabs-component .tab-content .swc .flex .item .btn {
  color: #E82F8A !important;
}

.tabs-component .tab-content .dss .flex .item .btn {
  color: #962DFB !important;
}

.tabs-component .tab-content .lvs .flex .item .btn {
  color: #80BA27 !important;
}

.tabs-component .tab-content .cic .flex .item .btn {
  color: #DEC902 !important;
}

.content-carousel-business {
  background: #fff;
  padding-top: 65px;
}

.content-carousel-business .slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.content-carousel-business .slider {
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content-carousel-business .slide {
  flex: 0 0 10%;
  width: 300px;
  margin: 0;
  transform-origin: center;
  text-align: center;
}

.content-carousel-business .slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.content-carousel-business .slide-text {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
}

.content-carousel-business .dots {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.content-carousel-business .dots span {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.content-carousel-business .dots span.active-dot {
  background-color: #007bff;
}

.content-carousel-business .slide.active-slide {
  transform: scale(1);
  opacity: 1;
}

.content-carousel-business .owl-theme .owl-nav {
  display: none;
}

.content-carousel-business .slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.content-carousel-business .slide-text {
  max-width: 288px;
  margin: 10px auto;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.content-carousel-business .dots {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.content-carousel-business .dots span {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.content-carousel-business .dots span.active-dot {
  background-color: #007bff;
}

.content-carousel-business h3 {
  font-size: 32px !important;
  color: #333333;
  font-weight: bold;
  margin: 10px auto 20px;
}

.content-carousel-business .active:nth-child(2) {
  border: 3px solid #00A800;
}

.content-carousel-business .box-image-carousel {
  background: #2F2F2F;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-carousel-business .box-image-carousel img {
  margin: auto;
  width: auto;
}

.content-carousel-business p {
  font-size: 18px;
  color: #333333;
  text-align: center;
}
</style>