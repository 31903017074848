<template>
    <div style="min-height: 100vh;">

        <transition name="slide-fade">

            <div>

                <div class="section section-main-module">
                    <div class="main-background"></div>

                    <h1 class="mb-4 title" v-html="$t('DAS').replace(/<br>/g, '')"></h1>
                    <h2 class="subtitle" v-html="$t('DAS_SUBTITLE').replace(/<br>/g, '')"></h2>

                    <div class="div-buttons base-padding d-flex flex-row justify-content-center align-items-center">
                        <a class="btn btn-1 mx-3" @click.stop="scrollto('#plans')">{{ $t('LEARN_MORE') }}</a>
                        <router-link
                            v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')"
                            class="btn btn-2 mx-3"
                            :to="`/become-a-partner${partner_url}`">{{ $t('BECOME_A_PARTNER') }}</router-link>
                    </div>

                    <div class="mockup">
                        <img class="base" :src="`/assets/images/das/mockup-01.png`"
                            :alt="$t('DAS') + ' - ' + $t('DAS_SUBTITLE')">
                    </div>

                </div>

                <Clients></Clients>
                <!-- 01 -->
                <div
                    class="section base-padding section-das-01 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="mb-4 text-center font-ecm">A melhor plataforma para instituições de ensino</h1>
                    <h2 class="text-center">Módulo de captura de alta performance, que permite a digitalização rápida do
                        acervo, inclusive offline, reduzindo o risco de não atender ao prazo legal.<br>ECM para a gestão
                        de todo o conteúdo e o BPM para automatização de processos</h2>
                </div>

                <!-- 02 -->
                <div
                    class="section base-padding section-das-02 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="mb-4 text-center font-ecm">Sistema preparado para atender a Portaria 315 do Ministério da
                        Educação (MEC)</h1>

                    <div class="mt-2 d-flex flex-column justify-content-start align-items-center">

                        <h2 class="text-center">Segundo a Portaria 315 do Ministério da Educação (MEC), as instituições
                            de ensino superior brasileiras, tem até abril de 2020 para digitalizar todo seu acervo
                            documental.</h2>

                        <p>
                            <br><br>Para garantir o atendimento às regras toda instituição precisa contratar um software
                            que deve permitir ao menos o cumprimento dos seguintes itens:
                            <br>I - capacidade de utilizar e gerenciar base de dados adequada para a preservação do
                            acervo acadêmico digital;
                            <br>II - forma de indexação que permita a pronta recuperação do acervo acadêmico digital;
                            <br>III - método de reprodução do acervo acadêmico digital que garanta a sua segurança e
                            preservação;
                            <br>IV - utilização de certificação digital padrão ICP-Brasil, conforme disciplinada em lei,
                            pelos responsáveis pela mantenedora e sua mantida, para garantir a autenticidade, a
                            integridade e a validade jurídica do acervo.
                        </p>

                    </div>
                </div>


                <div class="section section-bpm-features base-padding-md">
                    <h1 class="mb-4 text-center">Principais benefícios da Secretaria Acadêmica Digital</h1>
                    <h2 class="mb-5">Administração completa do software com possibilidade de definição de perfis de
                        acesso para controle e segurança dos dados e documentos.</h2>

                    <!-- 
                    Desktop version
                -->
                    <div v-if="window.width > 991" class="accordion">
                        <ul>
                            <li v-for="(item, index) in features_das_accordion" :key="index">
                                <div class="div-icon d-flex align-items-center justify-content-center">
                                    <i :class="item.icon"></i>
                                </div>
                                <div class="div-text">
                                    <p v-if="window.width > 991" class="text-center">
                                        {{ item.description[lang].replace(/{domain}/g, domain.title) }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- 
                    Mobile version
                -->
                    <div class="carousel-wrapper" v-else>
                        <Carousel class="mod-carousel" :margin="0" :autoplay="true" :dots="false" :nav="false"
                            :responsive="{
                                0: {
                                    items: 1
                                }
                            }">
                            <div class="item" v-for="(item, index) in features_das_accordion" :key="index">
                                <div class="div-icon d-flex align-items-center justify-content-center">
                                    <i :class="item.icon"></i>
                                </div>
                                <div class="div-text">
                                    <p class="description">{{ item.description[lang].replace(/{domain}/g, domain.title) }}
                                    </p>
                                </div>
                            </div>

                        </Carousel>
                    </div>

                </div>

                <!-- 03 -->
                <div
                    class="section base-padding section-das-03 d-flex flex-column justify-content-center align-items-center">
                    <h1 class="mb-4 text-center">Processamento de imagens, com indexação automatizada através da
                        inteligência artificial associado ao OCR (opcional)</h1>
                    <h2 class="text-center mb-5">Reduz a quantidade de pessoas necessárias para executar a classificação
                        e indexação dos documentos estruturados</h2>

                    <div class="row">
                        <div
                            class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-start align-items-center">
                            <img class='mb-4' :src="`/assets/images/das/image-01.png`"
                                alt="Cenário sem Secretaria Acadêmica Digital">
                            <p>Cenário sem Secretaria Acadêmica Digital</p>
                        </div>

                        <div
                            class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-start align-items-center">
                            <img class='mb-4' :src="`/assets/images/domains/${domain.name}/das/image-02.png`"
                                alt="com Secretaria Acadêmica Digital">
                            <p>com Secretaria Acadêmica Digital</p>
                        </div>
                    </div>

                </div>
                <!-- 04 -->
                <div
                    class="section base-padding section-das-04 d-flex flex-column justify-content-center align-items-center">
                    <div class="background"></div>
                    <div class="content">
                        <h1 class="mb-4 text-center">A Plataforma contém:</h1>

                        <div class="blocks-wrapper mt-3">
                            <div class="block">
                                <div class="inner-block">
                                    <p class="mb-3">Assinatura digital <br>com certificado ICP Brasil</p>
                                    <div class="image">
                                        <img class='mb-3' :src="`/assets/images/das/image-03.png`"
                                            alt="Assinatura digital com certificado ICP Brasil">
                                    </div>
                                    <p>Esse é um requisito obrigatório que já vem incluso na Plataforma.</p>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-block">
                                    <p class="mb-3">Plataforma de Gestão de Conteúdo <br>e Automatização de Processos
                                        <br>100% via browser em nuvem</p>
                                    <div class="image">
                                        <img class='mb-3' :src="`/assets/images/das/image-04.png`"
                                            alt="Plataforma de Gestão de Conteúdo e Automatização de Processos 100% via browser em nuvem">
                                    </div>
                                    <p>Todos os documentos capturados pelo módulo de digitalização são publicados no
                                        sistema de gestão 100% web, permitindo que todas as pessoas autorizadas tenham
                                        acesso à informação sem a necessidade de nenhuma instalação de sistema.</p>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-block">
                                    <p class="mb-3">Criação de <br>Processos</p>
                                    <div class="image">
                                        <img class='mb-3'
                                            :src="`/assets/images/domains/${domain.name}/das/image-05.png`"
                                            alt="Criação de Processos">
                                    </div>
                                    <p>Permite a criação de processos ilimitados no BPM voltado para área educacional,
                                        como: fluxo de matrícula, gestão de bolsas, fluxo de pagamento, etc...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 05 -->
                <div class="section section-das-05 base-padding">
                    <h1 class="mb-4 text-center mb-4">Como podemos ajudar sua instituição</h1>

                    <div :class="{ 'mt-5': window.width > 991 }"
                        class="d-flex flex-row justify-content-center align-items-center">

                        <Carousel style="width: 100%;" :margin="0" :autoplay="true" :dots="false" :nav="false"
                            :responsive="{
                                0: {
                                    items: 1
                                },
                                991: {
                                    items: 3
                                },
                                1400: {
                                    items: 4
                                },
                            }">
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-das-09"></i> <span></span></div>
                                        <p>Equipe para <br>implementação</p>
                                        <p class="description">Além de fornecermos a plataforma completa para atender a
                                            demanda, também temos uma equipe especializada para implantação do serviço.
                                        </p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-das-10"></i> <span></span></div>
                                        <p>Criação do Setor <br>de Digitalização</p>
                                        <p class="description">Nós orientamos na criação do setor de digitalização do
                                            acervo e parametrizamos o sistema para atender os requisitos exigidos</p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-das-11"></i> <span></span></div>
                                        <p>Melhor Relação <br>Custo x Benefício</p>
                                        <p class="description">Nós viabilizamos a implantação com uma proposta de custo
                                            atraente e viável para a sua instituição de ensino.</p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="card-col">
                                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                        <div><i class="icon-das-12"></i> <span></span></div>
                                        <p>Revendas <br>Autorizadas</p>
                                        <p class="description">Mas se preferir terceirizar esse serviço, pode contar com
                                            nossa rede de revendas autorizadas em todo o Brasil.</p>
                                        <router-link class="nav-link" :to="`/${partner_url}`">{{ $t('LEARN_MORE') }}
                                            ></router-link>
                                    </div>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>

                <!-- <div id="plans" class="section base-padding section-addon d-flex flex-column justify-content-center align-items-center">
                <h1 class="mb-4 text-center">Secretaria Acadêmica Digital<br>Opções disponíveis na área de checkout</h1>
                <h2 class="text-center mb-5">Esse item inclui a digitalização com validade legal e a assinatura digital e eletrônica.</h2>

                <div style="width: 100%;" class="addons-wrapper mt-5 d-flex flex-wrap justify-content-center align-items-start" v-if="addon">
                    <div v-for="(tier_item, index) in addon.tiers"  :key="index" class='addon-tier d-flex flex-row justify-content-center'>
                        
                        <div class='d-flex flex-column justify-content-center align-items-center'>
                            
                            <span v-if="tier_item.max && !tier_item.last_tier_with_price" class="text-center" v-html="
                                `${$t('UP_TO')} <b>${tier_item.max}</b> ${$t(getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular')))} / ${$t('MONTH')}`
                            "></span>
                            <span v-else class="text-center" v-html="
                                `<b>${$t(getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular')))} ${$t('UNLIMITED_plural')}</b>`
                            "></span>


                            <p class="text-center description mb-3" v-html="tier_item.description"></p>
                            
                            <p class="text-center price" v-html="
                                `${user_monetary.symbol} <b>${tier_item.total}</b> +${$t('PLAN')} / ${$t('MONTH')}`
                            "></p>

                            
                        </div>

                    </div>
                </div>
            </div>

            
            <div class="section section-main-plans section-das-06">
                <div class="plans-background"></div>
                <Plans 
                    v-if="show" 
                    :parent="'slv'"
                    :must_have="`ECM`"
                    :title="'Escolha o plano ideal para criação da sua Secretaria Acadêmica Digital, o qual se estende para Gestão de Conteúdo e a Automatização dos Processos nos demais setores da IES'"
                ></Plans>
            </div> -->

                <div class='section base-padding section-das-07'>

                    <h1 class="mb-4 text-center mb-5">Instituições de Ensino que já utilizam a plataforma</h1>

                    <Carousel class="mt-3" :margin="20" :autoplay="true" :dots="false" :nav="false" :responsive="{
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1400: {
                            items: 3
                        },
                        1500: {
                            items: 5
                        }
                    }">
                        <div class='slide-item' v-for="(item, index) in logos" :key="index">
                            <div class='d-flex justify-content-center align-items-center'>
                                <img :src="`/assets/images/das/logos/${item.filename}`" :alt="item.label">
                            </div>
                        </div>
                    </Carousel>

                </div>

                <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show" class="ecm"></Join>
                <ThePlatform :window="window" v-if="show"></ThePlatform>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import Plans from '../plans/plans'
import PlansMixin from '../../mixins/PlansMixin'
import Carousel from 'vue-owl-carousel'

export default {
    inject: ['scrollto'],
    mixins: [PlansMixin],
    components: {
        Clients,
        ThePlatform,
        Join,
        Carousel,
        // Plans
    },
    props: {
        isDomainPermitted: {
            type: Boolean,
            default: false
        },
        partner_url: {
            type: String,
            default: ''
        },
        window: {
            type: Object,
            default() {
                return {
                    width: 0,
                    height: 0
                }
            }
        }
    },
    data() {
        return {
            show: false,
            addon: null,
            logos: [
                { "filename": "01.png", "label": "Universidades Cruzeiro do Sul" },
                { "filename": "02.png", "label": "FGV" },
                { "filename": "03.png", "label": "UNASP" },
                { "filename": "04.png", "label": "CEUMA Universidades" },
                { "filename": "05.png", "label": "UniBrasil" },
                { "filename": "06.png", "label": "UNIEURO Centro Universitário" },
                { "filename": "07.png", "label": "FISMA" },
                { "filename": "08.png", "label": "UNISUL" },
                { "filename": "09.png", "label": "Faculdade Campo Real" },
                { "filename": "10.png", "label": "UFAM" },
                { "filename": "11.png", "label": "CESUP" },
                { "filename": "12.png", "label": "UNICID" },
                { "filename": "13.png", "label": "UNIFAMAZ" },
                { "filename": "14.png", "label": "UNIPAC" },
                { "filename": "15.png", "label": "Pitágoras" },
                { "filename": "16.png", "label": "Braz Cubas" },
                { "filename": "17.png", "label": "Uniguaçú" },
                { "filename": "18.png", "label": "CEUPI Faculdade" },
                { "filename": "19.png", "label": "UEA" },
                { "filename": "20.png", "label": "Educação Adventista" }
            ]
        }
    },

    computed: {
        ...mapState('MainStoreModule', {
            user_monetary: state => state.user_monetary,
            domain: state => state.domain
        }),
        lang() {
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
        features_das_accordion() {
            return [
                {
                    "icon": "icon-das-01",
                    "description": {
                        pt: "Gerenciamento do ciclo de vida dos documentos com o controle da temporalidade, permitindo eliminar documento de acordo com os prazos legais estabelecidos",
                        en: "Gerenciamento do ciclo de vida dos documentos com o controle da temporalidade, permitindo eliminar documento de acordo com os prazos legais estabelecidos",
                        es: "Gerenciamento do ciclo de vida dos documentos com o controle da temporalidade, permitindo eliminar documento de acordo com os prazos legais estabelecidos"
                    }
                },
                {
                    "icon": "icon-das-02",
                    "description": {
                        pt: "Visualizador interno para diversos formatos de arquivos, dentre eles: TIFF, JPG, BMP, PDF, XML, TXT, MP3, MP4, arquivos do Microsoft Office (Não requer instalação do pacote Office)",
                        en: "Visualizador interno para diversos formatos de arquivos, dentre eles: TIFF, JPG, BMP, PDF, XML, TXT, MP3, MP4, arquivos do Microsoft Office (Não requer instalação do pacote Office)",
                        es: "Visualizador interno para diversos formatos de arquivos, dentre eles: TIFF, JPG, BMP, PDF, XML, TXT, MP3, MP4, arquivos do Microsoft Office (Não requer instalação do pacote Office)"
                    }
                },
                {
                    "icon": "icon-das-03",
                    "description": {
                        pt: "Controle de versionamento de documentos",
                        en: "Controle de versionamento de documentos",
                        es: "Controle de versionamento de documentos"
                    }
                },
                {
                    "icon": "icon-das-04",
                    "description": {
                        pt: "Processos e documentos totalmente integrados (ECM/BPM)",
                        en: "Processos e documentos totalmente integrados (ECM/BPM)",
                        es: "Processos e documentos totalmente integrados (ECM/BPM)"
                    }
                },
                {
                    "icon": "icon-das-05",
                    "description": {
                        pt: "Não requer nenhuma instalação, exceto para módulo de captura de documentos em lote",
                        en: "Não requer nenhuma instalação, exceto para módulo de captura de documentos em lote",
                        es: "Não requer nenhuma instalação, exceto para módulo de captura de documentos em lote"
                    }
                },
                {
                    "icon": "icon-das-06",
                    "description": {
                        pt: "Acompanhamento do andamento dos processos de matrícula, concessão de bolsas, pagamentos, compras, e tantos quantos forem implementados, já que a plataforma permite a livre inclusão de fluxos de trabalho de uma forma simples e eficiente",
                        en: "Acompanhamento do andamento dos processos de matrícula, concessão de bolsas, pagamentos, compras, e tantos quantos forem implementados, já que a plataforma permite a livre inclusão de fluxos de trabalho de uma forma simples e eficiente",
                        es: "Acompanhamento do andamento dos processos de matrícula, concessão de bolsas, pagamentos, compras, e tantos quantos forem implementados, já que a plataforma permite a livre inclusão de fluxos de trabalho de uma forma simples e eficiente"
                    }
                },
                {
                    "icon": "icon-das-07",
                    "description": {
                        pt: "Assinatura digital dos contratos",
                        en: "Assinatura digital dos contratos",
                        es: "Assinatura digital dos contratos"
                    }
                },
                {
                    "icon": "icon-das-08",
                    "description": {
                        pt: "Módulo de captura de alta performance com capacidade de processamento de documentos estruturados em lotes",
                        en: "Módulo de captura de alta performance com capacidade de processamento de documentos estruturados em lotes",
                        es: "Módulo de captura de alta performance com capacidade de processamento de documentos estruturados em lotes"
                    }
                }
            ]
        }
    },

    watch: {
        lang(lang) {
            this.addonTranslation()
        },
    },

    methods: {
        ...mapActions('MainStoreModule', [
            'getAddonByKey'
        ]),

        addonTranslation() {
            if (this.addon.tiers[0]) this.addon.tiers[0].description = "Solução para<br> Micro Empresas"
            if (this.addon.tiers[1]) this.addon.tiers[1].description = "Solução acessível para<br> Pequenas Empresas"
            if (this.addon.tiers[2]) this.addon.tiers[2].description = "Solução para Médias empresas<br> de uso intensivo de papel"
            if (this.addon.tiers[3]) this.addon.tiers[3].description = "Solução customizadas para<br> clientes Corporativos"
        },
    },

    beforeCreate() {
        this.$emit('isPageLoaded', false)
    },

    async created() {

        if (!this.api.token) {
            await this.setApiToken()
        }

        if (this.api.token) {
            await this.getAddonByKey({ key: '5' })
            await this.getSellablePlans({ 'domain': this.domain.name, 'group': this.group })

            this.addon = Object.assign({}, this.renderedAddons['GENERAL'][0])

            if (this.addon.tiers.length > 4) {
                this.addon.tiers.length = 4
            }

            this.addonTranslation()
        }
    },

    async mounted() {

        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }
}
</script>

<style lang="scss">
$ecm: #0DC5C7;
$bpm: #2C2FFA;
$swc: #E82F8A;
$dss: #962DFB;
$slv: #80BA27;
$das: #DEC902;

.section-das-01 {

    p {
        color: rgba(0, 0, 0, .9) !important;
        font-weight: 600;
    }

    background-color: #EFF3FE;
}

.section-das-02 {
    background-color: #FFF;
    padding-left: 20vw !important;
    padding-right: 20vw !important;

    div {
        background-color: #EFF3FE;
        border: 2px solid #D8D4D4;
        padding: 30px;
        border-radius: 10px;

        img {
            width: 200px;
            height: auto;
        }

        p {
            margin-bottom: 0;
            margin-left: 30px;
            text-align: left;
            color: rgba(0, 0, 0, .9) !important;
        }
    }
}

.section-das-03 {
    p {
        color: rgba(0, 0, 0, .9) !important;
    }

    background-color: #FFF;

    div {
        div {
            img {
                position: relative;
                width: 100%;
            }

            p {
                font-size: 18px;
                line-height: 21px;
                font-weight: 600;
            }
        }
    }
}

.section-das-04 {
    background-color: #FFF;
    padding-top: 0 !important;
    position: relative;

    .background {
        position: absolute;
        z-index: 0;
        background-color: #EEF4FB;
        width: 100%;
        height: 350px !important;
        top: 0;
    }

    .content {
        width: 100%;
        padding-top: 6vw;
        z-index: 1;

        .blocks-wrapper {
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .block {
                width: calc(100% / 3);
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: stretch;

                .inner-block {
                    border: 2px solid $ecm;
                    border-radius: 10px;
                    padding: 25px;
                    width: 100%;
                    background-color: #FFF;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    .image {
                        position: relative;

                        img {
                            position: relative;
                            width: 100%;
                            margin: 30px 0 !important;
                        }
                    }

                    p {
                        text-align: center;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                        margin: 0 !important;

                        &:first-child {
                            width: 70%;
                            padding-bottom: 10px;
                            border-bottom: 2px solid $ecm;
                        }

                        &:last-child {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.section-das-05 {
    h2 {
        width: 100%;
        text-align: center;
    }

    a {
        font-size: 16px !important;
        transition: all .4s ease;
    }

    p {
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }

    .imgOpenModal {
        margin-top: 20px;
        width: 15vw;
    }

    .item {
        padding: 30px 25px;

        .div-card {
            width: 100%;
            border-radius: 20px;
            padding: 40px 20px;
            height: 400px;
            transition: all .4s ease;
            box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
            cursor: pointer;
            text-align: center;
            background-color: #fff;

            &:hover {

                p,
                a {
                    opacity: 0;
                }

                p.description {
                    opacity: 1;
                }

                div {
                    span {
                        width: 70px;
                    }
                }
            }

            div {
                margin-bottom: -10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    display: block;
                    margin-top: 10px;
                    width: 0;
                    border-radius: 20px;
                    transition: all .2s ease;
                }

                i {
                    font-size: 50px;
                }
            }

            p {
                margin: 0 !important;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;

                b {
                    font-weight: 800;
                }

                &.description {
                    font-size: 16px !important;
                    line-height: 20px !important;
                    font-weight: 400 !important;
                    opacity: 0;
                    position: absolute;
                    padding: 20px 40px;
                    top: 130px;
                }
            }

            a {
                text-decoration: none;
                border: 1px solid transparent;
                padding: 0;
                transition: all .2s ease;
            }
        }
    }
}

.section-das-05 .owl-item {
    &:nth-child(1) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(238, 136, 5, 0.5);
            }

            color: #EE8805;

            a:hover {
                color: #EE8805;
                border: 1px solid #EE8805;
            }

            div span {
                border-bottom: 3.5px solid #EE8805;
            }
        }
    }

    &:nth-child(2) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(148, 197, 74, 0.5);
            }

            i {
                color: #94C54A;
            }

            a:hover {
                color: #94C54A;
                border: 1px solid #94C54A;
            }

            div span {
                border-bottom: 3.5px solid #94C54A;
            }
        }
    }

    &:nth-child(3) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(128, 81, 152, 0.5);
            }

            i {
                color: #805198;
            }

            a:hover {
                color: #805198;
                border: 1px solid #805198;
            }

            div span {
                border-bottom: 3.5px solid #805198;
            }
        }
    }

    &:nth-child(4) {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(232, 48, 138, 0.5);
            }

            i {
                color: #E8308A;
            }

            a:hover {
                color: #E8308A;
                border: 1px solid #E8308A;
            }

            div span {
                border-bottom: 3.5px solid #E8308A;
            }
        }
    }

}

.section-das-06 {

    .plans-background {
        position: absolute;
        z-index: -1;
        background-color: #4669F8;
        width: 100%;
        height: 30vw !important;
    }

    &.section-main-plans {
        .bottom-content {
            margin-bottom: 30px;
            min-height: auto !important;

            .plans-columns .plan-column .block-02 {
                margin-bottom: 20px !important;
            }
        }
    }
}

.section-das-07 {
    padding-top: 0 !important;

    .slide-item {
        div {
            img {
                width: auto !important;
                height: 140px !important;
            }
        }
    }

}

@media only screen and (max-width: 991px) {


    .section-das-01,
    .section-das-02,
    .section-das-03,
    .section-addon,
    .section-das-07,
    .section-main-module {
        h1 {
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2 {
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
        }

        h3 {
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
            text-align: center;
        }
    }

    .section-das-02 {

        padding-left: 10px !important;
        padding-right: 10px !important;

        h2 {
            margin: 0 !important;
        }

        div {
            p {
                margin: 0 !important;
                padding: 0 !important;

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }

    }

    .section-das-04 {

        .background {
            height: 350px !important;
        }

        .content {
            padding: 30px 0 !important;

            .blocks-wrapper {
                flex-direction: column !important;
                align-items: center;
                justify-content: center;

                .block {
                    width: 100% !important;

                    .inner-block {
                        .image {
                            position: relative;

                            img {
                                width: 100%;
                                height: auto !important;
                            }
                        }
                    }
                }
            }
        }

    }

    .section-bpm-features {
        padding: 30px 0 !important;

        .carousel-wrapper {

            .mod-carousel {
                min-height: 200px;

                .owl-item {
                    min-height: 200px;

                    .item {
                        min-height: 200px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 20px;
                        background-color: #fff !important;
                        border-radius: 15px !important;
                        margin: 20px !important;

                        .div-icon {
                            i {
                                font-size: 45px;
                            }
                        }

                        .div-text {
                            .description {
                                margin: 20px 0 0 0 !important;
                                text-align: center;
                            }
                        }
                    }


                    &:nth-child(1) .item {
                        box-shadow: 1px 2px 5px #CE3009;

                        .div-icon i,
                        .div-text p {
                            color: #CE3009;
                        }
                    }

                    &:nth-child(2) .item {
                        box-shadow: 1px 2px 5px #01C7B0;

                        .div-icon i,
                        .div-text p {
                            color: #01C7B0;
                        }
                    }

                    &:nth-child(3) .item {
                        box-shadow: 1px 2px 5px #E32555;

                        .div-icon i,
                        .div-text p {
                            color: #E32555;
                        }
                    }

                    &:nth-child(4) .item {
                        box-shadow: 1px 2px 5px #7F81F0;

                        .div-icon i,
                        .div-text p {
                            color: #7F81F0;
                        }
                    }

                    &:nth-child(5) .item {
                        box-shadow: 1px 2px 5px #EA4587;

                        .div-icon i,
                        .div-text p {
                            color: #EA4587;
                        }
                    }

                    &:nth-child(6) .item {
                        box-shadow: 1px 2px 5px #FF9B4D;

                        .div-icon i,
                        .div-text p {
                            color: #FF9B4D;
                        }
                    }

                    &:nth-child(7) .item {
                        box-shadow: 1px 2px 5px #248AF6;

                        .div-icon i,
                        .div-text p {
                            color: #248AF6;
                        }
                    }

                    &:nth-child(8) .item {
                        box-shadow: 1px 2px 5px #34D432;

                        .div-icon i,
                        .div-text p {
                            color: #34D432;
                        }
                    }

                }
            }
        }
    }

}
</style>