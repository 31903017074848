<template>
  <footer class="footer" v-if="show">
    <div class="footer-content">
      <!-- 
          Footer Top
        -->
      <div class="row footer-top base-padding">
        <div class="col">
          <div class="row">
            <div class="group py-0 col-lg-3 col-md-3 col-sm-12">
              <div class="footer-logo">
                <router-link :to="`/home${partner_url}`"><img :src="`/assets/images/domains/${domain.name}/logo_03.png`"
                    :alt="`${domain.title}`" /></router-link>
              </div>
            </div>
            <div class="group py-0 col-lg-3 col-md-3 col-sm-12 pl0">
              <div class="contact-info d-flex flex-row align-items-center">
                <div class="contact-icon">
                  <img class="icon-footer" src="/assets/images/48.png" alt="Call us" />
                </div>
                <div class="contact-text">
                  <div v-if="domain.phone1" class="text">
                    <a :href="`tel:${domain.phone1}`">{{ domain.phone1 }}</a>
                  </div>
                  <div v-if="domain.phone2" class="text">
                    <a :href="`tel:${domain.phone2}`">{{ domain.phone2 }}</a>
                  </div>
                  <div v-if="domain.phone3" class="text">
                    <a :href="`tel:${domain.phone3}`">{{ domain.phone3 }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="group py-0 col-lg-3 col-md-3 col-sm-12 pl0">
              <div class="contact-info d-flex flex-row align-items-center">
                <div class="contact-icon">
                  <img class="icon-footer" src="/assets/images/49.png" alt="Send us a message" />
                </div>
                <div class="contact-text">
                  <div class="contact-title">{{ $t("SEND_US_A_MESSAGE") }}</div>
                  <div class="text">
                    <a :href="`mailto:${domain.email1}`">{{ domain.email1 }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="group py-0 col-lg-3 col-md-3 col-sm-12 pl0">
              <div class="contact-info d-flex flex-row align-items-center">
                <div class="contact-icon">
                  <img class="icon-footer" src="/assets/images/50.png" alt="Visit us" />
                </div>
                <div class="contact-text">
                  <div class="contact-title">
                    {{ $t("VISIT_OUR_LOCATION") }}
                  </div>
                  <div v-if="domain.address1" class="text">
                    <a target="_blank" :href="`${domain.googlemaps1}`" v-html="domain.address1"></a>
                  </div>
                  <div v-if="domain.address2" class="text">
                    <a target="_blank" :href="`${domain.googlemaps2}`" v-html="domain.address2"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 
          Footer Bottom
        -->
      <div class="row footer-bottom base-padding">
        <div class="col">
          <div class="row">
            <!--

                COMPANY

               -->
            <div class="widget group py-0 col-lg-3 col-md-3 col-sm-12">
              <div class="sitemap-widget-title" @click.stop="widget.company = !widget.company">
                <h3 class="footer-title">{{ $t("COMPANY") }}</h3>
                <i v-if="window.width <= 991" :class="{
                  'fas fa-chevron-up': widget.company,
                  'fas fa-chevron-down': !widget.company,
                }"></i>
              </div>

              <ul class="mb-0 sitemap-widget" v-if="window.width > 991 || widget.company">
                <li>
                  <router-link class="nav-link" :to="`/about-us${partner_url}`">{{ $t("ABOUT_OUR_COMPANY")
                    }}</router-link>
                </li>
                <li v-if="domain.type == 'interfy'">
                  <router-link class="nav-link" :to="`/blog${partner_url}`">{{
                    $t("READ_OUR_BLOG")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/plans${partner_url}`">{{
                    $t("PRICES_AND_PLANS")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/careers${partner_url}`">{{
                    $t("INTERFY_CAREERS").replace(/{domain}/g, domain.title)
                  }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/solutions${partner_url}`">{{ $t("SEGMENT_SOLUTIONS")
                    }}</router-link>
                </li>
                <li v-if="
                  isDomainPermitted &&
                  (domain.type == 'interfy' || domain.type == 'distribuitor')
                ">
                  <router-link class="nav-link" :to="`/become-a-partner${partner_url}`">{{ $t("PARTNERSHIP_PROGRAM")
                    }}</router-link>
                </li>
              </ul>
            </div>

            <!--

                PROCESSES MODELS
                
               -->
            <div class="widget group py-0 col-lg-3 col-md-3 col-sm-12">
              <div class="sitemap-widget-title" @click.stop="widget.processes = !widget.processes">
                <h3 class="footer-title">{{ $t("PROCESSES_MODELS") }}</h3>
                <i v-if="window.width <= 991" :class="{
                  'fas fa-chevron-up': widget.processes,
                  'fas fa-chevron-down': !widget.processes,
                }"></i>
              </div>
              <ul class="mb-0 sitemap-widget" v-if="window.width > 991 || widget.processes">
                <li>
                  <router-link class="nav-link" :to="`/process/customerService${partner_url}`">{{ $t("CUSTOMER SERVI")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/process/purchaseOrder${partner_url}`">{{ $t("PURCHASE ORDER")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/process/contractManagement${partner_url}`">{{ $t("CONTRACT MANAG") }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/process/paymentAuthorization${partner_url}`">{{ $t("PAYMENT AUTHOR") }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/process/expensesRefund${partner_url}`">{{ $t("EXPENSES REFUN")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/process/recruitmentAndSelection${partner_url}`">{{
                    $t("RECRUITMENT AN") }}</router-link>
                </li>
              </ul>
            </div>

            <!--

                PRODUCTS
                
               -->
            <div class="widget group py-0 col-lg-3 col-md-3 col-sm-12">
              <div class="sitemap-widget-title" @click.stop="widget.products = !widget.products">
                <h3 class="footer-title">{{ $t("PRODUCTS") }}</h3>
                <i v-if="window.width <= 991" :class="{
                  'fas fa-chevron-up': widget.products,
                  'fas fa-chevron-down': !widget.products,
                }"></i>
              </div>
              <ul class="mb-0 sitemap-widget" v-if="window.width > 991 || widget.products">
                <li>
                  <router-link class="nav-link" :to="`/ecm${partner_url}`">{{
                    $t("KNOW_ECM")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/bpm${partner_url}`">{{
                    $t("KNOW_BPM")
                    }}</router-link>
                </li>

                <li>
                  <router-link class="nav-link" :to="`/swc${partner_url}`">{{
                    $t("KNOW_SWC")
                    }}</router-link>
                </li>
                <li>
                  <router-link class="nav-link" :to="`/dss${partner_url}`">{{
                    $t("KNOW_DSS")
                    }}</router-link>
                </li>

                <li>
                  <router-link class="nav-link" :to="`/slv${partner_url}`">{{
                    $t("KNOW_SLV")
                    }}</router-link>
                </li>
                <li v-if="this.$i18n.locale == 'en'">
                  <router-link class="nav-link" :to="`/cic${partner_url}`">{{
                    $t("KNOW_CIC")
                    }}</router-link>
                </li>
                <li v-if="this.$i18n.locale == 'pt_br'">
                  <router-link class="nav-link" :to="`/das${partner_url}`">{{
                    $t("KNOW_DAS")
                    }}</router-link>
                </li>
              </ul>
            </div>

            <div class="group py-0 col-lg-3 col-md-3 col-sm-12">
              <div class="newsletter">
                <b-overlay :show="isLoading" rounded="sm">
                  <b-form id="formNewsletter" @submit.stop.prevent="handleSubmit" class="news-form">
                    <div class="input-field d-flex flex-row justify-content-start align-items-start">
                      <b-form-group :state="state.email" label-for="email" invalid-feedback="E-mail is required">
                        <b-form-input class="form-input" :state="state.email" id="email" v-model="form.email"
                          :placeholder="$t('YOUR_BEST_EMAIL')" required></b-form-input>
                      </b-form-group>

                      <button @click="handleSubmit" class="form-button primary-btn">
                        {{ $t("SUBSCRIBE") }}
                      </button>
                    </div>

                    <div class="d-flex flex-row mt-3 agree">
                      <b-form-group :state="state.agree" label-for="agree"
                        invalid-feedback="You need to agree with the policy">
                        <b-form-checkbox required v-model="form.agree" id="agree" name="agree" value="true"
                          unchecked-value="false">
                          {{ $t("I_AGREE_TO_THE_PRIVACY_POLICY") }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </b-form>
                </b-overlay>

                <b-alert class="mt-2" :show="dismissCountDown" dismissible variant="success"
                  @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
                  <p>
                    {{ $t("SENT_SUCESS") }} <br /><small>{{
                      $t("CLOSING...").replace("{var1}", dismissCountDown)
                      }}</small>
                  </p>

                  <b-progress variant="success" :max="dismissSecs" :value="dismissCountDown" height="4px"></b-progress>
                </b-alert>
              </div>

              <div class="mt-2 footer-social d-flex flex-row">
                <a v-if="domain.instagram" target="_blank" :href="`${domain.instagram}`"><i
                    class="fab fa-instagram"></i></a>
                <a v-if="domain.facebook" target="_blank" :href="`${domain.facebook}`"><i
                    class="fab fa-facebook"></i></a>
                <a v-if="domain.linkedin" target="_blank" :href="`${domain.linkedin}`"><i
                    class="fab fa-linkedin"></i></a>
                <a v-if="domain.youtube" target="_blank" :href="`${domain.youtube}`"><i class="fab fa-youtube"></i></a>

                <!-- PIXEL LINKEDIN -->
                <img height="1" width="1" style="display: none" alt=""
                  src="https://px.ads.linkedin.com/collect/?pid=3160050&conversionId=4268738&fmt=gif" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 
          Footer Copyright
        -->
      <div class="row footer-copyright">
        <div class="col">
          <div class="row base-padding">
            <div class="py-0 col-md-6 d-flex align-items-center">
              <div class="copyright text-left">
                <p>
                  {{ domain.title }} © {{ new Date().getFullYear() }}.
                  {{ $t("ALL_RIGHTS_RESERVED") }}.
                </p>
              </div>
            </div>
            <div class="term-condition-wrapper py-0 col-md-6 d-flex align-items-center justify-content-end">
              <div class="term-conditon">
                <a :href="`https://interfy.statuspage.io/`" target="_blank" v-html="`Status`"></a>
                <a :href="`/privacy-policy/${lang}${partner_url}`" v-html="$t('PRIVACY_POLICY')"></a>
                <a :href="`/terms-and-conditions/${lang}${partner_url}`" v-html="$t('TERMS_AND_CONDITIONS')"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="allowed_domains.includes(domain.location)">
      <a :href="`https://wa.me/${whatsAppPhone}`" target="_blank" class="whatsapp-link"
        :class="{ closed: !showCommunicationLinks }">
        <i class="fab fa-whatsapp"></i>
      </a>

      <!-- <div class="intercom" :class="{'closed': !showCommunicationLinks}">
          <IntercomChat></IntercomChat>
        </div> -->

      <a class="communicationLinks" @click.stop="toggleCommunicationLinks">
        <i :class="{
          'fas fa-comments': !showCommunicationLinks,
          'fas fa-times-circle': showCommunicationLinks,
        }"></i>
      </a>
    </template>
  </footer>
</template>

<script>
import $ from "jquery";
import { mapState, mapActions } from "vuex";
// import IntercomChat from '../common/IntercomChat'

export default {
  components: {
    // IntercomChat
  },
  props: {
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
    isDomainPermitted: {
      type: Boolean,
      default: false,
    },
    partner_url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      widget: {
        company: false,
        processes: false,
        products: false,
      },
      showCommunicationLinks: false,
      isLoading: false,
      show: false,
      showAlert: false,
      dismissSecs: 10,
      dismissCountDown: 0,

      state: {
        email: null,
        agree: null,
      },

      form: {
        email: "",
        agree: false,
      },

      allowed_domains: [
        "localhost",
        "interfy.io",
        "neofy.io",
        "processdoc.io",
        "clouddoc.io",
        "intellify.app",
        "autofy.one",
        "lawfy.io",
        "healthify.one",
        "educafy.one",
        "accountify.one",
        "realtify.one",
        "docsystem.io",
        "sysdoc.com.br",
      ],
    };
  },
  name: "Footer",
  computed: {
    ...mapState("MainStoreModule", {
      api: (state) => state.api,
      domain: (state) => state.domain,
      user_monetary: (state) => state.user_monetary,
      env: (state) => state.env,
    }),

    lang() {
      return this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale;
    },

    whatsAppPhone() {
      if (this.domain.type === "interfy") {
        if (this.lang == "es") {
          return "13214443931";
        } else {
          return "13218887963";
        }
      } else {
        switch (this.domain.location) {
          case "sysdoc.com.br":
            return "5511941247852";

          default:
            return "";
        }
      }
    },
  },
  watch: {
    isLoading(bool) {
      $("#formNewsletter button").prop("disabled", bool);
    },

    dismissCountDown(n) {
      if (n == 0) {
        this.resetForm();
      }
    },
  },
  methods: {
    ...mapActions("MainStoreModule", ["sendNewsletter"]),

    toggleCommunicationLinks() {
      this.showCommunicationLinks = !this.showCommunicationLinks;
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    checkFormValidity() {
      var t = this;
      var v = true;

      $("#formNewsletter")
        .find("input")
        .each(function () {
          const valid = this.checkValidity();

          const id = $(this).attr("id");
          t.state[id] = valid;

          if (!valid) {
            v = valid;
          }
        });

      return v;
    },

    resetForm() {
      this.form = {
        email: "",
        agree: false,
      };
      this.state = {
        email: null,
        agree: null,
      };
    },

    async handleSubmit() {
      if (!this.checkFormValidity()) {
        this.showAlert = true;
        return;
      }

      this.form.view = "newsletter";
      this.form.subject = "Requisição de newsletter!";

      this.isLoading = true;
      this.form.locale = this.$i18n.locale;
      await this.sendNewsletter(this.form).then((response) => {
        if (response.sent) {
          this.dismissCountDown = this.dismissSecs;
        }
      });

      this.isLoading = false;
    },
  },

  mounted() {
    this.show = true;
  },
};
</script>

<style lang="scss">
.custom-checkbox {
  .custom-control-label {
    padding-top: 6px;
    font-size: 12px !important;
    line-height: 12px !important;
  }
}
</style>

<style lang="scss" scoped>
.intercom {
  z-index: 999996;
  background-color: #2c2ffa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 160px;
  right: 20px;
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
  transition: all 0.4s ease;

  &.closed {
    opacity: 0;
    bottom: 20px;
    transition: all 0.4s ease;
  }

  i {
    color: #fff;
    font-size: 30px;
  }
}

.whatsapp-link {
  z-index: 999997;
  background-color: #00e676;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 90px;
  right: 20px;
  text-decoration: none;
  opacity: 1;
  transition: all 0.4s ease;

  &.closed {
    opacity: 0;
    bottom: 20px;
    transition: all 0.4s ease;
  }

  i {
    color: #fff;
    font-size: 35px;
  }
}

.communicationLinks {
  z-index: 999998;
  cursor: pointer;
  background-color: #d85060;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-decoration: none;
  transition: all 0.4s ease;

  i {
    color: #fff;
    font-size: 35px;
  }
}

.form-group {
  margin: 0 !important;
}

.footer {
  color: #fff;
  background: url(/assets/images/background-footer.png) no-repeat;
  background-size: cover;

  .footer-top {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .text {
      font-weight: 700;
      font-size: 14px;

      a {
        font-size: 14px;
        color: #fff;
        transition: all 0.4s ease 0s;
        text-decoration: none;
        outline: none;

        &:hover {
          color: #ff2c54;
        }
      }
    }

    .footer-logo img {
      height: 50px;
    }

    .contact-info {
      margin-left: -50px;

      .contact-title {
        font-size: 14px;
      }

      .contact-icon img {
        height: 30px;
        margin-right: 20px;
      }
    }
  }

  .footer-bottom {
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-top: 1px solid #2758e4;

    .footer-title {
      color: #fff;
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .footer-social {
      a {
        color: #fff;
        font-size: 35px;
        margin-right: 20px;
        transition: all 0.4s ease;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #ff2c54;
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    .news-form {
      input {
        background-color: #2d5efc;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 15px;
        height: 40px;
        color: #000 !important;
        border: none;
        outline: none;
        width: 220px;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #000;
          opacity: 0.5;
          /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #000;
          opacity: 0.5;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #000;
          opacity: 0.5;
        }
      }

      .form-button {
        min-width: 90px;
        background: #ff7a7a;
        border: none;
        color: #fff;
        font-weight: 600;
        height: 40px;
        font-size: 15px;
        padding: 0 10px;
        border-radius: 0 0.5rem 0.5rem 0;
        cursor: pointer;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        letter-spacing: 0;
        outline: none;
        line-height: 10px;

        &:hover {
          background-color: rgba(0, 199, 175, 1);
        }
      }
    }
  }

  .footer-copyright {
    color: #fff;
    border-top: 1px solid #2758e4;
    padding-top: 2rem;
    padding-bottom: 2rem;

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 300;
    }

    .term-conditon {
      a {
        font-size: 14px;
        font-weight: 300;

        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .footer-bottom ul li a,
  .footer-copyright .term-conditon a {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    display: inline-block;
    background: linear-gradient(to right, #fff 0%, #fff 100%);
    background-size: 0px 1px;
    background-position: 0px 95%;
    transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    background-repeat: no-repeat;
    padding: 5px 0;
  }

  .footer-bottom ul li a:hover,
  .footer-copyright .term-conditon a:hover {
    background-size: 100% 1px;
    text-decoration: none !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1460px) {
  .footer-top {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    .text {
      font-size: 12px !important;

      a {
        font-size: 12px !important;
      }
    }

    .footer-logo img {
      height: 40px !important;
    }

    .contact-info {
      margin-left: -40px !important;

      .contact-title {
        font-size: 12px !important;
      }

      .contact-icon img {
        margin-right: 10px !important;
      }
    }
  }

  .footer-bottom {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    .footer-title {
      font-size: 18px !important;
      margin-bottom: 18px !important;
    }

    .footer-social {
      margin-top: 10px !important;

      a {
        font-size: 25px !important;
        margin-right: 20px !important;
      }
    }

    .news-form {
      input {
        padding: 0 10px !important;
        height: 30px !important;
        width: 150px !important;
        font-size: 12px !important;
      }

      .form-button {
        height: 30px !important;
        font-size: 12px !important;
        padding: 0 8px !important;
        line-height: 12px;
      }
    }
  }

  .footer-copyright {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    p {
      font-size: 12px !important;
    }

    .term-conditon {
      a {
        font-size: 12px !important;

        &:first-child {
          margin-right: 10px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .row {
    margin: 0 !important;

    .col {
      padding: 0 !important;
    }

    .col-md-3 {
      padding-left: 0;
    }
  }

  .footer {
    padding: 20px 0 !important;

    .widget {
      &:first-child {
        margin-top: 40px !important;
      }

      &:nth-child(2) {
        margin: 10px 0 !important;
      }

      &:nth-child(3) {
        margin-bottom: 40px !important;
      }

      .sitemap-widget-title {
        width: 100%;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;

        h3 {
          margin: 0 !important;
        }

        i {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .sitemap-widget {
        margin: 10px 0 !important;
        padding: 0 30px;

        li {
          text-align: left !important;
        }
      }
    }
  }

  .footer-top,
  .footer-bottom,
  .footer-copyright {
    text-align: center;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }

  .group {
    padding: 0 !important;
    margin: 15px 0 !important;
  }

  .contact-info {
    flex-direction: column !important;
    justify-content: center !important;
    margin: 0 0 0 0 !important;

    .contact-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: 0 0 15px 0 !important;
        height: 25px !important;
      }
    }
  }

  .footer-social {
    justify-content: center !important;
    margin-top: 30px !important;
  }

  .input-field,
  .agree {
    justify-content: center !important;
    text-align: center !important;

    input {
      width: 200px;
    }
  }

  .copyright {
    width: 100%;
    margin: 20px 0 0 0 !important;
    padding: 0;

    p {
      width: 100%;
      text-align: center !important;
    }
  }

  .term-condition-wrapper {
    justify-content: center !important;
    align-items: center !important;

    .term-conditon {
      display: flex;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;

      margin-bottom: 100px;

      a {
        margin: 0 !important;
      }
    }
  }
}
</style>