<template>
    <div class="section section-know-also-processes d-flex flex-column justify-content-center align-items-center ">
        <h1>{{$t('KNOW_ALSO_PROCESSES')}}</h1>
        
        <div class="div-processes mt-5 d-flex flex-wrap justify-content-center align-items-center">                    
            <router-link class="nav-link" :to="`/process/customerService${partner_url}`" v-if="path != '/process/customerService'">
                <span></span>
                <p v-html="$t('CUSTOMER SERVI')"></p>
            </router-link>
            <router-link class="nav-link" :to="`/process/purchaseOrder${partner_url}`" v-if="path != '/process/purchaseOrder'">
                <span></span>
                <p v-html="$t('PURCHASE ORDER')"></p>
            </router-link>
            <router-link class="nav-link" :to="`/process/contractManagement${partner_url}`" v-if="path != '/process/contractManagement'">
                <span></span>
                <p v-html="$t('CONTRACT MANAG')"></p>
            </router-link>
            <router-link class="nav-link" :to="`/process/paymentAuthorization${partner_url}`" v-if="path != '/process/paymentAuthorization'">
                <span></span>
                <p v-html="$t('PAYMENT AUTHOR')"></p>
            </router-link>
            <router-link class="nav-link" :to="`/process/expensesRefund${partner_url}`" v-if="path != '/process/expensesRefund'">
                <span></span>
                <p v-html="$t('EXPENSES REFUN')"></p>
            </router-link>
            <router-link class="nav-link" :to="`/process/recruitmentAndSelection${partner_url}`" v-if="path != '/process/recruitmentAndSelection'">
                <span></span>
                <p v-html="$t('RECRUITMENT AN')"></p>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {    
    props: {
        page: {
            type: String,
            default: null
        },
        path: {
            type: String,
            default: null
        },
        partner_url: {
            type: String,
            default: ''
        },
    },    
    computed:{
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },    
    },

}
</script>

<style scoped lang="scss">
.section-know-also-processes {
    padding: 6vw 10vw;
    background-color: #fff;
}
</style>