<template>
  <div class="start-free-page">

    <div class="start-free-wrapper">

      <div class="form-customer">

        <h1 class='text-center font-bpm mb-5' v-html="$t('SIGN_UP_START_TRIAL').replace(/{var1}/g, 7)"></h1>

        <FormCustomer :selectedPlan="selectedPlan" @onSave="isLoading = false" @error="isLoading = false">
        </FormCustomer>

        <b-button @click.stop="subscribe" v-if="!isLoading" class='btn btn-success btn-medium-width mt-3'>{{
          $t('SUBSCRIBE') }}</b-button>
        <p class='mt-4 sign-agreement' v-html="$t('SIGN_UP_AGREEMENT').replace(/{lang}/g, lang)"></p>

      </div>
    </div>
  </div>
</template>

<script>
import FormCustomer from '../plans/FormCustomer'
import PlansMixin from '../../mixins/PlansMixin'
export default {
  mixins: [PlansMixin],
  components: {
    FormCustomer
  },
  data() {
    return {
      isLoading: false,
      plan: {}
    }
  },
  methods: {
    subscribe() {
      if (this.preVerifySelectedPlan(this.plan, true)) {
        this.isLoading = true
        this.$root.$emit('on-hit-ok-start-free')
      }
    },
  },

  beforeCreate() {
    this.$emit('isPageLoaded', false)
  },

  async mounted() {
    if (!this.user_monetary)
      await this.setUserMonetaryByLocation()

    console.log(".......................", this.user_monetary)

    if (!this.api.token) {
      await this.setApiToken()
    }

    if (this.api.token) {
      await this.getSellablePlans({
        domain: this.domain.name,
        currency: this.user_monetary.currency_code,
        recurrence: 'month',
        tier: 'enterprise'
      })

      setTimeout(() => {
        if (this.renderedPlans.length > 0) {
          this.plan = this.renderedPlans[0]
        }
      }, 500)
    }

    setTimeout(() => {
      this.$emit('isPageLoaded', true)
      this.show = true
    }, 1000);
  }
}
</script>




<style lang="scss" scoped>
.start-free-page {
  background-color: #EFF3FE;
  padding: 80px 0;

  .start-free-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-customer {

      width: 850px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      border-radius: 10px;
      padding: 30px;

      -moz-box-shadow: 10px 10px 10px -12px #ccc;
      -webkit-box-shadow: 10px 10px 10px -12px #ccc;
      box-shadow: 10px 10px 10px -12px #ccc;

      h2 {
        color: #2C2FFA;
        text-align: center;
      }

      p {
        text-align: center;
        padding: 0 80px;

        a {
          color: #000 !important;
          font-weight: 600 !important;

          &:hover {
            color: #2C2FFA !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {

  .start-free-page {
    padding: 10px 10px !important;

    .start-free-wrapper {

      .form-customer {
        width: 100% !important;

        h2 {
          margin: 20px 0 20px 0 !important;
          padding: 0 !important;
          width: 100% !important;
          text-align: center;
          font-size: 20px !important;
        }

        .sign-agreement {
          padding: 0 !important;
        }
      }
    }
  }

}
</style>