<template>
    <div class="accordion-container">
      <div
        v-for="(faq, index) in faqs"
        :key="index"
        class="accordion-item"
      >
        <div
          class="accordion-header"
          :class="{ 'active': activeIndex === index }"
          @click="toggleAccordion(index)"
        >
          <span class="up" v-if="activeIndex === index">&circ;</span>
          <span class="down" v-else>&circ;</span>
          <h3>{{ faq.question }}</h3>
        </div>
        <div
          v-if="activeIndex === index"
          class="accordion-content"
        >
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      faqs: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        activeIndex: null, // Rastrea qual índice do accordion está ativo
      };
    },
    methods: {
      toggleAccordion(index) {
        // Alterna entre abrir e fechar o accordion
        this.activeIndex = this.activeIndex === index ? null : index;
      },
    },
  };
  </script>
  
  <style scoped>
  .accordion-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .accordion-item {
    border-bottom: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .accordion-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .accordion-header h3 {
    color: #333333;
    font-size: 16px;
  }

  .accordion-header span {
    font-size: 32px;
    width: 20px;
    height: 35px;
  }  
  
  .accordion-header.active h3 {
    color: #FFA100;
  }

  .accordion-header.active .up {
    transform: rotateX(185deg);
    color: #FFA100;
    height: 20px;
  }
  
  .accordion-content {
    padding: 15px;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: auto;
    }
  }
  </style>
  