<template>
  <div id="app">
    <div class="main-container">
      <b-overlay
        :show="(showOverlay && page != 'NotFound') || !domain"
        class="overlay"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-modal
          ref="modalAlert"
          :title="alertData.title"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
        >
          <p class="my-4">{{ alertData.message }}</p>
        </b-modal>
        <b-modal
          ref="modalAlertOkOnly"
          ok-only
          :title="alertData.title"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
        >
          <p class="my-4" v-html="alertData.message"></p>
        </b-modal>

        <TopMenu
          @isPageLoaded="setShowOverlay"
          :window="window"
          :partner_url="partner_url"
          :isDomainPermitted="isDomainPermitted"
          v-if="domain && page != 'checkout'"
        ></TopMenu>

        <router-view
          :isDomainPermitted="isDomainPermitted"
          v-if="domain"
          :page="page"
          :path="path"
          @isPageLoaded="setShowOverlay"
          :window="window"
          :partner_url="partner_url"
          class="page-view"
        ></router-view>

        <Footer
          :window="window"
          :partner_url="partner_url"
          :isDomainPermitted="isDomainPermitted"
          v-if="domain && page != 'checkout'"
        ></Footer>

        <Cookies
          :key="lang"
          :lang="lang"
          @cookiesAccepted="cookiesAccepted = true"
          v-if="
            domain &&
            page != 'checkout' &&
            (!cookiesAccepted || !cookiesSelected)
          "
        ></Cookies>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TopMenu from "./components/common/TopMenu";
import Footer from "./components/common/Footer";
import Cookies from "./components/common/Cookies";
import { BOverlay } from "bootstrap-vue";
import { BModal } from "bootstrap-vue";
import $ from "jquery";

export default {
  name: "App",
  components: {
    TopMenu,
    Footer,
    BOverlay,
    BModal,
    Cookies,
  },
  provide: function () {
    return {
      scrollto: this.scrollto,
      showAlert: this.showAlert,
    };
  },
  data() {
    return {
      partner_url: "",
      cookiesAccepted: false,
      cookiesSelected: null,
      headerBgVariant: "warning",
      headerTextVariant: "dark",
      isPageLoaded: false,
      path: null,
      page: null,
      showOverlay: true,

      window: {
        width: 0,
        height: 0,
      },
      alertData: {
        title: null,
        message: null,
      },
    };
  },
  watch: {
    $route: {
      deep: true,
      async handler(route) {
        this.setPageAttributes();
        this.changeFavicon();

        if (this.$route.name == "blog-post") {
          await this.getBlogPost();
        }
        this.changePageMetaTags();
      },
    },

    cookiesAccepted(bool) {
      this.setCookies();
    },

    partner: {
      deep: true,
      handler(partner) {
        if (partner && partner.id && partner.slug) {
          this.partner_url = `/partner/${this.partner.id}/${this.partner.slug}`;
        } else {
          this.partner_url = "";
        }
      },
    },
  },

  computed: {
    ...mapState("MainStoreModule", {
      api: (state) => state.api,
      partner: (state) => state.partner,
      domain: (state) => state.domain,
      post: (state) => state.post,
    }),

    lang() {
      return this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale;
    },
    isDomainPermitted() {
      if (this.partner) {
        return false;
      }

      if (
        ["organizefy", "arquify", "trify", "arquivodigitaldoc", "getfy"].includes(
          this.domain.name
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  async mounted() {
    this.$root.$on("showAlert", (payload) => {
      this.showAlert(payload);
    });

    await this.setPageAttributes();

    if (
      Object.keys(this.$route.params).length > 0 &&
      this.$route.params.partner_id &&
      this.$route.params.partner_slug
    ) {
      this.getPartnerData();
    }

    // refreshFacebookPixel()
  },
  methods: {
    ...mapActions("MainStoreModule", [
      "enableGoogleAnalytics",
      "setUserMonetaryByLocation",
      "setApiToken",
      "getPartner",
      "setDomain",
      "setLocale",
      "getPost",
    ]),

    async setCookies() {
      if (this.cookiesAccepted) {
        if (localStorage.cookiesSelected) {
          this.cookiesSelected = JSON.parse(localStorage.cookiesSelected);

          if (this.cookiesSelected) {
            if (this.cookiesSelected.performance_and_analytics) {
              console.log(">> Setting Google cookies...");
              this.setGoogleCookies(this.domain);
            }
          }
        } else {
          console.log(">> Cookies selection undefined");
        }
      }
    },

    async getBlogPost() {
      /*
        Get post - blog
      */
      const post_slug =
        Object.keys(this.$route.params).length && this.$route.params.slug
          ? this.$route.params.slug
          : null;
      const post_id =
        Object.keys(this.$route.params).length && this.$route.params.id
          ? this.$route.params.id
          : null;

      if (post_slug && post_id) {
        await this.getPost({
          id: post_id,
          slug: post_slug,
        });
      }
    },

    setDefaultLocale() {
      const locale = this.domain.languages[0];

      if (locale !== undefined) {
        this.$i18n.locale = locale;
        this.setLocale(locale);
        this.$moment.locale(locale);
      }
    },

    changeFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = this.domain.favicon;
    },

    changePageMetaTags() {
      if (this.$route.meta.title && this.$route.meta.title[this.lang]) {
        var title = null;

        if (this.page == "blog-post" && Object.keys(this.post).length > 0) {
          title = this.$route.meta.title[this.lang]
            .replace("{post_title}", this.post.title[this.lang])
            .replace("{title}", this.domain.title);
        } else {
          title = this.$route.meta.title[this.lang].replace(
            "{title}",
            this.domain.title
          );
        }

        $("#title").html(title);
      } else {
        console.log(">> Tag title undefined for", this.lang);
      }

      if (this.$route.meta.metaTags) {
        this.$route.meta.metaTags
          .map((tagDef) => {
            const tag = document.createElement("meta");

            if (
              tagDef.name == "description" ||
              tagDef.property == "og:description"
            ) {
              if (tagDef.content && tagDef.content[this.lang])
                if (
                  this.page == "blog-post" &&
                  Object.keys(this.post).length > 0
                ) {
                  tagDef.content = tagDef.content[this.lang]
                    .replace("{title}", this.domain.title)
                    .replace("{company_fullname}", this.domain.company_fullname)
                    .replace("{post_title}", this.post.title[this.lang]);
                } else {
                  tagDef.content = tagDef.content[this.lang]
                    .replace("{title}", this.domain.title)
                    .replace(
                      "{company_fullname}",
                      this.domain.company_fullname
                    );
                }
            }

            Object.keys(tagDef).forEach((key) => {
              tag.setAttribute(key, tagDef[key]);
            });

            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
          })
          .forEach((tag) => document.head.appendChild(tag));
      }
    },

    setPageAttributes() {
      this.path = this.$route.path;
      this.page = this.$route.name;
    },

    async getPartnerData() {
      if (!this.api.token) {
        await this.setApiToken();
      }

      if (this.api.token) {
        if (
          (this.$route.params.partner_id &&
            this.$route.params.partner_slug &&
            !this.partner) ||
          (this.partner && this.partner.id != this.$route.params.partner_id)
        ) {
          await this.getPartner({
            id: this.$route.params.partner_id,
            slug: this.$route.params.partner_slug,
          }).then((response) => {
            const partner = response;
            console.info(">> Response getPartner", partner);

            if (!partner.id || !partner.active) {
              // this.path = this.path.substring(0, this.path.indexOf('/partner/'));
              // this.$router.push(this.path)

              this.$router.push({ name: "NotFound" });
            }
          });
        }
      }
    },

    scrollto(elem) {
      var elemPos = $(elem).offset().top - $(this.$el).offset().top - 200;

      $("html, body").animate(
        {
          scrollTop: elemPos,
        },
        500
      );
    },

    showAlert(data) {
      this.alertData = data;
      this.headerBgVariant = data.bgVariant;
      this.headerTextVariant = data.textVariant;
      var ref = data.buttons == "ok-only" ? "modalAlertOkOnly" : "modalAlert";
      this.$refs[ref].show();
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    setShowOverlay(isPageLoaded) {
      this.isPageLoaded = isPageLoaded;
      this.showOverlay = !isPageLoaded;
    },
  },

  async created() {
    if (!this.api.token) {
      await this.setApiToken();
    }
    await this.setDomain();

    if (this.domain.languages.length == 1) {
      this.setDefaultLocale();
    }

    this.cookiesAccepted = localStorage.cookiesAccepted;
    await this.setUserMonetaryByLocation();

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (this.$route.name == "blog-post") {
      await this.getBlogPost();
    }

    this.changeFavicon();
    this.changePageMetaTags();

    this.setCookies();
    this.enableGoogleAnalytics();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>


<style>
@import "/assets/fonts/colfax/stylesheet.css";
@import "/assets/fonts/icomoon/style.css";
@import "/assets/fonts/montserrat/stylesheet.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");

@import "/assets/style/style.min.css";
</style>
