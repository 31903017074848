<template>
<div class="documentation">

    <ul class="numbered-list mb-5 no-print">

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.terms', 1)">GENERAL CONDITIONS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 2)">YOUR ACCOUNT</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 3)">RETENTION AND EXCLUSION OF YOUR DATA</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 4)">PROCESSING OF PERSONAL DATA, TRANSFER AND CONFIDENTIALITY</span></li>            
                <li class="link"><span @click.stop="scrollto('.terms', 5)">PAYMENT</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 6)">LIMITS OF USE</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 7)">MODIFY OR CANCEL THE SERVICES</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 8)">DISCLAIMER OF WARRANTIES</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 9)">LICENSE AND PROPERTY RIGHTS</span></li>

            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.terms', 10)">RESPONSIBILITIES FOR SERVICES</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 11)">CONTENT</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 12)">TERMINATION</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 13)">MODIFICATIONS OF THIS TERM</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 14)">APPLICABLE LAW</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 15)">DISPUTES</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 16)">COMBATING CORRUPTION AND MONEY LAUNDERING</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 17)">SURVIVING PROVISIONS</span></li>

            </div>
        </div>
    </ul>
    
    <div class="intro mb-5 d-flex flex-column justify-content-center align-items-center">
        <p>Standard Subscription Agreement - Version 1/2021 - February 18, 2021</p>
        <p>This Agreement will enter into force on the first date you agree to, access or use the Contracted Services of {{company.company_fullname}}., A private company, established at {{`${company.address1} ${company.address2}`}}, (“ Contractor ”) and You (“ Contractor ”).</p>
        <p>This is a legal agreement between you (Contractor) and {{company.company_fullname}} (Contractor), it describes the terms that govern the use of {{company.company_name}} online services, provided to you through this Site / Portal, including content, updates and new releases, and is identified as “Agreement”.</p>
        <p>If you are entering into this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that entity and its affiliates to these terms and conditions as your legal and authorized representative, and that you have read and understood this agreement. In this case, the terms 'You' or 'Yours' will refer to such entity and its affiliates, as well as to you.</p>
        <p>By accepting electronically by clicking "I agree", accessing or using the Services, you irrevocably agree to this agreement and the Privacy Policy available on this Site.</p>

        <br>

        <p><b>DEFINITIONS</b></p>
        <p><b>Users</b>: They are all individuals that are authorized by the Contractor through the release of login and password to access and use the services of the Platform. "Users" may be direct employees, service providers, contractors, consultants, etc ... with which the Contractor does business. </p>
        <p><b>Services</b>: It is the set of tools and functionalities provided online by the Contractor through the access account created by you or by one of our accredited business partners, in one of our web portals. </p>
        <p><b>Data</b>: It means any and all data or documents collected, processed and maintained by/for the Contractor, using the Solutions made available on the Platform by the Contractor.</p>
        <p><b>Intellectual Property</b>: All trade secrets, registered or unregistered trademarks for a product or service, patents and patent applications, trade names, copyrights, morals, inventions or intellectual property and all other rights of the Contractor anywhere in the world.</p>
        <p><b>Privacy Policy</b>: It means the rules and practices that regulate the way the Contractor treats the privacy of information that is processed on the Online Services Platform, specified in the following link: https://{{company.company_name}}.io/privacy-policy</p>
        <p>The Privacy Policy is linked to this service subscription acceptance suit and contains information about how we collect and process personal data through our websites and services.</p>
        <p><b>Online platform:</b> It means the virtual environment, through which the user, after creating the workspace, can access and use the services of the contractors.</p>
        <p><b>Malicious code:</b> They are data files, programs, source code, whose purpose is to cause damage to the functioning of the platform, such as viruses for example.</p>
        <p><b>Invoice:</b> It is the document issued to collect the services rendered monthly. It is also used as a tax document.</p>
        <p><b>Contract:</b> It is equivalent to this term of subscription agreement for the services provided by the Contractor.</p>
        <p><b>Documentation:</b> It means the help and training materials provided by the Contractor, to the Contractor's users, and updated periodically by the Contractor. </p>
        <p><b>Virtual account:</b> is the account used by the Contractor to access the services provided by the Contractor, which is performed online only by users authorized by the Contractor, through which all contracted functionalities are made available.</p>
        <p><b>Third-party services:</b> means other services that can be used in conjunction with the solutions made available through the online platform, for which the Contractor will not be responsible for the operation or interference with the services contracted under this term.</p>
    </div>

    <ul class="terms numbered-list">

        <li class="item">
            <p><b>GENERAL CONDITIONS</b></p>
            <p>The Contractor shall make available, upon formalizing the adhesion to the services through the acceptance of this Signature of Agreement Term (Contract) and of the Privacy Policy as applicable: a) The solution acquired by the Contractor in accordance with the plan selected at the time of online adhesion; b) Standard technical support to meet the solutions acquired, at no additional cost to the Contractor; c) Will keep the acquired solution available online 24 hours a day, 7 days a week, except for the previously scheduled downtime, or any unavailability caused by circumstances beyond the Contractor's reasonable control, including failures or problems with the internet provider and/or infrastructure, problems with connections external to the contracted solution, or related to the blocking of services, whatever the cause.

            <p>The Services may include components, systems and other third party services, in which additional terms, policies or requirements from other suppliers, including age requirements, may apply.</p>
            <p>The Privacy Policy available on this website is an integral part of this Agreement. The Contractor will maintain administrative, physical and technical protections to maintain the security, confidentiality and integrity of the stored content, considering a reasonable and acceptable market standard. You must follow the policies made available to you within the Services, and do not misuse, interfere with the use of the Services or attempt to access them using a method other than the interface and the legal instructions and methods we provide.</p>
            <p>You may use the services only as permitted by law. We may suspend or stop providing the Services if you breach our Terms or Policies, or any applicable laws, or if we are investigating cases of suspected misconduct.</p>
            <p>You must agree not to use, or allow any third party to use the Services or the content in a manner that violates any law, the rules of this agreement and the privacy policy.</p>
            <p>You agree that you will not:</p>
            <p class="pl-5 mb-1">- Provide access to or provide any part of the Services to third parties not linked to your business.</p>
            <p class="pl-5">- Reproduce, modify, copy, deconstruct, sell, sell or resell the Services, unless you are a reseller accredited by the Contractor and authorized by the Contractor in writing.</p>
            <p>The Services are protected by copyright, trade secrets and other intellectual property laws that include, among others, any brands or logos used on websites. </p>
            <p>You are only entitled to use the Services only for the purposes described in this subscription agreement.</p>
            <p>The Contractor reserves all other rights in the Services, whether owned or owned by third parties until termination of this Agreement and provided that you comply with all applicable payment obligations and are in compliance with this Agreement, the Contractor grants you a right limited, non-exclusive, non-transferable, and license to use the Services.</p>
            <p>We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers in accordance with the procedures set forth in American Copyright Laws. We provide information to help copyright holders manage their intellectual property online. </p>
            <p>If you understand that someone is infringing your copyright and want to notify us, you can send your notification to the following email: info@{{company.company_name}}.io. The Services allow you to send content, data and documents for storage. You retain ownership of any intellectual property rights that you hold over that content or document. In short, what belongs to you remains with you.</p>
        </li>

        <li class="item">
            <p><b>YOUR ACCOUNT</b></p>
            <p>You must create an account to use the Services. You may create your own account on the website or it may be assigned to you by a Contractor Partner administrator. If you are using an Online Services Platform Account assigned to you by an administrator, different or additional terms may apply, and your administrator may be able to block or disable your account.</p>
        </li>

        <li class="item">
            <p><b>RETENTION AND EXCLUSION OF YOUR DATA</b></p>
            <p>The Contractor's data will be stored in the Contractor's database, as long as the account has been created and this contract is valid. According to the privacy policy, the data will be deleted (permanently deleted) within 90 days after the end of the contract.</p>
        </li>

        <li class="item">
            <p><b>PROCESSING OF PERSONAL DATA, TRANSFER AND CONFIDENTIALITY</b></p>            
            <p>You can view the Contractor's Privacy Statement provided from our website. You must agree to the Privacy Policy and any changes posted by the Contractor. You must agree that the Contractor may use and maintain your data in accordance with the Privacy Statement published by the Contractor, as part of the Services. You grant the Contractor permission to combine identifiable and non-identifiable information that you enter or upload to the Services with that of other users of the Services and / or other services of the Contractor. This means that the Contractor may use its users' aggregated data to improve the Services or to design promotions and provide ways to compare business practices with other users. The Contractor is a global company and may access or store personal information in several countries, as specified in our Privacy Policy.</p>
            <p>Contractor and Contractor undertake to treat information classified legally as personal data, as a result of this contract, observing the applicable legislation, under penalty of liability for breach of contract, without prejudice to losses and damages. The processing of personal data will only take place in compliance with the purpose established in this contract.</p>
            <p>The parties undertake not to transfer and / or share the processed personal data with third parties, unless it is an essential requirement to comply with this term or to comply with legal / judicial obligations, however, even if one of the parties is legally obliged to transfer data to third parties, must notify the other party and ensure the confidentiality and integrity of the data under penalty of liability. Information about whom we share data to provide our services can be found in our Privacy Policy.</p>
            <p>The Contractor will be responsible for the fulfillment of this contract by its users, will be responsible for the accuracy, quantity, quality, legality of its data and the means by which it acquired it, and for the methods and way of processing its stored information, and will make its best efforts to guarantee the use of the platform in accordance with the applicable regulations, both with regard to the legality, privacy, integrity, confidentiality and security of the processed data, and to use the services in accordance with the purpose specified in these terms of agreement signature.</p>
            <p>If the Contractor connects other applications to our services, and uses them in conjunction with our solutions, the Contractor is aware and agrees that the provider of that application, regardless of how the connection is being made, may access and copy data from the Contractor, as needed to operate the application. The Contractor is not responsible for any incident, disclosure, modification, or deletion of data resulting from access by a third party authorized by the Contractor.</p>
            <p>If the Contractor identifies that a connected application does not provide reasonable conditions for interoperating with the contracted solution, we may cease to provide access to the third party, without any penalty, refund or compensation by the Contractor. The Contractor is not obliged to evaluate the applications of third parties, however, it may do so if deemed pertinent.</p>
        </li>

        <li class="item">
            <p><b>PAYMENT</b></p>
            <p>The Contractor shall pay in US dollars, all the amounts specified in the Virtual Account related to the subscription of the initial purchased plan plus the excess consumption amounts, if any.</p>
            <p>By providing payment information, you authorize the Contractor or third parties hired by us to process payments, to charge on such credit/debit card the amounts corresponding to the contracted solution according to Invoice made available in each closing cycle.</p>
            <p>You must pay with one of the following methods:</p>
            <p class="pl-5 mb-1">1. A valid credit card acceptable to the Contractor; </p>
            <p class="pl-5 mb-1">2. A valid and acceptable contracted debit card; </p>
            <p class="pl-5">3. For another payment option that the Contractor provides to you in writing or made available in the settings of your subscription plan.</p>
            <p>Option 1 is the most usual and valid for charges for services provided to any country, however, for some countries we can provide options 2 or 3 above.</p>
            <p>If your payment and registration information is not accurate, current and complete and you do not update it whenever it changes, we may suspend or terminate your account and refuse to use the Services.</p>
            <p>If you do not notify us of updates to your payment method (for example, credit card expiration date), to avoid interrupting your service, we may participate in programs supported by your card provider (for example, update services , recurring billing programs, etc.) to try to update your payment information, and you authorize us to continue to bill your account with the updated information we obtain, however, this will not be a contracted party obligation.</p>
            <p>The Contractor will automatically renew its monthly or annual Services at the rates in effect at the time, unless the Services are canceled or terminated under this Agreement.</p>
            <p>If no amount billed by the Contractor is received on the due date, without prejudice to other applicable measures being taken: a) interest of 1% of the outstanding balance per month will be charged, or the maximum rate permitted by law, whichever is less , and/or b) suspend the services provided when the delay exceeds 10 (ten days), c) there has been a delay of more than 30 (thirty) days, it is expressly agreed that the Contractor may use the services of outsourced companies to collect the amount due, including legal charges provided for by law, and/or d) report the default to credit protection agencies and entities and/or e) Eliminate the Contractor's account, after 40 (forty) days of default without resolution by the party of the Contractor.</p>
            <p>In cases of material breach of the Contract, the suspension will be effective immediately, regardless of prior notification.</p>
            <p>Without prejudice to the above, if the Contractor is contesting the applicable charges in a clear, reasonable, good faith manner and is cooperating diligently to resolve the dispute, the Contractor while the negotiations last, will make its best efforts to resolve the dispute in the most efficient manner. possible and avoid the measures mentioned above.</p>
            <p>The Contractor agrees that its purchases are not conditioned on any expectation related to the delivery of future features, to be developed in relation to the solution, even if the Contractor has made some oral or written comment on planned features.</p>
            <p>As this Contract is terminated, the Contractor is hereby authorized to delete the Contractor's Virtual Account permanently, by notification by e-mail to the Contractor.</p>
        </li>

        <li class="item">
            <p><b>LIMITS OF USE</b></p>
            <p>When creating your account, you will select the initial plan of the solution, where the resources contemplated in your contracting, the functionalities, the amount of storage space and the number of users will be defined. Optional items can be included in the contract, or later by the user who is the workspace administrator.</p>
            <p>While this contract is active, the Contractor will not limit the consumption of the Contractor's users, therefore, although it has an initial service package, you will be able to register and leave as many users as you wish, if you are exercising a platform administrator role, and the users will not be limited in terms of storage volume, therefore, the total consumption that includes the value of the initial package will be counted monthly, and all surplus items, whether in number of active users, in storage volume or in additional items, which added together will account for the total monthly invoice. Therefore, if there are surpluses, regardless of the Contractor's prior approval, the Contractor will be authorized to carry out the pro rata collection of the monthly package including the additional quantities.</p>
            <p>The workspace administrator can change his service plan to a plan larger than his current consumption or a plan with different features and tools, however he should be aware that for this second scenario, if the workspace used a plan containing a certain amount of features and migrate to a plan that contains fewer features, all functions NOT covered by the new plan will no longer be available to users.</p>
        </li>

        <li class="item">
            <p><b>MODIFY OR CANCEL THE SERVICES</b></p>
            <p>We are constantly changing and improving the Services. We may add or remove functionality or features and we may also suspend or terminate a Service entirely. You can stop using the Services at any time, although it will be unfortunate to see you go.</p>
            <p>The Contractor may also cease to provide the Services to you, or include, or create new limits to, the Services at any time. We believe that you are the owner of your data and that it is important to preserve your access to that data. If we discontinue a Service, when reasonably possible, you will be informed in advance and given the chance to withdraw your information from our Services.</p>
        </li>

        <li class="item">
            <p><b>DISCLAIMER OF WARRANTIES</b></p>
            <p>We provide our Services using a reasonable level of capacity and care and we hope you will enjoy using them. But there are some things that we don't promise about the Services. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS OR IN ADDITIONAL TERMS, NEITHER THE CONTRACTOR, NOR ITS SUPPLIERS, NOR ITS PARTNERS OR DISTRIBUTORS MAKE CERTAIN WARRANTIES ABOUT THE SERVICES. FOR EXAMPLE, WE ARE NOT RESPONSIBLE FOR THE CONTENT IN THE SERVICES, FOR SPECIFIC FEATURES, AVAILABILITY OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE SERVICES “AS IS”. CERTAIN JURISDICTIONS PROVIDE CERTAIN WARRANTIES, SUCH AS THE IMPLIED MERCHANTABILITY GUARANTEE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</P>
            <p>PURSUANT TO THIS AGREEMENT, NONE OF THE PARTIES MAKES ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES, INCLUDING VIOLATION, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. EACH PARTY IS EXEMPT FROM ALL LIABILITY AND COMPENSATION OBLIGATIONS FOR LOSSES OR DAMAGES CAUSED BY ANY THIRD PARTY HOSTING AND APPLICATION PROVIDERS.</P>
        </li>

        <li class="item">
            <p><b>LICENSE AND PROPERTY RIGHTS</b></p>
            <p>The Contractor reserves all its rights, title and interest related to the Solutions, including all its related Intellectual Property rights.</p>
            <p>The Service is made available with limited access and no ownership rights are transferred to the Contractor. The Contractor has and maintains all rights, titles and interests, including all intellectual property rights, in and for the service, including all modifications, updates, upgrades, extensions, components and all derivative works of the services. </p>
            <p>The Contractor grants the Contractor and the Contractor's Affiliates or Partners a worldwide license, with a limited term for the duration of this subscription term, to copy, transmit, host and display their Data, including with source code when they are created by or for the Contractor, as well as for third party applications, as necessary for the Contractor to provide access to Solutions in accordance with this contract.</p>
            <p>The Contractor may use this Data collected for the purpose of gathering information and anonymized metrics to improve and improve the Services and for other purposes of development, diagnosis and correction of the product that makes up the service, provided that it is anonymized, without the identification of the Contractor.</p>
            <p>The Contractor grants the Contractor a worldwide, definitive, irrevocable and royalty-free license to incorporate and use in the solutions, new features and functionality that have been developed at the request or at the suggestion of the Contractor and/or its users.</p>
            <p>THE CONTRACTOR, ITS SUPPLIERS, PARTNERS AND DISTRIBUTORS, DISCLAIM ANY STATEMENTS OR WARRANTIES THAT YOUR USE OF THE SERVICES SATISFIES OR GUARANTEES COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.</p>
        </li>

        <li class="item">
            <p><b>RESPONSIBILITIES FOR SERVICES</b></p>
            <p>WHEN PERMITTED BY LAW, THE CONTRACTOR AND SUPPLIERS OR PARTNERS AND DISTRIBUTORS SHALL NOT BE RESPONSIBLE FOR LOST PROFITS, LOSS OF REVENUE, LOSS OF DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITARY DAMAGES. TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF THE CONTRACTED PARTY AND ITS SUPPLIERS, PARTNERS AND DISTRIBUTORS, FOR ANY CLAIM UNDER THESE TERMS, INCLUDING ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAYED FOR (12) USE OF SERVICES. IN ALL CASES, THE CONTRACTOR AND ITS SUPPLIERS, PARTNERS AND DISTRIBUTORS SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORECASTABLE.</p>
            <p>If you are using our Services on behalf of a company, that company must accept these terms. It will disclaim liability and indemnify the Contractor and its affiliates, partners, distributors, executives, agents and employees from any claim, lawsuit or lawsuit arising out of or related to the use of the Services or the violation of these terms, including any liability or expense resulting from claims , losses, damages, lawsuits, judgments, litigation costs and attorney's fees, under these Terms.</p>
            <p>If the Contractor is the victim of a lawsuit alleging that the use of the Contracted Services violates or results from the misappropriation of third party intellectual property rights, the Contractor will defend the Contractor against any claim, demand or lawsuit, and indemnify the Contractor for any damages, expenses and costs attributed to an unfavorable decision to the Contractor by any court as a result of the claim against the Contractor, provided that the Contractor promptly notifies the Contractor in writing within a reasonable time for defense and resolution, and gives exclusive control and support to the Contractor to defend against any claim against the Contractor related to issues of intellectual property infringement arising from the provision of the services, or that the Contractor assumes its own defense and unconditionally exempts the Contractor from any and all liability. If the Contractor allows the Contractor to take sole control for resolving the demand at the Contractor's expense, the Contractor may define which solution is the most effective for resolving the demand, and may even choose, at its discretion, to modify the solution so that it does not infringe or deviate the intellectual property rights of third parties, if it identifies any deviation, without violating the Contractor's warranties, including the option to terminate this subscription contract with 30 (thirty) days prior notice.</p>
            <p>If the Contractor is sued for infringement of intellectual property caused by third party applications connected to the Services covered by this Agreement, the Contractor shall be exempt from any liability, and the obligations of defense and indemnification to the Contractor will NOT be applied to the Contractor.</p>
            <p>If the Contractor is the victim of a claim, demand, action or lawsuit filed by a third party, on the grounds that the Data stored and processed by the Contractor or the way it uses the services and processes the data, violates this agreement, or infringes any regulation or law, where applicable, including breach of third party intellectual property, the Contractor shall indemnify the Contractor for any damages, costs, expenses arising from the defense or the result of a sentence that may be attributed to the Contractor on account of such demand, either by reason of a court decision or an extra-judicial agreement, provided that the Contractor gives immediate and written notice to the Contractor, as soon as it is aware and identifies that the cause of the claim is related to violations or possible violations by the Contractor and that the Contractor gives the Contractor exclusive control of the defense upon request by the Contractor in a timely manner , with the proviso that the Contractor cannot enter into an agreement on behalf of the Contractor, unless this agreement releases the Contractor unconditionally from any and all liability and is previously approved in writing by the Contractor. The Contractor must provide the Contractor with reasonable assistance, at its expense, so that it can promote the Contractor's defense.</p>
        </li>

        <li class="item">
            <p><b>CONTENTS</b></p>

            <p>You are responsible for all materials, documents, data and personal information ('Content') uploaded, posted or stored through the use of the Services. You grant the Contractor a worldwide, royalty-free, non-exclusive license to host and monitor any Content provided through the use of the Services.</p>
            <p>You must use best practices for storing your content because you are responsible for any content lost or unrecoverable due to malpractice and misuse of the Service. The Contractor is not responsible for the Content or data sent through the Platform on which we provide the Services. You agree not to use or allow third parties to use the Services to upload, publish, distribute, link, reproduce, involve or transmit any of the following content, including, but not limited to:</p>
            <p class="pl-5">a) Illegal, fraudulent, defamatory, obscene, pornographic, profane, threatening, abusive, offensive, inappropriate or objectionable, or communications of any kind, including, without limitation, conduct that encourages the 'inflammation' of others, or breaks any law local, state, federal or foreign, in the criminal or civil sphere; b) Content that impersonates another person or falsely represents their identity or qualifications, or that constitutes a violation of the privacy of any individual; c) Except when permitted by the Contractor in writing, content such as: chain letters, pyramid schemes, other unsolicited commercial communications or engaging in spamming or flooding; d) Virus, Trojan horse, worm or other disruptive or harmful software or data; e) Any information, software or Content that is not legally yours and without the permission of the copyright owner or owner of intellectual property rights or the data subject, when applicable.</p>
            <p>The Contractor may, but has no obligation to monitor the content of the Services. We may disclose any information necessary to satisfy our legal obligations, protect the Contractor or its customers, protect privacy, promote safety and protect life, or operate the Services properly.</p>
            <p>If the Contractor is required by law to send you communications about the Services or products of third parties, you agree that the Contractor may send these communications to you via email or by posting them on our websites or from our applications which you have access, respecting the confidentiality of the information.</p>
            <p>The receiving party of confidential information may only disclose it when required by law, regulation or court or administrative order, and is required to promptly notify the other party prior to disclosure, in order to facilitate efforts to protect confidential information. The receiving Party may only disclose the portion of the information to which it is obliged, preserving the confidentiality of other information.</p>
            <p>When disclosure is made pursuant to the paragraph above, the disclosing party will not be responsible for the disclosure of confidential information.</p>
            
        </li>

        <li class="item">
            <p><b>TERMINATION</b></p>

            <p>The Contractor may immediately, at its sole discretion and without notice, terminate this Agreement or suspend the Services if you fail to comply with the terms of this Agreement or if you no longer agree to receive electronic communications. Upon termination, you must immediately stop using the Services and any outstanding payments will be due. Any termination of this Agreement will not affect the Contractor's rights to any payments due to it.</p>
            <p>The Contractor may suspend the use of the Services, remove content or disable and even close any virtual account, if the Contractor reasonably believes, in good faith, that the Contractor or any of its users are in breach of the terms of this agreement and the privacy policy. The Contractor shall notify the Contractor before suspending or deactivating the account, unless it is legally prevented from doing so. In a situation of impediment, the Contractor shall notify the Contractor when the impeding restrictions cease to have legal effect.</p>
            <p>The contractor may terminate this contract, without any motivation, by giving notice of at least 30 (thirty) days, without incurring a termination penalty.</p>
            <p>Either party can terminate this contract if one of the reasons is identified:</p>
            <p class="pl-5 mb-1">- One of the parties goes into bankruptcy or bankruptcy.</p>
            <p class="pl-5 mb-1">- If a material breach proven in accordance with this contract is not remedied.</p>
            <p class="pl-5">- In situations of force majeure, disasters, pandemics, natural events that demonstrably prevent the continuity of the provision of services.</p>
            <p>Any of the contractual termination hypotheses specified above, requires a minimum notice of 30 (thirty) days, without prejudice to the Contractor's receipt of the amounts due by the Contractor up to the effective date of the termination. No refund will be due to the Contractor.</p>
            
        </li>

        <li class="item">
            <p><b>MODIFICATIONS OF THIS TERM</b></p>

            <p>We may modify these terms or any additional terms that apply to the Service to, for example, reflect changes in the law or changes in our Services. You should check the terms regularly. We will post notices of changes to these terms on this page. We will post a notice of change regarding additional terms within the applicable Service. The changes will not be applied retroactively and will take effect at least fourteen days after their publication. However, changes regarding new features of a Service or changes made for legal reasons will take effect immediately. If you do not agree to the amended terms for a Service, you must discontinue using that Service. These terms govern the relationship between the Contractor and you. </p>
            <p>The amounts charged for the services can be changed by the Contractor, who must inform the Contractor through the electronic address provided as responsible for the virtual account and the changes will affect the value of the services from the next renewal of the monthly subscription.</p>
            <p>The Contractor may notify the Contractor of general information regarding the use of the Services or any communication that may be necessary, by means of the electronic mail registered by you on the Platform to the administrator of the virtual account and to the billing e-mail, when dealing with billing information. It is the Employer's responsibility to keep the virtual account data up to date. It is necessary that the e-mails sent from the domain of the Platform must be configured as safe so that they are not treated as spam by the e-mail service used by the Contractor, as all information sent will be considered delivered from 1 business day after the shipping. </p>
            <p>Service maintenance notices, scheduled interventions, technical issues of interest to users, availability of new features, can be sent directly to users, optionally by the Contractor.</p>
            <p>To communicate with the Contractor, the Contractor may use the following means:</p>
            <p class="mb-1"><b>Platform chat:</b> for direct service to users of the service to clarify doubts, technical support and usage guidance.</p>
            <p class="mb-1"><b>E-mail support@{{company.location}}:</b> for opening technical support calls, suggestions for improvements, clarification of doubts regarding configurations and use of services.</p>
            <p class="mb-1"><b>Questions related to data processing:</b> dpo@{{company.location}}</p>
            <p><b>Legal email@{{company.location}}:</b> for matters related to other requests, including termination of this subscription term or legal issues.</p>
            
        </li>

        <li class="item">
            <p><b>APPLICABLE LAW</b></p>
            <p>The Contractor agrees that Florida / USA state law governs this Agreement without regard to conflicts of legal provisions, regardless of the choice of another jurisdiction, however beneficial it may be.</p>            
        </li>

        <li class="item">
            <p><b>DISPUTES</b></p>
            <p>ANY DISPUTE OR CLAIM RELATING TO THE SERVICES OR THIS AGREEMENT WILL BE RESOLVED BY BINDING ARBITRATION, INSTEAD OF CUT, except that you can claim in small claims court if your claims qualify. The Federal Arbitration Act regulates the interpretation and application of this provision; the arbitrator will apply Florida / USA law to all other matters. Notwithstanding anything to the contrary, any party to the arbitration may, at any time, request injunctions or other forms of equitable redress from any court of competent jurisdiction.</p>
            <p>This Agreement, including the Privacy Policy, is the entire agreement between you and the Contractor and supersedes all previous understandings, communications and agreements, oral or written, with respect to the matter in question. If any court, having jurisdiction, determines that any part of this Agreement is invalid, that section will be removed without affecting the rest of the Agreement. The remaining terms will be valid and applicable.</p>
            <p>The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Contract. You may not assign or transfer ownership of this Agreement to anyone without the Contractor's written approval. However, the Contractor may assign or transfer it without your consent to (a) a partner, (b) a company through a sale of assets by the Contractor or (c) a successor through a merger. Any assignment that violates this Section will be void.</p>
            <p>THE CONTRACTING PARTY, HEREBY DISCLAIMS ANY RIGHT TO FORM ANY ORDER AS A REPRESENTATIVE OR ATTORNEY OF A CERTAIN CLASS IN A MASS OR AGGREGATE BASE, OR TO CONSOLIDATE THE ARBITRATION PROCESS WITHOUT THE CONSENT OF ALL PARTIES. Any award made by the arbitrator will be final, conclusive and binding on the parties. In connection with any arbitration procedure in accordance with these terms and conditions, unless the arbitrator determines otherwise, each party will bear its own costs and expenses. Upon confirmation of an arbitral award in Federal Court or the Florida State Court, the conviction can be enforced in any court of competent jurisdiction. Notwithstanding the foregoing, this section shall not prevent the Contractor from seeking an injunction, or other remedy in equity, in any court of competent jurisdiction.</p>
            <p>This contract is made by independent parties and does not create a partnership, association, partnership, franchise, joint venture or employment relationship.</p>
            <p>If you do not comply with these terms and we do not take immediate action, it does not mean that we are waiving any rights that we may have (such as taking future action). If a specific condition of this term is not enforceable, it will not affect any other provision in these terms.</p>            
        </li>

        <li class="item">
            <p><b>COMBATING CORRUPTION AND MONEY LAUNDERING</b></p>
            <p>According to what determines the limits of the contractual relationship stipulated by this Term of Signature, the parties undertake, on their own, for their employees, partners, representatives, directors, associates, third parties, affiliates, and any person who comes to act in your name, to conduct your business practices, for the entire period that this contract is active, in an ethical manner and in accordance with the applicable legal precepts, not being able to make promises of payments or payments, donations, or any benefits or pleasure, directly or indirectly to any third party, government representatives, public or private entities with the purpose of influencing an attitude or decision making to ensure any undue, illicit, irregular and/or unfair advantage. Both parties declare to be aware of and undertake to faithfully comply with the legal provisions related to combating and preventing the crime of “laundering” and concealment of assets.</p>            
        </li>

        <li class="item">
            <p><b>SURVIVING PROVISIONS</b></p>
            <p>The parties agree that sections 3, 4, 5, 8, 9,10,11, 14 and 15 will survive any termination or expiration of this agreement.</p>            
        </li>
    </ul>

    <br>
    <p>Each party declares that it has validly entered into this signature term (Agreement) and has the legal power to do so.</p>            
    <br>
    <br>
    

</div>

</template>

<script>export default {     
    props: ['company'],
    inject: ['scrollto']
}
</script>

<style scoped lang="scss">


</style>