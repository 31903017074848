<template>
<div class="documentation">

    <ul class="numbered-list mb-5 no-print">

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.policy', 1)">Definitions</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 2)">What are your rights as a data subject</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 3)">What data we treat</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 4)">What we use each data for</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 5)">How we collect and what we use each piece of data</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 6)">Data that is optionally collected</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 7)">Data collected from third parties</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 8)">As an OPERATOR, as {{company.company_name}} handles the documents and data stored by you on the platform:</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 9)">Sharing with third parties</span></li>

            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.policy', 10)">Blog and Community</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 11)">International Data Sharing:</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 12)">Data processing and retention time</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 13)">Consent</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 14)">Correct, Update or Delete your information</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 15)">Privacy rights for California users</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 16)">Children's personal information</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 17)">Legal basis for the processing of personal data</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 18)">General provisions</span></li>

            </div>
        </div>
    </ul>
    
    <div class="intro mb-5 d-flex flex-column justify-content-center align-items-center">
        <p>
            This privacy Policy describes the procedures used by the
            {{company.company_name}} for processing Personal Data. It applies to all forms
            of data collection we apply to provide our services.                
        </p>

        <p>
            For a better understanding of our Policy, we describe below the
            definitions of the terms used:
        </p>
    </div>

    <ul class="policy numbered-list">

        <li class="item">
            <p><b>Definitions</b></p>

            <p><b>Personal data:</b> It is the information that allows you to identify a natural person directly or indirectly through data intersection.Example: Identity number, CPF, address, photography, religious option, partisan, date of birth, health data, biometrics, etc.</p>
            <p><b>Holder:</b> It is the owner of the data, that is, the natural person to whom the data refers.</p>
            <p><b>Data processing:</b> It is any action performed with personal information, for example: capture, visualization, sharing, reception, editing, elimination, transfer, etc.</p>
            <p><b>Goal:</b> Objective to achieve with the treatment of data carried out.</p>
            <p><b>Controller:</b> Is the individual or legal person indicated by the {{company.company_name}} in order to answer questions and ensure that data processing is carried out in accordance with what applicable legislation, respecting the right of holders.</p>
            <p><b>Operator:</b> It is the physical or legal person who performs data processing on behalf of the controller.</p>
            <p><b>Third:</b> Personal or legal entity of public or private law with which {{company.company_name}} is related or to relate.</p>
            <p><b>In charge:</b> It is the individual or legal person indicated by {{company.company_name}} to answer questions and ensure that data processing is being carried out according to what determines LGPD, respecting the right of holders.</p>
        </li>

        <li class="item">
            <p><b>What are your rights as a data holder</b></p>

            <p>- As a data holder, you have the right to have facilitated access to information on the treatment of your data, which should be made available clearly, adequately and ostensiva; </p>
            <p>- You can confirm the existence of treatment;</p>
            <p>- You can request and have access to the data;</p>
            <p>- If you identify that data is incomplete, inaccurate or outdated, you can request the correction of the data;</p>
            <p>- {{company.company_name}}} will not save unnecessary data, but if any data is considered excessive you can request anonymity, block or data elimination, including whether they are being treated in uncomfortable with the provisions of applicable legislation;</p>
            <p>- If you need your data to be transferred to another vendor, you can apply for the portability of service or product data, upon express request, according to the regulation of the national authority, observed commercial and industrial secrets;</p>
            <p>- If you gave us consent to treat your data, you can request the elimination of them except in the hypotheses provided for in applicable legislation;</p>
            <p>- We will inform you about which public or private entities {{company.company_name}} shared your data when sharing occurs.</p>
            <p>- If you wish not to provide us with your consent for data processing, we will let your decision and let us know if your decision causes some consequence for non-supply;</p>
            <p>- You have the right to revoke your consent, through express manifestation, free and facilitated;</p>
            <p>- If necessary, you have the right to petition before the ANPD, before the consumer protection agencies and before the regulatory institutions of the issues related to the protection of personal data, in relation to your data, if they are treated inadequately by the {{company.company_name}};</p>
            <p>- As a data holder you can oppose to data processing based on one of the hypotheses of consent dispensing in case of noncompliance with the provisions of applicable legislation;</p>
            <p>- If {{company.company_name}} Treat data automated, and make decisions based solely on this treatment, and these decisions affect your interests, you can request the revision of such decisions.</p>
            <p>- At any time you can apply for clear and appropriate information on the criteria and procedures we use for data processing with automated decisions, provided that the commercial and industrial secrets are observed;</p>
            <p>To exercise your rights related to data protection, send us your request for e-mail dpo@ ({company.location}} and our data protection, Mrs. Lecivania Martins will take the necessary measures to meet your request.</p>
            <p>If you exercise your right and withdraw your consent to data processing, and there is no other legal basis that supports processing, we will fail to process your data for the consentive goal.</p>
            <p>Any information you provide on blogs, websites, and that is exposed on the Internet in places without access control, in a public way, we can not guarantee the protection of such information and neither limit who can copy or share such data.</p>
        </li>

        <li class="item">
            <p><b>What data we treat</b></p>
            <p>{{company.company_name}}} is a provider of online document distribution and automation of business processes based on Enterprise Content Management, BPM (Business Process Management) and Digital Signature and Electronic Document Subscription Services . Our services are provided online with direct contracting by user companies or through business partners and distributors. We treat the data needed to activate and maintain our services in operation, ensure security, service availability, diagnose technical problems, remember information that are useful and contributed to better use of service, send maintenance notifications and alerts that are required To communicate to users, as well as data that allow us to provide technical support to users, provide training, good practice guidelines, inform users about improvements and new features, offer complementary services that are relevant to users, or new services available For those who are not yet a user.</p>
            <p>Each individual or legal entity that contracts our services can store and process data using the features available on our platform, therefore, they are identified as CONTROLLER, and {{company.company_name}} starts to act as OPERATOR. In this condition, we have the following powers over the data: keep it safe and accessible, delete it or provide a copy of the data, upon an express request from the CONTROLLER, respecting the settings defined by the CONTROLLER and its responsible. As OPERATOR, {{company.company_name}} undertakes to treat classified information as personal data strictly following the criteria defined by the CONTROLLER and specified in the GDPR (General Data Protection Regulation), LGPD (General Law and Data Protection) and the other regulations to which it is subject, solely for the purpose of the purposes defined in the terms of use, and {{company.company_name}} cannot be held liable to the National Data Protection Authority - ANPD and / or other authorities, and to the Holders of Data or any other institution empowered to apply administrative or judicial penalties and sanctions.</p>
            <p>If the {{company.company_name}} is penalized for operator's to protect the rights of {{company.company_name}}.</p>
            <p>{{company.company_name}} and groups belonging to the group has no control of which data users store according to item 8 of our Terms of Use (<a :href="`https://${company.location}/terms-and-conditions/${lang}`" target="_blank">https://{{company.location}}/terms-and-conditions</a>).</p>
            <p>To use our services you provide the following information that is considered as personal data and for which {{company.company_name}} has responsibility as a controller:</p>
            <p>In the act of hiring services or as a platform user, or when you access our sites and send us a personal curriculum, you offer us your name, email, phone, position and curriculum data (curriculum data vary according to each holder).</p>            
        </li>

        <li class="item">
            <p><b>What we use each data for</b></p>
            <p><b>Name and e-mail:</b> </p>
            <p>We use this data to send the initial notification for creating your desktop, we can subsequently send service related to the service, maintenance needs, updates, or communicates of legal obligations, information produced by the marketing area related to new products and functionalities, offers, or any other that is necessary to improve the service or marketing objective.You can choose not to receive marketing messages by marking the option that will be made available in the body of the email;If we receive e-mail communication, we can retain the message, including our answers and other messages answered by you for maintaining service history maintenance. </p>            
            <p><b>Phone number:</b></p>
            <p>We collect the phone number to hold later contacts from WhatsApp or other messaging applications with a goal of support requests to support more quickly and accurately, and / or for sending communications and marketing material, or forpurposes of contacts by our financial department, when appropriate and necessary.</p>
            <p>We will not add you to any groups, but we can send the invitation to make you decide if you want to be part of our groups of disclosure, marketing and support.</p>
            <p><b>Resume</b>: </p>
            <p>The resumes sent by data subjects are stored in our database for 24 months and are used to select employees for our hiring processes, when a new vacancy is opened.</p>
            <p><b>Roles:</b> </p>
            <p>The position is stored in the register of customers and partners so that we can identify the roles of each person with whom we have a relationship, this is so that we can address the right people to deal with pertinent matters, when necessary.</p>            
            <p><b>As for the Payment Data:</b></p>
            <p>The payment data are used for the purpose of processing the collection of the monthly value of the services, however, when the payment option by credit and / or debit card is selected, {{company.company_name}} does not store them, it associates them a subscription ID on Stripe or Paypal which collects and maintains the data for processing the payment. {{company.company_name}} maintains only the associated ID. Our subprocessors have their own privacy policies.</p>            
            <p>For the other payment methods that we make available, we collect and maintain billing information, a subscription record and payment records for the continuity of services.</p>
            <p>In addition to the personal data above, which you provide when joining our services or using the platform, we may collect the following additional data: Location, IP of the access machine and Identification of access devices.</p>
        </li>

        <li class="item">
            <p><b>How we collect and what we use each piece of data</b></p>
            <p><b>Your location:</b></p>
            <p>We may collect, store and monitor location data when you access one of our websites or the {{company.company_name}} application. These data can be used in conjunction with other data, if so, make you identifiable, the location data is necessary for the proper functioning of some services such as: local currency identification and language.</p>
            <p><b>Access machine IP:</b></p>
            <p>This information, unified with the e-mail address and location, allows us to identify where the user is located on our platform, and which region he belongs to, which helps us to improve our service procedures and to identify possible problems that may have their causes. related to the region, such as: problems with service performance, if it occurs.</p>
            <p><b>Identification of access devices:</b></p>
            <p>When accessing the services through a mobile device, we can collect, store, monitor and access the device identifiers, which are small data files associated with a mobile device that identify that device and how it is used by you, which can make you identifiable if associated with other data.</p>
            <p>We store this data for rendering pages according to the size of the device used by the user and to restrict the use of some functions that are not suitable for some types of devices.</p>
            <br>

            <p>Other data that we process from the data collected above:</p>
            <p><b>Cookies</b></p>
            <p>Cookies help to log in to the platform more quickly because they store information on your computer about how you browse the site, sending this information to us anonymously allows {{company.company_name}} to improve your browsing experience .</p>
            
            <p>A persistent cookie remains on your hard drive after closing your browser so that it can be used by your browser on subsequent visits to the Service. Persistent cookies can be removed by following your browser's instructions. A session cookie is temporary and disappears after you close your browser.</p>

            <p><b>Log files:</b></p>
            <p>When you use the Service, our servers automatically record certain information sent by your web browser. These server logs may include information such as your web request, Internet Protocol ("IP") address, browser type, query pages/exit and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed, mobile operator and other information.</p>
            <p><b>Web Beacons:</b></p>
            <p>We can use web beacons in HTML-based emails sent to users, so we can track which ones are opened by the recipients and the usage patterns online. This information allows us to issue more elaborate statistics and make our services more attractive to users.</p>
            
        </li>

        <li class="item">
            <p><b>Data that is optionally collected</b></p>
            <p>You can add your photo to the access profile of the platform when using the services. This action is optional and you can remove it at any time.</p>
            <p>You can still send us your details when you fill out an online form, send us email or make contact via chat.</p>
            <p>This data is processed to provide you with the information you request from us and can be used for direct marketing and analytical purposes.</p>
            <p>If you register for one of our online events, you will send us your contact information through which we will provide you with access to participate in the event, and this data is processed for us to better understand our user base or future users and for purposes direct marketing.</p>
            <p>You can opt out of receiving marketing communications from {{company.company_name}} by following the opt-out instructions sent at the foot of promotional emails, or send an email to dpo@{{company.location}}.</p>
        </li>


        <li class="item">
            <p><b>Data collected from third parties</b></p>

            <p>We may collect data from third party sources so that you can log in to our services or if you share data stored in our services with third parties, these third party services can send us information about you, about your public profile, as long as the settings of your account in the third party’s application, allow this type of sharing.</p>
            <p>They may also receive information associated with the use of our sites from third party applications. Therefore, if you visit our websites, we can monitor and record your activities and our third-party providers can provide us with additional information that is available for public access.</p>
            <p>The information we collect may also be used for identification, authentication, fraud prevention and to improve the service we provide including troubleshooting, market research and to enforce our terms of service.</p>

        </li>

        <li class="item">
            <p><b>As an OPERATOR, as {{company.company_name}} handles the documents and data stored by you on the platform:</b></p>
            <p>The content stored by you and the people in your organization, on the {{company.company_name}} platform, are not viewed by our support team, unless the platform administrator (person registered by the CONTROLLER) provides a login and password for access for one of our employees, for exclusive technical support purposes and guidance on using the application. If this occurs, the user must be deactivated by the platform administrator at the end of the service. Each of our customers is provided with an isolated base, guaranteeing the protection of access to information, therefore, they will only have access to the content, the people to whom the platform administrator (who is a master user of the company contracting the services) provides login and password access with the respective permissions. All stored content and data (records, electronic files, metadata, forms, processes, etc ...) are transferred to our servers on Amazon AWS (HIPAA certifications, ISSO 27001, SOC 1/2/3, Directive 95 / 46 / CE and PCI DSS Level 1) by typing, scanning and / or uploading by persons authorized by the CONTROLLER in accordance with the rules and configurations predefined by the platform administrator, through an internet connection, the content travels between stations devices or devices and are transferred via HTTPS using TLS encryption. </p>
        </li>
            

        <li class="item">
            <p><b>Sharing with third parties</b></p>
            
            <p>{{company.company_name}} may share your data with our business partners when we believe that they are reliable and that they may have products and services that may be relevant to you or for us to perform this service provision agreement, such as : example hosting providers. When we need to do this, we make sure that the third party is trustworthy and will maintain confidentiality about your data.</p>
            <p>If {{company.company_name}} goes through a merger, acquisition, or change in the corporate structure, the company may share its data with third parties as a result of this process.</p>
            <p>If you access our application using third-party applications directly, your data may be shared with it, so you must make sure that you trust the application and observe the data protection policies of the company that owns the application.</p>
            <p>We can share data with third party applications through the use of the API, where the stored data can be consulted and / or copied to other applications, with the due consent of the CONTROLLER (natural or legal person responsible for the data stored on our platform).</p>
            <p>{{company.company_name}} may disclose your personal data when required by law or some legal provision, with the aim of protecting the security of any individual in matters of fraud, protecting the right to life, freedom or to protect the rights {{company.company_name}}.</p>
            <p>We use third party analytical tools, they collect information sent by your browser as part of a web page request, browser add-ons and other information, to help us measure traffic and usage trends for our Services. </p>
            <p>{{company.company_name}} can store and process personal data in third party marketing systems, customer relationship systems (CRM), e-mail management systems, in order to promote the company's operations and facilitate interactions with users and potential customers.</p>
            
        </li>

        <li class="item">
            <p><b>Blog and Community</b></p>
            <p>We can create blogs and communities that are accessible to the public, including forums. You should know that any information you include in these areas can be read by others, which we cannot control, and can still be collected and used by those who access this content, and the information posted can remain available even after you close your account.</p>
            <p>If you remove information posted on the blog and / or community service, copies may remain cached by the service or by other users. If you want us to remove this information, you must send us an email to dpo@{{company.location}}, however, in some cases, we may not be able to remove such personal information.</p>
        </li>

        <li class="item">
            <p><b>International Data Sharing:</b></p>
            <p><b>The hosting of our services is distributed in the regions below:</b></p>
            <p>Amazon AWS - São Paulo (Brazil) and Virginia (United States of America).</p>
            <p>The data is distributed in an automated way through the technological resources applied in the infrastructure itself. To ensure data protection, we adopt security measures following the provisions specified in the LGPD (General Law and Data Protection), together with the AWS provider, we use resources against accidental destruction, unauthorized access, automated backups, organizational measures in line with the policy safety, among other reasonable technical aspects.</p>
            <p>User data from the European Union, United Kingdom, Lichtenstein, Norway or Iceland, and all other countries, is stored in the United States (USA) through {{company.company_name}} and its service providers and, if you do not reside in the USA, the laws may differ from the laws in which you reside. By using the Services, you acknowledge that any personal information about you, whether provided by you or obtained by a third party, is being provided to {{company.company_name}} in the U.S. and will be hosted on U.S. servers, and you authorize the {{company.company_name}} to transfer, store and process your information from and in the United States and, possibly, to other countries, when the need arises and when informed in this policy. Except users in Brazil, who may have their data stored in the United States and Brazil.</p>
        </li>

        <li class="item">
            <p><b>Data processing and retention time</b></p>
            <p><b>DATA THAT {{company.company_name.toUpperCase()}} IS THE CONTROLLER</b></p>            
            <p>The data collected directly or by our customers from the Service Platform we offer, is maintained only for the time necessary to provide the services, that is, as long as the services are active, or for the time that is necessary to comply with our agreements and obligations. legal, accounting and tax. For elimination or anonymization of data, we have established internal policies that guarantee security in the process, for this we can keep the data for up to 03 months after the use of services ceases, as a safety margin before they are eliminated or anonymized.</p>
            <p>The data that is used by the marketing department may be maintained as long as we understand that our services may be relevant to you for future hiring, or until you unsubscribe to stop receiving our notifications and promotions, or request that the data be deleted.</p>

            <p><b>DATA THAT {{company.company_name.toUpperCase()}} IS THE OPERATOR</b></p>
            <p>The data is kept until the CONTROLLER requests its deletion and until we can safely perform the deletion, and we confirm that all data has been definitively deleted, which can take up to 90 (ninety) days.</p>
            <p>{{company.company_name}} requires that all individuals and legal entities contracting and using our services act in compliance with all applicable laws and regulations, in addition to this policy, and may terminate the provision of services unilaterally if it detects that the contractor's users are violating rules and regulations on protection of personal data and the others specified in accordance with our Terms of Use (<a :href="`https://${company.location}/terms-and-conditions/${lang}`" target="_blank">https://{{company.location}}/terms-and-conditions</a>)</p>
            <br>

            <p><b>DATA PROCESSED TO MAINTAIN COMPLIANCE</b></p>
            <p>The data that are necessary to comply with the tax, tax, social security, labor and legal obligations of the {{company.company_name}} group, in any of the countries in which we operate, are kept for the time determined by the law of each country.</p>
        </li>

        <li class="item">
            <p><b>Consent</b></p>
            <p>By agreeing to our privacy policy and registering to use our platform or any of our Services, you assume that you agree with it and give us authorization to process your data in accordance with that policy.</p>
            <p>You have the right to withdraw all or part of your consent for us to process your Personal Data, to do so please contact dpo@{{company.location}} and we will stop processing your personal data for the purposes for which you originally consented, except if there are compelling and legal reasons for us to continue processing your Personal Data, which void your interests, rights and freedoms or for the establishment, exercise or defense in legal actions.</p>
        </li>

        <li class="item">
            <p><b>Correct, Update or Delete your information</b></p>
            <p>You can request to correct, update or delete the personal information you provide directly to {{company.company_name}}, our team will make the best efforts to comply with your request within the applicable legal timeframe, but if there is a failure to deliver our answer, we are not responsible for such failure.</p>
            <p>When your information is processed by one of our clients contracting for our services, you must direct your requests directly to your supplier (our service client), who acts as CONTROLLER, of your data.</p>
        </li>

        <li class="item">
            <p><b>Privacy rights for California users</b></p>
            <p>California residents have the right to request a notice from us identifying the categories of personal information we share with our partners or third parties for marketing purposes, including contact information for each third party. If you are a California resident, use our services and wish to request such information, send a request to dpo@{{company.location}}.</p>
            
        </li>

        <li class="item">
            <p><b>Children's personal information</b></p>
            <p>We do not knowingly collect or solicit personal information from children under the age of 13 in accordance with our Term of Signature. If you are under the age of 13, do not attempt to register for the services or send us any personal information about yourself. If we become aware of an account that was created by a person under the age of 13, or of information that was sent to us directly under these conditions, we will delete all information as soon as possible. If you find that a child under the age of 13 has registered an account with our services or provided personal information, please contact us at dpo@{{company.location}} .</p>
            
        </li>

        <li class="item">
            <p><b>Legal basis for the processing of personal data</b></p>

            <p>We only process your personal information if we have a legal basis for doing so.</p>
            <p>We use the following legal bases:</p>
            <p class="pl-5 mb-1">- Contractual need: the data is processed to comply with our Terms of Service with you, and personal data is essential for us to provide the services, without them we would be unable to do so. The data includes: essential account data, Essential First-Party Cookies, Connection Data, Third Party Account Information (if any), such as if the user logs in using Active Directory.</p>
            <p class="pl-5 mb-1">- Consent: In some cases, we process personal data based on the consent you formally provide to us, at the time we collect that data. Your consent is expressly requested at the time of collection.</p>
            <p class="pl-5 mb-1">- Legitimate Interest: The categories specified here are collected when we believe they favor our legitimate interest or the interest of third parties: Billing Data, Online Form Data, Additional Account Data, Telephone, Bata-Paid and E-mail (when collected by means other than the service user account), Event, Community Data, Tracking Cookies, Increased Tracking Data, Support Ticket, Device Identifiers and Web Beacons.</p>
            <p class="pl-5">- Another reason: We may process legal data to comply with legal, accounting, tax and labor obligations, or to protect your vital interests or that of other data subjects, or to meet requirements of governmental institutions and/or the judiciary.</p>
            
        </li>

        <li class="item">
            <p><b>General provisions</b></p>
            <p>If an update to this Privacy Policy occurs, we will make a new publication on this page updating the information.</p>
            <p>Our policy only covers access to our sites and applications. Links to third party websites are not covered by our privacy policy.</p>
        </li>

    </ul>

    <br>

    <p><b>Contact Us:</b></p>
    <p>Any questions about this privacy policy can be sent to dpo@{{company.location}} or at:</p>
    <p>{{company.address1}} <br> {{company.address2}}</p>

    <br>
    <br>
    


</div>

</template>

<script>
export default {     
    props: ['company','lang'],
    inject: ['scrollto']
}
</script>

<style scoped lang="scss">

</style>