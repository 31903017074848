<template>
    <div class="thanks-page d-flex flex-column justify-content-start align-items-center">   

        <h2>{{$t('THANKS_TITLE').replace(/{domain}/g, domain.title)}}</h2>
        <h3>{{$t('THANKS_TITLE2')}}</h3>

        <div class='box' v-if="urlData && show">
            
            <p>{{$t('THANKS_SUBTITLE')}}</p>
            <p class="mt-4">{{`${$t('WORKSPACE_ID')}:`}} </p>
            <p class="id"><b>{{`${parseInt(urlData.workspace_id)}`}}</b></p>
            <p class="mt-4 contact-support">{{$t('THANKS_SUBTITLE_2')}}</p>

            <img class="mt-4 box-logo" :src="`/assets/images/domains/${domain.name}/logo_01.png`" :alt="`${domain.title}`">

        </div>


    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {

    computed: {
      ...mapState('MainStoreModule', {                                  
          domain: state => state.domain
      }),
    },


    data(){
        return {
            show: false,
            urlData: null
        }
    },

    beforeCreate(){        
        this.$emit('isPageLoaded', false)  
    },

    mounted(){

        try {

            this.urlData = JSON.parse(atob(this.$route.params.data))
            
        } catch (error) {

            this.$root.$emit('showAlert', {
                bgVariant: "danger",
                textVariant: "dark",
                buttons: "ok-only",
                title: this.$t('ERROR'),
                message: error
            })

            this.$router.push({ name: "NotFound" })
            
        }

        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    },

}
</script>

<style lang="scss" scoped>

.thanks-page{  
    margin: 0 !important;   
    min-height: 100vh;
    background-color: #EEF3FC;
    padding-top: 200px;
    
    h2{        
        width: 100%;
        text-align: center;        
        font-size: 22px;
        color: #2C2FFA;
        margin-bottom: 20px !important;
    }

    h3{        
        width: 100%;
        text-align: center;        
        font-size: 20px;
        margin-bottom: 50px !important;
    }

    .box{

        background-color: #fff;
        border-radius: 10px;
        -moz-box-shadow: 10px 10px 10px -12px #ccc;
        -webkit-box-shadow: 10px 10px 10px -12px #ccc;
        box-shadow: 10px 10px 10px -12px #ccc;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px;
        width: 800px;

        p{     
            text-align: center;
            // font-weight: 500;
            font-size: 20px;

            &.contact-support{
                font-size: 16px;
                width: 100%;                
            }

            &.id{
                background-color: #eef3fc;
                padding: 10px 100px;
                color: #2C2FFA !important;
                font-size: 25px;
            }
        }
    }

    .box-logo{
        height: 45px;
    }
}

</style>