var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.plan_addons),function(addon,index){return _c('li',{key:index,class:{'on': addon.hasAddon, 'off': !addon.hasAddon}},[(!addon.isOptional || !addon.hasAddon || (addon.isOptional && addon.is_included))?_c('img',{attrs:{"src":`/assets/images/plans/${addon.hasAddon}.png`}}):_vm._e(),(addon.isOptional && addon.hasAddon && !addon.is_included)?_c('div',{staticClass:"optional-wrapper d-flex flex-row justify-content-between align-items-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"optional"},[(!addon.tiered)?_c('div',[_c('span',[_c('span',{class:{
                                'font-ecm': (_vm.mod == 'ECM' || _vm.mod == 'GENERAL'),
                                'font-bpm': _vm.mod == 'BPM',
                                'font-swc': _vm.mod == 'SCS',
                                'font-dss': _vm.mod == 'DSS'
                            }},[_vm._v(_vm._s(_vm.$t('MONTHLY_VALUE')))]),_c('br')]),_c('span',[_vm._v(_vm._s(`${(_vm.acceptCurrencies.includes(_vm.currency_by_url)) ? ( (_vm.currency_by_url.toLowerCase() == 'usd') ? "$" : "R$" ) : _vm.user_monetary.symbol} ${addon.price[0]}${_vm.user_monetary.separators.cents}${addon.price[1]}`))])]):_vm._e(),(addon.tiered)?_c('div',{staticClass:"addon-tiers"},_vm._l((addon.tiers),function(tier_item,tier_index){return _c('div',{key:tier_index,class:{
                            'hide' : tier_index > 0                       
                        }},[_c('span',[_c('span',{class:{
                                'font-ecm': (_vm.mod == 'ECM' || _vm.mod == 'GENERAL'),
                                'font-bpm': _vm.mod == 'BPM',
                                'font-swc': _vm.mod == 'SCS',
                                'font-dss': _vm.mod == 'DSS'
                            }},[_vm._v(_vm._s(_vm.$t('MONTHLY_VALUE')))]),_vm._v(" "),_c('br'),(tier_item.max && !tier_item.last_tier_with_price)?[_vm._v(" "+_vm._s(_vm.$t('UP_TO'))+" "+_vm._s(tier_item.max)+" "+_vm._s(_vm.$t( _vm.getAddonUnit(addon, ((tier_item.max == null || tier_item.max > 1) ? 'plural' : 'singular'))))+" ")]:(tier_item.max && tier_item.last_tier_with_price)?[_vm._v(" "+_vm._s(_vm.$t('UNLIMITED'))+" ")]:[_vm._v(" "+_vm._s(tier_item.min)+" "+_vm._s(_vm.$t( _vm.getAddonUnit(addon, ((tier_item.min > 1) ? 'plural' : 'singular')) ))+" "+_vm._s(_vm.$t('OR_MORE'))+" ")]],2),_c('span',[_vm._v(_vm._s(`${(_vm.acceptCurrencies.includes(_vm.currency_by_url)) ? ( (_vm.currency_by_url.toLowerCase() == 'usd') ? "$" : "R$" ) : _vm.user_monetary.symbol} ${tier_item.total}`))])])}),0):_vm._e()]),_c('small',{class:{
                'font-ecm': _vm.mod == 'ECM' || _vm.mod == 'GENERAL',
                'font-bpm': _vm.mod == 'BPM',
                'font-swc': _vm.mod == 'SCS',
                'font-dss': _vm.mod == 'DSS'
            }},[(addon.tiered)?_c('i',{staticClass:"mr-1 fas fa-chevron-circle-down"}):_vm._e(),_c('i',{staticClass:"fas fa-info-circle",attrs:{"id":`tooltip-target-${index}`}})]),_c('b-tooltip',{attrs:{"target":`tooltip-target-${index}`,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t('OPTIONAL_ON_CHECKOUT'))+" ")])],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }