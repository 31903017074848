<template>
  <div style="min-height: 100vh;">   
    <!-- 
        Main banner
    -->
    <transition name="slide-fade">
        <div class="section section-main-module">
            <div class="main-background"></div>
            
            <h1 class="mb-4 title" v-html="$t('BPM').replace(/<br>/g, '')"></h1>
            <h2 class="subtitle" v-html="$t('BPM_SUBTITLE').replace(/<br>/g, '')"></h2>   

             <div class="div-buttons base-padding d-flex flex-row justify-content-center align-items-center">
                <router-link class="btn btn-1 mx-3" :to="`/start-free${partner_url}`">{{$t('START_FREE')}}</router-link>  
                <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="btn btn-2 mx-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
            </div>

          <div class="mockup">
              <img class="base" :src="`/assets/images/domains/${domain.name}/bpm/mockup-${lang}-01.png`" :alt="$t('BPM') + ' - ' + $t('BPM_SUBTITLE')">
          </div> 

        </div>
    </transition>
  
    <transition name="slide-fade">
        <Clients></Clients>
    </transition>             

    <transition name="slide-fade">
        <div class="section section-bpm-features base-padding-md">
            <h1 class="mb-4 text-center font-bpm" v-html="$t('HAVE A BPM LIKE YOUVE NEVER SEEN')"></h1>
            <h2 class="mb-5" v-html="$t('BPM INCREASES...')"></h2>

            <div v-if="window.width > 991" class="accordion">
                <ul>
                    <li v-for="(item, index) in features_bpm_accordion" :key="index">
                        <div class="div-icon d-flex align-items-center justify-content-center">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="div-text">                            
                            <p class="mb-2 font-weight-bold text-center">{{item.title[lang]}}</p>
                            <p v-if="window.width > 991" class="text-center">{{item.description[lang].replace(/{domain}/g, domain.title)}}</p>                            
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 
                Mobile version
            -->
            <div class="carousel-wrapper" v-else>
                <Carousel   
                    
                    class="mod-carousel"
                    :margin="0" 
                    :autoplay="true"                     
                    :dots="false"
                    :nav="false"
                    :responsive="{
                        0:{
                            items: 1
                        }
                    }"
                >                
                    <div class="item" v-for="(item, index) in features_bpm_accordion" :key="index">
                        <div class="div-icon d-flex align-items-center justify-content-center">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="div-text">                            
                            <p class="title">{{item.title[lang]}}</p>
                            <p class="description">{{item.description[lang].replace(/{domain}/g, domain.title)}}</p>                            
                        </div>
                    </div>
                    
                </Carousel>
            </div> 
            
        </div>
    </transition>

    <transition name="slide-fade">             
        <div v-if="show" class="section section-inovate-bpm base-padding d-flex flex-column justify-content-center" style="background-color: #fff !important;">
            <h1 class="mb-4 text-center font-bpm">{{$t('FROM NOW ON YOUR BUSINESS WILL GAIN MO')}}</h1>
            <h2>{{$t('WITH JUST ONE CLICK EVERYTHING CHANGE')}}</h2>

            <div class="d-flex flex-row justify-content-center align-items-center">                
                <router-link class="btn btn-outline-blue btn-medium-width mx-3" :to="`/start-free/${partner_url}`">{{$t('START_FREE')}}</router-link>
                <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-3 btn btn-success px-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>                
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <div class="section section-bpm-simplify base-padding">
            <h1 class="mb-4 text-center font-bpm">{{$t('SIMPLIFY YOUR W')}}</h1>

             <div :class="{'mt-5': window.width > 991}" class="d-flex flex-row justify-content-center align-items-center">

                <Carousel       
                    style="width: 100%;"
                    :margin="0" 
                    :autoplay="true"                     
                    :dots="false"
                    :nav="false"
                    :responsive="{
                        0:{
                            items: 1
                        },
                        991:{
                            items: 3
                        },
                        1400:{
                            items: 4
                        }
                    }"
                >

                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-09`"></i><span></span></div>
                                <p v-html="$t('TASK BOX...')"></p>
                                <p class="description" v-html="$t('AREA TO ...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>

                    <div class="item">

                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-10`"></i><span></span></div>
                                <p v-html="$t('PUBLIC V...')"></p>
                                <p class="description" v-html="$t('USE THE ...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-11`"></i><span></span></div>
                                <p v-html="$t('PARALLEL...')"></p>
                                <p class="description" v-html="$t('CREATE P...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-12`"></i><span></span></div>
                                <p v-html="$t('VIEWING ...')"></p>
                                <p class="description" v-html="$t('ANALYZE ...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>                     
                    </div>

                </Carousel>
            </div>
        </div>    
    </transition> 

    <transition name="slide-fade">
        <div class="section section-bpm-store-any-type base-padding">
            <h1 class="mb-4 text-center font-bpm" v-html="$t('EXECUTE TASKS...')"></h1>            
             <div class="mt-5 mb-5">

                <img class="mb-n5" src="/assets/images/bpm/icons-floating.png" :alt="$t('STORE ANY TYPE')">                
                <b-carousel
                    id="carousel-every-company"
                    v-model="slide"
                    :interval="4000"
                    fade
                    controls
                    indicators
                    
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                >
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/bpm/${lang}-01.png`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/bpm/${lang}-02.png`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/bpm/${lang}-03.png`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/bpm/${lang}-04.png`"></b-carousel-slide>
                </b-carousel>
                
            </div>
        </div>
    </transition> 
 
    <transition name="slide-fade">             
        <div v-if="show" class="section section-access-your-content bpm base-padding d-flex flex-column justify-content-center align-items-center">
            
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <h1 class="mb-4 font-bpm" v-html="$t('ACCESS YOUR CO')"></h1>   
                    <h2 class="mt-4" v-html="$t('FIND YOUR FILE').replace(`{domain}`, domain.title)"></h2>
                </div>

                <div class="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-center">
                    <img :src="`/assets/images/domains/${domain.name}/ecm/mockup-${lang}-02.png`" :alt="$t('ACCESS YOU CO')">
                </div>
            </div>

        </div>
    </transition>

    <transition name="slide-fade">
        <div class="section section-bpm-simplify base-padding">
             <div class="d-flex flex-row justify-content-center align-items-center">

                 <Carousel       
                    style="width: 100%;"
                    :margin="0" 
                    :autoplay="true"                     
                    :dots="false"
                    :nav="false"
                    :responsive="{
                        0:{
                            items: 1
                        },
                        991:{
                            items: 3
                        },
                        1400:{
                            items: 4
                        }
                    }"
                >

                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-13`"></i><span></span></div>
                                <p v-html="$t('DEFINING...')"></p>
                                <p class="description" v-html="$t('DEFINE T...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-14`"></i><span></span></div>
                                <p v-html="$t('MANUAL, ...')"></p>
                                <p class="description" v-html="$t('SEVERAL ...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>  

                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-15`"></i><span></span></div>
                                <p v-html="$t('VIEWER O...')"></p>
                                <p class="description" v-html="$t('THE PROC...').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>  
                    
                    <div class="item">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="`icon-bpm-16`"></i><span></span></div>
                                <p v-html="$t('INTEGRATION WITH EXT')"></p>
                                <p class="description" v-html="$t('DURING THE EXECUTION').replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>                   
                    </div>

                </Carousel>
            
                
            </div>
        </div>    
    </transition>   
  
    <ModulesCarousel :partner_url="partner_url"  v-if="show" :modules="['ecm','scs','dss']"></ModulesCarousel>
 
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show" class="ecm"></Join>
    </transition>

    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import ModulesCarousel from '../common/ModulesCarousel'

import Carousel from 'vue-owl-carousel'

export default {
    components:{ 
        Carousel,                 
        Clients,        
        ThePlatform,
        Join,
        ModulesCarousel
    },
    props: {     
        isDomainPermitted:{
            type: Boolean,
            default: false
        }, 
        partner_url: {
            type: String,
            default: ''
        },     
        window: {
            type: Object,
            default () {
                return {
                    width: 0,
                    height: 0
                }
            }
        }
    },
    data(){
        return{                        
            show: false,
            slide: 0,
            sliding: null
        }
    },
    computed: {

        ...mapState("MainStoreModule", {            
            domain: state => state.domain
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },

        features_bpm_accordion() {
            return [ 
                {
                    "icon": "icon-bpm-01",
                    "title": {
                        pt: "Criação de processos de forma fácil e rápida",
                        en: "Criação de processos de forma fácil e rápida",
                        es: "Criação de processos de forma fácil e rápida"
                    },
                    "description": {
                        pt: "Entenda o andamento do caso olhando o desenho do fluxo com a identificação das etapas concluídas, a etapa atual e as etapas por realizar.",
                        en: "Entenda o andamento do caso olhando o desenho do fluxo com a identificação das etapas concluídas, a etapa atual e as etapas por realizar.",
                        es: "Entenda o andamento do caso olhando o desenho do fluxo com a identificação das etapas concluídas, a etapa atual e as etapas por realizar."
                    }
                },
                {
                    "icon": "icon-bpm-02",
                    "title": {
                        pt: "Formulários personalizados",
                        en: "Formulários personalizados",
                        es: "Formulários personalizados"
                    },
                    "description": {
                        pt: "Crie seus próprios formulários utilizando os diversos formatos de campos disponíveis no desenhador, que permite personalizar os dados de acordo com a exigência de cada etapa do fluxo e aproveitar a conexão via banco de dados para alimentá-los com conteúdos de outras aplicações.",
                        en: "Crie seus próprios formulários utilizando os diversos formatos de campos disponíveis no desenhador, que permite personalizar os dados de acordo com a exigência de cada etapa do fluxo e aproveitar a conexão via banco de dados para alimentá-los com conteúdos de outras aplicações.",
                        es: "Crie seus próprios formulários utilizando os diversos formatos de campos disponíveis no desenhador, que permite personalizar os dados de acordo com a exigência de cada etapa do fluxo e aproveitar a conexão via banco de dados para alimentá-los com conteúdos de outras aplicações."
                    }
                },
                {
                    "icon": "icon-bpm-03",
                    "title": {
                        pt: "Modelos de processos prontos",
                        en: "Modelos de processos prontos",
                        es: "Modelos de processos prontos"
                    },
                    "description": {
                        pt: "Acelere a implementação dos processos utilizando os modelos prontos disponíveis na biblioteca de processos. Utilize gratuitamente os modelos disponíveis.",
                        en: "Acelere a implementação dos processos utilizando os modelos prontos disponíveis na biblioteca de processos. Utilize gratuitamente os modelos disponíveis.",
                        es: "Acelere a implementação dos processos utilizando os modelos prontos disponíveis na biblioteca de processos. Utilize gratuitamente os modelos disponíveis."
                    }
                },
                {
                    "icon": "icon-bpm-04",
                    "title": {
                        pt: "Controle de SLA",
                        en: "Controle de SLA",
                        es: "Controle de SLA"
                    },
                    "description": {
                        pt: "Receba alertas quando os casos estiverem críticos ou vencidos. Envie notificações por e-mail antes ou depois de uma tarefa. Informe ao usuário quando uma nova tarefa for atribuída a ele.",
                        en: "Receba alertas quando os casos estiverem críticos ou vencidos. Envie notificações por e-mail antes ou depois de uma tarefa. Informe ao usuário quando uma nova tarefa for atribuída a ele.",
                        es: "Receba alertas quando os casos estiverem críticos ou vencidos. Envie notificações por e-mail antes ou depois de uma tarefa. Informe ao usuário quando uma nova tarefa for atribuída a ele."
                    }
                },
                {
                    "icon": "icon-bpm-05",
                    "title": {
                        pt: "Usuários Dinâmicos",
                        en: "Usuários Dinâmicos",
                        es: "Usuários Dinâmicos"
                    },
                    "description": {
                        pt: "Permite que usuários externos participem do processo em momentos específicos e enquanto o caso estiver ativo. Dispensa a gestão manual de usuários.",
                        en: "Permite que usuários externos participem do processo em momentos específicos e enquanto o caso estiver ativo. Dispensa a gestão manual de usuários.",
                        es: "Permite que usuários externos participem do processo em momentos específicos e enquanto o caso estiver ativo. Dispensa a gestão manual de usuários."
                    }
                },
                {
                    "icon": "icon-bpm-06",
                    "title": {
                        pt: "Supervisão de Processos",
                        en: "Supervisão de Processos",
                        es: "Supervisão de Processos"
                    },
                    "description": {
                        pt: "Nomeie responsáveis/gestores para os fluxos desenhados e permita que eles possam acompanhar a execução de todas as tarefas em andamento e interagir em tempo de processo para tomar ações que garantam o cumprimento dos prazos definidos",
                        en: "Nomeie responsáveis/gestores para os fluxos desenhados e permita que eles possam acompanhar a execução de todas as tarefas em andamento e interagir em tempo de processo para tomar ações que garantam o cumprimento dos prazos definidos",
                        es: "Nomeie responsáveis/gestores para os fluxos desenhados e permita que eles possam acompanhar a execução de todas as tarefas em andamento e interagir em tempo de processo para tomar ações que garantam o cumprimento dos prazos definidos"
                    }
                },
                {
                    "icon": "icon-bpm-07",
                    "title": {
                        pt: "Facilidade para Integração",
                        en: "Facilidade para Integração",
                        es: "Facilidade para Integração"
                    },
                    "description": {
                        pt: "Integre seus processos com uma gama de aplicativos como o Trello, o Slack, o Teans, etc.. através do Zapier, ou utilize triggers para fazer chamadas externas ou conexão com banco de dados externos para buscar dados já armazenados no seu ERP.",
                        en: "Integre seus processos com uma gama de aplicativos como o Trello, o Slack, o Teans, etc.. através do Zapier, ou utilize triggers para fazer chamadas externas ou conexão com banco de dados externos para buscar dados já armazenados no seu ERP.",
                        es: "Integre seus processos com uma gama de aplicativos como o Trello, o Slack, o Teans, etc.. através do Zapier, ou utilize triggers para fazer chamadas externas ou conexão com banco de dados externos para buscar dados já armazenados no seu ERP."
                    }
                },
                {
                    "icon": "icon-bpm-08",
                    "title": {
                        pt: "Link externo para início de casos",
                        en: "Link externo para início de casos",
                        es: "Link externo para início de casos"
                    },
                    "description": {
                        pt: "Inicie casos a partir de formulários de entrada integrados ao seu website ou de links enviados por e-mails para os iniciadores do processo.",
                        en: "Inicie casos a partir de formulários de entrada integrados ao seu website ou de links enviados por e-mails para os iniciadores do processo.",
                        es: "Inicie casos a partir de formulários de entrada integrados ao seu website ou de links enviados por e-mails para os iniciadores do processo."
                    }
                }
            ]
        }
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   

        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }, 
    
    beforeCreate(){          
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">

$ecm: #0DC5C7;
$bpm: #2C2FFA;
$swc: #E82F8A;
$dss: #962DFB;
$slv: #80BA27;
$das: #DEC902;


.section-bpm-simplify {    
    h2 {
        width: 100%;
        text-align: center;
    }
    a {
        font-size: 16px !important;
        transition: all .4s ease;
    }
    p {
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }
    .imgOpenModal {
        margin-top: 20px;
        width: 15vw;
    }
    .item{
        padding: 30px 25px;
            
        .div-card {
            width: 100%;
            border-radius: 20px;
            padding: 40px 20px;
            height: 400px;
            transition: all .4s ease;
            box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
            cursor: pointer;
            text-align: center;
            background-color: #fff;

            &:hover {
                p,
                a {
                    opacity: 0;
                }
                p.description {                    
                    opacity: 1;
                }
                div {
                    span {
                        width: 70px;
                    }
                }
            }
            div {                
                margin-bottom: -10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span {
                    display: block;
                    margin-top: 10px;
                    width: 0;
                    border-radius: 20px;
                    transition: all .2s ease;
                }
                i {
                    font-size: 50px;
                }
            }
            p {
                margin: 0 !important;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                b {
                    font-weight: 800;
                }
                &.description {
                    font-size: 16px !important;
                    line-height: 20px !important;
                    font-weight: 400 !important;
                    opacity: 0;
                    position: absolute;
                    padding: 20px 40px;
                    top: 130px;
                }
            }
            a {
                text-decoration: none;
                border: 1px solid transparent;
                padding: 0;
                transition: all .2s ease;
            }
        }
    }
}

.section-bpm-simplify .owl-item {
    .card-col {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(0, 199, 175, 0.5);
            }
            color: $bpm;
            a:hover {
                color: $bpm;
                border: 1px solid $bpm;
            }
            div span {
                border-bottom: 3.5px solid $bpm;
            }
        }
    }
}


@media only screen and (min-width: 992px) and (max-width: 1460px){
    .section-bpm-simplify{
        .item {
            .div-card {
                p,
                p.description{
                    font-size: 16px !important;
                    line-height: 18px !important;
                }
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    
    .section-bpm-simplify,
    .section-inovate-bpm,
    .section-bpm-features,
    .section-main-module {
        h1{
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2{
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
        }

        h3{
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
        }
    }

    .section-bpm-features {
        padding: 30px 0 !important;
        
        .carousel-wrapper{
            
            .mod-carousel{            
                min-height: 200px;

                .owl-item {            
                    min-height: 200px;   

                    .item {       
                        min-height: 200px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;                                                                    
                        padding: 20px;
                        background-color: #fff !important;
                        border-radius: 15px !important;
                        margin: 20px !important;
                        
                        .div-icon {                            
                            i{
                                font-size: 45px;
                            }
                        }

                        .div-text {
                            .title{
                                margin: 20px 0 20px 0 !important;
                                text-align: center;
                                font-weight: bold;
                            }
                            .description{                                
                                margin: 0 !important;
                                text-align: center;
                            }
                        }
                    }
                    
                    
                    &:nth-child(1) .item{
                        box-shadow: 1px 2px 5px #CE3009;
                        .div-icon i,
                        .div-text p {
                            color: #CE3009;
                        }
                    }
                    &:nth-child(2) .item{                    
                        box-shadow: 1px 2px 5px #01C7B0;
                        .div-icon i,
                        .div-text p {
                            color: #01C7B0;
                        }
                    }
                    &:nth-child(3) .item{                    
                        box-shadow: 1px 2px 5px #E32555;
                        .div-icon i,
                        .div-text p {
                            color: #E32555;
                        }
                    }
                    &:nth-child(4) .item{                    
                        box-shadow: 1px 2px 5px #7F81F0;
                        .div-icon i,
                        .div-text p {
                            color: #7F81F0;
                        }
                    }
                    &:nth-child(5) .item{                    
                        box-shadow: 1px 2px 5px #EA4587;
                        .div-icon i,
                        .div-text p {
                            color: #EA4587;
                        }
                    }
                    &:nth-child(6) .item{                    
                        box-shadow: 1px 2px 5px #FF9B4D;
                        .div-icon i,
                        .div-text p {
                            color: #FF9B4D;
                        }
                    }
                    &:nth-child(7) .item{                    
                        box-shadow: 1px 2px 5px #248AF6;
                        .div-icon i,
                        .div-text p {
                            color: #248AF6;
                        }
                    }
                    &:nth-child(8) .item{                    
                        box-shadow: 1px 2px 5px #34D432;
                        .div-icon i,
                        .div-text p {
                            color: #34D432;
                        }
                    }
                    
                }
            }
        }
    }

    .section-inovate-bpm{

        padding: 30px 10px !important;

        div{
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            
            .btn {
                padding: 0 10px !important;             
                margin: 20px 0 0 0 !important;
                width: 190px !important;
                height: 30px !important;
                font-size: 16px !important;
                line-height: 18px !important;
                border-radius: 5px !important;          
            }
        }
        
    }

    .section-bpm-simplify{                
        
        padding: 0 0 30px 0 !important;            
        margin: 30px 0 0 0 !important;

        h1{
            padding: 0 10px !important;
        }
        .item {
            .div-card {
                height: 330px !important;
                p,
                p.description{
                    font-size: 14px !important;
                    line-height: 16px !important;
                }
            }
        }
        
    }

}
    
</style>