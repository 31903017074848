<template>
  <div>

    <div class="show-more-resources base-padding">
      <a @click.stop="toggleModulesList()" v-html="(!showMore) ? $t('SHOW_MORE_RESOURCES') : $t(`SHOW_LESS_RESOURCES`)"></a>
    </div>
    
    <div v-if="renderedAddons" class="base-padding list-by-module" :class="{'hide': !showMore}">
      <div v-for="(mod, index) in modules" :key="index">

        <div v-if="renderedAddons[mod] && renderedAddons[mod].length > 0">
          <div :class="`text-center title logo-${mod.toLowerCase()}`"><i :class="`icon-${mod.toLowerCase()} logo-${mod.toLowerCase()} mr-2`" :alt="$t(`${mod}_NO_TAGS`)"></i> {{$t(`RESOURCES_${mod.toUpperCase()}`)}}</div>

          <div class="bottom-content module d-flex flex-row justify-content-center align-items-start">

            <div v-if="renderedPlans.length > 0" class="mr-2 fixed-column d-flex flex-column justify-content-center align-items-center">                
                <div class="block-04">
                  <ul class="list-addons">                    
                    <li v-for="(addon, index) in renderedAddons[mod]" :key="index" :id="`tooltip-module-resource-${mod}-${index}`">
                      {{limitChars(addon.name[lang], 70)}}
                      <b-tooltip v-if="addon.name[lang].length >= 70" :target="`tooltip-module-resource-${mod}-${index}`" triggers="hover">{{addon.name[lang]}}</b-tooltip>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="plans-columns d-flex flex-row justify-content-between align-items-start">
                <div class="mr-2 plan-column d-flex flex-column justify-content-center align-items-center" v-for="(plan, index) in renderedPlans" :key="index">
                  <div class="block-03">
                    <ul class="list-addons">                                            
                      <Addons :mod="mod" :plan_addons="plan.addons[mod]"></Addons>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="show-more-resources base-padding" :class="{'hide': !showMore}">
      <a @click.stop="toggleModulesList()" v-html="(!showMore) ? $t('SHOW_MORE_RESOURCES') : $t(`SHOW_LESS_RESOURCES`)"></a>
    </div>

  </div>
</template>

<script>
import PlansMixin from '../../mixins/PlansMixin'
import Addons from '../../components/plans/Addons'
import $ from 'jquery'
export default {
  mixins: [PlansMixin],
  components:{ 
    Addons         
  },
  props:{          
  },
  data(){
    return {   
      showMore: false,   
    }
  },
  methods:{

    toggleModulesList(){
      this.showMore = !this.showMore
      this.adjustListItemsHeight()
    }

  }
}
</script>

<style scoped lang="scss">
a{
  cursor: pointer;
}
</style>