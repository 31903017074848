<template>
<div class="documentation">

    <ul class="numbered-list mb-5 no-print">

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.terms', 1)">CONDIÇÕES GERAIS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 2)">SUA CONTA</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 3)">RETENÇÃO E EXCLUSÃO DE SEUS DADOS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 4)">TRATAMENTO DE DADOS PESSOAIS, TRANSFERÊNCIA E SIGILO</span></li>            
                <li class="link"><span @click.stop="scrollto('.terms', 5)">DO PAGAMENTO</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 6)">LIMITES DE USO</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 7)">MODIFICAR OU CANCELAR OS SERVIÇOS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 8)">EXCLUSÃO DE GARANTIAS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 9)">LICENÇA E DIREITOS DE PROPRIEDADE</span></li>

            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.terms', 10)">RESPONSABILIDADES SOBRE OS SERVIÇOS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 11)">CONTEÚDO</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 12)">RESCISÃO</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 13)">MODIFICAÇÕES DESTE TERMO</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 14)">LEI APLICÁVEL</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 15)">DISPUTAS</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 16)">COMBATE À CORRUPÇÃO E LAVAGEM DE DINHEIRO</span></li>
                <li class="link"><span @click.stop="scrollto('.terms', 17)">DISPOSIÇÕES SOBREVIVENTES</span></li>

            </div>
        </div>
    </ul>
    
    <div class="intro mb-5 d-flex flex-column justify-content-center align-items-center">
        <p>Contrato Padrão de Assinatura – Versão 1/2021 – 18 de Fevereiro de 2021</p>
        <p>Este Contrato entrará em vigor na primeira data em que você concordar, acessar ou usar os Serviços Contratados da {{company.company_fullname}}., uma empresa privada, estabelecida na {{`${company.address1} ${company.address2}`}}, (“Contratada”) e Você (“Contratante”).</p>
        <p>Este é um acordo legal entre você (Contratante) e a {{company.company_fullname}} (Contratada), ele descreve os termos que regem o uso dos serviços online da {{company.company_name}}, fornecidos a você através deste Site/Portal, incluindo conteúdo, atualizações e novos lançamentos, e é identificado como “Contrato”.</p>
        <p>Se você estiver celebrando este Contrato em nome de uma empresa ou outra entidade legal, você declara e garante que tem autoridade para vincular essa entidade e suas afiliadas a estes termos e condições como seu representante legal e autorizado, e que leu e entendeu este contrato. Nesse caso, os termos 'Você' ou 'Seu' se referirá a tal entidade e suas afiliadas, assim como a você.</p>
        <p>Ao aceitar eletronicamente clicando em “Concordo', acessar ou usar os Serviços, você concorda irrevogavelmente com este contrato e com a Política de Privacidade disponível neste Site.</p>

        <br>

        <p><b>DEFINIÇÕES</b></p>
        <p><b>Usuários</b>: São todos os indivíduos que são autorizados pela Contratante através da liberação de login e senha para acessar e utilizar os serviços da Plataforma. Os “usuários” podem ser empregados diretos, prestadores de serviços, terceirizados, consultores, etc... com os quais a Contratante realize negócios. </p>
        <p><b>Serviços</b>: É o conjunto de ferramentas e funcionalidades fornecidas online pela Contratada através da conta de acesso criada por você ou por um de nossos parceiros de negócios credenciados, em um de nossos portais na web. </p>
        <p><b>Dados</b>: Significa todo e qualquer dado ou documento coletado, processado e mantido pela/para Contratante, utilizando as Soluções disponibilizadas na Plataforma pela Contratada.</p>
        <p><b>Propriedade Intelectual</b>: São todos os segredos comerciais, marcas registradas ou não registradas para produto ou serviço patentes e pedidos de patentes, nomes comerciais, direitos autorais, morais, de invenções ou de propriedade intelectual e todos os outros diretos da Contratada em qualquer parte do mundo.</p>
        <p><b>Política de Privacidade</b>: Significa as regras e as práticas que regulam a maneira que a Contratada trata a privacidade das informações que são processadas na Plataforma de Serviços Online, especificada no seguinte link: https://{{company.company_name}}.io/privacy-policy</p>
        <p>A Política de Privacidade é vinculada a esse terno de aceite de assinatura de serviços e contém informações sobre como coletamos e processamos dados pessoais através dos nossos sites e dos nossos serviços.</p>
        <p><b>Plataforma Online:</b> Significa o ambiente virtual, por meio do qual o usuário, após a criação da workspace, pode acessar e utilizar os serviços da contratados.</p>
        <p><b>Código Malicioso:</b> São arquivos de dados, programas, código fonte, cujo propósito é causar danos ao funcionamento da plataforma, como vírus por exemplo.</p>
        <p><b>Invoice:</b> É o documento emitido para realização da cobrança dos serviços prestado mensalmente. É também utilizado como documento fiscal.</p>
        <p><b>Contrato:</b> Equivale a esse termo de acordo de assinatura dos serviços fornecidos pela Contradada.</p>
        <p><b>Documentação:</b> Significa os materiais de ajuda e treinamento fornecidos pela Contratada, aos usuários da Contratante, e atualizados periodicamente pela Contratada. </p>
        <p><b>Conta Virtual:</b> é a conta utilizada pela Contratante para ter acesso aos serviços fornecidos pela Contratada, o qual é realizado de forma online somente por usuários autorizados pela Contratante, através da qual são disponibilizadas todas as funcionalidades contratadas.</p>
        <p><b>Serviços de Terceiros:</b> significa outros serviços que podem ser utilizados em conjunto com as soluções disponibilizadas através da plataforma online, pelos quais a Contratada, não será responsável pelo funcionamento ou interferência nos serviços contratados de acordo com este termo.</p>
    </div>

    <ul class="terms numbered-list">

        <li class="item">
            <p><b>CONDIÇÕES GERAIS</b></p>
            <p>A Contratada disponibilizará, mediante a formalização da adesão aos serviços através do aceite deste Termo de Acordo de Assinatura (Contrato) e da Política de Privacidade conforme aplicável: a) A solução adquirida pela Contratante de acordo com o plano selecionado no ato da adesão online; b) Suporte técnico padrão para atendimento às soluções adquiridas, sem custo adicional para a Contratante; c) Manterá a solução adquirida disponível online 24 horas por dia, 7 dias por semana, exceto pelos período de inatividade previamente programado, ou qualquer indisponibilidade causadas por circunstâncias além do controle razoável da Contratada, incluindo falhas ou problemas com o provedor de internet e/ou de infraestrutura, problemas com conexões externas à solução contratada, ou relacionados ao bloqueio dos serviços, seja por qual for a causa.

            <p>Os Serviços podem incluir componentes, sistemas e outros serviços de terceiros, nos quais podem aplicar-se termos adicionais, políticas ou exigências de outros fornecedores, inclusive exigências de idade.</p>
            <p>A Política de Privacidade disponível neste site, faz parte integral deste Contrato. A Contratada manterá proteções administrativas, físicas e técnicas para manter a segurança, a confidencialidade e integridade do conteúdo armazenado, considerando um padrão de mercado razoável e aceitável. É preciso que você siga as políticas disponibilizadas a você dentro dos Serviços, e não faça uso indevido, não interfira no uso dos Serviços e nem tente acessá-los por um método diferente da interface e das instruções e métodos lícitos que fornecemos.</p>
            <p>Você pode usar os serviços somente conforme permitido por lei. Podemos suspender ou deixar de fornecer os Serviços se você descumprir nossos Termos ou Políticas, ou quaisquer leis aplicáveis, ou se estivermos investigando casos de suspeita de má conduta.</p>
            <p>Você deve concordar em não usar, nem permitir que terceiros usem os Serviços ou o conteúdo de uma maneira que viole qualquer lei, e as regras deste contrato e da política de privacidade.</p>
            <p>Você concorda que não irá:</p>
            <p class="pl-5 mb-1">- Fornecer acesso ou fornecer qualquer parte dos Serviços a terceiros não vinculados ao seu negócio.</p>
            <p class="pl-5">- Reproduzir, modificar, copiar, desconstruir, vender, comercializar ou revender os Serviços, exceto se você for um revendedor credenciado pela Contratada e que esta tenha autorizado por escrito.</p>
            <p>Os Serviços são protegidos por direitos autorais, segredos comerciais e outras leis de propriedade intelectual que inclui, dentre outros, quaisquer marcas ou logotipos utilizados em sites. </p>
            <p>Você só tem o direito de usar os Serviços apenas para os fins descritos neste contrato de assinatura.</p>
            <p>A Contratada reserva todos os outros direitos nos Serviços, seja de sua propriedade ou de propriedade de terceiros até a rescisão deste Contrato e desde que você cumpra todas as obrigações de pagamento aplicáveis e esteja em conformidade com este Contrato, a Contratada concede a você um direito pessoal, limitado, não exclusivo e intransferível, e licença para usar os Serviços.</p>
            <p>Nós respondemos às notificações de alegação de violação de direitos autorais e encerramos contas de infratores reincidentes de acordo com os procedimentos estabelecidos nas Leis Americanas de Direitos Autorais. Fornecemos informações para ajudar os detentores de direitos autorais a gerenciarem sua propriedade intelectual on-line. </p>
            <p>Caso você entenda que alguém está violando seus direitos autorais e quiser nos notificar, você pode enviar sua notificação para o seguinte e-mail: info@{{company.company_name}}.io . Os Serviços permitem que você envie conteúdo, dados e documentos para armazenamento. Você mantém a propriedade de quaisquer direitos de propriedade intelectual que você detenha sobre aquele conteúdo ou documento. Em resumo, aquilo que pertence a você, permanece com você.</p>
        </li>

        <li class="item">
            <p><b>SUA CONTA</b></p>
            <p>Você precisa criar uma conta para utilizar os Serviços. Você poderá criar sua própria conta no site ou ela poderá ser atribuída a você por um administrador Parceiro da Contratada. Se você estiver usando uma Conta da Plataforma de Serviços Online atribuída a você por um administrador, termos diferentes ou adicionais podem ser aplicados, e seu administrador poderá ser capaz de bloquear ou desativar sua conta.</p>
        </li>

        <li class="item">
            <p><b>RETENÇÃO E EXCLUSÃO DE SEUS DADOS</b></p>
            <p>Os dados da Contratante serão armazenados no banco de dados da Contratada, desde que a conta tenha sido criada e este contrato esteja válido. De acordo com a política de privacidade, os dados serão eliminados (excluídos permanentemente) em até 90 dias após o encerramento do contrato.</p>
        </li>

        <li class="item">
            <p><b>TRATAMENTO DE DADOS PESSOAIS, TRANSFERÊNCIA E SIGILO</b></p>            
            <p>Você pode ver a Declaração de Privacidade da Contratada fornecida a partir do nosso site. Você deve concordar com a Política de Privacidade e quaisquer alterações publicadas pela Contratada. Você deve concordar que a Contratada poderá usar e manter seus dados de acordo com a Declaração de Privacidade publicada pela Contratada, como parte dos Serviços. Você concede à Contratada permissão para combinar informações identificáveis e não identificáveis que você insere ou carrega nos Serviços com as de outros usuários dos Serviços e / ou outros serviços da Contratada. Isso significa que a Contratada poderá usar os dados agregados de seus usuários para melhorar os Serviços ou para projetar promoções e fornecer maneiras de comparar as práticas de negócios com outros usuários. A Contratada é uma empresa global e pode acessar ou armazenar informações pessoais em vários países, conforme especificado em nossa Política de Privacidade.</p>
            <p>Contratante e Contratada comprometem-se a tratar as informações classificadas legalmente como dados pessoais, em razão do presente contrato, observando a legislação aplicável, sob pena de responsabilização por descumprimento contratual, sem prejuízo de perdas e danos. O tratamento de dados pessoais se dará unicamente em observância à finalidade estabelecida neste contrato.</p>
            <p>As partes se comprometem a não transferir e/ou compartilhar com terceiros, os dados pessoais tratados, exceto se for requisito essencial para cumprimento do presente termo ou para cumprimento de obrigações legais/judiciais, contudo, ainda que uma das partes seja obrigada legalmente a transferir dados a terceiros, deve notificar a outra parte e assegurar a confidencialidade e a integridade dos dados sob pena de responsabilização. Informações sobre com quem compartilhamos dados para prover nossos serviços podem ser consultadas em nossa Política de Privacidade.</p>
            <p>A Contratante será responsável pelo cumprimento deste contrato pelo seus usuários, será responsável pela exatidão, quantidade, qualidade, legalidade de seus dados e dos meios pelos quais os adquiriu, e pelos métodos e forma de processamento de suas informações armazenadas, e fará seus melhores esforços para garantir a utilização da plataforma em acordo com os regulamentos aplicáveis, tanto no que diz respeito a legalidade, a privacidade, a integridade, confidencialidade e segurança dos dados processados, quanto para utilizar os serviços em acordo com a finalidade especificada neste termos de acordo de assinatura.</p>
            <p>Se a Contratante conectar outras aplicações aos nossos serviços, e utilizar em conjunto com as nossas soluções, a Contratante está ciente e concorda que o provedor dessa aplicação, independente da forma que está sendo feita a conexão, poderá acessar e copiar dados da Contratante, conforme necessário para operar a aplicação. A Contratada não se responsabiliza por qualquer incidente, divulgação, modificação, ou exclusão dos dados resultantes do acesso por um terceiro autorizado pela Contratante.</p>
            <p>Se a Contratada identificar que uma aplicação conectada não fornece condições razoáveis para a interoperação com a solução contratada, podemos deixar de fornecer o acesso ao terceiro, sem que haja qualquer penalização, reembolso ou compensação por parte da Contratada. A Contratada não é obrigada a avaliar as aplicações de terceiros, contudo, poderá fazê-los se julgar pertinente.</p>
        </li>

        <li class="item">
            <p><b>DO PAGAMENTO</b></p>
            <p>A Contratante pagará em dólares norte-americanos, todos os valores especificados na Conta Virtual relacionada à assinatura do plano inicial adquirido mais os valores de consumo excedentes, quando houver.</p>
            <p>Ao fornecer as informações de pagamento, você autoriza a Contratada ou a terceiros por nós contratados para processamento de pagamentos, a cobrar em tal cartão de crédito/débito os valores correspondentes à solução contratada de acordo com a Invoice disponibilizada em cada ciclo de fechamento.</p>
            <p>Você deve pagar com um dos seguintes métodos:</p>
            <p class="pl-5 mb-1">1. Um cartão de crédito válido aceitável pela Contratada; </p>
            <p class="pl-5 mb-1">2. Um cartão de débito válido e aceitável Contratada; </p>
            <p class="pl-5">3. Por outra opção de pagamento que a Contratada fornecer a você por escrito ou disponibilizar nas configurações do seu plano de assinatura.</p>
            <p>A opção 1 é a mais usual e válida para cobranças de serviços fornecidos a qualquer país, contudo, para alguns países podemos disponibilizar as opções 2 ou 3 acima.</p>
            <p>Se suas informações de pagamento e registro não forem precisas, atuais e completas e você não as atualizar sempre que elas alterarem, poderemos suspender ou encerrar sua conta e recusar o uso dos Serviços.</p>
            <p>Se você não nos notificar sobre atualizações do seu método de pagamento (por exemplo, data de vencimento do cartão de crédito), para evitar a interrupção de seu serviço, poderemos participar de programas apoiados pelo seu provedor de cartões (por exemplo, serviços de atualização, programas de faturamento recorrentes etc.) para tentar atualizar suas informações de pagamento, e você nos autoriza a continuar faturando sua conta com as informações atualizadas que obtemos, contudo, essa não será uma obrigação da Contratada.</p>
            <p>A Contratada renovará automaticamente seus Serviços mensais ou anuais às taxas vigentes na época, a menos que os Serviços sejam cancelados ou rescindidos sob este Contrato.</p>
            <p>Se não for recebido qualquer montante faturado pela Contratada na data de vencimento, sem prejuízo de serem tomadas outras medidas aplicáveis: a) será cobrado juros de 1% do saldo pendente por mês, ou a taxa máxima permitida por lei, prevalecendo o que for menor, e/ou b) suspender os serviços prestados quando o atraso superar 10 (dez dias), c) ocorrido atraso por mais de 30 (trinta) dias, fica expressamente acordado que a Contratada poderá utilizar serviços de empresas terceirizadas para fazer a cobrança do valor devido, incluindo os encargos legais previstos em lei, e/ou d) informar o inadimplemento aos órgãos e entidades de proteção de crédito e/ou e) Eliminar a conta da Contratada, após 40 (quarenta) dias de inadimplência sem resolução por parte da Contratante.</p>
            <p>Para casos de descumprimento material do Contrato, a suspensão será imediatamente efetiva, independentemente de notificação prévia.</p>
            <p>Sem prejuízo do exposto acima, caso a Contratante esteja contestando as cobranças aplicáveis de forma clara, razoável, de boa fé e estiver cooperando diligentemente para resolver a divergência, a Contratada enquanto perdurarem as negociações, fará seus melhores esforços para resolver a divergência da forma mais célebre possível e evitar as medidas acima mencionadas.</p>
            <p>A Contratante concorda que suas compras não são condicionadas a qualquer expectativa relacionada à entrega de funcionalidades futuras, a serem desenvolvidas relativas à solução, ainda que a Contratada tenha feito algum comentário oral ou por escrito sobre funcionalidades previstas.</p>
            <p>Sendo este Contrato rescindido, a Contratada fica desde já autorizada a eliminar a Conta Virtual da Contratante em definitivo, mediante notificação por e-mail à Contratante.</p>
        </li>

        <li class="item">
            <p><b>LIMITES DE USO</b></p>
            <p>Ao criar sua conta, você irá selecionar o plano inicial da solução, onde estarão definidos os recursos contemplados na sua contratação, as funcionalidades, a quantidade de espaço de armazenamento e a quantidade de usuários. Itens opcionais podem ser incluídos na contratação, ou posteriormente pelo usuário administrador da workspace.</p>
            <p>Enquanto este contrato estiver ativo, a Contratada não irá limitar o consumo dos usuários da Contratante, portanto, embora tenha um pacote de serviços inicial, você poderá cadastrar e deixar ativo quantos usuários desejar, se estiver exercendo um papel de administrador da plataforma, e os usuários não estarão limitados quanto ao volume de armazenamento, sendo assim, mensalmente será contabilizado o consumo total que inclui o valor do pacote inicial, e todos os itens excedentes, seja em número de usuários ativos, em volume de armazenamento ou em itens adicionais, os quais somados contabilizarão o total da fatura mensal. Portanto, se houver excedentes, independente de aprovação prévia da Contratante, a Contratada estará autorizada a realizar a cobrança pro-rata do pacote mensal incluindo as quantidades adicionais.</p>
            <p>O administrador da workspace pode mudar seu plano de serviços para um plano maior que o seu consumo atual ou um plano com características e ferramentas diferentes, contudo deve estar ciente de que para esse segundo cenário, se a workspace usava um plano contendo uma determinada quantidade de funcionalidades e migrar para um plano que contenha menos funcionalidades, todas as funções NÃO contempladas no novo plano deixarão de ser disponibilizadas aos usuários.</p>
        </li>

        <li class="item">
            <p><b>MODIFICAR OU CANCELAR OS SERVIÇOS</b></p>
            <p>Estamos constantemente alterando e melhorando os Serviços. Podemos incluir ou remover funcionalidades ou recursos e podemos também suspender ou encerrar um Serviço por completo. Você pode deixar de usar os Serviços a qualquer momento, embora vamos ficar chateados ao ver você ir embora.</p>
            <p>A Contratada também poderá deixar de prestar os Serviços a você, ou incluir, ou criar novos limites aos Serviços a qualquer momento. Acreditamos que você seja o proprietário dos seus dados e que é importante preservar seu acesso a esses dados. Se descontinuarmos um Serviço, quando razoavelmente possível, você será informado com antecedência e terá a chance de retirar as suas informações dos nossos Serviços.</p>
        </li>

        <li class="item">
            <p><b>EXCLUSÃO DE GARANTIAS</b></p>
            <p>Fornecemos nossos Serviços usando um nível razoável de capacidade e cuidado e esperamos que você aproveite seu uso deles. Mas existem algumas coisas que não prometemos sobre os Serviços. EXCETO QUANDO EXPRESSAMENTE PREVISTO NESTES TERMOS OU EM TERMOS ADICIONAIS, NEM A CONTRATADA, NEM SEUS FORNECEDORES, NEM SEUS PARCEIROS OU DISTRIBUIDORES OFERECEM CERTAS GARANTIAS SOBRE OS SERVIÇOS. POR EXEMPLO, NÃO NOS RESPONSABILIZAMOS PELOS CONTEÚDOS NOS SERVIÇOS, POR FUNCIONALIDADES ESPECÍFICAS, DISPONIBILIDADE OU CAPACIDADE DE ATENDER SUAS NECESSIDADES. FORNECEMOS OS SERVIÇOS “NA FORMA EM QUE ESTÃO”. CERTAS JURISDIÇÕES PREVÊEM DETERMINADAS GARANTIAS, COMO A GARANTIA DE COMERCIABILIDADE IMPLÍCITA, ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA E NÃO VIOLAÇÃO. NA MEDIDA PERMITIDA POR LEI, EXCLUÍMOS TODAS AS GARANTIAS.</P>
            <p>CONFORME PREVISTO NESTE CONTRATO, NENHUMA DAS PARTES FAZ QUALQUER GARANTIA DE QUALQUER TIPO, QUER EXPRESSAS, IMPLÍCITAS, ESTATUTÁRIAS OU OUTRA FORMA, E CADA PARTE RENUNCIA ESPECIFICAMENTE TODAS AS GARANTIAS IMPLÍCITAS, INCLUINDO QUALQUER GARANTIA IMPLÍCITA DE COMERCIABILIDADE, ADEQUADA PARA UM PROPÓSITO PARTICULAR OU NÃO-VIOLAÇÃO, NA EXTENSÃO MÁXIMA PERMITIDA PELA LEI APLICÁVEL. CADA UMA DAS PARTES FICA ISENTA DE TODA RESPONSABILIDADE E OBRIGAÇÕES DE INDENIZAÇÃO POR PERDAS OU DANOS CAUSADOS POR QUAISQUER TERCEIROS PROVEDORES DE HOSPEDAGEM E APLICAÇÕES.</P>
        </li>

        <li class="item">
            <p><b>LICENÇA E DIREITOS DE PROPRIEDADE</b></p>
            <p>A Contratada, reserva todos os seus direitos, título e interessa  relativos às Soluções, incluindo todos os seus direitos de Propriedade Intelectual relacionados.</p>
            <p>O Serviço é disponibilizado com acesso limitado e nenhum direito de Propriedade é transmitido à Contratante. A Contratada tem e mantém todos os direitos, títulos e interesses, incluindo todos os direitos de propriedade intelectual, no e para o serviço, incluindo todas as modificações, atualizações, upgrades, extensões, componentes e todos os trabalhos derivados do serviços. </p>
            <p>A Contratante concede à Contratada e às Afiliadas ou Parceiros da Contratada uma licença mundial, com prazo limitado pelo período que durar esse termo de assinatura, para copiar, transmitir, hospedar e exibir seus Dados, inclusive com código fonte quando estes forem criados por ou para a Contratante, bem como para aplicações de terceiros, conforme for necessário para a Contratada fornecer o acesso às Soluções em conformidade com este contrato.</p>
            <p>A Contratada poderá utilizar esses Dados coletados para fins de levantamento de informações e métricas anonimizadas para melhorar e aprimorar os Serviços e para outros fins de desenvolvimento, diagnóstico e correção do produto que compõe  o serviço, desde que de forma anonimizada, sem a identificação da Contratante.</p>
            <p>A Contratante concede à Contratada uma licença mundial, definitiva, irrevogável e livre de royalties para incorporar e utilizar nas soluções, novos recursos e funcionalidades que tenham sido desenvolvidos à pedido ou por sugestão da Contratante e/ou de seus usuários.</p>
            <p>A CONTRATADA, SEUS FORNECEDORES, PARCEIROS E DISTRIBUIDORES, RENUNCIAM QUAISQUER DECLARAÇÕES OU GARANTIAS DE QUE A SUA UTILIZAÇÃO DOS SERVIÇOS SATISFAÇA OU GARANTA O CUMPRIMENTO DE QUAISQUER OBRIGAÇÕES LEGAIS OU LEIS OU REGULAMENTOS.</p>
        </li>

        <li class="item">
            <p><b>RESPONSABILIDADES SOBRE OS SERVIÇOS</b></p>
            <p>QUANDO PERMITIDO POR LEI, A CONTRATADA E OS FORNECEDORES OU PARCEIROS E DISTRIBUIDORES NÃO SERÃO RESPONSÁVEIS POR PERDA DE LUCROS, PERDA DE RECEITA, PERDA DE DADOS, PERDAS FINANCEIRAS OU DANOS INDIRETOS, ESPECIAIS, CONSEQUENCIAIS, EXEMPLARES OU PUNITIVAS. NA MEDIDA PERMITIDA POR LEI, A RESPONSABILIDADE TOTAL DA CONTRATADA E DE SEUS FORNECEDORES, PARCEIROS E DISTRIBUIDORES, PARA QUALQUER RECLAMAÇÃO SOB ESTES TERMOS, INCLUINDO QUAISQUER GARANTIAS IMPLÍCITAS, LIMITA-SE AO VALOR QUE VOCÊ PAGOU A CONTRATADA NOS ÚLTIMOS 12 (DOZE) MESES PELO USO DOS SERVIÇOS. EM TODOS OS CASOS, A CONTRATADA E SEUS FORNECEDORES, PARCEIROS E DISTRIBUIDORES NÃO SERÃO RESPONSÁVEIS POR QUALQUER PERDA OU DANO QUE NÃO SEJA RAZOAVELMENTE PREVISÍVEL.</p>
            <p>Se você estiver usando nossos Serviços em nome de uma empresa, tal empresa deve aceitar estes termos. Ela isentará de responsabilidade e indenizará a Contratada e suas afiliadas, parceiros, distribuidores, executivos, agentes e trabalhadores de qualquer reivindicação, processo ou ação judicial proveniente ou relacionado ao uso dos Serviços ou à violação destes termos, incluindo qualquer responsabilidade ou despesa resultante de reivindicações, perdas, danos, processos, julgamentos, custos de litígio e honorários advocatícios, sobre estes Termos.</p>
            <p>Se a Contratante for vítima de um processo judicial alegando que o uso dos Serviços contratados violam ou são frutos de apropriação indevida de direitos de propriedade intelectual de terceiros, a Contratada defenderá a Contratante contra qualquer reclamação, demanda ou ação judicial, e indenizará a Contratante por quaisquer danos, despesas e custas atribuídas a uma decisão desfavorável à Contratante por algum tribunal como resultado da reclamação contra a Contratante, desde que a Contratante notifique por escrito à Contratada prontamente em prazo viável para defesa e resolução, e dê o controle exclusivo e o suporte à Contratada para promover a defesa contra qualquer reclamação contra a Contratante relacionada à questões de violação de propriedade intelectual decorrente da prestação dos serviços, ou que a Contratante assuma sua própria defesa e isente incondicionalmente a Contratada de toda e qualquer responsabilidade. Se a Contratante permitir que a Contratada tome o controle exclusivo para a resolução da demanda às custas da Contratada, esta pode definir qual a solução mais eficaz para resolução da demanda, inclusive pode optar a seu critério, por modificar a solução para que não infrinja ou desvie os direitos de propriedade intelectual de terceiros, caso identifique algum desvio, sem violar as garantias da Contratada, incluindo a opção de encerrar esse contrato de assinatura com aviso prévio de 30 (trinta) dias.</p>
            <p>Se a Contratante for acionada judicialmente por violação de propriedade intelectual causada por aplicações de terceiros conectadas aos Serviços objeto deste Contrato, a Contratada ficará isenta de qualquer responsabilidade, e as obrigações de defesa e indenização à Contratante NÃO serão aplicadas à Contratada.</p>
            <p>Se a Contratada for vítima de uma reclamação, demanda, ação ou processo judicial apresentado por um terceiro, sob a alegação de que os Dados armazenados e processados pela Contratante ou a forma que utiliza os serviços e processa os dados, viola o presente acordo, ou infringe algum regulamento ou lei, quando aplicável, inclusive violação de propriedade intelectual de terceiros, a Contratante irá indenizar a Contratada de quaisquer danos, custas, despesas decorrente da defesa ou de resultado de condenação que venham ser atribuídos à Contratada por conta de tal demanda, seja em razão de uma decisão por um tribunal ou de um acordo firmado de forma extra judicial, desde que a Contratada dê aviso imediato e por escrito à Contratante, tão logo tenha ciência e identifique que a causa da demanda está relaciona à violações ou possíveis violações por parte da Contratante e que a Contratada dê à Contratante controle exclusivo da defesa mediante requerimento por parte da Contratante em tempo hábil, ressalvando que a Contratante não pode fazer acordo em nome da Contratada, a menos que este acordo libere a Contratada incondicionalmente de toda e qualquer responsabilidade e seja previamente aprovado por escrito pela Contratada. A Contratada deve fornecer à Contratante assistência razoável, às suas expensas, para que esta possa promover a defesa da Contratada.</p>
        </li>

        <li class="item">
            <p><b>CONTEÚDO</b></p>

            <p>Você é responsável por todos os materiais, documentos, dados e informações pessoais ('Conteúdo') carregados, postados ou armazenados por meio do uso dos Serviços. Você concede à Contratada uma licença mundial, isenta de royalties e não exclusiva para hospedar e monitorar qualquer Conteúdo fornecido por meio do uso dos Serviços.</p>
            <p>Você deve utilizar as melhores práticas para armazenar seu conteúdo porque você é responsável por qualquer conteúdo perdido ou irrecuperável por imperícia e mau uso do Serviço. A Contratada não é responsável pelo Conteúdo ou pelos dados enviados através da Plataforma pela qual fornecemos os Serviços. Você concorda em não usar, nem permitir que terceiros usem os Serviços para fazer upload, publicar, distribuir, vincular, reproduzir, envolver ou transmitir qualquer um dos seguintes conteúdos, incluindo, mas não limitados a:</p>
            <p class="pl-5">a) Ilegal, fraudulento, difamatório, obsceno, pornográfico, profano, ameaçador, abusivo, ofensivo, impróprio ou censurável, ou comunicações de qualquer tipo, incluindo, sem limitação, condutas que encorajem a 'inflamação' de outras pessoas, ou infrinja qualquer lei local, estadual, federal ou estrangeira, na esfera criminal ou civil; b) Conteúdo que personifique outra pessoa ou represente falsamente sua identidade ou qualificações, ou que constitua uma violação da privacidade de qualquer indivíduo; c) Exceto quando permitido pela Contratada por escrito, conteúdo do tipo: cartas em cadeia, esquemas de pirâmide, outras comunicações comerciais não solicitadas ou envolver-se em spamming ou flooding; d) Vírus, cavalo de Tróia, worm ou outro software ou dados disruptivos ou prejudiciais; e) Qualquer informação, software ou Conteúdo que não seja legalmente seu e sem permissão do proprietário dos direitos autorais ou proprietário dos direitos de propriedade intelectual ou do titular de dados, quando aplicável.</p>
            <p>A Contratada pode, mas não tem obrigação de monitorar o conteúdo dos Serviços. Podemos divulgar qualquer informação necessária para satisfazer nossas obrigações legais, proteger a Contratada ou seus clientes, proteger a privacidade, promover a segurança e proteger a vida, ou operar os Serviços adequadamente.</p>
            <p>Se a Contratada for obrigada por lei a enviar-lhe comunicações sobre os Serviços ou produtos de terceiros, você concorda que a Contratada pode enviar essas comunicações para você via e-mail ou postando-as em nossos sites ou a partir de nossas aplicações as quais você tiver acesso, respeitando a confidencialidade das informações.</p>
            <p>A parte receptora de informações confidenciais poderá divulgá-la somente quando obrigada por lei, regulamento ou ordem judicial ou administrativa, sendo obrigada a notificar prontamente a outra parte antes da divulgação, com a finalidade de facilitar os esforços para proteger as informações confidenciais. A Parte receptora só poderá divulgar a porção da informação a qual estiver obrigada, preservando a confidencialidade das demais informações.</p>
            <p>Quando a divulgação for feita nos termos do parágrafo acima, a parte divulgadora não será responsabilizada pela divulgação das informações confidenciais.</p>
            
        </li>

        <li class="item">
            <p><b>RESCISÃO</b></p>

            <p>A Contratada pode imediatamente, a seu exclusivo critério e sem aviso prévio, rescindir este Contrato ou suspender os Serviços se você não cumprir os termos este Contrato ou se você não concordar mais em receber comunicações eletrônicas. Após o término, você deve parar imediatamente de usar os Serviços e quaisquer pagamentos pendentes serão devidos. Qualquer rescisão deste Contrato não afetará os direitos da Contratada a quaisquer pagamentos devidos a ela.</p>
            <p>A Contratada pode suspender o uso dos Serviços, remover conteúdo ou desativar e até encerrar qualquer conta virtual, se a Contratada razoavelmente acreditar, de boa fé, que a Contratante ou algum de seus usuários estão infringindo os termos deste contrato e a política de privacidade. A Contratada notificará a Contratante antes de suspender ou desativar a conta, a menos que esteja impedida legalmente de fazê-lo. Em situação de impedimento, a Contratada notificará a Contratante quando as restrições impeditivas deixarem de ter efeito legal.</p>
            <p>A contratante poder rescindir esse contrato, sem nenhuma motivação, mediante aviso prévio no mínimo 30 (trinta) dias, sem a incidência de multa rescisória.</p>
            <p>Qualquer das partes podem rescindir esse contrato, se uma das motivações forem identificadas:</p>
            <p class="pl-5 mb-1">- Uma das partes entrar em processo de falência ou concordata.</p>
            <p class="pl-5 mb-1">- Se não for sanada violação material comprovada de acordo com o presente contrato.</p>
            <p class="pl-5">- Em situações de força maior, desastres, pandemias, eventos naturais que impeçam comprovadamente a continuidade da prestação dos serviços.</p>
            <p>Qualquer das hipóteses de rescisão contratual acima especificadas, requer aviso prévio mínimo de 30 (trinta) dias, sem prejuízo do recebimento por parte da Contratada dos valores devidos pela Contratante até a data efetiva da rescisão. Nenhum reembolso será devido à Contratante.</p>
            
        </li>

        <li class="item">
            <p><b>MODIFICAÇÕES DESTE TERMO</b></p>

            <p>Podemos modificar estes termos ou quaisquer termos adicionais que sejam aplicáveis ao Serviço para, por exemplo, refletir alterações da lei ou mudanças em nossos Serviços. Você deve consultar os termos regularmente. Postaremos avisos sobre modificações nesses termos nesta página. Publicaremos um aviso de alteração sobre os termos adicionais dentro do Serviço aplicável. As alterações não serão aplicadas retroativamente e entrarão em vigor pelo menos quatorze dias após sua publicação. Entretanto, alterações a respeito de novas funcionalidades de um Serviço ou alterações feitas por razões legais entrarão em vigor imediatamente. Se você não concordar com os termos alterados de um Serviço, deve descontinuar o uso desse Serviço. Estes termos regem a relação entre a Contratada e você. </p>
            <p>Os valores cobrados pelos serviços podem ser alterados pela Contratada que deverá informar a Contratante através do endereço eletrônico fornecido como responsável pela conta virtual e as alterações passarão a incidir sobre o valor dos serviços a partir da próxima renovação da assinatura mensal.</p>
            <p>A Contratada poderá notificar a Contratante sobre informações gerais referentes ao uso dos Serviços ou qualquer comunicação que se fizer necessária, por meio do correio eletrônico registrado por você na Plataforma para o administrador da conta virtual e para o e-mail de faturamento, quando se tratar de informações de cobrança. É responsabilidade da Contratante manter os dados da conta virtual atualizados. É necessário que os e-mails enviados a partir do domínio da Plataforma sejam configurados como seguros para que não sejam tratados como spam pelo serviço de e-mail utilizado pela Contratante, pois toda informação enviada será considerada entregue a partir de 1 dia útil após o envio. </p>
            <p>Avisos de manutenção dos serviços, intervenções programadas, assuntos técnicos de interesse dos usuários, disponibilização de novas funcionalidades, podem ser enviados diretamente aos usuários, opcionalmente pela Contratante.</p>
            <p>Para se comunicar com a Contratada, a Contratante pode se utilizar dos seguintes meios:</p>
            <p class="mb-1"><b>Chat da Plataforma:</b> para atendimento direto aos usuários do serviço para esclarecimento de dúvidas, suporte técnico e orientação de uso. </p>
            <p class="mb-1"><b>E-mail support@{{company.location}}:</b> para abertura de chamados de suporte técnico, sugestões de melhorias, esclarecimentos de dúvidas quanto a configurações e uso dos serviços.</p>
            <p class="mb-1"><b>Questões relacionadas a tratamento de dados:</b> dpo@{{company.location}}</p>
            <p><b>E-mail legal@{{company.location}}:</b> para assuntos relacionados a demais solicitações, inclusive rescisão deste termo de assinatura ou questões legais.  </p>
            
        </li>

        <li class="item">
            <p><b>LEI APLICÁVEL</b></p>
            <p>A Contratante concorda que a lei estadual da Flórida / EUA rege este Contrato sem considerar os conflitos de provisões legais, sem levar em conta a escolha de outra jurisdição, por mais benéfica que seja.</p>            
        </li>

        <li class="item">
            <p><b>DISPUTAS</b></p>
            <p>QUALQUER DISPUTA OU REIVINDICAÇÃO RELACIONADA COM OS SERVIÇOS OU A ESTE CONTRATO SERÁ RESOLVIDO PELA ARBITRAGEM VINCULATIVA, EM VEZ DE CORTE, exceto que você pode reivindicar no tribunal de pequenas causas se suas reivindicações se qualificarem. O Federal Arbitration Act regula a interpretação e aplicação desta disposição; o árbitro aplicará a lei da Flórida/EUA a todos os outros assuntos. Não obstante qualquer disposição em contrário, qualquer parte na arbitragem pode, a qualquer momento, solicitar liminares ou outras formas de reparação equitativa de qualquer tribunal de jurisdição competente.</p>
            <p>Este Contrato, incluindo a Política de Privacidade, é o acordo integral entre você e a Contratada e substitui todos os entendimentos, comunicações e acordos anteriores, orais ou escritos, com relação ao assunto em questão. Se qualquer tribunal, tendo a jurisdição, determinar que qualquer parte deste Contrato é inválida, essa seção será removida sem afetar o restante do Contrato. Os termos restantes serão válidos e aplicáveis.</p>
            <p>A Convenção das Nações Unidas sobre Contratos para a Venda Internacional de Mercadorias não se aplica a este Contrato. Você não pode atribuir ou transferir a propriedade deste Contrato para ninguém sem a aprovação por escrito da Contratada. Entretanto, a Contratada pode ceder ou transferi-lo sem o seu consentimento para (a) um parceiro, (b) uma empresa através de uma venda de ativos pela Contratada ou (c) um sucessor por meio de uma fusão. Qualquer atribuição que viole esta Seção será anulada.</p>
            <p>A CONTRATANTE, POR MEIO DESTE, RENUNCIA A QUALQUER DIREITO DE FORMULAR QUALQUER PEDIDO COMO REPRESENTANTE OU ADVOGADO DE UMA DETERMINADA CLASSE EM UMA BASE DE MASSA OU AGREGADA, OU PARA CONSOLIDAR O PROCESSO DE ARBITRAGEM SEM O CONSENTIMENTO DE TODAS AS PARTES. Qualquer sentença proferida pelo árbitro será final, conclusiva e vinculativa para as partes. Em conexão com qualquer procedimento de arbitragem em conformidade com estes termos e condições, a menos que o árbitro determine de outra forma, cada parte arcará com seus próprios custos e despesas. Após a confirmação de uma sentença arbitral em Tribunal Federal ou o Tribunal Estadual da Flórida, a condenação pode ser executada em qualquer tribunal de jurisdição competente. Não obstante o acima exposto, esta seção não impedirá a Contratada de buscar uma liminar, ou outro recurso em equidade, em qualquer tribunal de jurisdição competente.</p>
            <p>Este contrato é feito por partes independentes e não cria uma parceria, associação, sociedade, franquia, joint venture ou relação de emprego.</p>
            <p>Caso você não cumpra estes termos e nós não tomemos providências imediatas, isso não significa que estamos renunciando a quaisquer direitos que possamos ter (como tomar providências futuras). Caso uma condição específica deste termo não seja executável, isso não prejudicará quaisquer outra disposta nestes termos.</p>            
        </li>

        <li class="item">
            <p><b>COMBATE À CORRUPÇÃO E LAVAGEM DE DINHEIRO</b></p>
            <p>De acordo com o que determina os limites da relação contratual estipulada por esse Termo de Assinatura, as partes obrigam-se, por si, por seus empregados, sócios, prepostos, diretores, associados, terceiros, afiliados, e qualquer pessoa que venha agir em seu nome, a conduzir suas práticas comerciais, por todo período em que esse contrato estiver ativo, de forma ética e de acordo com os preceitos legais aplicáveis, não podendo realizar promessas de pagamentos ou pagamentos, doações, ou qualquer benefícios ou agrado, direta ou indiretamente a qualquer terceiros, representantes do governo, de entidades públicas ou privadas com a finalidade de influenciar uma atitude ou uma tomada de decisão para assegurar qualquer vantagem indevida, ilícita, irregular e/ou injusta. Ambas as partes declaram estar ciente e comprometem-se a cumprir fielmente as disposições legais relacionadas ao combate e prevenção ao crime de “lavagem” e ocultação de bens.</p>            
        </li>

        <li class="item">
            <p><b>DISPOSIÇÕES SOBREVIVENTES</b></p>
            <p>As partes concordam que as seções 3, 4, 5, 8, 9,10,11, 14 e 15 sobreviverão a qualquer rescisão ou expiração deste contrato.</p>            
        </li>
    </ul>

    <br>
    <p>Cada parte declara que validamente celebrou esse termo de assinatura (Contrato) e tem o poder legal para fazê-lo.</p>            
    <br>
    <br>
    

</div>

</template>

<script>export default {     
    props: ['company'],
    inject: ['scrollto']
}
</script>

<style scoped lang="scss">


</style>