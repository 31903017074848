<template>
<div class="documentation">

    <ul class="numbered-list mb-5 no-print">

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.policy', 1)">Definições</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 2)">Quais são os seus direitos como titular de dados</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 3)">Quais dados tratamos</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 4)">Para que usamos cada dado</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 5)">Como coletamos e para que usamos cada dado</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 6)">Dados que são coletados opcionalmente</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 7)">Dados coletados de terceiros</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 8)">Na qualidade de OPERADORA, como a {{company.company_name}} trata os documentos e dados armazenados por você na plataforma:</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 9)">Compartilhamento com terceiros</span></li>

            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">

                <li class="link"><span @click.stop="scrollto('.policy', 10)">Blog e Comunidade</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 11)">Compartilhamento Internacional de Dados:</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 12)">Tempo de processamento e retenção dos dados</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 13)">Do consentimento</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 14)">Corrigir, Atualizar ou Excluir suas informações</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 15)">Direitos de privacidade para usuários da Califórnia</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 16)">Informações pessoais de crianças</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 17)">Base Legal para o processamento de dados pessoais</span></li>
                <li class="link"><span @click.stop="scrollto('.policy', 18)">Disposições gerais</span></li>

            </div>
        </div>
    </ul>
    
    <div class="intro mb-5 d-flex flex-column justify-content-center align-items-center">
        <p>
            Esta Política de Privacidade descreve os procedimentos utilizados pelo Grupo
            {{company.company_name}} para processamento de Dados Pessoais. Ela se aplica a todas as formas
            de coleta de dados que aplicamos para fornecimento dos nossos serviços.                
        </p>

        <p>
            Para um melhor entendimento de nossa Política, descrevemos abaixo as
            definições dos termos utilizados:
        </p>
    </div>

    <ul class="policy numbered-list">

        <li class="item">
            <p><b>Definições</b></p>

            <p><b>Dados Pessoais:</b> São as informações que permitem identificar uma pessoa natural de forma direta ou indiretamente através do cruzamento de dados. Exemplo: Número da Identidade, CPF, Endereço, Fotografia, Opção Religiosa, Partidária, Data de Nascimento, Dados de Saúde, Biometria, Etc..</p>
            <p><b>Titular:</b> É o proprietário do dado, ou seja, a pessoa natural a quem o dado se refere.</p>
            <p><b>Tratamento de Dados:</b> É qualquer ação realizada com uma informação pessoal, como por exemplo: captura, visualização, compartilhamento, recepção, edição, eliminação, transferência, etc..</p>
            <p><b>Finalidade:</b> Objetivo que se pretende atingir com o tratamento dos dados realizados.</p>
            <p><b>Controlador:</b> É a pessoa física ou jurídica indicada pela {{company.company_name}} para responder aos questionamentos e garantir que o tratamento de dados seja realizado de acordo com o que determina a legislação aplicável, respeitando o direito dos titulares.</p>
            <p><b>Operador:</b> É a pessoa física ou jurídica que realiza o tratamento de dados em nome do controlador.</p>
            <p><b>Terceiro:</b> Pessoa Física ou jurídica de direito público ou privado com os quais a {{company.company_name}} se relaciona ou venha a se relacionar.</p>
            <p><b>Encarregado:</b> É a pessoa física ou jurídica indicada pela {{company.company_name}} para responder aos questionamentos e garantir que o tratamento de dados esteja sendo realizado de acordo com o que determina a LGPD, respeitando o direito dos titulares.</p>
        </li>

        <li class="item">
            <p><b>Quais são os seus direitos como titular de dados</b></p>

            <p>- Como titular dos dados, você tem direito de ter acesso facilitado às informações sobre o tratamento de seus dados, que deverão ser disponibilizadas de forma clara, adequada e ostensiva; </p>
            <p>- Você pode confirmar a existência de tratamento;</p>
            <p>- Você pode solicitar e ter acesso aos dados;</p>
            <p>- Se identificar que os dados estão incompletos, inexatos ou desatualizados, você pode solicitar a correção dos dados;</p>
            <p>- A {{company.company_name}} não irá guardar dados desnecessários, mas se algum dado for considerado excessivo você pode solicitar a anonimização, bloqueio ou eliminação de dados, inclusive se eles estiverem sendo tratados em desconformidade com o disposto na legislação aplicável;</p>
            <p>- Se você precisar que seus dados sejam transferidos para outro fornecedor, você pode nos solicitar a portabilidade dos dados de serviços ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</p>
            <p>- Se você nos deu consentimento para tratar seus dados, você pode solicitar a eliminação dos mesmos, excetos nas hipóteses previstas na legislação aplicável;</p>
            <p>-  Nós lhe informaremos sobre quais entidades públicas ou privadas a {{company.company_name}} compartilhou seus dados, quando ocorrer o compartilhamento.</p>
            <p>- Se você desejar NÃO nos fornecer seu consentimento para o tratamento de dados, nós acataremos sua decisão e lhe informaremos se a sua decisão ocasionar alguma consequência pelo não fornecimento;</p>
            <p>- Você tem direito de revogar seu consentimento, mediante manifestação expressa, de forma gratuita e facilitada;</p>
            <p>- Se for necessário, você tem direito de peticionar perante a ANPD, perante os organismos de defesa do consumidor e perante as instituições reguladoras das questões relacionadas a proteção de dados pessoais, com relação aos seus dados, caso eles sejam tratados de forma inadequada pela {{company.company_name}};</p>
            <p>- Como titular dos dados você pode se opor ao tratamento de dados fundamentado em uma das hipóteses de dispensa de consentimento em caso de descumprimento ao disposto na Legislação Aplicável;</p>
            <p>- Se a {{company.company_name}} tratar dados de forma automatizada, e tomar decisões baseadas unicamente nesse tratamento, e essas decisões afetarem seus interesses, você pode solicitar a revisão de tais decisões.</p>
            <p>- A qualquer momento você pode solicitar informações claras e adequadas a respeito dos critérios e dos procedimentos que utilizamos para tratamento de dados com decisões automatizadas, desde que observados os segredos comercial e industrial;</p>
            <p>Para exercer seus direitos relacionados a proteção de dados, envie-nos sua solicitação para o e-mail dpo@{{company.location}} e nossa encarregada pela proteção de dados, sra Lecivania Martins, tomará as medidas necessárias para atendimento à vossa solicitação.</p>
            <p>Se você exercer seu direito e retirar seu consentimento para o tratamento de dados, e não houver outra base legal que ampare o processamento, nós deixaremos de processar seus dados para o objetivo consentido.</p>
            <p>Qualquer informação que você fornecer em blogs, websites, e que esteja exposta na internet em locais sem controle de acesso, de forma pública, nós não podemos garantir a proteção dessas informações e nem limitar quem poderá copiar ou compartilhar tais dados.</p>
        </li>

        <li class="item">
            <p><b>Quais dados tratamos</b></p>
            <p>A {{company.company_name}} é uma provedora de serviços de distribuição de documentos online e automatização de processos de negócios baseados nas tecnologias de ECM (Enterprise Content Management), BPM (Businesse Process Management) e serviços de Assinatura Digital e Eletrônica de Documentos. Nossos serviços são fornecidos online com contratação direta pelas empresas usuárias ou através de parceiros de negócios e distribuidores. Tratamos os dados necessários para ativar e manter nossos serviços em funcionamento, garantir a segurança, a disponibilidade dos serviços, diagnosticar problemas técnicos, lembrar informações que sejam úteis e contribuíam para uma melhor utilização do serviço, enviar notificações sobre manutenção e alertas que se façam necessários para comunicar aos usuários, além de dados que nos permitam prestar suporte técnico aos usuários, prover treinamentos, orientações de boas práticas de uso, informar aos usuários sobre melhorias e novas funcionalidades, ofertar serviços complementares que sejam relevantes para os usuários, ou novos serviços disponíveis para quem ainda não é usuário.</p>
            <p>Cada pessoa física ou jurídica que contrata nossos serviços pode armazenar e tratar dados utilizando as funcionalidades disponíveis em nossa plataforma, sendo assim, são identificados como CONTROLADOR, e a {{company.company_name}} passa a atuar como OPERADORA. Nessa condição, nós possuímos os seguintes poderes sobre os dados: mantê-los seguros e acessíveis, eliminá-los ou fornecer cópia dos dados, mediante um pedido expresso do CONTROLADOR, respeitando as configurações definidas pelo CONTROLADOR e seus responsáveis. Como OPERADORA a {{company.company_name}} se compromete em tratar as informações classificadas como dados pessoais seguindo rigorosamente os critérios definidos pelo CONTROLADOR e especificados na GDPR (General Data Protection Regulation), na LGPD (Lei Geral e Proteção de Dados) e nos demais regulamentos a que estiver sujeito, unicamente para alcance dos fins delimitados nos termos de uso, não podendo a {{company.company_name}} ser responsabilizada perante a Autoridade Nacional de Proteção de Dados – ANPD e/ou outras autoridades, e aos Titulares de Dados ou a qualquer outra instituição detentora de poderes para aplicar penalidades e sanções a título administrativo ou judicial.</p>
            <p>Se a {{company.company_name}} vier a ser penalizada na qualidade de OPERADORA, fica resguardado seu direto de regresso para requerer do CONTROLADOR o ressarcimento por todas as perdas e danos e despesas decorrentes do ilícito, além de outras medidas que se façam necessárias para proteger os direitos da {{company.company_name}}.</p>
            <p>A {{company.company_name}} e as empresas pertencentes ao grupo não tem controle de quais dados os usuários armazenam de acordo com o item 8 do nosso Termo de Uso (<a :href="`https://${company.location}/terms-and-conditions/${lang}`" target="_blank">https://{{company.location}}/terms-and-conditions</a>).</p>
            <p>Para usar nossos serviços você fornece as seguintes informações que são consideradas como Dados Pessoais e pelas quais a {{company.company_name}} tem responsabilidade como CONTROLADORA:</p>
            <p>No ato da contratação dos serviços ou como usuário da plataforma, ou quando acessa nossos sites e nos envia um currículo pessoal, você nos oferece seu Nome, E-mail, Telefone, Cargo e Dados de Currículo (os dados do currículo variam de acordo com cada titular).</p>            
        </li>

        <li class="item">
            <p><b>Para que usamos cada dado</b></p>
            <p><b>Nome e E-mail:</b> </p>
            <p>Utilizamos esses dados para enviar a notificação inicial para criação da sua área de trabalho, posteriormente, podemos enviar comunicados relacionados ao serviço, às necessidades de manutenção, atualizações, ou comunicados de obrigações legais, informações produzidas pela área de marketing referente a novos produtos e funcionalidades, ofertas, ou qualquer outro que se faça necessário para aperfeiçoar a prestação do serviço ou com objetivo de marketing. Você pode optar por não receber as mensagens de marketing, marcando a opção que será disponibilizada no corpo do e-mail; Se recebermos uma comunicação por e-mail, nós poderemos reter a mensagem, incluindo as nossas respostas e as demais mensagens respondidas por você para fins de manutenção de histórico de atendimento. </p>            
            <p><b>Número de Telefone:</b></p>
            <p>Coletamos o número de telefone para realizar contatos posteriores a partir do WhatsApp ou de outros aplicativos de mensagens com objetivo de atendimento a solicitações de suporte de maneira mais rápida e precisa, e/ou para envio de comunicados e de material de marketing, ou ainda para fins de contatos por nosso departamento financeiro, quando adequado e necessário.</p>
            <p>Nós não adicionaremos você a nenhum grupo, mas podemos enviar o convite para que você decida se quer fazer parte dos nossos grupos de divulgação, marketing e de suporte.</p>
            <p><b>Currículo</b>: </p>
            <p>Os currículos enviados pelos titulares de dados, são armazenados em nossa base por 24 meses e são utilizados para selecionar colaboradores para nossos processos de contratação, quando uma nova vaga é aberta.</p>
            <p><b>Cargo:</b> </p>
            <p>O cargo é armazenado no cadastro de clientes e parceiros para que possamos identificar as funções de cada pessoa com as quais temos algum relacionamento, isso ocorre para que possamos nos direcionar às pessoas corretas para tratar de assuntos pertinentes, quando necessário.</p>            
            <p><b>Quanto aos Dados de Pagamento:</b></p>
            <p>Os dados de pagamento são utilizados para fins de processamento da cobrança do valor mensal dos serviços, entretanto, quando selecionada a opção de pagamento por cartão de crédito e/ou débito, a {{company.company_name}} não os armazena, ela os associa a um ID de assinatura no Stripe ou Paypal os quais coletam e mantem os dados para realizar o processamento do pagamento. A {{company.company_name}} mantém somente o ID associado. Os nossos subprocessadores possuem suas próprias políticas de privacidade.</p>            
            <p>Para os demais métodos de pagamento que disponibilizamos, coletamos e mantemos informações de cobrança, um registro de assinatura e os registros de pagamentos para a continuidade dos serviços.</p>
            <p>Além dos dados pessoais acima, os quais você fornece quando da adesão dos nossos serviços ou da utilização da plataforma, podemos coletar os seguintes dados adicionais: Localização, IP da máquina de acesso e Identificação de dispositivos de acesso.</p>
        </li>

        <li class="item">
            <p><b>Como coletamos e para que usamos cada dado</b></p>
            <p><b>Sua localização:</b></p>
            <p>Podemos coletar, armazenar e monitorar dados de localização quando você acessa um de nossos sites ou a aplicação {{company.company_name}}. Esses dados podem ser utilizados em conjunto com outros dados, se assim for feito, tornam você identificável, os dados de localização são necessários para o funcionamento adequado de alguns serviços como: identificação da moeda local e idioma.</p>
            <p><b>IP da máquina de acesso:</b></p>
            <p>Essa informação unificada ao endereço de e-mail e a localização, nos permite identificar onde o usuário está alocado em nossa plataforma, e a qual região ele pertence, o que nos ajuda melhorar nossos procedimentos de atendimento e identificar possíveis problemas que possam ter suas causas relacionadas à região, como por exemplo: problemas com performance do serviço, caso ocorra.</p>
            <p><b>Identificação de dispositivos de acesso:</b></p>
            <p>Ao acessar os serviços através de um dispositivo móvel podemos coletar, armazenar, monitorar e acessar os identificadores dos dispositivos, que são pequenos arquivos de dados associados a um dispositivo móvel que identificam esse dispositivo e como ele é utilizado por você, o que pode tornar você identificável se associado a outros dados.</p>
            <p>Armazenamos esses dados para renderização das páginas de acordo com o tamanho do dispositivo utilizado pelo usuário e para restringir a utilização de algumas funções que não são adequadas a alguns tipos de dispositivos.</p>
            <br>

            <p>Outros dados que processamos a partir dos dados coletados acima:</p>
            <p><b>Cookies</b></p>
            <p>Os Cookies ajudam a fazer o login na plataforma de forma mais rápida porque armazenam no seu computador informações sobre como você navega pelo site, enviando essas informações para nós de forma anônima permite que o {{company.company_name}} melhore a sua experiência de navegação.</p>
            
            <p>Um cookie persistente permanece no seu disco rígido depois de fechar seu navegador para que ele possa ser usado pelo seu navegador em visitas subsequentes ao Serviço. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Um cookie de sessão é temporário e desaparece depois que você fecha seu navegador.</p>

            <p><b>Arquivos de Log:</b></p>
            <p>Quando você usa o Serviço, nossos servidores registram automaticamente certas informações enviadas pelo seu navegador da Web. Esses logs de servidor podem incluir informações como sua solicitação web, endereço do Protocolo da Internet ("IP"), tipo de navegador, páginas de consulta/saída e URLs, número de cliques e como você interage com links no Serviço, nomes de domínio, landing pages, páginas visualizadas, operadora móvel e outras informações.</p>
            <p><b>Web Beacons:</b></p>
            <p>Podemos utilizar web beacons em e-mails baseados em HTML enviados aos usuários, sendo assim podemos rastrear quais deles são abertos pelos destinatários e os padrões de uso on-line. Essas informações nos permitem emitir estatísticas mais elaboradas e tornar nossos serviços mais atraentes aos usuários.</p>
            
        </li>

        <li class="item">
            <p><b>Dados que são coletados opcionalmente</b></p>
            <p>Você pode adicionar sua foto no perfil de acesso da plataforma quando estiver utilizando os serviços. Essa ação é opcional e você pode removê-lo quando desejar.</p>
            <p>Você ainda pode nos enviar seus dados quando preenche um formulário online, nos envia e-mail ou faz contato através de bate-papo.</p>
            <p>Esses dados são processados para fornecer a você as informações que você nos solicitar e podem ser utilizados para marketing direto e para fins analíticos.</p>
            <p>Se você se inscrever em um de nossos eventos online, nos enviará suas informações de contato pelos quais forneceremos a você o acesso para participação do evento, e esses dados são processados pro nós para entender melhor nossa base de usuários ou de futuros usuários e para fins de marketing direto.</p>
            <p>Você pode cancelar o recebimento de comunicações de marketing da {{company.company_name}}, para isso siga as instruções de cancelamento enviadas no rodapé dos e-mails promocionais, ou envie um e-mail para dpo@{{company.location}}.</p>
        </li>


        <li class="item">
            <p><b>Dados coletados de terceiros</b></p>

            <p>Podemos coletar dados a partir de fontes de terceiros para que você possa fazer login em nossos serviços ou se você compartilhar dados armazenados nos nossos serviços com terceiros, esses serviços de terceiros podem nos enviar informações sobre você, sobre seu perfil público, desde que as configurações da sua conta na aplicação do terceiro, permita esse tipo de compartilhamento.</p>
            <p>Também podem os receber informações associadas ao uso dos nossos sites a partir de aplicações de terceiros. Portanto, se você visitar nossos sites, podemos monitorar e registrar suas atividades e nossos provedores terceirizados podem nos fornecer informações adicionais que estão disponíveis para acesso público.</p>
            <p>As informações que coletamos também podem ser utilizadas para identificação, autenticação, prevenção de fraude e para melhorar o serviço que prestamos incluindo solução de problemas, pesquisas de mercado e para fazer cumprir nossos termos de uso de serviços.</p>

        </li>

        <li class="item">
            <p><b>Na qualidade de OPERADORA, como a {{company.company_name}} trata os documentos e dados armazenados por você na plataforma:</b></p>
            <p>O conteúdo armazenado por você e as pessoas da sua organização, na plataforma {{company.company_name}}, não são visualizados por nossa equipe de suporte, exceto se o administrador da plataforma (pessoa cadastrada pelo CONTROLADOR), fornecer um login e senha de acesso para um de nossos colaboradores, para fins exclusivos de suporte técnico e orientações sobre uso da aplicação. Caso isso ocorra, o usuário deve ser desativado pelo administrador da plataforma ao final do atendimento. Cada um de nossos clientes recebem uma base isolada, garantindo a proteção do acesso à informação, portanto, só terão acesso ao conteúdo, as pessoas a quem o administrador da plataforma (que é um usuário master da empresa contratante dos serviços) fornecer login e senha de acesso com as respectivas permissões. Todos os conteúdos e dados armazenados (registros, arquivos eletrônicos, metadados, formulários, processos, etc...), são transferidos para os nossos servidores na Amazon AWS (certificações HIPAA, ISSO 27001, SOC 1/2/3, a Directiva 95/46/CE e PCI DSS Nível 1) mediante digitação, digitalização e /ou upload pelas pessoas autorizadas pelo CONTROLADOR de acordo com as regras e configurações predefinidas pelo administrador da plataforma, através de uma conexão com a internet, o conteúdo trafega entre as estações de trabalho ou dispositivos e são transferidos via HTTPS, utilizando a criptografia TLS. </p>
        </li>
            

        <li class="item">
            <p><b>Compartilhamento com terceiros</b></p>
            
            <p>A {{company.company_name}} pode compartilhar seus dados com nossos parceiros de negócios quando considerarmos que eles são confiáveis e que possam ter produtos e serviços que possam ser relevantes para você ou para que possamos executar esse contrato de prestação de serviços, como por: exemplo provedores de hospedagem. Quando precisamos fazer isso, nos certificamos de que o terceiro é confiável e manterá a confidencialidade sobre seus dados.</p>
            <p>Se a {{company.company_name}} passar por um processo de fusão, aquisição, ou mudança no quadro societário, a empresa poderá compartilhar seus dados com terceiros em decorrência desse processo.</p>
            <p>Se você acessar nossa aplicação utilizando aplicativos de terceiros diretamente, os seus dados podem ser compartilhados com ele, por isso você deve se certificar de que confia no aplicativo e observar as políticas de proteção de dados da empresa proprietária do aplicativo.</p>
            <p>Podemos compartilhar dados com aplicativos de terceiros através do uso da API, onde os dados armazenados podem ser consultados e/ou copiados para outras aplicações, com a devida anuência do CONTROLADOR (pessoa física ou jurídica responsável pelos dados armazenados na nossa plataforma).</p>
            <p>A {{company.company_name}} pode divulgar seus dados pessoais quando for obrigada por lei ou alguma disposição legal, com objetivo de proteger a segurança de qualquer indivíduo em questões de fraudes, proteger o direito à vida, à liberdade ou para proteger os direitos da {{company.company_name}}.</p>
            <p>Usamos ferramentas analíticas de terceiros, elas coletam informações enviadas pelo seu navegador como parte de uma solicitação de página web, complementos do seu navegador e outras informações, para nos ajudar a medir o tráfego e as tendências de uso dos nossos Serviços. </p>
            <p>A {{company.company_name}} pode armazenar e processar dados pessoais em sistemas de marketing de terceiros, em sistemas de relacionamento com cliente (CRM), sistemas de gerenciamento de correio eletrônico, a vim de promover as operações da empresa e facilitar as interações com os usuários e os clientes em potencial.</p>
            
        </li>

        <li class="item">
            <p><b>Blog e Comunidade</b></p>
            <p>Podemos criar blogs e comunidades que ficam acessíveis ao público, incluindo fóruns. Você deve saber que toda informação que você incluir nessas áreas poderão ser lidas por outras pessoas, as quais não podemos controlar, podendo ainda ser coletada e utilizada por quem acessar esse conteúdo, e as informações postadas podem permanecer disponíveis mesmo depois que você encerrar sua conta.</p>
            <p>Se você remover informações postadas no serviço de blog e/ou comunidade, as cópias podem permanecer armazenadas em cache do serviço ou por outros usuários. Se você desejar que façamos a remoção dessas informações, deve nos enviar um e-mail para dpo@{{company.location}}, contudo, em alguns casos, podemos não conseguir remover tais informações pessoais.</p>
        </li>

        <li class="item">
            <p><b>Compartilhamento Internacional de Dados:</b></p>
            <p><b>A hospedagem dos nossos serviços está distribuída nas regiões abaixo:</b></p>
            <p>Amazon AWS – São Paulo (Brasil) e Virginia (Estados Unidos da América).</p>
            <p>Os dados são distribuídos de forma automatizada através dos recursos tecnológicos aplicados na própria infraestrutura. Para garantir a proteção dos dados adotamos medidas de segurança seguindo as determinações especificadas na LGPD (Lei Geral e Proteção de Dados), em conjunto com o provedor AWS utilizamos recursos contra destruição acidental, acesso não autorizado, backups automatizados, medidas organizacionais alinhadas com a política de segurança, dentre outros aspectos técnicos razoáveis.</p>
            <p>Os dados dos usuários da União Europeia, Reino Unido, Lichtenstein, Noruega ou Islândia, e todos os demais países, são armazenados nos Estados Unidos (EUA) através da {{company.company_name}} e seus provedores de serviços e, se você não residir nos EUA, as leis podem ser diferentes das leis onde você reside. Ao usar os Serviços, você reconhece que qualquer informação pessoal sobre você, independente de serem fornecidas por você ou obtidas por terceiros, estão sendo fornecidas à {{company.company_name}} nos EUA e serão hospedadas em servidores dos EUA, e você autoriza a {{company.company_name}} a transferir, armazenar e processar suas informações a partir e nos Estados Unidos e, possivelmente para outros países, quando houver necessidade e quando informado nessa política. Exceto os usuários do Brasil, que podem ter seus dados armazenados nos Estados Unidos e no Brasil.</p>
        </li>

        <li class="item">
            <p><b>Tempo de processamento e retenção dos dados</b></p>
            <p><b>DADOS QUE A {{company.company_name.toUpperCase()}} É CONTROLADORA</b></p>            
            <p>Os dados coletados diretamente ou por nossos clientes a partir da Plataforma de Serviços que oferecemos, são mantidos somente pelo tempo necessário para fornecimento dos serviços, ou seja, enquanto os serviços estiverem ativos, ou pelo tempo que forem necessários para cumprimento dos nossos acordos e obrigações legais, contábeis e fiscais. Para eliminação ou anonimização de dados, estabelecemos políticas internas que garantam a segurança no processo, para isso podemos manter o dado por até 03 meses após cessar o uso dos serviços, como margem de segurança antes que sejam eliminados ou anonimizados.</p>
            <p>Os dados que são utilizados pelo departamento de marketing poderão ser mantidos enquanto entendermos que nossos serviços podem ser relevantes para você para uma futura contratação, ou até que você se descadastre para deixar de receber nossas notificações e promoções, ou que solicite a eliminação dos dados.</p>

            <p><b>DADOS QUE A {{company.company_name.toUpperCase()}} É OPERADORA</b></p>
            <p>Os dados são mantidos até que o CONTROLADOR solicite a eliminação dos mesmos e até que possamos executar a eliminação com segurança, e confirmemos que todos os dados foram definitivamente eliminados, o que pode demorar até 90 (noventa) dias.</p>
            <p>A {{company.company_name}} exige que todas as pessoas físicas e jurídicas contratantes e usuárias dos nossos serviços ajam em conformidade com todas as leis e todos os regulamentos aplicáveis, além desta política, podendo encerrar a prestação dos serviços de forma unilateral se detectar que os usuários da contratante estão infringindo normas e regulamentos sobre proteção de dados pessoais e os demais especificados de acordo com o nosso termo de uso (<a :href="`https://${company.location}/terms-and-conditions/${lang}`" target="_blank">https://{{company.location}}/terms-and-conditions</a>)</p>
            <br>

            <p><b>DADOS PROCESSADOS PARA MANTER CONFORMIDADE</b></p>
            <p>Os dados que são necessários para atendimentos às obrigações fiscais, tributárias, previdenciárias, trabalhistas e legais do grupo {{company.company_name}}, em qualquer dos países que atuamos, são mantidos pelo tempo determinado pela lei de cada país.</p>
        </li>

        <li class="item">
            <p><b>Do consentimento</b></p>
            <p>Ao concordar com nossa política de privacidade e ao se cadastrar para utilizar nossa plataforma ou qualquer um de nossos Serviços, você assume que concorda com ela e nos dá autorização para processar seus dados de acordo com essa política.</p>
            <p>Você tem o direito de retirar total ou parcialmente seu consentimento para processarmos seus Dados Pessoais, para fazer isso entre em contato com dpo@{{company.location}} e vamos deixar de processar seus dados pessoais para os fins com os quais consentiu originalmente, exceto se existir motivos legais e convincentes para continuarmos processando seus Dados Pessoais, os quais anulem os seus interesses, direitos e liberdades ou para estabelecimento, exercício ou defesa em ações judiciais.</p>
        </li>

        <li class="item">
            <p><b>Corrigir, Atualizar ou Excluir suas informações</b></p>
            <p>Você pode solicitar para corrigir, atualizar ou excluir as informações pessoais que fornecem diretamente à {{company.company_name}}, nossa equipe fará os melhores esforços para atender à sua solicitação dentro do prazo legal aplicável, mas se ocorrer alguma falha na entrega de nossa resposta, nós não somos responsáveis por tal falha.</p>
            <p>Quando sua informação é processada por um de nossos clientes contratantes dos nossos serviços, você deve direcionar suas solicitações diretamente ao seu fornecedor (nosso cliente de serviços), o qual atua como CONTROLADOR, dos seus dados.</p>
        </li>

        <li class="item">
            <p><b>Direitos de privacidade para usuários da Califórnia</b></p>
            <p>Os residentes na Califórnia, tem o direito de nos solicitar um aviso identificando as categorias de informações pessoais que compartilhamos com nossos parceiros ou terceiros para fins de marketing, incluindo informações de contato para cada terceiro. Se você é um residente na Califórnia, utiliza os nossos serviços e deseja solicitar tais informações, envie uma solicitação para dpo@{{company.location}}.</p>
            
        </li>

        <li class="item">
            <p><b>Informações pessoais de crianças</b></p>
            <p>Não coletamos ou solicitamos intencionalmente informações pessoais de menor de 13 anos de acordo com nosso Termo de Assinatura. Se você for menor de 13 anos, não tente se registrar nos serviços ou nos enviar qualquer informação pessoal sobre você. Se tomarmos conhecimento sobre uma conta que tenha sido criada por uma pessoa menor de 13 anos, ou de informações que tenham sido enviadas a nós diretamente nessas condições, excluiremos todas as informações o mais rápido possível. Se você identificar que uma criança menor de 13 anos registrou uma conta em nossos serviços ou forneceu informações pessoais, entre em contato conosco em dpo@{{company.location}} .</p>
            
        </li>

        <li class="item">
            <p><b>Base Legal para o processamento de dados pessoais</b></p>

            <p>Só processamos suas informações pessoais se tivermos uma base legal para fazê-lo.</p>
            <p>Utilizamos as seguintes bases legal:</p>
            <p class="pl-5 mb-1">- Necessidade contratual: os dados são processados para cumprir nosso Termo de Serviço com você, e os dados pessoais são fundamentais para que possamos fornecer os serviços, sem eles ficaríamos incapacitados de fazê-lo. Os dados inclui: dados essências da conta, Cookies primários Essenciais, Dados de Conexão, Informações de Conta de Terceiros (se houver), como por exemplo se o usuário fizer login utilizando o Active Directory.</p>
            <p class="pl-5 mb-1">- Consentimento: em alguns casos processamos dados pessoais baseado no consentimento que você formalmente nos fornece, no momento em que coletamos esses dados. O seu consentimento é solicitado de forma expressa no ato da coleta.</p>
            <p class="pl-5 mb-1">- Legítimo Interesse: As categorias aqui especificadas são coletadas quando acreditamos que favorecem o nosso legítimo interesse ou o interesse de terceiros: Dados de Cobrança, Dados de Formulário Online, Dados Adicionais da Conta, Telefone, Bate-pago e E-mail (quando coletado por meios diferentes da conta de usuário do serviço), Dados de Eventos, de Comunidades, Cookies de rastreamento, Dados de Rastreamento aumentado, Ticket de Suporte, Identificadores de Dispositivos e Web Beacons.</p>
            <p class="pl-5">- Outro motivos: Podemos processar dados legais para cumprir obrigações legais, contábeis, fiscais e trabalhistas, ou para proteger seus interesses vitais ou de outros titulares de dados, ou ainda para atender exigências das instituições governamentais e/ou ao poder judiciário.</p>
            
        </li>

        <li class="item">
            <p><b>Disposições gerais</b></p>
            <p>Se ocorrer atualização nesta Política de Privacidade, faremos uma nova publicação nesta página atualizando as informações.</p>
            <p>Nossa política só abrange o acesso aos nossos sites e aplicações. Links para sites de terceiros, não estão cobertos por nossa política de privacidade.</p>
        </li>

    </ul>

    <br>

    <p><b>Entre em contato:</b></p>
    <p>Qualquer dúvida sobre essa política de privacidade pode ser enviada para dpo@{{company.location}} ou em:</p>
    <p>{{company.address1}} <br> {{company.address2}}</p>

    <br>
    <br>
    


</div>

</template>

<script>
export default {     
    props: ['company','lang'],
    inject: ['scrollto']
}
</script>

<style scoped lang="scss">

</style>