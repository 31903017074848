<template>
    <div class="section section-automate-processes d-flex flex-column justify-content-center align-items-center ">
        <h1 class="mb-4">{{$t('DO YOU WANT TO AUTOMATE YOUR PROCESSES WITH AGILITY?')}}</h1>
        <h2 class="mb-4">{{$t('USE THE READY-MADE AND FREE TEMPLATES WE MAKE AVAILA')}}</h2>
        <div class='d-flex div-buttons'>
            <router-link class="btn btn-success btn-medium-width" :to="`/plans${partner_url}`">{{$t("START_FREE")}}</router-link>
            <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="mx-5 btn btn-blue px-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {    
    props:{
        isDomainPermitted:{
            type: Boolean,
            default: false
        },
        partner_url: {
            type: String,
            default: ''
        },
    },

    computed:{
        ...mapState("MainStoreModule", {            
            domain: state => state.domain
        }),
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },    
    },
    
    data(){
        return{            
        }
    },
    methods:{
        
    }

}
</script>

<style scoped lang="scss">
.section-automate-processes {
    padding: 6vw 10vw;
    background-color: #F3F6FF;
    h2,
    p {
        text-align: center;
    }
}

@media only screen and (max-width: 991px) {
    
    p {    
        margin-bottom: 0 !important;
    }
}

</style>