<template>
  <div style="min-height: 100vh;" class="page-careers">        
    <!-- 

        Main banner

    -->
    <transition name="slide-fade">
        <div class="section section-main-careers base-padding d-flex flex-row justify-content-center align-items-center">

          <div class="row">
            <div class="col-12">
              <h1 class="mb-4 title" v-html="$t('CAREERS_TITLE')"></h1>
              <h2 class="subtitle" v-html="$t('CAREERS_SUBTITLE').replace(/{domain}/g, domain.title)"></h2>
              
            </div>            
          </div>  

          <div class="mockup" v-if="window.width > 991">
              <img src="/assets/images/careers/mockup.png" :alt="$t('CAREERS_TITLE')">              
          </div> 
        </div>
    </transition>

    <!-- 

        Who...

    -->  
    <transition name="slide-fade">             
        <div v-if="show" class="section section-who careers d-flex flex-row justify-content-center align-items-center">
            <div class="row">                

                <div class="item col-lg-6 col-md-6 col-sm-12 d-flex flex-row justify-content-center align-items-center">
                    <div class="text-center">
                        <img src="/assets/images/careers/01.png" :alt="$t('WHO_WE_ARE')">
                        <h2 v-html="$t('WHO_WE_ARE')"></h2>                        
                        <p v-html="$t('WHO_WE_ARE_SUBTITLE')"></p>                        
                    </div>
                </div> 

                <div class="item col-lg-6 col-md-6 col-sm-12 d-flex flex-row justify-content-center align-items-center">
                    <div class="text-center">
                        <img src="/assets/images/careers/02.png" :alt="$t('WHAT_WE_DO')">
                        <h2 v-html="$t('WHAT_WE_DO')"></h2>                        
                        <p v-html="$t('WHAT_WE_DO_SUBTITLE')"></p>                        
                    </div>
                </div>                
            </div>
            
        </div>
    </transition>


    <!-- 

        Simplify...

    -->  
    <transition name="slide-fade">             
        <div v-if="show" class="section section-simplify careers base-padding d-flex flex-row justify-content-center align-items-center">
            <div class="row">
                <div class="col-6" v-if="window.width > 991">
                    <img src="/assets/images/careers/03.png" alt="">
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-row justify-content-center align-items-center">
                    <div>
                        <h2 v-html="$t('WHY_TITLE')"></h2>                        
                        <p v-html="$t('WHY_SUBTITLE')"></p>                        
                    </div>
                </div>                
            </div>
            
        </div>
    </transition>


    <!-- 

        Description...

    -->  
    <transition name="slide-fade">             
        <div v-if="show" class="section-description-career blocks d-flex flex-column justify-content-center align-items-center">

          <div class="row">            
            <div class="col-lg-4 col-md-4 col-sm-12">
              
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="/assets/images/careers/04.png" :alt="$t('FLEXIBLE ENVIRONMEN')">
                <div>
                  <h2 v-html="$t('FLEXIBLE ENVIRONMEN')"></h2>
                  <p v-html="$t('OUR BUSINESS FORMAT')"></p>
                </div>
              </div>

            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="/assets/images/careers/05.png" :alt="$t('OUR TEAM, OUR FAMIL')">
                <div>
                  <h2 v-html="$t('OUR TEAM, OUR FAMIL')"></h2>
                  <p v-html="$t('OUR TEAM COMES TOGE')"></p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="/assets/images/careers/06.png" :alt="$t('NOT JUST A JOB, BUT')">
                <div>
                  <h2 v-html="$t('NOT JUST A JOB, BUT')"></h2>
                  <p v-html="$t('WORKING WITH PEOPLE')"></p>
                </div>
              </div>

            </div>
          </div>                        

        </div>
    </transition>


    <!-- 

        Description...

    -->  
    <transition name="slide-fade">             
        <div v-if="show" class="section-description-career listjobs base-padding d-flex flex-column justify-content-center align-items-center">

          <div class="row">            
            <div class="col-lg-7 col-md-12 col-sm-12 d-flex flex-column justify-content-center align-items-center">
              <ul>
                <li v-for="(item, index) in jobs" :key="index">
                  {{item.title[lang]}}
                  <a class="btn btn-success btn-medium-width" @click="openModalJob(item)">{{$t('APPLY_NOW')}}</a>
                </li>                
              </ul>

              
            </div>

            <div class="col-lg-5 col-md-12 col-sm-12" v-if="window.width > 991">
              <img src="/assets/images/careers/07.png" :alt="$t('CAREERS_TITLE')">
            </div>
          </div>
        </div>

    </transition>



    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients class="mt-5 mb-5"></Clients>
    </transition>   
    

    <b-modal 
      :key="lang" 
      class="modal-careers"
      size="lg" 
      ref="modalCareers" 
      :title="modalData.title" 
      :header-bg-variant="headerBgVariant" 
      :header-text-variant="headerTextVariant"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >

      <b-overlay :show="isLoading" rounded="sm"> 
        
        <form id="formCareers" ref="formCareers" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">                
                <b-form-group :label="$t('FIRST_NAME')" :state="state.first_name" label-for="first_name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('FIRST_NAME'))">
                    <b-form-input :state="state.first_name" id="first_name" v-model="form.first_name" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="$t('LAST_NAME')" :state="state.last_name" label-for="last_name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('LAST_NAME'))">
                    <b-form-input id="last_name" :state="state.last_name" v-model="form.last_name" required></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="'E-mail'" :state="state.email" label-for="email" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}','E-mail')">
                    <b-form-input id="email" :state="state.email" v-model="form.email" type="email" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="$t('PHONE')" :state="state.phone" label-for="phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('PHONE'))">
                    <b-form-input id="phone" :state="state.phone" v-model="form.phone" type="tel" required></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-form-group :label="$t('RESUME')" :state="state.resume" label-for="attachment" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}',$t('RESUME'))">
                    <b-form-file
                      required
                      :state="state.attachment"
                      accept=".pdf, .doc, .docx"                    
                      
                      ref="file-input"
                      id="attachment"
                      @change="uploadFile"
                    ></b-form-file>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col div-recaptcha mt-5">
                <vue-recaptcha
                  ref="recaptcha"
                  @verify="recaptchaOnVerify"
                  @expired="recaptchaOnExpired"
                  :sitekey="domain.googlerecaptcha_key"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha>
              </div>
            </div>
        </form>
      </b-overlay>
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
      >
        <p>{{$t('SENT_SUCESS')}} <br><small>{{$t('CLOSING...').replace("{var1}", dismissCountDown)}}</small></p>

        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>

    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import { BModal } from 'bootstrap-vue'
import { BProgress } from 'bootstrap-vue'
import { BAlert } from 'bootstrap-vue'
import $ from 'jquery'
import RecaptchaMixin from '../../mixins/RecaptchaMixin'

export default {    
    mixins: [RecaptchaMixin],
    components:{                  
        Clients, 
        BModal,
        BProgress,
        BAlert
    },    
    props: {  
        partner_url: {
            type: String,
            default: ''
        },             
        window: {
            type: Object,
            default () {
                return {
                    width: 0,
                    height: 0
                }
            }
        }        
    },
    data(){
        return{   
            job: null, 
            isLoading: false,                    
            show: false,
            headerBgVariant: 'light',
            headerTextVariant: 'dark',
            modalData: {
              title: this.$t('MODAL_CAREER_TITLE')
            },
            dismissSecs: 10,
            dismissCountDown: 0,            
            state: {
              first_name: null,
              last_name: null,
              email: null,
              phone: null,
              attachment: null,
              job: null,
            },
            form: {              
              first_name: '',
              last_name: '',
              email: '',
              phone: '',
              attachment: null,
              job: null,
            },
            jobs: [              
              {
                'id': '1',                
                'title': {
                  'pt': 'Programador PHP',
                  'en': 'PHP Developer',
                  'es': 'Desarrollador PHP'
                },                
              },
              {
                'id': '2',                
                'title': {
                  'pt': 'Programador Java',
                  'en': 'Java Developer',
                  'es': 'Programador Java'
                },                
              },
              {
                'id': '3',                
                'title': {
                  'pt': 'Analista de Negócios',
                  'en': 'Business Analyst',
                  'es': 'Analista de negocios'
                },                
              },
              {
                'id': '4',                
                'title': {
                  'pt': 'Especialista em sucesso do cliente',
                  'en': 'Customer Success Specialist',
                  'es': 'Especialista en Éxito del Cliente'
                },                
              },
              {
                'id': '5',                
                'title': {
                  'pt': 'Engenheiro de Software',
                  'en': 'Software engineer',
                  'es': 'Ingeniero de software'
                },                
              },
              {
                'id': '6',                
                'title': {
                  'pt': 'Especialista em mídia social',
                  'en': 'Social media specialist',
                  'es': 'Especialista en Social Media'
                },                
              },
              {
                'id': '7',                
                'title': {
                  'pt': 'Analista de Suporte',
                  'en': 'Support analyst',
                  'es': 'Analista de soporte'
                },                
              },
            ]
        }
    },
    computed: {
      ...mapState('MainStoreModule', {                          
          api: state => state.api,
          google: state => state.google,
          domain: state => state.domain,
          env: state => state.env
      }),
      lang(){
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },         
    },   
    watch:{
      isLoading(bool){
        $(".modal-dialog button").prop('disabled', bool)
      },
      dismissCountDown(n){
        if(n == 0){
          this.$refs['modalCareers'].hide()
        }
      }
    },
    methods: {
        ...mapActions('MainStoreModule', [             
          'setApiToken',
          'sendJobRequest',
          'upload'             
        ]),   
        openModalJob(job){                                 
            this.job = job
            this.$refs['modalCareers'].show()
            $(".modal-dialog").ready(function(){
              $(".modal-dialog").addClass('modal-careers')
            })
        },
        countDownChanged(dismissCountDown) {
          this.dismissCountDown = dismissCountDown
        },

        uploadFile(value){
          
          const formData = new FormData()

          formData.append('file', $('#attachment')[0].files[0]);

          const url = this.api.baseUrl + "/uploads/site/"
          
          this.upload(formData).then((response) => {            
            this.form.attachment = response.path
          })
        },

        checkFormValidity() {
          
          var t = this
          var v = true

          $("#formCareers").find('input').each(function(){
            const valid = this.checkValidity()

            const id = $(this).attr('id')
            t.state[id] = valid
            
            if(!valid){
              v = valid
            }

          })

          return v
          
        },
        resetModal() {

          this.form = {                            
              first_name: '',
              last_name: '',
              email: '',
              phone: '',
              attachment: null,
              job: null,
          }
          this.state = {            
              first_name: null,
              last_name: null,
              email: null,
              phone: null,
              attachment: null,
              job: null,
          }
        },
        handleOk(e) {          
          e.preventDefault()          
          this.handleSubmit()
          let el = document.querySelector( ':focus' )
          if( el ) el.blur()
        },
        async handleSubmit() {
          // Exit when the form isn't valid
          if (!this.checkFormValidity()) {            
            return
          }

          setTimeout(async () => {
            
            if (!this.recaptcha) {
              this.$root.$emit("showAlert", {
                  bgVariant: "warning",
                  textVariant: "dark",
                  buttons: "ok-only",
                  title: this.$t("WARNING"),
                  message: this.$t("SOLVE_RECAPTCHA"),
              })
              this.$emit('error', false)
              return
            }

            this.recaptchaReset()
            this.$refs.recaptcha.reset() // Direct call reset method        

            this.form.view = "careers"
            this.form.subject = "Nova aplicação de trabalho!"

            this.isLoading = true
            this.form.locale = this.$i18n.locale
            this.form.job = this.job
            await this.sendJobRequest(this.form).then((response) => {

              if(response.sent){

                this.$refs['modalCareers'].hide()

                this.$root.$emit('showAlert', {
                  bgVariant: "success",
                  textVariant: "light",
                  buttons: "ok-only",
                  title: this.$t('SENT_SUCESS'),
                  message: this.$t('THANKS_CAREERS').replace('{domain}', this.domain.title)
                })
              }
            })

            this.isLoading = false

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })

          }, 500)
        }
        
    }, 
    
    beforeCreate(){          
        this.$emit('isPageLoaded', false)  
    },

    async created(){      
      if(!this.api.token){            
        await this.setApiToken()
      }
    },    
    mounted(){     
        
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style lang="scss">

  .modal-careers{
    
    header{
      background-color: transparent !important;
      h5{
        padding-left: 15px;
      }
    }
    
  }

    
  .section-who {
      background-color: #F3F6FF;
      padding: 0 15vw;
      .item {
          margin-top: -9vw !important;
          div {
              margin: 0 30px;
              background-color: #fff;
              border-radius: 30px;
              padding: 60px;
              height: 520px;
              img {
                  height: 100px;
              }
              h2,
              p {
                  width: 100%;
                  text-align: left;
                  margin-top: 20px;
              }
          }
          &:nth-child(1) {
              h2 {
                  color: #04C9FE !important;
              }
          }
          &:nth-child(2) {
              h2 {
                  color: #8282F4 !important;
              }
          }
      }
  } 

  .section-description-career {
      padding: 6vw 10vw;
      background-color: #F7F7F7;
      div {
          div {
              div {
                  img {
                      height: 150px;
                      margin-bottom: 30px;
                  }
                  div {
                      h2 {
                          color: #1443C5;
                          text-align: center;
                          padding: 0 20px;
                          margin-bottom: 30px;
                          font-size: 24px;
                          font-weight: 600;
                      }
                      p {
                          padding: 0 20px;
                          text-align: center;
                      }
                  }
              }
          }
      }
  }

  .section-description-career.listjobs {
      padding: 6vw 10vw;
      background-color: #F3F6FF;
      ul {
          width: 100%;
          list-style: none;
          padding: 0 40px 0 0;
          li {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #ccc;
              width: 100%;
              color: #737479;
              padding: 10px 40px;
              font-size: 23px;
              a {
                  height: 50px;
              }
          }
      }
  }

  .section-description-career.blocks {
      background-color: #F7F7F7;
  }


  .section-main-careers {      
      height: calc(100vh - 80px);
      color: #fff;
      background: url(/assets/images/careers/banner-01.png) no-repeat;
      background-size: cover;
      h1 {
          color: #fff;
          width: 40%;
          text-align: left;          
          font-weight: 600;
      }
      h2 {
          color: #fff;
          width: 40%;
          text-align: left;
          font-weight: 400;
      }
      .mockup {
          position: absolute;
          bottom: 6vw;
          right: 10vw;
          width: 40vw;
          img {
              width: 100%;
          }
      }
  }


  @media only screen and (max-width: 991px) {

    
    .section-description-career,
    .section-simplify,
    .section-who,
    .section-main-careers {
        h1{
            font-size: 25px !important;
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2{
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
            font-size: 20px !important;
            line-height: 23px !important;
        }

        h3{
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
            font-size: 18px !important;
        }
    }

    .section-main-careers{

      h1.title{
        font-size: 25px !important;
        width: 100% !important;
        text-align: center;
        padding: 20px 0 !important;
        margin: 0 !important;
      }

      h2.subtitle{
        margin: 20px 0 0 0 !important;
        padding: 0 !important;
        width: 100% !important;
        text-align: center;
        font-size: 20px !important;
        line-height: 23px !important;
      }
    }

    .section-who{  
      padding: 30px 10px !important;            
      margin: 0 !important;

      .item{
        margin: 10px 20px !important;
        padding: 0 !important;
        
        div{
          margin: 0 !important;
          padding: 20px !important;
          height: auto !important;

          img{
            margin-top: 10px !important;
            height: 60px !important;
          }
          h2{
            width: 100% !important;
            margin-top: 15px !important;
            text-align: center !important;
          }
          p{
            width: 100% !important;
            text-align: center !important;
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .section-simplify{
      padding: 30px 10px !important;            
      margin: 0 !important;

      p{
        margin-bottom: 0 !important;
        text-align: center !important;
      }
    }

    .section-description-career{
      padding: 30px 10px !important;            
      margin: 0 !important;

      img{
        margin: 10px 0 0 0 !important;
        height: 60px !important;
      }
      h2{
        width: 100% !important;
        margin-top: 15px !important;
        text-align: center !important;
      }
      p{
        width: 100% !important;
        text-align: center !important;
        margin-bottom: 0 !important;
      }

      &.listjobs{
        padding: 0 10px !important;            
        margin: 0 !important;
        ul{
          padding: 0 !important;
          li{
            padding: 10px !important;
            font-size: 12px !important;
            line-height: 14px !important;

            .btn{
              width: 100px !important;
              font-size: 12px !important;
              line-height: 14px !important;
              padding: 5px !important;
            }
          }
        }
      }
    }

    #formCareers{

      .div-recaptcha{        
        display: flex;        
        justify-content: center;        
        align-items: center;
        margin: 15px 0 !important;
      }

      .row{
        .col-sm-12, .col{
          padding: 0 !important;
        }
      }
    }
  }


    
</style>