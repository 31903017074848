<template>
  <div style="min-height: 100vh">
    <transition name="slide-fade">
      <div>
        <!-- 

                Main banner

            -->
        <div v-if="show && userMonetaryLoaded"
          class="base-padding section-main-banner d-flex flex-row justify-content-center align-items-center">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 align-self-center">
              <div class="banner-hero-home">
                <h1 class="mb-4 title" v-html="$t('HOME_TITLE')"></h1>
                <h2 class="mt-3 subtitle" v-html="$t('HOME_SUBTITLE').replace(/{domain}/g, domain.title)
                  "></h2>
                <div class="mt-3 d-flex justify-content-left">
                  <router-link class="btn btn-success btn-medium-width" :to="`/start-free${partner_url}`">{{
                    $t("START_FREE") }}
                  </router-link>
                  <a v-if="
                    lang == 'pt' &&
                    [
                      'interfy',
                      'docsystem',
                      'processdoc',
                      'clouddoc',
                      'intellify',
                      'neofy',
                    ].includes(domain.name)
                  " class="ml-2 btn btn-red btn-medium-width" target="_blank"
                    href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA">{{
                      $t("WATCH_THE_VIDEO") }}
                  </a>
                </div>

                <h2 class="subtitle mt-4">
                  {{ $t("EVALUATION_HERO_LINE_1") }}
                  <br />
                  <br />
                  {{ $t("EVALUATION_HERO_LINE_2") }}
                </h2>

                <div class="mt-2">
                  <a
                    href="https://www.capterra.com/reviews/219451/Interfy?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
                    <img border="0" src="/assets/images/reviews_capterra.png" height="40px" />
                  </a>

                  <a class="ml-2" href="https://www.getapp.com/collaboration-software/a/interfy-1/reviews/">
                    <img border="0" src="/assets/images/reviews_getapp.png" height="40px" />
                  </a>

                  <a class="ml-2" href="https://www.softwareadvice.com/bpm/interfy-profile/">
                    <img border="0" src="/assets/images/reviews_sa.png" height="40px" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-9 align-self-center" v-if="window.width > 991">
              <div class="main-banner-slider">
                <div class="wrapper-div-slider">
                  <b-carousel :key="`home_carousel_${lang}`" id="carousel-main-banner" fade indicators>
                    <template v-if="
                      lang == 'pt' &&
                      [
                        'interfy',
                        'docsystem',
                        'processdoc',
                        'clouddoc',
                        'intellify',
                        'neofy',
                      ].includes(domain.name)
                    ">
                      <a target="_blank"
                        href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA"><b-carousel-slide
                          :img-src="`/assets/images/domains/${domain.name
                            }/home/${lang}-01.png?id=${create_UUID()}`"></b-carousel-slide></a>
                      <a target="_blank"
                        href="https://www.youtube.com/watch?v=hUVipSHxxsk&list=PL0fzQLWbvmlzJ1iPpUiDXyJIKDTDPRkLA"><b-carousel-slide
                          :img-src="`/assets/images/domains/${domain.name
                            }/home/${lang}-02.png?id=${create_UUID()}`"></b-carousel-slide></a>
                    </template>

                    <template v-else>
                      <b-carousel-slide :img-src="`/assets/images/domains/${domain.name
                        }/home/${lang}-01.png?id=${create_UUID()}`"></b-carousel-slide>
                      <b-carousel-slide :img-src="`/assets/images/domains/${domain.name
                        }/home/${lang}-02.png?id=${create_UUID()}`"></b-carousel-slide>
                    </template>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 
                Clients
            -->
        <Clients />

        <div class="section row base-padding service-badges"
          style="padding-top: 3vw !important; padding-bottom: 3vw !important">
          <div class="col">
            <div class="d-flex justify-content-center">
              <img border="0" src="/assets/images/badges/SA-badge.png" height="180px" />
              <img border="0" src="/assets/images/badges/getapp-badge.png" height="180px" />
              <img border="0" src="/assets/images/badges/capterra-badge.png" height="180px" />
            </div>
          </div>
        </div>

        <!-- 
                All what...
            -->
        <div v-if="show"
          class="section section-all-what base-padding d-flex flex-column justify-content-center align-items-center">
          <h1 v-html="$t('EVERYTHING YOUR BUSINESS NEEDS IN ONE PLACE')"></h1>
          <h2 v-html="$t(
            'INTERFY IS A CORPORATE DIGITAL PLATFORM FOCUSED ON CONTENT MANAGEMENT AND PROCESS AUTOMATION'
          ).replace(/{domain}/g, domain.title)
            "></h2>

          <div :key="`modules_${modules.length}`" v-if="show && modules.length > 0" class="modules">
            <Carousel :margin="0" :autoplay="true" :dots="false" :nav="false" :responsive="responsiveOpts">
              <div class="item" v-for="(item, index) in modules" :key="index">
                <router-link :class="`inner ${item.module}`" :to="`/${item.module}${partner_url}`">
                  <template v-if="index % 2 == 0">
                    <span><i :class="item.class" :alt="$t(item.title)"></i><label>{{ item.label }}</label></span>
                    <div class="title">
                      <p v-html="$t(item.title)"></p>
                    </div>
                  </template>

                  <template v-else>
                    <div class="title">
                      <p v-html="$t(item.title)"></p>
                    </div>
                    <span><i :class="item.class" :alt="$t(item.title)"></i><label>{{ item.label }}</label></span>
                  </template>
                </router-link>
              </div>
            </Carousel>
          </div>
        </div>

        <!-- 
                MODULES...
            -->
        <ModulesCarousel :partner_url="partner_url" v-if="show" :modules="['ecm', 'bpm', 'swc', 'dss']" />

        <!-- 
                Inovate...
            -->
        <div v-if="show"
          class="section section-inovate base-padding d-flex flex-column justify-content-center align-items-center">
          <h1>{{ $t("INOVATE THE WAY YOU WORK") }}</h1>
          <h2>
            {{
              $t("NOW YOU ARE ONE CLICK FROM THE INTERFY PLATFORM").replace(
                /{domain}/g,
                domain.title
              )
            }}
          </h2>

          <div class="d-flex flex-row justify-content-center align-items-center div-buttons-hire">
            <router-link class="btn mx-3 btn-success btn-medium-width" :to="`/start-free${partner_url}`">{{
              $t("START_FREE") }}
            </router-link>
            <router-link v-if="
              isDomainPermitted &&
              (domain.type == 'interfy' || domain.type == 'distribuitor')
            " class="mx-3 btn btn-blue px-3" :to="`/become-a-partner${partner_url}`">{{ $t("BECOME_A_PARTNER")
              }}</router-link>
          </div>
        </div>

        <div class="double-background">
          <!-- 
                    CHECK IT OUT...
                -->

          <div v-if="show"
            class="section section-check-it-out home d-flex flex-column justify-content-center align-items-center">
            <div>
              <h1 class="text-center" v-html="$t('CHECK IT OUT...').replace(/{domain}/g, domain.title)
                " />
            </div>

            <div style="width: 100%" class="mt-3">
              <Carousel :margin="0" :autoplay="true" :dots="false" :nav="false" :responsive="{
                0: {
                  items: 1,
                },
                991: {
                  items: 3,
                },
                1400: {
                  items: 4,
                },
              }">
                <div class="item">
                  <div class="card-col">
                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                      <div>
                        <img src="/assets/images/home/40.png" :alt="`${domain.title}`" />
                        <span></span>
                      </div>
                      <p v-html="$t('ARTIFICIAL')"></p>
                      <p class="description" v-html="$t('ARTIFICIAL_SUBTITLE')"></p>
                      <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card-col">
                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                      <div>
                        <img src="/assets/images/home/41.png" :alt="`${domain.title}`" />
                        <span></span>
                      </div>
                      <p v-html="$t('DATA ISOLA')"></p>
                      <p class="description" v-html="$t('DATA ISOLA_SUBTITLE')"></p>
                      <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card-col">
                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                      <div>
                        <img src="/assets/images/home/42.png" :alt="`${domain.title}`" />
                        <span></span>
                      </div>
                      <p v-html="$t('WHITE LABE')"></p>
                      <p class="description">
                        <img class="imgOpenModal" style="cursor: zoom-in" @click="openModalGallery()"
                          src="/assets/images/home/slider/white-label/coca-smaller-01.png" :alt="$t('WHITE LABE')" />
                      </p>
                      <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card-col">
                    <div class="div-card d-flex flex-column justify-content-between align-items-center">
                      <div>
                        <img src="/assets/images/home/43.png" :alt="`${domain.title}`" />
                        <span></span>
                      </div>
                      <p v-html="$t('EASY INTEG')"></p>
                      <p class="description" v-html="$t('EASY INTEG_SUBTITLE').replace(
                        /{domain}/g,
                        domain.title
                      )
                        "></p>
                      <router-link class="nav-link" :to="`/${partner_url}`">{{ $t("LEARN_MORE") }} ></router-link>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>

        <!-- 
                Flexible workflow...
            -->

        <div v-if="show"
          class="section section-flexible-workflow font-bpm base-padding d-flex flex-row justify-content-center align-items-center">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-row justify-content-center align-items-center">
              <div>
                <h1 v-html="$t('USE READY-MADE...')"></h1>
                <p v-html="$t('MANUAL PROCESS')"></p>
              </div>
            </div>
            <div
              class="div-processes col-lg-6 col-md-6 col-sm-12 d-flex flex-wrap justify-content-center align-items-center">
              <router-link class="nav-link" :to="`/process/customerService${partner_url}`">
                <span></span>
                <p v-html="$t('CUSTOMER SERVI')"></p>
              </router-link>
              <router-link class="nav-link" :to="`/process/purchaseOrder${partner_url}`">
                <span></span>
                <p v-html="$t('PURCHASE ORDER')"></p>
              </router-link>
              <router-link class="nav-link" :to="`/process/contractManagement${partner_url}`">
                <span></span>
                <p v-html="$t('CONTRACT MANAG')"></p>
              </router-link>
              <router-link class="nav-link" :to="`/process/paymentAuthorization${partner_url}`">
                <span></span>
                <p v-html="$t('PAYMENT AUTHOR')"></p>
              </router-link>
              <router-link class="nav-link" :to="`/process/expensesRefund${partner_url}`">
                <span></span>
                <p v-html="$t('EXPENSES REFUN')"></p>
              </router-link>
              <router-link class="nav-link" :to="`/process/recruitmentAndSelection${partner_url}`">
                <span></span>
                <p v-html="$t('RECRUITMENT AN')"></p>
              </router-link>
            </div>
          </div>
        </div>

        <!-- 
                Testimonials
            -->
        <Testimonials class="home"></Testimonials>

        <!-- 
                The platform...
            -->
        <ThePlatform :window="window" v-if="show"></ThePlatform>

        <b-modal size="xl" ref="modalGallery" :title="modalData.title" :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant" hide-header hide-footer>
          <div>
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls indicators background="#000"
              img-width="981" img-height="542" style="text-shadow: 1px 1px 2px #333">
              <b-carousel-slide caption="" text=""
                img-src="/assets/images/home/slider/white-label/coca-bigger-01.png"></b-carousel-slide>
              <b-carousel-slide caption="" text=""
                img-src="/assets/images/home/slider/white-label/coca-bigger-02.png"></b-carousel-slide>
              <b-carousel-slide caption="" text=""
                img-src="/assets/images/home/slider/white-label/toyota-bigger-01.png"></b-carousel-slide>
              <b-carousel-slide caption="" text=""
                img-src="/assets/images/home/slider/white-label/toyota-bigger-02.png"></b-carousel-slide>
            </b-carousel>
          </div>
        </b-modal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { BCarousel } from "bootstrap-vue";
import Clients from "../common/Clients";
import Testimonials from "../common/Testimonials";
import ThePlatform from "../common/ThePlatform";
import { BModal } from "bootstrap-vue";
import ModulesCarousel from "../common/ModulesCarousel";
import $ from "jquery";

import Carousel from "vue-owl-carousel";

export default {
  components: {
    Carousel,
    BCarousel,
    Clients,
    Testimonials,
    ThePlatform,
    BModal,
    ModulesCarousel,
  },
  props: {
    isDomainPermitted: {
      type: Boolean,
      default: false,
    },
    partner_url: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },
  data() {
    return {
      userMonetaryLoaded: false,
      show: false,
      modalData: {
        title: "",
      },
      slide: [],
      headerBgVariant: "light",
      headerTextVariant: "dark",
      responsiveOpts: {},
      modules: [],
    };
  },
  computed: {
    ...mapState("MainStoreModule", {
      domain: (state) => state.domain,
      user_monetary: (state) => state.user_monetary,
      env: (state) => state.env,
    }),

    lang() {
      return this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale;
    },
  },

  watch: {
    user_monetary: {
      deep: true,
      handler(user_monetary) {
        console.log(">> watcher user_monetary", user_monetary);
        if (user_monetary) {
          this.userMonetaryLoaded = true;
          this.setModules();
        } else {
          this.userMonetaryLoaded = false;
        }
      },
    },
  },
  methods: {
    openModalGallery() {
      this.$refs["modalGallery"].show();
    },

    setModules() {
      var modules = [
        {
          module: "ecm",
          label: "ECM",
          icon: "icon-ecm",
          title: this.$t("ECM_NO_TAGS"),
          class: `icon-ecm logo-ecm`,
        },
        {
          module: "bpm",
          label: "BPM",
          icon: "icon-bpm",
          title: this.$t("BPM_NO_TAGS"),
          class: `icon-bpm logo-bpm`
        },
        {
          module: "swc",
          label: "SWC",
          icon: "icon-swc",
          title: this.$t("SWC_NO_TAGS"),
          class: `icon-swc logo-swc`
        },
        {
          module: "dss",
          label: "DSS",
          icon: "icon-dss",
          title: this.$t("DSS_NO_TAGS"),
          class: `icon-dss logo-dss`
        },
        {
          module: "slv",
          label: "LVS",
          icon: "icon-slv",
          title: this.$t("SLV_NO_TAGS"),
          class: `icon-slv logo-slv`
        },
        {
          module: "cic",
          label: "CIC",
          icon: "icon-cic",
          title: this.$t("CIC_NO_TAGS"),
          class: `far fa-comments`
        },
        {
          module: "das",
          label: "DAS",
          icon: "icon-das",
          title: this.$t("DAS_NO_TAGS"),
          class: `icon-das logo-das`
        },
      ],
        newModules = [];

      for (var i in modules) {
        if (this.$i18n.locale != 'pt_br') {
          if (!["das"].includes(modules[i].module)) {
            newModules.push(modules[i]);
          }
        } else {
          if (!["cic"].includes(modules[i].module)) {
            newModules.push(modules[i]);
          }
        }
      }

      console.log(">> Accepted modules", newModules);

      (this.responsiveOpts = {
        0: {
          items: 1,
        },
        991: {
          items: 4,
        },
        1400: {
          items: newModules.length,
        },
      }),
        (this.modules = newModules);
    },

    create_UUID() {
      var dt = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
  },

  beforeCreate() {
    this.$emit("isPageLoaded", false);
  },

  mounted() {
    if (this.user_monetary) {
      this.userMonetaryLoaded = true;
      this.setModules();
    } else {
      this.userMonetaryLoaded = false;
    }

    $(".link-youtube").click(() => {
      console.log("YT");
    });

    setTimeout(() => {
      this.$emit("isPageLoaded", true);
      this.show = true;
    }, 1000);
  },
};
</script>

<style lang="scss">
$ecm: #4fa608;
$bpm: #0040a6;
$swc: #d10c94;
$dss: #ff3d00;
$slv: #00a6dd;
$das: #dec902;
$cic: #ffa100;

.section-check-it-out {
  &.home {
    padding: 0 10vw !important;
    padding-top: 6vw !important;
    margin-bottom: -4vw !important;
  }

  h1 {
    width: 100%;
    color: $bpm;
  }

  a {
    font-size: 16px !important;
    transition: all 0.4s ease;
  }

  p {
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 2vw;
  }

  .imgOpenModal {
    margin-top: 20px;
    width: 15vw;
  }

  .item {
    padding: 30px 25px;

    .div-card {
      width: 100%;
      border-radius: 20px;
      padding: 40px 20px;
      height: 400px;
      transition: all 0.4s ease;
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
      cursor: pointer;
      text-align: center;
      background-color: #fff;

      &:hover {

        p,
        a {
          opacity: 0;
        }

        p.description {
          opacity: 1;
        }

        div {
          span {
            width: 70px;
          }
        }
      }

      div {
        margin-bottom: -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          display: block;
          margin-top: 10px;
          width: 0;
          border-radius: 20px;
          transition: all 0.2s ease;
        }

        img {
          width: auto;
          height: 50px;
        }
      }

      p {
        margin: 0 !important;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;

        b {
          font-weight: 800;
        }

        &.description {
          font-size: 16px !important;
          line-height: 20px !important;
          font-weight: 400 !important;
          opacity: 0;
          position: absolute;
          padding: 20px 40px;
          top: 130px;
        }
      }

      a {
        text-decoration: none;
        border: 1px solid transparent;
        padding: 0;
        transition: all 0.2s ease;
      }
    }
  }
}

.section-check-it-out .owl-item {
  &:nth-child(1) {
    .card-col {
      .div-card {
        &:hover {
          box-shadow: 0 0 2.5rem rgba(0, 199, 175, 0.5);
        }

        color: #00c7af;

        a:hover {
          color: #00c7af;
          border: 1px solid #00c7af;
        }

        div span {
          border-bottom: 3.5px solid #00c7af;
        }
      }
    }
  }

  &:nth-child(2) {
    .card-col {
      .div-card {
        &:hover {
          box-shadow: 0 0 2.5rem rgba(255, 122, 122, 0.5);
        }

        i {
          color: #ff7a7a;
        }

        a:hover {
          color: #ff7a7a;
          border: 1px solid #ff7a7a;
        }

        div span {
          border-bottom: 3.5px solid #ff7a7a;
        }
      }
    }
  }

  &:nth-child(3) {
    .card-col {
      .div-card {
        &:hover {
          box-shadow: 0 0 2.5rem rgba(128, 128, 241, 0.5);
        }

        i {
          color: #8080f1;
        }

        a:hover {
          color: #8080f1;
          border: 1px solid #8080f1;
        }

        div span {
          border-bottom: 3.5px solid #8080f1;
        }
      }
    }
  }

  &:nth-child(4) {
    .card-col {
      .div-card {
        &:hover {
          box-shadow: 0 0 2.5rem rgba(255, 176, 121, 0.5);
        }

        i {
          color: #ffb079;
        }

        a:hover {
          color: #ffb079;
          border: 1px solid #ffb079;
        }

        div span {
          border-bottom: 3.5px solid #ffb079;
        }
      }
    }
  }
}

.section-all-what {
  padding-top: 6vw !important;
  padding-bottom: 6vw !important;
  color: #000100;
  background-color: #f3f6ff;

  h1 {
    text-align: center;
    margin-bottom: 20px !important;
  }

  h2 {
    padding: 0 15vw;
    text-align: center;
    margin-bottom: 50px !important;
  }

  .modules {
    width: 100%;

    .item {
      padding: 10px 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .inner {
        text-decoration: none;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 250px;
        width: 250px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

        &:hover {
          color: #fff;
          box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25),
            0 2px 2px rgba(0, 0, 0, 0.22);
        }

        &.ecm {
          border: 3px solid $ecm;
          background-color: $ecm;

          div.title {
            border-radius: 0 0 16px 16px;
          }

          &:hover {
            span {
              z-index: 10;
              transform: translateY(25%);
            }

            div.title {
              z-index: 9;
              transform: translateY(-15%);
              background-color: $ecm;

              p {
                color: #fff;
              }
            }
          }
        }

        &.bpm {
          border: 3px solid $bpm;
          background-color: $bpm;

          div.title {
            border-radius: 16px 16px 0 0;
          }

          &:hover {
            span {
              transform: translateY(-75%);
            }

            div.title {
              transform: translateY(80%);
              background-color: $bpm;

              p {
                color: #fff;
              }
            }
          }
        }

        &.slv {
          border: 3px solid $slv;
          background-color: $slv;

          div.title {
            border-radius: 0 0 16px 16px;
          }

          &:hover {
            span {
              z-index: 10;
              transform: translateY(25%);
            }

            div.title {
              z-index: 9;
              transform: translateY(-15%);
              background-color: $slv;

              p {
                color: #fff;
              }
            }
          }
        }

        &.swc {
          border: 3px solid $swc;
          background-color: $swc;

          div.title {
            border-radius: 0 0 16px 16px;
          }

          &:hover {
            span {
              z-index: 10;
              transform: translateY(25%);
            }

            div.title {
              z-index: 9;
              transform: translateY(-15%);
              background-color: $swc;

              p {
                color: #fff;
              }
            }
          }
        }

        &.dss {
          border: 3px solid $dss;
          background-color: $dss;

          div.title {
            border-radius: 16px 16px 0 0;
          }

          &:hover {
            span {
              transform: translateY(-75%);
            }

            div.title {
              transform: translateY(80%);
              background-color: $dss;

              p {
                color: #fff;
              }
            }
          }
        }

        &.das {
          border: 3px solid $das;
          background-color: $das;

          div.title {
            border-radius: 16px 16px 0 0;
          }

          &:hover {
            span {
              transform: translateY(-75%);
            }

            div.title {
              transform: translateY(80%);
              background-color: $das;

              p {
                color: #fff;
              }
            }
          }
        }

        &.cic {
          border: 3px solid $cic;
          background-color: $cic;

          div.title {
            border-radius: 16px 16px 0 0;
          }

          &:hover {
            span {
              transform: translateY(-75%);
            }

            div.title {
              transform: translateY(80%);
              background-color: $das;

              p {
                color: #fff;
              }
            }
          }
        }

        span {
          height: 125px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transform: translateY(0);
          transition: all 300ms;

          i {
            color: #fff;
            font-size: 35px;
            margin-bottom: 5px;
          }

          label {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            padding: 0;
            margin: 0;
          }
        }

        div.title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 125px;
          background-color: #fff;
          transform: translateY(0);
          transition: all 300ms;

          p {
            text-align: center;
            padding: 10px;
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.section-main-banner {
  height: calc(100vh - 80px);
  background: url(/assets/images/home/background-main.png) no-repeat;
  background-size: cover;

  h1,
  h2 {
    color: #fff !important;
  }

  h3 {
    margin: 1.3vw 0 !important;
  }

  .banner-hero-home {
    position: absolute;
    width: 35vw;
    top: -12vw;
  }

  .main-banner-slider {
    width: 55vw !important;

    .wrapper-div-slider {
      position: relative;
      right: -18vw;
    }

    .carousel-indicators {
      bottom: -3vw !important;

      li {
        background-color: #fff;
        opacity: 1 !important;
        width: 50px !important;
        height: 6px !important;
        border-radius: 11px;
        margin: 0 10px;

        &.active {
          background-color: #1640c9;
          opacity: 1 !important;
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 3s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .slide-fade-enter-active {
      transition: all 3s ease;
    }

    .slide-fade-leave-active {
      transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateX(10px);
      opacity: 0;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1460px) {
  .banner-hero-home {
    .btn {
      margin: 20px 0 0 0 !important;
    }
  }

  .section-flexible-workflow,
  .section-check-it-out,
  .section-inovate,
  .section-main-banner,
  .section-all-what {
    h1 {
      font-size: 25px !important;
      line-height: 28px !important;
    }

    h2 {
      font-size: 16px !important;
      line-height: 18px !important;
    }
  }

  .section-modules .div-carousel .product-slide div.description {
    margin-bottom: 50px !important;
  }

  .section-check-it-out {
    .item {
      .div-card {

        p,
        p.description {
          font-size: 16px !important;
          line-height: 18px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .section-main-banner {
    min-height: 200px;
    height: auto !important;

    .banner-hero-home {
      width: 100% !important;
      position: relative !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      .btn {
        margin: 20px 0 0 0 !important;
      }
    }
  }

  .section-inovate {
    .div-buttons-hire {
      flex-direction: column !important;
      margin-top: 30px;

      a {
        width: 190px !important;
        margin: 10px !important;
      }
    }
  }

  .section-check-it-out {
    padding: 0 0 30px 0 !important;
    margin: 30px 0 0 0 !important;

    h1 {
      padding: 0 10px !important;
    }

    .item {
      .div-card {
        height: 330px !important;

        p,
        p.description {
          font-size: 14px !important;
          line-height: 16px !important;
        }
      }
    }
  }

  .section-flexible-workflow,
  .section-check-it-out,
  .section-inovate,
  .section-main-banner,
  .section-all-what {
    h1 {
      font-size: 20px !important;
      line-height: 22px !important;
      width: 100% !important;
      text-align: center;
      padding: 20px 0 !important;
      margin: 0 !important;
    }

    h2 {
      margin: 20px 0 20px 0 !important;
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
      font-size: 16px !important;
      line-height: 18px !important;
    }

    h3 {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
      font-size: 14px !important;
    }

    .modules {
      margin: 30px 0;
    }
  }
}

.service-badges {
  background-color: lightblue;
}

.service-badges img {
  padding: 1vw 5vw;
}
</style>