<template>
    <div @mouseover="isVisible = true" class="section section-platform base-padding d-flex flex-column justify-content-center align-items-center">
        <div class="head">
            <h1 class="text-center font-bpm" v-html="$t('PLATFORM_TITLE').replace(/{domain}/g, domain.title)"></h1>
            <p class="text-center" v-html="$t('PLATFORM_SUBTITLE').replace(/{domain}/g, domain.title)"></p>
        </div>

        <div class="counter-wrapper d-flex flex-wrap justify-content-between align-items-center body">

            <div class="div-card d-flex flex-column justify-content-center align-items-center">                                
                <h2 class="text-center"><span id="users">0</span>+</h2>
                <p class="text-center">{{$t('USERS')}}</p>
            </div>
            <div class="div-card d-flex flex-column justify-content-center align-items-center">                                
                <h2 class="text-center"><span id="customers">0</span>+</h2>
                <p class="text-center">{{$t('CUSTOMERS')}}</p>
            </div>
            <div class="div-card d-flex flex-column justify-content-center align-items-center">                                
                <h2 class="text-center"><span id="countries">0</span>+</h2>
                <p class="text-center">{{$t('COUNTRIES')}}</p>
            </div>            
            <div class="div-card d-flex flex-column justify-content-center align-items-center">                                
                <h2 class="text-center"><span id="partners">0</span>+</h2>
                <p class="text-center">{{$t('PARTNERS')}}</p>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'

export default {
    props: ['window'],

    computed: {
      ...mapState("MainStoreModule", {            
          domain: state => state.domain
      }),
      
      lang(){
        return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
      },
    }, 

    data(){
        return{
            isVisible: false
        }
    },

    watch:{
        isVisible(bool){
            if(bool){

                this.counter("users", 0, 2000000, 8000, 1)
                this.counter("customers", 0, 20000, 100, 1)
                this.counter("countries", 0, 50, 1, 1)
                this.counter("partners", 0, 200, 1, 1)

            }
        }
    },
 
    methods: {

        counter(id, start, end, step, duration) {
            let obj = document.getElementById(id),
                current = start,
                range = end - start,
                increment = end > start ? step : -step,
                interval = Math.abs(Math.floor(duration / range)),

                timer = setInterval(() => {
                    current += increment
                    obj.textContent = current
                    if (current == end) {
                        clearInterval(timer)
                    }
                }, interval)
        }
    },
    mounted(){
        if(this.window.width <= 1200){
            this.isVisible = true
        }
    },

}
</script>

<style lang="scss" scoped>
    .div-card{
        width: 25% !important;        
    }

        
    .section-platform {
        padding-top: 6vw !important;
        padding-bottom: 6vw !important;
        .head {
            text-align: center;
            p {
                padding: 0 14vw;
                margin-bottom: 40px;
                margin-top: 20px;
                line-height: 25px;
            }
        }
        .body {
            width: 100%;
            h2 {
                color: #4D4D4D;
                margin: 0;
            }
        }
    }


    @media only screen and (max-width: 991px) {
        .section-platform{
            padding: 30px 10px !important;

            h2{
                margin: 20px 0 20px 0 !important;
                padding: 0 !important;
                width: 100% !important; 
                text-align: center;
                font-size: 20px !important;
            }

            p{
                padding: 0 !important;
                font-size: 16px !important;
                line-height: 18px !important;
                margin: 0 0 20px 0 !important;
            }

            .counter-wrapper{
                flex-direction: column !important;
                justify-content: center !important;
                align-items: center !important;
            }
        }
    }
    
</style>