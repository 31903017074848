<template>
    <div class="section section-testimonials base-padding d-flex flex-column justify-content-center align-items-center">
        <div>
            <h1 class="mb-5 text-center font-bpm">{{$t('TESTIMONIES_TITLE').replace(/{domain}/g, domain.title)}}</h1>                    
        </div>

        <Carousel                  
            class="carousel-testimonials mt-5"
            :margin="0" 
            :autoplay="true"                     
            :dots="false"
            :nav="false"
            :responsive="{
                0:{
                    items: 1
                },
                991:{
                    items: 2
                },
                1400:{
                    items: 3
                },
                1500:{
                    items: 3
                }
            }"
        >

            <div class="testimonial-slide" v-for="(item, index) in data_testimonials" :key="index">                
                <div class="inner d-flex flex-row justify-content-center align-items-center">
                    
                    <div class="d-flex flex-column justify-content-start align-items-center div-item">                                    
                        <i class="align-self-start icon-aspas"></i>

                        <div class="d-flex flex-row justify-content-between align-items-end div-person">
                            <img :src="'/assets/images/home/testimonials/' + item.avatar">
                            <div class="text-right">
                                <p>{{item.name}}</p>
                                <span>{{item.role}}</span>
                            </div>                                        
                        </div>

                        <p class="description" v-html="item.text[lang].replace(/{domain}/g, domain.title)"></p>
                        
                        <span class="solution">{{`${$t('SOLUTION_USED')}: ${item.solution}`}}</span>    

                    </div>
                </div>
            </div>


        </Carousel>
        
        <div class="empty-background"></div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Carousel from 'vue-owl-carousel'

export default {
    components:{
        Carousel,        
    },
    computed:{
        ...mapState("MainStoreModule", {            
            domain: state => state.domain
        }),
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },    
    },
    data(){
        return{            
            window: {
                width: 0,
                height: 0
            },
            /*
                Testimonials
            */
            data_testimonials: [
                {
                    'id': 1,    
                    'text': {
                        pt: 'Implementamos a Plataforma {domain} a alguns meses e passamos a trabalhar com processos 100% digitais, e até o que tinha em papel, a gente digitalizou tudo e descartou o físico, e os novos já mantemos no formato digital, pois a plataforma já tem assinador integrado; isso ajudou minha empresa a de fato se transformar para uma cultura digital.',
                        en: 'We implemented the {domain} Platform a few months ago and started working with 100% digital processes, and even what we had on paper, we digitized everything and discarded the physical, and the new ones we already keep in digital format, because the platform already hasintegrated subscriber;it helped my company to actually transform into a digital culture.',
                        es: 'Implementamos la Plataforma {dominio} hace unos meses y comenzamos a trabajar con procesos 100% digitales, e incluso lo que teníamos en papel, digitalizamos todo y descartamos lo físico, y las nuevas ya las guardamos en formato digital, porque la plataforma ya tiene abonado integrado;ayudó a mi empresa a transformarse realmente en una cultura digital.',
                    },
                    'name': 'Moisaniel Cantanhede',
                    'role': 'Organize',
                    'avatar': 'moisaniel.png',
                    'solution': "ECM + BPM"
                },
                {
                    'id': 2,
                    'text': {
                        pt: 'A plataforma {domain} unifica as ferramentas necessárias para o controle dos documentos e dos processos de uma empresa (ECM e BPM), além disso é escalável e tem uma política comercial muito atraente. É um serviço seguro, com isolamento de dados e alta escalabilidade. É fundamental para o meu negócio!',
                        en: 'The {domain} platform unifies the necessary tools to control a company\'s documents and processes (ECM and BPM), in addition to being scalable and having a very attractive commercial policy. It is a secure service, with data isolation and high scalability. It is fundamental to my business!',
                        es: 'La plataforma {domain} unifica las herramientas necesarias para controlar los documentos y procesos de una empresa (ECM y BPM), además de ser escalable y tener una política comercial muy atractiva. Es un servicio seguro, con aislamiento de datos y alta escalabilidad. ¡Es fundamental para mi negocio!',
                    },
                    'name': 'Rodrigo Pereira ',
                    'role': 'Triagem Consultoria',
                    'avatar': 'rodrigo.png',
                    'solution': "ECM + BPM"
                },
                {
                    'id': 3,
                    'text': {
                        pt: 'A Plataforma {domain} está permitindo que meus clientes inovem sua maneira de trabalhar optando pelo escritório sem papel. Com a opção de digitalização com validade legal, eles podem digitalizar os documentos do legado e descartar o papel, e com a assinatura digital, podem criar novos documentos e tramitar em um fluxo 100% digital',
                        en: 'The {domain} Platform is allowing my clients to inovate their way of working by opting for the paperless office. With the option of scanning with legal validity, they can scan the legacy documents and discard the paper, and with the digital signature, they can create new documents and process in a 100% digital flow',
                        es: 'La Plataforma {domain} está permitiendo a mis clientes innovar su forma de trabajar optando por la oficina sin papeles. Con la opción de escanear con validez legal, pueden escanear los documentos heredados y descartar el papel, y con la firma digital, pueden crear nuevos documentos y procesarlos en un flujo 100% digital.',
                    },
                    'name': 'Carlos Maia',
                    'role': 'Tecnews',
                    'avatar': 'carlos.png',
                    'solution': "ECM + BPM"
                },
            ],
        }
    },
    methods: {        
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped lang="scss">


.section-testimonials {
    h2 {
        margin-bottom: 2vw;
    }
    padding-bottom: 80px !important;    
    
    .carousel-testimonials {
           
        width: 100%;       
        
        .testimonial-slide{

            padding-bottom: 20px;            
        
            .inner{
                position: relative;
                margin: 0 50px;
                height: 100%;

                .div-item {
                    position: relative;                    
                    width: 100%;
                    height: 450px;
                    background-color: #fff;
                    border-radius: 20px;
                    padding: 40px;
                    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);

                    .description{                                                
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        margin: 20px 0;
                        color: #4D4D4D;                           
                        height: 250px;
                        padding: 0 5px;
                        overflow: auto;
                    }
                    .solution {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        position: relative;  
                        margin-bottom: -55px;                      
                        text-align: center !important;
                        color: #fff;
                        background-color: #173fca;
                        padding: 10px;
                    }

                    .div-person {
                        z-index: 4;
                        width: 100%;
                        padding-bottom: 20px;
                        border-bottom: 2px solid #e9e9e9;

                        img {
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                        p {
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 18px;
                            margin: 0;
                            color: #4D4D4D;
                        }
                        span{
                            font-size: 16px;
                            line-height: 16px;
                        }
                        
                    }
                }

                .icon-aspas {
                    position: absolute;
                    color: #4D4D4D;
                    z-index: 5;
                    margin-left: -10px;
                    font-size: 20px;
                    line-height: 20px;
                } 
            
            }
        }     
    }
    .empty-background {
        background-color: #F5F5FD;
        width: 100%;
        height: 30vh;
        position: absolute;
        margin-bottom: -430px;
        z-index: -1;        
    }
}

@media only screen and (max-width: 991px) {
    
.section-testimonials {
    h1 {
        margin: 30px 0 !important;
    }
    padding: 0 0 80px 0 !important;    
    
    .carousel-testimonials {
        
        .testimonial-slide{
            
            .inner{
                
                margin: 30px 10px !important;                
                
                .div-item {

                    height: 400px !important;
                    
                    .description{                                                
                        font-size: 14px !important;
                        line-height: 20px !important;                        
                        margin: 20px 0;
                        height: 250px;
                    }
                    .solution {
                        font-size: 14px !important;
                        line-height: 14px !important;
                    }

                    .div-person {                        
                        img {
                            width: 70px !important;
                            height: 70px !important;
                        }
                        p {
                            font-size: 16px !important;
                            line-height: 16px !important;
                        }
                        span{
                            font-size: 14px !important;
                            line-height: 14px !important;
                        }
                    }
                }

            
            }
        }     
    }
    .empty-background {        
        height: 210px !important;        
        margin-bottom: -430px !important;               
    }
}

}

</style>