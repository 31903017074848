<template>
  <div style="min-height: 100vh;">   
    <!-- 

        Main banner

    -->

    <transition name="slide-fade">
        <div class="section section-main-module">
            <div class="main-background"></div>
            
            <h1 class="mb-4 title" v-html="$t('ECM').replace(/<br>/g, '')"></h1>
            <h2 class="subtitle" v-html="$t('ECM_SUBTITLE').replace(/<br>/g, '')"></h2>   

             <div class="div-buttons base-padding d-flex flex-row justify-content-center align-items-center">
                <router-link class="btn btn-1 mx-3" :to="`/start-free${partner_url}`">{{$t("TRY IT FREE")}}</router-link>  
                <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="btn btn-2 mx-3" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>            </div>

          <div class="mockup">
              <img class="base" :src="`/assets/images/domains/${domain.name}/ecm/mockup-${lang}-01.png`" :alt="$t('ECM') + ' - ' + $t('ECM_SUBTITLE')">
          </div> 

        </div>
    </transition>

    <!-- 

        Clients

    -->  
    <transition name="slide-fade">
        <Clients></Clients>
    </transition>   
          

    <transition name="slide-fade">
        <div class="section section-ecm-features base-padding-md">
            <h1 class="mb-4 text-center font-ecm" v-html="$t('HAVE AN ECM LIKE YOUVE NEVER SEEN')"></h1>
            <h2 class="mb-5" v-html="$t('DISCOVER THE MAIN FEATURES OF ECM')"></h2>

            <!-- 
                Desktop version
            -->
            <div v-if="window.width > 991" class="accordion">
                <ul>
                    <li v-for="(item, index) in features_ecm_accordion" :key="index">
                        <div class="div-icon d-flex align-items-center justify-content-center">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="div-text">                            
                            <p class="mb-2 font-weight-bold text-center">{{item.title[lang]}}</p>
                            <p class="text-center">{{item.description[lang].replace(/{domain}/g, domain.title)}}</p>                            
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 
                Mobile version
            -->
            <div class="carousel-wrapper" v-else>
                <Carousel   
                    
                    class="mod-carousel"
                    :margin="0" 
                    :autoplay="true"                     
                    :dots="false"
                    :nav="false"
                    :responsive="{
                        0:{
                            items: 1
                        }
                    }"
                >                
                    <div class="item" v-for="(item, index) in features_ecm_accordion" :key="index">
                        <div class="div-icon d-flex align-items-center justify-content-center">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="div-text">                            
                            <p class="title">{{item.title[lang]}}</p>
                            <p class="description">{{item.description[lang].replace(/{domain}/g, domain.title)}}</p>                            
                        </div>
                    </div>
                    
                </Carousel>
            </div>            
        </div>
    </transition>

    <!-- 

        Transform your company...

    -->  
    <transition name="slide-fade">             
        <div v-if="show" class="section section-inovate-ecm base-padding d-flex flex-column justify-content-center" style="background-color: #fff !important;">
            <h1 class="mb-4 text-center font-ecm">{{$t('INOVATE THE WAY YOU WORK')}}</h1>
            <h2>{{$t('NOW YOU ARE ONE CLICK FROM THE INTERFY PLATFORM').replace(/{domain}/g, domain.title)}}</h2>

            <div class="d-flex flex-row justify-content-center align-items-center">
                <router-link class="btn btn-outline-green btn-medium-width mx-2" :to="`/start-free/${partner_url}`">{{$t('START_FREE')}}</router-link>
                <router-link v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')" class="btn btn-blue px-3 mx-2" :to="`/become-a-partner${partner_url}`">{{$t('BECOME_A_PARTNER')}}</router-link>
            </div>
        </div>
    </transition>

    <!-- 

        Simplify...

    -->  
    <transition name="slide-fade">
        <div class="section section-ecm-simplify base-padding">
            <h1 class="text-center font-ecm">{{$t('SIMPLIFY YOUR W')}}</h1>

            <div :class="{'mt-5': window.width > 991}" class="d-flex flex-row justify-content-center align-items-center">

                <Carousel   
                    style="width: 100%;"                     
                    :margin="0" 
                    :autoplay="true"                     
                    :dots="false"
                    :nav="false"
                    :responsive="{
                        0:{
                            items: 1
                        },
                        991:{
                            items: 3
                        },
                        1400:{
                            items: 4
                        },
                    }"
                >
                    <div class="item" v-for="(item, index) in simplify_your_w_cards" :key="index">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="item.icon"></i><span></span></div>
                                <p v-html="$t(item.title[lang])"></p>
                                <p class="description" v-html="$t(item.description[lang]).replace(`{domain}`, domain.title)"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>

                </Carousel>
            </div>
        </div>    
    </transition> 

    <!-- 
        Store any type...
    --> 
    <transition name="slide-fade">
        <div class="section section-ecm-store-any-type base-padding">
            <h1 class="mb-4 text-center font-ecm" v-html="$t('STORE ANY TYPE')"></h1>
            <h2 v-html="$t('EVERY COMPANY ')"></h2>
             <div class="mt-5 mb-5">

                <img class="mb-5" src="/assets/images/ecm/ecm-floating-icons.png" :alt="$t('STORE ANY TYPE')">                
                <b-carousel
                    id="carousel-every-company"
                    v-model="slide"
                    :interval="4000"
                    fade
                    controls
                    indicators
                    
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                >

                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/ecm/${lang}-01.png`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/ecm/${lang}-02.png`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/ecm/${lang}-03.png`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`/assets/images/domains/${domain.name}/ecm/${lang}-04.png`"></b-carousel-slide>
                    

                </b-carousel>
                
            </div>
        </div>
    </transition> 

   <!-- 
        Access your content...
    -->  
    <transition name="slide-fade">             
        <div v-if="show" class="section section-access-your-content ecm base-padding d-flex flex-column justify-content-center align-items-center">
            
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <h1 class="font-ecm" v-html="$t('ACCESS YOUR CO')"></h1>   
                    <h2 class="mt-4" v-html="$t('FIND YOUR FILE').replace(`{domain}`, domain.title)"></h2>
                </div>

                <div class="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-center">
                    <img :src="`/assets/images/domains/${domain.name}/ecm/mockup-${lang}-02.png`" :alt="$t('ACCESS YOU CO')">
                </div>
            </div>

        </div>
    </transition>   

    <transition name="slide-fade">
        <div class="section section-ecm-simplify base-padding">
             <div class="d-flex flex-row justify-content-center align-items-center">

                 <Carousel
                    style="width: 100%;"                     
                    :margin="0" 
                    :autoplay="true"                     
                    :dots="false"
                    :nav="false"
                    :responsive="{
                        0:{
                            items: 1
                        },
                        991:{
                            items: 3
                        },
                        1400:{
                            items: 4
                        }
                    }"
                >
                    <div class="item" v-for="(item, key) in card_features_responsive" :key="key">
                        <div class="card-col">
                            <div class="div-card d-flex flex-column justify-content-between align-items-center">
                                <div><i :class="item.icon"></i><span></span></div>
                                <p v-html="$t(item.title[lang])"></p>
                                <p class="description" v-html="$t(item.description[lang])"></p>
                                <router-link class="nav-link" :to="`/${partner_url}`">{{$t('LEARN_MORE')}} ></router-link>
                            </div>
                        </div>
                    </div>

                </Carousel>

               
            </div>
        </div>    
    </transition>   
    

     <!-- 
        MODULES...
    --> 
    <ModulesCarousel :partner_url="partner_url"  v-if="show" :modules="['bpm','scs','dss']"></ModulesCarousel>


    <!-- 

        Join......

    -->  
    <transition name="slide-fade">             
        <Join :isDomainPermitted="isDomainPermitted" :partner_url="partner_url" v-if="show" class="ecm"></Join>
    </transition>

    <!-- 

        The platform...

    -->  
    <transition name="slide-fade">             
        <ThePlatform :window="window" v-if="show"></ThePlatform>
    </transition>
    
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Clients from '../common/Clients'
import Join from '../common/Join'
import ThePlatform from '../common/ThePlatform'
import ModulesCarousel from '../common/ModulesCarousel'

import Carousel from 'vue-owl-carousel'

export default {
    components:{   
        Carousel,               
        Clients,        
        ThePlatform,
        Join,
        ModulesCarousel
    },
    props: {   
        isDomainPermitted:{
            type: Boolean,
            default: false
        },   
        partner_url: {
            type: String,
            default: ''
        },     
        window: {
            type: Object,
            default () {
                return {
                    width: 0,
                    height: 0
                }
            }
        }
    },
    data(){
        return{                        
            show: false,
            slide: 0,
            sliding: null
        }
    },
    computed: {

        ...mapState("MainStoreModule", {            
            domain: state => state.domain
        }),
        
        lang(){
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },

        simplify_your_w_cards() { 
            return [ 
                {
                    "icon": "icon-ecm-12",
                    "title": {
                        pt: "Gerenciamento de Perfil de Acesso ao Conteúdo",
                        en: "Content Access Profile Management",
                        es: "Gestión de perfiles de acceso al contenido"
                    },
                    "description": {
                        pt: "Defina as pastas que os usuários podem ter acesso e o que eles poderão realizar em cada conteúdo, inclusive, pode-se aplicar permissões distintas para documentos em uma mesma pasta utilizando os recursos de TAGs.",
                        en: "Define the folders that users can access and what they can do in each content, including, you can apply different permissions for documents in the same folder using the features of TAGs.",
                        es: "Defina las carpetas a las que pueden acceder los usuarios y lo que pueden hacer en cada contenido, incluido, puede aplicar diferentes permisos para documentos en la misma carpeta utilizando las características de TAG."
                    }
                },
                {
                    "icon": "icon-bpm-02",
                    "title": {
                        pt: "Indexação por fichas, Templates de Fácil Criação",
                        en: "Indexing by tokens, Easy Creation Templates",
                        es: "Indexación por tokens, Plantillas de creación fácil"
                    },
                    "description": {
                        pt: "Fichas é um recurso extra para indexar documentos de uma forma mais específica. Elas podem ser configuradas com quantos campos forem necessários, tendo eles vários formatos diferentes para ajudar na organização dos dados.",
                        en: "Bookmarks is an extra resource for indexing documents in a more specific way. They can be configured with as many fields as needed, and they have several different formats to help organize the data.",
                        es: "Marcadores es un recurso adicional para indexar documentos de una manera más específica. Se pueden configurar con tantos campos como sea necesario y tienen varios formatos diferentes para ayudar a organizar los datos."
                    }
                },
                {
                    "icon": "icon-ecm-07",
                    "title": {
                        pt: "Visualizador Interno para Principais Arquivos",
                        en: "Internal Viewer for Main Files",
                        es: "Visor interno de archivos principales"
                    },
                    "description": {
                        pt: "O visualizador do {domain} ECM é muito prático e suporta os principais formatos de arquivos que usamos no dia a dia, como: arquivos do office, imagens, pdfs, txt, xml, fotos, vídeos e áudios.",
                        en: "The {domain} ECM viewer is very practical and supports the main file formats we use on a daily basis, such as: office files, images, pdfs, txt, xml, photos, videos and audios.",
                        es: "El visor de {domain} ECM es muy práctico y admite los principales formatos de archivo que usamos a diario, como: archivos de oficina, imágenes, pdfs, txt, xml, fotos, videos y audios."
                    }
                },
                {
                    "icon": "icon-ecm-13",
                    "title": {
                        pt: "Captura de Documentos Utilizando Arrastar e Soltar",
                        en: "Document Capture Using Drag and Drop",
                        es: "Captura de documentos mediante arrastrar y soltar"
                    },
                    "description": {
                        pt: "Nossa interface de upload é otimizada para que o usuário tenha tudo que precisa em uma única janela, podendo classificar e indexar, capturar ou carregar vários arquivos de uma só vez.",
                        en: "Our upload interface is optimized so that the user has everything they need in a single window, being able to classify and index, capture or upload several files at once.",
                        es: "Nuestra interfaz de carga está optimizada para que el usuario tenga todo lo que necesita en una sola ventana, pudiendo clasificar e indexar, capturar o cargar varios archivos a la vez."
                    }
                }
            ]
        },

        card_features_responsive(){

            return [ 
                {
                    "icon": "icon-swc-02",
                    "title": {
                        pt: "Janela de trabalho responsiva",
                        en: "Responsive work window",
                        es: "Ventana de trabajo receptiva",
                    },
                    "description": {
                        pt: "O smartphone está sempre às mãos e nos ajuda a resolver muitas coisas em tempo recorde, e não poderia ser diferente quando precisar consultar um documento rapidamente mesmo que esteja fora do seu local de trabalho.",
                        en: "The smartphone is always at hand and helps us to solve many things in record time, and it couldn't be different when you need to consult a document quickly even if you are away from your workplace.",
                        es: "El teléfono inteligente está siempre a mano y nos ayuda a resolver muchas cosas en un tiempo récord, y no podría ser diferente cuando necesitas consultar un documento rápidamente incluso si estás fuera de tu lugar de trabajo."
                    }
                },
                {
                    "icon": "icon-ecm-14",
                    "title": {
                        pt: "Destaque para Arquivos Recentes",
                        en: "Highlight for Recent Files",
                        es: "Resaltar para archivos recientes",
                    },
                    "description": {
                        pt: "Todos os documentos que você acessou recentemente ficam listados na parte superior da home, e podem ser acessados rapidamente para facilitar a execução das atividades.",
                        en: "All documents that you have recently accessed are listed at the top of the home, and can be accessed quickly to facilitate the execution of activities.",
                        es: "Todos los documentos a los que ha accedido recientemente se enumeran en la parte superior de la casa y se puede acceder a ellos rápidamente para facilitar la ejecución de las actividades."
                    }
                },
                {
                    "icon": "icon-ecm-16",
                    "title": {
                        pt: "Ação de Favoritar Documentos",
                        en: "Favorite Documents Action",
                        es: "Acción de documentos favoritos",
                    },
                    "description": {
                        pt: "Sabe aqueles documentos que você usa bastante, ou enquanto resolve uma demanda tem que acessá-los várias vezes, pois é, esses documentos podem ser favoritados e aparecerão em destaque na sua home.",
                        en: "You know those documents that you use a lot, or while solving a demand you have to access them several times, as it is, these documents can be favored and will appear prominently in your home.",
                        es: "Conoces esos documentos que usas mucho, o al resolver una demanda tienes que acceder a ellos varias veces, ya que estos documentos pueden verse favorecidos y aparecerán de forma destacada en tu hogar."
                    }
                },
                {
                    "icon": "icon-ecm-15",
                    "title": {
                        pt: "Atalhos para as Principais Ações",
                        en: "Shortcuts to Main Actions",
                        es: "Accesos directos a las acciones principales",
                    },
                    "description": {
                        pt: "A home é a interface de entrada do software, nela incluímos vários atalhos para as principais ações que podem ser realizadas no sistema, o que permite excecutar atividades com menos cliques e mais agilidade.",
                        en: "The home is the input interface of the software, it includes several shortcuts to the main actions that can be performed on the system, which allows you to perform activities with fewer clicks and more agility.",
                        es: "El hogar es la interfaz de entrada del software, incluye varios accesos directos a las principales acciones que se pueden realizar en el sistema, lo que le permite realizar actividades con menos clics y más agilidad."
                    }
                }
            ]
        },
        
        features_ecm_accordion() {
            return [ 
                {
                    "icon": "icon-bpm-04",
                    "title": {
                        pt: "Conteúdo centralizado e 100% web",
                        en: "Centralized content and 100% web",
                        es: "Contenido centralizado y 100% web",
                    },
                    "description": {
                        pt: "Todos os documentos são armazenados em um único local em nuvem.",
                        en: "All documents are stored in a single cloud location.",
                        es: "Todos los documentos se almacenan en una única ubicación en la nube."
                    }
                },
                {
                    "icon": "icon-ecm-09",
                    "title": {
                        pt: "Telas responsivas para uso em smartphones",
                        en: "Responsive screens for use on smartphones",
                        es: "Pantallas receptivas para usar en teléfonos inteligentes",
                    },
                    "description": {
                        pt: "O sistema na palma da mão, onde e quando você precisar.",
                        en: "The system in your palm, wherever and whenever you need it.",
                        es: "El sistema en su palma, donde y cuando lo necesite."
                    }
                },
                {
                    "icon": "icon-ecm-01",
                    "title": {
                        pt: "Digitalização e upload de documentos simplificados",
                        en: "Simplified document scanning and uploading",
                        es: "Escaneo y carga de documentos simplificados",
                    },
                    "description": {
                        pt: "A interface de captura de documentos foi desenhada pensando em fornecer praticidade ao usuário.",
                        en: "The document capture interface was designed with the user in mind.",
                        es: "La interfaz de captura de documentos se diseñó pensando en el usuario."
                    }
                },
                {
                    "icon": "icon-ecm-07",
                    "title": {
                        pt: "Busca avançada, rápida e flexível",
                        en: "Advanced, fast and flexible search",
                        es: "Búsqueda avanzada, rápida y flexible",
                    },
                    "description": {
                        pt: "Os documentos armazenados no {domain} ECM podem ser encontrados de várias maneiras, com vários filtros de busca.",
                        en: "Documents stored in {domain} ECM can be found in a number of ways, with various search filters.",
                        es: "Los documentos almacenados en {domain} ECM se pueden encontrar de varias formas, con varios filtros de búsqueda."
                    }
                },
                {
                    "icon": "icon-ecm-10",
                    "title": {
                        pt: "Processamento de documentos por OCR",
                        en: "OCR document processing",
                        es: "Procesamiento de documentos OCR",
                    },
                    "description": {
                        pt: "Cada arquivo armazenado do {domain} ECM é processado e tem seu conteúdo extraído em alguns segundos após sua inserção.",
                        en: "Each stored file of {domain} ECM is processed and its content is extracted in a few seconds after its insertion.",
                        es: "Cada archivo almacenado de {domain} ECM se procesa y su contenido se extrae unos segundos después de su inserción."
                    }
                },
                {
                    "icon": "icon-ecm-03",
                    "title": {
                        pt: "Versionamento com checkin e checkout",
                        en: "Versioning with checkin and checkout",
                        es: "Control de versiones con checkin y checkout",
                    },
                    "description": {
                        pt: "Ative o controle de versão e mantenha o histórico de alterações realizadas no documento e nos registros. Somente usuários autorizados podem acessar versões anteriores.",
                        en: "Enable versioning and keep a history of changes made to the document and records. Only authorized users can access previous versions.",
                        es: "Habilite el control de versiones y mantenga un historial de los cambios realizados en el documento y los registros. Solo los usuarios autorizados pueden acceder a versiones anteriores."
                    }
                },
                {
                    "icon": "icon-ecm-08",
                    "title": {
                        pt: "Controle de temporalidade",
                        en: "Temporality control",
                        es: "Control de temporalidad",
                    },
                    "description": {
                        pt: "Configure a classificação da temporalidade para determinar o prazo de guarda legal do conteúdo. Aplique a temporalidade aos documentos armazenados e deixe que o sistema realize as ações de mudança de fases automaticamente, até a eliminação ou a guarda permanente.",
                        en: "Configure the temporality classification to determine the legal custody of the content. Apply temporality to stored documents and let the system carry out the phase change actions automatically, until elimination or permanent guard.",
                        es: "Configurar la clasificación de temporalidad para determinar la custodia legal del contenido. Aplicar temporalidad a los documentos almacenados y dejar que el sistema realice las acciones de cambio de fase de forma automática, hasta su eliminación o guarda permanente."
                    }
                },
                {
                    "icon": "icon-ecm-11",
                    "title": {
                        pt: "Compartilhamento de documentos online",
                        en: "Online document sharing",
                        es: "Compartir documentos en línea",
                    },
                    "description": {
                        pt: "Uma maneira segura de compartilhar documentos com terceiros e definir o tempo que ficará compartilhado.",
                        en: "A secure way to share documents with third parties and set the time to be shared.",
                        es: "Una forma segura de compartir documentos con terceros y establecer el tiempo para compartirlos."
                    }
                }
            ]
        }
    },    
    methods: {
        ...mapActions('MainStoreModule', [                  
        ]),   

        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }, 
    
    beforeCreate(){          
        this.$emit('isPageLoaded', false)  
    },
    
    mounted(){             
        setTimeout(() => {
            this.$emit('isPageLoaded', true)
            this.show = true
        }, 1000);
    }   
}
</script>

<style scoped lang="scss">

$ecm: #0DC5C7;
$bpm: #2C2FFA;
$swc: #E82F8A;
$dss: #962DFB;
$slv: #80BA27;
$das: #DEC902;

.section-ecm-simplify {    
    h2 {
        width: 100%;
        text-align: center;
    }
    a {
        font-size: 16px !important;
        transition: all .4s ease;
    }
    p {
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }
    .imgOpenModal {
        margin-top: 20px;
        width: 15vw;
    }
    .item{
        padding: 30px 25px;
            
        .div-card {
            width: 100%;
            border-radius: 20px;
            padding: 40px 20px;
            height: 400px;
            transition: all .4s ease;
            box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
            cursor: pointer;
            text-align: center;
            background-color: #fff;

            &:hover {
                p,
                a {
                    opacity: 0;
                }
                p.description {                    
                    opacity: 1;
                }
                div {
                    span {
                        width: 70px;
                    }
                }
            }
            div {                
                margin-bottom: -10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span {
                    display: block;
                    margin-top: 10px;
                    width: 0;
                    border-radius: 20px;
                    transition: all .2s ease;
                }
                i {
                    font-size: 50px;
                }
            }
            p {
                margin: 0 !important;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                b {
                    font-weight: 800;
                }
                &.description {
                    font-size: 16px !important;
                    line-height: 20px !important;
                    font-weight: 400 !important;
                    opacity: 0;
                    position: absolute;
                    padding: 20px 40px;
                    top: 130px;
                }
            }
            a {
                text-decoration: none;
                border: 1px solid transparent;
                padding: 0;
                transition: all .2s ease;
            }
        }
    }
}

.section-ecm-simplify .owl-item {
    .card-col {
        .div-card {
            &:hover {
                box-shadow: 0 0 2.5rem rgba(0, 199, 175, 0.5);
            }
            color: $ecm;
            a:hover {
                color: $ecm;
                border: 1px solid $ecm;
            }
            div span {
                border-bottom: 3.5px solid $ecm;
            }
        }
    }
}

.section-join.ecm {
    background-color: #214CD2;
}


@media only screen and (min-width: 992px) and (max-width: 1460px){
    .section-ecm-simplify{
        .item {
            .div-card {
                p,
                p.description{
                    font-size: 16px !important;
                    line-height: 18px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    
    .section-main-ecm {        
        background: url(/assets/images/ecm/ecm-topo-bg.png) no-repeat;
        background-size: 100% 100%;
        width: 100% !important;
        height: auto !important;
        padding: 10px !important;

        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        
        .div-buttons{
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            
            .btn {
                padding: 0 10px !important;             
                margin: 20px 0 0 0 !important;
                width: 190px !important;
                height: 30px !important;
                font-size: 16px !important;
                line-height: 18px !important;
                border-radius: 5px !important;          
            }
        }

        .main-background,
        .mockup {
            display: none;
        }
    }

    .section-ecm-simplify,
    .section-inovate-ecm,
    .section-ecm-features,
    .section-main-module {
        h1{
            width: 100% !important;
            text-align: center;
            padding: 20px 0 !important;
            margin: 0 !important;
        }

        h2{
            margin: 0 0 20px 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
        }

        h3{
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important; 
            text-align: center;
        }
    }

    .section-ecm-features {
        padding: 30px 0 !important;
        
        .carousel-wrapper{
            
            .mod-carousel{            
                min-height: 200px;

                .owl-item {            
                    min-height: 200px;   

                    .item {       
                        min-height: 200px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;                                                                    
                        padding: 20px;
                        background-color: #fff !important;
                        border-radius: 15px !important;
                        margin: 20px !important;
                        
                        .div-icon {                            
                            i{
                                font-size: 45px;
                            }
                        }

                        .div-text {
                            .title{                                
                                margin: 20px 0 20px 0 !important;
                                text-align: center;
                                font-weight: bold;
                            }
                            .description{                                
                                margin: 0 !important;
                                text-align: center;
                            }
                        }
                    }
                    
                    
                    &:nth-child(1) .item{
                        box-shadow: 1px 2px 5px #CE3009;
                        .div-icon i,
                        .div-text p {
                            color: #CE3009;
                        }
                    }
                    &:nth-child(2) .item{                    
                        box-shadow: 1px 2px 5px #01C7B0;
                        .div-icon i,
                        .div-text p {
                            color: #01C7B0;
                        }
                    }
                    &:nth-child(3) .item{                    
                        box-shadow: 1px 2px 5px #E32555;
                        .div-icon i,
                        .div-text p {
                            color: #E32555;
                        }
                    }
                    &:nth-child(4) .item{                    
                        box-shadow: 1px 2px 5px #7F81F0;
                        .div-icon i,
                        .div-text p {
                            color: #7F81F0;
                        }
                    }
                    &:nth-child(5) .item{                    
                        box-shadow: 1px 2px 5px #EA4587;
                        .div-icon i,
                        .div-text p {
                            color: #EA4587;
                        }
                    }
                    &:nth-child(6) .item{                    
                        box-shadow: 1px 2px 5px #FF9B4D;
                        .div-icon i,
                        .div-text p {
                            color: #FF9B4D;
                        }
                    }
                    &:nth-child(7) .item{                    
                        box-shadow: 1px 2px 5px #248AF6;
                        .div-icon i,
                        .div-text p {
                            color: #248AF6;
                        }
                    }
                    &:nth-child(8) .item{                    
                        box-shadow: 1px 2px 5px #34D432;
                        .div-icon i,
                        .div-text p {
                            color: #34D432;
                        }
                    }
                    
                }
            }
        }
    }

    .section-inovate-ecm{

        padding: 30px 10px !important;

        div{
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            
            .btn {
                padding: 0 10px !important;             
                margin: 20px 0 0 0 !important;
                width: 190px !important;
                height: 30px !important;
                font-size: 16px !important;
                line-height: 18px !important;
                border-radius: 5px !important;          
            }
        }
        
    }

    .section-ecm-simplify{                
        
        padding: 0 0 30px 0 !important;            
        margin: 30px 0 0 0 !important;

        h1{
            padding: 0 10px !important;
        }
        .item {
            .div-card {
                height: 330px !important;
                p,
                p.description{
                    font-size: 14px !important;
                    line-height: 16px !important;
                }
            }
        }
        
    }
}


    
</style>