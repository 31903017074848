var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-testimonials base-padding d-flex flex-column justify-content-center align-items-center"},[_c('div',[_c('h1',{staticClass:"mb-5 text-center font-bpm"},[_vm._v(_vm._s(_vm.$t('TESTIMONIES_TITLE').replace(/{domain}/g, _vm.domain.title)))])]),_c('Carousel',{staticClass:"carousel-testimonials mt-5",attrs:{"margin":0,"autoplay":true,"dots":false,"nav":false,"responsive":{
            0:{
                items: 1
            },
            991:{
                items: 2
            },
            1400:{
                items: 3
            },
            1500:{
                items: 3
            }
        }}},_vm._l((_vm.data_testimonials),function(item,index){return _c('div',{key:index,staticClass:"testimonial-slide"},[_c('div',{staticClass:"inner d-flex flex-row justify-content-center align-items-center"},[_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-center div-item"},[_c('i',{staticClass:"align-self-start icon-aspas"}),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-end div-person"},[_c('img',{attrs:{"src":'/assets/images/home/testimonials/' + item.avatar}}),_c('div',{staticClass:"text-right"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.role))])])]),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.text[_vm.lang].replace(/{domain}/g, _vm.domain.title))}}),_c('span',{staticClass:"solution"},[_vm._v(_vm._s(`${_vm.$t('SOLUTION_USED')}: ${item.solution}`))])])])])}),0),_c('div',{staticClass:"empty-background"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }