<template>
  <div>
    <b-modal
      size="lg"
      ref="modalCustomerData"
      :title="modalData.title"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"      
      @hidden="$emit('closeModal')"
      @ok="handleOk"
    >
      <FormCustomer
        :selectedPlan="selectedPlan"
      ></FormCustomer>

    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue"
import FormCustomer from '../plans/FormCustomer'

export default {  
  components: {
    BModal,  
    FormCustomer  
  },
  props: ["selectedPlan"],
  data() {
    return {
      modalData: {
        title: this.$t("MODAL_PLAN_TITLE"),
      },
      headerBgVariant: "light",
      headerTextVariant: "dark",   
    };
  },  
  
  methods: {   
    
    handleOk(bvModalEvt) {      
      bvModalEvt.preventDefault()
      this.$root.$emit('on-hit-ok-modal-customer')
    },
    
  },
};
</script>

<style lang="scss">
.modal-plans {
  border: 5px solid #4669f8;
  border-radius: 0.3rem;
  .modal-content {
    border-radius: none !important;
    border: none !important;
  }
  header {
    background-color: transparent !important;
    h5 {
      padding-left: 15px;
    }
  }
}
</style>